import { Component, OnInit } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { CustomRouter } from '../services/custom-router';

@Component({
  selector: 'app-verified',
  templateUrl: './verified.component.html',
  styleUrls: ['./verified.component.scss']
})

export class VerifiedComponent implements OnInit {

  router
  constructor(public appService: AppService, router: CustomRouter) {
    this.router = router
  }

  ngOnInit() {
  }

}
