
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Order } from '../resources/Order';
import { ConfigService } from './config.service';
import { MoomeRestService, Options } from './moome-rest.service';

@Injectable({
	providedIn: 'root'
})
export class OrderService extends MoomeRestService<Order> {


	constructor(public http: HttpClient, injector: Injector,private configService: ConfigService) {
		super(Order);
	}

	getAllOrders(key,page, size){
        
		let param: any =
		{
			projection: 'orders',
			
		}
		if (key != null)
			param.key = key.toLowerCase();

		let options: Options = {
			params:
				param
			,
			pageParams:{
				page: page,
				size:size,
			}
			
		}
      return this.customRequestGet(`/getAllOrders`,null,options)
    }
	saveOrder(body) {
		return this.http.post(this.configService.getApiPath() + `/orders/saveOrder`, body);
	}
	getCountOrderPerDay() {
		return this.http.get(this.configService.getApiPath() + `/orders/getCountOrderPerDay`)
	}


}
