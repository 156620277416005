
import { Injectable, Injector} from "@angular/core";
import { TokenManagementService } from "./token-management.service";
import { MoomeRestService } from "./moome-rest.service";
import { CustomerSetting } from "../resources/CustomerSetting";
import { ConfigService } from "./config.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: 'root',
})

export class CustomerSettingService extends MoomeRestService<CustomerSetting>{


	constructor() {
        super(CustomerSetting);
    }
    

}