<div id="loading">
        <div class="blink">
          <img src="/assets/images/2020-04-20_22-32-11.png">
        </div>
      </div>
      <app-network-detection-dialog *ngIf="noNetwork"></app-network-detection-dialog>
<router-outlet ></router-outlet>



<!--<h1>Test Me</h1>

my position is:<br/>
longitude: {{lng}}<br/>
latitude: {{ltd}}<br/>

timestamp: {{timestamp}}<br/>-->