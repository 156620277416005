import {Breed} from './Breed';
import {Address} from './Address';
import {Device} from './Device';

export class AnimalDto {
    id: number;
    uuid: string;
    uid: string;
    code: string;
    name: string;
    addressId: Address | any;
    breedId: Breed | any;
    deviceId: Device | any;
    gatewayId: number[];
    installDate: any;
    sexe: String;
    lactNum: number;
    birthDate: any;
    weight: number;
    group: string;
  }