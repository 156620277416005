import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AppService } from 'projects/shared/services/app.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FarmerService } from 'projects/shared/services/farmer.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { Alert, MAX_LENGTH_PASSWORD, MIN_LENGTH_PASSWORD, PATERN_PASSWORD } from 'projects/shared/tools/constants';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { Customer } from 'projects/shared/resources/Customer';
import { CustomRouter } from '../../services/custom-router';
//import { RecaptchaComponent } from '../../recaptcha/recaptcha.component';
import { Subscription } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-change-passord-dialog',
  templateUrl: './change-passord-dialog.component.html',
  styleUrls: ['./change-passord-dialog.component.scss']
})
export class ChangePassordDialogComponent implements OnInit {

  formGroup: UntypedFormGroup;
  id: number
  hidePass: Boolean
  hidePassC: Boolean
  minLengthPassword = MIN_LENGTH_PASSWORD
  maxLengthPassword = MAX_LENGTH_PASSWORD
  patternPassword = PATERN_PASSWORD
  private executionsSubscription: Subscription;

  constructor(private router: CustomRouter, public tokenManagementService: TokenManagementService, public dialogRef: MatDialogRef<ChangePassordDialogComponent>, public appService: AppService, private farmerService: FarmerService,
    @Inject(MAT_DIALOG_DATA) public data: any, private recaptchaV3Service: ReCaptchaV3Service, private formBuilder: UntypedFormBuilder, public translate: TranslateService, private alertsService: AlertsService, public dialog: MatDialog,) {
    this.id = this.appService.connectedCustomer.id;
    this.createForm();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.appService.hideRecaptcha()
    if (this.executionsSubscription) {
      this.executionsSubscription.unsubscribe();
    }
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'newPassword': [null, [Validators.required,
      Validators.minLength(MIN_LENGTH_PASSWORD),
      Validators.maxLength(MAX_LENGTH_PASSWORD),
      Validators.pattern(PATERN_PASSWORD)]],
      'confNewPassword': [null, [Validators.required,
      Validators.minLength(MIN_LENGTH_PASSWORD),
      Validators.maxLength(MAX_LENGTH_PASSWORD),
      Validators.pattern(PATERN_PASSWORD)]],
    });
  }

  confirm() {
    if (this.formGroup.controls['newPassword'].hasError('minlength'))
      this.message(this.translate.instant('LENG_NEW_PASSWORD') + " " + this.minLengthPassword.toString()+" "+ this.translate.instant('CARACTERE') + " ")

    else if (this.formGroup.controls['newPassword'].hasError('maxlength'))
      this.message(this.translate.instant('MAX_LENGTH_NEW_PASSWORD') + " " + this.maxLengthPassword.toString()+" "+ this.translate.instant('CARACTERE') + " ")

    else if (this.formGroup.controls['newPassword'].hasError('pattern'))
      this.message('NEW_PASSWORD_PATTERN');

    else if (this.formGroup.controls['newPassword'].hasError('required'))
      this.message(this.translate.instant('PLEASE_CHECK_NEW_PASSWORD'));

    else if (this.formGroup.controls['confNewPassword'].hasError('required'))
      this.message(this.translate.instant('PLEASE_CONFIRM_NEW_PASSWORD'));

    else if (this.formGroup.controls['confNewPassword'].hasError('minlength'))
      this.message(this.translate.instant('LENG_CONF_NEW_PASSWORD') + " " + this.minLengthPassword.toString() +" "+ this.translate.instant('CARACTERE') + " ")

    else if (this.formGroup.controls['confNewPassword'].hasError('maxlength'))
      this.message(this.translate.instant('MAX_LENGTH_CONF_NEW_PASSWORD') + " " + this.maxLengthPassword.toString() +" "+ this.translate.instant('CARACTERE') + " ")

    else if (this.formGroup.controls['confNewPassword'].hasError('pattern'))
      this.message('CONF_NEW_PASSWORD_PATTERN');

    else if (this.formGroup.controls['newPassword'].value != this.formGroup.controls['confNewPassword'].value) {
      this.message(this.translate.instant('NO_CONFIRM_PASSWORD'));
    }
    else {
      /*const dialogRef = this.dialog.open(RecaptchaComponent, {
        data: {
          onConfirm: (token) => {*/
          this.appService.showRecaptcha();
          this.executionsSubscription = this.recaptchaV3Service.execute('importantAction')
          .subscribe((token: string) => {
            this.appService.showLoading()

            let customer: Customer = this.data.customer;
            customer.id = this.id
            customer['recaptchaToken'] = token
            customer.password = (document.getElementById("newPassword") as HTMLFormElement).value.toString().replace(/\s/g, "");
            this.farmerService.updatePasswordApp(customer).subscribe(data => {
              this.succesMessage()
              this.login();
            }
              , error => {
                this.message(this.translate.instant('SAVE_FAIL'));
              })
          })
      //  }
     // });
    }
  }

  login() {
    this.appService.showLoading()
    this.tokenManagementService.login(this.appService.connectedCustomer.username, (document.getElementById("newPassword") as HTMLFormElement).value.toString().replace(/\s/g, ""))
      .subscribe(
        data => {
          this.tokenManagementService.setToken(data['access_token'], data['refresh_token'], data['expires_in'], data['fullname']);
          this.appService.hideLoading();
          this.dialogRef.close()
        },
        error => {
          this.message(this.translate.instant('LOGIN_FAIL'));
        }
      );
  }

  succesMessage() {
    this.dialog.closeAll();
    let message: string;
    this.appService.hideLoading();
    message = this.translate.instant('SAVE_SUCESS');
    this.alertsService.open(message);
  }

  message(msg) {
    this.appService.hideLoading();
    let alert = this.translate.instant(Alert.OK)
    this.alertsService.open(msg, alert);
  }
}
