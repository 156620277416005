import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartComponent } from 'projects/publicV2/src/app/start/start.component';
import { LoginComponent } from 'projects/publicV2/src/app/login/login.component';
import { EntryComponent } from 'projects/publicV2/src/app/entry/entry.component';
import { HomeComponent } from 'projects/publicV2/src/app/home/home.component';
import { WelcomeComponent } from 'projects/publicV2/src/app/welcome/welcome.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { MenuComponent } from './menu/menu.component';
import { AnimalsComponent } from './animals/animals.component';
import { AlertsComponent } from './alerts/alerts.component';
import { SettingsAnimalComponent } from './settings/settings-animal/settings-animal.component';
import { SmsVerificationComponent } from './sign-up/sms-verification/sms-verification.component';
import { AlertTypeComponent } from './alerts/alert-type/alert-type.component';
import { EditAnimalComponent } from './animals/edit-animal/edit-animal.component';
import { GroupsComponent } from './animals/groups/groups.component';
import { TimelinesComponent } from './timelines/timelines.component';
import { NotesComponent } from './notes/notes.component';
import { PedigreeComponent } from './pedigree/pedigree.component';
import { EditNoteComponent } from './notes/edit-note/edit-note.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { RoleGuard } from 'projects/shared/guard/role.guard';
import { AuthGuard } from 'projects/shared/guard/auth.guard';
import { ForgetPasswordComponent } from './login/forget-password/forget-password.component';
import { FarmersComponent } from './farmers/farmers.component';
import { ResultSearchComponent } from './farmers/result-search/result-search.component';
import { AuthorityGuard } from 'projects/shared/guard/authority.guard';
import { Authorities } from 'projects/shared/tools/constants';
import { MilkCollectionCenterComponent } from './milk-collection-center/milk-collection-center.component';
import { TanksComponent } from './tanks/tanks.component';
import { ThiComponent } from './thi/thi.component';
import { SupervisorsComponent } from './supervisors/supervisors.component';
import { CommunGraphComponent } from 'projects/shared/components/commun-graph/commun-graph.component';
import { HelpComponent } from './menu/help/help.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SettingsCustomerComponent } from './settings/settings-customer/settings-customer.component';
import { LabelComponent } from './label/label.component';
import { SettingsFormComponent } from './settings/settings-form/settings-form.component';
import { ReportComponent } from './report/report.component';
import { MapComponent } from './map/map.component';
import { FarmerMilkProductionComponent } from './farmer-milk-production/farmer-milk-production.component';
import { SupervisorFarmerSettingsComponent } from './supervisor-farmer-settings/supervisor-farmer-settings.component';
import { EditSupervisorFarmerSettingsComponent } from './supervisor-farmer-settings/edit-supervisor-farmer-settings/edit-supervisor-farmer-settings.component';
import { EditFarmerMilkProductionComponent } from './farmer-milk-production/edit-farmer-milk-production/edit-farmer-milk-production.component';
import { FarmerFeedConsumptionComponent } from './farmer-feed-consumption/farmer-feed-consumption.component';
import { EditFarmerFeedConsumptionComponent } from './farmer-feed-consumption/edit-farmer-feed-consumption/edit-farmer-feed-consumption.component';
import { WeeklyPlanManagementComponent } from './weekly-plan-management/weekly-plan-management.component';
import { ReproductionReportComponent } from './reproduction-report/reproduction-report.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { ShopComponent } from './shop/shop.component';
import { MilkFeedReportComponent } from './milk-feed-report/milk-feed-report.component';
import { LikeComponent } from './menu/like/like.component';
import { SettingsGraphComponent } from './settings/settings-graph/settings-graph.component';
import { MilkCollectedGraphComponent } from './milk-collected-graph/milk-collected-graph.component';
import { CalvingGraphComponent } from './calving-graph/calving-graph.component';
import { MilkQuantityCowComponent } from './milk-quantity-cow/milk-quantity-cow.component';
import { EditMilkQuantityCowComponent } from './milk-quantity-cow/edit-milk-quantity-cow/edit-milk-quantity-cow.component';
import { CowMilkGraphComponent } from './cow-milk-graph/cow-milk-graph.component';
import { EconomyComponent } from './economy/economy.component';
import { HistoriqueCardEconomyComponent } from './economy/historique-card-economy/historique-card-economy.component';
import { EditCardEconomyComponent } from './economy/edit-card-economy/edit-card-economy.component';
import { StockComponent } from './stock/stock.component';
import { EditStockComponent } from './stock/edit-stock/edit-stock.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HerdComponent } from './herd/herd.component';
import { CarbonEmissionsComponent } from './carbon-emissions/carbon-emissions.component';
import { FarmEcoComponent } from './farm-eco/farm-eco.component';
import { MilkProductionComponent } from './milk-production/milk-production.component';
import { HealthComponent } from './health/health.component';
import { ProductionComponent } from './production/production.component';
import { FeedComponent } from './feed/feed.component';
import { HistoryStockComponent } from './stock/history-stock/history-stock.component';
import { HistoryMiscStockComponent } from './stock/history-misc-stock/history-misc-stock.component';
import { EditMiscStockComponent } from './stock/edit-misc-stock/edit-misc-stock.component';
const routes: Routes = [

  {
    path: '', component: StartComponent, children: [
      { path: 'login', component: LoginComponent },
      { path: '', component: WelcomeComponent, canActivate: [AuthGuard] },
      { path: 'get-started', component: GetStartedComponent },
      { path: 'sign-up', component: SignUpComponent },
      { path: 'sms-verification', component: SmsVerificationComponent },
      { path: 'forget-password', component: ForgetPasswordComponent },
      { path: 'contact', component: ContactUsComponent },

      //{ path: 'no-connection', component: NetworkDetectionDialogComponent },

    ]
  },
  {
    path: '', component: EntryComponent, canActivate: [RoleGuard], data: { access: ['publicAccess'] }, children: [
      { path: 'home', component: HomeComponent },
      { path: 'herd' , component : HerdComponent},
      { path: 'dashboard', component :DashboardComponent},
      { path: 'carbon', component: CarbonEmissionsComponent },
      { path: 'economics', component:FarmEcoComponent},
      { path: 'health', component:HealthComponent},
      { path: 'milkProd', component:MilkProductionComponent},
      { path:'production', component:ProductionComponent},
      { path:'feed', component:FeedComponent},
      { path: 'menu', component: MenuComponent },
      { path: 'settings', component: SettingsCustomerComponent },
      { path: 'alerts', component: AlertsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ALERTS } },
      { path: 'alerts/:code', component: AlertTypeComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ALERTS } },
      { path: 'animals/save', component: EditAnimalComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMALS } },
      { path: 'animals/save/:id', component: EditAnimalComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMALS } },
      { path: 'animals/groups', component: GroupsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.GROUPS } },
      { path: 'animals/groups/:id', component: AnimalsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMALS } },
      { path: 'animals/groups/:id/:label', component: AnimalsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMALS } },
      { path: 'animals/:id/groups', component: GroupsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.GROUPS } },
      { path: 'animals/:id/timelines', component: TimelinesComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.TIMELINES } },
      { path: 'animals/:id/settings', component: SettingsAnimalComponent },
      { path: 'animals/:id/settings/search', component: AnimalsComponent },
      { path: 'animals/:id/settings/notes', component: NotesComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_NOTES } },
      { path: 'animals/:id/settings/pedigree', component: PedigreeComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.PEDIGREE } },
      { path: 'animals/:id/settings/notes/save', component: EditNoteComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_NOTES } },
      { path: 'animals/:id/settings/notes/save/:idNote', component: EditNoteComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_NOTES } },
      { path: 'animals/groups/:id/search/:key', component: AnimalsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMALS } },
      { path: 'alerts/search/:key', component: AlertTypeComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ALERTS } },
      { path: 'alerts/:code/search/:key', component: AlertTypeComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ALERTS } },
      { path: 'animals/:id/settings/notes/search', component: NotesComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_NOTES } },
      { path: 'farmers', component: FarmersComponent, canActivate: [RoleGuard], data: { access: ['isSupervisor'], auth: Authorities.SUPERVISORS } },
      { path: 'farmers/:idFarmer/animals/groups/:id', component: AnimalsComponent },
      { path: 'farmers/:idFarmer/animals/save', component: EditAnimalComponent },
      { path: 'farmers/:idFarmer/animals/groups', component: GroupsComponent },
      { path: 'farmers/animals/groups/:id/resultSearch/:key', component: ResultSearchComponent },
      { path: 'farmers/:idFarmer/animals/:id/settings', component: SettingsAnimalComponent },
      { path: 'farmers/:idFarmer/animals/:id/settings/search', component: ResultSearchComponent },
      { path: 'farmers/:idFarmer/animals/groups/:id', component: AnimalsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMALS } },
      { path: 'farmers/:idFarmer/animals/groups/:id/:label', component: AnimalsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMALS } },
      { path: 'farmers/:idFarmer/animals/:id/groups', component: GroupsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.GROUPS } },
      { path: 'farmers/:idFarmer/animals/:id/settings/notes', component: NotesComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_NOTES } },
      { path: 'farmers/:idFarmer/animals/:id/settings/pedigree', component: PedigreeComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.PEDIGREE } },
      { path: 'farmers/:idFarmer/animals/save/:id', component: EditAnimalComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMALS } },
      { path: 'farmers/:idFarmer/animals/:id/settings/notes/save', component: EditNoteComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_NOTES } },
      { path: 'farmers/:idFarmer/animals/:id/settings/notes/save/:idNote', component: EditNoteComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_NOTES } },
      { path: 'farmers/:idFarmer/animals/save', component: EditAnimalComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMALS } },
      { path: 'farmers/:idFarmer/animals/:id/timelines', component: TimelinesComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.TIMELINES } },
      { path: 'farmers/:idFarmer/animals/:id/settings/notes/search', component: NotesComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_NOTES } },
      { path: 'mcc', component: MilkCollectionCenterComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ENTREPRISES } },
      { path: 'mcc/:id/tanks', component: TanksComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.TANKS } },
      { path: 'farmers/:idFarmer/alerts', component: AlertsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ALERTS } },
      { path: 'farmers/:idFarmer/alerts/:code', component: AlertTypeComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ALERTS } },
      { path: 'thi', component: ThiComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.TAGS } },
      { path: 'supervisors', component: SupervisorsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.SUPERVISORS } },
      { path: 'supervisors/:idSupervisor/farmers', component: FarmersComponent,/* canActivate: [RoleGuard],*/ data: {/* access: ['isSupervisor'], */auth: Authorities.SUPERVISORS } },
      { path: 'supervisors/:idSupervisor/mcc', component: MilkCollectionCenterComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ENTREPRISES } },
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/animals/groups/:id', component: AnimalsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMALS } },
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/animals/save', component: EditAnimalComponent },
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/animals/groups/:id', component: AnimalsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMALS } },
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/animals/:id/settings', component: SettingsAnimalComponent },
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/animals/groups', component: GroupsComponent },
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/animals/:id/settings/notes', component: NotesComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_NOTES } },
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/animals/:id/settings/pedigree', component: PedigreeComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.PEDIGREE } },
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/animals/save/:id', component: EditAnimalComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMALS } },
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/animals/:id/settings/notes/save', component: EditNoteComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_NOTES } },
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/animals/groups/:id/:label', component: AnimalsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMALS } },
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/animals/:id/groups', component: GroupsComponent },
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/animals/:id/timelines', component: TimelinesComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.TIMELINES } },
      { path: 'supervisors/:idSupervisor/mcc', component: MilkCollectionCenterComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ENTREPRISES } },
      { path: 'supervisors/:idSupervisor/mcc/:id/tanks', component: TanksComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.TANKS } },
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/alerts/:code', component: AlertTypeComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ALERTS } },
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/alerts', component: AlertsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ALERTS } },
      { path: 'tanks', component: TanksComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.TANKS } },
      { path: 'supervisors/:idSupervisor/humidity-temp', component: CommunGraphComponent, canActivate: [AuthorityGuard], data: { auth: [Authorities.REPORTS_HUMIDITY_TEMP] } },
      // { path: 'supervisors/:idSupervisor/reports' , component: FarmerAnimalTableComponent },
      { path: 'supervisors/:idSupervisor/rumination-ui', component: CommunGraphComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.RUMINATION_UI } },
      { path: 'supervisors/:idSupervisor/temp_graph', component: CommunGraphComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.REPORTS_TEMP_GRAPHS } },
      // { path: 'no-connection', component: NetworkDetectionDialogComponent },
      { path: 'help', component: HelpComponent },
      { path: 'rumination-ui', component: CommunGraphComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.RUMINATION_UI } },
      { path: 'humidity-temp', component: CommunGraphComponent, canActivate: [AuthorityGuard], data: { auth: [Authorities.REPORTS_HUMIDITY_TEMP] }},
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/settings', component: SettingsFormComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FORM_TEMPLATE }},
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/settings/:idFormTemplate', component: SupervisorFarmerSettingsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FORM }},
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/settings/:idFormTemplate/save', component: EditSupervisorFarmerSettingsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FORM }},
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/settings/:idFormTemplate/save/:id', component: EditSupervisorFarmerSettingsComponent,  canActivate: [AuthorityGuard], data: { auth: Authorities.FORM }},
      { path: 'animals/:id/settings/label', component: LabelComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.PROJECT } },
      { path: 'farmers/:idFarmer/animals/:id/settings/label', component: LabelComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.PROJECT } },
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/animals/:id/settings/label', component: LabelComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.PROJECT } },
      { path: 'supervisors/:idSupervisor/reports', component: ReportComponent },
      { path: 'supervisors/:idSupervisor/map', component: MapComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ADDRESS } },
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/map', component: MapComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ADDRESS } },

      { path: 'resultSearch/farmers/:idFarmer/animals/groups/:id', component: AnimalsComponent },
      { path: 'resultSearch/farmers/:idFarmer/alerts', component: AlertsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ALERTS } },
      { path: 'farmers/:idFarmer/settings', component: SettingsFormComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FORM_TEMPLATE } },
      { path: 'resultSearch/farmers/:idFarmer/settings', component: SettingsFormComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FORM_TEMPLATE } },
      { path: 'farmers/:idFarmer/settings/:idFormTemplate', component: SupervisorFarmerSettingsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FORM } },
      { path: 'farmers/:idFarmer/settings/:idFormTemplate/save', component: EditSupervisorFarmerSettingsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FORM } },
      { path: 'farmers/:idFarmer/settings/:idFormTemplate/save/:id', component: EditSupervisorFarmerSettingsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FORM } },
      { path: 'resultSearch/farmers/:idFarmer/animals/:id/settings', component: SettingsAnimalComponent },
      { path: 'farmers/:idFarmer/settings/:idFormTemplate/search', component: SupervisorFarmerSettingsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FORM } },
      { path: 'mcc/:mccId/alerts', component: AlertsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ALERTS } },
      { path: 'supervisors/:idSupervisor/mcc/:mccId/alerts', component: AlertsComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ALERTS } },
      { path: 'mcc/:mccId/alerts/:code', component: AlertTypeComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ALERTS } },
      { path: 'supervisors/:idSupervisor/mcc/:mccId/alerts/:code', component: AlertTypeComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ALERTS } },
      { path: 'settings/:idFormTemplate/' + Authorities.FARMER_MILK_PRODUCTION, component: FarmerMilkProductionComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FARMER_MILK_PRODUCTION } },
      { path: 'settings/:idFormTemplate/' + Authorities.FARMER_MILK_PRODUCTION + '/save', component: EditFarmerMilkProductionComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FARMER_MILK_PRODUCTION } },
      { path: 'settings/:idFormTemplate/' + Authorities.FARMER_MILK_PRODUCTION + '/save/:id', component: EditFarmerMilkProductionComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FARMER_MILK_PRODUCTION } },
      { path: 'settings/:idFormTemplate/' + Authorities.FARMER_FEED_CONSUMPTION, component: FarmerFeedConsumptionComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FARMER_FEED_CONSUMPTION } },
      { path: 'settings/:idFormTemplate/' + Authorities.FARMER_FEED_CONSUMPTION + '/save', component: EditFarmerFeedConsumptionComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FARMER_FEED_CONSUMPTION } },
      { path: 'settings/:idFormTemplate/' + Authorities.FARMER_FEED_CONSUMPTION + '/save/:id', component: EditFarmerFeedConsumptionComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FARMER_FEED_CONSUMPTION } },
      { path: 'settings/:idFormTemplate/' + Authorities.FARMER_MILK_PRODUCTION + '/search', component: FarmerMilkProductionComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FARMER_MILK_PRODUCTION } },
      { path: 'settings/:idFormTemplate/' + Authorities.FARMER_FEED_CONSUMPTION + '/search', component: FarmerFeedConsumptionComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FARMER_FEED_CONSUMPTION } },
      { path: 'weekly-plan', component: WeeklyPlanManagementComponent },
      { path: 'farmers/sign-up', component: SignUpComponent },
      { path: 'tanks/:key', component: ResultSearchComponent },
      { path: 'mcc/:mccId/:key', component: ResultSearchComponent },
      { path: 'reproduction-report', component: ReproductionReportComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMALS } },
      { path: 'feedback', component: FeedbackComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FEEDBACK } },
      { path: 'shop', component: ShopComponent ,canActivate: [AuthorityGuard], data: { auth: Authorities.ORDERS }},
      { path: 'milk-feed-report', component: MilkFeedReportComponent ,canActivate: [AuthorityGuard], data: { auth: Authorities.REPORTS_FEED_MILK }},
      { path: 'like', component: LikeComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.LIKE } },
      { path: 'setting-graphs', component: SettingsGraphComponent },
      { path: 'milk-graphs', component:MilkCollectedGraphComponent,canActivate: [AuthorityGuard], data: { auth: Authorities.MILK_GRAPH } },
      { path: 'calving-graphs', component:CalvingGraphComponent,canActivate: [AuthorityGuard], data: { auth: Authorities.CALVING_GRAPH } },
      { path: 'animals/:idAnimal/settings/:idFormTemplate/' + Authorities.ANIMAL_MILK_PRODUCTION, component: MilkQuantityCowComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_MILK_PRODUCTION } },
      { path: 'farmers/:idFarmer/animals/:idAnimal/settings/:idFormTemplate/' + Authorities.ANIMAL_MILK_PRODUCTION, component: MilkQuantityCowComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_MILK_PRODUCTION } },
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/animals/:idAnimal/settings/:idFormTemplate/' + Authorities.ANIMAL_MILK_PRODUCTION, component: MilkQuantityCowComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_MILK_PRODUCTION } },
      { path: 'animals/:idAnimal/settings/:idFormTemplate/' + Authorities.ANIMAL_MILK_PRODUCTION + '/save', component: EditMilkQuantityCowComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_MILK_PRODUCTION } },
      { path: 'animals/:idAnimal/settings/:idFormTemplate/' + Authorities.ANIMAL_MILK_PRODUCTION + '/save/:id', component: EditMilkQuantityCowComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_MILK_PRODUCTION } },
      { path: 'farmers/:idFarmer/animals/:idAnimal/settings/:idFormTemplate/' + Authorities.ANIMAL_MILK_PRODUCTION + '/save', component: EditMilkQuantityCowComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_MILK_PRODUCTION } },
      { path: 'farmers/:idFarmer/animals/:idAnimal/settings/:idFormTemplate/' + Authorities.ANIMAL_MILK_PRODUCTION + '/save/:id', component: EditMilkQuantityCowComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_MILK_PRODUCTION }},
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/animals/:idAnimal/settings/:idFormTemplate/' + Authorities.ANIMAL_MILK_PRODUCTION + '/save', component: EditMilkQuantityCowComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_MILK_PRODUCTION }},
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/animals/:idAnimal/settings/:idFormTemplate/' + Authorities.ANIMAL_MILK_PRODUCTION + '/save/:id', component: EditMilkQuantityCowComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_MILK_PRODUCTION }},
      { path: 'supervisors/:idSupervisor/farmers/:idFarmer/animals/:idAnimal/settings/:idFormTemplate/' + Authorities.ANIMAL_MILK_PRODUCTION+ '/search', component: MilkQuantityCowComponent, canActivate: [AuthorityGuard], data: { auth: Authorities. ANIMAL_MILK_PRODUCTION} },
      { path: 'farmers/:idFarmer/animals/:idAnimal/settings/:idFormTemplate/' + Authorities.ANIMAL_MILK_PRODUCTION+ '/search', component: MilkQuantityCowComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_MILK_PRODUCTION } },
      { path: 'animals/:idAnimal/settings/:idFormTemplate/' + Authorities.ANIMAL_MILK_PRODUCTION+ '/search', component: MilkQuantityCowComponent, canActivate: [AuthorityGuard], data: { auth: Authorities. ANIMAL_MILK_PRODUCTION} },
      { path: 'cow-milk-graphs', component:CowMilkGraphComponent,canActivate: [AuthorityGuard], data: { auth: Authorities.ANIMAL_MILK_GRAPH } },
      { path: 'setting-economy', component: EconomyComponent },
      { path: 'setting-economy/settings/:idFormTemplate', component: HistoriqueCardEconomyComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.FORM }},
      { path: 'setting-economy/settings/:idFormTemplate/save', component: EditCardEconomyComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.FORM }},
      { path: 'setting-economy/settings/:idFormTemplate/save/:id', component: EditCardEconomyComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.FORM }},
      { path: 'setting-economy/settings/:idFormTemplate/search', component: HistoriqueCardEconomyComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.FORM }},
      { path: 'stock', component: StockComponent},
      { path: 'stock/feedInventory/:idFormTemplate', component: HistoryStockComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.STOCK }},
      { path: 'stock/feedInventory/:idFormTemplate/save', component: EditStockComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.STOCK }},
      { path: 'stock/feedInventory/:idFormTemplate/save/:id', component: EditStockComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.STOCK }},
      { path: 'stock/feedInventory/:idFormTemplate/search', component: HistoryStockComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.STOCK }},
      { path: 'settings/:idFormTemplate/' + Authorities.FARMER_FEED_CONSUMPTION+'/:idFeed/historyFeedInventory/:title', component: FarmerFeedConsumptionComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FARMER_FEED_CONSUMPTION } },
      { path: 'stock/feedInventory/:idFormTemplate/save/:id/addFeedInventory', component: EditStockComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.STOCK }},
      { path: 'settings/:idFormTemplate/' + Authorities.FARMER_FEED_CONSUMPTION+'/:idFeed/historyMiscInventory/:title', component: FarmerFeedConsumptionComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FARMER_FEED_CONSUMPTION } },
      { path: 'stock/miscInventory/:idFormTemplate', component: HistoryMiscStockComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.STOCK }},
      { path: 'stock/miscInventory/:idFormTemplate/save', component: EditMiscStockComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.STOCK }},
      { path: 'stock/miscInventory/:idFormTemplate/save/:id', component: EditMiscStockComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.STOCK }},
      { path: 'stock/miscInventory/:idFormTemplate/search', component: HistoryMiscStockComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.STOCK }},
      { path: 'stock/miscInventory/:idFormTemplate/save/:id/addMiscInventory', component: EditMiscStockComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.STOCK }},
      { path: 'stock/miscInventory/:idFormTemplate/save/:id/reduceMiscInventory', component: EditMiscStockComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.STOCK }},
      { path: 'stock/miscInventory/:idFormTemplate/save/:id/:quantity/:idStock', component: EditMiscStockComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.STOCK }},
      { path: 'stock/feedInventory/:idFormTemplate/save/:id/:quantity/:idStock', component: EditStockComponent , canActivate: [AuthorityGuard], data: { auth: Authorities.STOCK }},
      { path: 'settings/:idFormTemplate/' + Authorities.FARMER_FEED_CONSUMPTION+'/:idFeed/historyFeedInventory/:title/search', component: FarmerFeedConsumptionComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FARMER_FEED_CONSUMPTION } },
      { path: 'settings/:idFormTemplate/' + Authorities.FARMER_FEED_CONSUMPTION+'/:idFeed/historyMiscInventory/:title/search', component: FarmerFeedConsumptionComponent, canActivate: [AuthorityGuard], data: { auth: Authorities.FARMER_FEED_CONSUMPTION } },
    ]}
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
