<div class="row">
  <div class="col-12">
    <h2 mat-dialog-title [ngClass]="{rtl:appService.rtl()}"> {{ 'NOTES' | translate}}</h2>
  </div>
</div>
<div [ngClass]="{rtl:appService.rtl()}">
  <mat-list [ngClass]="length > 4 ? 'make-scrollable' : ''">
    <!---  <h3 mat-subheader>{{ 'NOTES' | translate }}</h3>-->
    <mat-list-item *ngFor="let note of notes">
      <div style="width: 100%">
        <!-- <mat-icon matListIcon style="position:absolute; padding: 8px;">folder</mat-icon>-->
        <i [ngClass]="appService.rtl()? 'fa fa-folder icon-style-ar' : 'fa fa-folder icon-style'"
          *ngIf="note.type == Type.ADD_NOTE  || note.type == null"></i>
        <i [ngClass]="appService.rtl()? 'fa fa-paw icon-style-ar' : 'fa fa-paw icon-style'"
          *ngIf="note.type == Type.CALVING_DATE"></i>
        <i [ngClass]="appService.rtl()? 'fa fa-thermometer-full icon-style-heat-ar' : 'fa fa-thermometer-full icon-style-heat'"
          *ngIf="note.type == Type.HEAT_DATE"></i>
        <i [ngClass]="appService.rtl()? 'fa fa-syringe icon-style-ar' : 'fa fa-syringe icon-style'"
          *ngIf="note.type == Type.INSE_DATE"></i>
        <img src="/assets/images/icons/milk-tank_g2.svg"
          [ngClass]="appService.rtl()? 'icon-button-style-svg-ar' : 'icon-button-style-svg'"
          *ngIf="note.type == Type.MILK_PROD">
        <i [ngClass]="appService.rtl()? 'fa fa-weight icon-style-ar' : 'fa fa-weight icon-style'"
          *ngIf="note.type == Type.WEIGHT"></i>
        <div [ngClass]="appService.rtl()? 'p-right' : 'p-left'">
          <div class="style-weight" matLine>{{note.customerFullName}}<i
              [ngClass]="appService.rtl()? 'material-icons style-delete-ar pointer' : 'material-icons style-delete pointer'"
              matTooltip="{{ 'DELETE' | translate }}" matTooltipClass="custom-tooltip" (click)="deleteNote(note)" *ngIf="delete">
              delete
            </i></div>
          <div class="style-weight" matLine>{{note.noteDate | date: appService.rtl()? 'dd/MM/yyyy' : 'yyyy/MM/dd'}}
          </div>
        </div>
        <div style="padding-bottom: 5px" [ngClass]="{rtl:appService.rtl()}">
          <span *ngIf="note.type == null; else dateNote">
            {{('NOTE' |translate) + ': '  + note.note}}
          </span>
          <ng-template #dateNote>
            <span *ngIf="note.type == Type.ADD_NOTE; else typeNote">
              {{(('NOTE' |translate)  + ': ' + (note.actionDate | date: appService.rtl()? 'dd/MM/yyyy' : 'yyyy/MM/dd') + (note.note? ( ' -- '+ note.note) : ''))}}
            </span>
            <ng-template #typeNote>
              <span>
                {{((note.type | translate) + ': ' + (note.actionDate | date: appService.rtl()? 'dd/MM/yyyy' : 'yyyy/MM/dd') + (note.note? ( ' -- '+ note.note) : ''))}}
              </span>
            </ng-template>
          </ng-template>

        </div>
        <!--<div style="padding-bottom: 5px">
            {{ (note.type == Type.ADD_NOTE  || note.type == null )?  ( : ((note.type | translate) + ': ' + (note.note | date: appService.rtl()? 'dd/MM/yyyy' : 'yyyy/MM/dd')) }}
          </div>-->
      </div>
      <mat-divider [ngClass]="appService.rtl()? 'padding-ar' : 'padding-fr-en'"></mat-divider>
    </mat-list-item>

    <mat-list-item *ngIf="length == 0" [ngClass]="{rtl:appService.rtl()}">
      <mat-icon mat-list-icon>folder_open</mat-icon>
      <div mat-line [ngClass]="appService.rtl()? 'icon-style-no-note-ar' : 'icon-style-no-note'">
        {{ 'NO_NOTES' | translate}}</div>
    </mat-list-item>
  </mat-list>
</div>