import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AnimalService } from 'projects/shared/services/animal.service';
import { AppService } from 'projects/shared/services/app.service';
import { LocalNotificationService } from 'projects/shared/services/local-notification.service';
import { TimelineChildService } from 'projects/shared/services/timeline-child.service';
import { TimelineService } from 'projects/shared/services/timeline.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { HelpComponent } from '../../menu/help/help.component';
import { CustomRouter } from '../../services/custom-router';
import { EditTimelineComponent } from '../edit-timeline/edit-timeline.component';

@Component({
  selector: 'app-timeline-information',
  templateUrl: './timeline-information.component.html',
  styleUrls: ['./timeline-information.component.scss']
})
export class TimelineInformationComponent implements OnInit {
  @ViewChild(HelpComponent, { static: false }) help: HelpComponent;
  constructor(public router: CustomRouter, public tokenManagementService: TokenManagementService, public dialogRef: MatDialogRef<EditTimelineComponent>, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, public appService: AppService, public animalService: AnimalService, public codeTranslate: CodeTranslatePipe,
    public translate: TranslateService) { }

  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close()
  }
  openLink(url: string) {
    window.open(url, "_blank");
    this.dialogRef.disableClose;
  }


}