<div class="dialog-size-mob">
    <div class="row">
        <div class="col">
            <div class="text-center title-style-dialog"><span [ngClass]="{rtl:appService.rtl()}"
                    [ngStyle]="{'color': ((data.title == ('WARNING' | translate))? 'red' : '')}">{{data.title}}</span>
            </div>
        </div>
    </div>
    <div class="row" [ngClass]="{rtl:appService.rtl()}">
        <div class="col mt-3 mb-3">
            <span class="message-style center-element" [ngClass]="{rtl:appService.rtl()}">{{data.message}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="center-element">
                 <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="warn"
                    style="margin-right: 5px" class="button-dialog   text-center" mat-dialog-close> <span
                        [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'"
                        class="center-element" (click)="action(refused)">{{ 'REFUSE' | translate }} </span></button>
                <button [ngClass]="{rtl:appService.rtl()}" class="button-dialog button-text-size text-center"
                    mat-raised-button color="primary" ><span
                        [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'"
                        class="center-element" (click)="action(accepted)">{{ 'ACCEPT' | translate }} </span></button>
            </div>
        </div>
    </div>
</div>
