import { Injectable, Injector } from '@angular/core';
import { AnimalNote } from '../resources/AnimalNote';
import { MoomeRestService } from './moome-rest.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
@Injectable({
  providedIn: 'root'
})
export class AnimalNoteService extends MoomeRestService<AnimalNote> {

  constructor(public http: HttpClient, injector: Injector, private configService: ConfigService) {
    super(AnimalNote);
  }

  findAnimalNotes(id, farmerId?) {
    if (farmerId == null)
      farmerId = ""
    return this.customRequestGet(`/findByAnimal?id=${id}&projection=animalNotes&farmerId=${farmerId}`)
  }

  findByIdNote(id) {
    return this.searchSingle(`findByIdNote?id=${id}`)
  }

  saveAnimalNote(body) {
    return this.http.post(this.configService.getApiPath() + `/animalNotes/save`, body);
  }
  
  findAnimalNotesSearch( id , farmerId, key) {
    return this.search(`findByKeySearch?id=${id}&farmerId=${farmerId}&key=${key.toLowerCase()}&projection=animalNotes`)
  }
}
