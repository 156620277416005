<div class="style-bar w-100">
    <div class="d-flex justify-content-between">
        <div class=" mt-4 pt-1 ms-4" (click)="closePopup();((router.url == '/menu') ? home() :router.navigateByUrl('/menu'))">
            <img src="/assets/images/menu.svg" >
        </div>
        <div class="text-center mt-2 pt-2">
            <img *ngIf="router.url != '/menu'" src="/assets/images/logo-header.svg" style="height: 62px"
                (click)="closePopup();router.navigateByUrl('/home')" >
        </div>
        <div class=" mt-4 pt-2 me-4">
        <div class="row">
            <div class="float-end col">
                <img src="{{(router.url == '/menu') ? '/assets/images/Union2.svg' : '/assets/images/search.svg'}} " (click)="closePopup();functionSearch()" >
            </div>
        </div>
         <!--<div class="row" *ngIf=" role">
            <div class="float-end col">
            <img src="{{(router.url == '/menu') ? '' : '/assets/noun-advanced-search.svg'}} " (click)="closePopup();functionSearchAdvanced()">
        </div>
    </div>-->
        </div>
    </div>
</div>

<div [ngClass]="appService.verif? 'd-block position-style' : 'd-none position-style' ">
    <app-search (searchWithKey)="searchElements($event)" ></app-search>
</div>
<!--<div [ngClass]="appService.advancedSearch? 'd-block position-style' : 'd-none position-style' ">
    <app-search (searchWithKey)="advancedSearchElements($event)" ></app-search>
</div>-->