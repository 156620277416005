
import { Component, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'projects/shared/services/app.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  serchTimeout: any;
  formGroup: UntypedFormGroup;
  @Output()
  searchWithKey: EventEmitter<any> = new EventEmitter();
  @ViewChild('searchkey', { static: true }) searchkey: any;
  //isAdvancedSearch;
  auth: string[] = [];

  constructor(public appService: AppService, private formBuilder: UntypedFormBuilder, public router: Router) {
    
   }

  ngOnInit() {
    
    /*if(this.appService.advancedSearch){
      console.log(this.appService.advancedSearch)
       this.appService.searchText = 'search_by_farmer_settings'
    }
  
    else{
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if(this.auth.indexOf("true") != -1) {
        this.appService.searchText = 'search_by_uid_name_phone'
       
      }
      else {
       this.appService.searchText = 'search_by_uid'
      }
    }*/
    this.createForm();
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'key': [''],
    });
  }

  search(key) {
    let cleanKey = key.replace(/\s/g,'')
    if(key && cleanKey != ""){
    this.searchWithKey.emit(key)
    setTimeout(() => {
      this.formGroup.controls['key'].setValue('')
    }, 1000);
  }
  }

}