import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { Farmer } from "./Farmer";
@HateoasResource('farmerStockFeeds')
export class FarmerStockFeed extends Resource {
    id: number;
    farmer: Farmer | any;
    date: string;
    quantity: string;
    title: string;
    unit:string;
    dryMatter:number
    crudProteine:number
}