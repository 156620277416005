import {Component, Input, OnInit} from '@angular/core';
import {AppService} from 'projects/shared/services/app.service';
import {TranslateService} from '@ngx-translate/core';
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';
import * as moment from 'moment';
import {ReportService} from 'projects/shared/services/report.service';
import {Alert, RUMINATION, SITTING/*, OFFSET_RUMINATION*/} from 'projects/shared/tools/constants';
import {AlertsService} from 'projects/shared/services/alerts.service';
import * as FileSaver from 'file-saver';
import { formatDate } from '@angular/common';
import { CapacitorUtils } from 'projects/shared/tools/CapacitorUtils';

@Component({
  selector: 'app-rumination-ui',
  templateUrl: './rumination-ui.component.html',
  styleUrls: ['./rumination-ui.component.scss']
})
export class RuminationUiComponent implements OnInit {

  displaygraph = false;
  barChart: Chart;

  dayTime: any[];
  status: Number[] = [];
  @Input() formGroup
  startDate;
  endDate;
  code;
  periodValue
  title: string;
  statusPeriod: any;
  dataOfDatasets: any[] = []
  unit: any
  percentages: any[] = []
  diffDay
  times1: any[];
  times2: any[];
  //offset: any = OFFSET_RUMINATION;
  dataOfResting: any[] = []
  diffTimezone
  constructor(private translateService: TranslateService, public appService: AppService, public reportService: ReportService, private alertsService: AlertsService, public translate: TranslateService) {
    Chart.register(
      ArcElement,
      LineElement,
      BarElement,
      PointElement,
      BarController,
      BubbleController,
      DoughnutController,
      LineController,
      PieController,
      PolarAreaController,
      RadarController,
      ScatterController,
      CategoryScale,
      LinearScale,
      LogarithmicScale,
      RadialLinearScale,
      TimeScale,
      TimeSeriesScale,
      Decimation,
      Filler,
      Legend,
      Title,
      Tooltip,
      SubTitle
    );
  }

  ngOnInit() {
   // this.diffTimezone = new Date().getTimezoneOffset()

  }

  extractData(){
    this.periodValue = this.formGroup.controls['period'].value
    this.startDate = formatDate(this.formGroup.controls['startDate'].value,'yyyy-MM-dd','en_UTC');
    this.endDate = formatDate(this.formGroup.controls['endDate'].value,'yyyy-MM-dd','en_UTC');
    this.code = this.formGroup.controls['code'].value;
    this.setPeriod();
   // this.code.uuid = "90c147d2-4b61-4b49-b731-e2825df46bca"

    this.diffDay = moment(this.endDate).diff(moment(this.startDate), 'days')
    if (this.diffDay <= 2)
      this.unit = 'YYYY/MM/DD HH:mm';
    else if (this.diffDay > 2 && this.diffDay <= 60)
      this.unit = 'YYYY/MM/DD';
    else if (this.diffDay > 60)
      this.unit = 'YYYY/MM'
  }

  apply() {
    if (this.barChart) {
      this.barChart.destroy();
      this.displaygraph = false;
    }
    this.extractData();
    this.appService.showLoading();

    this.reportService.displayRuminationUi(this.code.uuid, RUMINATION,this.startDate, this.endDate, SITTING).subscribe(data => {    
      this.dayTime = [];
      this.dataOfDatasets = [];
      this.percentages = [];
      this.times1 = [];
      this.times2 = [];
      this.dataOfResting= [];
      let keys = Object.keys(data);
      keys.forEach(key => {
        let element = data[key]
        if (element != null) {
          let k = Object.keys(element[0])
          k.forEach(ele => {

            if (this.dayTime.indexOf(ele) < 0)
              this.dayTime.push(ele)
          })
        }
      })
      this.dayTime.sort()
      this.dayTime.forEach((day, index) => {
        if(data["ruminationDataMap"])
        this.dataOfDatasets.push(data["ruminationDataMap"][0][day] ? data["ruminationDataMap"][0][day] : 0)
        if(data["availableDataMap"])
        this.percentages.push(data["availableDataMap"][0][day] ? data["availableDataMap"][0][day] : 0)
        if(data["restingDataMap"])
        this.dataOfResting.push(data["restingDataMap"][0][day] ? data["restingDataMap"][0][day] : 0)
       this.dayTime[index] = moment(day).format(this.unit)
      })


      this.displaygraph = true;
      this.appService.hideLoading();
let datasets:any[]= [
  {
      label: this.translateService.instant('RUMINATING_TIME') + " ",
      backgroundColor: '#80cc28',
      data: this.dataOfDatasets,
      percentage: this.percentages,
     
  },
  {
      label: this.translateService.instant('RESTING_TIME') + " ",
      backgroundColor:  '#316a90',
      data: this.dataOfResting,
      percentage: this.percentages,
  },
  
]
      this.barChart = new Chart('barChart', {
        type: 'bar',
        data: {
          labels: this.dayTime,
          datasets:  datasets
         
        },

        options: {
         
          scales: {
            y: {
                beginAtZero: true,
            },

          },
          responsive: false,
          plugins: {
            legend: {
              display: true
            },
            title: {
              display: true,
              text: this.title
  
            },
          }
       ,
        

        }
      })
      
      Chart.register({
        id : 'p2' ,afterDatasetsDraw: function (chart, easing) {
         
          var ctx = chart.ctx;

          chart.data.datasets.forEach(function (dataset, i) {
            var meta = chart.getDatasetMeta(i);
            if (!meta.hidden) {
              meta.data.forEach(function (element, index) {
                var fontSize = 16;
                if (dataset['percentage'][index] < 80)
                 var dataString = dataset['percentage'][index].toString() + '%';
                else
                  var dataString = ''
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';

                var padding = 5;
                var position = element.tooltipPosition();
                ctx.fillText(dataString, position.x, position.y - (fontSize / 2) - padding);
              });
            }
          });
        }
      });
    }, error => {

      this.appService.hideLoading();
      let message = this.translate.instant(error.error.code);
      let alert = this.translate.instant(Alert.OK)
      this.alertsService.open(message, alert);
    })
  }

  setPeriod() {
    if (this.periodValue != null) {
      let start
     /* if (this.periodValue == "3_HOURS")
        start = moment().subtract(3, 'hours');

      if (this.periodValue == "6_HOURS")
        start = moment().subtract(6, 'hours');*/

      if (this.periodValue == "1_DAY")
        start = moment().subtract(1, 'days');

      if (this.periodValue == "3_DAYS")
        start = moment().subtract(3, 'days');

      if (this.periodValue == "1_WEEK")
        start = moment().subtract(1, 'weeks');

      if (this.periodValue == "1_MONTH")
        start = moment().subtract(1, 'months');
start.set("hour", 0).set("minute", 0).set("second", 0);
      /*if (this.formGroup.controls['period'].value == "3_MONTHS")
                start = moment().subtract(3, 'months');*/
      this.title = moment(start).format("MM/DD/YYYY") + " 06:00"+ " -- "
        +   moment().format("MM/DD/YYYY HH") + ":00";
        //+ moment().format("MM/DD/YYYY HH:mm:ss");
        
      this.startDate = start.toISOString();
      this.endDate = moment().toISOString();
    } else {
      
      this.title = moment(this.startDate).format("MM/DD/YYYY") + " 06H00" + " -- "
        + moment(this.endDate).add(24, 'hours').format("MM/DD/YYYY") + " 06H00";
      this.startDate = moment(this.startDate).toISOString();
      this.endDate = moment(this.endDate).add(24, 'hours').toISOString();

    }
  }
 
  exportCsv(){
    
    this.extractData()

    this.reportService.displayRuminationUiAsCsv(this.code.uuid, "RUMINATION", this.startDate, this.endDate,"SITTING").subscribe(res => {
      let blob = new Blob([res], { type: 'text/csv; charset=utf-8'}); 
      let type=".csv"
      let filename = this.translate.instant('RUMINATION_UI')  + moment(new Date()).format('yyyy-MM-DD HH:mm') 
      if (CapacitorUtils.isApp){
        CapacitorUtils.writeAndOpenFile(blob, filename+type );
        this.appService.hideLoading();
        let message = this.translate.instant('SAVE_FILE_SUCESS');
        this.alertsService.open(message);}
      else{
      this.appService.saveAs(blob, filename, type);
      this.appService.hideLoading();}
    }, error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.code);
      let alert = this.translate.instant(Alert.OK)
      this.alertsService.open(message, alert);
    })}
  

}
