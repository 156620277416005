import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditNoteComponent } from './edit-note.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../material/material.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { AnimalBarModule } from '../../animals/animal-bar/animal-bar.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
@NgModule({
  declarations: [EditNoteComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    AnimalBarModule,
    FormsModule,
    IonicModule,
  ],
  exports:[
    EditNoteComponent
  ]
})
export class EditNoteModule { }
