import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class FarmerSupervisorRequestService {
  constructor(public http: HttpClient, private configService: ConfigService,) { }

  saveRequest(phone) {
		return this.http.post(this.configService.getApiPath() + `/farmerSupervisorRequests/saveFarmerSupervisorRequest`, phone);
	}

  updateStatus(id, status) {
		return this.http.put(this.configService.getApiPath() + `/farmerSupervisorRequests/${id}/updateStatus/${status}`,status);
	}
 /* getByStatusAndConnected(){
    return this.http.get(this.configService.getApiPath() + `/farmerSupervisorRequests/getByStatusAndConnected`)
  }*/
}
