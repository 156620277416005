import { Component, OnInit } from '@angular/core';
import { CustomRouter } from '../services/custom-router';
import { AppService } from 'projects/shared/services/app.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GroupCode } from 'projects/shared/tools/constants';
import { FarmerMilkProductionService } from 'projects/shared/services/farmer-milk-production.service';
import * as moment from 'moment';
@Component({
  selector: 'app-milk-production',
  templateUrl: './milk-production.component.html',
  styleUrls: ['./milk-production.component.scss']
})
export class MilkProductionComponent implements OnInit {
  list: any[] = []
  greenCard: any
  bar: any;
  formatArabe: any;
  formGroup: UntypedFormGroup;
  listGroups
  startDate
  endDate
  totalQuantity
  percentMilkSoldQuantity
  totalQuantityPerDay
  totalQuantityPerDayPerCow
  availableData
  constructor(public farmerMilkProductionService: FarmerMilkProductionService, private formBuilder: UntypedFormBuilder, public tokenManagementService: TokenManagementService, public router: CustomRouter, public appService: AppService) {

  }
  ngOnInit() {
    this.formatArabe = this.appService.verifFormatDate()
    this.createForm()
    this.list = [
      { value1: "", value2: "LITERS", label: "TOTAL_MILK_PROD", class: "fullBleuGreen-cercle", value1Font: "milk-prod-value1-font", value2Font: "milk-prod-value2-fon", labelFont: "milk-pro-blueGreen-label-font" },
      { value1: "", value2: "%", label: "AVAILABLE_DATA", class: "fullBleuGreen-cercle", value1Font: "milk-prod-value1-font", value2Font: "milk-prod-value2-fon", labelFont: "milk-pro-blueGreen-label-font" },
      { value1: "", value2: "LITRES_DAY", label: "AVG_MILK_DAY", class: "blueGreen-cercle", value1Font: "milk-prod-value1-font", value2Font: "milk-prod-value2-fon", labelFont: "milk-pro-blueGreen-label-font" },
      { value1: "", value2: "LITRES_DAY_COW", label: "AVG_MILK_DAY_COW", class: "blueGreen-cercle", value1Font: "milk-prod-value1-font", value2Font: "milk-prod-value2-fon", labelFont: "milk-pro-blueGreen-label-font" },
      { value1: "", value2: "%", label: "TOTAL_MILK_SOLD_PERC", class: "blueGreen-cercle", value1Font: "milk-prod-value1-font", value2Font: "milk-prod-value2-fon", labelFont: "milk-pro-blueGreen-label-font" },

    ]
    this.bar = { title: "MILK_PRODUCTION", arrowIcon: "/assets/images/left-arrow.svg", color: "#f6f7fb", fontColor: "#06AF8F", router: "/dashboard" }
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      'startDate': [null, [Validators.required]],
      'endDate': [null, [Validators.required]]
    });
  }

  back() {
    this.router.navigateByUrl('/dashboard')
  }
  fixArabeFormat() {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD/MMM/YYYY"
  }

  countByDateAndGroup() {
    this.listGroups = []
    this.listGroups.push(GroupCode.P_Pregnant_CODE,GroupCode.PINS_CODE,GroupCode.PNOTINS_CODE,GroupCode.P_Negative_Pregnancy_CODE)
    if (this.formGroup.controls['startDate'].value && this.formGroup.controls['endDate'].value) {
      this.startDate = moment(this.formGroup.controls['startDate'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format('yyyy-MM-DD')
      this.endDate = moment(this.formGroup.controls['endDate'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format('yyyy-MM-DD')
      const date1 = (new Date(this.startDate)).getTime();
      const date2 = (new Date(this.endDate)).getTime();
      if (date1 > date2)
        this.appService.error('ERROR_DATE_FIELD');
      else {
        this.farmerMilkProductionService.countByDateAndGroup(this.startDate, this.endDate, this.listGroups).subscribe(data => {
          this.totalQuantity = data["totalQuantity"]
        this.availableData=data["availableData"]? data["availableData"]:'--';
        this.percentMilkSoldQuantity =data["percentMilkSoldQuantity"]? data["percentMilkSoldQuantity"]:'--';
        this.totalQuantityPerDay = data["totalQuantityPerDay"] ? data["totalQuantityPerDay"]:'--';
        this.totalQuantityPerDayPerCow = data["totalQuantityPerDayPerCow"] ? data["totalQuantityPerDayPerCow"]:'--'  ;
          if (this.totalQuantity || this.percentMilkSoldQuantity || this.totalQuantityPerDay || this.totalQuantityPerDayPerCow) {
            this.list = [
              { value1: this.totalQuantity, value2: "LITERS", label: "TOTAL_MILK_PROD", class: "fullBleuGreen-cercle", value1Font: "milk-prod-value1-font", value2Font: "milk-prod-value2-fon", labelFont: "milk-pro-blueGreen-label-font" },
              { value1: this.availableData, value2: "%", label: "AVAILABLE_DATA", class: "fullBleuGreen-cercle", value1Font: "milk-prod-value1-font", value2Font: "milk-prod-value2-fon", labelFont: "milk-pro-blueGreen-label-font" },
              { value1: this.totalQuantityPerDay, value2: "LITRES_DAY", label: "AVG_MILK_DAY", class: "blueGreen-cercle", value1Font: "milk-prod-value1-font", value2Font: "milk-prod-value2-fon", labelFont: "milk-pro-blueGreen-label-font" },
              { value1: this.totalQuantityPerDayPerCow, value2: "LITRES_DAY_COW", label: "AVG_MILK_DAY_COW", class: "blueGreen-cercle", value1Font: "milk-prod-value1-font", value2Font: "milk-prod-value2-fon", labelFont: "milk-pro-blueGreen-label-font" },
              { value1: this.percentMilkSoldQuantity, value2: "%", label: "TOTAL_MILK_SOLD_PERC", class: "blueGreen-cercle", value1Font: "milk-prod-value1-font", value2Font: "milk-prod-value2-fon", labelFont: "milk-pro-blueGreen-label-font" },

            ]
            this.bar = { title: "MILK_PRODUCTION", arrowIcon: "/assets/images/left-arrow.svg", color: "#f6f7fb", fontColor: "#06AF8F", router: "/dashboard" }
          }
          else
            this.appService.error('HEALTH_REQUIRED');
        })

      }
    }
  }
}
