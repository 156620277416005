import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { Alert, Status, labelStep, Step, Authorities, LivestockIdentif } from 'projects/shared/tools/constants';
import { TranslateService } from '@ngx-translate/core';
import { AnimalService } from 'projects/shared/services/animal.service';
import { ActivatedRoute } from '@angular/router';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { Location } from '@angular/common';
import { TimelineService } from 'projects/shared/services/timeline.service';
import { CommonDialogComponent } from '../common-dialog/common-dialog.component';
import { EditTimelineComponent } from './edit-timeline/edit-timeline.component';
import * as moment from 'moment'
import { CustomRouter } from '../services/custom-router';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { TimelineInformationComponent } from './timeline-information/timeline-information.component';

@Component({
  selector: 'app-timelines',
  templateUrl: './timelines.component.html',
  styleUrls: ['./timelines.component.scss']
})
export class TimelinesComponent implements OnInit {

  id: string
  uid: String;
  code: String;
  lactNum: number;
  timelines = [];
  timeline;
  timelineDetails = [];
  display = false;
  counter = 0
  status = Status
  currentState
  intervalLabels = []
  distance: number = 90;
  @ViewChild("tagRef", { static: false }) tagRef: ElementRef;
  @ViewChild("buttonRef", { static: false }) buttonRef: MatButton;
  fixedWidthButton: String;
  fixedWidthTag: String;
  max
  intervalLabelsPositions = []
  abortExists = false;
  positifExists = false;
  isNextExist = false;
  isLastExist = false;
  showCurrentState = true;
  age
  colorGroup: String
  authorityAnimals = Authorities.ANIMALS;
  authorityTimelines = Authorities.TIMELINES;
  animal
  birthDate
  name: any
  nationalIdentification
  nameLocalCode
  today
  animals = [];
  farmerId
  constructor(public appService: AppService, public translate: TranslateService, public dialog: MatDialog, public timelineService: TimelineService,
    private router: CustomRouter, private route: ActivatedRoute, public animalService: AnimalService, private location: Location, private alertsService: AlertsService) { }

  ngOnInit() {
    this.today = moment().format(this.appService.rtl() ? 'yyyy/MM/DD' : 'DD/MM/yyyy')

    this.id = this.route.snapshot.paramMap.get('id');
    this.farmerId = this.route.snapshot.paramMap.get('idFarmer');
    this.findAnimal(this.id)
    this.loadTimelineByAnimalId(this.id);
    this.nationalIdentification = LivestockIdentif.NATIONAL_IDENTIFICATION
    this.nameLocalCode = LivestockIdentif.NAME_LOCALCODE
    this.loadCountTimeline(this.farmerId);



  }
  help() {
    const dialogRef = this.dialog.open(TimelineInformationComponent, {
    });
  }

  findAnimal(id) {
    let projection: string = "animal-details4"
    this.animalService.getAnimalById(parseInt(id), projection).subscribe(res => {
      this.animal = res
      this.uid = res.uid
      this.code = res.code
      this.lactNum = res.lactNum
      this.birthDate = res.birthDate
      this.name = res.name
      this.age = this.calcAge(res.birthDate)
      if (res.customerAnimalGroups && res.customerAnimalGroups.length > 0) {
        res.customerAnimalGroups.forEach(element => {
          if (element.color != null)
            this.colorGroup = element.color
        });
      }
    })
    error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
      this.alertsService.open(message, alert);
    }
  }

  calcAge(birdhDate) {
    if (birdhDate)
      return moment().diff(moment(birdhDate), "days")
  }
  loadCountTimeline(farmerId?) {

    this.timelineService.findCountTimeline(farmerId).subscribe(res => {
      if (res == true)
        this.help()
    })
    error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
      this.alertsService.open(message, alert);
    }
  }
  loadTimelineByAnimalId(id) {
    this.appService.showLoading();
    this.timelineService.getTimelineByAnimal(id).subscribe(data => {
      if (data['resources'].length > 1)
        this.isNextExist = true
      else
        this.isNextExist = false
      this.timelines = data['resources']
      this.timeline = data['resources'][this.counter]
      this.timelineDetails = this.timeline.timelineDetails
      this.calculAndDisplay()
      this.display = true
      this.appService.hideLoading();
    }, error => {
      this.error(error.error.error)
      let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
      this.alertsService.open(message, alert);
    })
  }


  backToAnimalList = () => {
    this.router.back("/home")
  }

  last() {
    if (this.counter >= 0 && this.counter < (this.timelines.length - 1)) {


      this.counter++
      this.timeline = this.timelines[this.counter]
      this.timelineDetails = this.timeline.timelineDetails
      this.calculAndDisplay()

      if (this.counter == 0) {
        this.showCurrentState = true;
        this.isLastExist = true
        this.isNextExist = false
      }
      else {
        this.showCurrentState = false;
        this.isLastExist = true
        this.isNextExist = true
        if (this.counter == (this.timelines.length - 1)) {
          this.isLastExist = true
          this.isNextExist = false
        }
      }
    }
  }

  next() {
    if (this.counter > 0) {
      /* if(this.timeline.abortExists)
       this.lactNum = this.timeline.lactationNum*/
      this.counter--
      this.timeline = this.timelines[this.counter]
      this.timelineDetails = this.timeline.timelineDetails
      this.calculAndDisplay()

      if (this.counter == 0) {
        this.showCurrentState = true;
        this.isLastExist = false
        this.isNextExist = true
      }
      else {
        this.showCurrentState = false;
        this.isLastExist = true
        this.isNextExist = true
        if (this.counter == this.lactNum) {
          this.isLastExist = true
          this.isNextExist = false
        }
      }
    }
  }

  calculAndDisplay() {
    this.generateIntervalsLabels(this.timelineDetails)
    this.styleEvent(this.timelineDetails)
    this.calcPosition(this.timelineDetails);
    this.fixButton()
    this.currentState = { label: moment().toDate(), nbreDay: this.getCurrentStateNbreDay(this.timelineDetails) }
  }

  generateIntervalsLabels(timelineDetails) {
    timelineDetails.sort((a, b) => {
      return a.nbreDay - b.nbreDay
    })

    this.intervalLabels = []
    for (var j = 0; j < timelineDetails.length - 1; j++) {
      if (timelineDetails[j].label != labelStep.C2) {
        this.intervalLabels.push((timelineDetails[j + 1].nbreDay - timelineDetails[j].nbreDay) + " " + this.translate.instant("DAYS2"))
      } else {
        this.intervalLabels.push("");
      }
    }
  }

  styleEvent(timelineDetails) {

    timelineDetails.forEach(el => {
      if (el) {
        el['style'] = Step.styleApp(el.status)
        el['style'].num = Step.numAi(el)
        this.timeline.end = Step.isEnd(el.status)

        if (el.status == Status.X_ACCESSIBLE) {
          this.timeline.abortExists = true;
        }
        if (el.status == Status.IA_POSITIVE) {
          this.timeline.positifExists = true
        }
      }
    });

  }

  calcPosition(timelineDetails) {
    this.max = (timelineDetails.length - 1) * this.distance
    let nbrDayEqui = 0
    timelineDetails.forEach(element => {
      element['position'] = (nbrDayEqui / this.max) * 100;
      element['color'] = Step.styleColorDate(element.status)
      nbrDayEqui = nbrDayEqui + this.distance
    });
    this.intervalLabelsPositions = []
    for (var i = 0; i < timelineDetails.length - 1; i++) {
      this.intervalLabelsPositions.push((timelineDetails[i + 1].position + timelineDetails[i].position) / 2)
    }
  }

  getCurrentStateNbreDay(timelineDetails) {
    let time = moment().toDate();
    time.setHours(0);
    time.setMinutes(0);
    time.setSeconds(0);
    time.setMilliseconds(0);

    let nbreDay = 0
    if (moment(timelineDetails[0].date).isAfter(time)) {
      nbreDay = 0;
      return nbreDay
    }
    else if (moment(timelineDetails[timelineDetails.length - 1].date).isSame(time)) {
      nbreDay = this.max
      return nbreDay
    }
    else if (moment(timelineDetails[timelineDetails.length - 1].date ? timelineDetails[timelineDetails.length - 1].date : timelineDetails[timelineDetails.length - 2].date).isBefore(moment())) {
      nbreDay = this.max + this.distance / 1.5;
      return nbreDay
    }

    else {
      for (var i = 0; i < timelineDetails.length - 1; i++) {
        if (moment(timelineDetails[i].date).isBefore(time))
          nbreDay = (this.distance * i) + (this.distance / 1.7)
        else if (moment(timelineDetails[i].date).isSame(time)) {
          nbreDay = (this.distance * i)
          timelineDetails[i].show = false
        }
      }
      return nbreDay
    }

  }


  fixButton() {
    setTimeout(() => {
      this.fixedWidthTag = (this.tagRef.nativeElement.offsetWidth) / 2 + 'px';
      this.fixedWidthButton = (this.buttonRef._elementRef.nativeElement.offsetWidth) / 2 + 'px';
      if (this.currentState && this.showCurrentState)
        this.currentState['position'] = this.currentState.nbreDay * 100 / this.max
    }, 20);
  }

  timelineEventClick(timeline, timelineDetail) {
    if (Step.isEditable(timelineDetail.status)) {
      let minList = timeline.timelineDetails.filter(detail =>
        moment(detail.date).isBefore(timelineDetail.date)
      )
      let dateCalveActually= (timeline.timelineDetails.filter(detail=>
        detail.status==Status.GIVE_BIRTH_END))[0]?.date;
      let dateCalve=moment(dateCalveActually).subtract(1, 'days');
      let minDate
      let lastDate
      let minLabel: String
      if (minList.length > 0) {
        minDate = minList[0].date
        minLabel = minList[0].label
        lastDate = minList[minList.length - 1].date

        minList.forEach(element => {
          if (element.date > minDate) {
            minDate = element.date
            minLabel = element.label
          }

        })
      }
      let moments = []
      let list = minList.filter(detail => Step.isIa(detail.status))
      moments = (list).map(function (detail) {
        return moment(detail.date);
      });
      let maxDateIA = moment.max(moments)

      if (timelineDetail.status == Status.X_NO_ACCESSIBLE)
        timeline.timelineDetails.forEach(element => {
          if (element.status == Status.IA_POSITIVE) {
            minDate = element.date
            minLabel = element.label
          }
        })
      this.eventTimelineDetail(timeline, timelineDetail, minDate, minLabel, lastDate, maxDateIA,dateCalve)
    }
    else {

      /*if (Step.isNoEditable(timelineDetail.status))
        this.error(Step.isNoEditableMessage(timelineDetail.status, this.translate))*/
    }

  }
  eventTimelineDetail(timeline, timelineDetail, minDate, minLabel, lastDate, maxDateIA,dateCalve) {

    if ((timelineDetail.status == Status.IA_WAITING || timelineDetail.status == Status.IA_START) && this.lactNum == 0 && this.age <= 450) {
      let title = this.translate.instant('WARNING')
      let message = this.translate.instant('AGE_LESS_15_MONTHS')

      const dialogRef = this.dialog.open(CommonDialogComponent, {
        width: '350px',
        data: {
          message,
          title,
          onConfirm: () => {
            this.timelineDialog(timeline, timelineDetail, minDate, minLabel, lastDate, maxDateIA,dateCalve)
          }
        }
      });
    }

    else
      this.timelineDialog(timeline, timelineDetail, minDate, minLabel, lastDate, maxDateIA,dateCalve)

  }

  timelineDialog(timeline, timelineDetail, minDate, minLabel, lastDate, maxDateIA,dateCalve) {
    if (this.animal.customerAnimals.length > 0 && this.animal.customerAnimals[0].customer.id == this.appService.connectedCustomer.id)
      this.error('DISABLED_ANIMAL')
    else {
      const dialogRef = this.dialog.open(EditTimelineComponent, {
        width: '400px',
        data: {
          farmerId: this.farmerId,
          timeline: timeline,
          timelineDetail: timelineDetail,
          minDate: minDate,
          minLabel: minLabel,
          lastDate: lastDate,
          maxDateIA: maxDateIA,
          birthDate: this.birthDate,
          animal: this.animal,
          dateCalve:dateCalve,
          reloadData: () => {
            this.done(this.translate.instant('SAVE_SUCESS'))

          }
        }
      });
       }
    }

    delete (timeline) {
      let title = this.translate.instant('WARNING')
      let message1 = this.translate.instant('CONFIRM_TIMELINE_DELETION1')
      let message2 = this.translate.instant('CONFIRM_TIMELINE_DELETION2')
     if (this.animal.customerAnimals.length > 0) {
         this.error('DISABLED_ANIMAL')
       }
       else {
      const dialogRef = this.dialog.open(CommonDialogComponent, {
        width: '350px',
        data: {
          message1,
          title,
          onConfirm: () => {
            const dialogRef = this.dialog.open(CommonDialogComponent, {
              width: '350px',
              data: {
                message2,
                title,
                onConfirm: () => {
                  this.appService.showLoading()
                  this.timelineService.delete(timeline).subscribe(data => {
                    this.done(this.translate.instant('DELETED'))
                  }
                    , error => {
                      this.error(error.error.code)
                    });
                }
              }
            });
          }
        }
      });
   }
    }

    done(message) {
      this.appService.hideLoading();
      this.showCurrentState = true;
      //this.counter = this.counter - 1
      this.counter = 0
      this.isLastExist = false
      setTimeout(() => {
        this.findAnimal(this.id)
      }, 400);
      this.loadTimelineByAnimalId(this.id)
      this.alertsService.open(message);
    }

    error(message) {
      this.appService.hideLoading();
      let alert = this.translate.instant(Alert.OK)
      this.alertsService.open(this.translate.instant(message), alert);
    }
  }


