import { Group } from "../resources/group";
import { Injectable, Injector } from '@angular/core';
import { MoomeRestService, Options } from './moome-rest.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from "./config.service";

@Injectable({
	providedIn: 'root'
})
export class GroupService extends MoomeRestService<Group> {

	constructor(public http: HttpClient, injector: Injector, private configService: ConfigService) {
		super(Group);
	}

	getGroupsOfCustomer(farmerId?) {
			if (farmerId == null)
				farmerId = ""
		return this.customRequestGet(`/find?projection=groups&farmer=${farmerId}`)
	}

	getGroupsOfCustomerInPublic(farmerId?) {
		if (farmerId == null)
			farmerId = ""

	return this.customRequestGet(`/find-public?projection=groups2&farmer=${farmerId}`)
}


	deleteGroup(id) {
		return this.http.delete(this.configService.getApiPath() + `/groups/${id}/delete`)
	}

	isHasAnimals(id) {
		return this.http.get(this.configService.getApiPath() + `/groups/${id}/hasAnimals`);
	}

	/*detachAnimal(customerId, animalId, groupId) {
		return this.http.delete(this.configService.getApiPath() + `/groups/${groupId}/animal/${animalId}/customer/${customerId}`)
	}*/

	/*detachAnimal(animalId, groupId) {
		return this.http.delete(this.configService.getApiPath() + `/groups/${groupId}/animal/${animalId}`)
	}*/

	getGroupsWithFilter(page, size, field, direction, key) {
		
		let param: any =
		{
			projection: 'groups',
			
		}
		if (key != null)
			param.key = key.toLowerCase();
			if (field != null)
			param.field = field;
			if (direction != null)
			param.direction = direction.toUpperCase();


		let options: Options = {
			params:
				param,
			pageParams:{
				page:page,
				size:size
			}
			
		}
		
		return this.searchPages(`getGroupsByKey`,options)
	}

	getCustomerAnimalGroups(groupId) {
		return this.customRequestGet(`/findByGroup?projection=customeAnimal&groupId=${groupId}`)
	}

	saveGroupWithChild(group){
		return this.customRequestPost(`save`,{ body: group })
	}

	setDefault(id){
		return this.customRequestPost(`/${id}/default`)
	}
   
	setImportant(id) {
		return this.customRequestPost(`/${id}/important`)
	}


	getDefaultId(){
		return this.http.get(this.configService.getApiPath() + '/groups/default');
	}

	getDefault(){
		return this.searchSingle('getDefault')
	}
	
	getGroupById(id) {
		return this.searchSingle(`getGroupById?projection=groups&id=${id}`)
	}
	
	findAvailableGroupOfCustomer() {
		return this.http.get(this.configService.getApiPath() + '/groups/listAvailableGroupOfCustomer?projection=groupFeed');
	}
}
