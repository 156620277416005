<div class="row">
    <div class="col-12 mx-auto back-style">
        <div class="row"  [ngClass]="{rtl:appService.rtl()}">
           
            <div class="col-4 mt-3  text-center text-style ellipsis-text" (click)="apply(note)" [editAuthorized]="authority" >
                <span>{{ note.note | translate}}</span>
            </div>
            <div class="col-6 mt-3  text-center text-style" (click)="apply(note)" [editAuthorized]="authority" >
                <span>{{note.actionDate | date: appService.rtl()? 'dd/MM/yyyy' : 'yyyy/MM/dd'}}</span>
            </div>
       
          
            <div class="col-2" [ngClass]="appService.rtl()? 'mt-2 pt-2' : 'mt-1 pt-3' " [deleteAuthorized]="authority" >
                <div [ngClass]="appService.rtl()? ' float-start' : 'float-end' ">
                    <i class="crois-style fas  icon fa-trash" (click)="deleteNote(note)"></i>
                </div>
            </div>
            
        </div>
    </div>
</div>