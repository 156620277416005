import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { CommonDialogComponent } from '../../../common-dialog/common-dialog.component';
import { Helper } from 'projects/shared/tools/helper';
import { CustomRouter } from '../../../services/custom-router';
import { Authorities, Country, SectionForm } from 'projects/shared/tools/constants';
import state from 'projects/shared/tools/state';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FarmerStockFeedService } from 'projects/shared/services/farmer-stock-feed.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';

@Component({
  selector: 'app-history-card',
  templateUrl: './history-card.component.html',
  styleUrls: ['./history-card.component.scss']
})
export class HistoryCardComponent implements OnInit {

  @Input() form: any
  @Output() reload = new EventEmitter();
  @Output() reloadState = new EventEmitter();
  @Input() authority: any
  @Input() service: any
  @Output() itemClick = new EventEmitter();
  @Output() formSelectionChange = new EventEmitter<any>();
  unit: any;
  formTemlateId: any;
  formTemlateIdValue: any;
  showDate: boolean = false;
  @Input() section: any
  sectionStock = SectionForm.farmer_stock
  sectionFeed = SectionForm.farmer_feed_consumption
  idFeed
  showReduce: boolean = false;
  history: boolean = false
  historyMisc: boolean = false
  historyFeed: boolean = false
  formsValues
  forms;
  listId = []
  sectionFarmerMilkPorduction = SectionForm.farmer_milk_production
  labelAr: boolean = false
  labelEn: boolean = false
  labelFr: boolean = false
  labelInd: boolean = false
  sectionFeedValue: boolean = false;
  isIdnCode: boolean=false
  constructor(public tokenManagementService: TokenManagementService, public farmerStockFeedService: FarmerStockFeedService, public appService: AppService, public dialog: MatDialog, private router: CustomRouter, public route: ActivatedRoute,
    public alertsService: AlertsService, public translate: TranslateService, private formTemplateService: FormTemplateService) {
    let currentRoute = this.router.url;
    const { idFormTemplate, idFeed } = this.route.snapshot.params
    this.formTemlateId = idFormTemplate;
    this.idFeed = idFeed
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.labelAr = true
    else if (this.tokenManagementService.getTranslateLanguage() == "fr")
      this.labelFr = true
    else if (this.tokenManagementService.getTranslateLanguage() == "ind")
      this.labelInd = true
    else
      this.labelEn = true
  }

  ngOnInit() {
    if (Country.isIdn(this.appService.connectedCustomer.addresses[0].country.code))
    this.isIdnCode = true;
  else
    this.isIdnCode = false;


    if (state.forms[this.section] == SectionForm.advisor_farmer_settings) {
      if (!state.forms[this.section]) {
        this.formTemplateService.findByLabelOfFormTemplate('MILK QUANTITY').subscribe(res1 => {
          this.formTemlateIdValue = res1
          this.addUnit(this.section, this.formTemlateIdValue)
        })
      }
      else {
        (state.forms[this.section]).forEach(element => {
          if (element.xml.type == 'MILK_QUANTITY') {
            this.formTemlateIdValue = element.id
          }
        })
        this.addUnit(this.section, this.formTemlateIdValue)
      }
    } else {
      this.addUnit(this.section, this.formTemlateIdValue)
    }
    if (this.router.url.indexOf("stock") != -1)
      this.showDate = true;
    else
      this.showDate = false;
    if (this.router.url.indexOf("miscInventory") != -1) {
      this.showReduce = true;
    }
    if (this.router.url.indexOf("history") != -1) {
      this.history = true;
      
      // this.showDate = true;
    }

    if (this.router.url.indexOf("historyMiscInventory") != -1)
      this.historyMisc = true;
    if (this.router.url.indexOf("historyFeedInventory") != -1)
      this.historyFeed = true;
      if ( this.section==this.sectionFeed)
        this.sectionFeedValue = true;
      else this.sectionFeedValue = false
  }

  addUnit(section, formTemlateIdValue) {
    if ((section == SectionForm.farmer_milk_production) || (section == SectionForm.animal_milk_production) || (formTemlateIdValue == this.formTemlateId))
      this.unit = 'L'
    else if ((section == SectionForm.farmer_feed_consumption) || (section == SectionForm.farmer_stock))
      this.unit = this.form.unit;
    else if (section == SectionForm.farmer_economy)
      this.unit = this.appService.connectedCustomer.addresses[0].country.currency
  }

  apply(item) {

    this.itemClick.emit(item)
  }

  delete(element) {
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      data: {
        message: this.translate.instant('CONFIRM_DELETE_ITEM'),
        title: this.translate.instant('DELETE_ITEM'),
        onConfirm: () => {
          this.appService.showLoading();
          if (element._links)
            element._links.self.href = Helper.cleanLink((element)._links.self.href)
          this.appService.showLoading()
          if (this.service == this.farmerStockFeedService) {
            this.service.delete(element).subscribe(res => {
              this.successDel()
            })
          } else {
            this.service.deleteById(element).subscribe(data => {
              //this.reload.emit();
              this.successDel();


            }, error => {
              this.appService.error(error.error.error)
            })
          }
        }
      }
    });
  }
  edit(item) {
    if (this.router.url.indexOf("historyFeedInventory") != -1)
      this.router.navigateByUrl('stock/feedInventory/' + this.appService.idFeedInventory + '/save' + "/" + (this.idFeed) + "/" + item.quantity + "/" + item.id)
    else if (this.router.url.indexOf("historyMiscInventory") != -1)
      this.router.navigateByUrl('stock/miscInventory/' + this.appService.idMiscInventory + '/save' + "/" + (this.idFeed) + "/" + item.quantity + "/" + item.id)
  }
  reduce(item) {
    if (this.router.url.indexOf("miscInventory") != -1)
      this.router.navigateByUrl('stock/miscInventory/' + this.formTemlateId + '/save' + "/" + (item.id) + "/reduceMiscInventory")
  }
  addNew(item) {
    if (this.router.url.indexOf("feedInventory") != -1)
      this.router.navigateByUrl('stock/feedInventory/' + this.formTemlateId + '/save' + "/" + (item.id) + "/addFeedInventory")
    else if (this.router.url.indexOf("miscInventory") != -1)
      this.router.navigateByUrl('stock/miscInventory/' + this.formTemlateId + '/save' + "/" + (item.id) + "/addMiscInventory")
  }
  showHistory(item) {
    if (this.router.url.indexOf("feedInventory") != -1)
      this.router.navigateByUrl('settings/' + this.appService.formTemplateFormFarmerFeedConsumptionId + '/' + Authorities.FARMER_FEED_CONSUMPTION + '/' + (item.id) + '/historyFeedInventory/' + item.title);
    else if (this.router.url.indexOf("miscInventory") != -1)
      this.router.navigateByUrl('settings/' + this.appService.formTemplateFormFarmerFeedConsumptionId + '/' + Authorities.FARMER_FEED_CONSUMPTION + '/' + (item.id) + '/historyMiscInventory/' + item.title);

  }

  successDel() {
    this.appService.hideLoading();
    let message = this.translate.instant('DELETE_RECORDING');
    this.alertsService.open(message);
    this.reloadState.emit()
    if (this.router.url.indexOf("feedInventory") != -1)
      this.calculSommeCrudeDry();
  }
  calculSommeCrudeDry() {
    this.appService.sommeCrude = 0
    this.appService.sommeDry = 0
    this.farmerStockFeedService.findByFarmer().subscribe(res => {
      this.formsValues = this.appService.organizedData(res, this.appService.dataAddStockFeed);
      this.formsValues?.forEach(res1 => {
        if (res1.crudProtein || res1.dryMatter) {
          if (res1.unit == "TON") {
            this.appService.sommeCrude += (res1.quantity * 1000 * res1.crudProtein) / 100;
            this.appService.sommeDry += (res1.quantity * 1000 * res1.dryMatter) / 100;
          }
          if (res1.unit == "KG") {
            this.appService.sommeCrude += (res1.quantity * res1.crudProtein) / 100;
            this.appService.sommeDry += (res1.quantity * res1.dryMatter) / 100;
          }
        }
      })
    })
  }
  onCheckboxChange(event: any, form) {
    if (event.checked) {
      this.appService.listId.push(form.id)

    } else {
      this.appService.listId = this.appService.listId.filter(id => id != form.id);

    }
    this.formSelectionChange.emit(this.appService.listId);
  }
}
