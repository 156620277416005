import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'projects/shared/services/app.service';
import { FarmerSupervisorRequestService } from 'projects/shared/services/farmer-supervisor-request.service';
import { StatusRequest } from 'projects/shared/tools/constants';
import { CustomRouter } from '../services/custom-router';

@Component({
  selector: 'app-request-dialog',
  templateUrl: './request-dialog.component.html',
  styleUrls: ['./request-dialog.component.scss']
})
export class RequestDialogComponent implements OnInit {
accepted=StatusRequest.ACCEPTED;
refused=StatusRequest.REFUSED;
  constructor(public dialogRef: MatDialogRef<RequestDialogComponent>, public appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: UntypedFormBuilder, public router: CustomRouter, public dialog: MatDialog, private translate: TranslateService, private farmerSupervisorRequestService: FarmerSupervisorRequestService) { }

  ngOnInit(): void {
  }
  action(value) {
    this.farmerSupervisorRequestService.updateStatus(Number(this.data.idFsr), value).subscribe(
      data => {
        this.dialogRef.close();
      });
  }
  
}
