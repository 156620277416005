import { Injectable } from '@angular/core';
import { MoomeRestService, Options } from './moome-rest.service';
import { map } from 'rxjs';
import { FarmerFeedConsumption } from '../resources/FarmerFeedConsumption';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { FarmerMiscInventoryStock } from '../resources/FarmerMiscInventoryStock';

@Injectable({
    providedIn: 'root'
})
export class FarmerMiscInventoryStockService extends MoomeRestService<FarmerMiscInventoryStock> {
   
    constructor(public http: HttpClient, private configService: ConfigService) {
        super(FarmerMiscInventoryStock);
    }

    findByFarmer(id) {
        
        let param: any=
        {projection: 'farmer-misc-inventory-stock',
       }
        if(id!=null)
        param.id=id;

        let options: Options =
        {
            params: 
            param
        }
        return this.customRequestGet(`/findAllByFarmer`, null, options).pipe(
            map(res => { return res['_embedded']?.farmerMiscInventoryStocks }
            ))
    }

    findByFarmerByKey(key: any,idStock) {
        let options: Options =
        {
            params: {
                key: key,
                idStock:idStock,
                projection: "farmer-misc-inventory-stock",
            }
        }
        return this.customRequestGet(`/findAllByFarmerByKey`, null, options).pipe(
            map(res => { return res['_embedded']?.farmerMiscInventoryStocks }
            ))
    }

    deleteById(body) {
        return this.http.delete(this.configService.getApiPath() + `/farmerMiscInventoryStocks/${body.id}/deleteFeed`)
    }
    
      find(){
        return this.http.get(this.configService.getApiPath() + `/farmerMiscInventoryStocks/find`);
    }
    update(idStock,body){
        return this.http.post(this.configService.getApiPath() + `/farmerMiscInventoryStocks/${idStock}/update`,body)
    }
}