import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AnimalNoteService } from 'projects/shared/services/animal-note.service';
import { AnimalService } from 'projects/shared/services/animal.service';
import { AppService } from 'projects/shared/services/app.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { Alert, Type, Authorities, FORMAT_MAX_MIN, LivestockIdentif, NUMERIC_PATTERN_RECALL, NUMERIC_PATTERN_FEED } from 'projects/shared/tools/constants';
import { combineLatest, forkJoin } from 'rxjs';
import { CustomRouter } from '../../services/custom-router';

@Component({
  selector: 'app-add-weight-popup',
  templateUrl: './add-weight-popup.component.html',
  styleUrls: ['./add-weight-popup.component.scss']
})
export class AddWeightPopupComponent implements OnInit {

  idFarmer: any;
  uid: any
  code: any
  colorGroup: any
  formGroup: UntypedFormGroup;
  animal: any
  animalNote: any
  valueEvent: any
  maxDate: any
  minDate: any
  role: any
  authorityAnimals = Authorities.ANIMALS;
  auth: string[] = [];
  formatArabe: any
  name: any
  nationalIdentification
  nameLocalCode
  currentRoute: any;
  supervisorId: any;
  note
  details
  numericPattern = NUMERIC_PATTERN_FEED
  constructor(private route: ActivatedRoute, public router: CustomRouter, public tokenManagementService: TokenManagementService, public appService: AppService, private translate: TranslateService, public animalService: AnimalService, public animalNoteService: AnimalNoteService, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: UntypedFormBuilder, private alertsService: AlertsService, public dialogRef: MatDialogRef<AddWeightPopupComponent>) {
    this.createForm();
    this.findAnimal()


  }
  ngOnInit() {
    this.currentRoute = this.router.url
    this.formatArabe = this.appService.verifFormatDate()
    this.maxDate = moment(new Date()).format(FORMAT_MAX_MIN);
    this.animalService.getById(parseInt(this.data.animalId)).subscribe(res => {
      this.animal = res
      this.minDate = moment(res.birthDate).format(FORMAT_MAX_MIN)
      if (!res.birthDate)
        this.minDate = moment("2000, 1, 1").format(FORMAT_MAX_MIN)
    })
    /*error => {
      this.appService.hideLoading();
     let  message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK )
      this.alertsService.open(message, alert);
    }*/
    this.nationalIdentification = LivestockIdentif.NATIONAL_IDENTIFICATION
    this.nameLocalCode = LivestockIdentif.NAME_LOCALCODE

  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      'actionDate': [null, [Validators.required]],
      'noteDetail': [null],
      'height': [null],
      'type': [null],
      'note': [null]
    });
  }

  findAnimal() {
    this.animalService.getAnimalById(parseInt(this.data.animalId), "animal-details").subscribe(res => {
      this.details = res
      this.animal = res
      this.uid = res.uid
      this.name = res.name
      this.code = res.code
      
      if (res.weight != null) {
        this.formGroup.controls['noteDetail'].setValue(res.weight);
        this.note = (this.details.animalNotes.sort((val1, val2) => { return new Date(val2.actionDate).getTime() - new Date(val1.actionDate).getTime() })).filter(r => r.type == "WEIGHT")[0];
        this.formGroup.controls['actionDate'].setValue(this.note.actionDate);
        
      }
      if (res.height != null) {
        this.note = (this.details.animalNotes.sort((val1, val2) => { return new Date(val2.actionDate).getTime() - new Date(val1.actionDate).getTime() })).filter(r => r.type == "HEIGHT")[0];
        this.formGroup.controls['actionDate'].setValue(this.note.actionDate);
        this.formGroup.controls['height'].setValue(res.height)


      }

      if (res.customerAnimalGroups && res.customerAnimalGroups.length > 0) {
        res.customerAnimalGroups.forEach(element => {
          if (element.color != null)
            this.colorGroup = element.color
        });
      }
    })
    /* error => {
       this.appService.hideLoading();
      let  message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
       let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK )
       this.alertsService.open(message, alert);
     }*/
  }
  saveWeight() {
    return this.save({ type: Type.WEIGHT, note: "WEIGHT", noteDetail: this.formGroup.controls['noteDetail'].value });
  }
  saveHeight() {
    return this.save({ type: Type.HEIGHT, note: "HEIGHT", noteDetail: this.formGroup.controls['height'].value });

  }
  save({ type, note, noteDetail }) {
    let body: any = { type, note, noteDetail }

    body.animal = this.animal._links.self.href;
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1 || this.currentRoute.startsWith('/supervisors'))
      body.customer = location.origin + "/api/customers/" + this.data.idFarmer
    else {
      body.customer = this.appService.connectedCustomer['link'].href;
    }
    body.actionDate = (moment(this.formGroup.controls['actionDate'].value).toISOString());
    return this.animalNoteService.save(body)
  }
  confirm() {
    if (this.animal.customerAnimals.length > 0 && this.animal.customerAnimals[0].customer.id == this.appService.connectedCustomer.id) {
      this.message('DISABLED_ANIMAL')
    }
    else {
      if (!this.formGroup.valid) {
        if ((this.formGroup.controls['actionDate'].hasError('required')))
          this.message('FIELD_REQUIRED')
        else if ((this.valueEvent) || (this.formGroup.controls['actionDate'].value > this.maxDate))
          this.message('FORM_DATE');
      }
      else if (((this.formGroup.controls['height'].value == null) && (this.formGroup.controls['noteDetail'].value == null)))
        this.message('FIELD_REQUIRED')
      else {
        this.appService.showLoading();
        let saveTasks: any = {}
        if (this.formGroup.controls['noteDetail'].value != null) saveTasks.weightTask = this.saveWeight()
        if (this.formGroup.controls['height'].value != null) saveTasks.heightTask = this.saveHeight()
        forkJoin(saveTasks).subscribe({
          complete: () => {
            this.appService.hideLoading();
            let message = this.translate.instant('SAVE_SUCESS');
            this.dialogRef.close()
            this.data.reload();
            this.alertsService.open(message);
          },
          error: (error) => {
            this.message(error.error.code);
          }

        })
      }
    }
  }
  fixArabeFormat() {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD/MMM/YYYY"
  }
  verifPoids() {


    if (this.formGroup.controls['noteDetail'].value > 2500) {
      this.formGroup.controls['noteDetail'].setValue(2500)
    }
    else if (this.formGroup.controls['noteDetail'].value < 0) {
      this.formGroup.controls['noteDetail'].setValue(0)
    }

  }
  verifHeight() {


    if (this.formGroup.controls['height'].value > 250) {
      this.formGroup.controls['height'].setValue(250)
    }
    else if (this.formGroup.controls['height'].value < 0) {
      this.formGroup.controls['height'].setValue(0)
    }

  }
  /*addEvent(event) {
    if (event.targetElement.value) {
      if (this.tokenManagementService.getTranslateLanguage() == "fr") {
        if (!moment(event.targetElement.value, 'DD/MM/YYYY', true).isValid()) {
          this.message('FORM_DATE');
          this.valueEvent = true
        }

      }
      else if (this.tokenManagementService.getTranslateLanguage() == "ar") {
        if (!moment(event.targetElement.value, 'YYYY/MM/DD', true).isValid()) {
          this.message('FORM_DATE');
          this.valueEvent = true
        }

      }
      else if (this.tokenManagementService.getTranslateLanguage() == "en") {
        if (!moment(event.targetElement.value, 'MM/DD/YYYY', true).isValid()) {
          this.message('FORM_DATE');
          this.valueEvent = true
        }

      }
    }
  }*/


  message(msg) {
    this.appService.hideLoading();
    let message = this.translate.instant(msg);
    let alert = this.translate.instant(Alert.OK)
    this.alertsService.open(message, alert);
  }
}
