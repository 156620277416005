import { Component, Inject, Input, OnInit } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { ActivatedRoute } from '@angular/router';
import { GroupService } from 'projects/shared/services/group.service';
import { CustomerService } from 'projects/shared/services/customer.service';
import { Authorities, GroupCode } from 'projects/shared/tools/constants';
import { CustomRouter } from 'projects/publicV2/src/app/services/custom-router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Group } from 'projects/shared/resources/group';
@Component({
  selector: 'app-lost-animals-dialog',
  templateUrl: './lost-animals-dialog.component.html',
  styleUrls: ['./lost-animals-dialog.component.scss']
})
export class LostAnimalsDialogComponent implements OnInit {
  groupsHasChildren: any[] = []
  idFarmer: any
  router
  authority = Authorities.GROUPS;
  auth: string[] = [];
  oldGroup: any;
  supervisorId: any;
  constructor(public dialogRef: MatDialogRef<LostAnimalsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public appService: AppService, private route: ActivatedRoute, router: CustomRouter, public groupService: GroupService, public customerService: CustomerService) {
    this.router = router
  }

  ngOnInit() {
    this.supervisorId = this.data.idSupervisor
    this.idFarmer = this.data.idFarmer;
    this.appService.verif = false
    if (this.idFarmer)
      this.loadGroups(this.idFarmer)
    else
      this.loadGroups()
    this.route.queryParams.subscribe(params => {
      this.oldGroup = params.oldGroup;
    })

  }



  loadGroups(idFarmer?) {
    this.data.group.childrens = []
    this.data.group['children'].sort((a, b) => {
      if (a.orderGroup > b.orderGroup) {
        return 1
      } else if (a.orderGroup < b.orderGroup) {
        return -1
      }
      return 0
    }).forEach(value => {
      value.clickable = true;
      this.data.group.childrens.unshift(value)

    })
  }

}

