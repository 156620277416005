import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { OrderDetail } from '../resources/OrderDetail';
import { ConfigService } from './config.service';

import { MoomeRestService, Options } from './moome-rest.service';

@Injectable({
	providedIn: 'root'
})
export class OrderDetailService extends MoomeRestService<OrderDetail> {


	constructor(public http: HttpClient, injector: Injector, private configService: ConfigService) {
		super(OrderDetail);
	}
	}