import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimalService } from 'projects/shared/services/animal.service';
import { AppService } from 'projects/shared/services/app.service';
import { CustomRouter } from '../services/custom-router';
import { AnimalBarComponent } from '../animals/animal-bar/animal-bar.component';
import { Authorities, LivestockIdentif } from 'projects/shared/tools/constants';

@Component({
  selector: 'app-pedigree',
  templateUrl: './pedigree.component.html',
  styleUrls: ['./pedigree.component.scss']
})

export class PedigreeComponent implements OnInit {
  id: any
  animal: any
  uid: any
  code: any
  colorGroup: any
  uidFather: any
  codeFather: any
  colorGroupFather: any
  uidMother: any
  codeMother: any
  colorGroupMother: any
  pedigreeDetails: any
  nbre: any
  children: any[] = []
  motherId: any
  fatherId: any
  role: any;
  idFarmer: any;
  inseminationRef: any
  externalFather: any
  @ViewChild(AnimalBarComponent, { static: false }) animalBar: AnimalBarComponent;
  authorityPedigrees = Authorities.PEDIGREE;
  auth: string[] = [];
  nameFather: any
  nameMother: any
  nationalIdentification
  nameLocalCode
  suffix: string
  currentRoute: any;
  supervisorId: any;
  constructor(private router: CustomRouter, public appService: AppService,
    public dialog: MatDialog, public route: ActivatedRoute, public animalService: AnimalService,private routerR: Router) { 
      this.routerR.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
    }

  ngOnInit() {
    this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
    this.currentRoute = this.router.url
    this.suffix = this.appService.connectedCustomer.addresses[0].country.uidIndex;
    this.nationalIdentification = LivestockIdentif.NATIONAL_IDENTIFICATION
    this.nameLocalCode = LivestockIdentif.NAME_LOCALCODE
    this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1)
      this.role = true;
    else
      this.role = false

    this.id = this.route.snapshot.paramMap.get('id');
    this.loadPedigreeDetail(this.id)
  }

  back() {
    this.appService.verif = false
    this.router.back('')
  }

  loadPedigree(id) {
    this.animalBar.findAnimal(id)
    if (!this.role) {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.router.navigateByUrl('supervisors/' + this.supervisorId + '/farmers/' + this.idFarmer + '/animals/' + id + '/settings/pedigree')
      }
      else
        this.router.navigateByUrl('/animals/' + id + '/settings/pedigree')
    }

    else
      this.router.navigateByUrl('farmers/' + this.idFarmer + '/animals/' + id + '/settings/pedigree')
    this.loadPedigreeDetail(id)
  }

  loadPedigreeDetail(id) {
    this.appService.showLoading()
    this.animalService.getPedigree(id).subscribe(data => {
      this.pedigreeDetails = data
      if (this.pedigreeDetails.customerFatherGroups && this.pedigreeDetails.customerFatherGroups.length > 0) {
        this.pedigreeDetails.customerFatherGroups.forEach(element => {
          if (element.color != null)
            this.colorGroupFather = element.color
        });
      }
      if (this.pedigreeDetails.customerMotherGroups && this.pedigreeDetails.customerMotherGroups.length > 0) {
        this.pedigreeDetails.customerMotherGroups.forEach(element => {
          if (element.color != null)
            this.colorGroupMother = element.color
        });
      }

      this.nbre = this.pedigreeDetails.lactNum
      this.uidMother = this.pedigreeDetails.motherUid
      this.codeMother = this.pedigreeDetails.motherCode
      this.uidFather = this.pedigreeDetails.fatherUid
      this.codeFather = this.pedigreeDetails.fatherCode
      this.nameFather = this.pedigreeDetails.fatherName
      this.nameMother = this.pedigreeDetails.motherName
      this.motherId = parseInt(this.pedigreeDetails.motherId)
      this.fatherId = parseInt(this.pedigreeDetails.fatherId)
      if ((this.pedigreeDetails.externalFather != null) && ((this.pedigreeDetails.externalFather).toString().toUpperCase().startsWith(this.suffix)))
        this.externalFather = (this.pedigreeDetails.externalFather).toUpperCase()
      else if (this.pedigreeDetails.externalFather == "null")
        this.externalFather = ""
      else
        this.externalFather = this.pedigreeDetails.externalFather
      if (this.pedigreeDetails.inseminationRef != null)
        this.inseminationRef = this.pedigreeDetails.inseminationRef
      else
        this.inseminationRef = ""
      this.pedigreeDetails.childrens.sort((a, b) => {
        if (a.birthDate < b.birthDate) {
          return 1
        } else if (a.birthDate > b.birthDate) {
          return -1
        }
        return 0
      }).forEach(child => {
        if (child.childrens.length > 0)
          child.childrens.sort((a, b) => {
            if (a.birthDate < b.birthDate) {
              return 1
            } else if (a.birthDate > b.birthDate) {
              return -1
            }
            return 0
          })
      });
      this.appService.hideLoading();
    })

  }
}
