import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { AlertService } from 'projects/shared/services/alert.service';
import { AppService } from 'projects/shared/services/app.service';
import { AlertsType, Authorities, LivestockIdentif, SectionForm, typeAlert, TypeAlert } from 'projects/shared/tools/constants';
import { CustomRouter } from '../../../services/custom-router';
import { AlertTypeAppelLaboComponent } from '../alert-type-appel-labo/alert-type-appel-labo.component';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import * as moment from 'moment';

@Component({
  selector: 'app-alert-type-card',
  templateUrl: './alert-type-card.component.html',
  styleUrls: ['./alert-type-card.component.scss']
})
export class AlertTypeCardComponent implements OnInit {
  @Input() alertDetail: any
  type: any
  code: string
  auth: string[] = [];
  router
  role
  nationalIdentification
  nameLocalCode
  currentRoute: any;
  supervisorId: any
  authGetEntreprises: string[] = [];
  idConnected: any = null
  typeAlert: TypeAlert
  note
  idFormTemplate
  numberDays
  constructor(private formTemplateService: FormTemplateService,private alertService: AlertService, public codeTranslate: CodeTranslatePipe, public appService: AppService, private route: ActivatedRoute, router: CustomRouter,
    public translate: TranslateService, public dialog: MatDialog) {
    this.router = router;
  }

  ngOnInit() {
    this.appService.connectedCustomer['roles'].forEach(element => {
      if (element.publicAccess == true && element.isSupervisor == true)
        this.idConnected = this.appService.connectedCustomer.id
      element['authorityRoles'].forEach(authorityRole => {

        if (authorityRole.authority.code == Authorities.ENTREPRISES)
          this.authGetEntreprises.push((authorityRole["get"]).toString())
      })
    })
    this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
    this.currentRoute = this.router.url
    this.type = this.route.snapshot.paramMap.get('type');
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if ((this.auth.indexOf("true") != -1) || ((this.auth.indexOf("true") != -1) && (this.authGetEntreprises.indexOf("true") == -1)))
      this.role = true
    // this.code = this.alertDetail.alert.animal.address.country.code

    /* 
     else this.code = this.appService.connectedCustomer.addresses[0].country.code*/
    if (this.alertDetail.alert.animal)
      this.code = this.alertDetail.alert.animal.address.country.code
    this.nationalIdentification = LivestockIdentif.NATIONAL_IDENTIFICATION
    this.nameLocalCode = LivestockIdentif.NAME_LOCALCODE
  }

  checkType(value) {
    return TypeAlert.checkTypeAlertTanks(value)
  }

  checkTypeConnected(value) {
    return TypeAlert.checkTypeAlertConnected(value)
  }

  checkTypeThi(value) {
    return TypeAlert.checkTypeAlertTHI(value)
  }

  apply() {
    if (AlertsType.timelineRouter(this.type)) {
     if(this.alertDetail.alert.type ==typeAlert.RECALL){
        this.formTemplateService.getForms("form-template", SectionForm.animal_milk_production).subscribe(res => {
        this.idFormTemplate = res[0].id})
      this.note =   this.alertDetail.alert.animal.animalNotes.filter(r => r.others?.alertId==this.alertDetail.alert.id)[0];
      if (this.role){
      if(this.note)
      this.router.navigateByUrl('/farmers/' + this.alertDetail.alert.animal.address.customer.id + '/animals/' + this.alertDetail.alert.animal.id + '/settings/notes/save/'+this.note.id)
       else
       this.router.navigateByUrl('/farmers/' + this.alertDetail.alert.animal.address.customer.id + '/animals/' + this.alertDetail.alert.animal.id + '/settings/'+this.idFormTemplate+'/animalMilkProductions/save')
    
    
    } else {
      if(this.note){
      if (this.currentRoute.startsWith('/supervisors'))
        this.router.navigateByUrl('supervisors/' + this.supervisorId + '/farmers/' + this.alertDetail.alert.animal.address.customer.id + '/animals/' + this.alertDetail.alert.animal.id + '/settings/notes/save/'+this.note.id)
      else
        this.router.navigateByUrl('/animals/' + this.alertDetail.alert.animal.id + '/settings/notes/save/'+this.note.id)
       }
      else{
        if (this.currentRoute.startsWith('/supervisors'))
        this.router.navigateByUrl('supervisors/' + this.supervisorId + '/farmers/' + this.alertDetail.alert.animal.address.customer.id + '/animals/' + this.alertDetail.alert.animal.id + '/settings/'+this.idFormTemplate+'/animalMilkProductions/save')
      else
      this.router.navigateByUrl('/animals/' + this.alertDetail.alert.animal.id + '/settings/'+this.idFormTemplate+'/animalMilkProductions/save')
       } }
      }
      else if(this.alertDetail.alert.animal.sexe) {
        if(this.alertDetail.alert.animal.birthDate){
        let diff = moment().diff(moment(this.alertDetail.alert.animal.birthDate), "days")
        this.numberDays = diff ;}
      if (this.alertDetail.alert.animal.sexe == "FEMALE" && (this.numberDays >=365 ||!this.alertDetail.alert.animal.birthDate)) {
        if (this.role)
          this.router.navigateByUrl('/farmers/' + this.alertDetail.alert.animal.address.customer.id + '/animals/' + this.alertDetail.alert.animal.id + '/timelines')
        else {
          if (this.currentRoute.startsWith('/supervisors'))
            this.router.navigateByUrl('supervisors/' + this.supervisorId + '/farmers/' + this.alertDetail.alert.animal.address.customer.id + '/animals/' + this.alertDetail.alert.animal.id + '/timelines')
          else
            this.router.navigateByUrl('/animals/' + this.alertDetail.alert.animal.id + '/timelines')
        }
      } else {
        /* const dialogRef = this.dialog.open(AnimalCardComponent, {
           width: '350px',
         });
         dialogRef.afterClosed().subscribe(result => {
         });*/
      }
    }
  }
  }
  /*readAlert(selectedRows, event) {
    event.stopPropagation()
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      data: {
        message: this.translate.instant('CONFIRM_MARK_AS_UNREAD'),
        title: this.translate.instant('MARK_AS_READ'),
        onConfirm: () => {
          let body = new AlertReadDto();
          let rows: any[] = [];
          rows.push(selectedRows.id)
          body.read = false;
          body.rows = rows;
          this.alertService.updateRead(body).subscribe(data => {
          })
        }
      }
    })
  }*/
  confirmAppel() {
    //googleAnalytics
    this.appService.googleAnalyticsAllPages('call-lab',null,null,null)
    const dialogRef = this.dialog.open(AlertTypeAppelLaboComponent, {
      data: {
        message: this.translate.instant('CALL_LAB_POPUP'),
        title: this.translate.instant('CALL_LAB'),

      }
    })
  }

}

