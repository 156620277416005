import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommunHistoryCardComponent } from './commun-history-card.component';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HistoryCardModule } from '../history-card/history-card.module';

@NgModule({
  declarations: [
    CommunHistoryCardComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MoomeCommonModule,HistoryCardModule,
  ],
  exports: [
    CommunHistoryCardComponent
  ]
})
export class CommunHistoryCardModule { }
