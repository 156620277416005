import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { MaterialModule } from '../material/material.module';
import { MilkCollectedGraphComponent } from './milk-collected-graph.component';


@NgModule({
  declarations: [
    MilkCollectedGraphComponent,
    ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgxHideOnScrollModule,
    IonicModule,
    MatButtonToggleModule,MatMenuModule
  ],
  exports: [
    MilkCollectedGraphComponent,
   
    
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class MilkCollectedGraphModule { }
