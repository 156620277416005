import { Resource } from "@lagoshny/ngx-hateoas-client";
import { MoomeRestService, Options } from './moome-rest.service';
import { environment } from 'projects/admin/src/environments/environment';
import { HttpClient } from '@angular/common/http';
export class CustomerCommonService<T extends Resource> extends MoomeRestService<T> {

	getFarmers() {
		let http: HttpClient
		return http.get(environment.apiPath + `/reports/farmers`);
	}
	getCustomers(page, size, field, direction, id, associatedTo, key, projection, searchRepository) {
		let param: any =
		{
			projection: projection,

		}
		if (id != null)
			param.id = id;
		if (associatedTo != null)
			param.associatedTo = associatedTo;
		if (field != null)
			param.field = field;
		if (direction != null)
			param.direction = direction.toUpperCase();
		if (key != null)
			param.key = key.toLowerCase();


		let options: Options = {
			params:
				param
			,
			pageParams: {
				size: size,
				page: page
			}
		}
		return this.searchPages(searchRepository, options)
	}
	getAdvisorForFarmers(id, key, associatedTo ,projection) {
		let param: any =
		{
			projection: projection,
		}
		if (id != null)
			param.id = id;
		if (key != null)
		param.key = key.toLowerCase();
		if (associatedTo != null)
			param.associatedTo = associatedTo;

		let options: Options = {
			params:
				param
		}
		return this.search('getAdvisorForFarmers', options)
	}
	getCustomersByKey(key, associatedTo, projection, searchRepository) {

		let param: any =
		{
			projection: projection,
		}

		if (associatedTo != null)
			param.associatedTo = associatedTo;
		if (key != null)
			param.key = key.toLowerCase();


		let options: Options = {
			params:
				param
		}
		return this.search(searchRepository, options)
	}

	getCustomersByRole() {
		let options: Options = {
			params: {
				projection: 'customer_name',
			}
		}
		return this.search(`getCustomersByRole`, options)
	}

	/*
		updatePhone(customerId, phone) {
			
			return CacheHelper.put(environment.apiPath + `/${customerId}/updatePhone`,  phone);
		}
		
			
			updateFullname(id, fullname){
			 let http: HttpClient
				return http.put(this.configService.getApiPath()+ this.customers+`/${id}/updateFullname`, fullname);
			}
			
			updateUsername(customerId, customer){
				 let http: HttpClient
				let httpOptions = { headers: new HttpHeaders({
					'language':  this.tokenManagementService.getTranslateLanguage(),
				  })
				};
				
				return http.put(this.configService.getApiPath()+ this.customers+`/${customerId}/updateUsername`, customer, httpOptions);
			}
		
			updateAddress(customerId, countryId){
			 let http: HttpClient
			return http.put(this.configService.getApiPath()+  this.customers+`/${customerId}/updateAddress`, countryId);
		}
		
		verifPassword(password){
			 let http: HttpClient
			return http.get(this.configService.getApiPath()+  this.customers+`/verifPassword?password=${password}`);
		}
		updatePasswordApp(id, customer) {
			 let http: HttpClient
			let httpOptions = { headers: new HttpHeaders({
				'language':  this.tokenManagementService.getTranslateLanguage(),
			  })
			};
			return http.put(this.configService.getApiPath() +  this.customers+`/${id}/update-password`, customer, httpOptions);
		}
		*/



}