import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { AppService } from 'projects/shared/services/app.service';
import { Authorities } from 'projects/shared/tools/constants';
import { CustomRouter } from '../../../services/custom-router';

@Component({
  selector: 'app-alert-type-bar',
  templateUrl: './alert-type-bar.component.html',
  styleUrls: ['./alert-type-bar.component.scss']
})
export class AlertTypeBarComponent implements OnInit {
  @Input() bar: any
  code: any
  barInfo:any[] = []
  @Output() barApply = new EventEmitter();
  router
  authority = Authorities.ANIMALS;
  farmerId:any;
  currentRoute:any
  constructor(private codeTranslate: CodeTranslatePipe, public sanitizer: DomSanitizer, private route: ActivatedRoute, router: CustomRouter, public appService: AppService,private routerR: Router) { 
   /* this.routerR.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };*/
    this.router = router;
  }

  ngOnInit() {
    this.currentRoute = this.router.url;
    this.farmerId = this.route.snapshot.paramMap.get('idFarmer');
    this.code = this.route.snapshot.paramMap.get('code');
  }

  apply() {
      this.code = this.bar.code
      this.appService.showLoading()
      if (this.currentRoute.indexOf("farmers") != -1) {
         this.router.navigateByUrl('/farmers/'+this.farmerId+'/alerts/' + this.code)
       } else
         this.router.navigateByUrl('/alerts/' + this.code)
      this.barApply.emit({ code:this.code , img:this.bar.coloredIcon ,title:this.codeTranslate.transform(this.bar), color:this.bar.color })

    }
}
