import { Component, OnInit } from '@angular/core';
import { CustomRouter } from '../services/custom-router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from 'projects/shared/services/app.service';
import { FORMAT_MAX_MIN, GroupCode } from 'projects/shared/tools/constants';
import * as moment from 'moment';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { CustomerAnimalGroupsService } from 'projects/shared/services/customer-animal-groups.service';
@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.scss']
})
export class HealthComponent implements OnInit {
  bar: any;
  list: any[] = [];
  formatArabe: any;
  formGroup: UntypedFormGroup;
  listGroups
  startDate
  endDate
  numberMortality;
  numberSick;
  constructor(public customerAnimalGroupsService: CustomerAnimalGroupsService, public tokenManagementService: TokenManagementService, public appService: AppService, public router: CustomRouter, private formBuilder: UntypedFormBuilder,) {

  }

  ngOnInit(): void {
    this.formatArabe = this.appService.verifFormatDate()
    this.createForm()
    this.list = [
      { title: "MORTALITY", value: this.numberMortality ? this.numberMortality:'--', class: "black-box", bigFontClass: "font-style-big", smallFontClass: "font-style-small" },
      { title: "HEALTH_ISSIUES", value: this.numberSick ? this.numberSick:'--', class: "red-box", bigFontClass: "font-style-big", smallFontClass: "font-style-small" },
    ]
    this.bar = { title: "HEALTH", arrowIcon: "/assets/images/left-arrow.svg", color: "#f6f7fb", fontColor: "#f44336", router: "/dashboard" }
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      'startDate': [null, [Validators.required]],
      'endDate': [null, [Validators.required]]
    });
  }
  back() {
    this.router.navigateByUrl('/dashboard')
  }
  fixArabeFormat() {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD/MMM/YYYY"
  }
  countByDateAndGroup() {
    this.listGroups = []
    this.listGroups.push(GroupCode.MORTALITY_CODE)
    this.listGroups.push(GroupCode.SICK_CODE)
    if (this.formGroup.controls['startDate'].value && this.formGroup.controls['endDate'].value) {
      this.startDate = moment(this.formGroup.controls['startDate'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format('yyyy-MM-DD')
      this.endDate = moment(this.formGroup.controls['endDate'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format('yyyy-MM-DD')
        const date1=(new Date(this.startDate)).getTime();
        const date2=(new Date(this.endDate)).getTime();
        if(date1>date2)
        this.appService.error('ERROR_DATE_FIELD');
      else {

      this.customerAnimalGroupsService.countByDateAndGroup(this.startDate, this.endDate, this.listGroups).subscribe(data => {

        this.numberMortality = data[GroupCode.MORTALITY_CODE]
        this.numberSick = data[GroupCode.SICK_CODE]
        if (this.numberMortality || this.numberSick) {
          this.list = [
            { title: "MORTALITY", value: this.numberMortality ? this.numberMortality:'--' , class: "black-box", bigFontClass: "font-style-big", smallFontClass: "font-style-small" },
            { title: "HEALTH_ISSIUES", value: this.numberSick ? this.numberSick:'--', class: "red-box", bigFontClass: "font-style-big", smallFontClass: "font-style-small" },
          ]
          this.bar = { title: "HEALTH", arrowIcon: "/assets/images/left-arrow.svg", color: "#f6f7fb", fontColor: "#f44336", router: "/dashboard" }
        }
        else
          this.appService.error('HEALTH_DASH_REQUIRED');
      })

    }}
  }
}

