import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmsVerificationComponent } from './sms-verification.component';
import { MaterialModule } from 'projects/admin/src/app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { VerifiedModule } from '../../verified/verified.module';
import { CommonDialogComponent } from '../../common-dialog/common-dialog.component';
import { CommonDialogModule } from '../../common-dialog/common-dialog.module';
//import { RecaptchadModule } from '../../recaptcha/recaptcha.module';
//import { RecaptchaComponent } from '../../recaptcha/recaptcha.component';
import { ChangePassordDialogModule } from '../change-passord-dialog/change-passord-dialog.module';
import { ChangePassordDialogComponent } from '../change-passord-dialog/change-passord-dialog.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

@NgModule({
    declarations: [
        SmsVerificationComponent,
    ],
    imports: [
        TranslateModule,
        CommonModule,
        MaterialModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        VerifiedModule,
        CommonDialogModule,
        ChangePassordDialogModule,
        //RecaptchadModule
    ],
    exports: [
        SmsVerificationComponent,
    ]
})
export class SmsVerificationModule { }
