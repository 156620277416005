import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'projects/shared/services/app.service';
import { RegionService } from 'projects/shared/services/region.service';
import { CustomRouter } from '../services/custom-router';
import { PATTERN_NAME_ARABIC, Authorities } from 'projects/shared/tools/constants';
import { FarmerService } from 'projects/shared/services/farmer.service';
import { ActivatedRoute } from '@angular/router';
import { Alert } from 'projects/shared/tools/constants';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { ProjectService } from 'projects/shared/services/project.service';
import { Project } from 'projects/shared/resources/Project';
import { CustomerService } from 'projects/shared/services/customer.service';
import { AnimalService } from 'projects/shared/services/animal.service';
import { Animal } from 'projects/shared/resources/Animal';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { EntrepriseService } from 'projects/shared/services/entreprise.service';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {
  currentRoute: any;
  supervisorId: any;
  entreprise: any
  idFarmer: any;
  role: any
  authority = Authorities.PROJECT
  customerId: any
  id: any
  entrepriseId: any;
  auth: string[] = [];
  groupForm: UntypedFormGroup;
  patternName: any;
  projects: any;
  project: Project
  selectedProjects: any;
  patternNameArabic = PATTERN_NAME_ARABIC;
  animal: Animal;
  constructor(public translate: TranslateService,
    public appService: AppService,
    private animalService: AnimalService,
    private formBuilder: UntypedFormBuilder,
    private codeTranslate: CodeTranslatePipe,
    private projectService: ProjectService, private customerService: CustomerService,
    private router: CustomRouter, private route: ActivatedRoute, public regionService: RegionService, public farmerService: FarmerService, public alertsService: AlertsService) {
    this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
    this.currentRoute = this.router.url
    this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1) {
      this.role = true;
      this.customerId = this.idFarmer
    } else {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.customerId = this.idFarmer
      }
      else {
        this.role = false
        this.customerId = this.appService.connectedCustomer.id;
      }
    }

    this.id = this.route.snapshot.paramMap.get('id');
    this.createForm();

    this.loadProjects()
    this.animalService.getAnimalById(this.id, "car-animals").subscribe(res => {
      this.animal = res;
      this.groupForm.controls['project'].setValue(this.animal.project ? this.animal.project.id : null)
    })
  }

  ngOnInit() {

  }
  loadProjects() {

    this.projectService.getProjectOfConnected().subscribe(res => {
      this.selectedProjects = this.projects = res

    });


  }
  onKeyProject(value) {
    this.selectedProjects = this.appService.searchLabel(this.projects, value);
  }
  createForm() {

    this.groupForm = this.formBuilder.group({
      'project': [null, Validators.required],

    });
  }

  apply() {
    
    this.appService.showLoading();
    this.project = this.groupForm.controls['project'].value;
    this.animalService.updateAnimalProject(this.id, this.project).subscribe(res => {

      this.appService.hideLoading();
      let message = this.translate.instant('SAVE_SUCESS');
      this.alertsService.open(message);
      this.router.back('')
      // this.createForm();
    }, error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.code);
      let alert = this.translate.instant(Alert.OK)
      this.alertsService.open(message, alert);
    }
    );
  }


  message(msg) {
    let message = this.translate.instant(msg);
    let alert = this.translate.instant(Alert.OK)
    this.alertsService.open(message, alert);
  }

  back() {
    this.appService.verif = false
    if (!this.role) {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.router.navigateByUrl('/supervisors/' + this.supervisorId + '/farmers/' + this.idFarmer + '/animals/' + this.id + '/settings');
      }
      else
        this.router.navigateByUrl('/animals/' + this.id + '/settings')
    }
    else
      this.router.navigateByUrl('farmers/' + this.idFarmer + '/animals/' + this.id + '/settings')
  }
}