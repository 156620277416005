import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'projects/shared/services/app.service';
import { RegionService } from 'projects/shared/services/region.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { CustomRouter } from '../services/custom-router';
import { Roles, NAME_MIN, NAME_MAX, NAME_PATTERN, PATTERN_NAME_ARABIC, PATTERN_NAME_FRENCH, PATTERN_NAME_ENGLISH } from 'projects/shared/tools/constants';
import { FarmerService } from 'projects/shared/services/farmer.service';
import { NavigationExtras } from '@angular/router';
import { Address } from 'projects/shared/resources/Address';
import { Helper } from 'projects/shared/tools/helper';
import { Alert } from 'projects/shared/tools/constants';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { Country } from 'projects/shared/resources/Country';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']

})

export class SignUpComponent implements OnInit {

  groupForm: UntypedFormGroup;
  innerHeight: number;
  patternName: any;
  patternNameArabic = PATTERN_NAME_ARABIC;
  patternNameFrench = PATTERN_NAME_FRENCH;
  currentRoute:any;
  nameText = "WHAT_NAME"
  countryText = "WHAT_COUNTRY"
  language: String = 'ar'
  selectedLanguage = {};
  color='color-white'
  constructor(private dateAdapter: DateAdapter<Date>,public translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    public appService: AppService,
    private tokenManagementService:
      TokenManagementService,
    private router: CustomRouter, public regionService: RegionService, public farmerService: FarmerService, public alertsService: AlertsService) {
    this.innerHeight = window.innerHeight - 168
    const l = this.tokenManagementService.getTranslateLanguage()
    if (l) {
      this.language = l;
      this.selectedLanguage=this.appService.chooseLanguage(this.language);
    }
  }

  ngOnInit() {
    this.currentRoute= this.router.url
    if(this.currentRoute.indexOf('farmers')!= -1){
    this.nameText = "WHAT_FARMER_NAME"
    this.countryText = "WHAT_FARMER_COUNTRY"
  }
    this.appService.loadCountries()
    this.createForm();
  }

  back() {
    this.router.back("/login")
  }

  createForm() {
    this.patternName = Helper.checkPatternName(this.tokenManagementService.getTranslateLanguage())
    this.groupForm = this.formBuilder.group({
      'sex': [null, Validators.required],
      'fullname': [null, [
        Validators.required,
        Validators.minLength(NAME_MIN),
        Validators.maxLength(NAME_MAX),
        Validators.pattern(this.patternName)]],
      'country': [null, Validators.required]
      //  'region': [null, Validators.required]

    });
  }

  apply() {
    if (this.groupForm.invalid) {
      if (this.groupForm.controls['sex'].hasError('required')) {
        this.message('SEX_REQUIRED');
      } else
        if ((this.groupForm.controls['fullname'].hasError('required'))) {
          this.message('NAME_REQUIRED');
        } else if (this.groupForm.controls['fullname'].hasError('minlength')) {
          this.message(this.translate.instant('LENG_USERNAME') + " " + NAME_MIN.toString() + " " + this.translate.instant('CARACTERE') + " ")
        }
        else if (this.groupForm.controls['fullname'].hasError('maxlength')) {
          this.message(this.translate.instant('MAX_LENGTH') + " " + NAME_MAX.toString() + " " + this.translate.instant('CARACTERE') + " ")
        } else if (this.groupForm.controls['fullname'].hasError('pattern')) {
          
          if (this.patternName == this.patternNameArabic)
            this.message('PATTERN_CHAR_ARABIC');
          else if(this.patternName == this.patternNameFrench && this.tokenManagementService.getTranslateLanguage() == 'fr')
            this.message('PATTERN_LANGUAGE_FRENCH');
          else
          this.message('PATTERN_LANGUAGE_ENGLISH');

        }
        /* else if (this.groupForm.controls['region'].hasError('required')) {
           this.message('REGION_REQUIRED')
         }*/

        else if (this.groupForm.controls['country'].hasError('required')) {
          this.message('COUNTRY_REQUIRED')
        }
    }

    else {
      let customer = this.groupForm.value
      customer.roles = [{ code: Roles.ROLE_FARMER }]
      let address = new Address();
      //let region = new Region();
      let country = new Country()
      //region.id = this.groupForm.controls['region'].value
      country.id = this.groupForm.controls['country'].value
      country.phoneLength = this.appService.countries.filter(c => c.id == country.id)[0].phoneLength
      //address.region = region
      address.country = country
      customer.addresses = [address];
      const navigationExtras: NavigationExtras = { state: { customer: customer } };
      //googleAnalytics
      this.appService.googleAnalyticsSignup('signup',customer.fullname)
      this.router.navigateByUrl('/sms-verification', navigationExtras)
      

    }
  }

  message(msg) {
    let message = this.translate.instant(msg);
    let alert = this.translate.instant(Alert.OK)
    this.alertsService.open(message, alert);
  }
}