  <mat-card [ngStyle]="{'display' : (displaygraph ? 'block' : 'none')}">
    <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="{{ 'SETTINGS' | translate }}" matTooltipClass="custom-tooltip">
      <mat-icon>settings</mat-icon>
    </button>
    <button *ngIf="tempGraph" mat-icon-button  [disabled]="size<-60" style="float: right" matTooltip="{{ 'ZOOM_IN' | translate }}" matTooltipClass="custom-tooltip" (click)="zoomChart(1,-10)">
      <mat-icon>zoom_in</mat-icon>
    </button>
    <button *ngIf="tempGraph" mat-icon-button [disabled]="size>=0" style="float: right" matTooltip="{{ 'ZOOM_OUT' | translate }}" matTooltipClass="custom-tooltip" (click)="zoomChart(-1,10)">
      <mat-icon>zoom_out</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <mat-checkbox (click)="updateChart(point)" #point>{{'POINT_TYPE' |translate}}</mat-checkbox>
      </button>
    </mat-menu>
    <canvas id="lineChart"></canvas>
  </mat-card>
