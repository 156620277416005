import { Component, OnInit, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { CommonDialogComponent } from './common-dialog/common-dialog.component';
import { Location } from '@angular/common';
import { CustomRouter } from './services/custom-router';
//import { App } from '@capacitor/core';
import { Platform } from '@ionic/angular';

import { UpdateService } from 'projects/shared/services/update.service';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { AppService } from 'projects/shared/services/app.service';
import {
  NetworkDetectionService
} from 'projects/shared/services/network-detection.service';
import { Network } from '@capacitor/network';
import { NavigationEnd, Router } from '@angular/router';
import { getMessaging, isSupported, onMessage } from "firebase/messaging";
import { RequestDialogComponent } from './request-dialog/request-dialog.component';
import { AlertsService } from 'projects/shared/services/alerts.service';

declare var UXCam: any;
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  /*title = 'publicV2';
  lng;
  ltd;
  timestamp;*/
  router
  noNetwork = false
  title = 'af-notification';
  message: any = null;
  connectedCustomer: any = null;
  constructor(public appService: AppService,/*private ngZone: NgZone*/public tokenManagementService: TokenManagementService, private dateAdapter: DateAdapter<Date>,
    router: CustomRouter, public dialog: MatDialog, private translate: TranslateService, private location: Location, private ngZone: NgZone,
    private platform: Platform, private updateService: UpdateService, private networkDetectionService: NetworkDetectionService, public route: Router, public alertsService: AlertsService) {

    /*this.networkDetectionService.getStatus();
    this.networkDetectionService.startListenNetwork();*/

    if (this.platform.is("cordova"))
      this.platform.ready().then(() => {
        this.updateService.checkForUpdate();
      });


    this.router = router;

    translate.setDefaultLang('ar');

    let language = this.tokenManagementService.getTranslateLanguage();
    if (!language)
      language = "ar";
    this.tokenManagementService.translateLanguage(language);
    translate.use(language).subscribe(res => {
      this.dateAdapter.setLocale(language + '-TN');
    });


  }


  async checkNetwork() {
    let status = await Network.getStatus();
    this.noNetwork = !status.connected
    Network.addListener('networkStatusChange', status => {
      this.ngZone.run(() => {
        console.log('Network status changed', status);
        this.noNetwork = !status.connected
      })
    });
  }

  initializeApp() {
    this.checkNetwork()
    SplashScreen.hide();
    this.appService.hideLoading()
    if (Capacitor.isNative) {
      // UXCam.optIntoSchematicRecordings() //* To enable session video recording on iOS 
      // UXCam.startWithKey("91ji59eybfstn14")
    }
    ;
    App.addListener('backButton', () => {
      this.ngZone.run(() => {
        if (this.dialog.openDialogs && this.dialog.openDialogs.length)
          this.dialog.closeAll();
        else {
          if (this.router.url == "/home" || this.router.url == "/") {
            const dialogRef = this.dialog.open(CommonDialogComponent, {
              data: {
                message: this.translate.instant('EXIT_APP'),
                onConfirm: () => {
                  App.exitApp()
                }
              }
            });
          }
          else {
            this.router.back("/home");
          }
        }

      })


    })
  
  }

  ngOnInit() {
    // this.appService.hideLoading();
    //UXCam.optIntoSchematicRecordings() //* To enable session video recording on iOS 
    // UXCam.startWithKey("5lozh0ruj4mis6d")
    this.initializeApp()
    this.setUpAnalytics();
    //this.listen();
    /* this.getCurrentPosition();
     this.watchPosition();
     alert(Capacitor.isNative);*/
     const messaging = (async () => {
     
          const isSupportedBrowser = await isSupported();
          if (isSupportedBrowser) {
             this.listen();
          }
          else
          console.log('Firebase not supported this browser');
          
      
      })();
   /*   if ( (window.location.href.indexOf ("/login"))==-1) {
        this.tokenManagementService.getUserInfo().subscribe(res => {
          this.connectedCustomer = res
          this.appService.verifValueUid(this.connectedCustomer)})
        }*/
  }

  
  setUpAnalytics() {

    this.route.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('set', 'page', event.urlAfterRedirects);
        gtag('send', 'pageview');
      }
    });
  }




  listen() {

    if (isSupported()) {
      const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      if (payload) {
        setTimeout(() => {
          this.openDialog(payload);
        }, 800);

      }
   });
    }

    
  }
  openDialog(msg) {
    const dialogRef = this.dialog.open(RequestDialogComponent, {
      width: '350px',
      data: {
        title: msg.notification.title,
        message: msg.notification.body,
        idFsr: msg.data['gcm.notification.id'],
        onConfirm: () => {
        }
      }
    });
  }


  /*async getCurrentPosition() {
    const coordinates = await Geolocation.getCurrentPosition();
    // alert(JSON.stringify(coordinates));
    // this.lng = coordinates.coords.longitude;
    // this.ltd = coordinates.coords.latitude;
    // this.timestamp = coordinates.timestamp;
    
  }

  watchPosition() {
    const wait = Geolocation.watchPosition({}, (position, err) => {
      this.ngZone.run(() => {

        this.lng = position.coords.longitude;
        this.ltd = position.coords.latitude;
        this.timestamp = position.timestamp;
      })
    })
  }*/
}
