import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { FormService } from 'projects/shared/services/form.service';
import { CustomRouter } from '../../services/custom-router';
import { SectionForm, TEXT_PATTERN } from 'projects/shared/tools/constants';
import { CommonDialogComponent } from '../../common-dialog/common-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FarmerStockFeedService } from 'projects/shared/services/farmer-stock-feed.service';
import { StockDto } from 'projects/shared/resources/StockDto';
import { FarmerStockFeedDto } from 'projects/shared/resources/FarmerStockFeedDto';
import { FarmerFeedConsumptionService } from 'projects/shared/services/farmer-feed-consumption.service';
import { from } from 'rxjs';

@Component({
  selector: 'app-edit-stock',
  templateUrl: './edit-stock.component.html',
  styleUrls: ['./edit-stock.component.scss']
})
export class EditStockComponent implements OnInit {


  idFarmer
  idFormTemplate
  formTemplate
  id
  result
  name
  section = SectionForm.farmer_stock
  create = false
  resultValue
  currentRoute
  quantity
  idStock
  textPattern = TEXT_PATTERN
  color='color-stock'
  constructor(public farmerFeedConsumptionService: FarmerFeedConsumptionService, public formService: FormService, public appService: AppService, private farmerStockFeedService: FarmerStockFeedService,
    public translate: TranslateService, private alertsService: AlertsService, public dialog: MatDialog,
    private router: CustomRouter, public route: ActivatedRoute, public formTemplateService: FormTemplateService) {

    const { idFormTemplate, id, quantity, idStock } = this.route.snapshot.params
    this.idFarmer = this.appService.connectedCustomer.id
    this.idFormTemplate = idFormTemplate;
    this.id = id
    this.quantity = quantity
    this.idStock = idStock
    if (this.id)
      this.displayInfo(this.id);
    else this.create = true
    this.currentRoute = this.router.url;
    if (this.router.url.indexOf("addFeedInventory") != -1)
      this.name = "ADD_FEED_INVENTORY"
    else
      this.name = "FEED_INVENTORY"
  }

  ngOnInit(): void {

  }

  findFormTemplate() {
    this.formTemplateService.getForm(this.idFormTemplate, "form-template", this.section).subscribe(template => {
      this.formTemplate = template
    })
  }

  displayInfo(id) {
    let body: FarmerStockFeedDto = new FarmerStockFeedDto();
    this.appService.showLoading()
    this.farmerStockFeedService.getStockFeed(id).subscribe(res => {
      this.result = res;
      body.title = this.result.title;
      body.date = this.result.date;
      body.crudProtein = this.result.crudProtein;
      body.dryMatter = this.result.dryMatter;
      body.fiber = this.result.fiber;
      if (this.currentRoute.indexOf("add") != -1) {
        body.quantity = null;
      }
      else if (this.quantity != null)
        body.quantity = this.quantity
      else
        body.quantity = this.result.quantity;
      body.unit = this.result.unit
      body.unitPrice = (this.result.farmerFeedConsumptions.filter(item => item.id == this.idStock))[0]?.unitPrice;
      body.date = (this.result.farmerFeedConsumptions.filter(item => item.id == this.idStock))[0]?.date;
      this.resultValue = body;
    })
    this.appService.hideLoading()
  }
  confirm(event) {
    this.save(event);
  }
  save(event) {
    
    let form = event.formGroup.value
   if(!this.textPattern.test(form.title)) {
      form.title = form.title.slice(0, -1)  
    }
    else if ((form.unit != 'KG' && form.unit != 'TON') && ((form.crudProtein) || (form.dryMatter)))
      this.appService.error('STOCK_INVALID_UNIT')
    else if ((!(form.quantity >= 0)) || (!form.unitPrice)||(!form.quantity))
      this.appService.error('STOCK_EMPTY')
    else if (this.idStock) {
      this.appService.showLoading();
      this.farmerFeedConsumptionService.update(this.idStock, form).subscribe(res1 => {
        this.appService.hideLoading();
        let message = this.translate.instant('SAVE_SUCESS');
        this.alertsService.open(message);
        this.back();
      }, error => {
        this.appService.error(error.error.code);
      })
    }
    else {

      if (event.formGroup.status == "INVALID")
        this.appService.error('STOCK_INVALID')
      else if ((!form.title) || (!form.unit))
        this.appService.error('STOCK_EMPTY')
      else {
        form.id = this.id
        let method = "saveFarmerStockFeed"
        if (this.result && this.currentRoute.indexOf("add") == -1) {
          method = "update"
        }
        if (this.result)
          form.sumQuantity = this.result.quantity
        this.appService.showLoading();
        this.farmerStockFeedService[method](form).subscribe(res => {
          this.appService.hideLoading();
          let message = this.translate.instant('SAVE_SUCESS');
          this.alertsService.open(message);
          this.back();
        }
          , error => {
            this.appService.error(error.error.code);
          });

      }
    }
  }

  back() {

    this.router.navigateByUrl('stock/feedInventory/' + this.idFormTemplate);

  }
}