<div class="dialog-size-mob">
        <div class="row">
                <div class="text-center col mt-4 mb-4">
                    <img class="moome-style" src="/assets/images/moome.svg" />
                </div>
            </div>
    <div class="row" [ngClass]="{rtl:appService.rtl()}">
        <div class="col mt-3 mb-3">
            <form [formGroup]="formGroup">
                <div class="row">
                    <div class="col mb-4">
                        <input matInput class="input-mob input-width" [type]="hidePass ? 'text' : 'password'" id="newPassword"
                            formControlName="newPassword" [ngClass]="{rtl:appService.rtl()}"
                            placeholder="{{'NEW_PASS' | translate}}">
                        <mat-icon *ngIf="hidePass" class="icon-pos"
                            (click)="!hidePass ? hhidePasside= true : hidePass=false"
                            [ngClass]="appService.rtl()? 'icon-ar-pos' :  'icon-fr-en-pos'" color="primary"> visibility
                        </mat-icon>
                        <mat-icon *ngIf="!hidePass" class="icon-pos"
                            (click)="!hidePass ? hidePass= true : hidePass=false"
                            [ngClass]="appService.rtl()? 'icon-ar-pos' :  'icon-fr-en-pos'" color="primary">
                            visibility_off </mat-icon>
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-4">
                        <input matInput class="input-mob input-width ellipsis-text" [type]="hidePassC ? 'text' : 'password'" formControlName="confNewPassword" id="confNewPassword"
                            [ngClass]="{rtl:appService.rtl()}" placeholder="{{'CONF_NEW_PASSWORD' | translate}}">
                            <mat-icon *ngIf="hidePassC" class="icon-pos"
                            (click)="!hidePassC ? hidePassC= true : hidePassC=false"
                            [ngClass]="appService.rtl()? 'icon-ar-pos' :  'icon-fr-en-pos'" color="primary"> visibility
                        </mat-icon>
                        <mat-icon *ngIf="!hidePassC" class="icon-pos" (click)="!hidePassC ? hidePassC= true : hidePassC=false"
                            [ngClass]="appService.rtl()? 'icon-ar-pos' :  'icon-fr-en-pos'" color="primary">
                            visibility_off </mat-icon>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="float-end"> <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="warn"
                    style="margin-right: 5px" class="button-dialog   text-center" mat-dialog-close> <span
                        [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'"
                        class="center-element">{{ 'CANCEL' | translate }} </span></button>
                       
                <button [ngClass]="{rtl:appService.rtl()}" class="button-dialog button-text-size text-center"
                    mat-raised-button color="primary" (click)="confirm()" [ngClass]="!formGroup.valid ? 'opaci ' : '' "><span
                        [ngClass]="appService.rtl()? 'button-mob-text-ar rtl' : 'button-mob-text'"
                        class="center-element">{{ 'CONFIRM' | translate }} </span></button>
            </div>
        </div>
    </div>
</div>