import { Component, OnInit } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { EntrepriseService } from 'projects/shared/services/entreprise.service';
import { Alert } from 'projects/shared/tools/constants';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomRouter } from '../services/custom-router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-milk-collection-center',
  templateUrl: './milk-collection-center.component.html',
  styleUrls: ['./milk-collection-center.component.scss']
})
export class MilkCollectionCenterComponent implements OnInit {
  currentRoute: any
  milkCollectionCenters: any
  milkCollectionEmpty: any
  supervisorId: any;
  constructor(public router: CustomRouter, public appService: AppService, private entrepriseService: EntrepriseService, private route: ActivatedRoute,
    private alertsService: AlertsService, private translate: TranslateService) { }

  ngOnInit() {
    this.currentRoute = this.router.url
    if(this.currentRoute.indexOf("supervisors")!= -1)
    this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
    else
    this.appService.getConnected().subscribe(customer => {
      this.supervisorId = customer.id;

     });
    this.loadMCC(this.supervisorId)
  }

  loadMCC(supervisorId?) {
    this.appService.showLoading()
    this.entrepriseService.getEntreprisesOfConnected(supervisorId).subscribe(data => {
      
      this.milkCollectionCenters = data
      this.milkCollectionEmpty = !data ;
      this.appService.hideLoading()
    }, error => {
      this.appService.hideLoading();
     let  message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.code);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK )
      this.alertsService.open(message, alert);
    });
  }

  message(msg) {
    this.appService.hideLoading();
    let message = this.translate.instant(msg);
    let alert = this.translate.instant(Alert.OK)
    this.alertsService.open(message, alert);
  }
  back() {
    if (this.currentRoute.startsWith('/supervisors')) {
      this.router.navigateByUrl('/supervisors');
    }
    else
      this.router.navigateByUrl('/home')
    this.appService.verif = false
  }
}
