
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { SubscriptionRequestHistory } from '../resources/SubscriptionRequestHistory';
import { ConfigService } from './config.service';
import { MoomeRestService } from './moome-rest.service';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionRequestHistoryService extends MoomeRestService<SubscriptionRequestHistory> {


    constructor(public http: HttpClient, injector: Injector, private configService: ConfigService) {
        super(SubscriptionRequestHistory);
    }

    getLastHistory() {
        return this.customRequestGet(`/getLast`)
    }

    saveSubscriptionHistory() {
        return this.customRequestPost(`/save`);
    }

}

