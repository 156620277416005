import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { AppService } from 'projects/shared/services/app.service';
import { SectionForm } from 'projects/shared/tools/constants';
import { CustomRouter } from '../../../services/custom-router';
import { FarmerStockFeedService } from 'projects/shared/services/farmer-stock-feed.service';
import { SettingsText } from '../../settings-animal/settings-animal.component';
import { SettingCustomerDialogComponent } from '../../settings-customer/setting-customer-dialog/setting-customer-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FarmerMilkProductionService } from 'projects/shared/services/farmer-milk-production.service';
import { AlertsService } from 'projects/shared/services/alerts.service';
//import { FormTemplateService } from 'projects/shared/services/form-template.service';
@Component({
  selector: 'app-common-setting-form',
  templateUrl: './common-setting-form.component.html',
  styleUrls: ['./common-setting-form.component.scss']
})
export class CommonSettingFormComponent implements OnInit {
  data
  authority
  idFormTemplate
  idFeed
  //dataAdd: any;
  key: any
  /* idSupervisor:any;
   isSupervisor:Boolean=false;*/
  @Output() load = new EventEmitter();
  @Output() routerBack = new EventEmitter();
  @Output() itemClick = new EventEmitter();
  @Output() search = new EventEmitter();
  @Input() forms
  @Input() service
  @Input() name: String
  @Input() title: String
  @Input() section
  @Input() color: String
  sectionStock = SectionForm.farmer_stock
  historyFeed: boolean = false
  historyMisc: boolean = false
  feedInventory: boolean = false
  formsValues
  listIds
  value=[]
  sectionFarmerMilkPorduction=SectionForm.farmer_milk_production
  selectedFormIds: any[] = [];
  constructor(public alertsService: AlertsService, public farmerMilkProductionService: FarmerMilkProductionService, public dialog: MatDialog, public dialogRef: MatDialogRef<SettingCustomerDialogComponent>, private farmerStockFeedService: FarmerStockFeedService, private router: CustomRouter, public codeTranslate: CodeTranslatePipe,
    public translate: TranslateService,
    // private formTemplateService: FormTemplateService,
    public route: ActivatedRoute,
    public appService: AppService) {

    const { idFormTemplate, idFeed } = this.route.snapshot.params;

    this.idFormTemplate = idFormTemplate;
    this.idFeed = idFeed

    /* this.formTemplateService.getForm(idFormTemplate, "form-template").subscribe(template => {
       const stateSettingAdd = template;
       if (stateSettingAdd) 
         this.dataAdd = stateSettingAdd
 
       const stateSetting = template;
       if (stateSetting)
         this.data = stateSetting
 
     })*/

    if (this.router.url.indexOf("historyFeedInventory") != -1)
      this.historyFeed = true;
    if (this.router.url.indexOf("historyMiscInventory") != -1)
      this.historyMisc = true;
    if (this.router.url.indexOf("feedInventory") != -1)
      this.feedInventory = true;
    if ((this.feedInventory) && (this.router.url.indexOf("search") == -1)) {
      this.calculSommeCrudeDry()
    }
  }


  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.key = params.key;
    })
    if (this.key)
      this.searchSettings()
    //else
    //this.findForms()
  }
  getFormYears(forms: any): string[] {
    const sortedKeys = Object.keys(forms).filter(year => year !== 'monthValues').sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    return sortedKeys;
  }

  calculSommeCrudeDry() {
    this.appService.sommeDry = 0;
    this.appService.sommeCrude = 0;
    this.farmerStockFeedService.findByFarmer().subscribe(res => {
      this.formsValues = this.appService.organizedData(res, this.appService.dataAddStockFeed);
      this.formsValues?.forEach(res1 => {
        if (res1.crudProtein || res1.dryMatter) {
          if (res1.unit == "TON") {
            this.appService.sommeCrude += (res1.quantity * 1000 * res1.crudProtein) / 100;
            this.appService.sommeDry += (res1.quantity * 1000 * res1.dryMatter) / 100;
          }
          if (res1.unit == "KG") {
            this.appService.sommeCrude += (res1.quantity * res1.crudProtein) / 100;
            this.appService.sommeDry += (res1.quantity * res1.dryMatter) / 100;
          }
        }
      })
    })
  }

  back() {
    this.routerBack.emit()
  }

  action(item?) {
    this.itemClick.emit(item)

  }

  findForms() {
    this.load.emit();
  }

  searchSettings() {
    this.search.emit({ "key": this.key, /*"dataAdd": this.dataAdd*/ });
  }

  editMilkPrice() {
    const dialogRef = this.dialog.open(SettingCustomerDialogComponent, {
      data: {
        title: this.translate.instant('EDIT_MILK_PRICE_FOR')+this.appService.listId.length+this.translate.instant('INPUTS'),
        editType: 'editMilkPrice',
        value:'price',
        onConfirm: (formGroup) => {
          this.farmerMilkProductionService.updatePriceMilkProduction(this.appService.listId,formGroup.controls['price'].value).subscribe(element => {
            this.succesMessage()
            this.appService.listId=[]
            this.load.emit();
          })

        }
      }
    });
  }
 

  onFormSelectionChange(formId: any) {
    this.listIds = formId
  }
  succesMessage() {
    this.dialog.closeAll();
    let message: string;
    this.appService.hideLoading();
    message = this.translate.instant('SAVE_SUCESS');
    this.alertsService.open(message);
  }
}
