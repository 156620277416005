import { Injectable, Injector } from '@angular/core';
import { Species } from '../resources/Species';
import { MoomeRestService, Options } from './moome-rest.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
	providedIn: 'root'
})

export class SpeciesService extends MoomeRestService<Species> {
	constructor(injector: Injector, private http: HttpClient, private configService: ConfigService) {
		super(Species);
	}

	getSpeciesWithFilter(page, size, sort, key) {

		let param: any = {}
		param.projection = 'animalTypes'
		if (key != null)
			param.key = key.toLowerCase();
		if (sort != null)
			param.sort = sort;


		let options: Options = {
			params:
				param
			,
			pageParams: {
				page: page,
				size: size,
			}
		}

		return this.searchPages(`getSpeciesByKey`, options)
	}

	isHasBreeds(animalType): Observable<any> {
		return this.http.get(this.configService.getApiPath() + "/animalTypes/hasBreeds/" + animalType);
	}
	getByCode(code) {
		return this.searchSingle(`getByCode?code=${code}&projection=animalTypes`)
	}

}
