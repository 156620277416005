import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfigService } from 'projects/shared/services/config.service';
import { WelcomeModule } from './welcome/welcome.module';
import { StartModule } from './start/start.module';
import { MenuModule } from './menu/menu.module';
import { LoginModule } from './login/login.module';
import { HomeModule } from './home/home.module';
import { HeaderModule } from './header/header.module';
import { GetStartedModule } from './get-started/get-started.module';
import { EntryModule } from './entry/entry.module';
import { SignUpModule } from './sign-up/sign-up.module';
import { AlertsModule } from './alerts/alerts.module';
import { SmsVerificationModule } from './sign-up/sms-verification/sms-verification.module';
import { SettingsAnimalModule } from './settings/settings-animal/settings-animal.module';
import { AnimalsModule } from './animals/animals.module';
import { TimelinesModule } from './timelines/timelines.module';
import { AlertTypeModule } from './alerts/alert-type/alert-type.module';
import { EditAnimalModule } from './animals/edit-animal/edit-animal.module';
import { GroupsModule } from './animals/groups/groups.module';
import { NotesModule } from './notes/notes.module';
import { PedigreeModule } from './pedigree/pedigree.module';
import { EditNoteModule } from './notes/edit-note/edit-note.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PublicHttpInterceptor } from './interceptor/auth-http.interceptor';
import { AlertComponent } from 'projects/shared/components/alert/alert.component';
import { PublicV2ConfigService } from './services/publicV2-config.service';
import { FarmersModule } from './farmers/farmers.module';
import { ResultSearchModule } from './farmers/result-search/result-search.module';
import { MilkCollectionCenterModule } from './milk-collection-center/milk-collection-center.module';
import { TanksModule } from './tanks/tanks.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NetworkDetectionDialogComponent } from './network-detection-dialog/network-detection-dialog.component';
import { NetworkDetectionDialogModule } from './network-detection-dialog/network-detection-dialog.module';
import { ThiModule } from './thi/thi.module';
import { CommunGraphModule } from 'projects/shared/components/commun-graph/commun-graph.module';
import { FarmerAnimalTableModule } from 'projects/shared/components/farmer-animal-table/farmer-animal-table.module';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { environment } from 'projects/publicV2/src/environments/environment';
import { initializeApp } from "firebase/app";
initializeApp(environment.firebase);
import { SettingsFormModule } from './settings/settings-form/settings-form.module';
import { ReportModule } from './report/report.module';
import { NgxHateoasClientConfigurationService, NgxHateoasClientModule } from '@lagoshny/ngx-hateoas-client';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { WeeklyPlanManagementModule } from './weekly-plan-management/weekly-plan-management.module';
import { ReproductionReportModule } from './reproduction-report/reproduction-report.module';
import { RecaptchadModule } from './recaptcha/recaptcha.module';
import { FeedbackModule } from './feedback/feedback.module';
import { SettingsGraphModule } from './settings/settings-graph/settings-graph.module';
import { MilkCollectedGraphModule } from './milk-collected-graph/milk-collected-graph.module';
import { CalvingGraphModule } from './calving-graph/calving-graph.module';
import { MilkQuantityCowModule } from './milk-quantity-cow/milk-quantity-cow.module';
import { EconomyModule } from './economy/economy.module';
import { StockModule } from './stock/stock.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashbordCardComponent } from './dashboard/dashbord-card/dashbord-card.component';
import { HerdComponent } from './herd/herd.component';
import { HerdCardComponent } from './herd/herd-card/herd-card.component';
import { CarbonEmissionsComponent } from './carbon-emissions/carbon-emissions.component';
import { FarmEcoComponent } from './farm-eco/farm-eco.component';
import { MilkProductionComponent } from './milk-production/milk-production.component';
import { HealthComponent } from './health/health.component';
import { CercleCardComponent } from './components/cercle-card/cercle-card.component';
import { RectangleCardComponent } from './components/rectangle-card/rectangle-card.component';
import { ProductionComponent } from './production/production.component';
import { FeedComponent } from './feed/feed.component';
import { LargeRectCardComponent } from './components/large-rect-card/large-rect-card.component';
import { BarComponent } from './components/bar/bar.component';
import { FooterComponent } from './components/footer/footer.component';
@NgModule({
    declarations: [
        AppComponent, AlertComponent, ContactUsComponent, CarbonEmissionsComponent, FooterComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        WelcomeModule,
        StartModule,
        MenuModule,
        LoginModule,
        HomeModule,
        HeaderModule,
        GetStartedModule,
        EntryModule,
        SignUpModule,
        AlertsModule,
        AlertTypeModule,
        SmsVerificationModule,
        SettingsFormModule,
        SettingsAnimalModule,
        AnimalsModule,
        TimelinesModule,
        EditAnimalModule,
        GroupsModule,
        NotesModule,
        PedigreeModule,
        EditNoteModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FarmersModule,
        ResultSearchModule,
        MilkCollectionCenterModule,
        TanksModule,
        NetworkDetectionDialogModule,
        ThiModule,
        IonicModule.forRoot(),
        CommunGraphModule,
        FarmerAnimalTableModule,
        ReportModule,
        NgxHateoasClientModule.forRoot(),
        RecaptchadModule,
        WeeklyPlanManagementModule,
        ReproductionReportModule,
        FeedbackModule,
        SettingsGraphModule,
        MilkCollectedGraphModule,
        CalvingGraphModule,
        MilkQuantityCowModule,
        EconomyModule,
        StockModule
    ],
    providers: [
        {
            provide: ConfigService,
            useClass: PublicV2ConfigService,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: PublicHttpInterceptor,
            multi: true
        },
        AppVersion
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(hateoasConfig: NgxHateoasClientConfigurationService) {
    hateoasConfig.configure({
      http: {
        defaultRoute: {
          rootUrl: environment.apiPath
        }
      }
    });
  }
}


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http/*, environment.rootUrl+"/i18n/", ".json"*/);
}
