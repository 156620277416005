import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EconomyComponent } from './economy.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { SectionBarModule } from '../animals/section-bar/section-bar.module';
import { CommonSettingModule } from '../settings/common-setting/common-setting.module';



@NgModule({
  declarations: [EconomyComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    CommonSettingModule,
    SectionBarModule,
    MoomeCommonModule
  ], exports: [
    EconomyComponent
  ],
})
export class EconomyModule { }
