import { Injectable, NgZone } from '@angular/core';
import { CustomRouter } from 'projects/publicV2/src/app/services/custom-router';
import { LivestockIdentif } from '../tools/constants';
import { AnimalService } from './animal.service';
import { AppService } from './app.service';
import { PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { FcmService } from './fcm.service';
import { ActionPerformed, LocalNotifications } from '@capacitor/local-notifications';
const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
@Injectable({
  providedIn: 'root'
})

export class LocalNotificationService {

  auth: string[] = [];
  role: any;
  idsList = []
  language;
  localNotifAlerts: any[] = []
  label
  nationalIdentification = LivestockIdentif.NATIONAL_IDENTIFICATION
  nameLocalCode = LivestockIdentif.NAME_LOCALCODE
  constructor(private ngZone: NgZone, private router: CustomRouter, public appService: AppService, private animalService: AnimalService, private fcmService: FcmService) {
    if (isPushNotificationsAvailable)
      this.receiveLocalNotif(this.appService.checkRole())
  }

  receiveLocalNotif(role) {
    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));

         let id = (new Date()).getTime()
         LocalNotifications.schedule({
           notifications: [
             {
               id: id,
               title: notification.data.uid ? notification.data.uid : (notification.data.name ? notification.data.name : notification.title),
               body: notification.body,
               extra: {
                 typeAlert: notification.data.typeAlert,
                 uid: notification.data.uid,
                 name: notification.data.name,
                 mccId: notification.data.mccId,
                 idFarmerSupervisorReques: notification.data.idFarmerSupervisorRequest,
                 message: notification.data.message,
                 title: notification.data.title
               }
             }
           ]
         });
      }
    );

    LocalNotifications.addListener('localNotificationActionPerformed',
      (notificationAction: ActionPerformed) => {
        this.ngZone.run(() => {
          console.log(notificationAction)
          let uid = notificationAction.notification.extra.uid
          let name = notificationAction.notification.extra.name
          let mccId = notificationAction.notification.extra.mccId
          let typeAlert = notificationAction.notification.extra.typeAlert
          let idFarmerSupervisorRequest = notificationAction.notification.extra.idFarmerSupervisorRequest
          let message = notificationAction.notification.extra.message
          let title = notificationAction.notification.extra.title
          console.log('Local received: uid: ' + uid + 'name:' + name + 'mccId:' + mccId + 'typeAlert:' + typeAlert + 'idFarmerSupervisorRequest:' + idFarmerSupervisorRequest);

          this.fcmService.actionRouter(uid, name, mccId, typeAlert, role, idFarmerSupervisorRequest, message, title)
        })
      });
  }

  /* sendLocalNotif(animalId) {
     
     this.language = this.tokenManagementService.getTranslateLanguage();
     this.idsList = [];
     this.localNotifAlerts = [];
     //  let value=this.valueCustomerSetting()
 
     this.customerAlertService.getUnreadLocalNotifAlerts(animalId).subscribe(data => {
       this.localNotifAlerts = data;
       for (let i = 0; i < this.localNotifAlerts.length; i++) {
         this.idsList.push(this.localNotifAlerts[i].alertId)
         if (this.language == "en")
           this.label = this.localNotifAlerts[i].labelEn;
         else if (this.language == "fr")
           this.label = this.localNotifAlerts[i].labelFr;
         else
           this.label = this.localNotifAlerts[i].labelAr;
         let value = ((this.appService.valueUid == this.nationalIdentification) ? ((this.localNotifAlerts[i].uid) ? (this.localNotifAlerts[i].uid) : (this.localNotifAlerts[i].name)) : ((this.appService.valueUid == this.nameLocalCode) ? ((this.localNotifAlerts[i].name) ? (this.localNotifAlerts[i].name) : (this.localNotifAlerts[i].uid)) : (((this.localNotifAlerts[i].uid) ? (this.localNotifAlerts[i].uid) : (this.localNotifAlerts[i].name)))))
         this.showLocalNotification(i, value, this.label);
       }
       if (this.idsList.length > 0)
         this.customerAlertService.updatecustomerAlertStatus(this.idsList).subscribe(data => {
           console.log(data)
         })
       this.appService.getUnreadAlertCount()
 
 
     });
   }*/


  /*showLocalNotification(title: string, text: string) {
    let id = (new Date()).getTime()
    LocalNotifications.schedule({
      notifications: [
        {
          id: id,
          title: title,
          body: text,
        }
      ]
    });
    console.log('Local received: id: ' + id + ' title:' + title + 'text:' + text);

  }*/

  /*navigateToPageAfterActioninLocalNotif() {
    LocalNotifications.addListener('localNotificationActionPerformed',
      (notificationAction: ActionPerformed) => {
        this.ngZone.run(() => {
          console.log(notificationAction)
          let title = notificationAction.notification.title
          if (title != null)
            this.animalService.getByUidOrByName(title).subscribe(animal => {
              console.log(animal)

              let diff = moment().diff(moment(animal.birthDate), "days")
              let month = (Math.floor(diff / 30));
              console.log(month)

              if ((animal.sexe == Sexe.FEMALE && month > 14) || ((animal.birthDate == null) && (animal.sexe == Sexe.FEMALE) && (animal.lactNum != null))) {
                console.log("true")
                this.appService.connectedCustomer['roles'].forEach(role => {
                  this.auth.push((role.isSupervisor).toString())
                })
                if (this.auth.indexOf("true") != -1)
                  this.role = true;
                if (this.role)
                  this.router.navigateByUrl('/farmers/' + animal['customerId'] + '/animals/' + animal.id + '/timelines')
                else this.router.navigateByUrl('/animals/' + animal.id + '/timelines')
              } else {
                this.appService.getDefaultGroup().subscribe(defaultGroupId => {
                  if (this.role)
                    this.router.navigateByUrl('/farmers/' + animal['customerId'] + '/animals/groups/' + defaultGroupId)
                  else this.router.navigateByUrl("/animals/groups/" + defaultGroupId)
                })

              }

            })
        })
      })
  }*/
}

