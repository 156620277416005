import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PedigreeComponent } from './pedigree.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AnimalBarModule } from '../animals/animal-bar/animal-bar.module';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';



@NgModule({
  declarations: [PedigreeComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    AnimalBarModule,
    MoomeCommonModule
  ]
})
export class PedigreeModule { }
