import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Authorities, Alert, SectionForm } from 'projects/shared/tools/constants';
import { ReportService } from 'projects/shared/services/report.service';
import * as FileSaver from "file-saver"
import { SelectionModel } from '@angular/cdk/collections';
import { AnimalService } from 'projects/shared/services/animal.service';
import { CustomRouter } from '../services/custom-router';
import { CustomerService } from 'projects/shared/services/customer.service';
import { Project } from 'projects/shared/resources/Project';
import { ProjectService } from 'projects/shared/services/project.service';
import { FarmerService } from 'projects/shared/services/farmer.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import * as moment from 'moment';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { MatOption } from '@angular/material/core';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { CapacitorUtils } from 'projects/shared/tools/CapacitorUtils';

export class FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  checked?: boolean
  item: any;
}


enum NodeType {
  FARMER, GROUP, ANIMAL
}
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],

})

export class ReportComponent implements OnInit {
  projection = "form-template"
  relatedTo = "farmer"

  hasChild = (_: number, node: FlatNode) => node.expandable;
  projects: any=[];
  selectedProjects: any=[];
  formGroup: UntypedFormGroup;
  columns: String[] = ["UID", "CODE", "BREED", "SEX", "AGE", "WEIGHT", "LACT", "LACTNUM", "GEST"];
  selectedListColumns: String[] = [];
  selectedListColumn: String[] = ["UID", "CODE", "BREED", "SEX", "AGE", "WEIGHT", "LACT", "LACTNUM", "GEST"];
  selectedListAnimals: any=[];
  animals: any=[];
  selectedListFarmers: any = []
  create: boolean
  getFarmer: boolean
  listAnimals: any[] = []
  listProjects: any[] = []
  farmers: any
  selection = new SelectionModel<any>(true, []);
  list = []
  formatArabe: any
  allAnimal: any[] = [];
  searchListA: any[] = [];
  listIdAnimals = [];
  selectedAnimals = [];
  newItems = {};
  startDate;
  endDate;
  allGroup: any[] = [];
  listQualities: any[] = [];
  display = false
  selectedListColumnQuality = []
  columnsQuality = []
  idsProject = []
  noProject: Boolean = false
  showAll: Boolean = false
  select
  isAllSelected
  @ViewChild('allSelectedProject') private allSelectedProject: MatOption;
  @ViewChild('allSelectedFarmer') private allSelectedFarmer: MatOption;
  @ViewChild('allSelectedColumn') private allSelectedColumn: MatOption;
  @ViewChild('allSelectedQuality') private allSelectedQuality: MatOption;

  allFarmers;

  constructor(public formTemplateService: FormTemplateService, public tokenManagementService: TokenManagementService,
    private farmerService: FarmerService, private projectService: ProjectService, private customerService: CustomerService,
    public router: CustomRouter, public appService: AppService, private formBuilder: UntypedFormBuilder,
    public alertsService: AlertsService, public translate: TranslateService, public codeTranslate: CodeTranslatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any, public reportService: ReportService, public animalService: AnimalService) {

    this.createForm();

    this.appService.connectedCustomer['roles'].forEach(element => {
      if (element.publicAccess == true && element.isSupervisor == true)
        element['authorityRoles'].forEach(authorityRole => {
          if (authorityRole.authority.code == Authorities.REPORTS_EXPORT_LIVESTOCK)
            this.create = authorityRole["create"];

          if (authorityRole.authority.code == Authorities.SUPERVISORS)
            this.getFarmer = authorityRole["get"];
        });
    });
  }

  ngOnInit() {
    this.formatArabe = this.appService.verifFormatDate()
    this.loadProjects();
    this.loadAllFarmers();
    this.loadForm()
  }


  tosslePerOneProject() {
    if (this.allSelectedProject.selected) {
      this.allSelectedProject.deselect();
      this.selectedListFarmers = this.allFarmers
      return false;
    }

    if (this.formGroup.controls['selectedProjects'].value.length == this.projects.length) {
      this.allSelectedProject.select();
      this.loadFarmersByProject(this.idsProject)
    }

    if (this.formGroup.controls['selectedProjects'].value.length > 0)
      this.loadFarmersByProject(this.formGroup.controls['selectedProjects'].value)
    else
      this.selectedListFarmers = this.allFarmers
  }

  toggleAllSelectionProject() {
    if (this.allSelectedProject.selected) {
      this.formGroup.controls['selectedProjects']
        .patchValue([...this.idsProject, 0]);
      this.loadFarmersByProject(this.idsProject)
    } else {
      this.formGroup.controls['selectedProjects'].patchValue([]);
      this.selectedListFarmers = this.allFarmers
    }
  }

  loadAnimalsAfterChek(value) {
    if (this.formGroup.controls['selectedProjects'].value)
      this.loadAnimals(this.formGroup.controls['selectedProjects'].value, value)
    else this.loadAnimalsForFarmers(value)
  }


  tosslePerOneFarmer() {
    if (this.allSelectedFarmer.selected) {
      this.allSelectedFarmer.deselect();
      this.allAnimal = []
      this.organiseTreeDataAnimal({});
      this.animals = this.selectedListAnimals = [];
      this.newItems = {};
      return false;
    }
 /*   if (this.formGroup.controls['selectedFarmers'].value.length == this.selectedListFarmers.length) {
      this.allSelectedFarmer.select();
      this.formGroup.controls['selectedFarmers'].setValue(this.allFarmers.map(item => item.id))
    }*/

    this.loadAnimalsAfterChek(this.formGroup.controls['selectedFarmers'].value)

  }

  toggleAllSelectionFarmer() {
    if (this.allSelectedFarmer.selected) {
      this.formGroup.controls['selectedFarmers']
        .patchValue([...this.selectedListFarmers.map(item => item.id), 0]);
      this.loadAnimalsAfterChek(this.formGroup.controls['selectedFarmers'].value)

    } else {
      this.formGroup.controls['selectedFarmers'].patchValue([]);
      this.allAnimal = []
      this.organiseTreeDataAnimal({});
      this.animals = this.selectedListAnimals = [];
      this.newItems = {};
    }

  }

  fixArabeFormat() {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD/MMM/YYYY"
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'selectedProjects': [],
      'selectedFarmers': [null, Validators.required],
      'selectedColumns': [null],
      'selectedAnimals': [null],
      'startDate': [moment()],
      'endDate': [moment()],
      'listQualities': [[]],
      'showQuantity': [false]
    });
  }

  loadProjects() {
    this.projectService.getProjectOfConnected().subscribe(res => {
      this.selectedProjects = this.projects = res
      this.idsProject = this.projects?.map(item => item.id)
    })
  }

  showProjects() {
    if (!this.projects) {
      this.noProject = true
      let message = this.translate.instant("NO_PROJECT");
      let alert = this.translate.instant(Alert.OK)
      this.alertsService.open(message, alert);
    }
  }

  searchProject(value: string) {
    let filter = value.toLowerCase();
    return this.projects.filter(option => option['labelEn'].toLowerCase().includes(filter));
  }

  onKeyProject(value) {
    this.selectedProjects = this.searchProject(value);
  }

  loadAllFarmers() {

    this.farmerService.getFarmerOfSupervisor().subscribe(res => {
      this.allFarmers = res
      this.farmers = this.selectedListFarmers = res;
    })

  }

  searchFarmer(value: string) {
    let filter = value.toLowerCase();
    return this.farmers.filter(option => option['fullname'].toLowerCase().includes(filter));
  }

  onKeyFarmer(value) {
    this.selectedListFarmers = this.searchFarmer(value);
  }

  loadFarmersByProject(projects) {
    if (projects.length > 0) {
      this.farmerService.getFarmerOfSupervisorByProject(projects).subscribe(res => {
        this.allFarmers = res['_embedded'].customers
        this.farmers = this.selectedListFarmers = res['_embedded'].customers;
      })
      /* this.allAnimal = []
       this.farmers = this.selectedListFarmers = []
       this.organiseTreeDataAnimal({});
       this.animals = this.selectedListAnimals = [];
       this.dataSourceAnimal.data = this.allAnimal
       this.farmerService.getFarmerOfSupervisorByProject(projects).subscribe(res => {
         this.farmers = this.selectedListFarmers = res;
         if (res.length == 0) {
           let message = this.translate.instant("NO_FARMERS");
           let alert = this.translate.instant(Alert.OK)
           this.alertsService.open(message, alert);
         }
       })*/
    }
    else
      this.loadAllFarmers();
  }

  loadAnimals(projectIds, farmerIds) {
    this.newItems = {};
    this.animalService.getAnimalsByGroupByProject(projectIds, farmerIds).subscribe(res => {
      this.newItems = this.groupByType(res['resources']);
      this.organiseTreeDataAnimal(this.newItems);
      this.animals = this.selectedListAnimals = res['resources'];
      this.dataSourceAnimal.data = this.allAnimal
      this.checklistSelection.clear();
      if ((this.dataSourceAnimal.data).length > 0)
        this.showAll = true

    })
  }

  loadAnimalsForFarmers(farmerIds) {
    this.newItems = {};
    this.animalService.getAllAnimalByFarmers(farmerIds).subscribe(res => {
      this.newItems = this.groupByType(res['resources']);
      this.organiseTreeDataAnimal(this.newItems);
      this.animals = this.selectedListAnimals = res;
      this.dataSourceAnimal.data = this.allAnimal
      this.checklistSelection.clear();
      if ((this.dataSourceAnimal.data).length > 0)
        this.showAll = true
    })
  }

  groupByType(array) {
    return array.reduce((r, a) => {
      r[a.customerFullName] = r[a.customerFullName] || [];
      r[a.customerFullName].push(a);
      return r;
    }, Object.create(null));
  }

  getKeys(o) {
    return Object.keys(o)
  }

  organiseTreeDataAnimal(data) {
    this.allAnimal = []
    this.getKeys(data).forEach(key => {
      data[key].forEach(animal => {
        animal['type'] = NodeType.ANIMAL
      })
      this.allAnimal.push({ name: key, animals: data[key], type: NodeType.FARMER, id: -1 })
    })
  }

  back() {
    this.router.back('')
  }


  loadForm() {
    this.formTemplateService.getForms(this.projection,SectionForm.advisor_farmer_settings, this.relatedTo).subscribe(res => {
      let selectedFormTemplate = res.find(f => f.xml.type == 'MILK_QUALITY')
      this.selectedListColumnQuality = this.columnsQuality = selectedFormTemplate.xml.formFields.filter(f => f.code.endsWith("_BALANCE"))
    }, error => {
      this.error(error.error.error)
    })
  }

  error(message) {
    this.appService.hideLoading();
    let alert = this.translate.instant(Alert.OK)
    this.alertsService.open(this.translate.instant(message), alert);
  }

  tosslePerOneColumn() {
    if (this.allSelectedColumn.selected) {
      this.allSelectedColumn.deselect();
      return false;
    }
    if (this.formGroup.controls['selectedColumns'].value.length == this.columns.length)
      this.allSelectedColumn.select();

  }
  toggleAllSelectionColumn() {
    if (this.allSelectedColumn.selected) {
      this.formGroup.controls['selectedColumns']
        .patchValue([...this.selectedListColumn.map(item => item), 0]);
    } else {
      this.formGroup.controls['selectedColumns'].patchValue([]);
    }
  }

  tosslePerOneQuality() {
    if (this.allSelectedQuality.selected) {
      this.allSelectedQuality.deselect();
      return false;
    }
    if (this.formGroup.controls['listQualities'].value.length == this.columns.length)
      this.allSelectedQuality.select();
  }

  toggleAllSelectionQuality() {
    if (this.allSelectedQuality.selected) {
      this.formGroup.controls['listQualities']
        .patchValue([...this.selectedListColumnQuality.map(item => item.code), 0]);
    } else {
      this.formGroup.controls['listQualities'].patchValue([]);
    }
  }

  searchColumn(list, value: string) {
    let filter = value.toLowerCase();
    return list.filter(option => option.toLowerCase().includes(filter));
  }

  onKeyColumn(value) {
    this.selectedListColumn = this.searchColumn(this.columns, value);
  }

  onKeyColumnQuality(value) {
    this.selectedListColumnQuality = this.searchColumn(this.columnsQuality, value);
  }

  transformerAnimal(node, level: number): FlatNode {
    if (node.type == NodeType.FARMER) {
      node.expandable = !!node.animals && node.animals.length > 0
    }
    if (node.type == NodeType.ANIMAL) {
      node.expandable = false
      node.name = (node.uid ? node.uid : node.name)
    }

    return {
      expandable: node.expandable,
      name: node.name,
      level: level,
      item: node,
      checked: node.checked
    };
  }

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable);

  treeFlattenerAnimal = new MatTreeFlattener(

    this.transformerAnimal, node => {
      return node.level
    }, node => node.expandable, node => {
      node.children = node.animals
      return node.children
    });

  check(node: FlatNode, event) {
    if (!node.item)
      node.item = node
    this.updateSelected([node], event.checked)
  }

  updateSelected(nodes: FlatNode[], checked) {
    nodes.forEach(node => {

      if (node.item.type == NodeType.ANIMAL) {
        if (checked) {
          let index = this.selectedAnimals.indexOf(node.item);
          if (index < 0)
            this.selectedAnimals.push(node.item);
        } else {
          let index = this.selectedAnimals.indexOf(node.item);
          this.selectedAnimals.splice(index, 1);
        }
      }

    })
    this.createListId();
  }

  createListId() {
    this.listIdAnimals = [];
    this.selectedAnimals.forEach(element => {
      this.listIdAnimals.push(element.id);
    })

  }

  dataSourceAnimal = new MatTreeFlatDataSource(this.treeControl, this.treeFlattenerAnimal);


  apply() {
    //  if(this.create) {
      let type=".pdf"
    this.appService.showLoading();
    let animals = this.checklistSelection['_selection']
    animals.forEach(elem => {
      if (!elem.item.animals)
        this.selectedAnimals.push(elem.item.id);
    })
    this.startDate = moment(this.formGroup.controls['startDate'].value).toISOString();
    this.endDate = moment(this.formGroup.controls['endDate'].value).toISOString();
    let listFarmers = []
    if (this.formGroup.controls['selectedFarmers'].value)
      listFarmers = (this.formGroup.controls['selectedFarmers'].value).filter(element => element != 0)

    let listColumns = []
    if (this.formGroup.controls['selectedColumns'].value)
      listColumns = (this.formGroup.controls['selectedColumns'].value).filter(element => element != 0)
    let listQualities = []
    if (this.formGroup.controls['listQualities'].value)
      listQualities = this.formGroup.controls['listQualities'].value.filter(element => element != 0)
    this.reportService.export(listFarmers, this.selectedAnimals, listColumns, this.formGroup.controls['showQuantity'].value, listQualities, this.startDate, this.endDate).subscribe(data => {
      let blob = new Blob([data], { type: 'application/pdf' });
      let filename = this.translate.instant('REPORT_MOOME') + moment(new Date()).format('yyyy-MM-DD HH:mm') 
      if (CapacitorUtils.isApp){
        CapacitorUtils.writeAndOpenFile(blob, filename + type);
        this.appService.hideLoading();
        let message = this.translate.instant('SAVE_FILE_SUCESS');
        this.alertsService.open(message);}
      else{
        this.appService.saveAs(blob, filename,type);
      this.appService.hideLoading();}
    }, error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.code);
      let alert = this.translate.instant(Alert.OK)
      this.alertsService.open(message, alert);
    })
    //this.checklistSelection.clear();
   this.selectedAnimals = [];
    this.isAllSelected = false;

    //  }
  }



  checklistSelection = new SelectionModel<FlatNode>(true /* multiple */);


  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: FlatNode): void {

    let parent: FlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: FlatNode): void {

    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }
  getLevel = (node: FlatNode) => node.level;


  /* Get the parent node of a node */
  getParentNode(node: FlatNode): FlatNode | null {

    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }


  selectAll(): void {
    this.select = !this.select;
    this.treeControl.dataNodes.forEach((r, index) => {
      this.treeControl.expand(this.treeControl.dataNodes[index]);
      if (this.select)
        this.checklistSelection.select(this.treeControl.dataNodes[index])
      else {
        this.checklistSelection.deselect(this.treeControl.dataNodes[index]);
        this.checklistSelection.clear();

      }
    });
    this.isAllSelected = true;
  }


}
