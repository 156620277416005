import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenManagementService } from './token-management.service';
import { ConfigService } from './config.service';

@Injectable({
	providedIn: 'root',
})
export class ReportService {
	constructor(private http: HttpClient, public tokenManagementService: TokenManagementService, private configService: ConfigService) {
	}

	getFarmersForThi(/*id?*/): Observable<any> {
		/*	if (id == null || !id)
				id = ""
			return this.http.get(this.configService.getApiPath() + `/reports/graphs/farmers/thi?id=${id}`);*/

		return this.http.get(this.configService.getApiPath() + `/reports/graphs/farmers/thi`);
	}

	getFarmersForRummination(/*id?*/): Observable<any> {
		/*	if (id == null || !id)
				id = ""
			return this.http.get(this.configService.getApiPath() + `/reports/graphs/farmers/rumination?id=${id}`);*/

		return this.http.get(this.configService.getApiPath() + `/reports/graphs/farmers/rumination`);
	}

	getListAnimals(id): Observable<any> {
		return this.http.get(this.configService.getApiPath() + `/reports/graphs/animals/${id}`);
	}
	getListAnimalsForReproReport(id): Observable<any> {
		return this.http.get(this.configService.getApiPath() + `/reports/graphs/animalsReproReport/${id}`);
	}

	displayGraph(uuid, deviceId, startDate, endDate) {
		return this.http.get(this.configService.getApiPath() + `/reports/graphs?uuid=${uuid}&deviceId=${deviceId}&startDate=${startDate}&endDate=${endDate}`);
	}


	showRumination(listIdGroups, listIdAnimals, startDate, endDate, farmer) {
		if (farmer == null)
			farmer = ""
		return this.http.get(this.configService.getApiPath() + `/reports/rumination?listIdGroups=${listIdGroups}&listIdAnimals=${listIdAnimals}&startDate=${startDate}&endDate=${endDate}&farmer=${farmer}`)
	}

	showMilkProduction(listIdGroups, listIdAnimals, startDate, endDate, farmer) {
		if (farmer == null)
			farmer = ""
		return this.http.get(this.configService.getApiPath() + `/reports/milk-production?listIdGroups=${listIdGroups}&listIdAnimals=${listIdAnimals}&startDate=${startDate}&endDate=${endDate}&farmer=${farmer}`)
	}

	getPoidsVifAge() {
		return this.http.get(this.configService.getApiPath() + `/reports/weight-age`) as Observable<any[]>;
	}

	getAlertsStats() {
		return this.http.get(this.configService.getApiPath() + `/reports/alerts-stats`) as Observable<any[]>;
	}

	getFeedConsumption() {
		return this.http.get(this.configService.getApiPath() + `/reports/feed-consumption`) as Observable<any[]>;
	}


	displayRuminationUi(uuid, status, startDate, endDate,/* offset,*/ sitting/*, diffTimezone*/) {
		return this.http.post<any>(this.configService.getApiPath() + `/reports/rumination-ui?uuid=${uuid}&status=${status}&startDate=${startDate}&endDate=${endDate}&sitting=${sitting}`, null);

		//return this.http.get(this.configService.getApiPath() + `/reports/rumination-ui?uuid=${uuid}&status=${status}&startDate=${startDate}&endDate=${endDate}&offset=${offset}&sitting=${sitting}&diffTimeZone=${diffTimezone}`);
	}

	displayWellBeing(uuid, status, startDate, endDate, offset, sitting, diffTimezone) {
		return this.http.get(this.configService.getApiPath() + `/reports/rumination-ui/well-being?uuid=${uuid}&status=${status}&startDate=${startDate}&endDate=${endDate}&offset=${offset}&sitting=${sitting}&diffTimeZone=${diffTimezone}`);
	}



	export(listFarmers, listAnimals, listColumns, showQuantity, listQualities, startDate, endDate) {
		const httpOptions = {
			'responseType': 'blob' as 'json'
		};
		return this.http.post<any>(this.configService.getApiPath() + `/reports/export-livestock?listFarmers=${listFarmers}&listAnimals=${listAnimals}&listColumns=${listColumns}&showQuantity=${showQuantity}&listQualities=${listQualities}&startDate=${startDate}&endDate=${endDate}&projection=animals`, null, httpOptions);
	}
	exportToCsv(uuid, deviceId, startDate, endDate) {
		const httpOptions = {
			'responseType': 'blob' as 'json'
		};
		return this.http.post<any>(this.configService.getApiPath() + `/reports/export-info?uuid=${uuid}&deviceId=${deviceId}&startDate=${startDate}&endDate=${endDate}`, null, httpOptions);
	}

	displayRuminationUiAsCsv(uuid, status, startDate, endDate, sitting) {
		const httpOptions = {
			'responseType': 'blob' as 'json'
		};
		return this.http.post<any>(this.configService.getApiPath() + `/reports/rumination-ui?uuid=${uuid}&status=${status}&startDate=${startDate}&endDate=${endDate}&csv=true&sitting=${sitting}`, null, httpOptions
		);
	}
	exportThiToCsv(tagIds, farmerId, startDate, endDate) {
		if (farmerId == null || !farmerId)
			farmerId = ""
		const httpOptions = {
			'responseType': 'blob' as 'json'
		};
		return this.http.post<any>(this.configService.getApiPath() + `/reports/export-humidity-temp?tagIds=${tagIds}&farmerId=${farmerId}&startDate=${startDate}&endDate=${endDate}`, null, httpOptions)
	}
	exportTempGraphToCsv(tankId, startDate, endDate) {
		const httpOptions = {
			'responseType': 'blob' as 'json'
		};
		return this.http.post<any>(this.configService.getApiPath() + `/reports/temp-graphs/export?tankId=${tankId}&startDate=${startDate}&endDate=${endDate}`, null, httpOptions);
	}


	getTempGraph(tankId, startDate, endDate) {
		return this.http.get(this.configService.getApiPath() + `/reports/temp-graphs?tankId=${tankId}&startDate=${startDate}&endDate=${endDate}`);
	}

	getEntreprises(): Observable<any> {
		return this.http.get(this.configService.getApiPath() + `/reports/temp-graphs/entreprises`);
	}

	getEntreprisesWithTanks(): Observable<any> {
		return this.http.get(this.configService.getApiPath() + `/reports/temp-graphs/entreprises-with-tanks`);
	}

	getTanks(id): Observable<any> {
		return this.http.get(this.configService.getApiPath() + `/reports/temp-graphs/tanks/${id}`);
	}

	displayThiGraph(tagIds, farmerId, startDate, endDate) {
		if (farmerId == null || !farmerId)
			farmerId = ""
		return this.http.get(this.configService.getApiPath() + `/reports/humidity-temp?tagIds=${tagIds}&farmerId=${farmerId}&startDate=${startDate}&endDate=${endDate}`);
	}
	getCountStatus(uuids, date) {

		return this.http.get(this.configService.getApiPath() + `/reports/rumination-ui/getCountStatus?uuids=${uuids}&date=${date}`)
	}

	exportReproductionReportAsCsv(farmers, cows) {
		const httpOptions = {
			'responseType': 'blob' as 'json'
		};
		return this.http.post<any>(this.configService.getApiPath() + `/reproductionReports/reproduction?farmers=${farmers}&cows=${cows}`,null,httpOptions
		);
	}

	exportExcel(startDate, endDate) {
		const httpOptions = {
			'responseType': 'blob' as 'json'
		};
		return this.http.post<any>(this.configService.getApiPath() + `/kpiReports/export?startDate=${startDate}&endDate=${endDate}`, null, httpOptions);
	}
	getFeedMilkGraph( startDate, endDate) {
		return this.http.get(this.configService.getApiPath() + `/reports/feedMilk?startDate=${startDate}&endDate=${endDate}`);
	}
	exportFeedMilkGraphToCsv(startDate, endDate) {
		const httpOptions = {
			'responseType': 'blob' as 'json'
		};
		return this.http.post<any>(this.configService.getApiPath() + `/reports/feedMilkAsCsv?startDate=${startDate}&endDate=${endDate}`, null, httpOptions);
	}
	
	getMilkGraph( startDate, endDate,idSupervisor?) {
		if (idSupervisor == null )
		idSupervisor = ""
		return this.http.get(this.configService.getApiPath() + `/reports/milk-graphs?startDate=${startDate}&endDate=${endDate}&idSupervisor=${idSupervisor}`);
	}

	exportMilkGraphToCsv(startDate, endDate,idSupervisor?) {
		if (idSupervisor == null )
		idSupervisor = ""
		const httpOptions = {
			'responseType': 'blob' as 'json'
		};
		return this.http.post<any>(this.configService.getApiPath() + `/reports/milk-graphsAsCsv?startDate=${startDate}&endDate=${endDate}&idSupervisor=${idSupervisor}`, null, httpOptions);
	}
	getCalvingGraph( startDate, endDate,idSupervisor?) {
		if (idSupervisor == null )
		idSupervisor = ""
		return this.http.get(this.configService.getApiPath() + `/reports/calving-graphs?startDate=${startDate}&endDate=${endDate}&idSupervisor=${idSupervisor}`);
	}

	exportCalvingGraphToCsv(startDate, endDate,idSupervisor?) {
		if (idSupervisor == null )
		idSupervisor = ""
		const httpOptions = {
			'responseType': 'blob' as 'json'
		};
		return this.http.post<any>(this.configService.getApiPath() + `/reports/calving-graphsAsCsv/?startDate=${startDate}&endDate=${endDate}&idSupervisor=${idSupervisor}`, null, httpOptions);
	}
	getMilkProductionOfAnimal( startDate, endDate,idAnimal?) {
		
		return this.http.get(this.configService.getApiPath() + `/reports/animal-milk-graphs?startDate=${startDate}&endDate=${endDate}&idAnimal=${idAnimal}`);
	}

	exportMilkProductionOfAnimalToCsv(startDate, endDate,idAnimal?) {
		
		const httpOptions = {
			'responseType': 'blob' as 'json'
		};
		return this.http.post<any>(this.configService.getApiPath() + `/reports/animal-milk-graphsAsCsv?startDate=${startDate}&endDate=${endDate}&idAnimal=${idAnimal}`, null, httpOptions);
	}
	getListAnimalsMilkProduction(id): Observable<any> {
		return this.http.get(this.configService.getApiPath() + `/reports/graphs/animalsMilkProduction/${id}`);
	}
	getFarmersForMilkCowGraph(): Observable<any> {
		return this.http.get(this.configService.getApiPath() + `/reports/graphs/farmers/milkCowGraph`);
	}

	getListAnimalsForMilkCowGraph(id): Observable<any> {
		return this.http.get(this.configService.getApiPath() + `/reports/graphs/milkCowGraph/${id}`);
	}

	exportReportExcel(startDate, endDate,countryId,reportType) {
		const httpOptions = {
			'responseType': 'blob' as 'json'
		};
		return this.http.post<any>(this.configService.getApiPath() + `/kpiReports/export-excel-report?startDate=${startDate}&endDate=${endDate}&countryId=${countryId}&reportType=${reportType}`, null, httpOptions);
	}
	

}
