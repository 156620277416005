import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(tags: any[], searchText: string): any[] {
    if (!tags) return [];
    if (!searchText) return tags;
    searchText = searchText.toLowerCase();
    return tags.filter(tag => {
      return (tag.code && tag.code.toLowerCase().indexOf(searchText) >= 0) ||
        (tag.animalCustomerFullName && tag.animalCustomerFullName.toLowerCase().indexOf(searchText) >= 0) ||
        (tag.animalAddress && tag.animalAddress.toLowerCase().indexOf(searchText) >= 0) ||
        (tag.animalUid && tag.animalUid.toLowerCase().indexOf(searchText) >= 0) ||
        (tag.animalCode && tag.animalCode.toLowerCase().indexOf(searchText) >= 0)
    });
  }
}