<div class="style-bar">
    <div class="row h-100">
        <div class="d-flex justify-content-end pe-4">
            <img src="/assets/images/Union-5.svg" (click)="closePopUp()" />
        </div>
        <div >
            <div class="row  center-element">
                <div class="text-center size-number" [ngClass]="{rtl:appService.rtl()}">
                    {{ 'CONTACT' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>

<!--<section class="center-element mt-3 mb-4">
    <a href="tel:+21631407355">
        <span>
            <i class="fas fa-phone-alt size-number"></i> </span>
        <span class="size-number color">+216 31 40 73 55</span>
    </a>
</section>-->
<section class="center-element">
    <div>
        <div class="mt-3 center-element " *ngFor="let element of list">
            <a href={{element.href}} target="_blank">
                <i aria-hidden="true" class={{element.class}}></i> </a>

        </div>
    </div>

</section>