import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WelcomeComponent } from './welcome.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { RouterModule } from '@angular/router';
import { OwlModule } from 'ngx-owl-carousel';
import { LanguageModule } from '../language/language.module';

@NgModule({
  declarations: [
    WelcomeComponent
  ],

  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    RouterModule,
    OwlModule,
    LanguageModule
  ],

  exports: [
    WelcomeComponent
  ]

})
export class WelcomeModule { }
