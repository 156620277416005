
import { ConfigService, APP_TYPE } from 'projects/shared/services/config.service';
import { environment } from '../../environments/environment'
import { Injectable } from '@angular/core';

@Injectable(
	{
		providedIn: 'root'
	}
)
export class PublicV2ConfigService extends ConfigService {
	public applicationType(): APP_TYPE {
		return APP_TYPE.PUBLIC
	}
  public getWebSocketPath() {
    return environment.webSocketPath;
  }

	public getServerTokenPath() {
		return environment.serverTokenPath;
	}
	public getServerAuthorisation() {
		return environment.authorization;
	}
	public getApiPath() {
		return environment.apiPath;
	}
}
