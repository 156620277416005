<div class="card-size content ">
    <div class="upper">
    <div class="d-flex pe-4 ps-4 pt-3 row ">
        <div class="flex-grow-1 col-6">
            <div [ngClass]="value? 'rtl' : 'ltr'" class="ellipsis-text">

                <span class=" font-name title-style color-dark-blue">{{farmer.fullname}}</span>
            </div>
            <div><span class="nbr-style-parametre title-style-parametre">{{farmer.phone}}</span></div>
            <div><span (click)="router.navigateByUrl(url)" class="me-4">
                    <img src="/assets/images/map-location.svg">
                </span>
                <span (click)="addLocation()">
                    <img src="/assets/images/add-location.svg">
                </span>
            </div>


        </div>
        <div [ngClass]="{rtl:appService.rtl()}" *ngIf="farmer.isHasAnimalWithLabel" class=" center-element col-2">
            <img [ngClass]="appService.rtl()? 'book-marker-ar' : 'book-marker'" src="/assets/images/bookmark.svg">
            <!--<i class="material-icons" [ngClass]="appService.rtl()? 'book-marker-ar' : 'book-marker'">bookmark</i>-->

        </div>
        <div class=" col-4 ps-2 pe-2 ">

            <div class="min-card  blue-color ">
                <!--<div class="d-flex justify-content-between  " >
                    <div class="center-element mt-1">-->
                <div class="row  h-100 gx-1 px-1" [ngClass]="appService.rtl()? 'pt-1' : ''" (click)="openAnimals()">
                    <div class="col-6 center-element">
                        <span><img src="/assets/images/animal2.svg"></span>
                    </div>
                    <div class=" col-6 center-element">

                        <span [ngClass]="appService.rtl()? 'ms-2' : ''"
                            class="me-2 nbr-style-number">{{farmer.animalCount? farmer.animalCount : "0"}}</span>
                    </div>

                </div>
                <div class="min-card  blue-color " >
                    <div class="row  h-100 gx-1 px-1 mt-2" (click)="showMilkQuantity(farmer)">
                        <div class="col-6 center-element">
                            <span><img src="/assets/images/tank_dark.svg"></span>
                        </div>
                        <div class="center-element col-6">
                            <span [ngClass]="appService.rtl()? 'ms-2' : ''" class="me-1 nbr-style-number-milk"
                                [ngClass]="{rtl:appService.rtl()}">{{farmer.quantityMilk}}{{ 'L' | translate }}</span>
                        </div>
                    </div>
                </div>
                <div class="min-card-thi  blue-color ">
                    <div class="p-2 mt-2">
                    <div class="row ">
                        <div class="col-6 center-element font-number">
                            <span>{{farmer.temp ? (farmer.temp |
                                number:'2.1-2' ): '--'}}</span>
                        </div>
                        <div class="col center-element">
                            <span [ngClass]="appService.rtl()? 'ms-2' : ''"
                                class="me-1 nbr-style-number-milk exp-unit-celsus"
                                [ngClass]="{rtl:appService.rtl()}">°C</span>
                        </div>
                    </div>
                        <div class="row mt-2" >
                        <div class="col-6 center-element font-number">
                            <span>{{farmer.humidity ?
                                farmer.humidity : '--'}}</span>
                        </div>
                        <div class="col center-element">
                            <span [ngClass]="appService.rtl()? 'ms-2' : ''"
                                class="me-1 nbr-style-number-milk exp-unit-pourcentage"
                                [ngClass]="{rtl:appService.rtl()}">%</span>
                        </div>
                        </div>
                        <div class="row mt-2">
                        <div class="col-6 center-element moome-color font-number">
                            <span>{{farmer.thi ? (farmer.thi |
                                number:'2.1-2' ): '--'}}</span>
                        </div>
                        <div class="center-element col-6">
                            <span [ngClass]="appService.rtl()? 'ms-2' : ''" class="me-1 nbr-style-number-milk "
                                [ngClass]="{rtl:appService.rtl()}">THI</span>
                        </div>

                    </div>
                </div>

                </div>
            </div>
        </div>

    </div>
</div>
   
<div class="bottom padding-bottom">
    <div class="d-flex justify-content-between  mt-5" [ngClass]="{rtl:appService.rtl()}">
        <div class="min-card blue-color " [getAuthorized]="authorityAlerts" (click)="openAlerts()"  [ngClass]="appService.rtl()? 'me-1' : 'ms-1'">
            <div class="d-flex center-element h-100 gx-1 px-1">
                <div class="col-4 center-element ms-1">
                    <span><img src="/assets/images/Alert-menu.svg"></span>
                </div>
                <div class="bd-highlight me-2 ms-2">
                    <span class="title-style-parametre">{{ 'alerts' | translate}}</span>
                </div>
            </div>
            <div *ngIf="farmer.unreadAlert!=null" [ngClass]="appService.rtl()? 'dot' : 'dot-fr'">
                <div class="number-style mt-2" [ngClass]="appService.rtl()? 'pos' : 'pos-fr'">{{farmer.unreadAlert>=100 ?
                    '+99' :
                    farmer.unreadAlert}}</div>
            </div>
        </div>


        <div class="min-card blue-color "
            [getAuthorized]="authorityRapports">
            <div class=" flex-shrink-1 center-element h-100 gx-1 px-1">
               
                    <i style="font-size: 30px"
                        [ngClass]="appService.rtl()? 'material-icons' : 'material-icons icon-style'">
                        dvr
                    </i>
                <div class="bd-highlight me-2 ms-2">
                    <span class="title-style-parametre">{{ 'RAPPORTS' | translate}}</span>
                </div>
            </div>
        </div>

        <div  [ngClass]="appService.rtl()? 'ms-1' : 'me-1'">
            <div class="min-card blue-color center-element h-100 pt-1 px-1 ">
                
                       <img src="/assets/images/gear-option.svg" (click)="settingFarmer(); appService.verif=false" />
            </div>
        </div>
    </div>
</div>
</div>