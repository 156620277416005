<div class="style-bar-troups w-100" ngxHideOnScroll [scrollingElementSelector]="'body'" [propertyUsedToHide]="'top'"
  [valueWhenHidden]="'-50px'" [valueWhenShown]="'90px'">
  <div class="row  h-100">
    <div class="col-12">
      <div class="row background-troups">
        <div class="col center-element" (click)="back()">
          <img src="/assets/images/left-arrow-1.svg" />
        </div>
        <div class="col center-element">
          <div class="row d-block">
            <div class="col text-center"> <img src="/assets/images/reproReportBar.svg" />
            </div>
            <div class="col text-center ps-0 pe-0 pt-1"> <span class="text-style" [ngClass]="{rtl:appService.rtl()}">{{
              'REPRODUCTION_REPORT' | translate }}</span></div>
          </div>
        </div>
        <div class="col center-element">
        </div>
      </div>
    </div>
  </div>
</div>

<div style="padding-top:100px" class=mat-elevation-z88>
  <mat-card class="style-card-graph">

    <form [formGroup]="formGroup" (ngSubmit)="apply()">
      <div class="row">
        <div class="mx-auto">
        </div>
      </div>
      <div class="row ms-3 me-3 mb-5 mt-5" [ngClass]="{rtl:appService.rtl()}">
     
        <div  *ngIf="roleFarmer" class="col-sm-6 col-12" >
          <mat-form-field [ngClass]="appService.rtl()? 'mat-form-field-ar' : ''" style="width:100%">
            <mat-label >{{ 'CHOOSE' | translate}}&nbsp;{{ 'ANIMAL' | translate}}</mat-label>
            <mat-select [ngClass]="{rtl:appService.rtl()}" formControlName="selectedAnimals" multiple disableOptionCentering panelClass="myPanelClass">
              <input matInput placeholder="{{ 'ANIMAL' | translate}}"
              (keydown)="$event.stopPropagation()" (keyup)="onKeyAnimals($event.target.value)" class="search_style">
              <mat-option [value]="0" (click)="toggleAllSelectionAnimal()" #allSelectedAnimal>{{ 'ALL' | translate}}
              </mat-option>
              <mat-option *ngFor="let animal of selectedAnimals" [value]="animal">
                {{appService.valueUid == nationalIdentification? (animal.uid ? animal.uid :animal.name) : (appService.valueUid == nameLocalCode? (animal.name ? animal.name : animal.uid) : (animal.uid? animal.uid :animal.name))}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="!formGroup.controls['selectedAnimals'].valid && formGroup.controls['selectedAnimals'].touched">
              {{ 'FIELD_REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="!roleFarmer" class="col-6">
          <mat-form-field [ngClass]="appService.rtl()? 'mat-form-field-ar' : ''">
            <mat-label><span>{{ 'CHOOSE' | translate}}&nbsp;{{ 'FARMER' | translate}}</span></mat-label>
            <mat-select [ngClass]="{rtl:appService.rtl()}" formControlName="selectedFarmers" multiple disableOptionCentering panelClass="myPanelClass">
              <input matInput placeholder="{{ 'CHOOSE' | translate}} &nbsp; {{ 'FARMER' | translate}}"
              (keydown)="$event.stopPropagation()" (keyup)="onKeyFarmer($event.target.value)" class="search_style">
              <mat-option [value]="0" (click)="toggleAllSelectionFarmer()" #allSelectedFarmer>{{ 'ALL' | translate}}
              </mat-option>
              <mat-option *ngFor="let farmer of selectedListFarmers" [value]="farmer.id" (click)="tosslePerOneFarmer()">
                {{farmer.fullname}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="!formGroup.controls['selectedFarmers'].valid && formGroup.controls['selectedFarmers'].touched">
              {{ 'FIELD_REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
     
        <div *ngIf="!roleFarmer" class="col-6">
          <mat-label>{{ 'CHOOSE' | translate}}&nbsp;{{ 'ANIMAL' | translate}}</mat-label>
          <div style="overflow-y: scroll; height: 200px">
            <mat-checkbox formControlName="selectedAnimals" *ngIf="showAll" class="checklist-leaf-node" color="primary" [checked]="isAllSelected" (change)="selectAll()">{{ 'ALL' | translate}}</mat-checkbox>

            
            <mat-tree [dataSource]="dataSourceAnimal" [treeControl]="treeControl">
              <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [matTreeNodePaddingIndent]="10">
                <button mat-icon-button disabled></button>
                <mat-checkbox formControlName="selectedAnimals" class="checklist-leaf-node" color="primary"
                  [checked]="checklistSelection.isSelected(node)" (change)="todoLeafItemSelectionToggle(node)">
                </mat-checkbox>&nbsp;
                <span [ngClass]="appService.rtl()? 'style-font-family' : ''">{{node.name}}</span>
              </mat-tree-node>
              <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding
                [matTreeNodePaddingIndent]="10">
                <mat-icon matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name"
                  class="mat-icon-rtl-mirror pointer">
                  {{treeControl.isExpanded(node) ? 'expand_more' : (appService.rtl()? 'chevron_leftt' :
                  'chevron_right')}}
                </mat-icon>
                <mat-checkbox formControlName="selectedAnimals" [checked]="descendantsAllSelected(node)"
                  [indeterminate]="descendantsPartiallySelected(node)" (change)="todoItemSelectionToggle(node)"
                  color="primary">{{node.name}}
                </mat-checkbox>  
              </mat-tree-node>
            </mat-tree>

          </div>
        </div>
      

      </div>
      

      <!--div [ngStyle]="{'display':display== true ? 'block' : 'none'}"-->
      <!--<div class="row mb-5 ms-3 me-3 " [ngClass]="{rtl:appService.rtl()}">
        <div class="col-sm-6 col-12">
          <ion-item>
            <ion-datetime #datePicker mode="ios" class="font-size-text datetime-text" [ngClass]="{rtl:appService.rtl()}"
              [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()" formControlName="startDate"
              placeholder="{{ 'CHOOSE' | translate}}&nbsp;{{ 'START_DATE' | translate}}"
              [monthShortNames]="appService.monthShort()" [doneText]="'SAVE' |translate"
              [cancelText]="'CANCEL' |translate" 
              (ionChange)="fixArabeFormat()"  [max]="maxDate">
            </ion-datetime>

            <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar ' : 'date '"
              src="/assets/images/calendar4.svg" />
          </ion-item>
          <span slot="helper" class="error-msg"
            *ngIf="!formGroup.controls['startDate'].valid && formGroup.controls['startDate'].touched"> {{
            'FIELD_REQUIRED' | translate }}</span>
        </div>

        <div class="col-sm-6 col-12">
          <ion-item>
            <ion-datetime #datePicker mode="ios" class="font-size-text datetime-text " (ionChange)="fixArabeFormat();"
              [ngClass]="{rtl:appService.rtl()}" [pickerFormat]="appService.verifFormatDate()" formControlName="endDate"
              placeholder="{{ 'CHOOSE' | translate}}&nbsp;{{ 'END_DATE' | translate}}"
              [monthShortNames]="appService.monthShort()" [doneText]="'SAVE' |translate"
              [cancelText]="'CANCEL' |translate" 
              [displayFormat]="formatArabe"  [max]="maxDate">
            </ion-datetime>
            <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar ' : 'date '"
              src="/assets/images/calendar4.svg" />
          </ion-item>
          <span slot="helper" class="error-msg"
            *ngIf="!formGroup.controls['endDate'].valid && formGroup.controls['endDate'].touched"> {{
            'FIELD_REQUIRED' | translate }}</span>
        </div>
      </div>-->
      <div class="row">
        <div class="ml-auto">
          <div class="col-12 ">
            <button class="d-flex bd-highlight mb-3 ms-auto p-2 bd-highlight" [ngClass]="{rtl:appService.rtl()}"
              mat-raised-button color="primary" type="submit" [disabled]="!formGroup.valid">{{ 'EXPORT_DOWNLOAD' |
              translate}}</button>
          </div>
        </div>
      </div>
    </form>
  </mat-card>
</div>