<div class="row">
    <div class="col-12">
        <h2 mat-dialog-title [ngClass]="{rtl:appService.rtl()}" [ngStyle]="{'color': ((data.title == ('WARNING' | translate))? 'red' : '')}"> {{data.title}}</h2>
    </div>
</div>

<p [ngClass]="{rtl:appService.rtl()}" *ngIf="data.message">{{data.message}}</p>
<p [ngClass]="{rtl:appService.rtl()}" *ngIf="data.message1">{{data.message1}}</p>
<p [ngClass]="{rtl:appService.rtl()}" *ngIf="data.message2">{{data.message2}}</p>


<div class="row">
    <div class="ml-auto">
        <div class="col-12"> <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="warn" mat-dialog-close
                style="margin-right:10px">{{ 'CANCEL' | translate}}</button>
            <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="primary"
                (click)="confirm()">{{ 'CONFIRM' | translate}}</button>
        </div>
    </div>
</div>