<div class="dialog-size-mob">
    <div class="row">
        <div class="col">
            <div class="text-center title-style-dialog"><span [ngClass]="{rtl:appService.rtl()}"
                    [ngStyle]="{'color': ((data.title == ('WARNING' | translate))? 'red' : '')}">{{data.title}}</span>
            </div>
        </div>
    </div>
    <div class="row" [ngClass]="{rtl:appService.rtl()}">
        <div class="col  mt-3 mb-3">
            <span class="message-style-labo" [ngClass]="{rtl:appService.rtl()}">{{data.message}}</span>

        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="float-end"> <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="warn"
                    style="margin-right: 5px" class="button-dialog   text-center" mat-dialog-close> <span
                        [ngClass]="appService.rtl()? 'button-mob-text-ar rtl' : 'button-mob-text'" class="center-element">{{
                        'CANCEL' | translate }} </span></button>
                <a [ngClass]="{rtl:appService.rtl()}" class="button-dialog button-text-size text-center"
                    mat-raised-button color="primary" href="tel:+21697-430-270"><span
                        [ngClass]="appService.rtl()? 'button-mob-text-ar rtl' : 'button-mob-text'" class="center-element ">{{
                        'CONFIRM' | translate }} </span></a>
            </div>
        </div>
    </div>
</div>