<div class="style-bar-troups w-100" ngxHideOnScroll [scrollingElementSelector]="'body'" [propertyUsedToHide]="'top'"
    [valueWhenHidden]="'-50px'" [valueWhenShown]="'90px'">
    <div class="row  h-100">
        <div class="col-12">
            <div class="row background-troups">
                <div class="col center-element" (click)="back()">
                    <img src="/assets/images/left-arrow-1.svg" />
                </div>
                <div class="col center-element">
                    <div class="row d-block">
                        <div class="col text-center"> <img src="/assets/velage-Graph.svg"  />
                        </div>
                        <div class="col text-center ps-0 pe-0 pt-0"> <span class="text-style"
                                [ngClass]="{rtl:appService.rtl()}">{{'CALVING_GRAPH' | translate }}</span></div>
                    </div>
                </div>
                <div class="col center-element">
                </div>
            </div>
        </div>
    </div>
</div>



<div class="position-card" [ngStyle]="{'margin-top': '50px'}">

    <div class="row m-0">
        <div [ngClass]="{rtl:appService.rtl()}" class="mx-auto">
            <mat-card class="style-card-graph">
                <form [formGroup]="formGroup" (ngSubmit)="apply()">
                    <div class="row">
                        <div [ngClass]="{rtl:appService.rtl()}" class="mx-auto">
                            <a [ngClass]="{rtl:appService.rtl()}">{{ 'CALVING_GRAPH'| translate}}</a>
                        </div>
                    </div>
                    <div class="col-6">
                        <a *ngIf="adminAccess">
                          <mat-form-field style="width:100%" [ngClass]="appService.rtl()? 'mat-form-field-ar' : ''">
                            <mat-label >{{ 'CHOOSE' | translate}} &nbsp; {{ 'SUPERVISORS' | translate}}</mat-label>
                            <mat-select formControlName="supervisor" disableOptionCentering panelClass="myPanelClass">
                              <mat-select-trigger>
                                {{formGroup.controls['supervisor'].value != null? formGroup.controls['supervisor'].value.fullname :
                                null}}
                              </mat-select-trigger>
                              <input matInput placeholder="{{ 'SUPERVISORS' | translate}}"
                              (keydown)="$event.stopPropagation()" (keyup)="onKeySupervisors($event.target.value)" class="search_style">
                               <mat-option *ngFor="let supervisor of supervisors" [value]="supervisor"
                               >
                               {{supervisor.fullname}}
                             </mat-option>
                            </mat-select>
                            <mat-error
                            *ngIf="!formGroup.controls['supervisor'].valid && formGroup.controls['supervisor'].touched" >
                              {{ 'FIELD_REQUIRED' | translate }}
                            </mat-error>
                          </mat-form-field>
                        </a>
                      </div>
                    <div class="d-none d-sm-block">
                        <span style="font-size: 18px; padding-bottom: 5px" [ngClass]="{rtl:appService.rtl()}">{{
                            'LAST'|translate}}:&nbsp;</span>

                        <div class="row">
                            <div class="col-12">
                                <mat-button-toggle-group formControlName="period">
                                    <mat-button-toggle value="1_MONTH" style="width:87px"><span
                                            [ngClass]="{rtl:appService.rtl()}">{{
                                            '1_MONTH' | translate}}</span></mat-button-toggle>

                                </mat-button-toggle-group>
                            </div>
                        </div>
                    </div>
                    <div class="d-block d-sm-none">
                        <div class="row">
                            <div class="col-sm-6 col-12">
                                <mat-form-field [ngClass]="appService.rtl()? 'mat-form-field-ar' : ''"
                                    style="width:100%">
                                    <mat-label>{{ 'CHOOSE' | translate}} &nbsp; {{ 'LAST' | translate}}</mat-label>
                                    <mat-select formControlName="period" disableOptionCentering
                                        panelClass="myPanelClass">
                                        <input matInput
                                            placeholder="{{ 'CHOOSE' | translate}} &nbsp; {{ 'LAST' | translate}}"
                                            (keydown)="$event.stopPropagation()"
                                            (keyup)="onKeyPeriod($event.target.value)" class="search_style">
                                        <mat-option *ngFor="let period of selectedPeriods" [value]="period">
                                            {{period | translate}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="!formGroup.controls['period'].valid && formGroup.controls['period'].touched">
                                        {{ 'FIELD_REQUIRED' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <mat-checkbox (change)="showOptions($event)"><span
                            [ngClass]="appService.rtl()? 'check-style' : ''">{{
                            'SPECIFIC_PERIOD' | translate}}</span>
                    </mat-checkbox>

                    <div class="row">

                        <div class="col-sm-6 col-12">
                            <ion-item>
                                <ion-datetime #datePicker mode="ios" class="font-size-text "
                                    [ngClass]="{rtl:appService.rtl()}" [displayFormat]="formatArabe"
                                    [pickerFormat]="appService.verifFormatDate()" formControlName="startDate"
                                    placeholder="{{ 'CHOOSE' | translate}}&nbsp;{{ 'START_DATE' | translate}}"
                                    [monthShortNames]="appService.monthShort()" [doneText]="'SAVE' |translate"
                                    [cancelText]="'CANCEL' |translate" [required]="!formGroup.controls['period'].value"
                                     (ionChange)="fixArabeFormat()"
                                     [max]="maxDate">
                                </ion-datetime>

                                <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar ' : 'date '"
                                    src="/assets/images/calendar4.svg" />


                            </ion-item>
                            <span slot="helper" class="error-msg"
                                *ngIf="!formGroup.controls['startDate'].valid && formGroup.controls['startDate'].touched">
                                {{
                                'FIELD_REQUIRED' | translate }}</span>

                        </div>
                        <div class="col-sm-6 col-12">
                            <ion-item>
                                <ion-datetime #datePicker mode="ios" class="font-size-text "
                                    (ionChange)="fixArabeFormat();" [ngClass]="{rtl:appService.rtl()}"
                                    [pickerFormat]="appService.verifFormatDate()" formControlName="endDate"
                                    placeholder="{{ 'CHOOSE' | translate}}&nbsp;{{ 'END_DATE' | translate}}"
                                    [monthShortNames]="appService.monthShort()" [doneText]="'SAVE' |translate"
                                    [cancelText]="'CANCEL' |translate" [required]="!formGroup.controls['period'].value"
                                    [displayFormat]="formatArabe" [max]="maxDate"
                                    [min]="formGroup.controls['startDate'].value">
                                </ion-datetime>
                                <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar ' : 'date '"
                                    src="/assets/images/calendar4.svg" />
                            </ion-item>
                            <span slot="helper" class="error-msg"
                                *ngIf="!formGroup.controls['endDate'].valid && formGroup.controls['endDate'].touched">
                                {{
                                'FIELD_REQUIRED' | translate }}</span>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="ml-auto">
                            <div class="col-12">

                                <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="primary"
                                    type="button" style="margin-right:10px" (click)="exportCsv()"
                                    [disabled]="!formGroup.valid">{{
                                    'EXPORT_DOWNLOAD' | translate}}</button>
                                <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="primary"
                                    type="submit" [disabled]="!formGroup.valid">{{
                                    'SHOW_GRAPH' |
                                    translate}}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>

    </div>

</div>
<mat-card [ngStyle]="{'display' : (displaygraph ? 'block' : 'none')}">
    <canvas id="barChart" style="margin:auto; max-width: 100%;"
        width="1220" height="550"></canvas>
</mat-card>

