import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'projects/shared/services/app.service';

@Component({
  selector: 'app-alert-type-appel-labo',
  templateUrl: './alert-type-appel-labo.component.html',
  styleUrls: ['./alert-type-appel-labo.component.scss']
})
export class AlertTypeAppelLaboComponent implements OnInit {

  constructor( public appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

}
