
import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { Address } from "./Address";
import { Customer } from "./Customer";
import { Entreprise } from "./Entreprise";
import { Role } from "./Role";
@HateoasResource('supervisors')
export class Supervisor  extends Resource {
    id: number;
    fullname: string;
    username: string;
    phone: string;
    email: string;
    password: string;
    roles: Role[] | any;
    addresses: Address[];
    supervisors: Customer[] | any;
    farmers: Customer[] | any
    totalMilkProduction: number
    defaultGroupColor
    temp
    humidity
    thi
    sex
    entreprises: Entreprise [] | any
    entreprise:any;


}


