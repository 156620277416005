<app-section-bar [title]="name" (goBack)="back()" [color]="color"></app-section-bar>

<form [formGroup]="formGroup" class="m-auto" *ngIf="formGroup" [ngClass]="{rtl:appService.rtl()}">
  <div class="row" [ngClass]="{rtl:appService.rtl()}">
    <div class="col mb-3 mt-2 center-element ">
      <ion-item class="input-mob-date ps-2 pe-2">
        <ion-datetime [disabled]="isDisabled" #datePicker mode="ios" [ngClass]="{rtl:appService.rtl()}"
          class="font-size-text" [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()"
          formControlName="date" [min]="minDate" placeholder="{{ 'CHOOSE'| translate }}&nbsp;{{ 'DATE' | translate}}"
          [doneText]="'SAVE' |translate" [cancelText]="'CANCEL' |translate" [monthShortNames]="appService.monthShort()">
        </ion-datetime>
        <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar' : 'date'"
          src="/assets/images/calendar4.svg" />
      </ion-item>
    </div>
  </div>
  <div class="row center-element mt-3 mb-2" [ngClass]="{rtl:appService.rtl()}" *ngIf="!id">
    <div class="text-center input-mob-message ">{{'ONLY_GROUPS' | translate}}</div>
  </div>
  <div class="col center-element mb-3">
    <mat-select [disabled]="isDisabled" class=" input-height input-width op ps-2 pe-2" disableOptionCentering
      panelClass="myPanelAnimalClass" placeholder="{{ 'CHOOSE' | translate}} &nbsp; {{ 'GROUP' | translate}}"
      [ngClass]="{rtl:appService.rtl()}" formControlName="group" required>

      <mat-option *ngFor="let group of selectedGroups" [value]="group.id" [disabled]="isDisabled">
        <span *ngIf="labelEn">{{group.labelEn}}</span>
        <span *ngIf="labelFr">{{group.labelFr}}</span>
        <span *ngIf="labelInd">{{group.labelInd}}</span>
        <span [ngClass]="{rtl:appService.rtl()}" *ngIf="labelAr">{{group.labelAr}}</span>
      </mat-option>
    </mat-select>
  </div>
  <div class="col center-element mb-3 title-style" [ngClass]="{rtl:appService.rtl()}" *ngIf="displayCode">{{ 'CONSUMPTION_PERIOD' | translate}} <span class="ms-5 me-4"> {{ 'PERIOD' | translate}}</span></div>
  <div class="col center-element mb-3" *ngIf="displayCode">
    
    <input [readonly]="isDisabled" class="input-mob-period ms-2 me-2" type="number"
    formControlName="consumptionPeriod"  (keypress)="appService.numericOnly($event,numericPattern)" placeholder="{{ 'CONSUMPTION_PERIOD' | translate}}" required/>
   
    <mat-select [disabled]="isDisabled" class=" input-period input-width op ps-2 pe-2" disableOptionCentering
      panelClass="myPanelAnimalClass" placeholder="{{ 'PERIOD' | translate}}"
      [ngClass]="{rtl:appService.rtl()}" formControlName="period" required>

      <mat-option value="day" [disabled]="isDisabled">
        <span > {{ 'DAYS' | translate}}</span>
      </mat-option>
      <mat-option value="month" [disabled]="isDisabled">
        <span > {{ 'MONTHS' | translate}}</span>
      </mat-option>
    </mat-select>
  </div>

  <div>
    <div class="col center-element mt-5 mb-2" *ngIf="!id"m>
      <div class="text-center input-mob-message">{{'ONLY_FEED' | translate}}</div>
    </div>
    <table class="center-element">
      <div formArrayName="stocks">
        <tr>
          <td></td>
          <td class="note-style">{{'QUANTITY' | translate}}</td>
          <td class="note-style">{{'UNIT' | translate}}</td>
        </tr>
        <tr *ngFor="let stock of formGroup.get('stocks')['controls']; let j=index">
          <ng-container [formGroupName]="j">
            <td class="center-element">
              <div class="title-date-wrapper center center-element">
                <span class="note-style">{{stock?.value.title}} </span>
                <!--<span class="small-note-style">{{stock?.value.stockDate}} </span>-->

              </div>
            </td>
            <td>
              <input [readonly]="isDisabled" class="input-mob text-center ms-2 me-2" type="number"
                formControlName="quantity" id="quantity" name="quantity"
                (keypress)="appService.numericOnly($event,numericPattern)" />
            </td>
            <td class="note-style"> {{stock?.value?.unit| translate}}</td>
          </ng-container>

        </tr>
      </div>

    </table>
  </div>
</form>
<div class="row">
  <div class="col center-element mt-3 mb-3">
    <button [disabled]="isDisabled" [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="primary" class="button-mob" (click)="confirm()"
    [ngClass]="!formGroup.valid?  'opac' : ''">{{ 'CONFIRM' |
        translate }}</button>
  </div>



</div>