import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { Animal } from './Animal';
import { Customer } from "./Customer";
@HateoasResource('animalNotes')
export class AnimalNote extends Resource {
    id: number;
    animal: Animal | string;
	customer: Customer | string;
    noteDate: String;
    note: string;
    noteDetail: String;
    type: String;
    actionDate: string
    others:any

  }