import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from 'projects/public/src/app/material/material.module';
import { ConfirmComponent } from '../confirm/confirm.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
    ],
    declarations: [
        ConfirmComponent,
    ],
    exports: [
        ConfirmComponent
    ]
})
export class ConfirmModule { }   