<form [formGroup]="formGroup" class="m-auto">
  <app-common-field [field]="dateQualityField" [code]="getField('DATE_QUALITY')"></app-common-field>
  <table>
    <thead>
      <tr>
        <td></td>
        <td>{{'MORNING' | translate}}</td>
        <td>{{'EVENING' | translate}}</td>
        <td>{{'BALANCE' | translate}}</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let param of params">
        <td>{{param | translate}}</td>
        <td *ngFor="let period of ['_MORNING', '_EVENING']" >
          <input class="input-mob text-center" [formControlName]="param+period" type="number"  (keypress)="appService.numericOnly($event,numericPattern)" required />
        </td>
        <td *ngFor="let period of ['_BALANCE']">
          <input class="input-mob text-center" [formControlName]="param+period" type="number" (keypress)="appService.numericOnly($event,numericPattern)" disabled="disabled" required />
        </td>
      </tr>
    </tbody>
  </table>
  <app-common-field [field]="fieldRemarkQuality" [code]="getField('REMARK_QUALITY')"></app-common-field>
</form>