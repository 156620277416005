<app-section-bar [title]="'TROUPS'" (goBack)="back()" [color]="color"></app-section-bar>
<div class="row">
    <div class="col text-center mt-1  ">
        <img class="style-img w-100" src="{{male ? '/assets/images/men_farmer.svg' : '/assets/images/women_farmer.svg' }} " />
    </div>
</div>
<div class="row">
    <div class=" col mt-3 text-center text-style" [ngClass]="{rtl:appService.rtl()}">
        <span>{{ 'YOU_STILL' | translate }}</span>
    </div>
</div>
<div class="row">
    <div class="text-center text-style" [ngClass]="{rtl:appService.rtl()}">
        <span>{{ 'YOUR_DATA' | translate }}</span>
    </div>
</div>
<div class="row">
    <div class="col text-center mt-4 mb-3">
        <button mat-raised-button color="primary" class="button-mob"
            (click)="addAnimalUrl()"><span [ngClass]="{rtl:appService.rtl()}">{{ 'ADD_COWS' | translate
                }}</span></button>
    </div>
</div>