import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { Gateway } from './Gateway';
import { Customer } from './Customer';
import { Device } from './Device';
import { AlertType } from "./AlertType";
@HateoasResource('alerts')
export class Alert extends Resource {

    id: number;
    date: string;
   // type: string;
    alertType: AlertType | any ; 
    message: string;
    read: boolean;
    customers: Customer | string;
    devices: Device[] | any;
    gateways: Gateway[] | any;
    
}

