import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CustomRouter } from '../services/custom-router';
import { ForgetPasswordModule } from './forget-password/forget-password.module';
import { LanguageModule } from '../language/language.module';

@NgModule({
    declarations: [
        LoginComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MaterialModule,
        ReactiveFormsModule,
        RouterModule,
        ForgetPasswordModule,
        LanguageModule
    ],
    exports: [
        LoginComponent
    ],
    providers: [CustomRouter]
})
export class LoginModule { }
