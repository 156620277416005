
function setStore(key, o, store?) {
	
	store = store || localStorage;
	if (o) return store.setItem(key, JSON.stringify(o));
	return store.removeItem(key);
}
function getStore(key, store?) {
	store = store || localStorage;
	const o = store.getItem(key);
	return (o && JSON.parse(o)) || null;
}
function clear() {
	localStorage.clear();
}

function removeItem(item) {
	localStorage.removeItem(item);
}

function clearWithExcludeItem(keys) {

    let res = keys
      .map(element => ({ control: localStorage.getItem(element), code: element}))
    //.reduce((a, b) => a[b.code] = b.control, {})  
	localStorage.clear();
	res.forEach(element => 
		localStorage.setItem(element.code, element.control)
		//localStorage.setItem(element, res[element])
		)

}


const state: any = new Proxy(

	{
		clear, removeItem, clearWithExcludeItem
	},


	{
		set(obj, prop, value): any {
			setStore(prop, value);
			return true;
		},
		get(obj, prop): any {
			return obj[prop] || getStore(prop);
		},

	}
);

export default state;