import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertTypeComponent } from './alert-type.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AlertTypeBarModule } from './alert-type-bar/alert-type-bar.module';
import { AlertTypeCardModule } from './alert-type-card/alert-type-card.module';
import { AlertTypeEmptyModule } from './alert-type-empty/alert-type-empty.module';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MaterialModule } from '../../material/material.module';;
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { AlertTypeAppelLaboComponent } from './alert-type-appel-labo/alert-type-appel-labo.component';


@NgModule({
  declarations: [AlertTypeComponent, AlertTypeAppelLaboComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    AlertTypeCardModule,
    AlertTypeBarModule,
    AlertTypeEmptyModule,
    NgxHideOnScrollModule,
    ScrollingModule,
    MaterialModule,
    MoomeCommonModule
  ],
  exports: [
    AlertTypeComponent,
  ],

})
export class AlertTypeModule { }
