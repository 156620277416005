<div class="style-bar h-100 " (click)="router.navigateByUrl('/home')">
    <div class="row">
        <div class="col text-center mt-5 mb-3">
            <img class="check" src="/assets/images/check.svg" />
        </div>
    </div>
    <div class="row">
        <div class="text-center text-style">
            <span [ngClass]="{rtl:appService.rtl()}">{{ 'VERIFIED' | translate }}</span>
        </div>
    </div>
</div>