import { Component, Inject, OnInit } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { ActivatedRoute } from '@angular/router';
import { GroupService } from 'projects/shared/services/group.service';
import { CustomerService } from 'projects/shared/services/customer.service';
import { CustomRouter } from '../../services/custom-router';
import { Authorities, Group, GroupCode } from 'projects/shared/tools/constants';
import { LostAnimalsDialogComponent } from './group-card/lost-animals-dialog/lost-animals-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'
]
})
export class GroupsComponent implements OnInit {

  groupsNotHasChildren: any[] = []
  groupsHasChildren: any[] = []
  filtredGroupsHasChildren: any[] = []
  groupAll: any[] = []
  animalId: any
  totalAll: any
  id: any
  idFarmer: any
  oldGroup: any
  defaultGroupId: any
  text = "GROUPS2"
  router
  currentRoute: any;
  supervisorId: any;
  authority = Authorities.GROUPS;
  auth: string[] = [];
  color='color-blue'
  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<LostAnimalsDialogComponent>, public appService: AppService, private route: ActivatedRoute, router: CustomRouter, public groupService: GroupService, public customerService: CustomerService) {
    this.router = router
  }

  ngOnInit() {
    this.currentRoute = this.router.url
    this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');
    this.appService.verif = false
    //this.loadGroups()
    if (this.idFarmer)
      this.loadGroups(this.idFarmer)
    else
      this.loadGroups()
    this.defaultGroupId = this.appService.defaultGroupId
    this.animalId = this.route.snapshot.paramMap.get('id');
    this.route.queryParams.subscribe(params => {
      this.oldGroup = params.oldGroup;
    })
    if (this.oldGroup)
      this.text = "CHANGE_GROUP"
  }


  back() {
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1) {
      this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');
      this.appService.getDefaultGroup().subscribe(group => {
        this.defaultGroupId = group
        this.router.navigateByUrl("farmers/" + this.idFarmer + "/animals/groups/" + this.defaultGroupId)
      })

    }
    else {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
        this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + this.idFarmer + "/animals/groups/" + this.defaultGroupId)
      }
      else
        this.router.navigateByUrl("animals/groups/" + this.defaultGroupId)
    }

  }

  loadGroups(idFarmer?) {
    let lostGroupHasChildren = []
    this.filtredGroupsHasChildren = []
    this.groupsHasChildren = []
    this.groupsNotHasChildren = []
    this.groupAll = []
    this.groupService.getGroupsOfCustomer(idFarmer).subscribe(data => {
      let datafilter = data['resources'].filter(d => d.id != -1)
      let dataAll = data['resources'].filter(d => d.id == -1)
      this.groupsHasChildren = datafilter.filter(d => d.hasChildren == true)
      this.filtredGroupsHasChildren = this.groupsHasChildren.filter(d => d.code == GroupCode.PRESENT_HERD)
      console.log(this.filtredGroupsHasChildren)
      let childrins = []
      this.groupsHasChildren.forEach(group => {
        group.childrens = []
        group['children'].sort((a, b) => {
          if (a.orderGroup > b.orderGroup) {
            return 1
          } else if (a.orderGroup < b.orderGroup) {
            return -1
          }
          return 0
        }).forEach(value => {
          const data = datafilter.find(d => d.id == value.id)
          data.hasParent = true
          childrins.push(value.id)
          value.clickable = true;
          if (value.important) {
            value.class = "col-6 offset-3"
            group.childrens.unshift(value)
          } else {
            value['class'] = "col-6"
            group.childrens.push(value)
          }
        })
        group['class'] = "col-12"
        group['clickable'] = false;

      })

      this.groupsNotHasChildren = datafilter.filter(d => d.hasChildren == false && !d.hasParent).sort((a, b) => {

        if (a.orderGroup > b.orderGroup) {
          return 1
        } else if (a.orderGroup < b.orderGroup) {
          return -1
        }
        return 0
      })

      lostGroupHasChildren = this.groupsHasChildren.filter(d => d.code == GroupCode.LOST_CODE)
      lostGroupHasChildren.forEach(group => { this.groupsNotHasChildren.push(group) })
      this.groupsNotHasChildren.forEach(group => {
        group['clickable'] = true;
      })


      this.totalAll = dataAll[0].groupAnimalCount;
      let clickable: Boolean = true;
      if (this.text == "CHANGE_GROUP")
        clickable = false;

      this.groupAll.push({
        "id": "-1", "groupAnimalCount": this.totalAll, "labelFr": "Tout le troupeau", "labelEn": "All Herd", "labelAr": "كل القطيع","labelInd": "Semua Kawanan",
        "code": 'ALL_ANIMAL', "color": "#306A90", "clickable": clickable,
      })
    })

  }

}
