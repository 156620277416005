import { Component, OnInit } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { TankService } from 'projects/shared/services/tank.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { Alert, Authorities } from 'projects/shared/tools/constants';
import { CustomRouter } from '../services/custom-router';
import { ActivatedRoute } from '@angular/router';
import { RealtimeTagService } from 'projects/shared/services/realtime-tag.service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-tanks',
  templateUrl: './tanks.component.html',
  styleUrls: ['./tanks.component.scss']
})
export class TanksComponent implements OnInit {
  tankCards: any
  tanksEmpty: any
  id: any
  tags: any[] = [];
  currentRoute: any;
  supervisorId: any;
  authorityTanks = Authorities.TANKS
  role: Boolean = false
  auth: string[] = [];
  constructor(private route: ActivatedRoute, public router: CustomRouter, public appService: AppService, private tankService: TankService,
    private alertsService: AlertsService, private translate: TranslateService,
    public realtimeTagService: RealtimeTagService) { }

  ngOnInit() {
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1 || (this.router.url).startsWith('/supervisors')) {
      this.role = true
      this.id = this.route.snapshot.paramMap.get('id');
    }
    else
    this.id = this.appService.connectedCustomer.entreprise.id
  
    this.currentRoute = this.router.url;
    this.loadTanks()
    this.realtimeTagService.connect((frame) => {
      let sub = interval(1000).subscribe(res => {
        if (this.tankCards) {
          this.realtimeTagService.subscribeDevices(this.tags)
          sub.unsubscribe();
        }
      })
    })

  }

  loadTanks() {
    this.realtimeTagService.unsubscribeDevices();
    this.appService.showLoading()
    this.tankService.findByEntreprise(this.id).subscribe(data => {
      this.tankCards = data['resources']
      this.appService.hideLoading()
      this.tanksEmpty = this.tankCards.length == 0;
      this.tags = [];
      this.tankCards.forEach(tank => {
        if (tank.deviceTemp)
          this.tags.push(tank.deviceTemp);
        if (tank.deviceAgitators.length > 0) {
          tank.deviceAgitators.sort((a, b) => {
            if (a.label > b.label) {
              return 1
            } else if (a.label < b.label) {
              return -1
            }
            return 0
          }).forEach(element => {
            this.tags.push(element)
          });
        }
        if (tank.deviceFridges.length > 0) {
          tank.deviceFridges.sort((a, b) => {
            if (a.label > b.label) {
              return 1
            } else if (a.label < b.label) {
              return -1
            }
            return 0
          }).forEach(element => {
            this.tags.push(element)
          });
        }
        if (tank.deviceHumidities.length > 0) {
          tank.deviceHumidities.sort((a, b) => {
            if (a.label > b.label) {
              return 1
            } else if (a.label < b.label) {
              return -1
            }
            return 0
          }).forEach(element => {
            this.tags.push(element)
          });
        }
      })
      this.realtimeTagService.subscribeDevices(this.tags)
    }, error => {
      this.appService.hideLoading();
      let  message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.code);
       let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK )
       this.alertsService.open(message, alert);
    });
  }

  ngOnDestroy() {
    this.realtimeTagService.disconnect();
  }

  message(msg) {
    this.appService.hideLoading();
    let message = this.translate.instant(msg);
    let alert = this.translate.instant(Alert.OK)
    this.alertsService.open(message, alert);
  }
  back() {
    if (this.currentRoute.startsWith('/supervisors')) {
      this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
      this.router.navigateByUrl('/supervisors/' + this.supervisorId + '/mcc');
    }
    else
      this.router.navigateByUrl('/mcc')
    this.appService.verif = false
  }
}
