<ng-container *ngIf="field">
    <div class="row" *ngIf="field.type == type.TEXT">
        <div class="col mb-3 mt-2 center-element">
            <div>
                <div class="title-style" [ngClass]="{rtl:appService.rtl()}">{{ field | codeTranslate }}</div>
                <input [readonly]="isDisabled" class="input-mob height font-size-text" [formControl]="code" type="text"
                    id="code" placeholder="{{ field | codeTranslate }}" [ngClass]="{rtl:appService.rtl()}"
                    (input)="appService.textOnly($event,textPattern)">
            </div>
        </div>
    </div>

    <div class="row" [ngClass]="{rtl:appService.rtl()}" *ngIf="field.type == type.DATE">
        <div class="col mb-3 mt-2 center-element ">
            <ion-item class="input-mob-date ps-2 pe-2">
                <ion-datetime #datePicker mode="ios" [ngClass]="{rtl:appService.rtl()}" class="font-size-text"
                    [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()" [formControl]="code"
                    [max]="maxDate" placeholder="{{ 'CHOOSE'| translate }}&nbsp;{{ 'DATE' | translate}}" [min]="minDate"
                    [doneText]="'SAVE' |translate" [cancelText]="'CANCEL' |translate"
                    [monthShortNames]="appService.monthShort()" required>
                </ion-datetime>
                <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar' : 'date'"
                    src="/assets/images/calendar4.svg" />
            </ion-item>
        </div>
    </div>
    <div class="row" *ngIf="field.type == type.TEXTAREA">

        <div class="col mb-3 mt-2 center-element">
            <div>
                <div class="title-style" [ngClass]="{rtl:appService.rtl()}">{{ field | codeTranslate }}</div>
                <textarea class="input-mob input-height font-size-text pt-2" [ngClass]="{rtl:appService.rtl()}"
                    placeholder="{{ field | codeTranslate }}" [formControl]="code"></textarea>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="field.type == type.NUMBER" [ngClass]="{rtl: appService.rtl()}">
        <div class="col center-element mt-1 mb-1" [ngClass]="{rtl: appService.rtl()}" *ngIf="!notShow">

            <div>
                <div class="title-style" [ngClass]="{rtl:appService.rtl()}">{{ field | codeTranslate }}</div>
                <input [ngClass]="!showUnit ? 'width-weight': 'width-weight-currency'"
                    class="input-mob-style height font-size-text" placeholder="{{ field | codeTranslate }}"
                    type="number" [formControl]="code" (keypress)="appService.numericOnly($event, numericPattern)">
                <span class="text-kg ms-1" [ngClass]="{rtl: appService.rtl()}" *ngIf="showUnit">{{ unit | translate
                    }}</span>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="field.type == type.NUMBER_PERCENTAGE" [ngClass]="{rtl: appService.rtl()}">
        <div class="col center-element mt-2 mb-2" [ngClass]="{rtl: appService.rtl()}" *ngIf="!notShow">
            <div>
                <div class="title-style" [ngClass]="{rtl:appService.rtl()}">{{ field | codeTranslate }}</div>
                <input [ngClass]="!showUnit ? 'width-weight' : 'width-weight-currency'"
                    class="input-mob-style height font-size-text" placeholder="{{ field | codeTranslate }}"
                    type="number" [formControl]="code" (input)="percentValue($event)"
                    (keypress)="appService.numericOnly($event, numericPattern)">
            </div>
        </div>
    </div>

    <div class="row" *ngIf="field.type == type.DYNAMIC" [ngClass]="{rtl:appService.rtl()}">
        <div class="col center-element mt-2 mb-2 " [ngClass]="{rtl:appService.rtl()}" *ngIf="!notShow">
            <div>
                <div class="title-style" [ngClass]="{rtl:appService.rtl()}">{{ field | codeTranslate }}</div>
                <input *ngIf=" field.code !='totalCosts'&& field.code !='totalQuantityMilk' && field.code !='incomes'"
                    class="input-mob width-weight font-size-text"  [ngClass]="field.code=='snf'||field.code=='adjusted' ||field.code=='soldAdjusted'? ' color-dynamic' : ''" placeholder="{{ field | codeTranslate }}" readOnly
                    [formControl]="code">
                <input *ngIf=" field.code =='totalCosts'||field.code =='totalQuantityMilk' ||field.code =='incomes'"
                    class="input-mob width-weight-currency-dynamic font-size-text "[ngClass]="field.code=='totalQuantityMilk'? ' color-dynamic' : ''"
                    placeholder="{{ field | codeTranslate }}" readOnly [formControl]="code">

                <span class="text-kg ms-1" [ngClass]="{rtl:appService.rtl()}"
                    *ngIf="field.code=='totalCosts'||field.code =='incomes'"> {{unit |
                    translate}}</span>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="field.type == type.SELECT_QUERY">
        <div class="col center-element mt-2 mb-2">
            <div>
                <div class="title-style" [ngClass]="{rtl:appService.rtl()}">{{ field | codeTranslate }}</div>
                <mat-select class="input-mob height font-size-text width" placeholder="{{ field | codeTranslate }}"
                    disableOptionCentering panelClass="myPanelSettingFarmerClass" [ngClass]="{rtl:appService.rtl()}"
                    [formControl]="code" required>
                    <mat-option *ngFor="let element of field.options" [value]="element.code"><span
                            [ngClass]="{rtl:appService.rtl()}">{{element.code}}</span>
                    </mat-option>
                </mat-select>
            </div>
        </div>
    </div>


    <div class="row" *ngIf="field.type == type.SELECT_STATIC">
        <div class="col mb-3 mt-2 center-element">
            <div>
                <div class="title-style" [ngClass]="{rtl:appService.rtl()}">{{ field | codeTranslate }}</div>
                <mat-select [disabled]="isDisabled" class="input-mob height font-size-text width" [formControl]="code"
                    disableOptionCentering panelClass="myPanelSettingFarmerClass"
                    placeholder="{{ field | codeTranslate }}" [ngClass]="{rtl:appService.rtl()}" required>
                    <mat-option *ngFor="let ele of field.options" [value]="ele.code"><span [disabled]="isDisabled"
                            [ngClass]="{rtl:appService.rtl()}" style="font-size: 24px;">{{ele |
                            codeTranslate}}</span>
                    </mat-option>
                </mat-select>
            </div>
        </div>
    </div>
    <div class="row " *ngIf="displayCode">
        <div class="col mt-2 mb-1  center-element">
            <section [ngClass]="{rtl:appService.rtl()}">
                <mat-checkbox [formControl]="code" *ngIf="field.type == type.CHECKBOX">
                    <span class="rappel-style me-1 ms-1">
                        {{field | codeTranslate }}
                    </span>
                </mat-checkbox>
                <input class="input-mob year-style" placeholder="{{'NUMBER' | codeTranslate }}" type="number"
                    [formControl]="code" *ngIf="field.type == type.REMINDER"
                    (keypress)="appService.numericOnly($event,numericPatternn)">
                <span class="rappel-style me-1 ms-1" *ngIf="field.type == type.REMINDER">
                    {{field | codeTranslate }}
                </span>
            </section>
        </div>
    </div>

</ng-container>