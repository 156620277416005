import { Component, OnInit } from '@angular/core';
import { AlertService } from 'projects/shared/services/alert.service';
import { AppService } from 'projects/shared/services/app.service';
import { GroupService } from 'projects/shared/services/group.service';
import { CustomRouter } from '../services/custom-router';
import { Authorities, Alert, Roles, SectionForm } from 'projects/shared/tools/constants';
import { DeviceService } from 'projects/shared/services/device.service';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { PushNotificationService } from 'projects/shared/services/push-notification.service';
import { CustomerService } from 'projects/shared/services/customer.service';
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { FarmerService } from 'projects/shared/services/farmer.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { LocalNotificationService } from 'projects/shared/services/local-notification.service';
//const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');


import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { environment } from 'projects/publicV2/src/environments/environment';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { MatDialog } from '@angular/material/dialog';
import { LikeComponent } from '../menu/like/like.component';
import { CustomerLikeService } from 'projects/shared/services/customer-like.service';
import state from 'projects/shared/tools/state';
import { DeviceFarmerService } from 'projects/shared/services/device-farmer.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  authAdmin: string[] = [];
  auth: string[] = [];
  collar: boolean = false
  defaultGroupId: any
  list = []
  list1 = []

  numberAlert: any = 0
  defaultGroupIdSubscription: any;
  formTemplateIdForFarmerMilkProductionSubscription: any;
  formTemplateIdForFarmerFeedConsumptionSubscription: any;
  thiCards: any[]
  thiEmpty: any
  getTags = []
  getAlerts = []
  getGroup = []
  getSupervisors = []
  getEntreprises = []
  getCustomerSetting = []
  getFormTemplate = []
  getShopFormTemplate = []
  allowPersonal: boolean
  service: any
  role: boolean = false
  authFarmer: string[] = [];
  formTemplateIdFarmerMilkProduction: any
  formTemplateIdFarmerFeedConsumption: any
  idFormTemplateStock
  formTemplateFormFarmerEconomyId
  constructor(private deviceFarmerService: DeviceFarmerService,public dialog: MatDialog, private likeService: CustomerLikeService, public localNotificationService: LocalNotificationService, public formTemplateService: FormTemplateService, public appService: AppService, public groupService: GroupService, private alertService: AlertService, private translate: TranslateService,
    private alertsService: AlertsService, public router: CustomRouter, public deviceService: DeviceService,
    public pushNotificationService: PushNotificationService, public tokenManagementService: TokenManagementService) {
      this.appService.listId=[]
    this.getTags = []
    this.getAlerts = []
    this.getCustomerSetting = []

    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
      this.authAdmin.push((role.adminAccess).toString())
      if (role.code == Roles.ROLE_FARMER) {
        this.authFarmer.push((true).toString())
      }
      role['authorityRoles'].forEach(authorityRole => {
        if (authorityRole.authority.code == Authorities.TAGS)
          this.getTags.push((authorityRole["get"]).toString());
        if (authorityRole.authority.code == Authorities.ALERTS)
          this.getAlerts.push((authorityRole["get"]).toString());
        if (authorityRole.authority.code == Authorities.GROUPS)
          this.getGroup.push((authorityRole["get"]).toString());
        if (authorityRole.authority.code == Authorities.SUPERVISORS)
          this.getSupervisors.push((authorityRole["get"]).toString());
        if (authorityRole.authority.code == Authorities.CUSTOMER_SETTING)
          this.getCustomerSetting.push((authorityRole["get"]).toString());
        if (authorityRole.authority.code == Authorities.CUSTOMER_SETTING)
          this.getCustomerSetting.push((authorityRole["edit"]).toString());
        if (authorityRole.authority.code == Authorities.FORM_TEMPLATE)
          this.getFormTemplate.push((authorityRole["get"]).toString());
          if (authorityRole.authority.code == Authorities.SHOP_FORM_TEMPLATE)
          this.getShopFormTemplate.push((authorityRole["get"]).toString());
      })
    })
    if (this.auth.indexOf("true") != -1) {
      this.role = true
    }


    this.list = [
      { img: "/assets/images/Exclusion1.svg", router: "/alerts", text: "ALERTS", class: "sect-style alert-style", back: "alert-back"/*, authority: Authorities.ALERTS*/ },
    
      { img: "/assets/images/CCL_icon.svg", router: "/mcc", text: "MCC", class: "sect-style troups-style", back: "troups-back", authority: this.auth.indexOf("true") != -1 ? Authorities.ENTREPRISES : Authorities.NO_ACCESSIBLE },
      { img: "/assets/images/Groupe-428-home.svg", router: "/animals/groups", text: "GROUPS2", class: "sect-style troups-style", back: "troups-back",authority: this.authFarmer.indexOf("true") != -1 ? Authorities.ANIMALS : Authorities.NO_ACCESSIBLE },
     
      { img: "/assets/images/milk_tank_icon.svg", router: "/tanks", text: "TANKS-MOB", class: "sect-style troups-style", back: "troups-back", authority: (this.auth.indexOf("true") == -1 && this.authAdmin.indexOf("true") == -1) ? Authorities.TANKS : Authorities.NO_ACCESSIBLE },
      
      
     /*
      // { img: "/assets/images/alert/THI_high.svg", router: "/thi", text: "THI", class: "sect-style thi-style", back: "thi-back", authority: (this.authFarmer.indexOf("true") != -1) ? Authorities.TAGS : Authorities.NO_ACCESSIBLE },
      //{ img: this.collor ? '/assets/images/Groupe-167.svg' : '/assets/images/Groupe-167-1.svg', router: "/home", text: "WELL_BEING", class: this.collor ? 'text-style' : 'text-style text-style-op', back: "default-back", authority: Authorities.REPORTS_GRAPHS},
      //{ img: this.collor ? '/assets/images/Groupe-167.svg' : '/assets/images/Groupe-167-1.svg', router: "/home", text: "REMUNATION", class: this.collor ? 'text-style' : 'text-style text-style-op', back: "default-back", authority: Authorities.RUMINATION_UI }
     { img: '/assets/images/Groupe-167-1.svg', router: "/home", text: "WELL_BEING", class: 'text-style text-style-op', back: "default-back", authority: Authorities.TAGS },
       { img: '/assets/images/Groupe-167-1.svg', router: "/home", text: "REMUNATION", class: 'text-style text-style-op', back: "default-back", authority: Authorities.TAGS },*/

    ]
    if ((this.authFarmer.indexOf("true") != -1)) 
    this.list.splice(4,0, { img: "/assets/images/Dashboard.svg", router: "/dashboard", text: "DASHBOARD", class: "sect-style troups-style", back: "alert-back"},)
    
    if (this.getFormTemplate.indexOf("true") != -1 && (this.authFarmer.indexOf("true") != -1)) {
      
      this.formTemplateService.getForms("form-template",SectionForm.farmer_milk_production).subscribe(res => {
      this.appService.formTemplateFormFarmerMilkProductionId =this.formTemplateIdFarmerMilkProduction=res[0].id
      this.list.splice(5, 0, { img: '/assets/images/tank_white.svg', router: "/settings/" + this.formTemplateIdFarmerMilkProduction + "/" + Authorities.FARMER_MILK_PRODUCTION, text: "MILK_PRODUCTION", class: 'sect-style milk-production-style', back: "milk-production-back", authority: Authorities.FARMER_MILK_PRODUCTION },);
      })

      this.formTemplateService.getForms("form-template",SectionForm.farmer_feed_consumption).subscribe(res1 => {
        this.appService.formTemplateFormFarmerFeedConsumptionId=this.formTemplateIdFarmerFeedConsumption=res1[0].id
        this.list.splice(6, 0, { img: '/assets/images/feed-consumption-icon_new.svg', router: "/settings/" + this.formTemplateIdFarmerFeedConsumption + "/" + Authorities.FARMER_FEED_CONSUMPTION, text: "CONCENTRATED_FEED", class: 'sect-style feed-consumption-style', back: "feed-consumption-back", authority: Authorities.FARMER_FEED_CONSUMPTION },);
        })
        this.formTemplateService.getForms("form-template",SectionForm.farmer_economy).subscribe(res1 => {
          this.appService.formTemplateFormFarmerEconomyId=this.formTemplateFormFarmerEconomyId=res1[0].id
          this.list.splice(7,0,{ img: "/assets/images/new_Other_income_costs.svg", router: "/setting-economy", text: "ECONOMY", class: "sect-style abort-style", back: "abort-back", authority: Authorities.FORM})
          })
          this.formTemplateService.getForms("form-template", SectionForm.farmer_stock).subscribe(res2 => {
            this.appService.idFormTemplateStockId=this.idFormTemplateStock = res2[0].id
            this.list.splice(8,0,{ img: "/assets/images/stock_1.svg", router: "/stock", text: "STOCK", class: "sect-style stock-style", back: "stock-back", authority: Authorities.STOCK },)
            })
    
          }
    if (this.getGroup.indexOf("true") != -1)
      this.defaultGroupIdSubscription = this.appService.getDefaultGroupUpdated.subscribe(res => {
        this.appService.getDefaultGroup().subscribe(group => {
          this.defaultGroupId = group
          this.list.splice(1, 0, { img: (this.auth.indexOf("true") != -1 || this.getSupervisors.indexOf("true") != -1) ? "/assets/images/farmer_icon_neutral.svg" : "/assets/images/Groupe-156.svg", router: (this.auth.indexOf("true") != -1) ? "/farmers" : ((this.getSupervisors.indexOf("true") != -1) ? "/supervisors" : "/animals/groups/" + this.defaultGroupId), text: (this.auth.indexOf("true") != -1) ? "FARMERS_MOB" : ((this.getSupervisors.indexOf("true") != -1) ? "SUPERVISORSS" : "TROUPS"), class: "sect-style troups-style", back: "troups-back", authority: (this.auth.indexOf("true") != -1 || this.getSupervisors.indexOf("true") != -1) ? Authorities.SUPERVISORS : Authorities.ANIMALS })

        })
      })
      this.deviceFarmerService.getDevicesCode().subscribe((data: any) => {
        this.thiCards = data
        this.thiEmpty = this.thiCards.length == 0;
      if(!this.thiEmpty)
      this.list.splice(9, 0, { img: "/assets/images/alert/THI_high.svg", router: "/thi", text: "THI", class: "sect-style thi-style", back: "thi-back", authority: (this.authFarmer.indexOf("true") != -1) ? Authorities.TAGS : Authorities.NO_ACCESSIBLE })
    })
  
    
    
   
    
   
    
   

    this.appService.getDefaultGroupUpdated.next(null);
    this.appService.getFormTemplateForFarmerMilkProduction.next(null);
    this.appService.getFormTemplateForFarmerFeedConsumption.next(null);
    if(this.appService.listShop.length == 0)
    if(this.getShopFormTemplate.indexOf("true") != -1 )
    this.appService.loadShopFormTmplate();
  }

  ngOnInit() {
    if (this.authFarmer.indexOf("true") != -1)
      this.checkLike();
    
    this.service = this.appService.checkService();
    this.appService.verif = false
    if (this.getCustomerSetting.indexOf("true") != -1)
      this.appService.saveLanguage()
    this.saveDeviceToken()

    const messaging = (async () => {
      const isSupportedBrowser = await isSupported();
      if (isSupportedBrowser)
        this.requestPermission(this.appService.checkService());
      else
        console.log('Firebase not supported this browser')
    })();





    this.checkIfHasDevices()
    this.getUnreadAlertCount();
    //googleAnalytics
    this.appService.googleAnalyticsAllPages('home')
    setTimeout(() => {
      this.list1 = this.list
    }, 1200);
  }
  checkIfHasDevices() {
    if (this.getTags.indexOf("true") != -1)
      this.deviceService.checkIfHasDevices().subscribe(res => {
        if (res)
          this.collar = true
      },
        error => {
          this.appService.hideLoading();
          let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
          let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
          this.alertsService.open(message, alert);
        })
  }

  saveDeviceToken() {
    let deviceToken = this.tokenManagementService.getDeviceToken()
    if (deviceToken)
      this.service.saveDeviceToken({ "deviceToken": deviceToken }).subscribe(
        data => {
          console.log('register device token success');
        }, error => {
          console.log('register device token failed');
        }
      );
  }

  getUnreadAlertCount() {
    if (this.getAlerts.indexOf("true") != -1)
      this.alertService.getUnreadAlertCount().subscribe(data => {
        this.numberAlert = data
      },
        error => {
          this.appService.hideLoading();
          let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
          let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
          this.alertsService.open(message, alert);
        })

  }
  navigate(element) {
    let msg = this.translate.instant(this.collar ? "FEATUE_IN_DEV" : "NEED_COLLAR");
    let alert = this.translate.instant(Alert.OK)
    if (this.getAlerts.indexOf("true") == -1 && element.text == "ALERTS")
      return this.alertsService.open(this.translate.instant("FEATUE_IN_DEV"), alert);
    switch (element.text) {
      case "WELL_BEING":
      case "REMUNATION":
        return this.alertsService.open(msg, alert);
      default:
        return this.router.navigateByUrl(element.router)
    }

  }
  /* getByStatusAndConnected(){
     
     this.farmerSupervisorRequest.getByStatusAndConnected().subscribe(data => {
       this.farmerSupervisorRequests=data;
       let nameSupervisor=this.farmerSupervisorRequests[0].supervisor.fullname;
       console.log(nameSupervisor)
       if(nameSupervisor!= null){
       const dialogRef = this.dialog.open(RequestDialogComponent, {
         width: '350px',
         data: {
           message: nameSupervisor+" "+ this.translate.instant('WANTS_ADD_YOU')+"",
           onConfirm: () => {
           }
         }
       });}
     })
   }*/
  requestPermission(service) {

    const messaging = getMessaging();
    getToken(messaging,
      { vapidKey: environment.firebase.vapidKey }).then(
        (currentToken) => {
          if (currentToken) {
            console.log(currentToken);
            if (!this.appService.connectedCustomer.getWebToken)
              service.saveWebToken({ "webToken": currentToken }).subscribe(
                data => {
                  console.log('register web token success');
                }, error => {
                  console.log('register web token failed');
                }
              );
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
  }



  checkLike() {
    setTimeout(() => {
      let days = 7;
      // this.appService.getConnected().subscribe(customer => { 
      // this.id = customer.id
      this.likeService.checkLike(days).subscribe(data => {
        if (data) {
          const dialogRef = this.dialog.open(LikeComponent, {
            width: '500px',
            data: {
              like: data
            }
          });
        }
      })
      // })
    }, 2000);
  }


}
