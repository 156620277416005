import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';
import { TokenManagementService } from './token-management.service';
@Injectable({
	providedIn: 'root',
})
export class PushNotificationService {
	constructor(public tokenManagementService: TokenManagementService, private http: HttpClient, private configService: ConfigService) {
	}

	registerTopic(body) {
	/*	let httpOptions = { headers: new HttpHeaders({
			'language':  this.tokenManagementService.getTranslateLanguage(),
		  })
		};*/
		return this.http.post(this.configService.getApiPath() + `/notifications/token`, body/*, httpOptions*/);
	}

}
