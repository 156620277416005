import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesComponent } from './notes.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NoteCardModule } from './note-card/note-card.module';
import { AnimalBarModule } from '../animals/animal-bar/animal-bar.module';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';

@NgModule({
  declarations: [NotesComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NoteCardModule,
    AnimalBarModule, MoomeCommonModule,
  ],
  exports: [
    NotesComponent
  ]
})
export class NotesModule { }
