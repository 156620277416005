<div class="style-bar">
    <div class="row">
        <div class="text-center mt-5 mb-4">
            <div class="col"><img src="/assets/images/g3368-8.svg"></div>
        </div>
    </div>
    <div class="style-login">
        <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="row">
                <div class="col text-center mt-5 mb-5">
                    <span class="text-style color-dark-blue"
                        [ngClass]="{rtl:appService.rtl()}">{{ 'LOG_IN_P' | translate }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col text-center">
                    <input matInput name="phone" class="input input-mob width-input"
                        type="text" placeholder="{{'username' | translate}}" formControlName="username" [ngClass]="{rtl:appService.rtl()}">
                </div>
            </div>
            <div class="row">
                <div class="col text-center mt-5 position-relative">
                    <input matInput name="password" class="input input-mob width-input"
                        [type]="hide ? 'text' : 'password'" [ngClass]="{rtl:appService.rtl()}"
                        placeholder="{{'PASSWORD' | translate}}" formControlName="password">
                    <mat-icon *ngIf="hide" class="icon-pos" (click)="!hide ? hide= true : hide=false"
                        [ngClass]="appService.rtl()? 'icon-ar-pos' :  'icon-fr-en-pos'" color="primary"> visibility
                    </mat-icon>
                    <mat-icon *ngIf="!hide" class="icon-pos" (click)="!hide ? hide= true : hide=false"
                        [ngClass]="appService.rtl()? 'icon-ar-pos' :  'icon-fr-en-pos'" color="primary"> visibility_off
                    </mat-icon>
                </div>

            </div>

            <div class="row">
                <div class="col forget-pos" [ngClass]="appService.rtl()? 'pos-for-ar' : 'pos-for'">
                    <span class="text-forget-pass-style color-dark-blue"
                        [ngClass]="{rtl:appService.rtl()}">{{ 'FORGET_PASSWORD' | translate }}</span>&nbsp;<a
                        (click)="router.navigateByUrl('/forget-password')"
                        class="text-forget-pass-style moome-color"><span
                            [ngClass]="{rtl:appService.rtl()}">{{ 'PASS' | translate }}</span></a>
                </div>
            </div>
            <div class="row">
                <div class="col text-center mt-5">
                    <button [ngClass]="!loginForm.valid ? 'opac' : '' " mat-raised-button color="primary"
                        class="button-mob"><span
                            [ngClass]="{rtl:appService.rtl()}">{{ 'LOGIN' | translate }}</span></button></div>
            </div>
            <div class="row">
                <div class="col text-center mt-3 mb-3">
                    <span class="text-new-user-style color-dark-blue"
                        [ngClass]="{rtl:appService.rtl()}">{{ 'NEW_USER?' | translate }}</span>&nbsp;<a
                        (click)="router.navigateByUrl('/sign-up')" class="text-new-user-style moome-color">
                        <!--a
                        routerLink="/sign-up" class="text-new-user-style moome-color disabled"--><span
                            [ngClass]="{rtl:appService.rtl()}">{{ 'SIGN_UP' | translate }}</span></a>
                </div>
            </div>
            <div class="row">
                <div class="col text-center mt-2 mb-4 lang-pos">
                        <app-language [value]="selectedLanguage" [color]="color"></app-language>
                </div>
            </div>
        </form>
    </div>
</div>