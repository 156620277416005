import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { HelpModule } from './help/help.module';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { LanguageModule } from '../language/language.module';

@NgModule({
  declarations: [
    MenuComponent
  ],

  imports: [
    NgxHideOnScrollModule,
    CommonModule,
    TranslateModule,
    RouterModule,
    MoomeCommonModule,
    HelpModule,
    LanguageModule
  ],

  exports: [
    MenuComponent
  ]

})
export class MenuModule { }
