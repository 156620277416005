import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';

@Directive({
  selector: '[appAuthorized]'
})
export class AuthorizedDirective {

  constructor(public elmRef: ElementRef,
    public renderer: Renderer2, public appService: AppService) {

  }

  ngOnInit() {

  }

  checkAuthDisplay(field, authority, hideType) {
    
    let auth: String[] = []
    this.appService.connectedCustomer['roles'].forEach(role => {
      role['authorityRoles'].forEach(authorityRole => {
        if (authorityRole.authority.code == authority)
          auth.push((authorityRole[field]).toString());
      })
    })
     
    let val = `${hideType}`;
    if (auth.indexOf("true") == -1)
      this.renderer.setStyle(this.elmRef.nativeElement, 'display', val);
  }
}
