import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { ThiCardComponent } from './thi-card.component';



@NgModule({
  declarations: [ThiCardComponent],
  imports: [
    CommonModule,
    MoomeCommonModule,
    TranslateModule,
  ],
  exports: [
    ThiCardComponent
  ]
})
export class ThiCardModule { }
