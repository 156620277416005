import { Injectable } from '@angular/core';
import { CodeTranslatePipe } from '../pipes/code-translate.pipe';

@Injectable({
  providedIn: 'root'
})
export class MarkerPopUpService {
  constructor(public codeTranslate: CodeTranslatePipe) { }

  makeCapitalPopup(data: any): string {
    return `<div> ${ data.customer.fullname }</div>`
  }
}