import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionBarModule } from '../../../animals/section-bar/section-bar.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { HistoryCardModule } from '../history-card/history-card.module';
import { CommonSettingFormComponent } from './common-setting-form.component';
import { CommunHistoryCardModule } from '../commun-history-card/commun-history-card.module';
import { MaterialModule } from '../../../material/material.module';



@NgModule({
  declarations: [
    CommonSettingFormComponent
  ],
  imports: [
    CommonModule,
    SectionBarModule,
    TranslateModule,
    RouterModule,
    MoomeCommonModule,
    HistoryCardModule,
    MaterialModule,
    CommunHistoryCardModule],
  exports: [
    CommonSettingFormComponent
  ]
})
export class CommonSettingFormModule { }
