<div class="card-type-style" [ngClass]="alertDetail.read  ? ' backgroundReaded' : 'backgroundNotReaded'">

    <div class="row h-100">
        <div (click)="apply()">
            <div class="  col">
                <div class="row">
                    <div class="col-1 mt-2 mb-1"  *ngIf="!checkType(alertDetail.alert.type) && !checkTypeConnected(alertDetail.alert.type) && !checkTypeThi(alertDetail.alert.type)">
                        <div class="dot"
                            [ngStyle]="{'background-color': alertDetail.alert.animal?.customerAnimalGroups[0]?.color}">
                        </div>
                    </div>
                    <div class="col-7 mt-1 mb-1">
                        <span class="text-style-UID" [ngStyle]="{'font-size':code == 'MAR' ? '18px' : '25px' }"
                            *ngIf="!checkType(alertDetail.alert.type) && !checkTypeConnected(alertDetail.alert.type)&& !checkTypeThi(alertDetail.alert.type)">
                            {{appService.valueUid ==nationalIdentification? (alertDetail.alert?.animal?.uid ?
                            alertDetail.alert.animal.uid : alertDetail.alert.animal.name) : (appService.valueUid ==
                            nameLocalCode? (alertDetail.alert?.animal?.name ? alertDetail.alert.animal.name :
                            alertDetail.alert.animal.uid) : (alertDetail.alert?.animal?.uid ?
                            alertDetail.alert.animal.uid : alertDetail.alert.animal.name))}}
                        </span>
                        <span class="text-style-UID" [ngClass]="{rtl:appService.rtl()}"
                            [ngStyle]="{'font-size':code == 'MAR' ? '18px' : '25px' }"
                            *ngIf="checkType(alertDetail.alert.type) ">
                            {{ 'NUMBER-TANK' | translate}}{{alertDetail.alert?.devices[0]?.code ?
                            alertDetail.alert.devices[0].code:(alertDetail.alert?.devices[0]?.codeTank ?
                            alertDetail.alert.devices[0].codeTank:
                            (alertDetail.alert?.devices[0]?.tankDeviceAgitators[0]?.code ?
                            alertDetail.alert.devices[0].tankDeviceAgitators[0].code :
                            alertDetail.alert.devices[0].tankDeviceFridges[0].code))}}
                        </span>
                        <span class="text-style-UID" [ngClass]="{rtl:appService.rtl()}"
                            [ngStyle]="{'font-size':code == 'MAR' ? '18px' : '25px' }"
                            *ngIf="checkTypeConnected(alertDetail.alert.type) ">
                            {{alertDetail.alert?.devices[0]?.codeTank || alertDetail.alert?.devices[0]?.tankDeviceAgitators[0]?.code  || alertDetail.alert?.devices[0]?.tankDeviceFridges[0]?.code ? ('NUMBER-TANK'| translate) : ''}}
                            {{alertDetail.alert?.devices[0] ? (alertDetail.alert?.devices[0]?.uidAnimal ? (appService.valueUid == nationalIdentification?
                            (alertDetail.alert?.devices[0]?.uidAnimal ?
                            alertDetail.alert.devices[0].uidAnimal : alertDetail.alert.devices[0].nameAnimal) :
                            (appService.valueUid ==
                            nameLocalCode? (alertDetail.alert?.devices[0]?.nameAnimal ?
                            alertDetail.alert.devices[0].nameAnimal :
                            alertDetail.alert.devices[0].uidAnimal) : (alertDetail.alert?.devices[0]?.uidAnimal ?
                            alertDetail.alert.devices[0].uidAnimal : alertDetail.alert.devices[0].nameAnimal))) :(alertDetail.alert?.devices[0]?.codeTank ?
                            alertDetail.alert.devices[0].codeTank :
                            (alertDetail.alert?.devices[0]?.tankDeviceAgitators[0]?.code ?
                            alertDetail.alert.devices[0].tankDeviceAgitators[0].code : (
                            alertDetail.alert?.devices[0]?.tankDeviceFridges[0]?.code ? alertDetail.alert.devices[0].tankDeviceFridges[0].code : '' )))) : alertDetail.alert.gateways[0].code }}

                        </span>
                    </div>


                    <div class="col-4 mt-2 mb-1">
                        <div class="float-end">
                            <span class="style-date me-1">{{alertDetail.alert.date | date:
                                'dd/MM/yyyy'}}</span>
                                <br>
                               <!-- <span class="style-date me-1">{{alertDetail.alert.date | date:
                                    'HH:mm'}}</span>-->
                        </div>
                        
                        
                    </div>
                   
                </div>
                <div class="row" *ngIf="role">
                    <div class="col-12 ps-4"
                        *ngIf="!checkType(alertDetail.alert.type) && !checkTypeConnected(alertDetail.alert.type) && !checkTypeThi(alertDetail.alert.type)">
                        <span class="text-style-customer"> {{ alertDetail.alert.animal.address.customer.fullname}}
                        </span>
                    </div>
                    <div class="col-12 ps-4" *ngIf="checkTypeConnected(alertDetail.alert.type) || checkTypeThi(alertDetail.alert.type)">
                     <span class="text-style-customer"> {{ alertDetail.alert?.devices[0]?.nameEntreprise ?
                            alertDetail.alert.devices[0].nameEntreprise :( alertDetail.alert?.devices[0]?.nameFarmer ?
                            alertDetail.alert.devices[0].nameFarmer : (alertDetail.alert?.gateways[0]?.addresses[0]?.fullname ?
                            alertDetail.alert.gateways[0].addresses[0].fullname :(alertDetail.alert?.gateways[0]?.tank[0]?.name ? alertDetail.alert.gateways[0].tank[0].name :  (
                                alertDetail.alert.devices[0].deviceFarmers[0]?.customerFullName?  alertDetail.alert.devices[0].deviceFarmers[0].customerFullName : ''
                            )   ) ))}}
                        </span>
                    </div>
                    <div class="col-12 ps-4" *ngIf="checkType(alertDetail.alert.type)">
                        <span class="text-style-customer"> {{alertDetail.alert?.devices[0]?.name ?
                            alertDetail.alert.devices[0].name :(alertDetail.alert?.devices[0]?.nameEntreprise ?
                            alertDetail.alert.devices[0].nameEntreprise:
                            (alertDetail.alert?.devices[0]?.tankDeviceAgitators[0]?.name ?
                            alertDetail.alert.devices[0].tankDeviceAgitators[0].name :
                            alertDetail.alert.devices[0].tankDeviceFridges[0].name))}}
                        </span>
                    </div>
                </div>

                <div class="row">
                    <div class="detail-style  col ms-2 me-2 " [ngClass]="appService.rtl()? 'p-2 rtl' : ''">
                        <div *ngIf="alertDetail.alert.type == 'AI'">      
                          {{alertDetail.alert.message | translate:{'label': alertDetail.alert.label.substring(2) |
                          translate } }}
                        </div>  
                        <div *ngIf="alertDetail.alert.type != 'AI'" class="misaligned" >
                            {{alertDetail.alert | codeTranslate}}
                        </div>                    
                    </div>
                </div>
                <!--div  [ngClass]="appService.rtl()? 'crois-style-ar' : 'crois-style'" *ngIf="alertDetail.read">
                    <i class="material-icons pointer" 
                     (click)="readAlert(alertDetail,$event)">visibility</i>
                </div-->
            </div>

        </div>
       <!-- <div class="d-flex " [ngClass]="appService.rtl()? 'justify-content-start' : 'justify-content-end'">
            <div class="  me-2 mb-2 ms-2" *ngIf="alertDetail.alert.message=='C1_BE_DONE_48H'&& code=='TN'">

                <button [ngClass]="{rtl:appService.rtl()}" class="button-dialog-lab button-text-size text-center"
                    mat-raised-button color="primary" (click)="confirmAppel()"><span
                        [ngClass]="appService.rtl()? 'button-mob-text-ar ' : 'button-mob-text'"
                        class="center-element">{{'CALL_LAB' | translate }} </span></button>

            </div>
        </div>-->
    </div>