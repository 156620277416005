import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { Animal } from './Animal';
import { Customer } from './Customer';
@HateoasResource('groups')
export class Group extends Resource {
  id: Number
  name: string;
  description: string
  animals: Animal[];
  customer: Customer | any;
  color: string;
  groupAnimalCount: any
  hasChildren: boolean
  code: any[];
  orderGroup: number
  backgroundColor: string;
}