import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimalCardComponent } from './animal-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AddWeightPopupModule } from '../add-weight-popup/add-weight-popup.module';
import { AddWeightPopupComponent } from '../add-weight-popup/add-weight-popup.component';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';



@NgModule({
    declarations: [AnimalCardComponent],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        AddWeightPopupModule,
        MoomeCommonModule
    ],
    exports: [
        AnimalCardComponent
    ]
})
export class AnimalCardModule { }
