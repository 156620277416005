<div *ngIf="project=='publicV2'" class="style-bar-troups w-100" ngxHideOnScroll [scrollingElementSelector]="'body'"
  [propertyUsedToHide]="'top'" [valueWhenHidden]="'-50px'" [valueWhenShown]="'90px'">
  <div class="row  h-100">
    <div class="col-12">
      <div class="row background-troups">
        <div class="col center-element" (click)="back()">
          <img src="/assets/images/left-arrow-1.svg" />
        </div>
        <div class="col center-element">
          <div *ngIf="graphType=='rumination'" class="row d-block">
            <div class="col text-center"> <img src="/assets/images/graph-bar-svgrepo-com-white.svg" />
            </div>
            <div class="col text-center ps-0 pe-0 pt-1"> <span class="text-style" [ngClass]="{rtl:appService.rtl()}">{{
                'RUMINATING_RESTING_TIME_MAJUSCULE' | translate }}</span></div>
          </div>
          <div *ngIf="graphType=='thi'" class="row d-block">
            <div class="col text-center"> <img src="/assets/images/graph-line-svgrepo-com-white.svg" />
            </div>
            <div class="col text-center ps-0 pe-0 pt-1"> <span class="text-style" [ngClass]="{rtl:appService.rtl()}">{{'THI_GRAPH'| translate }}</span></div>
          </div>

          <div *ngIf="graphType=='tempGraph'" class="row d-block">
            <div class="col text-center"> <img src="/assets/images/graph-svgrepo-com_white.svg" />
            </div>
            <div class="col text-center ps-0 pe-0 pt-1"> <span class="text-style" [ngClass]="{rtl:appService.rtl()}">{{
                'TEMP_GRAPH_MAJUSCULE' | translate }}</span></div>
          </div>

        </div>
        <div class="col center-element">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="position-card" [ngStyle]="{'margin-top':project == 'publicV2' ? '50px': ''}">

  <div class="row m-0">

    <div *ngIf="project=='admin'" class="col-12">
      <div class="d-none d-sm-block">

        <div class="d-flex align-items-center" style="margin-bottom:10px" [ngClass]="{rtl:appService.rtl()}">
          <i class="material-icons" style="align-self: center;font-size:40px;color: white">
            {{icon}}
          </i>
          <h2 style="margin-left: 6px;font-size:22px;color:white">{{ title | translate}}</h2>
        </div>
      </div>
    </div>
    <div [ngClass]="{rtl:appService.rtl()}" class="mx-auto">
      <mat-card class="style-card-graph">
        <form [formGroup]="formGroup" (ngSubmit)="apply()">
          <div class="row">
            <div [ngClass]="{rtl:appService.rtl()}" class="mx-auto">
              <a [ngClass]="{rtl:appService.rtl()}">{{ title| translate}}</a>
            </div>
          </div>
          <div class="row" [ngClass]="{rtl:appService.rtl()}">

            <div class="col-sm-6 col-12" *ngIf="(xyzGraph || rumGraph || thiGraph )" [ngClass]="{rtl:appService.rtl()}">
              <a *ngIf="!roleFarmer">
                <mat-form-field  style="width:100%" [ngClass]="appService.rtl()? 'mat-form-field-ar' : ''">
                  <mat-label [ngClass]="{rtl:appService.rtl()}">{{ 'CHOOSE' | translate}} &nbsp; {{ 'FARMER' |
                    translate}}

                  </mat-label>

                  <mat-select formControlName="farmer" disableOptionCentering panelClass="myPanelClass">
                    <mat-select-trigger>
                      {{formGroup.controls['farmer'].value != null? formGroup.controls['farmer'].value.fullname : null}}
                    </mat-select-trigger>
                    <input matInput placeholder="{{ 'CHOOSE' | translate}} &nbsp; {{ 'FARMER' | translate}}"
                    (keydown)="$event.stopPropagation()" (keyup)="onKeyFarmer($event.target.value)" class="search_style">
                    <mat-option *ngFor="let farmer of selectedFarmers" [value]="farmer"
                      (click)="thiGraph? loadDeviceFarmers() : loadAnimals()">
                      <div class="row">
                        <div class="col-2">
                          <i class="material-icons pointer" style="display: inline-block;
                                                        position: relative;
                                                        top: 4px;
                                                        left: 4px;">
                            perm_identity
                          </i>
                        </div>
                        <div class="col-10">
                          {{farmer.fullname}}
                        </div>
                      </div>
                    </mat-option>
                  </mat-select>

                  <mat-error *ngIf="!formGroup.controls['farmer'].valid && formGroup.controls['farmer'].touched">
                    {{ 'FIELD_REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </a>
            </div>

            <div class="col-sm-6 col-12" *ngIf="thiGraph">
              <mat-form-field [ngClass]="appService.rtl()? 'mat-form-field-ar' : ''" style="width:100%">
                <mat-label>{{ 'HUMIDITY_SENSORS' | translate}}</mat-label>
                <mat-select formControlName="device" disableOptionCentering panelClass="myPanelClass">
                  <input matInput placeholder="{{ 'HUMIDITY_SENSORS' | translate}}"
                  (keydown)="$event.stopPropagation()" (keyup)="onKeyHumiditySensors($event.target.value)" class="search_style">
                  <mat-option *ngIf="deviceFarmers.length>1" [value]="'ALL_HIMIDITY_SENSOR'">
                    {{ 'AVG_ALL_SENSORS' | translate}}
                  </mat-option>
                  <mat-option *ngFor="let deviceFarmer of selectedHumiditySensors" [value]="deviceFarmer.code">
                    {{deviceFarmer.label}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!formGroup.controls['device'].valid && formGroup.controls['device'].touched">
                  {{ 'FIELD_REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-sm-6 col-12" *ngIf="xyzGraph || rumGraph">
              <mat-form-field [ngClass]="appService.rtl()? 'mat-form-field-ar' : ''" style="width:100%">
                <mat-label>{{ 'LOCAL_CODE' | translate}}</mat-label>
                <mat-select formControlName="code" disableOptionCentering panelClass="myPanelClass">
                  <input matInput placeholder="{{ 'LOCAL_CODE' | translate}}"
                  (keydown)="$event.stopPropagation()" (keyup)="onKeyAnimals($event.target.value)" class="search_style">
                  <mat-option *ngFor="let animal of selectedAnimals" [value]="animal">
                    {{appService.valueUid == nationalIdentification? (animal.uid ? animal.uid :animal.name) : (appService.valueUid == nameLocalCode? (animal.name ? animal.name : animal.uid) : (animal.uid? animal.uid : (animal.name ? animal.name : 'NA')))}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!formGroup.controls['code'].valid && formGroup.controls['code'].touched">
                  {{ 'FIELD_REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>


          <div class="row" *ngIf="tempGraph">

            <div class="col-6">
              <a *ngIf="!roleCcl">
                <mat-form-field style="width:100%" [ngClass]="appService.rtl()? 'mat-form-field-ar' : ''">
                  <mat-label >{{ 'CHOOSE' | translate}} &nbsp; {{ 'ENTREPRISE' | translate}}</mat-label>
                  <mat-select formControlName="entreprise" disableOptionCentering panelClass="myPanelClass">
                    <mat-select-trigger>
                      {{formGroup.controls['entreprise'].value != null? formGroup.controls['entreprise'].value.name :
                      null}}
                    </mat-select-trigger>
                    <input matInput placeholder="{{ 'ENTREPRISE' | translate}}"
                    (keydown)="$event.stopPropagation()" (keyup)="onKeyEntreprises($event.target.value)" class="search_style">
                    <mat-option *ngFor="let entreprise of selectedEntreprises" [value]="entreprise"
                      (click)="loadTanks(entreprise.id)">
                      {{entreprise.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="!formGroup.controls['entreprise'].valid && formGroup.controls['entreprise'].touched">
                    {{ 'FIELD_REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </a>
            </div>


            <div class="col-6">
              <mat-form-field [ngClass]="appService.rtl()? 'mat-form-field-ar' : ''" style="width:100%">
                <mat-label>{{ 'LOCAL_CODE' | translate}}</mat-label>
                <mat-select formControlName="code" disableOptionCentering panelClass="myPanelClass">
                  <input matInput placeholder="{{ 'LOCAL_CODE' | translate}}" (keydown)="$event.stopPropagation()" (keyup)="onKeyTanks($event.target.value)"
                    class="search_style">
                  <mat-option *ngFor="let tank of selectedTanks" [value]="tank">
                    {{tank.code}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!formGroup.controls['code'].valid && formGroup.controls['code'].touched">
                  {{ 'FIELD_REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

          </div>
          <div class="d-none d-sm-block">

            <span style="font-size: 18px; padding-bottom: 5px" [ngClass]="{rtl:appService.rtl()}">{{ 'LAST' |
              translate}}:&nbsp;</span>

            <div class="row">
              <div class="col-12">
                <mat-button-toggle-group formControlName="period">
                  <!--mat-button-toggle value="3_HOURS" style="width:87px"><span
                            [ngClass]="{rtl:appService.rtl()}">{{ '3_HOURS' | translate}}</span></mat-button-toggle>
                        <mat-button-toggle value="6_HOURS" style="width:87px"><span
                            [ngClass]="{rtl:appService.rtl()}">{{ '6_HOURS' | translate}}</span></mat-button-toggle-->
                  <mat-button-toggle value="1_DAY" style="width:87px"><span [ngClass]="{rtl:appService.rtl()}">{{
                      '1_DAY' | translate}}</span></mat-button-toggle>


                  <mat-button-toggle value="3_DAYS" style="width:87px"><span [ngClass]="{rtl:appService.rtl()}">{{
                      '3_DAYS' | translate}}</span></mat-button-toggle>
                  <mat-button-toggle value="1_WEEK" style="width:87px"><span [ngClass]="{rtl:appService.rtl()}">{{
                      '1_WEEK' | translate}}</span></mat-button-toggle>
                  <mat-button-toggle value="1_MONTH" style="width:87px"><span [ngClass]="{rtl:appService.rtl()}">{{
                      '1_MONTH' | translate}}</span></mat-button-toggle>
                  <!-- <mat-button-toggle value="3_MONTHS">{{ '3_MONTHS' | translate}}</mat-button-toggle>-->
                </mat-button-toggle-group>
              </div>
            </div>
          </div>
          <div class="d-block d-sm-none">
            <div class="row">
              <div class="col-sm-6 col-12">
                <mat-form-field [ngClass]="appService.rtl()? 'mat-form-field-ar' : ''" style="width:100%">
                  <mat-label>{{ 'CHOOSE' | translate}} &nbsp; {{ 'LAST' | translate}}</mat-label>
                  <mat-select formControlName="period" disableOptionCentering panelClass="myPanelClass">


                    <input matInput placeholder="{{ 'CHOOSE' | translate}} &nbsp; {{ 'LAST' | translate}}"
                    (keydown)="$event.stopPropagation()"  (keyup)="onKeyPeriod($event.target.value)" class="search_style">


                    <mat-option *ngFor="let period of selectedPeriods" [value]="period">
                      {{period | translate}}
                    </mat-option>
                  </mat-select>

                  <mat-error *ngIf="!formGroup.controls['period'].valid && formGroup.controls['period'].touched">
                    {{ 'FIELD_REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <mat-checkbox (change)="showOptions($event)"><span [ngClass]="appService.rtl()? 'check-style' : ''">{{
              'SPECIFIC_PERIOD' | translate}}</span>
          </mat-checkbox>

          <div class="row">
            <!--<div class="col-sm-6 col-12" >
              <mat-form-field [ngClass]="{rtl:appService.rtl()}" >
                <input matInput [matDatepicker]="pickerStartDate"
                  placeholder="{{ 'CHOOSE' | translate}}&nbsp;{{ 'START_DATE' | translate}}" formControlName="startDate"
                  [required]="!formGroup.controls['period'].value">
                <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerStartDate></mat-datepicker>
                <mat-error *ngIf="!formGroup.controls['startDate'].valid && formGroup.controls['startDate'].touched">
                  {{ 'FIELD_REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
            </div>-->
           <div class="col-sm-6 col-12">
              <ion-item >
                <ion-datetime #datePicker mode="ios" class="font-size-text " [ngClass]="{rtl:appService.rtl()}"
                  [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()"
                  formControlName="startDate" placeholder="{{ 'CHOOSE' | translate}}&nbsp;{{ 'START_DATE' | translate}}"
                  [monthShortNames]="appService.monthShort()" [doneText]="'SAVE' |translate"
                  [cancelText]="'CANCEL' |translate" [required]="!formGroup.controls['period'].value" [max]="maxDate"
                  (ionChange)="fixArabeFormat()">
                </ion-datetime>

                <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar ' : 'date '"
                  src="/assets/images/calendar4.svg" />


              </ion-item>
              <span slot="helper" class="error-msg"
                *ngIf="!formGroup.controls['startDate'].valid && formGroup.controls['startDate'].touched"> {{
                'FIELD_REQUIRED' | translate }}</span>

            </div>
           <!--<div class="col-sm-6 col-12" >
              <mat-form-field [ngClass]="{rtl:appService.rtl()}">
                <input matInput [matDatepicker]="pickerEndtDate"
                  placeholder="{{ 'CHOOSE' | translate}}&nbsp;{{ 'END_DATE' | translate}}" formControlName="endDate"
                  [required]="!formGroup.controls['period'].value">
                <mat-datepicker-toggle matSuffix [for]="pickerEndtDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerEndtDate></mat-datepicker>
                <mat-error *ngIf="!formGroup.controls['endDate'].valid && formGroup.controls['endDate'].touched">
                  {{ 'FIELD_REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
            </div>-->

            <div class="col-sm-6 col-12">
              <ion-item >
                <ion-datetime #datePicker mode="ios" class="font-size-text " (ionChange)="fixArabeFormat();"
                  [ngClass]="{rtl:appService.rtl()}" [pickerFormat]="appService.verifFormatDate()" 
                  formControlName="endDate" placeholder="{{ 'CHOOSE' | translate}}&nbsp;{{ 'END_DATE' | translate}}"
                  [monthShortNames]="appService.monthShort()" [doneText]="'SAVE' |translate"
                  [cancelText]="'CANCEL' |translate" [required]="!formGroup.controls['period'].value"
                  [displayFormat]="formatArabe" [max]="maxDate" [min]="formGroup.controls['startDate'].value">
                </ion-datetime>
                <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar ' : 'date '"
                  src="/assets/images/calendar4.svg" />
              </ion-item>
              <span slot="helper" class="error-msg"
                *ngIf="!formGroup.controls['endDate'].valid && formGroup.controls['endDate'].touched"> {{
                'FIELD_REQUIRED' | translate }}</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="ml-auto">
              <div class="col-12">

                <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="primary" type="button"
                  *ngIf="getExportInfo" style="margin-right:10px" (click)="exportCsv()" [disabled]="!formGroup.valid">{{
                  'EXPORT_DOWNLOAD' | translate}}</button>
                <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="primary" type="submit"
                  *ngIf="getGraphs || getRuminationUi || getExportTempGraph" [disabled]="!formGroup.valid">{{
                  'SHOW_GRAPH' |
                  translate}}</button>
              </div>
            </div>
          </div>
        </form>
      </mat-card>
    </div>

  </div>
  <app-graph #graph [formGroup]="formGroup" [tempGraph]="tempGraph"></app-graph>
  <app-rumination-ui #ruminationUiChart [formGroup]="formGroup"></app-rumination-ui>
</div>