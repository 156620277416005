import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { FlexLayoutModule } from '@angular/flex-layout';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { MaterialModule } from 'projects/public/src/app/material/material.module';
import { RapportDialogComponent } from './rapport-dialog.component';





@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        // FlexLayoutModule,
        MoomeCommonModule,
    ],
    declarations: [
        RapportDialogComponent,
    ],
    exports: [
        RapportDialogComponent,
    ]
})
export class RapportDialogModule { }   