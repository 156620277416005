<app-section-bar [title]="name" (goBack)="back()" [color]="color"></app-section-bar>
<div class="row px-4">
   <a *ngIf="showSettings">
        <app-common-setting *ngFor="let element of list" class="col-12 mt-4 text-center" [element]="element"
            (routerUrl)="pathUrl(element)" [editAuthorized]="authority"[color]="color" >
        </app-common-setting>
    </a>
    <a>
        <app-common-setting *ngFor="let element of formAdditional" class="col-12 mt-4 text-center" [element]="element"
            (click)="deleteFarmer()" [deleteAuthorized]="authorityFarmers"[color]="color">

        </app-common-setting>
    </a>
</div>