import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { CustomRouter } from '../../services/custom-router';
import { ActivatedRoute } from '@angular/router';
import { Authorities } from 'projects/shared/tools/constants';

@Component({
  selector: 'app-milk-collection-center-card',
  templateUrl: './milk-collection-center-card.component.html',
  styleUrls: ['./milk-collection-center-card.component.scss']
})
export class MilkCollectionCenterCardComponent implements OnInit {
  @Input() milkCollectionCenter;
  currentRoute: any;
  supervisorId: any;
  authorityAlerts = Authorities.ALERTS

  constructor(public appService: AppService, public codeTranslate: CodeTranslatePipe,
    private router: CustomRouter, private route: ActivatedRoute) { }

  ngOnInit() {
    this.currentRoute = this.router.url;
    this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
  }

  openTanks(id) {
    if (this.currentRoute.startsWith('/supervisors')) {
      this.router.navigateByUrl("supervisors/" + this.supervisorId + "/mcc/" + id + "/tanks")
    }
    else
      this.router.navigateByUrl("mcc/" + id + "/tanks")
  }



  openAlerts(id) {
 if (this.currentRoute.startsWith('/supervisors'))
 this.router.navigateByUrl( "supervisors/" + this.supervisorId +"/mcc/" + id +  "/alerts")

 else
 this.router.navigateByUrl( "/mcc/" + id +  "/alerts")

}
}
