import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { Feedback } from 'projects/shared/resources/Feedback';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { CustomerFeedbackService } from 'projects/shared/services/customer-feedback.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { PATTERN_FEEDBACK_FRENCH, PATTERN_FEEDBACK_ARABIC, Alert, PATTERN_FEEDBACK_ENGLISH } from 'projects/shared/tools/constants';
import { take } from 'rxjs';
import { CustomRouter } from '../services/custom-router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  title: any;
  language: any;
  isAr: Boolean = false;
  isFr: Boolean = false;
  formGroup: UntypedFormGroup;
  numberOfFeedbacksPerDay: any;
  maxFeedbaks = 3;
  maxSize = 300;
  disabled: Boolean = false;
  like: any
  maxChar = 300
  color='color-alert'
  constructor(private feedbackService: CustomerFeedbackService, public alertsService: AlertsService, private formBuilder: UntypedFormBuilder, public tokenManagementService: TokenManagementService, public router: CustomRouter, public translate: TranslateService, public codeTranslate: CodeTranslatePipe, public route: ActivatedRoute,
    public appService: AppService, private _ngZone: NgZone) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as { like: any };
    if (state)
      this.like = state.like;

  }
  @ViewChild('autosize') autosize: CdkTextareaAutosize;


  ngOnInit(): void {
    this.title = this.translate.instant("FEEDBACK");
    this.language = this.tokenManagementService.getTranslateLanguage();
    if (this.language == "ar")
      this.isAr = true;
    else if (this.language == "fr")
      this.isFr = true;

    this.checkNumberOfFeedbacks();
    this.createForm();

  }

  triggerResize() {
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'message': ['', [this.isAr ? Validators.pattern(PATTERN_FEEDBACK_ARABIC) : (this.isFr ? Validators.pattern(PATTERN_FEEDBACK_FRENCH) : Validators.pattern(PATTERN_FEEDBACK_ENGLISH)), Validators.required]],
    });
  }

  back() {
    this.router.back('')
  }

  checkNumberOfFeedbacks() {
    this.feedbackService.numberOfFeedbackPerDay().subscribe(res => {
      this.numberOfFeedbacksPerDay = res;
      if (this.numberOfFeedbacksPerDay >= this.maxFeedbaks) {
        this.disabled = true;
        this.appService.error('FEEDBACK_LIMIT');
      }
    })
  }

  verifLengthMessage(msg) {
    if (!this.disabled) {
      if ((msg.controls['message'].value).length == this.maxSize)
        this.appService.error(this.translate.instant('MAX_LENGTH_MESSAGE') + " " + this.maxSize.toString() + " " + this.translate.instant('CARACTERE'))
    }
  }

  save() {

    if (this.formGroup.controls['message'].hasError('pattern'))
      this.appService.error('PATTERN_FEEDBACK_CHAR');

    else if (this.numberOfFeedbacksPerDay >= 3) {
      this.appService.error('FEEDBACK_LIMIT');
    }
    else if (this.formGroup.controls['message'].hasError('required'))
      this.appService.error('MESSAGE_REQUIRED');

    else {
      let msg: string;
      this.appService.showLoading();
      let feedback: Feedback = this.formGroup.value;
      if (this.like) {
        feedback.customerLikeId = this.like;
      }
      this.feedbackService.saveFeedback(feedback).subscribe(
        data => {
          this.appService.hideLoading();
          msg = this.translate.instant('THANKS');
          this.createForm();
          this.checkNumberOfFeedbacks();
          this.alertsService.open(msg);
        }, error => {
          this.appService.hideLoading();
          msg = this.translate.instant(error.error.code);
          let alert = this.translate.instant(Alert.OK)
          this.alertsService.open(msg, alert);
        }
      )
    }
  }
}
