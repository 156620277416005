<div class="style-bar  w-100" [ngStyle]="{'background-color':element.color }" >
    <div class="row  h-100 ">
        <div class="row margin h-100 ">
            <div class="col center-element" (click)="back(element.router)">
                <img src={{element.arrowIcon}} />
            </div>
            <div class="col center-element">
                <div class="row d-block">
                    <div class="center-element"> <img src={{element.icon}} />
                    </div>
                    <div class="col text-center " [ngStyle]="{'color':element.fontColor }"> <span class="text-style"
                            > {{ element.title | translate}} </span></div>
                </div>
            </div>
            <div class="col center-element">
            </div>
        </div>
    </div>
</div>