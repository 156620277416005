

const rootUrl = "https://kbu7t6zekg8c8wrwvot3fbgrd.moome.io"
export const environment = {
  rootUrl: rootUrl,
  apiPath: rootUrl + "/api",
  production: true,
  serverTokenPath: rootUrl + "/",
  authorization: "Basic dHJ1c3RlZDpzZWNyZXQ=",
  webSocketPath: rootUrl + "/moome-ws",
  application: "public",
  firebase: {
    apiKey: "AIzaSyDowlqaf0gy-ppu3s5fEvwrYI74nMBpW9U",
    authDomain: "moomeweb.firebaseapp.com",
    projectId: "moomeweb",
    storageBucket: "moomeweb.appspot.com",
    messagingSenderId: "663838982811",
    appId: "1:663838982811:web:0bd5dbf30124285a7bbc3e",
    measurementId: "G-D33659SJS4",
    vapidKey: "BJnOU6wvPgSNjlkoxhdJmEgcDOh7UgI1kTI6bZube3iIdhzH54LCVhUSQeNbtrsl8_CtzqyoFRp8oup0VJ63WwI"
  }
};