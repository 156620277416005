import { Capacitor, CapacitorHttp } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { Filesystem, Directory, Encoding, FilesystemEncoding } from '@capacitor/filesystem';


export class CapacitorUtils {
  
  
  static readonly isApp = Capacitor.getPlatform() !== 'web';

  static async writeAndOpenFile(data: Blob, fileName: string) {
    try {
        let fileReader = new FileReader();
        fileReader.onloadend = async function() {
            try {
                if ((Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android')) {
                    let filePath = '';
                    if (Capacitor.getPlatform() === 'ios') {
                        filePath = `${Directory.Documents}/${fileName}`;
                    } else if (Capacitor.getPlatform() === 'android') {
                      filePath = `Download/${fileName}`;
                    }

                    if (!Filesystem) {
                        console.error('Filesystem API not available');
                        return;
                    }
                    
                    let textData = new TextDecoder().decode(new Uint8Array(fileReader.result as ArrayBuffer));
                    await Filesystem.writeFile({
                        path: filePath,
                        data: textData,
                        directory: Directory.External,
                        recursive: true,
                        encoding: Encoding.UTF8,
                    });
                    console.log('File written successfully.');
                } else {
                    console.error('Platform not supported');
                    return;
                }
            } catch (e) {
                console.error('Error writing file:', e);
            }
        };
        fileReader.readAsArrayBuffer(data);
    } catch (error) {
        console.error('Error reading file:', error);
    }
   
}


   
  static async setName(name, value) {
    await Preferences.set({
      key: name,
      value: value,
    });
    console.log(`setName ${value}`);
  };

  static async checkName() {
    const { value } = await Preferences.get({ key: 'name' });
    console.log(`checkName ${value}!`);
  };

  static async removeName() {
    await Preferences.remove({ key: 'name' });
  };
 
}
