<div class="style-bar w-100" ngxHideOnScroll [scrollingElementSelector]="'body'" [propertyUsedToHide]="'top'"
	[valueWhenHidden]="'0px'" [valueWhenShown]="'90px'">
	<div class="row h-100 ">
		<div class="row h-100 ">
			<div class="col-4 center-element" (click)="back()">
				<img src="/assets/images/left-arrow-1.svg" />
			</div>
			<div class="col-4 center-element">
				<div class="row d-block">
					<div class="col text-center"> <img src="/assets/images/calendar-week-solid-1.svg" />
					</div>
					<div class="col text-center ps-0 pe-0 pt-1"> <span class="text-style"
							[ngClass]="{rtl:appService.rtl()}">{{ 'WEEK_PLAN' | translate }}</span></div>
				</div>
			</div>

		</div>
	</div>

</div>
<div *ngIf="weeklyEmpty=='notEmpty' ">

	<div style="padding-top:150px" class="mat-elevation-z88" *ngIf="!role ">
		<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

			<ng-container matColumnDef="cow">
				<th mat-header-cell *matHeaderCellDef> {{ 'UID' | translate}} / {{ 'NAME' | translate}}</th>
				<td mat-cell *matCellDef="let element" class="text-center"> {{appService.valueUid ==
					nationalIdentification?
					(element.uid? element.uid : element.name) : (appService.valueUid == nameLocalCode? (element.name?
					element.name : element.uid) : (element.uid? element.uid : element.name))}}</td>
			</ng-container>

			<ng-container matColumnDef="event">
				<th mat-header-cell *matHeaderCellDef> {{ 'EVENT'|translate }}</th>
				<td mat-cell *matCellDef="let element" class="text-center">
					<div *ngIf="element.alert.type != 'AI'">
						{{element.alert | codeTranslate}}
					</div>
					<div *ngIf="element.alert.type == 'AI'">
						{{element.alert.message | translate:{'label': element.alert.label.substring(2) |
						translate } }} </div>

				</td>
			</ng-container>

			<ng-container matColumnDef="date">
				<th mat-header-cell *matHeaderCellDef>
					{{ 'DATE_TITLE' |translate }}</th>
				<td mat-cell *matCellDef="let element" class="text-center">{{element.date | date: 'yyyy/MM/dd'}}
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</div>



<div style="padding-top:150px; padding-left: 15px;padding-right: 15px;" class="containerTab" *ngIf="role"  >
	<mat-accordion displayMode="flat" multi>
		<mat-expansion-panel *ngFor="let key of keys">
			<mat-expansion-panel-header class="mat-row">
				<mat-panel-title>
					<span>{{key}}</span>
				</mat-panel-title>
			</mat-expansion-panel-header>

			<table mat-table [dataSource]="dataSource[key]" matSort class="mat-elevation-z8">

				<ng-container matColumnDef="cow">
					<th mat-header-cell *matHeaderCellDef> {{ 'NUM_COW'| translate }}</th>
					<td mat-cell *matCellDef="let element" class="text-center"> {{appService.valueUid ==
						nationalIdentification?
						(element.uid? element.uid : element.name) : (appService.valueUid == nameLocalCode?
						(element.name?
						element.name : element.uid) : (element.uid? element.uid : element.name))}}</td>
				</ng-container>

				<ng-container matColumnDef="event">
					<th mat-header-cell *matHeaderCellDef> {{ 'EVENT'|translate }}</th>
					<td mat-cell *matCellDef="let element" class="text-center">
						<div *ngIf="element.alert.alertType.type != 'AI'">
							{{element.alert | codeTranslate}}
						</div>
						<div *ngIf="element.alert.alertType.type == 'AI'">
							{{element.alert.message | translate:{'label':
							element.alert.timelineDetail.label.substring(2) |
							translate } }}
						</div>

					</td>
				</ng-container>

				<ng-container matColumnDef="date">
					<th mat-header-cell *matHeaderCellDef>
						{{ 'DATE_TITLE' |translate }}</th>
					<td mat-cell *matCellDef="let element" class="text-center">{{element.alert.date | date: 'yyyy/MM/dd
						HH:mm'}}
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			</table>

		</mat-expansion-panel>
	</mat-accordion>
</div>
</div>

<div *ngIf="weeklyEmpty=='empty'"  class="h-100 center-element mt-2 pt-5 mb-4">
    <app-no-animals [text]="'NO_WEEKLY_PLAN'"></app-no-animals>
</div>