import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { WeeklyPlanManagementComponent } from './weekly-plan-management.component';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { NoAnimalsModule } from '../animals/no-animals/no-animals.module';




@NgModule({
  declarations: [WeeklyPlanManagementComponent

  ],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    NgxHideOnScrollModule,
    MoomeCommonModule,
    NoAnimalsModule,
   
   
    
   
   
  ],
  exports: [
    WeeklyPlanManagementComponent
  ]
})
export class WeeklyPlanManagementModule { }
