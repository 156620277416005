import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'projects/shared/services/app.service';
import { FarmerMilkProductionService } from 'projects/shared/services/farmer-milk-production.service';
import { Authorities, SectionForm } from 'projects/shared/tools/constants';
import { CustomRouter } from '../services/custom-router';
import { FormService } from 'projects/shared/services/form.service';

@Component({
  selector: 'app-farmer-milk-production',
  templateUrl: './farmer-milk-production.component.html',
  styleUrls: ['./farmer-milk-production.component.scss']
})
export class FarmerMilkProductionComponent implements OnInit {

  forms: any={}
  idFormTemplate
  service
  name = "MILK_PRODUCTION"
  section = SectionForm.farmer_milk_production
  idFarmer;
  color='color-milk-prod'
 
  constructor(public farmerMilkProductionService: FarmerMilkProductionService,
    public appService: AppService, public route: ActivatedRoute,
    private router: CustomRouter, private formService: FormService) {
    const { idFormTemplate } = this.route.snapshot.params
    this.idFormTemplate = idFormTemplate;
    this.service = this.farmerMilkProductionService
  }

  ngOnInit(): void {
    this.idFarmer = this.appService.connectedCustomer.id
    let currentRoute = this.router.url;
    this.appService.getFormTemplateFarmerMilkProduction().subscribe(res => { })
    if(currentRoute.indexOf('key') == -1)
    this.getData()
  }

  getDataByKey(element) {
    
    this.farmerMilkProductionService.findByFarmerByKey(element.key).subscribe(res => {
      this.forms=this.appService.groupDataByYearAndMonth(this.appService.organizedData(res, this.appService.dataAddMilkProduction));
    }, error => {
      this.appService.error(error.error.error)
    });
  }
  getData() {
    this.farmerMilkProductionService.findByFarmer().subscribe(res => {
      this.forms=this.appService.groupDataByYearAndMonth(this.appService.organizedData(res, this.appService.dataAddMilkProduction));
   
      //  this.forms = this.appService.organizedData(res, this.appService.dataAddMilkProduction);
    }, error => {
      this.appService.error(error.error.error)
    });
  }

  back() {
    if (this.router.url.indexOf('search') == -1)
      this.router.back('')
    else
      this.router.navigateByUrl('/settings/' + this.idFormTemplate + '/' + Authorities.FARMER_MILK_PRODUCTION)
  }

  action(item) {
    this.router.navigateByUrl('/settings/' + this.idFormTemplate + '/' + Authorities.FARMER_MILK_PRODUCTION + '/save' + (item ? ("/" + (item.id)) : ""))
  }

}
