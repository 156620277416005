import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Address } from 'projects/shared/resources/Address';
import { Animal } from 'projects/shared/resources/Animal';
import { AnimalDto } from 'projects/shared/resources/AnimalDto';
import { AddressService } from 'projects/shared/services/address.service';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AnimalService } from 'projects/shared/services/animal.service';
import { AppService } from 'projects/shared/services/app.service';
import { BreedService } from 'projects/shared/services/breed.service';
import { GroupService } from 'projects/shared/services/group.service';
import { SpeciesService } from 'projects/shared/services/species.service';
import { Authorities, Alert, AnimalType, GroupCode, PATTERN_UID_MARC, UID2_ANIMALS_MAR, UID1_ANIMALS_MAR, MAX_LENGTH_NAME_COW, MIN_LENGTH_NAME_COW, PATTER_NAME_COW, MAX_UID1, MAX_UID2, FORMAT_MAX_MIN, Group } from 'projects/shared/tools/constants';
import * as uuid from 'uuid';
import * as moment from 'moment';
import { CustomRouter } from '../../services/custom-router';
import { Validator } from 'projects/shared/tools/validators';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { Customer } from 'projects/shared/resources/Customer';
import { CustomerService } from 'projects/shared/services/customer.service';
import { CustomerAlertService } from 'projects/shared/services/customer-alert.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Options } from 'projects/shared/services/moome-rest.service';
import { SubscriptionRequestService } from 'projects/shared/services/subscription-request.service';
import { TimelineService } from 'projects/shared/services/timeline.service';


@Component({
  selector: 'app-edit-animal',
  templateUrl: './edit-animal.component.html',
  styleUrls: ['./edit-animal.component.scss']
})
export class EditAnimalComponent implements OnInit {
  birthday: Date = new Date(1988, 1, 28);
  today = new Date();
  animalId: any
  years: any
  val = true
  months: any
  monthsTot: any
  //calvings: number = 0
  step: number = 1
  text = "CONFIRM"
  formGroup: UntypedFormGroup;
  selectedBreeds: any[];
  breeds: any[];
  animalType: any
  animal: Animal;
  days: any
  defaultGroupId: any;
  customerId: any
  addresses: any
  suffix: String
  resources: any
  mess = "COW_ID_ADD"
  productionCode: String
  dryCode: String
  uidLength: number
  date: String
  valUid1 = true
  valUid2 = true
  maxUid1 = MAX_UID1
  maxUid2 = MAX_UID2
  minLengthCowName = MIN_LENGTH_NAME_COW
  maxLengthCowName = MAX_LENGTH_NAME_COW
  patternCowName = PATTER_NAME_COW
  B = UID2_ANIMALS_MAR
  paternMar = PATTERN_UID_MARC
  uid1Length = UID1_ANIMALS_MAR
  code
  farmers: any[] = []
  maxDate: any
  valueEvent: boolean = false;
  authorityBreeds = Authorities.BREEDS
  authoritySpecies = Authorities.SPECIES
  fullname: String;
  farmer: Customer = null
  //  show: Boolean = false
  //month: any
  writeWeight: Boolean = false
  role: Boolean = false
  auth: string[] = [];
  // valueDate: boolean = false
  //  valueNoDate: boolean = false
  formatArabe: any
  showForm: boolean = false;
  currentRoute: any;
  supervisorId: any
  isPopUp: boolean = false;
  valueTypeUid: boolean = false;
  minDate: any
  calculDate: any
  lactNumber: any
  numberMonth: any
  timelines = []
  haveTimeline: boolean = false
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditAnimalComponent>, public customerAlertService: CustomerAlertService, public customerService: CustomerService, private supervisorService: SupervisorService, public tokenManagementService: TokenManagementService, private route: ActivatedRoute, private router: CustomRouter, public appService: AppService, private formBuilder: UntypedFormBuilder, public dialog: MatDialog,
    public animalService: AnimalService, public breedService: BreedService, public alertsService: AlertsService,
    public speciesService: SpeciesService, public addressService: AddressService, public groupService: GroupService,
    public translate: TranslateService, public timelineService: TimelineService) {
    this.appService.calvings = 0
    this.currentRoute = this.router.url
    if (this.currentRoute.indexOf('reports') != -1)
      this.isPopUp = true;
    else this.isPopUp = false
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1) {

      this.role = true
      this.customerId = this.route.snapshot.paramMap.get('idFarmer');
      if (!this.customerId && this.isPopUp)
        this.customerId = this.data.customerId;
      this.getFarmerById(this.customerId)

    }
    else {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
        this.customerId = this.route.snapshot.paramMap.get('idFarmer');
        this.getFarmerById(this.customerId)
      }
      else {
        this.role = false
        this.customerId = this.appService.connectedCustomer.id
        this.suffix = this.appService.connectedCustomer.addresses[0].country.uidIndex
        this.code = this.appService.connectedCustomer.addresses[0].country.code
        this.uidLength = this.appService.connectedCustomer.addresses[0].country.uidLength
        this.createForm();

      }
    }
    this.animalId = this.route.snapshot.paramMap.get('id');
    if (this.animalId) {
      this.mess = "COW_ID"
      setTimeout(() => {
        this.setAnimalDetails()
      }, 100);
    }

  }

  ngOnInit() {

    this.formatArabe = this.appService.verifFormatDate()
    this.maxDate = moment(new Date()).format(FORMAT_MAX_MIN);
    this.calculDate = new Date().setFullYear(new Date().getFullYear() - 16);
    this.minDate = moment(new Date(this.calculDate)).format(FORMAT_MAX_MIN);
    this.appService.verif = false
    this.defaultGroupId = this.appService.defaultGroupId
    this.animalId = this.route.snapshot.paramMap.get('id');
    if (!this.animalId && this.isPopUp)
      this.animalId = this.data.animal.id
    this.loadBreeds();
    this.loadAddresses()
    /* if (this.animalId) {
       this.mess = "COW_ID"
       this.setAnimalDetails()
     }*/
    this.onWindowResize()

  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.valueTypeUid = this.appService.onWindowResize()
  }
  getFarmerById(id) {
    let options: Options = {
      params: {
        projection: 'customers',
      }
    }
    this.customerService.get(id, options).subscribe(res => {
      this.farmer = res
      this.suffix = this.farmer.addresses[0].country.uidIndex
      this.code = this.farmer.addresses[0].country.code
      this.uidLength = this.farmer.addresses[0].country.uidLength
      this.createForm()
    })
  }

  /*addEvent(event) {
    
    if (event.detail.value) {

      if (this.tokenManagementService.getTranslateLanguage() == "fr") {
        if (!moment(event.detail.value, this.format , true).isValid()) {
          this.message('FORM_DATE');
          this.valueEvent = true
        }

      }
      else if (this.tokenManagementService.getTranslateLanguage() == "ar") {
        if (!moment(event.detail.value, this.format , true).isValid()) {
          this.message('FORM_DATE');
          this.valueEvent = true
        }

      }
      else if (this.tokenManagementService.getTranslateLanguage() == "en") {
        if (!moment(event.detail.value, this.format , true).isValid()) {
          this.message('FORM_DATE');
          this.valueEvent = true
        }

      }
      this.showLact()
    }
  }*/
  createForm() {

    this.showForm = true
    const currentYear = moment().year();
    this.formGroup = this.formBuilder.group({
      'uid': [null, [Validator.validUidPattern(this.alertsService, this.translate, this.uidLength), Validator.validUidLength(this.translate, this.uidLength, this.alertsService)]],
      'uid1': [null, [Validator.validUid1Pattern(this.alertsService, this.translate), Validator.validUid1Length(this.translate, this.maxUid1, this.alertsService)]],
      'uid2': [null, [Validator.validUid2Pattern(this.alertsService, this.translate), Validator.validUid2Length(this.translate, this.maxUid2, this.alertsService)]],
      'breed': [null, Validators.required],
      'weight': [null, [Validator.validWeight(this.alertsService, this.translate)]],
      'sex': [null, Validators.required],
      'lactNum': [null],
      'years': [null],
      'months': [null],
      'group': [null],
      'name': [null, [
        Validators.minLength(MIN_LENGTH_NAME_COW),
        Validators.pattern(PATTER_NAME_COW),
        Validator.validNameLength(this.translate, this.maxLengthCowName, this.alertsService)]],
      'birthDate': [null],

    });

  }
  back() {
    if (this.role)
      this.router.navigateByUrl('/farmers/' + this.customerId + '/animals/' + this.animal.id + '/settings')
    else {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + this.customerId + "/animals/" + this.animal.id + "/settings")
      }
      else { this.router.navigateByUrl('/animals/' + this.animal.id + '/settings') }
    }

  }




  backAdd() {
    if (this.role)
      this.router.navigateByUrl("farmers/" + this.customerId + "/animals/groups/" + this.defaultGroupId)
    else {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + this.customerId + "/animals/groups/" + this.defaultGroupId)
      }
      else { this.router.navigateByUrl("animals/groups/" + this.defaultGroupId) }
    }
  }

  /*  moins() {
      if (this.calvings != 0 && this.formGroup.controls['sex'].value != 'MALE')
        this.calvings = Math.floor(this.calvings - this.step)
      if (this.calvings == 0) {
        this.formGroup.controls['group'].setValue(null);
        this.formGroup.controls['group'].clearValidators();
        this.formGroup.controls['group'].updateValueAndValidity();
      }
    }
  
    plus() {
      if ((this.formGroup.controls['sex'].value != 'MALE') && (this.calvings < 12))
        this.calvings = Math.floor(this.calvings + this.step)
  
      if (this.calvings > 0)
        this.formGroup.controls['group'].setValidators([Validators.required]);
      this.formGroup.controls['group'].updateValueAndValidity();
  
    }*/

  loadAddresses() {
    this.addressService.getAddressesByCustId(this.customerId).subscribe(data => {
      this.addresses = data['resources'];
    });
  }

  loadBreeds() {
    this.speciesService.getByCode(AnimalType.COW).subscribe(res => {
      this.animalType = res;
      this.animalService.getBreeds(this.animalType.id).subscribe(res => {
        this.selectedBreeds = this.breeds = res['resources'];
      });
    })
  }
  search(value: string, label) {
    let filter = value.toLowerCase();
    return this.breeds.filter(option => option[label].toLowerCase().includes(filter));
  }

  onKey(value) {
    let label;
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      label = "labelAr";
    else if (this.tokenManagementService.getTranslateLanguage() == "ind")
      label = "labelInd";
    else
      label = "labelEn"
    this.selectedBreeds = this.search(value, label);
  }
  setAnimalDetails() {

    this.animalService.getById(this.animalId).subscribe(res => {
      this.animal = res;
      if (this.animal.uid) {
        if (this.code != 'MAR')
          this.formGroup?.controls['uid']?.setValue(this.animal.uid.substring(this.suffix.length));
        else if (this.code == 'MAR') {
          this.formGroup.controls['uid1'].setValue(this.animal.uid.substr(this.suffix.length, this.uid1Length));
          this.formGroup.controls['uid2'].setValue(this.animal.uid.substr(this.suffix.length + this.uid1Length + this.B.length));
        }
      }
      if (this.animal.breed)
        this.formGroup?.controls['breed']?.setValue(this.animal.breed.id);
      if (this.animal.sexe)
        this.formGroup?.controls['sex']?.setValue(this.animal.sexe);
      if (this.animal.name)
        this.formGroup?.controls['name']?.setValue(this.animal.name);

      if (this.animal.birthDate)
        this.formGroup?.controls['birthDate']?.setValue(moment(this.animal.birthDate).format(this.appService.verifFormatDate()));

      if ((this.animal.birthDate != null)) {

        let diff = moment().diff(moment(this.animal.birthDate), "days")
        if (diff >= 30) {
          let ageYear = (Math.floor(diff / 30.416 / 12));
          let ageMonth = Math.floor((Math.round(diff - (ageYear * 12 * 30.4167))) / 30);

          this.formGroup?.controls['years']?.setValue(ageYear);
          this.formGroup?.controls['months']?.setValue(ageMonth);
        }
      }
      if (this.animal.lactNum)
        this.appService.calvings = this.animal.lactNum;
      if (this.animal.weight)
        this.formGroup?.controls['weight']?.setValue(this.animal.weight);
      this.appService.showLact(this.formGroup, this.formatArabe);
      if (this.appService.show) {
        this.animal.customerAnimalGroups.forEach(cag => {
          if (cag.code == GroupCode.DRY_NotPregnant_CODE || cag.code == GroupCode.DRY_Pregnant_CODE)
            this.formGroup?.controls['group']?.setValue('dryCode')
          if (cag.code == GroupCode.PNOTINS_CODE || cag.code == GroupCode.PINS_CODE || cag.code == GroupCode.P_Negative_Pregnancy_CODE || cag.code == GroupCode.P_Pregnant_CODE)
            this.formGroup?.controls['group']?.setValue('productionCode')
        })
      }
    })
    this.verifTimeline(this.animalId)
  }

  /* calculValueBirth() {
 
     if ((this.formGroup.controls['years'].value == null) && (this.formGroup.controls['months'].value != null)) {
       this.formGroup.controls['years'].setValue(0)
       this.calculBirthDate()
     }
 
     if ((this.formGroup.controls['months'].value == null) && (this.formGroup.controls['years'].value != null)) {
       this.formGroup.controls['months'].setValue(0)
       this.calculBirthDate()
     }
   }*/
  verifTimeline(animalId) {
    this.timelineService.getTimelineByAnimal(animalId).subscribe((data: any) => {
      this.timelines = data
      if (data['resources']?.length > 0)
        this.haveTimeline = true;
      else
        this.haveTimeline = false;
      return this.haveTimeline;
    })

  }
  save() {

    this.appService.calculValueBirth(this.formGroup, this.formatArabe);
    let body: AnimalDto = new AnimalDto();
    if (this.animalId) {
      let diff = moment().diff(moment(this.formGroup.controls['birthDate'].value), "days")
      this.numberMonth = (Math.floor(diff / 30.4167));
      this.lactNumber = this.animal.lactNum
      body.id = this.animal.id;
      body.uuid = this.animal.uuid;
      body.weight = this.animal.weight
      body.gatewayId = [];
      if (this.animal.device) {
        this.animal.device.gateways.forEach(gateway => {
          body.gatewayId.push(gateway.id)
        });
        body.deviceId = this.animal.device.id;
        body.installDate = this.animal.device.lastHistory.startDate
      }
    }
    else
      body.uuid = uuid.v4();
    let verifUid = true
    let verifUidMaroc = true
    if (this.formGroup.controls['uid'].value && this.code != "MAR") {
      body.uid = this.suffix + (document.getElementById("uid") as HTMLFormElement).value;
      if ((document.getElementById("uid") as HTMLFormElement).value.toString().length != this.uidLength)
        verifUid = false
    }
    else if ((this.formGroup.controls['uid1'].value || this.formGroup.controls['uid2'].value) && this.code == "MAR") {
      body.uid = this.suffix + (document.getElementById("uid1") as HTMLFormElement).value + this.B + (document.getElementById("uid2") as HTMLFormElement).value;
      var re = new RegExp(this.paternMar);
      verifUidMaroc = re.test(body.uid)
    }
    else
      delete body.uid;
    body.breedId = this.formGroup.controls['breed'].value;
    body.addressId = (this.addresses[0] as Address).id;
    body.sexe = this.formGroup.controls['sex'].value;
    body.name = this.formGroup.controls['name'].value;

    this.appService.showLact(this.formGroup, this.formatArabe);
    if ((this.formGroup.controls['sex'].value != 'MALE'))
      body.lactNum = this.appService.calvings;
    body.birthDate = moment(this.formGroup.controls['birthDate'].value).toDate()
    this.appService.showLact(this.formGroup, this.formatArabe);
    /*if (this.formGroup.controls['years'].value || this.formGroup.controls['months'].value) {
      this.days = Math.floor((this.formGroup.controls['years'].value ? this.formGroup.controls['years'].value : 0) * 365 + (this.formGroup.controls['months'].value ? this.formGroup.controls['months'].value : 0) * 30)
      body.birthDate = moment().subtract(this.days, 'days');
    }*/

    if (this.formGroup.controls['group'].value == 'dryCode')
      body.group = GroupCode.DRY_NotPregnant_CODE;
    if (this.formGroup.controls['group'].value == 'productionCode')
      body.group = GroupCode.PNOTINS_CODE;

    if (this.formGroup.invalid) {
      this.formGroup.setErrors({ 'invalid': true });
      if (this.formGroup.controls['sex'].hasError('required'))
        this.appService.error('SEX_REQUIRED');

      else if (this.formGroup.controls['breed'].hasError('required'))
        this.appService.error('BREED_REQUIRED');

      else if (this.formGroup.controls['name'].hasError('minlength'))
        this.appService.error(this.translate.instant('NAME_COW_MIN') + " " + this.minLengthCowName.toString() + " " + this.translate.instant('CARACTERE') + " ")

      else if (this.formGroup.controls['name'].hasError('pattern'))
        this.appService.error('COW_PATTERN_NAME');


      else if ((document.getElementById("uid") as HTMLFormElement).value.toString().length > 0 && (document.getElementById("uid") as HTMLFormElement).value.toString().length < this.uidLength)
        this.appService.error(this.translate.instant('UID_LENGTH') + " ")

      else if ((this.valueEvent == true) || (this.formGroup.controls['birthDate'].value > this.maxDate))
        this.appService.error('FORM_DATE');

      else if (((document.getElementById("uid") as HTMLFormElement).value.toString() == "" && (document.getElementById("name") as HTMLFormElement).value.toString() == "") || ((document.getElementById("name") as HTMLFormElement).value.toString() == "" && !this.formGroup.controls['uid'].value) || (!this.formGroup.controls['name'].value && (document.getElementById("uid") as HTMLFormElement).value.toString() == "")) {
        this.appService.error('UID_NAME');
      }
      else if (this.formGroup.controls['group'].hasError('required'))
        this.appService.error('GROUP_REQUIRED');
      /*else if (this.formGroup.controls['weight'].hasError('pattern'))
        this.message('COW_PATTERN_WEIGHT');
      */
    }
    else if (!this.formGroup.controls['group'].value && this.appService.calvings > 0) {
      this.appService.error('GROUP_REQUIRED');
      this.formGroup.setErrors({ 'invalid': true });
    }

    else if (!this.formGroup.controls['uid'].value && !this.formGroup.controls['name'].value && this.code != "MAR") {
      this.appService.error('UID_NAME');
      this.formGroup.setErrors({ 'invalid': true });
    }
    else if (!this.formGroup.controls['uid1'].value && !this.formGroup.controls['uid2'].value && !this.formGroup.controls['name'].value && this.code == "MAR") {
      this.appService.error('UID_NAME');
      this.formGroup.setErrors({ 'invalid': true });
    }

    else if (!verifUidMaroc) {
      this.appService.error('UID_LENGTH');
    }
    else if (((this.numberMonth < 12)) && ((this.lactNumber > 0)||(Group.haveTimeline(this.formGroup.controls['group'].value)))) {
      this.appService.error('CHECK_AGE_COW');
    }

    else {

      this.appService.showLoading();
      this.animalService.saveAnimal(body).subscribe((data: any) => {
        this.appService.hideLoading();
        let message = this.translate.instant('SAVE_SUCESS');
        this.alertsService.open(message);
        if (!this.animalId) {
          this.appService.calvings = 0
          if ((this.formGroup.controls['breed'].valid) && this.formGroup.controls['sex'].valid) {
            this.text = "NEXT"
            this.createForm()
          }
        }
        else this.router.back('')

        //googleAnalytics
        this.appService.googleAnalyticsAllPages('saveAnimal', body.uid, body.name)
        setTimeout(() => {
          this.customerAlertService.sendNotif(data['id']).subscribe()
        }, 3000);
      }, error => {
        this.appService.messageMaxCow(error.error.code);
      });
    }
  }



  /*verifAge() {

    if ((this.formGroup.controls['years'].value == 16) || (this.formGroup.controls['months'].value < 0)) {
      this.formGroup.controls['months'].setValue(0)
    }
    else if (this.formGroup.controls['years'].value < 0) {
      this.formGroup.controls['years'].setValue(0)
    }
    else if (this.formGroup.controls['years'].value > 16) {
      this.formGroup.controls['years'].setValue(16)
    }
    else if (this.formGroup.controls['months'].value > 12) {
      this.formGroup.controls['months'].setValue(12)
    }
    this.calculBirthDate()

  }
*/

  /* verifPoids() {
     if (this.formGroup.controls['weight'].value > 5000) {
       this.formGroup.controls['weight'].setValue(5000)
     }
     else if (this.formGroup.controls['weight'].value < 0) {
       this.formGroup.controls['weight'].setValue(0)
     }
   }*/

  /*calculBirthDate() {

    setTimeout(() => {
      this.calculValueBirth()
    }, 3500);
    var cntOfYears = this.formGroup.controls['years'].value;
    var cntOfMonths = this.formGroup.controls['months'].value;
    var cntOfDays = "0";
    var birthDate = moment().subtract(cntOfYears, 'years').subtract(cntOfMonths, 'months').subtract(cntOfDays, 'days');
    if (cntOfMonths != null && cntOfYears != null) {
      this.formGroup.controls['birthDate'].setValue(birthDate.format(FORMAT_MAX_MIN))
    }
    this.formGroup.controls['years'].setValue(cntOfYears)
    this.formGroup.controls['months'].setValue(cntOfMonths)
    this.showLact()
    //this.addWeight()
  }
*/

  /*verifLength() {
    if ((document.getElementById("uid") as HTMLFormElement).value.toString().length >= this.uidLength) {
      this.val = false;
      this.formGroup.setErrors({ 'invalid': true });
      let msg = this.translate.instant('NUM_COW') + " " + this.translate.instant('DONT_DEPASSE') + " " + this.uidLength.toString();
      let alert = this.translate.instant(Alert.OK)
      this.formGroup.controls['uid'].setValue((document.getElementById("uid") as HTMLFormElement).value.toString().substr(0, this.uidLength));
      this.val = true;
      return this.alertsService.open(msg, alert);
    }
  }

  verifLengthUid1() {
    if ((document.getElementById("uid1") as HTMLFormElement).value.toString().length > this.maxUid1) {
      this.valUid1 = false;
      this.formGroup.setErrors({ 'invalid': true });
      let msg = this.translate.instant('CASE_BOX') + " " + this.translate.instant('DONT_DEPASSE') + " " + this.maxUid1;
      let alert = this.translate.instant(Alert.OK)
      this.formGroup.controls['uid1'].setValue((document.getElementById("uid1") as HTMLFormElement).value.substr(0, this.maxUid1));
      this.valUid1 = true;
      return this.alertsService.open(msg, alert);
    }
  }

  verifLengthUid2() {
    if ((document.getElementById("uid2") as HTMLFormElement).value.toString().length > this.maxUid2) {
      this.valUid2 = false;
      this.formGroup.setErrors({ 'invalid': true });
      let msg = this.translate.instant('CASE_BOX') + " " + this.translate.instant('DONT_DEPASSE') + " " + this.maxUid2;
      let alert = this.translate.instant(Alert.OK)
      this.formGroup.controls['uid2'].setValue((document.getElementById("uid2") as HTMLFormElement).value.substr(0, this.maxUid2));
      this.valUid2 = true;
      return this.alertsService.open(msg, alert);
    }
  }
*/
  /*calculAge(event) {

    setTimeout(() => {
      this.calculValueBirth()
    }, 3500);
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD/MMM/YYYY"
    let diff = moment().diff(moment(event.detail.value), "days")
    if (diff >= 30) {
      let ageYear = (Math.floor(diff / 30.4167 / 12));
      let ageMonth = Math.floor((Math.round(diff - (ageYear * 12 * 30.4167))) / 30);
      this.formGroup.controls['years'].setValue(ageYear);
      this.formGroup.controls['months'].setValue(ageMonth);
      this.showLact()
      //this.addWeight()
    }
  }*/
  /*  verifDate() {
      if (((this.formGroup.controls['months'].value > 2) && (this.formGroup.controls['years'].value >= 1)) || (this.formGroup.controls['years'].value > 1))
        this.valueDate = true
      else
        this.valueDate = false
    }
    verifNoDate() {
      if ((this.formGroup.controls['months'].value == null) && (this.formGroup.controls['years'].value == null) && (this.formGroup.controls['birthDate'].value == null))
        this.valueNoDate = true
      else
        this.valueNoDate = false
    }
  
    showLact() {
      if (this.tokenManagementService.getTranslateLanguage() == "ar")
        this.formatArabe = "DD/MMM/YYYY"
      let diff = moment().diff(moment(this.formGroup.controls['birthDate'].value), "days")
      this.month = (Math.floor(diff / 30));
      this.verifDate()
      this.verifNoDate()
      if ((this.month > 14) || this.valueDate || this.valueNoDate)
        this.show = true
      else
        this.show = false
      return this.show
    }
  */

  closePopUp() {
    this.dialogRef.close();
  }


  /*
    addWeight(){
      if(this.formGroup.controls['birthDate'].value==null)
      this.writeWeight=true
      else 
      this.writeWeight=false 
      this.isDisabled(this.writeWeight)
    }
     isDisabled(value: Boolean) {
      
      if(value) {
        this.formGroup.controls['weight'].disable();
      } else {
        this.formGroup.controls['weight'].enable();
       }
     }*/

}
