import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { FlexLayoutModule } from '@angular/flex-layout';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';

import { FarmerAnimalTableComponent } from './farmer-animal-table.component';
import { MaterialModule } from 'projects/public/src/app/material/material.module';
import { SearchModule } from '../search/search.module';
import { ShowNotesModule } from '../show-notes/show-notes.module';
import { ConfirmComponent } from '../confirm/confirm.component';
import { ConfirmModule } from '../confirm/confirm.module';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { EditNoteComponent } from 'projects/publicV2/src/app/notes/edit-note/edit-note.component';
import { EditAnimalComponent } from 'projects/publicV2/src/app/animals/edit-animal/edit-animal.component';
import { ActionLivestockSummaryModule } from '../action-livestock-summary/action-livestock-summary.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        //FlexLayoutModule,
        MoomeCommonModule,
        SearchModule,
        ShowNotesModule,
        ConfirmModule,
        NgxHideOnScrollModule,
        ActionLivestockSummaryModule
    ],
    declarations: [
        FarmerAnimalTableComponent,
    ],
    exports: [
        FarmerAnimalTableComponent
    ]
})
export class FarmerAnimalTableModule { }   