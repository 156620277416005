<div *ngIf="thi && thi.temp && thi.relativeHumidity" class="card-size" [ngClass]="{rtl:appService.rtl()}">
    <div class="back-case-color" [ngClass]="{rtl:appService.rtl()}">
        <div class="d-flex align-items-center ">
            <div class=" flex-grow-1 ms-4 ps-0 mt-3 mb-3">
                <div class="info-style-UID me-4 aligne"
                    [ngClass]="appService.rtl()? 'text-size-title-ar' : 'text-size'">
                    <span class="">{{'THI_TAG'|translate}}</span> <span
                        [ngClass]="appService.rtl()? 'nbre-size-ar' : 'nbre-size'" class=" pt-2">{{thi.label}}</span>
                </div>
            </div>
            <div class="d-flex flex-nowrap ms-2 mt-3 mb-3 me-3 text-aligne" >
                    <div *ngIf="signal && thi">
                        <img src="{{imgBattery}}" class="imb-bat me-1 ms-1">
                        <img src="/assets/images/Tracé-836.svg" />
                    </div>
                    <div *ngIf="!signal && thi">
                        <img src="{{imgBattery}}" class="imb-bat me-1 ms-1">
                        <img src="/assets/images/Tracé-848.svg" />
                    </div>
                    <div *ngIf="!signal && !thi">
                        <img src="/assets/images/Tracé-750.svg" class="imb-bat me-1 ms-1" />
                        <img src="/assets/images/Tracé-847.svg" />
                    </div>
                
            </div>
        </div>
    </div>

    <div class="row px-3 mt-3 gx-5">
        <div class="col-3">
            <div class="row" *ngIf="thi.temp">
                <div class="col-12 mb-3">
                    <div class="min-card width-card blue-color">
                        <div class="text-center pt-2 pe-2 ps-2">
                            <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span
                                    [ngClass]="appService.rtl()? 'exp-unit-ar' : 'exp-unit-fr'">°C</span></div>
                        </div>
                        <div *ngIf="!thi.temp" class="text-center font-temp aligne"><span>--</span>
                        </div>
                        <div *ngIf="thi.temp" class=" text-center  aligne" >
                            <span class="entier" *ngIf="thi.temp<0 && !appService.rtl()">{{thiSign}}</span>
                            <span class="entier"
                                *ngIf="thi.temp<0">{{((thi.temp.toString()).split("-")[1]).split(".")[0]}}</span>
                            <span class="entier" *ngIf="thi.temp>=0">{{(thi.temp.toString()).split(".")[0]}}</span>

                            <span *ngIf="thi.temp.toString().split('.')[1]"
                                class="decimal pt-2">.{{thi.temp.toString().split(".")[1]}}</span>
                            <span class="entier" *ngIf="thi.temp<0 && appService.rtl()">{{thiSign}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-3">
            <div class="row" *ngIf="thi.relativeHumidity">
                <div class="col-12 mb-3">
                    <div class="min-card width-card blue-color">
                        <div class="text-center pt-2 pe-2 ps-2">
                            <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span
                                    [ngClass]="appService.rtl()? 'exp-unit-pourcen-ar' : 'exp-unit-pourcen-fr'">%</span>
                            </div>
                        </div>
                        <div *ngIf="!thi.relativeHumidity" class="text-center font-temp aligne"><span>--</span>
                        </div>
                        <div *ngIf="thi.relativeHumidity" class=" text-center  aligne">
                            <span class="entier"
                                *ngIf="thi.relativeHumidity>=0">{{(thi.relativeHumidity.toString()).split(".")[0]}}</span>
                            <span *ngIf="thi.relativeHumidity.toString().split('.')[1]"
                                class="decimal pt-2">.{{thi.relativeHumidity.toString().split(".")[1]}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="row" *ngIf="thi.temp && thi.relativeHumidity">
                <div class="col-12 mb-3">
                    <div class="min-card width-card blue-color">
                        <div class="text-center pt-2 pe-2 ps-2">
                            <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span
                                    [ngClass]="appService.rtl()? 'exp-unit-thi-ar' : 'exp-unit-fr'">THI</span>
                            </div>
                        </div>
                        <div *ngIf="!thi.temp && !thi.relativeHumidity" class="text-center font-temp aligne">
                            <span>--</span>
                        </div>
                        <div *ngIf="thi.temp && thi.relativeHumidity" class=" text-center  aligne" [ngClass]="appService.changeColorThi(thi.temp,thi.relativeHumidity)">
                            <span class="entier">{{(((1.8 * thi.temp + 32) - ((0.55 - 0.0055 * thi.relativeHumidity) *
                                (1.8 * thi.temp - 26))).toString()).split(".")[0]}}</span>
                            <span class="decimal pt-2">.{{((1.8 * thi.temp + 32) - ((0.55 - 0.0055 *
                                thi.relativeHumidity) * (1.8 * thi.temp - 26)) | number:'2.1-2'
                                ).toString().split(".")[1]}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3 center-element">
            <img src="/assets/images/gear-option.svg" />
        </div>
    </div>

</div>
