import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
	
	key: any
	serchTimeout: any;
	@Output()
	searchWithKey: EventEmitter<any> = new EventEmitter();
	constructor(private translate: TranslateService) { }

	ngOnInit() {
	}

	
	search(key) {
		if (this.serchTimeout)
			clearTimeout(this.serchTimeout);

		/*let list = ['AI', 'C1', 'C2', 'D', 'C']
		list.forEach(i => {
			this.translate.get(i).forEach(element => {
				if (element == key.toUpperCase()) {
					key = i;
				}
			});
		})
		*/
		this.serchTimeout = setTimeout(() => this.searchWithKey.emit(key), 500)
	}
}
