import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditMilkQuantityCowComponent } from './edit-milk-quantity-cow/edit-milk-quantity-cow.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { AnimalBarModule } from '../animals/animal-bar/animal-bar.module';
import { MilkQuantityCowComponent } from './milk-quantity-cow.component';
import { CommonSettingFormModule } from '../settings/settings-form/common-setting-form/common-setting-form.module';



@NgModule({
  declarations: [
    MilkQuantityCowComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    AnimalBarModule, 
    MoomeCommonModule,
    CommonSettingFormModule
  ],
  exports: [
    MilkQuantityCowComponent
  ]
})
export class MilkQuantityCowModule { }