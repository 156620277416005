import { Component, OnInit } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { CustomRouter } from '../services/custom-router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  list = []
  constructor(public appService:AppService, public router: CustomRouter) { }

  ngOnInit(): void {
    this.list = [
      { class: "fab fa-facebook-square size-icon", href: "https://www.facebook.com/moome.tn/"},
      { class: "fab fa-twitter size-icon", href: "https://twitter.com/MooMeOfficial1"},
      { class: "fab fa-linkedin-in size-icon", href: "https://www.linkedin.com/company/moome/"},
      { class: "fa fa-envelope size-icon", href: "mailto:hello@moome.io"},
      { class: "fas fa-map-marker-alt size-icon", href: "https://www.google.com/maps/place/MooMe/@36.8447065,10.178932,17z/data=!3m1!4b1!4m5!3m4!1s0x12fd35a7963fa8d7:0x56605f1a1c65785c!8m2!3d36.8447065!4d10.1811207"},
      
    ]
  }
  closePopUp(){
    this.router.back('')
  }

}
