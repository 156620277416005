import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Customer } from 'projects/shared/resources/Customer';
import { AppService } from 'projects/shared/services/app.service';
import { CustomerService } from 'projects/shared/services/customer.service';
import { CustomRouter } from '../../services/custom-router';

@Component({
  selector: 'app-animals-empty',
  templateUrl: './animals-empty.component.html',
  styleUrls: ['./animals-empty.component.scss']
})
export class AnimalsEmptyComponent implements OnInit {
  male: Boolean = false
  router
  role: any;
  idFarmer: any;
  auth: string[] = [];
  currentRoute: any;
  supervisorId: any
  customer:any;
  color='color-blue'
  constructor(private route: ActivatedRoute, router: CustomRouter, public appService: AppService,private customerService: CustomerService) {
    this.router = router
    
   /* let sex = this.appService.connectedCustomer.sex
    if (sex == 'MAN') {
      this.male = true
    }*/
    this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');
    if(this.idFarmer!= null){
      this.customerService.findCustomerById(this.idFarmer).subscribe(res => {
        this.customer=res;
        let sex = this.customer.sex;
        if (sex == 'MAN') {
          this.male = true
        }
      })
     
    }
    else{
      let sex = this.appService.connectedCustomer.sex
    if (sex == 'MAN') {
      this.male = true
    }
    }
  }

  ngOnInit() {
    this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
    this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');
    this.currentRoute = this.router.url
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1) {
      this.role = true
    }
  }

  back() {
    if (!this.role) {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.router.navigateByUrl('/supervisors/' + this.supervisorId + '/farmers');
      }
      else this.router.navigateByUrl('/home')
    }
    else
      this.router.navigateByUrl('/farmers')
  }

  addAnimalUrl() {
    if (this.role) {
      this.router.navigateByUrl("/farmers/" + this.idFarmer + "/animals/save")
    }
    else {
      if (this.currentRoute.startsWith('/supervisors'))
        this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + this.idFarmer + "/animals/save")
      else {
        this.router.navigateByUrl('/animals/save')
      }
    }
  }
}
