import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { Customer } from "./Customer";
@HateoasResource('customerSettings')
export class CustomerSetting extends Resource {
  
    id: number;
    setting: String;
    value: String;
    customer: Customer | string;
    
  }