<div class=" text-center title-style-dialog d-flex justify-content-center" style="margin-top: 20px!important;"
    [ngClass]="{rtl:appService.rtl()}">
    {{'GREAT_MESSAGE' | translate }}
</div>
<div class="row mt-5">
    <div class="col">
        <div class="center-element">
           
            <button [ngClass]="{rtl:appService.rtl()}" class="button-dialog button-text-size text-center" (click)="openLink('https://play.google.com/store/apps/details?id=io.lifeye.moome&hl=fr&gl=US')"
                mat-raised-button color="primary">
                <span [ngClass]="appService.rtl()? 'button-mob-text-ar rtl' : 'button-mob-text'"
                    class="center-element">{{ 'OK' | translate }} </span></button>
        </div>
    </div>
</div>