import { Component, OnInit } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';

@Component({
  selector: 'app-network-detection-dialog',
  templateUrl: './network-detection-dialog.component.html',
  styleUrls: ['./network-detection-dialog.component.scss']
})
export class NetworkDetectionDialogComponent implements OnInit {
  text: String;
  constructor(public appService: AppService, private tokenManagementService: TokenManagementService) {
    let language = this.tokenManagementService.getTranslateLanguage();
    if (language == 'fr')
      this.text = "Veuillez vérifier votre connexion internet";
    else if (language == 'en')
      this.text = "Please check your internet connection";
      else if (language == 'ind')
      this.text = "Silakan periksa koneksi internet Anda";
    else this.text = "الرجاء التثبت من اتصال الانترنت الخاص بك";
  }

  ngOnInit(): void {
  }


}
