<div class="h-115 d-flex flex-column">
<div class="fixed-bar-mob-top " [scrollingElementSelector]="'cdk-virtual-scroll-viewport'" ngxHideOnScroll
    [propertyUsedToHide]="'top'" [valueWhenHidden]="'-10px'" [valueWhenShown]="'93px'">
    <div class="row h-100">
        <div class="col-4 center-element" (click)="back()">
            <img src="../../assets/images/left-arrow-2.svg" />
        </div>
        <div class="col-4 center-element">
            <div class="text-center text-style col " [ngClass]="{rtl:appService.rtl()}" [ngStyle]="{'color':color}">
                <div class="row  d-block">
                    <div class="col mt-2"><img class="img-fluid mx-auto d-block" [src]="sanitizer.bypassSecurityTrustUrl(img)"/>
                    </div>
                    <div class="col"><span>{{title | translate }}</span></div>
                </div>
            </div>
        </div>
        <div *ngIf="!tableView" class="col-4 text-center d-none d-sm-block ">

            <mat-icon class=" table_icon style-icon" (click)="(tableView=true)">
                table_chart</mat-icon>

        </div>
        <div *ngIf="tableView" class="col-4 text-center d-none d-sm-block ">

            <mat-icon class=" table_icon style-icon" (click)="(tableView=false)">
                view_module</mat-icon>

        </div>
    </div>
</div>

<div *ngIf="empty" class="h-100 center-element" [getAuthorized]="authority">
    <app-alert-type-empty [alertTypeGroup]="alertTypeGroup" >
    </app-alert-type-empty>
</div>

<cdk-virtual-scroll-viewport [itemSize]="itemSize" (scrolledIndexChange)="nextBatch($event)" *ngIf="!empty && !tableView" class="row"
    [ngStyle]="{'height' : 'calc(100% + 70px)'}">
    <app-alert-type-card *cdkVirtualFor="let alertDetail of alertsDetails" [getAuthorized]="authority"
        class="col-12 col-sm-6 col-md-6 col-lg-4 mt-2 mb-2" [alertDetail]="alertDetail">
    </app-alert-type-card>
    <div *ngIf="!theEnd" style="padding: 10px;">
        <mat-spinner diameter="75" style="margin: auto;"></mat-spinner>
    </div>
</cdk-virtual-scroll-viewport>

<div class="fixed-bar-mob" [scrollingElementSelector]="'cdk-virtual-scroll-viewport'" ngxHideOnScroll
    [propertyUsedToHide]="'bottom'" [valueWhenHidden]="'-85px'" [valueWhenShown]="'0px'">
    <div class="horizontal-scrollable">
        <div *ngFor="let bar of barFiltre" class="group-style" [getAuthorized]="checkBarAuthority(bar)">
            <app-alert-type-bar [bar]="bar" (barApply)="navRouteCode($event)" >
            </app-alert-type-bar>
        </div>
    </div>
</div>








<div #target>
    <div class="position"  style="overflow-y: hidden !important; top: 150px "
        *ngIf="tableView">

        <div class="containerTab mat-elevation-z8">
            <table mat-table [dataSource]="alertsDetails" [ngClass]="{rtl:appService.rtl()}">

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> {{ 'NAME' | translate}} </th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                        <div class="row" >
                            <div class="col-12 ps-4"
                                *ngIf="!checkType(element.alert.type) && !checkTypeConnected(element.alert.type) && !checkTypeThi(element.alert.type)">
                                <span class="text-style-customer"> {{ element.alert.animal.address.customer.fullname}}
                                </span>
                            </div>
                            <div class="col-12 ps-4" *ngIf="checkTypeConnected(element.alert.type) || checkTypeThi(element.alert.type)">
                             <span class="text-style-customer"> {{ element.alert?.devices[0]?.nameEntreprise ?
                                element.alert.devices[0].nameEntreprise :( element.alert?.devices[0]?.nameFarmer ?
                                element.alert.devices[0].nameFarmer : (element.alert?.gateways[0]?.addresses[0]?.fullname ?
                                element.alert.gateways[0].addresses[0].fullname :(element.alert?.gateways[0]?.tank[0]?.name ? element.alert.gateways[0].tank[0].name :  (
                                    element.alert.devices[0].deviceFarmers[0]?.customerFullName?  element.alert.devices[0].deviceFarmers[0].customerFullName : ''
                                    )   ) ))}}
                                </span>
                            </div>
                            
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="group">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GROUP' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" style="    height: 48px;" class=" d-flex justify-content-center align-items-center ">
                            <div class="dot"
                                [ngStyle]="{'background-color': element.alert.animal.customerAnimalGroups[0]?.color}">
                            </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="uid">
                    <th mat-header-cell *matHeaderCellDef >{{ 'UID' | translate}} / {{ 'NAME' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" class=" text-center">
                        <div (click)="apply(element)" >
                            <span class="text-style-UID" 
                                *ngIf="!checkType(element.alert.type) && !checkTypeConnected(element.alert.type)&& !checkTypeThi(element.alert.type)">
                                {{appService.valueUid ==nationalIdentification? (element.alert?.animal?.uid ?
                                    element.alert.animal.uid : element.alert.animal.name) : (appService.valueUid ==
                                nameLocalCode? (element.alert?.animal?.name ? element.alert.animal.name :
                                element.alert.animal.uid) : (element.alert?.animal?.uid ?
                                element.alert.animal.uid : element.alert.animal.name))}}
                            </span>
                            
                            <span class="text-style-UID" [ngClass]="{rtl:appService.rtl()}"
                                
                                *ngIf="checkTypeConnected(element.alert.type) ">
                                {{element.alert?.devices[0]?.codeTank || element.alert?.devices[0]?.tankDeviceAgitators[0]?.code  || element.alert?.devices[0]?.tankDeviceFridges[0]?.code ? ('NUMBER-TANK'| translate) : ''}}
                                {{element.alert?.devices[0] ? (element.alert?.devices[0]?.uidAnimal ? (appService.valueUid == nationalIdentification?
                                (element.alert?.devices[0]?.uidAnimal ?
                                element.alert.devices[0].uidAnimal : element.alert.devices[0].nameAnimal) :
                                (appService.valueUid ==
                                nameLocalCode? (element.alert?.devices[0]?.nameAnimal ?
                                element.alert.devices[0].nameAnimal :
                                element.alert.devices[0].uidAnimal) : (element.alert?.devices[0]?.uidAnimal ?
                                element.alert.devices[0].uidAnimal : element.alert.devices[0].nameAnimal))) :(element.alert?.devices[0]?.codeTank ?
                                element.alert.devices[0].codeTank :
                                (element.alert?.devices[0]?.tankDeviceAgitators[0]?.code ?
                                element.alert.devices[0].tankDeviceAgitators[0].code : (
                                    element.alert?.devices[0]?.tankDeviceFridges[0]?.code ? element.alert.devices[0].tankDeviceFridges[0].code : '' )))) : alertDetail.alert.gateways[0].code }}
    
                            </span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="message">
                    <th mat-header-cell *matHeaderCellDef> {{ 'MESSAGE' | translate}} </th>
                    <td mat-cell *matCellDef="let element"  class="text-center" >
 
                        <div class="center-element row " >
                            <div *ngIf="element.alert.type == 'AI'">
                                {{element.alert.message | translate:{'label': element.alert.label.substring(2) |
                                translate } }}
                            </div>
                            <div [ngClass]="(element.alert.message=='C1_BE_DONE_48H'&& element.alert.animal.address.country.code=='TN') ? 'col-4' : 'col-12'"  *ngIf="element.alert.type != 'AI'" >
                                {{element.alert | codeTranslate}}
                            </div>   
                            <!--<div [ngClass]="(element.alert.message=='C1_BE_DONE_48H'&& element.alert.animal.address.country.code=='TN') ? 'col-2' : 'col-12'"  *ngIf="element.alert.message=='C1_BE_DONE_48H'&& element.alert.animal.address.country.code=='TN'">

                                <button [ngClass]="{rtl:appService.rtl()}"
                                    mat-raised-button color="primary" (click)="confirmAppel()"><span
                                        class="center-element">{{'CALL_LAB' | translate }} </span></button>
                
                            </div>-->                 
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="tank">
                    <th mat-header-cell *matHeaderCellDef> {{'TANK' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="text-center" >
                       <div>
                        <span class="text-style-UID" [ngClass]="{rtl:appService.rtl()}">
                        {{ 'NUMBER-TANK' | translate}}{{element.alert?.devices[0]?.code ?
                            element.alert.devices[0].code:(element.alert?.devices[0]?.codeTank ?
                            element.alert.devices[0].codeTank:
                        (element.alert?.devices[0]?.tankDeviceAgitators[0]?.code ?
                        element.alert.devices[0].tankDeviceAgitators[0].code :
                        element.alert.devices[0].tankDeviceFridges[0].code))}}
                    </span>
                       </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="entreprise">
                    <th mat-header-cell *matHeaderCellDef> {{ 'ENTREPRISE' | translate}} </th>
                    <td mat-cell *matCellDef="let element" >
                        <div class="col-12 ps-4" >
                            <span class="text-style-customer"> {{element.alert?.devices[0]?.name ?
                                element.alert.devices[0].name :(element.alert?.devices[0]?.nameEntreprise ?
                                element.alert.devices[0].nameEntreprise:
                                (element.alert?.devices[0]?.tankDeviceAgitators[0]?.name ?
                                element.alert.devices[0].tankDeviceAgitators[0].name :
                                element.alert.devices[0].tankDeviceFridges[0].name))}}
                            </span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> {{ 'DATE_TITLE' |translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        <div >
                            <span >{{element.alert.date | date:
                                'dd/MM/yyyy'}}</span>
                                <br>
                                <!--<span>{{element.alert.date | date:
                                    'HH:mm'}}</span>-->
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
            </table>
            <mat-paginator #paginator class="paginator" [length]="resultsLength" [pageSize]="size"
             (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>
</div>