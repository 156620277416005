import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { MaterialModule } from 'projects/admin/src/app/material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TranslateModule} from '@ngx-translate/core';
import { RuminationUiComponent } from '../rumination-ui/rumination-ui.component';
import { CommunGraphComponent } from './commun-graph.component';
//import { FlexLayoutModule } from '@angular/flex-layout';
import { GraphComponent } from 'projects/shared/components/graph/graph.component';
import { TempGraphComponent } from '../temp-graph/temp-graph.component';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { IonicModule } from '@ionic/angular';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        //FlexLayoutModule,
        NgxHideOnScrollModule,
        IonicModule,
    ],
    declarations: [
        RuminationUiComponent,
        CommunGraphComponent,
        GraphComponent,
        TempGraphComponent
    ],
    exports: [
        RuminationUiComponent,
        CommunGraphComponent,
        GraphComponent,
        TempGraphComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class CommunGraphModule { }   