<app-section-bar [title]="name" (goBack)="back()" [color]="color"></app-section-bar >


<div class="row">
    <div class="row col-12 center-element px-2 pt-2 pb-2 ms-1 me-1" [createAuthorized]="authority" *ngIf="feedInventory">
        <div class="col-6 me-1 back-style-feed-inventory" >
            <div class="mt-3 text-center note-style-feed-inventory" [ngClass]="{rtl:appService.rtl()}">
                <div>{{ 'DRY_MATTER' | translate}}</div>
                <div>{{appService.sommeDry| number:'2.1-2'}} {{'KG'|translate}}</div>
            </div>
        </div>
        <div class="col-6  back-style-feed-inventory" >
            <div class="mt-3 text-center note-style-feed-inventory" [ngClass]="{rtl:appService.rtl()}">
                <div>{{ 'CRUD_PROTEIN' | translate}}</div>
                <div>{{appService.sommeCrude | number:'2.1-2'}} {{'KG'|translate}}</div>
            </div>
        </div>
    </div>
<div class="row ms-0 px-4" [createAuthorized]="authority" *ngIf="!idFeed">
    <div class="col-12 mx-auto back-style" (click)="action()">
        <div class="mt-3 text-center note-style" [ngClass]="{rtl:appService.rtl()}">
            <div>{{ 'NEW_RECORDING' | translate}}</div>
        </div>
    </div>
</div>

<div class="row ms-0 px-4" *ngIf="idFeed">
    <div class="col-12 mx-auto ">
        <div class="mt-3 text-center note-style" [ngClass]="{rtl:appService.rtl()}">
            <div>{{title}}</div>
        </div>
    </div>
</div>
</div>

<div class="row"  [getAuthorized]="authority">
    <div class="col note-style text-center mt-4 mb-2" [ngClass]="{rtl:appService.rtl()}" *ngIf="section!=sectionStock && !historyFeed && !historyMisc">{{'HISTORY' | translate}}
    </div>
    <div class="col note-style text-center mt-4 mb-2" [ngClass]="{rtl:appService.rtl()}" *ngIf="section==sectionStock && !historyFeed && !historyMisc">{{'ITEMS' | translate}}
    </div>
    <div class="col note-style text-center mt-4 mb-2" [ngClass]="{rtl:appService.rtl()}" *ngIf="historyFeed">{{'HISTORY_STOCK' | translate}}
    </div>
    <div class="col note-style text-center mt-4 mb-2" [ngClass]="{rtl:appService.rtl()}" *ngIf="historyMisc">{{'HISTORY_MISC_STOCK' | translate}}
    </div>
</div>

<div class="row"  [ngClass]="{rtl: appService.rtl()}" *ngIf="section==sectionFarmerMilkPorduction && appService.listId.length > 0">
    <div class="col-10 text-center" >
        <div >
        <button [ngClass]="{rtl:appService.rtl()}" class="button-dialog button-text-size text-center custom-button"
            mat-raised-button color="primary" (click)="editMilkPrice()">
            <span [ngClass]="appService.rtl()? 'button-mob-text-ar rtl' : 'button-mob-text'">
                {{ 'EDIT_MILK_PRICE' | translate }}
            </span>
        </button>
    </div>
    </div>
</div>

<div class="row px-0" [getAuthorized]="authority">
    <app-history-card *ngFor="let form of forms.monthValues" class="col-12 mt-2" [form]="form" [service]="service" (itemClick)="action(form)"
    (reload)="findForms()" [authority]="authority" (reloadState)="findForms()" [section]="section" (formSelectionChange)="onFormSelectionChange($event)"></app-history-card>
    <app-commun-history-card *ngFor="let element of getFormYears(forms)" class="col-12 mt-2" [element]="element" [service]="service"   (itemClick)="action($event)"
    (load)="findForms()"(loadState)="findForms()"  [forms]="forms" [authority]="authority"  [section]="section"></app-commun-history-card>
</div>