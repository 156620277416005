import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
@Component({
  selector: 'app-herd-card',
  templateUrl: './herd-card.component.html',
  styleUrls: ['./herd-card.component.scss']
})
export class HerdCardComponent implements OnInit {
@Input() element : any

  constructor(public appService: AppService) { }

  ngOnInit(): void {
  }

}
