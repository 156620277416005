import { Component } from '@angular/core';
import { OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from "moment"
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'projects/shared/services/app.service';
import { Alert, Authorities } from 'projects/shared/tools/constants';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { AnimalService } from 'projects/shared/services/animal.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort, Sort } from '@angular/material/sort';
import { CustomRouter } from 'projects/publicV2/src/app/services/custom-router';
import { EditNoteComponent } from 'projects/publicV2/src/app/notes/edit-note/edit-note.component';
import { EditAnimalComponent } from 'projects/publicV2/src/app/animals/edit-animal/edit-animal.component';
import { CommonDialogComponent } from 'projects/publicV2/src/app/common-dialog/common-dialog.component';
@Component({
  selector: 'app-farmer-animal-table',
  templateUrl: './farmer-animal-table.component.html',
  styleUrls: ['./farmer-animal-table.component.scss'],
})

export class FarmerAnimalTableComponent implements OnInit {



  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  get: Boolean;
  create: Boolean;
  edit: Boolean;
  del: Boolean;
  getAnimalNotes: Boolean;
  addAnimalNotes: Boolean;
  dataSource: any = []
  resultsLength: any = 0;
  pageSize: number = 10;
  pageSizeOptions: number[] = [5, 10, 25];
  page: number = 0;
  size: number = 25;
  key: any;
  field: String = "uid";
  direction: String;
  selection = new SelectionModel<any>(true, []);
  selectedRows = [];
  all: Boolean = false
  summary: boolean = false
  project:any;
  constructor( public router: CustomRouter, public codeTranslate: CodeTranslatePipe, private supervisorService: SupervisorService, public dialog: MatDialog,
    private animalService: AnimalService,
    public translate: TranslateService, public alertsService: AlertsService, public appService: AppService) {
    this.appService.connectedCustomer['roles'].forEach(role => {
      role['authorityRoles'].forEach(authorityRole => {
        if (authorityRole.authority.code == Authorities.ANIMALS) {
          this.get = authorityRole["get"];
          this.create = authorityRole["create"];
          this.edit = authorityRole["edit"];
          this.del = authorityRole["delete"];
        }
        if (authorityRole.authority.code == Authorities.ANIMAL_NOTES) {
          this.addAnimalNotes = authorityRole["create"];
          this.getAnimalNotes = authorityRole["get"];
        }
      }
      );
    });
  }

  ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = this.translate.instant('ITEMS_PER_PAGE');
    let currentRoute = this.router.url;
    if (currentRoute.startsWith('/supervisors')) 
      this.project = "publicV2";
    else
    this.project="admin"
    
    this.loadFramersWithAnimals()
  }

  isAllSelected() {
    let animals = []
    if (this.dataSource) {
      this.dataSource.forEach(customer => {
        customer.addresses.forEach(address => {
          address.animals.forEach(animal => {
            animals.push(animal)
          });
        });
      });
      const numSelected = this.selection.selected.length;
      const numRows = animals.length;
      return numSelected === numRows;
    }
  }

  test(row) {

    if (this.selection.isSelected(row)) {
      this.selection.deselect(row)
      let index = this.selectedRows.indexOf(row)
      this.selectedRows.splice(index, 1)
    }
    else {
      this.selection.select(row)
      this.selectedRows.push(row)
    }
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear()
      this.selectedRows = []
    }

    else if (!this.isAllSelected()) {
      let animals = []
      this.dataSource.forEach(customer => {
        customer.addresses.forEach(address => {
          address.animals.forEach(animal => {
            this.selection.select(animal)
            animals.push(animal)
          });
        });
      });
      this.selectedRows = [...animals]
    }
  }

  cleanSelected() {
    this.selection.clear()
    this.selectedRows = [];
  }

  loadFramersWithAnimals(key?) {
    if (key && this.key !== key) {
      this.paginator.pageIndex = 0;
      this.page = 0
    }
    this.key = key
    this.supervisorService.findFarmersWithAnimals(this.page, this.size, this.field, this.direction, key).subscribe(res => {
      this.dataSource = res['resources']
      this.resultsLength = res['totalElements'];

    }, error => {
      this.appService.hideLoading();
      let  message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" :error.error.code);
       let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK )
       this.alertsService.open(message, alert);
    })
  }

  onPaginateChange(event) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.loadFramersWithAnimals(this.key);
  }

  sortData(sort: Sort) {
    this.field = sort.active;
    this.direction = sort.direction;
    if (sort.active == "breed_lablel") {
      let lang: string = this.translate.currentLang;
      this.field = sort.active + lang.charAt(0).toUpperCase() + lang.slice(1);
    }
    this.loadFramersWithAnimals(this.key);
  }

  convertToDays(input) {
    if (input) {
      return moment().diff(moment(input), "days")
    }
    return "N/A"
  }

  convertToAge(input) {
    if (input) {
      let diff = moment().diff(moment(input), "days")
      if (diff <= 30)
        return diff + this.translate.instant('DAY')
      else {
        let quotient = Math.floor(diff / 30);
        let remainder = diff % 30;
        return quotient + this.translate.instant('MONTH') + remainder + this.translate.instant('DAY')
      }
    }
    return "N/A"
  }

  openAddNote(animal, customerId) {
    const dialogRef = this.dialog.open(EditNoteComponent, {
      width: '450px',
      data: {
        animal: animal,
        customerId: customerId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadFramersWithAnimals(this.key);
    });
  }

  cuAnimal(animal, address, customerId) {
    const dialogRef = this.dialog.open(EditAnimalComponent, {
      width: '500px',
      data: {
        title: 'ANIMAL',
        animal: animal,
        addressId: address.id,
        customerId: customerId,
        reloadData: () => {
          this.loadFramersWithAnimals(this.key);
        }
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  deleteAnimal(data) {
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      data: {
        message: this.translate.instant('CONFIRM_DELETE_ANIMAL'),
        title: this.translate.instant('DELETE_ANIMAL'),
        onConfirm: () => {
          this.animalService.delete(data).subscribe(data => { this.loadFramersWithAnimals(this.key) }
          , error => {
            this.appService.hideLoading();
            let message = this.translate.instant(error.error.error);
            let alert = this.translate.instant(Alert.OK)
            this.alertsService.open(message, alert);
          });
      }
    }
  });
}

  back() {
			this.router.navigateByUrl('/menu')
	}
}