import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HistoryCardComponent } from './history-card.component';



import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../material/material.module';
import { MatTreeModule } from '@angular/material/tree';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [HistoryCardComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MoomeCommonModule,

  
    MaterialModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    MatTreeModule,
    MatButtonToggleModule,
    MatMenuModule
  ], exports: [
    HistoryCardComponent
  ],
})
export class HistoryCardModule { }
