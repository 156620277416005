import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { Authorities, Country, FORMAT_MAX_MIN, NUMERIC_PATTERN, NUMERIC_PATTERN_RECALL, TEXT_PATTERN, Type } from 'projects/shared/tools/constants';
import * as moment from 'moment'
import { CustomRouter } from '../../../services/custom-router';
import { AnimalService } from 'projects/shared/services/animal.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-common-field',
  templateUrl: './common-field.component.html',
  styleUrls: ['./common-field.component.scss']
})
export class CommonFieldComponent implements OnInit {
  type = Type
  @Input() field;
  @Input() code
  formatArabe
  maxDate: any;
  unitMoney
  unit
  numericPattern = NUMERIC_PATTERN
  showUnit: boolean = false
  numericPatternn = NUMERIC_PATTERN_RECALL
  textPattern = TEXT_PATTERN
  notShow: boolean = false
  minDate
  animal
  idAnimal
  isDisabled: boolean = false
  displayCode:boolean=false
  constructor(private route: ActivatedRoute, public animalService: AnimalService, public appService: AppService, public tokenManagementService: TokenManagementService, private router: CustomRouter) {
    this.unitMoney = this.appService.connectedCustomer.addresses[0].country.currency
    this.unit = this.unitMoney;
    this.formatArabe = this.appService.verifFormatDate()
    this.fixArabeFormat()
    this.idAnimal = this.route.snapshot.paramMap.get('idAnimal');
  }

  fixArabeFormat() {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD MMM YYYY"
  }

  ngOnInit(): void {
    if((Country.isPakIdn(this.appService.connectedCustomer.addresses[0].country.code))&& (this.field.code == 'fiber'))
    this.displayCode=true
  else
  this.displayCode=false
    if ((this.field.code == 'price') || (this.field.code == 'incomes') || (this.field.code == 'unitPrice'))
      this.showUnit = true
    else
      this.showUnit = false
    this.maxDate = moment(new Date()).format(FORMAT_MAX_MIN);
    this.minDate = moment("2000, 1, 1").format(FORMAT_MAX_MIN)
    if (this.idAnimal) {
      this.animalService.getById(parseInt(this.idAnimal)).subscribe(res => {
        this.animal = res
        if(res.birthDate)
        this.minDate = moment(res.birthDate).format(FORMAT_MAX_MIN)


      })
    }

    if ((this.appService.connectedCustomer.addresses[0].country.code != 'PAK') && (this.field.code == 'fat' || this.field.code == 'snf'|| this.field.code == 'lr' || this.field.code == 'adjusted'|| this.field.code == 'soldAdjusted'))
      this.notShow = true

    this.isDisabled = this.router.url.includes("addFeedInventory") || this.router.url.includes("addMiscInventory") || this.router.url.includes("reduceMiscInventory");
  }
  percentValue(event) {
    const inputElement = event.target as HTMLInputElement;
    if (this.code.value < 0){
      this.code.setValue('0')
      return inputElement.value = '0';
   }
    if (this.code.value > 100){
    this.code.setValue('100')
      return inputElement.value = '100';}
  }

}
