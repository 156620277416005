<div class="d-flex justify-content-end close">
    <img src="/assets/images/Union-5.svg" (click)="closePopup()" />
</div>

<div class=" text-center title-style-dialog d-flex justify-content-center" style="margin-top: 20px!important;"
    [ngClass]="{rtl:appService.rtl()}">
    {{'LIKE_TEXT' | translate }}
</div>

<div class="row mt-5">
    <div class="col">
        <div class="center-element">
            <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="warn" style="margin-right: 5px"
                class="button-dialog   text-center" mat-dialog-close> <span
                    [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'" (click)="noUrl()" class="center-element">{{
                    'NO' | translate }} </span></button>
            <button [ngClass]="{rtl:appService.rtl()}" class="button-dialog button-text-size text-center" (click)="yesUrl()"
                mat-raised-button color="primary">
                <span [ngClass]="appService.rtl()? 'button-mob-text-ar rtl' : 'button-mob-text'"
                    class="center-element">{{ 'YES' | translate }} </span></button>
        </div>
    </div>
</div>
