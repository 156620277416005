<div class="row ">
    <div class="d-flex justify-content-center">
        <div [ngClass]="appService.rtl()? 'mt-2 pt-2' : 'mt-3 pt-3' ">
            <div class="ms-3 me-3 " *ngIf="section==sectionStock">
                <i class="fas fa-plus-circle fix-size-icon crois-style-ext" (click)="addNew(form)"></i>
            </div>
            <div class="ms-2 me-2 " *ngIf=" (historyMisc && form.quantity) || (historyFeed && form.quantity>0)">
                <i class=" material-icons pointer fix-size-icon crois-style-ext " (click)="edit(form)">border_color</i>
            </div>
        </div>
        <div class=" center-element back-style me-2 ">
            <div class="row col-12 mx-auto" [ngClass]="{rtl:appService.rtl()}">
                <div class="col-1" [ngClass]="appService.rtl()? 'mt-2 pt-2' : 'mt-2 ' " *ngIf="section==sectionFarmerMilkPorduction">
                <mat-checkbox (change)="onCheckboxChange($event,form)" > </mat-checkbox>
            </div>
               
                <div class="col-4 mt-2 text-center text-style " [ngClass]="{rtl:appService.rtl()}" 
                    [editAuthorized]="authority" *ngIf="showDate " (click)="apply(form)">
                        <span >{{form.quantity | number:'2.1-2'}} {{unit | translate}}</span>
                </div>
                <div class="col-4 mt-2 text-center  " [ngClass]="{rtl:appService.rtl()}" (click)="apply(form)"
                    [editAuthorized]="authority" *ngIf="!showDate ">
                     <span *ngIf="(form.title>0 && historyFeed) " class="color-purchase text-style">{{form.title}} {{unit | translate}}</span>
                     <span *ngIf="(form.title<0 && historyFeed) "class="color-consumption text-style">{{form.title}} {{unit | translate}}</span>
                        <span *ngIf="(form.title && !historyFeed) " class="text-style">{{form.title}} {{unit | translate}}</span>
                        <br />
                        <span *ngIf="(sectionFeedValue && !historyFeed && isIdnCode)" class="text-style-fiber">{{form.percentFiber ? form.percentFiber +'%':'ADD_DRY_MATTER' | translate }}  {{form.percentFiber ? ('FIBER' | translate):''}} </span>
                    
                    </div>
                <div class=" mt-2 col-5 text-center text-style-title" (click)="apply(form)" [editAuthorized]="authority"
                    *ngIf="showDate">
                    <span>{{form.title}} </span>
                   

                </div>

                <div class=" mt-2 col-5 text-center text-style" (click)="apply(form)" [editAuthorized]="authority" *ngIf="!showDate">
                    <span *ngIf="!form.date?.includes('T')">{{  form.date }}</span>
                    <span  *ngIf="form.date?.includes('T')">{{form.date.split('T')[0].split('-')[0] }}/{{ form.date.split('T')[0].split('-')[1].padStart(2, '0') }}/{{ form.date.split('T')[0].split('-')[2].padStart(2, '0') }}</span>
                </div>

                <div class="col-2" [ngClass]="appService.rtl()? 'mt-2 pt-2' : 'mt-2 ' "
                    [deleteAuthorized]="authority" *ngIf="! historyFeed">
                    <div [ngClass]="appService.rtl()? ' float-start' : 'float-end' " >
                        <i class="crois-style fas  icon fa-trash" (click)="delete(form)"></i>
                    </div>
                    </div>
                    <div class="col-3" [ngClass]="appService.rtl()? 'mt-2 pt-2' : 'mt-2 ' "
                    [deleteAuthorized]="authority" *ngIf="historyFeed">
                    <div [ngClass]="appService.rtl()? ' float-start' : 'float-end' "  class="text-style" *ngIf="form?.group">
                        <span *ngIf="labelEn">{{form?.group?.labelEn}}</span>
                        <span *ngIf="labelFr">{{form?.group?.labelFr}}</span>
                        <span *ngIf="labelInd">{{form?.group?.labelInd}}</span>
                        <span [ngClass]="{rtl:appService.rtl()}" *ngIf="labelAr">{{form?.group?.labelAr}}</span>
                    </div>
                    <div [ngClass]="appService.rtl()? ' float-start' : 'float-end' "  class="text-style" *ngIf="!(form?.group)">
                        <span >{{ 'PURCHASE' | translate}}</span>
                       
                        
                    </div>

                </div>
            </div>
        </div>

        <div class=" ms-2 me-2 " [ngClass]="appService.rtl()? 'mt-2 pt-2' : 'mt-1 pt-1' " *ngIf="section==sectionStock">
            <div class="mt-4" *ngIf="showReduce">
                <i class="fas fa-minus-circle fix-size-icon crois-style-ext" (click)="reduce(form)"></i>
            </div>
        </div>
    </div>
</div>