import { Directive, ElementRef, Renderer2, Input } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { AuthorizedDirective } from './authorized.directive';

@Directive({
  selector: '[editAuthorized]',
  exportAs:'editAuthorized'
})
export class EditAuthorizedDirective extends AuthorizedDirective {
    
  @Input() editAuthorized: string;
  @Input() hideType: String = "none"

  constructor( public elmRef: ElementRef, 
    public renderer: Renderer2, public appService: AppService) {
      super(elmRef, renderer, appService)
   
  }
   ngOnInit() {
     if(this.editAuthorized)
    this.checkAuthDisplay("edit", this.editAuthorized, this.hideType)
  }
}
