import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'projects/shared/services/app.service';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { FormService } from 'projects/shared/services/form.service';
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { CustomRouter } from '../../services/custom-router';
import { Authorities, SectionForm } from 'projects/shared/tools/constants';

@Component({
  selector: 'app-historique-card-economy',
  templateUrl: './historique-card-economy.component.html',
  styleUrls: ['./historique-card-economy.component.scss']
})
export class HistoriqueCardEconomyComponent implements OnInit {
idFarmer
idFormTemplate
forms: any={}
  projection = "form-projection"
  name
  service
  section=SectionForm.farmer_economy
  formTemplate
  color='color-abort'
  constructor(public formTemplateService: FormTemplateService, public formService: FormService, public appService: AppService, public route: ActivatedRoute,
    private router: CustomRouter, private supervisorService: SupervisorService) { }

  ngOnInit(): void {
    const {  idFormTemplate } = this.route.snapshot.params
    this.idFarmer = this.appService.connectedCustomer.id;
    this.idFormTemplate = idFormTemplate;
    let currentRoute = this.router.url;
    if (currentRoute.indexOf('key') == -1)
    this.getData()
    
    this.service = this.formService
    this.findFormTemplateId()
    if(this.formTemplate.xml.type=="OTHER_INCOMES")
    this.name='OTHER_INCOMES'
    else 
    this.name='OTHER_COSTS'
  }


  findFomTemplate() {
    this.formService.findByFarmerAndFormTemplate(this.idFarmer, this.idFormTemplate, this.projection).subscribe(res => {
      if (res)
      this.forms=this.appService.groupDataByYearAndMonthWithoutTiming(this.appService.organizedData(res, res[0]?.formTemplate));
        //this.forms = this.appService.organizedData(res, res[0]?.formTemplate);
    }, error => {
      this.appService.error(error.error.error)
    })
  }
  findFormTemplateId() {
    this.formTemplateService.getForm(this.idFormTemplate, "form-template",this.section).subscribe(template => {
      this.formTemplate = template
    })
  }

  getData() {
    this.findFomTemplate()
  }
  searchSettings(element) {
    this.formService.findFormByKeySearch(this.idFormTemplate, this.idFarmer, element.key).subscribe(res => {
      this.forms=this.appService.groupDataByYearAndMonthWithoutTiming(this.appService.organizedData(res['resources'], res['resources'][0].formTemplate));
      //this.forms = this.appService.organizedData(res['resources'], res['resources'][0].formTemplate)
    }, error => {
      this.appService.error(error.error.error)
    });
  }

  back() {
    this.router.back('')
  }
  action(item) {

    this.router.navigateByUrl('setting-economy/settings/' + this.idFormTemplate + '/save' + (item ? ("/" + (item.id ? item.id : item.uuid)) : ""))
   
      
      
     
   
  }
}
