import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TankCardComponent } from './tank-card.component';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [TankCardComponent],
  imports: [
    CommonModule,
    MoomeCommonModule,
    TranslateModule,
  ],
  exports: [
    TankCardComponent
  ]
})
export class TankCardModule { }
