import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelinesComponent } from './timelines.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { RouterModule } from '@angular/router';
import { EditTimelineModule } from './edit-timeline/edit-timeline.module';
import { CommonDialogModule } from '../common-dialog/common-dialog.module';
import { EditTimelineComponent } from './edit-timeline/edit-timeline.component';
import { CommonDialogComponent } from '../common-dialog/common-dialog.component';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { TimelineInformationModule } from './timeline-information/timeline-information.module';

@NgModule({
    declarations: [TimelinesComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MaterialModule,
        RouterModule,
        NgxHideOnScrollModule,
        MoomeCommonModule,
        EditTimelineModule,
        CommonDialogModule,
        TimelineInformationModule
    ],
    exports: [
        TimelinesComponent,
    ]
})
export class TimelinesModule { }
