import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core'
import { MaterialModule } from 'projects/public/src/app/material/material.module';
import { SearchComponent } from './search.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TranslateModule} from '@ngx-translate/core';
@NgModule({
    imports: [
      CommonModule,
      MaterialModule,
      FormsModule, ReactiveFormsModule,
      TranslateModule
    ],
    declarations: [
        SearchComponent
    ],
    exports: [
      SearchComponent
    ],
  
  })
  export class SearchModule { }   