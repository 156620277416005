<div class="row">
    <div class="col ms-2">
        <div><img src="/assets/images/Union-1.svg" (click)="back()"></div>
    </div>
</div>


<form [formGroup]="groupForm" [ngStyle]="{'height': innerHeight +'px'}">
    <div class="row gx-1">
        <div class="col-6 mb-4">
            <button class="sex male-style float-end" (click)="groupForm.controls['sex'].setValue('MAN')"
                [ngClass]="groupForm.controls['sex'].value == 'MAN' ? 'bor-style' : 'color' "><span
                    [ngClass]="{rtl:appService.rtl()}">{{ 'MAN' |
                    translate}}</span></button>
        </div>
        <div class="col-6 mb-4"><button class="sex female-style" (click)="groupForm.controls['sex'].setValue('WOMEN')"
                [ngClass]="groupForm.controls['sex'].value == 'WOMEN' ? 'bor-style' : 'color' "><span
                    [ngClass]="{rtl:appService.rtl()}">{{ 'WOMEN' |
                    translate}}</span></button>
        </div>
    </div>
    <div class="row">
        <div class="col text-center text-style color-dark-blue">
            <span [ngClass]="{rtl:appService.rtl()}">{{ nameText | translate }}</span>
        </div>
    </div>
    <div
        [ngClass]="groupForm.controls['sex'].value == 'MAN' ? 'background backgroundMale h-150' : 'background backgroundFemale h-150' ">
        <div class="row" [ngClass]="{rtl:appService.rtl()}">
            <div class="col mt-3 text-center">
                <input matInput name="fullname" class="input-mob input-border" type="text" formControlName="fullname">
            </div>

        </div>

        <!--div class="row">
            <div class="col mb-3 pt-5 text-center text-style color-dark-blue">
                <span [ngClass]="{rtl:appService.rtl()}">{{ 'WHAT_REGION' | translate }}</span>
            </div>
        </div>

        <div class="row">
            <div class="col center-element">
                <mat-select class="input-mob input-border" [ngClass]="{rtl:appService.rtl()}" formControlName="region">
                    <input (keyup)="onKeyRegion($event.target.value)" class="input-search-mob" >
                    <mat-option *ngFor="let region of selectedRegions" [value]="region.id"
                        [ngStyle]="{'text-align':(appService.rtl()? 'right' : 'left')}"><span
                            [ngClass]="{rtl:appService.rtl()}">{{region | codeTranslate}}</span>
                    </mat-option>
                </mat-select>
            </div>
        </div-->

        <div class="row">
            <div class="col mb-3 pt-5 text-center text-style color-dark-blue">
                <span [ngClass]="{rtl:appService.rtl()}">{{ countryText | translate }}</span>
            </div>
        </div>

        <div class="row">
            <div class="col center-element">
                <mat-select class="input-mob input-border" disableOptionCentering panelClass="myPanelSettingLabelClass"
                    [ngClass]="{rtl:appService.rtl()}" formControlName="country">
                    <input (keydown)="$event.stopPropagation()" (keyup)=" appService.onKeyCountry($event.target.value)"
                        class="input-search-mob">
                    <mat-option [ngClass]="{rtl:appService.rtl()}" class="ellipsis-text"
                        *ngFor="let country of appService.selectedCountries" [value]="country.id"
                        [ngStyle]="{'text-align':(appService.rtl()? 'right' : 'left')}"><span>{{country |
                            codeTranslate}}</span>
                    </mat-option>
                </mat-select>
            </div>
        </div>

        <div class="row">
            <div [ngClass]="!groupForm.valid ? 'opaci ' : '' " class="col mb-2 mt-5 pt-5 text-center">
                <button mat-raised-button color="primary" class="button-mob" (click)="apply()"
                    [ngClass]="{rtl:appService.rtl()}">{{ 'NEXT' | translate
                    }}</button>
            </div>
        </div>
        <div class="row">
            <div class="col text-center mt-2 mb-4 lang-pos">
                    <app-language [value]="selectedLanguage" [color]="color"></app-language>
            </div>
        </div>

    </div>


</form>