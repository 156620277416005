import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Roles } from '../tools/constants';
import { AppService } from '../services/app.service';
import { map } from 'rxjs/operators';
import { TokenManagementService } from '../services/token-management.service';

@Injectable({
	providedIn: 'root'
})
export class RoleGuard implements CanActivate {

	constructor(private router: Router, private appService: AppService, private tokenManagementService: TokenManagementService) {
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		let role: Roles = next.data.role;
		let access = next.data.access;

		return this.appService.getConnected().pipe(map(u => {
			let found: Boolean = false;
			let auth: string[] = [];

			u["roles"].forEach(element => {
				auth.push((element[access]).toString())

				if (auth.indexOf("true") != -1) {
					found = true;
					return true;
				}
				if (!role) {
					this.tokenManagementService.logout()
				} else
					if (role.indexOf(element.code) >= 0) {
						found = true;

						return true;
					}

			})

			if (found)
				return true;
			this.router.navigate(['/'])
			return false


		}
		))
	}

}
