
import { Animal } from './Animal';
import { Gateway } from './Gateway';
import { Customer } from './Customer';
import { DeviceAnimalHistory } from './DeviceAnimalHistory';
import { DeviceGatewayHistory } from './DeviceGatewayHistory';
import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
@HateoasResource('devices')
export class Device extends Resource {
    id: number;
    code: string;
    animal: Animal | string;
	gateways: Gateway | any;
	lastHistory: DeviceAnimalHistory | any;
	active:any;
	roundVoltage: any;
	customer: Customer | any;
	lastDeviceGatewayHistory: DeviceGatewayHistory | any;
    connectionState: string;
	type: string;
	availability:string;

  }