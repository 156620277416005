import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { TokenManagementService } from '../services/token-management.service';
import { CustomRouter } from 'projects/publicV2/src/app/services/custom-router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: CustomRouter, private tokenManagementService: TokenManagementService) {
  }


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.tokenManagementService.verifyApp().pipe(map(ok=>{
        if(!ok)
            return true;
        this.router.navigateByUrl('/home')
    
    }))
}
}