import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { MaterialModule } from '../material/material.module';
import { ReportComponent } from './report.component';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { MatTreeModule } from '@angular/material/tree';
import { IonicModule } from '@ionic/angular';



@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MoomeCommonModule,
    NgxHideOnScrollModule,
    MatTreeModule,
    IonicModule
    ],
  declarations: [
    ReportComponent, 
     
  ],
  exports: [   
    ReportComponent,
  ]
})
export class ReportModule { }
