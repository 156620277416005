import { Component, OnInit, ViewChild } from '@angular/core';
import { MatColumnDef, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { AppService } from 'projects/shared/services/app.service';
import { CustomRouter } from '../services/custom-router';
import { CustomerAlertService } from 'projects/shared/services/customer-alert.service';
import * as moment from 'moment';
import { LivestockIdentif } from 'projects/shared/tools/constants';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-weekly-plan-management',
  templateUrl: './weekly-plan-management.component.html',
  styleUrls: ['./weekly-plan-management.component.scss']
})

export class WeeklyPlanManagementComponent implements OnInit {

  displayedColumns: string[] = ['fullname', 'cow', 'event', 'date'];
  dataSource: any = [];
  auth: string[] = [];
  role = false;
  nationalIdentification
  nameLocalCode
  keys = []
  weeklyEmpty;
  @ViewChild(MatColumnDef, { static: true }) other: MatColumnDef;
  @ViewChild(MatTable, { static: true }) table;
  @ViewChild('sort', { static: true }) sort: MatSort;
  constructor(public translate: TranslateService, public codeTranslate: CodeTranslatePipe, public appService: AppService, public router: CustomRouter, public customerAlertService: CustomerAlertService) { }
  ngOnInit() {
    this.nationalIdentification = LivestockIdentif.NATIONAL_IDENTIFICATION
    this.nameLocalCode = LivestockIdentif.NAME_LOCALCODE
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1) {
      this.role = true;
    }
    this.getWeeklyPlan()
    this.displayedColumns.splice(0, 1);
  }
  back() {
    this.router.back('')
  }
  getWeeklyPlan() {
    let startDate = moment(new Date()).format("yyyy-MM-DD")
    let endDate = moment(new Date()).add(7, 'days').format("yyyy-MM-DD")

    let method = "getAlertsOfWeekForFarmer"
    if (this.role)
      method = "getAlertsOfWeekForSupervisor"

    this.customerAlertService[method](startDate, endDate).subscribe(res => {
      if (this.role)
        this.keys = this.appService.getKeys(res);
      this.dataSource = res;
      if((!res)|| ( Object.keys(res).length === 0))
        this.weeklyEmpty="empty"
      else
        this.weeklyEmpty ="notEmpty"
    })
  }
  

}




