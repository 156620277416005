import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';


@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgxHideOnScrollModule,
    FormsModule, ReactiveFormsModule,
  ],
  exports: [
    SearchComponent
  ],
})
export class SearchModule { }
