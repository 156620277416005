import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { SectionBarModule } from '../../animals/section-bar/section-bar.module';
import { CommonDialogModule } from '../../common-dialog/common-dialog.module';
import { CommonSettingModule } from '../common-setting/common-setting.module';
import { SettingsGraphComponent } from './settings-graph.component';
@NgModule({
  declarations: [
    SettingsGraphComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    CommonSettingModule,
    SectionBarModule,
    MoomeCommonModule
  ], exports: [
    SettingsGraphComponent
  ],
})
export class SettingsGraphModule { }



