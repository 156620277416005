<div class="card-size" [ngClass]="{rtl:appService.rtl()}">
    <div class="back-case-color" [ngClass]="{rtl:appService.rtl()}">
        <div class="row ">
            <div class="col-8 ms-4 ps-0 mt-3 mb-3">
                <div class="info-style-UID me-4 aligne" [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'">
                    <span class="">{{'NUMBER-TANK'|translate}}</span> <span
                        [ngClass]="appService.rtl()? 'nbre-size-ar' : 'nbre-size'" class=" pt-2">{{tank.code}}</span>
                </div>

            </div>

            <div class="col mt-4 mb-3  " [ngClass]="appService.rtl()? 'me-5' : 'me-3'">
                <div class="float-end">
                    <img src="/assets/images/Tracé-847.svg" />
                </div>
            </div>
        </div>
    </div>


    <div class="row px-3 mt-3 gx-5">
        <div class="col-3">
            <div class="row" *ngIf="tank.deviceTemp">
                <div class="col-12 mb-3">
                    <div class="min-card width-card blue-color">
                        <div class="text-center pt-2 pe-2 ps-2">
                            <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span
                                    [ngClass]="appService.rtl()? 'exp-unit-ar' : 'exp-unit-fr'">°C</span></div>
                        </div>
                        <div *ngIf="!tank.deviceTemp.temp || !tank.deviceTemp.active"
                            class="text-center font-temp aligne"><span>--</span>
                        </div>
                        <div *ngIf="tank.deviceTemp.temp && tank.deviceTemp.active">
                            <div *ngIf="tank.deviceTemp.temp" class=" text-center  aligne">
                                <span class="entier"
                                    *ngIf="tank.deviceTemp.temp<0 && !appService.rtl()">{{tankSign}}</span>
                                <span class="entier"
                                    *ngIf="tank.deviceTemp.temp<0">{{((tank.deviceTemp.temp.toString()).split("-")[1]).split(".")[0]}}</span>
                                <span class="entier"
                                    *ngIf="tank.deviceTemp.temp>=0">{{(tank.deviceTemp.temp.toString()).split(".")[0]}}</span>

                                <span class="decimal pt-2">.{{tank.deviceTemp.temp.toString().split(".")[1]}}</span>
                                <span class="entier"
                                    *ngIf="tank.deviceTemp.temp<0 && appService.rtl()">{{tankSign}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 mb-3" *ngFor="let deviceHumidity of tank.deviceHumidities">
                    <div class="min-card width-card blue-color" [ngClass]="{rtl:appService.rtl()}"
                        *ngIf="deviceHumidity">
                        <div class="text-center pt-2 pe-2 ps-2">
                            <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span
                                    [ngClass]="appService.rtl()? 'exp-unit-ar' : 'exp-unit-fr'">°C</span>
                                <span [ngClass]="{rtl:appService.rtl()}"
                                    class="design-label-humidity font">{{deviceHumidity.label}}</span></div>
                        </div>

                        <div [ngClass]="appService.rtl()? 'mt-2' : 'mt-1'">

                            <div class=" text-center  aligne" *ngIf="deviceHumidity.temp && deviceHumidity.active">
                                <span class="entier"
                                    *ngIf="deviceHumidity.temp<0 && !appService.rtl()">{{tankSign}}</span>
                                <span class="entier"
                                    *ngIf="deviceHumidity.temp<0">{{(deviceHumidity.temp.toString().split("-")[1]).split(".")[0]}}</span>
                                <span class="entier"
                                    *ngIf="deviceHumidity.temp>=0">{{deviceHumidity.temp.toString().split(".")[0]}}</span>
                                <span class="decimal pt-2"
                                    *ngIf="deviceHumidity.temp.toString().split('.')[1]">.{{deviceHumidity.temp.toString().split(".")[1]}}</span>
                                <span class="entier"
                                    *ngIf="deviceHumidity.temp<0 && appService.rtl()">{{tankSign}}</span>
                            </div>
                            <div *ngIf="!deviceHumidity.temp || !deviceHumidity.active"
                                class="text-center font-temp aligne"><span>--</span></div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="col-3 ">
            <div class="row">
                <div class="col-12 mb-3" *ngFor="let deviceFridge of tank.deviceFridges">
                    <div class="min-card width-card blue-color">
                        <div class="text-center pt-2">
                            <div *ngIf="deviceFridge.active"
                                [ngClass]="appService.rtl()? 'text-on-ar' : 'text-on'" class="d-inline"><span
                                    [ngClass]="deviceFridge.event > 0 ? 'color' : 'red-color'"
                                    class="exp-unit font">{{ deviceFridge.event > 0 ? 'ON' : 'OFF'}}</span></div>
                            <div *ngIf="!deviceFridge.active"
                                class="text-center font-temp aligne-mov"><span>--</span></div>

                        </div>

                        <div class="mt-2 text-center aligne"><span
                                [ngClass]="appService.rtl()? 'text-cooling-ar' : 'text-cooling '">{{'COOLING'|translate}}</span><span
                                [ngClass]="appService.rtl()? 'text-on-ar' : 'text-on'"
                                [ngClass]="{rtl:appService.rtl()}"
                                class="design-label font">{{deviceFridge.label}}</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3 ">
            <div class="row">
                <div class="col-12 mb-3" *ngFor="let deviceAgitator of tank.deviceAgitators">
                    <div class="min-card width-card  blue-color" [ngClass]="{rtl:appService.rtl()}">
                        <div class="text-center pt-2" [ngClass]="{rtl:appService.rtl()}">
                            <div [ngClass]="appService.rtl()? 'text-on-ar' : 'text-on'" class="d-inline"><span
                                    [ngClass]="deviceAgitator.event > 0 ? 'color' : 'red-color'"
                                    *ngIf="deviceAgitator.active"
                                    class="exp-unit font">{{ deviceAgitator.event > 0 ? 'ON' : 'OFF'}}</span></div>
                            <div *ngIf="!deviceAgitator.active"
                                class="text-center font-temp aligne-mov"><span>--</span></div>

                        </div>

                        <div class=" mt-2 text-center aligne"><span
                                [ngClass]="appService.rtl()? 'text-cooling-ar' : 'text-cooling '">{{'AGITATION'|translate}}</span><span
                                [ngClass]="appService.rtl()? 'text-on-ar' : 'text-on'"
                                [ngClass]="{rtl:appService.rtl()}"
                                class="design-label font">{{deviceAgitator.label}}</span></div>

                    </div>
                </div>

            </div>


        </div>
        <div class="col-3 center-element">
            <img src="/assets/images/gear-option.svg" />
        </div>
    </div>

</div>