import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { CountryService } from 'projects/shared/services/country.service';
import { FarmerService } from 'projects/shared/services/farmer.service';
import { CustomRouter } from '../../services/custom-router';
import { Customer } from 'projects/shared/resources/Customer';
import { Alert, MAX_LENGTH_PASSWORD, NAME_MAX_LENGTH, NAME_MIN_LENGTH, PATTERN_NAME_ARABIC, PATTERN_NAME_FRENCH, PATERN_PASSWORD, USERNAME_MAX_LENGTH, USERNAME_MIN_LENGTH, USERNAME_PATTERN, NAME_MAX, NAME_MIN, NAME_PATTERN, MIN_LENGTH_PASSWORD, Authorities, Roles, LIVESTOCK_IDENTIFICATION, PATTERN_NAME_ENGLISH } from 'projects/shared/tools/constants';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
//import { RecaptchaComponent } from '../../recaptcha/recaptcha.component';
import { Validator } from 'projects/shared/tools/validators';
import { Helper } from 'projects/shared/tools/helper';
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { CustomerService } from 'projects/shared/services/customer.service';
import { CustomerSetting } from 'projects/shared/resources/CustomerSetting';
import { CustomerSettingService } from 'projects/shared/services/customer-setting.service';
import { ConfigService } from 'projects/shared/services/config.service';
import { SettingCustomerDialogComponent } from './setting-customer-dialog/setting-customer-dialog.component';
import { Subscription } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Role } from 'projects/shared/resources/Role';
import { CommonDialogComponent } from '../../common-dialog/common-dialog.component';

export enum SettingsText {
  EDIT_NAME = "EDIT_NAME",
  EDIT_ADDRESS = "EDIT_ADDRESS",
  EDIT_PHONE = "EDIT_PHONE",
  EDIT_PASS = "EDIT_PASS",
  EDIT_USERNAME = "EDIT_USERNAME",
  LIVSTOCK_IDENTIFICATION = "LIVSTOCK_IDENTIFICATION",
  DELETE_ACCOUNT="DELETE_ACCOUNT"

}

@Component({
  selector: 'app-settings-customer',
  templateUrl: './settings-customer.component.html',
  styleUrls: ['./settings-customer.component.scss']
})

export class SettingsCustomerComponent implements OnInit {
  countries: any[];
  selectedCountries: any[] = [];
  countrySelectedId: number;
  phoneLength: number
  name: String = "FOULEN BEN FALTEN"
  formGroup: UntypedFormGroup;
  id: number;
  phoneNumber: string;
  patternName: any;
  customer: Customer = new Customer();
  nameMinLength = NAME_MIN_LENGTH;
  nameMaxLength = NAME_MAX_LENGTH;
  patternNameArabic = PATTERN_NAME_ARABIC;
  patternNameFrench = PATTERN_NAME_FRENCH;
  userMinLength = USERNAME_MIN_LENGTH
  paternUsername = USERNAME_PATTERN
  userMaxLength = USERNAME_MAX_LENGTH
  minLengthPassword = MIN_LENGTH_PASSWORD
  maxLengthPassword = MAX_LENGTH_PASSWORD
  patternPassword = PATERN_PASSWORD
  list: any[] = [
    { type: SettingsText.EDIT_USERNAME, labelAr: "تغيير اسم المستخدم", labelFr: "Modifier le nom de l'utilisateur", labelEn: "Edit Username", labelInd: "Edit Nama Pengguna" },
    { type: SettingsText.EDIT_PASS, labelAr: "تغيير كلمة السر", labelFr: "Modifier le mot de passe", labelEn: "Edit Password", labelInd: "Edit Kata Sandi" },
    { type: SettingsText.EDIT_NAME, labelAr: "تغيير الإسم", labelFr: "Modifier le Nom", labelEn: "Edit Name", labelInd: "Ubah Nama"  },
    //{ text: SettingsText.EDIT_ADDRESS },
    { type: SettingsText.EDIT_PHONE, labelAr:"تغيير رقم الهاتف", labelFr: "Modifier le N° de téléphone", labelEn:"Edit Phone Number", labelInd: "Edit Nomor Telepon"  },
    { type: SettingsText.LIVSTOCK_IDENTIFICATION, labelAr: "تعريف القطيع", labelFr: "Identification du troupeau", labelEn: "Livestock Identification", labelInd: "Identifikasi Ternak"   },
    { type: SettingsText.DELETE_ACCOUNT, labelAr: "حذف الحساب", labelFr: "Supprimer le compte", labelEn: "Delete account" ,labelInd:"Hapus akun"  }
  ]
  authority = Authorities.FARMERS;
  auth: string[] = [];
  service: any
  connectedCustomer: any;
  customerSetting: CustomerSetting = new CustomerSetting();
  livestockIdentification = LIVESTOCK_IDENTIFICATION
  executionsSubscription: Subscription;
  color='color-alert'
  constructor(private configService: ConfigService, public customerSettingService: CustomerSettingService, public supervisorService: SupervisorService, public tokenManagementService: TokenManagementService, private formBuilder: UntypedFormBuilder, private countryService: CountryService, private router: CustomRouter, public appService: AppService, public dialog: MatDialog, public dialogRef: MatDialogRef<SettingCustomerDialogComponent>,
    public route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service, public customerService: CustomerService, @Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService, private codeTranslate: CodeTranslatePipe, private farmerService: FarmerService, public alertsService: AlertsService) {
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
      if(role.adminAccess)
      this.authority = Authorities.USERS
    })
    if (this.auth.indexOf("true") != -1)
      this.authority = Authorities.SUPERVISORS

          //this.appService.verifValueUid()

  }

  ngOnInit() {

    this.id = this.appService.connectedCustomer.id
    this.name = this.appService.connectedCustomer.fullname
    this.phoneNumber = this.appService.connectedCustomer.phone
    if ((this.appService.connectedCustomer.addresses.length == 0) || (this.appService.connectedCustomer.addresses[0].country == null))
      return this.messageError('COUNTRY_REQUIRED')
    else {
      this.phoneLength = this.appService.connectedCustomer.addresses[0].country.phoneLength

    }


    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })

    this.service = this.appService.checkService();
    //googleAnalytics
    this.appService.googleAnalyticsAllPages('settingsFarmer')
  }

  pathUrl(value) {
    switch (value) {
      case SettingsText.EDIT_USERNAME:
        return this.editUsername()
      case SettingsText.EDIT_NAME:
        return this.editName()
      case SettingsText.EDIT_ADDRESS:
        return this.editAddress()
      case SettingsText.EDIT_PHONE:
        return this.editPhone()
      case SettingsText.EDIT_PASS:
        return this.editPassword()
      case SettingsText.LIVSTOCK_IDENTIFICATION:
        return this.editLivstockIdentification()
        case SettingsText.DELETE_ACCOUNT:
          return this.deleteAccount()

    }
    
  }

  ngOnDestroy() {
    this.appService.menu = true
    this.appService.hideRecaptcha()
  if (this.executionsSubscription) {
      this.executionsSubscription.unsubscribe();
    }
  }

  back() {
    this.router.navigateByUrl('/menu')
  }

  editUsername() {
    const dialogRef = this.dialog.open(SettingCustomerDialogComponent, {
      data: {
        title: this.translate.instant('PASSWORD'),
        editType: "phonePass",
        validators: [Validators.required,
        ],
        value: 'password',
        onConfirm: (formGroup) => {
          let password = formGroup.controls['password'].value
          //this.appService.showLoading()
          if ((this.appService.connectedCustomer.addresses.length == 0) || (this.appService.connectedCustomer.addresses[0].country == null)) {
            formGroup.setErrors({ 'invalid': true });
            return this.messageError('COUNTRY_REQUIRED')
          }
          else {

            this.service.verifPassword(password).subscribe(
              data => {
                if ((data == false) || (formGroup.controls['password'].hasError('required'))) {
                  this.messageError('PLEASE_CHECK_PASSWORD');
                }
                else {
                  this.appService.hideLoading()
                  this.dialog.closeAll()
                  const dialogRef = this.dialog.open(SettingCustomerDialogComponent, {
                    width: '350px',
                    data: {
                      title: this.translate.instant(SettingsText.EDIT_USERNAME),
                      editType: "username",
                      validators: [Validators.required,
                      Validators.minLength(this.userMinLength),
                      Validators.maxLength(this.userMaxLength),
                      Validators.pattern(this.paternUsername)],
                      value: 'username',
                      onConfirm: (formGroup) => {
                        if (formGroup.controls['username'].hasError('required')) {
                          this.messageError('PLEASE_CHECK_USERNAME');
                        }
                        else if (formGroup.controls['username'].hasError('minlength'))
                          this.messageError(this.translate.instant('LENG_USERNAME_MIN') + " " + this.userMinLength.toString() + " " + this.translate.instant('CARACTERE'));
                        else if (formGroup.controls['username'].hasError('maxlength'))
                          this.messageError(this.translate.instant('LENG_USERNAME_MAX') + " " + this.userMaxLength.toString() + " " + this.translate.instant('CARACTERE'));
                        else if (formGroup.controls['username'].hasError('pattern'))
                          this.messageError('PATTERN_CHAR_USERNAME');
                        else {

                         /* const dialogRef = this.dialog.open(RecaptchaComponent, {

                            data: {
                              onConfirm: (token) => {*/
                              this.appService.showRecaptcha();
                              this.executionsSubscription = this.recaptchaV3Service.execute('importantAction')
                              .subscribe((token: string) => {
                                this.customer.addresses = this.appService.connectedCustomer.addresses;
                                this.customer['recaptchaToken'] = token;
                                this.customer.id = this.id
                                this.customer.phone = this.phoneNumber
                                this.customer.username = formGroup.controls['username'].value
                                this.customer.password = password
                                this.appService.showLoading()
                                this.service.updateUsername(this.customer).subscribe(
                                  data => {
                                    this.succesMessage()
                                    this.router.navigateByUrl('/login')
                                  }, error => {
                                    this.messageError(error,error)
                                  })
                              })
                          //  }
                         // })
                        }
                      }
                    }
                  }
                  )
                }
              },
              error => {
                this.messageError(error,error)
              })
          }
        }
      }
    });
  }

  editName() {

    this.patternName = Helper.checkPatternName(this.tokenManagementService.getTranslateLanguage())
    const dialogRef = this.dialog.open(SettingCustomerDialogComponent, {
      data: {
        title: this.translate.instant(SettingsText.EDIT_NAME),
        editType: "name",
        validators: [
          Validators.required,
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(this.nameMaxLength),
          Validators.pattern(this.patternName)],
        value: 'name',
        onConfirm: (formGroup) => {
          if ((this.appService.connectedCustomer.addresses.length == 0) || (this.appService.connectedCustomer.addresses[0].country == null)) {
            formGroup.setErrors({ 'invalid': true });
            return this.messageError('COUNTRY_REQUIRED')
          }
          else {
            if ((formGroup.controls['name'].hasError('required')))
              this.messageError("NAME_REQUIRED")
            else if (formGroup.controls['name'].hasError('minlength'))
              this.messageError(this.translate.instant('NAME_MIN_LENGTH') + " " + this.nameMinLength.toString() + " " + this.translate.instant('CARACTERES'));
            else if (formGroup.controls['name'].hasError('maxlength'))
              this.messageError(this.translate.instant('NAME_MAX_LENGTH') + " " + this.nameMaxLength.toString() + " " + this.translate.instant('CARACTERE'));
            else if (formGroup.controls['name'].hasError('pattern')) {
              if (this.patternName == this.patternNameArabic)
                this.messageError('PATTERN_CHAR_ARABIC');
                else if(this.patternName == this.patternNameFrench && this.tokenManagementService.getTranslateLanguage() == 'fr')
                this.messageError('PATTERN_LANGUAGE_FRENCH');
              else
              this.messageError('PATTERN_LANGUAGE_ENGLISH');
            }
            else {
              this.service.updateFullname(formGroup.controls['name'].value).subscribe(data => {
                this.succesMessage()
                this.tokenManagementService.getUserInfo().subscribe(res => {
                  this.appService.connectedCustomer = res
                  this.name = this.appService.connectedCustomer.fullname
                },
                  error => {
                    this.messageError(error,error)
                  });
              },
                error => {
                  this.messageError(error,error)
                });
            }
          }
        }
      }
    });
  }

  editAddress() {
    const dialogRef = this.dialog.open(SettingCustomerDialogComponent, {
      data: {
        title: this.translate.instant(SettingsText.EDIT_ADDRESS),
        editType: "country",
        validators: [Validators.required],
        value: 'country',
        onConfirm: (formGroup) => {
          if ((formGroup.controls['country'].hasError('required'))) {
            this.messageError('ADDRESS_REQUIRED');
          }
          else {

            this.service.updateAddress(formGroup.controls['country'].value).subscribe(
              data => {
                this.succesMessage()
              }, error => {
                this.messageError('EDIT_FAIL',error)
              })
          }
        }
      }
    });
  }

  editPhone() {
    const dialogRef = this.dialog.open(SettingCustomerDialogComponent, {
      data: {
        title: this.translate.instant('PASSWORD'),
        editType: "phonePass",
        validators: [Validators.required],
        value: 'password',
        onConfirm: (formGroup) => {
          // this.appService.showLoading()
          if ((this.appService.connectedCustomer.addresses.length == 0) || (this.appService.connectedCustomer.addresses[0].country == null)) {
            formGroup.setErrors({ 'invalid': true });
            return this.messageError('COUNTRY_REQUIRED')
          }
          else {
            this.service.verifPassword(formGroup.controls['password'].value).subscribe(
              data => {
                this.appService.hideLoading()
                if (!data) {
                  this.messageError('PLEASE_CHECK_PASSWORD');
                } else {
                  this.dialog.closeAll()

                  const dialogRef = this.dialog.open(SettingCustomerDialogComponent, {
                    width: '350px',
                    data: {
                      title: this.translate.instant(SettingsText.EDIT_PHONE),
                      editType: "phone",
                      validators: [Validators.required, Validator.validPhoneLength(this.translate, this.phoneLength, this.alertsService)],
                      value: 'phone',
                      onConfirm: (formGroup) => {

                        if (formGroup.controls['phone'].hasError('required'))
                          this.messageError(this.translate.instant('FIELD_REQUIRED'));
                        else if (formGroup.controls['phone'].value.toString().replace(/\s/g, "").length != this.phoneLength)
                          this.messageError(this.translate.instant('PHONE_LENGTH') + " " + this.phoneLength.toString());
                        else {
                          this.appService.showLoading()
                          this.service.updatePhone(formGroup.controls['phone'].value).subscribe(
                            data => {
                              this.succesMessage();
                              this.tokenManagementService.getUserInfo().subscribe(res => {
                                this.appService.connectedCustomer = res
                                this.phoneNumber = this.appService.connectedCustomer.phone
                              },
                              error => {
                                this.messageError(error.error.code,error)
                               
                              });
                            }
                            ,
                            error => {
                              this.messageError(error.error.code,error)
                           
                            })

                        }
                      }
                    }
                  });
                }
              },
              error => {
                this.messageError(error,error)}
              )
          }
        }
      }
    });
  }

  editPassword() {
    const dialogRef = this.dialog.open(SettingCustomerDialogComponent, {
      data: {
        title: this.translate.instant(SettingsText.EDIT_PASS),
        editType: "password",
        validators: [Validators.required,],
        value: 'password',
        onConfirm: (formGroup) => {
          if ((this.appService.connectedCustomer.addresses.length == 0) || (this.appService.connectedCustomer.addresses[0].country == null)) {
            formGroup.setErrors({ 'invalid': true });
            return this.messageError('COUNTRY_REQUIRED')
          }
          else {
            this.verif(formGroup);
          }
        }
      }
    });
  }
  editLivstockIdentification() {

    const dialogRef = this.dialog.open(SettingCustomerDialogComponent, {
      data: {
        title: this.translate.instant(SettingsText.LIVSTOCK_IDENTIFICATION),
        editType: "livestockIdentification",
        value: 'value',
        onConfirm: (formGroup) => {
          if ((this.appService.connectedCustomer.addresses.length == 0) || (this.appService.connectedCustomer.addresses[0].country == null)) {
            formGroup.setErrors({ 'invalid': true });
            return this.messageError('COUNTRY_REQUIRED')
          }
          else {
            if (this.appService.connectedCustomer['customerSettings'].length != 0) {
              this.appService.connectedCustomer['customerSettings'].forEach(customerSetting => {
                if (customerSetting.setting == this.livestockIdentification) {
                  this.customerSetting['_links'] = {
                    self: {
                      href: this.configService.getApiPath() + "/customerSettings/" + customerSetting.id
                    }
                  }
                }
                else
                  this.customerSetting.customer = this.appService.connectedCustomer['link'].href;
              })
            }

            this.customerSetting.customer = this.appService.connectedCustomer['link'].href;
            this.customerSetting.setting = this.livestockIdentification
            this.customerSetting.value = formGroup.controls['value'].value
            this.appService.showLoading()

            this.customerSettingService.save(this.customerSetting).subscribe(
              data => {
                this.appService.valueUid = formGroup.controls['value'].value
                this.succesMessage();
                this.tokenManagementService.getUserInfo().subscribe(res => {
                  this.appService.connectedCustomer = res
                  this.appService.verifValueUid(res)
                },
                  error => {
                    this.messageError(error,error)
                   
                  })
              },
              error => {
                this.messageError(error,error)
              })

          }
        }
      }
    });

  }

  verif(formGroup) {
    if (formGroup.controls['password'].hasError('required'))
      this.messageError(this.translate.instant('CODE_REQUIRED'));

    else if (formGroup.controls['newPassword'].hasError('required'))
      this.messageError(this.translate.instant('PLEASE_CHECK_NEW_PASSWORD'));

    else if (formGroup.controls['newPassword'].hasError('minlength'))
      this.messageError(this.translate.instant('LENG_NEW_PASSWORD') + " " + this.minLengthPassword.toString() + " " + this.translate.instant('CARACTERE') + " ")

    else if (formGroup.controls['newPassword'].hasError('maxlength'))
      this.messageError(this.translate.instant('MAX_LENGTH_NEW_PASSWORD') + " " + this.maxLengthPassword.toString() + " " + this.translate.instant('CARACTERE') + " ")


    else if (formGroup.controls['newPassword'].hasError('pattern'))
      this.messageError('NEW_PASSWORD_PATTERN');

    else if (formGroup.controls['confNewPassword'].hasError('required'))
      this.messageError(this.translate.instant('PLEASE_CONFIRM_NEW_PASSWORD'));

    else if (formGroup.controls['confNewPassword'].hasError('minlength'))
      this.messageError(this.translate.instant('LENG_CONF_NEW_PASSWORD') + " " + this.minLengthPassword.toString()+ " " + this.translate.instant('CARACTERE') + " ")

    else if (formGroup.controls['confNewPassword'].hasError('maxlength'))
      this.messageError(this.translate.instant('MAX_LENGTH_CONF_NEW_PASSWORD') + " " + this.maxLengthPassword.toString()+ " " + this.translate.instant('CARACTERE') + " ")


    else if (formGroup.controls['confNewPassword'].hasError('pattern'))
      this.messageError('CONF_NEW_PASSWORD_PATTERN');

    else if (formGroup.controls['newPassword'].value != formGroup.controls['confNewPassword'].value) {
      this.messageError(this.translate.instant('NO_CONFIRM_PASSWORD'));
    }
    else {
      this.confirmEditPassword(formGroup)
    }
  }

  confirmEditPassword(formGroup) {
   /* const dialogRef = this.dialog.open(RecaptchaComponent, {
      data: {
        onConfirm: (token) => {*/
        this.appService.showRecaptcha();
        this.executionsSubscription = this.recaptchaV3Service.execute('importantAction')
        .subscribe((token: string) => {
          this.customer.addresses = this.appService.connectedCustomer.addresses;
          this.customer['recaptchaToken'] = token;
          this.customer.id = this.id
          this.customer.phone = this.phoneNumber
          this.appService.showLoading()
          this.service.verifPassword(formGroup.controls['password'].value).subscribe(data => {
            if (data) {
              this.customer.password = formGroup.controls['newPassword'].value
              this.service.updatePasswordApp(this.customer).subscribe(data => {
                this.succesMessage()
                this.router.navigateByUrl('/login')
              }, error => {
                this.messageError('EDIT_FAIL',error)
              })
            }
            else
              this.messageError('PLEASE_CHECK_PASSWORD');
          },
          error => {
            this.messageError(error,error)
          })
        })
     // }
   // })
  }
  deleteAccount() {
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      data: {
        message: this.translate.instant('CONFIRM_DELETE_ACCOUNT'),
        title: this.translate.instant('DELETE_ACCOUNT'),
        onConfirm: () => {
          this.customerService.deleteCustomer().subscribe(res => {
            this.appService.successMessage( this.translate.instant('DELETED_ACCOUNT_SUCCESS') + " "+ this.translate.instant('SUCCESS') + " " )
            setTimeout(() => {
              this.login()
            }, 800);
          }
            , error => {
              this.appService.error(error.error.code)
            });
        }
      }
    });
  }
  login(){
    this.router.navigateByUrl('/login')
  }
  succesMessage() {
    this.dialog.closeAll();
    let message: string;
    this.appService.hideLoading();
    message = this.translate.instant('SAVE_SUCESS');
    this.alertsService.open(message);
  }

 
  messageError(msg,error?) {
    this.appService.hideLoading()
    let message = this.translate.instant(error ? (error.error.error_description?.startsWith('Invalid access token:') ? "User is disabled" : msg) : msg);
    let alert = this.translate.instant(error ? (error.error.error_description?.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK) : Alert.OK);
    this.alertsService.open(message, alert); 
  }

}
