import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetStartedComponent } from './get-started.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'projects/admin/src/app/material/material.module';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '../language/language.module';

@NgModule({
  declarations: [
    GetStartedComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    RouterModule,
    LanguageModule
  ],
  exports: [
    GetStartedComponent
  ]
})
export class GetStartedModule { }
