import { Injectable } from '@angular/core';
import 'projects/shared/style/shared.scss'
//import { AppService } from './app.service';
import { AlertComponent } from '../components/alert/alert.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Injectable(
	{
		providedIn: 'root'
	}
)
export class AlertsService {

	constructor(public snackBar: MatSnackBar/*, public appService: AppService*/, public dialog: MatDialog) {
	}


	/*	open(message, action?) {
			let autoHide: number = 3000;
			if (action)
				autoHide = 0;
			//let horizontalPosition: MatSnackBarHorizontalPosition = 'center'; //'start' | 'center' | 'end' | 'left' | 'right';
			//let verticalPosition: MatSnackBarVerticalPosition = 'top'; //'top' | 'bottom';
			let config = new MatSnackBarConfig();
		//	config.verticalPosition = verticalPosition;
			//config.horizontalPosition = horizontalPosition;
			config.duration = autoHide;
			config.panelClass = this.appService.rtl()? ['snackBarStyle', 'rtl-snackBar'] : 'snackBarStyle' ;
	
			return this.snackBar.open(message, action, config);
		}*/

	open(message, action?, time?) {
		
		let autoHide: number = 3000;
		if(time)
		  autoHide = time;
		const dialogRef = this.dialog.open(AlertComponent, {
			panelClass: 'alert-style',
			width: '300px',
			//height:'150px',
			data: {
				message: message,
				action: action
			}
		});
		if (!action)
			setTimeout(function () {
				dialogRef.close();
			}, autoHide);
	}
}