import { Resource } from '@lagoshny/ngx-hateoas-client';
import { PATTERN_NAME_ARABIC, PATTERN_NAME_FRENCH } from './constants';

export class Helper {

	public static cleanLink(link: string): string {
		return link.replace(/\{.*\}/, '');
	}

	public static getByLink<T extends Resource>(objects: T[], link): T {
		let selected: T;
		objects.forEach(o => {
			const objectLink = Helper.cleanLink(o['_links'].self.href);
			const cleanLink = Helper.cleanLink(link)
			if (objectLink === link) {
				selected = o;
				return true;
			}
		})
		return selected;
	}
	public static checkPatternName(userLanguage) {
		let pattern: any;
		if (userLanguage == 'ar')
			pattern = PATTERN_NAME_ARABIC;
		else
			pattern = PATTERN_NAME_FRENCH
		return pattern;
	}

	public static generatePassword() {
		let checkboxes = [
			{
				"id": "lowercase",
				"label": "a-z",
				"library": "abcdefghijklmnopqrstuvwxyz",
				"checked": true
			}, {
				"id": "uppercase",
				"label": "A-Z",
				"library": "ABCDEFGHIJKLMNOPWRSTUVWXYZ",
				"checked": true
			}, {
				"id": "numbers",
				"label": "0-9",
				"library": "0123456789",
				"checked": true
			}, {
				"id": "symbols",
				"label": "!-?",
				"library": "!@#$%^&*-_=+\\|:;',.\<>/?~",
				"checked": true
			}
		]

		let dictionary: Array<String>;
		let passwordLenght: Number = 8;
		let lowercase: Boolean = checkboxes[0].checked;
		let uppercase: Boolean = checkboxes[1].checked;
		let numbers: Boolean = checkboxes[2].checked;
		let symbols: Boolean = checkboxes[3].checked;
		// Create array from chosen checkboxes
		dictionary = [].concat(
			lowercase ? checkboxes[0].library.split("") : [],
			uppercase ? checkboxes[1].library.split("") : [],
			numbers ? checkboxes[2].library.split("") : [],
			symbols ? checkboxes[3].library.split("") : []
		);
		// Generate random password from array
		let newPassword: string = "";
		for (let i = 0; i < passwordLenght; i++) {
			newPassword += dictionary[Math.floor(Math.random() * dictionary.length)];
		}
		return newPassword;

	}

	/*public static datePickerValidator(value, alertsService, translate, tokenManagementService) {
		let msg = translate.instant('FORM_DATE');
		let alert = translate.instant(Alert.OK)
		if (tokenManagementService.getTranslateLanguage() == "fr") {
			if (!moment(value, 'DD/MM/YYYY', true).isValid())
			return alertsService.open(msg, alert);
		}
		else if (tokenManagementService.getTranslateLanguage() == "ar") {
			if (!moment(value, 'YYYY/MM/DD', true).isValid())
				msg = translate.instant('FORM_DATE');
			return alertsService.open(msg, alert);
		}
		else if (tokenManagementService.getTranslateLanguage() == "en") {
			if (!moment(value, 'MM/DD/YYYY', true).isValid())
				msg = translate.instant('FORM_DATE');
			return alertsService.open(msg, alert);
		}
	};*/
}