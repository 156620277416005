import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { Authorities, Roles } from 'projects/shared/tools/constants';
import { CustomRouter } from '../../services/custom-router';
import { NavigationExtras } from '@angular/router';
@Component({
  selector: 'app-supervisor-card',
  templateUrl: './supervisor-card.component.html',
  styleUrls: ['./supervisor-card.component.scss']
})
export class SupervisorCardComponent implements OnInit {

  @Input() supervisor;
  authorityAlerts = Authorities.ALERTS
  authorityRapports = Authorities.REPORT
  advisorMcc = false;
  advisorFarmer = false;
  constructor(public appService: AppService, private router: CustomRouter) { }

  ngOnInit() {
    let roles = this.supervisor.roles
    roles.forEach(element => {
        element['authorityRoles'].forEach(authorityRole => {
  
          if (authorityRole.authority.code == Authorities.ENTREPRISES && authorityRole.get == true)
          this.advisorMcc = true
          if(authorityRole.authority.code == Authorities.FARMERS && authorityRole.get == true)
          this.advisorFarmer = true
        })
      })
  }

  openFarmers(id) {
    const navigationExtras: NavigationExtras = { state: { supervisor: this.supervisor } };
    this.router.navigateByUrl("supervisors/" + id + "/farmers", navigationExtras)

  }

  openEntreprises() {
    const navigationExtras: NavigationExtras = { state: { supervisor: this.supervisor } };
    this.router.navigateByUrl("supervisors/" + this.supervisor.id + "/mcc", navigationExtras)
  }




  /*openAlerts(){
      this.router.navigateByUrl("supervisors/" + this.supervisor.id + "/alerts")
  }*/
}
