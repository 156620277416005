<div class="d-flex flex-column position-absolute center-element h-100 w-100 backdrop">
        <div class="bg-white p-4">
                        <div class="center-element">
                                        <img src="/assets/images/no-wifi.svg" class="size" /></div>
                                <div class="center-element mt-4">
                                        <div class="message-style text-center" [ngClass]="{rtl:appService.rtl()}">{{text}}
                                        </div>
                                </div>
        </div>
       
</div>
