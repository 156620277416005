import { Component, OnInit } from '@angular/core';
import { CustomRouter } from '../services/custom-router';
import { Authorities, GroupCode } from 'projects/shared/tools/constants';
import { AppService } from 'projects/shared/services/app.service';
import { CustomerService } from 'projects/shared/services/customer.service';
import { GroupService } from 'projects/shared/services/group.service';

@Component({
  selector: 'app-herd',
  templateUrl: './herd.component.html',
  styleUrls: ['./herd.component.scss']
})
export class HerdComponent implements OnInit {
  herdList : any[] = []
  card1 :any 
  card2 :any 
  bar:any
  url:any
  
  currentRoute: any;
  auth: string[] = [];
  countProd:any
  countDry:any
  countHeifers:any
  countTotal:any
  countCalves:any
  countAdult:any
  percentProduction;
  percentDry
  percentHeifers
  percentClaves
  constructor(public router: CustomRouter, public appService: AppService, public groupService: GroupService, public customerService: CustomerService) {
    this.currentRoute = this.router.url
      this.loadGroups()
  }

  ngOnInit(): void {
    this.url = this.router.url
  }

  back() {
    this.router.navigateByUrl('/dashboard')
  }
  loadGroups(idFarmer?) {
    this.groupService.getGroupsOfCustomer(idFarmer).subscribe(data => {
      this.countProd=(data['resources'].filter(d => d.code == GroupCode.PRODUCTION_CODE)[0]).groupAnimalCount;
      this.countDry=(data['resources'].filter(d => d.code == GroupCode.DRY_CODE)[0]).groupAnimalCount;
      this.countHeifers=(data['resources'].filter(d => d.code == GroupCode.HEIFERS_CODE)[0]).groupAnimalCount;
      this.countCalves=(data['resources'].filter(d => d.code == GroupCode.CALVES_CODE)[0]).groupAnimalCount;
      this.countAdult=this.countDry+this.countProd;
      this.countTotal=(data['resources'].filter(d => d.code == GroupCode.PRESENT_HERD)[0]).groupAnimalCount;
      if((this.countProd!=0)&&(this.countAdult!=0))
      this.percentProduction=(this.countProd*100/this.countAdult).toFixed(0)+'%';
      if((this.countDry!=0)&&(this.countAdult!=0))
      this.percentDry=(this.countDry*100/this.countAdult).toFixed(0)+'%';
      if((this.countHeifers!=0)&&(this.countAdult!=0))
      this.percentHeifers=(this.countHeifers*100/this.countAdult).toFixed(0)+'%';
      if((this.countCalves!=0)&&(this.countAdult!=0))
      this.percentClaves=(this.countCalves*100/this.countAdult).toFixed(0)+'%';
      this.herdList = [

        { title: "MILKING", color: "#80cc28", value1: this.countProd ? this.countProd:0, value2: this.percentProduction? this.percentProduction:'0%', image: "/assets/images/milk.svg" },
        { title: "DRY", color: "#632289", value1: this.countDry ? this.countDry:0, value2: this.percentDry ? this.percentDry:'0%', image: "/assets/images/dry.svg" },
        { title: "HEIFERS", color: "#ff2dc6", value1: this.countHeifers ?this.countHeifers:0, value2:this.percentHeifers ? this.percentHeifers :'0%', image: "/assets/images/Heifers.svg" },
        { title: "CALVES", color: "#06AF8F", value1: this.countCalves ? this.countCalves:0, value2: this.percentClaves ? this.percentClaves:'0%', image: "/assets/images/Calves.svg" },
      ];
      this.card1 = { title: "ADULT_COWS", value: this.countAdult, class: "blue-herd-box", bigFontClass: "big-herd-font", smallFontClass: "small-herd-font" }
      this.card2 = { title: "TOTAL_HERD", value:this.countTotal, class: "light-herd-box", bigFontClass: "big-herd-font", smallFontClass: "small-herd-font" }
      this.bar = { title: "HERD", arrowIcon: "/assets/images/left-arrow.svg", color: "#f6f7fb", fontColor: "#204157", router: "/dashboard" }
      
    })
  }
}
