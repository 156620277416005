import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
@Input() url:any 
barList:any[]=[]
filtredList:any[]=[]
  constructor() {
    this.barList=[
      {title:"HERD",color:"#204157",route:"/herd"},
      {title:"MILK_PRODUCTION",color:"#06AF8F",route:"/milkProd"},
      {title:"REPRO",color:"#80cc28",route:"/production"},
      {title:"FEED_FODDER",color:"#F7AC08",route:"/feed"},
      {title:"CARBON_EMISSIONS",color:"#80cc28",route:"/carbon"},
      {title:"HEALTH",color:"#f44336",route:"/health"},
      {title:"FARM_ECONOMICS",color:"#316a90",route:"/economics"}
    ]
   }

  ngOnInit(): void {
    this.filtredList = this.barList.filter(bar => bar.route != this.url)
  }

}
