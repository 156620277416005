import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'projects/shared/services/app.service';
import { DeviceService } from 'projects/shared/services/device.service';
import { RealtimeTagService } from 'projects/shared/services/realtime-tag.service';
import { CustomRouter } from '../services/custom-router';
import { interval } from 'rxjs';
import { DeviceFarmer } from 'projects/shared/resources/DeviceFarmer';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { Alert } from 'projects/shared/tools/constants';
import { DeviceFarmerService } from 'projects/shared/services/device-farmer.service';

@Component({
  selector: 'app-thi',
  templateUrl: './thi.component.html',
  styleUrls: ['./thi.component.scss']
})
export class ThiComponent implements OnInit {

  constructor(private deviceFarmerService: DeviceFarmerService, private alertsService: AlertsService, public router: CustomRouter, public appService: AppService,
    private translate: TranslateService, public deviceService: DeviceService,
    public realtimeTagService: RealtimeTagService) { }
  thiCards: any[]
  thiEmpty: any
  tags: DeviceFarmer[] = [];
  // thiValue
  ngOnInit(): void {

    this.loadDevices()
    this.realtimeTagService.connect((frame) => {
      let sub = interval(1000).subscribe(res => {
        if (this.thiCards) {
          this.realtimeTagService.subscribeDevices(this.tags)
          sub.unsubscribe();
        }
      })
    })
  }
  back() {
    this.appService.verif = false
    this.router.navigateByUrl('/home')
  }
  loadDevices() {

    this.realtimeTagService.unsubscribeDevices();
    this.appService.showLoading()
    this.deviceFarmerService.getDevicesCode().subscribe((data: any) => {
      this.thiCards = data
      this.thiEmpty = this.thiCards.length == 0;
      if(this.thiEmpty)
      this.appService.hideLoading()
      this.tags = []
      this.thiCards.forEach(element => {
        this.tags.push(element)
      })
      this.realtimeTagService.subscribeDevices(this.tags)
    },
      error => {
        this.appService.hideLoading();
        let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
        let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
        this.alertsService.open(message, alert);
      })
  }
  ngOnDestroy() {
    this.realtimeTagService.disconnect();
  }

}
