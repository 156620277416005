import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Customer } from 'projects/shared/resources/Customer';
import { AnimalService } from 'projects/shared/services/animal.service';
import { AppService } from 'projects/shared/services/app.service';
import { CustomerService } from 'projects/shared/services/customer.service';
import { GroupService } from 'projects/shared/services/group.service';
import { RealtimeTagService } from 'projects/shared/services/realtime-tag.service';
import { interval } from 'rxjs';
import { CustomRouter } from '../services/custom-router';
import { Authorities/*, OFFSET_RUMINATION*/, Alert, Roles, LivestockIdentif } from 'projects/shared/tools/constants';
import { ReportService } from 'projects/shared/services/report.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { Options } from 'projects/shared/services/moome-rest.service';
import { AddWeightPopupComponent } from './add-weight-popup/add-weight-popup.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscriptionRequestHistoryService } from 'projects/shared/services/subscription-request-history.service';
import { SubscriptionRequestService } from 'projects/shared/services/subscription-request.service';



@Component({
  selector: 'app-animals',
  templateUrl: './animals.component.html',
  styleUrls: ['./animals.component.scss']
})
export class AnimalsComponent implements OnInit {
  farmerId: any;
  animals: any = [];
  groupId;
  defaultGroupId;
  tags: any[] = [];
  empty: boolean = false
  searchAnimalsEmpty: boolean = false;
  groupAnimalsEmpty: boolean = false;
  id: any;
  key: any
  value: string
  fullname: String;
  idFarmer: any;
  farmer: Customer = null
  authorityGroups = Authorities.GROUPS;
  authorityAnimals = Authorities.ANIMALS;
  role: Boolean = false
  auth: string[] = [];
  currentRoute: any;
  supervisorId: any;
  //offset: Number = OFFSET_RUMINATION;
  startDate: string;
  endDate: string;
  diffTimezone
  tableView: Boolean = false;
  @Output() reload = new EventEmitter();
  authFarmer: string[] = [];
  nationalIdentification
  nameLocalCode
  displayedColumns: string[] = ["signal", 'group', 'uid', 'sex', 'lactNumber', 'lactDays', 'gest', 'age', 'weight', 'gmq', 'rumination', 'repos', 'settings'];
  verifAnimalsNumber: Boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private route: ActivatedRoute, public appService: AppService, public realtimeTagService: RealtimeTagService, public customerService: CustomerService,
    private animalService: AnimalService, public router: CustomRouter,
    public groupService: GroupService, public reportService: ReportService,
    public translate: TranslateService,
    public alertsService: AlertsService,
    private subscriptionRequestHistoryService: SubscriptionRequestHistoryService, private subscriptionRequestService: SubscriptionRequestService) {
    this.defaultGroupId = this.appService.defaultGroupId
    this.currentRoute = this.router.url
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as { value: string };
    if (state)
      this.value = state.value;
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1) {
      this.role = true
      this.id = this.route.snapshot.paramMap.get('idFarmer');
    }
    else if (this.currentRoute.startsWith('/supervisors')) {
      this.id = this.route.snapshot.paramMap.get('idFarmer');
      this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
    }
    else
      this.id = this.appService.connectedCustomer.id



  }
  ngOnInit() {
    this.nationalIdentification = LivestockIdentif.NATIONAL_IDENTIFICATION
    this.nameLocalCode = LivestockIdentif.NAME_LOCALCODE
    this.appService.connectedCustomer['roles'].forEach(role => {
      if (role.code == Roles.ROLE_FARMER) {
        this.authFarmer.push((true).toString())
      }
    })
    if (this.authFarmer.indexOf("true") != -1) {
      this.openSubscription()
      this.openSubscriptionExpired()
    }
    this.diffTimezone = new Date().getTimezoneOffset()
    let todayAtSchedulerHour = moment(new Date()).set({ hour: 6 - this.diffTimezone / 60, minute: 0, second: 0, millisecond: 0 });

    let today = moment(new Date());

    if (today.isAfter(todayAtSchedulerHour))
      this.startDate = (moment().subtract(1, 'days')).set({ hour: -this.diffTimezone / 60, minute: 0, second: 0, millisecond: 0 }).toISOString();
    else
      this.startDate = (moment().subtract(2, 'days')).set({ hour: -this.diffTimezone / 60, minute: 0, second: 0, millisecond: 0 }).toISOString();

    //this.endDate = (moment()).startOf('day').toISOString();
    this.appService.verif = false
    this.groupId = this.route.snapshot.paramMap.get('id');
    this.key = this.route.snapshot.paramMap.get('key');
    if (!this.key)
      this.route.queryParams.subscribe(params => {

        this.key = params.key;
      })
    this.farmerId = this.route.snapshot.paramMap.get('idFarmer');
    if (this.farmerId)
      this.getFarmerById(this.farmerId)
    this.getAnimals();


    this.realtimeTagService.connect((frame) => {
      let sub = interval(1000).subscribe(res => {
        if (this.animals) {
          this.realtimeTagService.subscribeDevices(this.tags)
          sub.unsubscribe();
        }
      })
    });

  }

  openSubscription() {
    this.subscriptionRequestHistoryService.getLastHistory().subscribe(res => {
      if (res)
        this.appService.showDialog('REACH_MAX_COW_LAST_FARMER')
    }, error => {
      this.appService.error(error.error.code)
    })
  }

  openSubscriptionExpired() {
    this.subscriptionRequestService.getLastSubscriptionExpired().subscribe(res => {
      if (res)
        this.appService.showDialog('REACH_EXPIRED_DATE')
    }, error => {
      this.appService.error(error.error.code)
    })
  }




  getAnimals() {
    if (this.key)
      this.loadAnimalsSearch()
    else if (this.groupId == -1) {
      if (this.farmerId)
        this.loadAnimals(this.farmerId)
      else
        this.loadAnimals()
    }

    else {
      this.loadAnimalsByGroup(this.groupId, this.supervisorId);
    }


  }

  ngOnDestroy() {
    this.realtimeTagService.disconnect();
    this.appService.menu = false
  }


  back() {
    //this.router.back()
    let current = this.router.url
    if (!this.role) {
      if (current == '/animals/groups/' + this.defaultGroupId) {
        this.router.navigateByUrl('/home')
      }
      else if (current == '/supervisors/' + this.supervisorId + '/farmers/' + this.farmerId + '/animals/groups/' + this.defaultGroupId) {
        this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers")
      }
      else {

        this.router.back('')
      }
    }

    else {
      if (current == "/farmers/" + this.farmerId + "/animals/groups/" + this.defaultGroupId) {
        this.router.navigateByUrl('/farmers')
      }
      else
        this.router.back('')

    }
  }

  loadAnimals(farmerId?) {
    this.animals = [];
    this.realtimeTagService.unsubscribeDevices();
    this.appService.showLoading()
    this.animalService.getAnimalsByConnectedCustomer(farmerId).subscribe(data => {
      this.animals = data;
      let numberOfAnimals = data.length
      if (numberOfAnimals > 20)
        this.verifAnimalsNumber = true
      this.empty = this.animals.length == 0;
      this.animals = this.animals.sort((a1, a2) => {
        if ((a1.customerAnimalGroups == null || a1.customerAnimalGroups.length == 0 || !a1.customerAnimalGroups[0].code) &&
          (a2.customerAnimalGroups == null || a2.customerAnimalGroups.length == 0 || !a2.customerAnimalGroups[0].code))
          return 0
        if (a1.customerAnimalGroups == null || a1.customerAnimalGroups.length == 0 || !a1.customerAnimalGroups[0].code)
          return 1
        if (a2.customerAnimalGroups == null || a2.customerAnimalGroups.length == 0 || !a2.customerAnimalGroups[0].code)
          return -1
        return a1.customerAnimalGroups[0].code.localeCompare(a2.customerAnimalGroups[0].code)
      })
      let uuids = []
      this.animals.forEach(animal => {
        uuids.push(animal.uuid)
        if (animal.device && !animal.device.active)
          this.tags.push(animal.device);
        if (animal.customerAnimalGroups && animal.customerAnimalGroups.length > 0) {
          animal.customerAnimalGroups.forEach(element => {
            if (element.color != null)
              animal.colorGroup = element.color
            animal.groupId = element.id
          });
        }
      })
      this.appService.hideLoading()
      this.realtimeTagService.subscribeDevices(this.tags);
      this.displayWellBeing(uuids);

      /*this.reportService.displayWellBeing(uuids.join(","), RUMINATION, this.startDate, this.endDate, this.offset, SITTING, this.diffTimezone).subscribe(data => {
        let keys = Object.keys(data);
        keys.forEach(key => {
          this.animals.forEach(f => {
            if (f.uuid == key) {
              f.countRumination = data[key]['RUMINATION'];
              f.countRest = data[key]['SITTING'];
            }
          })
        });
      })*/

    },
      error => {
        this.appService.hideLoading();
        let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
        let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
        this.alertsService.open(message, alert);
      })
  }

  loadAnimalsByGroup(value, supervisorId?) {
    this.realtimeTagService.unsubscribeDevices();
    this.appService.showLoading()
    this.animalService.getAnimalsByGroup(supervisorId, this.id, parseInt(value)).subscribe(data => {

      this.animals = data['resources']
      if (data['resources']) {
        let numberOfAnimals = data['resources'].length

        if (numberOfAnimals > 20)
          this.verifAnimalsNumber = true
      }
      if (!data['resources'])
        this.groupAnimalsEmpty = true/*this.animals.length == 0;*/
      if (data['resources'])
        this.animals = this.animals.sort((a1, a2) => {
          return a1.customerAnimalGroups[0].code.localeCompare(a2.customerAnimalGroups[0].code)
        })

      this.tags = [];
      let uuids = []
      if (this.animals)
        this.animals.forEach((animal: any) => {
          uuids.push(animal.uuid)
          if (animal.device && !animal.device.active)
            this.tags.push(animal.device);
          if (animal.customerAnimalGroups && animal.customerAnimalGroups.length > 0) {
            animal.customerAnimalGroups.forEach(element => {
              if (element.color != null)
                animal.colorGroup = element.color
              animal.groupId = element.id
            });
          }
        })
      this.appService.hideLoading()
      this.realtimeTagService.subscribeDevices(this.tags)
      this.displayWellBeing(uuids);
      /*  this.reportService.displayWellBeing(uuids.join(","), RUMINATION, this.startDate, this.endDate, this.offset, SITTING, this.diffTimezone).subscribe(data => {
          let keys = Object.keys(data);
          keys.forEach(key => {
            this.animals.forEach(f => {
              if (f.uuid == key) {
                f.countRumination = data[key]['RUMINATION'];
                f.countRest = data[key]['SITTING'];
              }
            })
          });
        })*/
    },
      error => {
        this.appService.hideLoading();
        let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
        let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
        this.alertsService.open(message, alert);
      })
  }

  displayWellBeing(uuids) {

    this.reportService.getCountStatus(uuids, this.startDate).subscribe((data: any[]) => {
      data.forEach(key => {
        this.animals.forEach(f => {
          if (f.uuid == key.uuid) {
            f.countRumination = key.rumination;
            f.countRest = key.rest;
          }
        })
      });
    })
  }

  loadAnimalsSearch() {
    this.realtimeTagService.unsubscribeDevices();
    this.animalService.getAnimalsSearch(this.id, this.key).subscribe(data => {
      this.appService.hideLoading();
      this.animals = data['resources'];
      let numberOfAnimals = data['resources']?.length
      if (numberOfAnimals > 20)
        this.verifAnimalsNumber = true
      this.searchAnimalsEmpty = !this.animals;

      /*  this.animals = this.animals.sort((a1, a2) => {
          return a1.customerAnimalGroups[0].code.localeCompare(a2.customerAnimalGroups[0].code)
        })*/
      this.tags = [];
      let uuids = []
      this.animals.forEach(animal => {
        uuids.push(animal.uuid)
        if (animal.device && !animal.device.active)
          this.tags.push(animal.device);
        if (animal.customerAnimalGroups && animal.customerAnimalGroups.length > 0) {
          animal.customerAnimalGroups.forEach(element => {
            if (element.color != null)
              animal.colorGroup = element.color
            animal.groupId = element.id
          });
        }
      })
      this.realtimeTagService.subscribeDevices(this.tags)
      /* this.reportService.displayWellBeing(uuids.join(","), RUMINATION, this.startDate, this.endDate, this.offset, SITTING, this.diffTimezone).subscribe(data => {
         let keys = Object.keys(data);
         keys.forEach(key => {
           this.animals.forEach(f => {
             if (f.uuid == key) {
               f.countRumination = data[key]['RUMINATION'];
               f.countRest = data[key]['SITTING'];
             }
           })
         });
       })*/
    },
      error => {
        this.appService.hideLoading();
        let message = this.translate.instant(error.error.error_description);
        let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
        this.alertsService.open(message, alert);
      })
  }
  getFarmerById(id) {

    let options: Options = {
      params: {
        projection: 'customer_name',
      }
    }
    this.customerService.get(id, options).subscribe(res => {
      this.farmer = res
      this.fullname = this.farmer.fullname
    })
  }

  addAnimalUrl() {
    if (this.role) {
      this.appService.getDefaultGroup().subscribe(group => {
        let idFarmer = this.route.snapshot.paramMap.get('idFarmer');
        this.router.navigateByUrl("farmers/" + idFarmer + "/animals/save")
      })
    }
    else if (this.currentRoute.startsWith('/supervisors')) {
      let idFarmer = this.route.snapshot.paramMap.get('idFarmer');
      this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + idFarmer + "/animals/save")
    }
    else
      this.router.navigateByUrl("/animals/save")

  }

  groupsUrl() {
    if (this.role) {
      this.appService.getDefaultGroup().subscribe(group => {
        let idFarmer = this.route.snapshot.paramMap.get('idFarmer');
        this.router.navigateByUrl("farmers/" + idFarmer + "/animals/groups")
      })
    }
    else if (this.currentRoute.startsWith('/supervisors')) {
      let idFarmer = this.route.snapshot.paramMap.get('idFarmer');
      this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + idFarmer + "/animals/groups")
    }
    else
      this.router.navigateByUrl("/animals/groups")
  }

  convertToDays(gestDay) {
    if (gestDay)
      return moment().diff(moment(gestDay), "days")
    else return "--";
  }

  convertToYear(birthDate) {
    if (birthDate) {
      let diff = moment().diff(moment(birthDate), "days")
      if (diff >= 30)
        return (Math.floor(diff / 30.416 / 12));
      else return "--";
    }
    else return "--";
    // this.ageMonth = Math.floor((Math.round(diff - (this.ageYear * 12 * 30.4167))) / 30);


  }


  convertToMonth(birthDate) {
    if (birthDate) {
      let diff = moment().diff(moment(birthDate), "days")
      if (diff >= 30) {
        let ageYear = (Math.floor(diff / 30.416 / 12));
        return Math.floor((Math.round(diff - (ageYear * 12 * 30.4167))) / 30);
      }
      else return "--";
    }
    else return "--";


  }

  settingsUrl(animal) {
    if (this.role) {
      if (this.idFarmer == null)
        this.idFarmer = animal.address.customer.id;

      if (this.currentRoute.indexOf('resultSearch') != -1)
        this.router.navigateByUrl("/resultSearch/farmers/" + this.idFarmer + "/animals/" + animal.id + "/settings")
      else
        this.router.navigateByUrl("/farmers/" + this.idFarmer + "/animals/" + animal.id + "/settings")
    }

    else {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + this.idFarmer + "/animals/" + animal.id + "/settings")
      }
      else
        this.router.navigateByUrl("/animals/" + animal.id + "/settings")
    }

  }


  timeLineUrl(animal) {
    if (this.role) {
      if (this.idFarmer != null)
        this.router.navigateByUrl('/farmers/' + this.idFarmer + '/animals/' + animal.id + '/timelines');
      else {
        this.animalService.getById(animal.id).subscribe(res => {
          animal = res;
          this.idFarmer = animal.address.customer.id;
          this.router.navigateByUrl('/farmers/' + this.idFarmer + '/animals/' + animal.id + '/timelines')
        })

      }
    }
    else {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.router.navigateByUrl('supervisors/' + this.supervisorId + '/farmers/' + this.idFarmer + '/animals/' + animal.id + '/timelines')
      }
      else
        this.router.navigateByUrl('/animals/' + animal.id + '/timelines');
    }
    this.appService.verif = false;
    //googleAnalytics
    this.appService.googleAnalyticsAllPages('timeline_animal', animal.uid, animal.name)
    error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
      this.alertsService.open(message, alert);
    }

  }

  groupUrl(animal) {
    let oldGroup: any;
    if (animal.customerAnimalGroups)
      oldGroup = animal.customerAnimalGroups[0].id
    else if (animal.groupId)
      oldGroup = animal.this.animal.groupId
    else
      oldGroup = -1
    if (animal.customerAnimalGroups)
      if (this.role) {
        if (this.idFarmer == null)
          this.idFarmer = animal.address.customer.id;
        this.router.navigateByUrl('/farmers/' + this.idFarmer + '/animals/' + animal.id + '/groups?oldGroup=' + oldGroup);
      }
      else {
        if (this.currentRoute.startsWith('/supervisors')) {
          this.router.navigateByUrl('supervisors/' + this.supervisorId + '/farmers/' + this.idFarmer + '/animals/' + animal.id + '/groups?oldGroup=' + oldGroup)
        }
        else
          this.router.navigateByUrl('/animals/' + animal.id + '/groups?oldGroup=' + oldGroup);
      }
    this.appService.verif = false;
  }
  animalNote(value) {
    let dialogRef
    dialogRef = this.dialog.open(AddWeightPopupComponent, {
      data: {
        animalId: value,
        idFarmer:this.farmerId? this.farmerId : value.farmerId,
        reload: () => {
          this.reload.emit();
          this.getAnimals();
        }

      }

    }
    );
  }

}
