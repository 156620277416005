import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonSettingModule } from '../common-setting/common-setting.module';
import { SectionBarModule } from '../../animals/section-bar/section-bar.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SettingsFormComponent } from './settings-form.component';



@NgModule({
    declarations: [
        SettingsFormComponent,
    ],
    imports: [
        CommonModule,
        CommonSettingModule,
        SectionBarModule,
        TranslateModule,
        RouterModule,
    ],
    exports: [
        SettingsFormComponent
    ]
})
export class SettingsFormModule { }
