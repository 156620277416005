import { Component, OnInit } from '@angular/core';
import { Alert, Authorities } from 'projects/shared/tools/constants';
import { AppService } from 'projects/shared/services/app.service';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { GroupService } from 'projects/shared/services/group.service';
import { ReportService } from 'projects/shared/services/report.service';
import * as moment from 'moment';
import { RealtimeTagService } from 'projects/shared/services/realtime-tag.service';
import { interval } from 'rxjs';
import { TankService } from 'projects/shared/services/tank.service';
import { AlertService } from 'projects/shared/services/alert.service';

@Component({
  selector: 'app-result-search',
  templateUrl: './result-search.component.html',
  styleUrls: ['./result-search.component.scss']
})
export class ResultSearchComponent implements OnInit {
  farmers
  animals = []
  key
  loading
  defaultGroup;
  ids: any=[]
  displayFarmers: boolean = true;
  searchList: any
  idFarmer:any
  startDate: string;
  diffTimezone
  tags: any[] = [];
  defaultGroupId:any;
  tankCards
  tanksEmpty: any
  id
  auth: string[] = [];
  authAdmin: string[] = [];
  administratorCCl
  farmerLength : boolean=false;
  constructor(private alertService: AlertService, public tankService: TankService,public realtimeTagService: RealtimeTagService, public codeTranslate: CodeTranslatePipe, private supervisorService: SupervisorService, public dialog: MatDialog, private location: Location,
    public translate: TranslateService, public reportService: ReportService, private groupService: GroupService, public alertsService: AlertsService, public appService: AppService, public route: ActivatedRoute, public router: Router) {

       }

  ngOnInit() {
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
      this.authAdmin.push((role.adminAccess).toString())
        role['authorityRoles'].forEach(authorityRole => {
  
          if (authorityRole.authority.code == Authorities.TANKS && authorityRole.get == true)
          this.administratorCCl = true
          
        })})
   
    this.diffTimezone = new Date().getTimezoneOffset()
    let todayAtSchedulerHour = moment(new Date()).set({hour:6 -this.diffTimezone/60,minute:0,second:0,millisecond:0});
    
   let today = moment(new Date());
   
    if(today.isAfter(todayAtSchedulerHour))
    this.startDate = (moment().subtract(1, 'days')).set({hour:-this.diffTimezone/60,minute:0,second:0,millisecond:0}).toISOString();
    else
    this.startDate = (moment().subtract(2, 'days')).set({hour:-this.diffTimezone/60,minute:0,second:0,millisecond:0}).toISOString();

    this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');
    this.key = this.route.snapshot.paramMap.get('key');
    if(!this.key)
    this.route.queryParams.subscribe(params => {

      this.key = params.key;
    })
    
    if((this.auth.indexOf("true") == -1 )&& (this.authAdmin.indexOf("true") == -1) && (this.administratorCCl))
    this.loadTankWithKey(this.key)
    else 
    this.loadFramersWithAnimals(this.key)

    this.realtimeTagService.connect((frame) => {
      let sub = interval(1000).subscribe(res => {
        if (this.animals) {
          this.realtimeTagService.subscribeDevices(this.tags)
          sub.unsubscribe();
        }
      })
    });
  }

  loadFramersWithAnimals = (key?) => {
    
    this.farmers = []
    this.animals = []
    this.loading = true
    this.realtimeTagService.unsubscribeDevices();

   // this.location.replaceState("/search/" + key);
    this.appService.showLoading()
    this.appService.getDefaultGroup().subscribe(group => {
      this.defaultGroupId = group
      this.supervisorService.findFarmersAnimalswithKey(key, this.defaultGroupId).subscribe(res => {
        res?.forEach(f => {
          this.ids.push(f.id)
        })
        this.getTempHumidity()
        this.getUnreadAlertCountForSupervisor()
				this.appService.hideLoading();
        this.searchList = this.farmers = res
        if (!res){
          this.displayFarmers = false;
        this.farmerLength=true}
      this.appService.hideLoading()
     // this.loading = false;
      this.farmers = res
      let uuids = []
      res?.forEach(farmer => {
        farmer.addresses.forEach(address => {
          address['animals'].forEach(animal => {
            if (animal.device && !animal.device.active)
            this.tags.push(animal.device);
            uuids.push(animal.uuid)
            if (animal.customerAnimalGroups && animal.customerAnimalGroups.length > 0) {
              animal.customerAnimalGroups.forEach(element => {
                if (element.color != null)
                animal.colorGroup = element.color
              });
            }
            animal.farmerId = farmer.id
            animal.address = address
            this.animals.push(animal)
          });
        })
      })
      this.realtimeTagService.subscribeDevices(this.tags);

      this.displayWellBeing(uuids); 

    }, error => {
      this.loading = false;
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.code);
      let alert = this.translate.instant(Alert.OK)
      this.alertsService.open(message, alert);
    })
  });
  }
  getTempHumidity() {
		this.supervisorService.findTemperatureHumidity(this.ids).subscribe(res => {
			let keys = Object.keys(res);
			keys.forEach(key => {
				this.farmers?.forEach(f => {
					if (f.id == key){
						f.temp = res[key]['temp'];
					f.humidity = res[key]['humidity'];
					f.thi = res[key]['thi'];}
				})


			});
		});
	}
  getUnreadAlertCountForSupervisor(supervisorId?) {
		this.alertService.getUnreadAlertCountForSupervisor(supervisorId).subscribe(data => {
			let keys = Object.keys(data);
			keys.forEach(key => {
				this.farmers?.forEach(f => {
					if (f.id == data[key]['id']) {
						f.unreadAlert = data[key]['count'];
					}
				})
			});

		})

	}

  displayWellBeing(uuids){
    
    this.reportService.getCountStatus(uuids, this.startDate).subscribe((data:any[]) => {
      data.forEach(key => {
        this.animals.forEach(f => {
          if (f.uuid == key.uuid) {
            f.countRumination = key.rumination;
            f.countRest = key.rest;
          }
        })
      });
    })
  }
  loadTankWithKey(key?){
    this.id = this.appService.connectedCustomer.entreprise.id
    this.realtimeTagService.unsubscribeDevices();
    this.appService.showLoading()
    this.tankService.getTankByCode(key,this.id).subscribe(data => {
      this.tankCards = data['resources']
      this.appService.hideLoading()
      this.tanksEmpty = this.tankCards.length == 0;
      this.tags = [];
      this.tankCards.forEach(tank => {
        if (tank.deviceTemp)
          this.tags.push(tank.deviceTemp);
        if (tank.deviceAgitators.length > 0) {
          tank.deviceAgitators.sort((a, b) => {
            if (a.label > b.label) {
              return 1
            } else if (a.label < b.label) {
              return -1
            }
            return 0
          }).forEach(element => {
            this.tags.push(element)
          });
        }
        if (tank.deviceFridges.length > 0) {
          tank.deviceFridges.sort((a, b) => {
            if (a.label > b.label) {
              return 1
            } else if (a.label < b.label) {
              return -1
            }
            return 0
          }).forEach(element => {
            this.tags.push(element)
          });
        }
        if (tank.deviceHumidities.length > 0) {
          tank.deviceHumidities.sort((a, b) => {
            if (a.label > b.label) {
              return 1
            } else if (a.label < b.label) {
              return -1
            }
            return 0
          }).forEach(element => {
            this.tags.push(element)
          });
        }
      })
      this.realtimeTagService.subscribeDevices(this.tags)
    }, error => {
      this.appService.hideLoading();
      let  message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.code);
       let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK )
       this.alertsService.open(message, alert);
    });
  }
}

