import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'projects/shared/services/alert.service';
import { AppService } from 'projects/shared/services/app.service';
import { GroupService } from 'projects/shared/services/group.service';
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { interval } from 'rxjs';
import { Alert, Authorities, SettingsFarmerByLabel, SectionForm } from 'projects/shared/tools/constants';
import { CustomRouter } from '../services/custom-router';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { ButtonDialogComponent } from './button-dialog/button-dialog.component';
import { MarkerService } from 'projects/shared/services/marker.service';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
@Component({
	selector: 'app-farmers',
	templateUrl: './farmers.component.html',
	styleUrls: ['./farmers.component.scss']
})
export class FarmersComponent implements OnInit {

	supervisorId: any;
	farmers: any
	displayFarmers: boolean = true;
	period
	ids: any[] = []
	searchList: any[] = []
	numberAlert: any = 0
	ListIds: any[] = [];
	farmerEmpty: boolean = false
	currentRoute: any;
	authority = Authorities.SUPERVISORS;
	tableView: Boolean = false;
	displayedColumns: string[] = ["name", 'phone', 'setAddress', 'address', 'cowNumber', 'milkQuantity', 't', 'h', 'thi', 'alerts', 'settings'];
	defaultGroupId: any
	verif: Boolean = false;
	idFormTemplate
	relatedTo: String = "farmer"
	list
	projection = "form-template"
	showSettings:Boolean=true;
	constructor(private markerService: MarkerService, private formTemplateService: FormTemplateService, public dialogRef: MatDialogRef<ButtonDialogComponent>, private alertsService: AlertsService, private translate: TranslateService, private route: ActivatedRoute, public router: CustomRouter, private alertService: AlertService, private supervisorService: SupervisorService, private groupService: GroupService, public appService: AppService, public dialog: MatDialog) { }

	ngOnInit() {
		this.currentRoute = this.router.url
		this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
		this.loadFarmers()
		this.period = interval(900000).subscribe(res => {
			if (this.ids)
				this.getTempHumidity()
		})
		this.loadForm()
	}

	back() {
		this.appService.verif = false
		let current = this.router.url;
		if (current.startsWith('/supervisors')) {
			this.router.navigateByUrl("/supervisors")
		}
		else
			this.router.navigateByUrl('/home')
	}

	loadFarmers() {

		this.appService.showLoading();
		this.supervisorService.findFarmers(this.supervisorId).subscribe(res => {
			this.appService.hideLoading();

			let numberOfFarmers = 0;
			this.farmerEmpty = true

			if (res) {
				res.forEach(f => {
					this.ids.push(f.id)
				})
				this.farmers = res
				numberOfFarmers = res.length
				if (numberOfFarmers > 20)
					this.verif = true
				this.farmerEmpty = numberOfFarmers == 0

				this.getTempHumidity()
				if (this.supervisorId)
					this.getUnreadAlertCountForSupervisor(this.supervisorId)
				else
					this.getUnreadAlertCountForSupervisor()
			}

		}, error => {
			this.appService.hideLoading();
			let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
			let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
			this.alertsService.open(message, alert);
		})
	}

	getTempHumidity() {
		this.supervisorService.findTemperatureHumidity(this.ids).subscribe(res => {
			let keys = Object.keys(res);
			keys.forEach(key => {
				this.farmers.forEach(f => {
					if (f.id == key) {
						f.temp = res[key]['temp'];
						f.humidity = res[key]['humidity'];
						f.thi = res[key]['thi'];
					}
				})
			});
		});

	}
	getUnreadAlertCountForSupervisor(supervisorId?) {
		this.alertService.getUnreadAlertCountForSupervisor(supervisorId).subscribe(data => {
			let keys = Object.keys(data);
			keys.forEach(key => {
				this.farmers.forEach(f => {
					if (f.id == data[key]['id']) {
						f.unreadAlert = data[key]['count'];
					}
				})
			});

		})

	}
	addFarmer() {
		const dialogRef = this.dialog.open(ButtonDialogComponent, {
		});
	}

	addLocation(farmer) {
		this.markerService.getUserLocation(farmer.addresses[0])
	}

	showLocation(farmer) {
		let url = 'supervisors/' + this.supervisorId + '/farmers/' + farmer.id + '/map'
		this.router.navigateByUrl(url);
	}

	openAnimals(farmer) {
		this.appService.getDefaultGroup().subscribe(group => {
			this.defaultGroupId = group
			const navigationExtras: NavigationExtras = { state: { farmer: farmer } };
			if (this.currentRoute.startsWith('/supervisors'))
				this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + farmer.id + "/animals/groups/" + this.defaultGroupId, navigationExtras)
			else {
				if (this.currentRoute.indexOf('resultSearch') != -1)
					this.router.navigateByUrl("resultSearch/farmers/" + farmer.id + "/animals/groups/" + this.defaultGroupId, navigationExtras)
				else
					this.router.navigateByUrl("farmers/" + farmer.id + "/animals/groups/" + this.defaultGroupId, navigationExtras)

			}
		})
	}

	openAlerts(farmer) {
		if (this.currentRoute.startsWith('/supervisors'))
			this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + farmer.id + "/alerts")
		else {
			if (this.currentRoute.indexOf('resultSearch') != -1)
				this.router.navigateByUrl("resultSearch/farmers/" + farmer.id + "/alerts"/*, navigationExtras*/)
			else
				this.router.navigateByUrl("farmers/" + farmer.id + "/alerts"/*, navigationExtras*/)

		}
	}

	settingFarmer(farmer) {
		
		if (this.currentRoute.startsWith('/supervisors'))
			this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + farmer.id + "/settings")
		else {
			if (this.currentRoute.indexOf('resultSearch') != -1)
				this.router.navigateByUrl("resultSearch/farmers/" + farmer.id + "/settings")
			else
				this.router.navigateByUrl("farmers/" + farmer.id + "/settings")

		}
	}

	showMilkQuantity(farmer) {
		if(this.showSettings==true){
		this.formTemplateService.findByLabelOfFormTemplate(SettingsFarmerByLabel.MILK_QUANTITY).subscribe(res1 => {
			this.idFormTemplate = res1;
			this.router.navigateByUrl('farmers/' + farmer.id + '/settings/' + this.idFormTemplate)})}

	}
	loadForm() {
    
		this.list = this.appService.loadForm(this.projection,SectionForm.advisor_farmer_settings,this.relatedTo)
		if(this.list.length==0)
			this.showSettings=false;
		
	  }
}