import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AnimalService } from 'projects/shared/services/animal.service';
import { Authorities, LivestockIdentif } from 'projects/shared/tools/constants';
import { AppService } from 'projects/shared/services/app.service';
import { CustomRouter } from '../../services/custom-router';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-animal-bar',
  templateUrl: './animal-bar.component.html',
  styleUrls: ['./animal-bar.component.scss']
})
export class AnimalBarComponent implements OnInit {

  @Input() id
  @Output() goBack = new EventEmitter()
  uid: String
  code: String
  name: any
  colorGroup: String
  authority = Authorities.ANIMALS;
  nationalIdentification
  nameLocalCode
  currentRoute: any;
  isPopUp: boolean = false;
  constructor(public dialogRef: MatDialogRef<AnimalBarComponent>, private router: CustomRouter, public animalService: AnimalService, public appService: AppService) { }

  ngOnInit() {
    this.currentRoute = this.router.url
    if (this.currentRoute.indexOf('reports') != -1)
      this.isPopUp = true;
    else this.isPopUp = false
    this.findAnimal(this.id)
    this.nationalIdentification = LivestockIdentif.NATIONAL_IDENTIFICATION
    this.nameLocalCode = LivestockIdentif.NAME_LOCALCODE
  }

  back() {
    this.findAnimal(this.id);
    this.goBack.emit();
  }

  findAnimal(id) {
    this.animalService.getAnimalById(parseInt(id), "animal-details").subscribe(res => {
      this.uid = res.uid
      this.code = res.code
      this.name = res.name
      if (res.customerAnimalGroups && res.customerAnimalGroups.length > 0) {
        res.customerAnimalGroups.forEach(element => {
          if (element.color != null)
            this.colorGroup = element.color
        });
      }
    })
  }
  closePopUp() {
    this.dialogRef.close();
  }
}
