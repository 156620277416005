import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupCardComponent } from './group-card.component';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { RouterModule } from '@angular/router';
import { LostAnimalsDialogComponent } from './lost-animals-dialog/lost-animals-dialog.component';

@NgModule({
  declarations: [GroupCardComponent, LostAnimalsDialogComponent],
  imports: [
    CommonModule,
    MoomeCommonModule,
    RouterModule,
  ],
exports: [GroupCardComponent]})
export class GroupCardModule { }
