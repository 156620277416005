<div class="row px-4">
    <div class="name-style col mt-3 color-dark-blue">
        <div [ngClass]="{rtl:appService.rtl()}">{{fullname}}</div>
    </div>
</div>
<div class="row px-4 mb-3">
    <div class="phone-style col color-dark-blue">
        <div [ngClass]="{rtl:appService.rtl()}">{{phone}}</div>
    </div>
</div>
<div class="row px-4 {{element.style }}" *ngFor="let element of list">

    <div class="col-12" (click)=" router.navigateByUrl(element.router)"  [ngClass]="appService.rtl()? '' : 'bottom'"
        [getAuthorized]="element.authority">

        <div>
            <div [ngClass]="{rtl:appService.rtl()}" class="d-flex position-relative
            ">
                <div class="align-middle"> <img src={{element.icon}} /></div>
                <div [ngClass]="appService.rtl()? 'style-position-ar' : 'style-position-fr'"><span
                        class="align-middle text-style">{{ element.text |
                        translate }}</span> </div>
            </div>
            <div>
                <hr class="hr-style">
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col  mt-1 mb-4 lang-pos" [ngClass]="{rtl:appService.rtl()}">
        <app-language [value]="selectedLanguage" [color]="color"></app-language>
    </div>
</div>




<div class=" text-footer-style">
    <div class="col mb-1  margin text-center">
        <div >MOOME ALL RIGHTS RESERVED 2024 </div>
    </div>
</div>
