import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import * as moment from "moment"
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { AddWeightPopupComponent } from '../add-weight-popup/add-weight-popup.component';
import { CustomRouter } from '../../services/custom-router';
import { Alert, Authorities, Country, LivestockIdentif } from 'projects/shared/tools/constants';
import { ActivatedRoute } from '@angular/router';
import { Customer } from 'projects/shared/resources/Customer';
import { CustomerService } from 'projects/shared/services/customer.service';
import { AnimalService } from 'projects/shared/services/animal.service';

@Component({
  selector: 'app-animal-card',
  templateUrl: './animal-card.component.html',
  styleUrls: ['./animal-card.component.scss']
})
export class AnimalCardComponent implements OnInit {

  @Input() animal;
  hide: Boolean = false;
  displayCode: Boolean = false;
  code;
  gestDays: any;
  lactDays: any;
  ageMonth: any = "--";
  ageYear: any = "--";
  @Input() signal: any
  @Input() imgBattery: any;
  @Output() reload = new EventEmitter();
  cardType: Boolean = false
  month: any
  authorityGroups = Authorities.CUSTOMER_ANIMAL_GROUPS
  authorityTimelines = Authorities.TIMELINES
  authorityNotes = Authorities.ANIMAL_NOTES
  authority = Authorities.ANIMALS;
  role: any;
  idFarmer: any;
  farmer: Customer = null
  auth: string[] = [];
  currentRoute: any;
  supervisorId: any;
  nationalIdentification
  nameLocalCode
  animalAdgSign: string = "-"
  constructor(public animalService: AnimalService, public customerService: CustomerService, private route: ActivatedRoute, public appService: AppService, public dialog: MatDialog, public alertsService: AlertsService, private translate: TranslateService, public router: CustomRouter,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1)
      this.role = true
  }

  ngOnInit() {
    this.currentRoute = this.router.url
    this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
    this.nationalIdentification = LivestockIdentif.NATIONAL_IDENTIFICATION
    this.nameLocalCode = LivestockIdentif.NAME_LOCALCODE
    this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');

    if (Country.isPakIdn(this.animal.address.country.code))
      this.displayCode = true;
    else
      this.displayCode = false;
    this.gestDays = this.convertToDays(this.animal.gest)
    this.lactDays = this.convertToDays(this.animal.lastLactation)
    this.convertToAge(this.animal.birthDate)
    this.verifCard(this.animal.birthDate)
    // this.verifAge(this.animal.birthDate)
    if (this.data.animal)
      this.animal = this.data.animal

    if (this.animal['customerAnimals']?.length > 0 && this.animal['customerAnimals'][0]?.customer.id == this.appService.connectedCustomer.id)
      this.animal.statusDisabled = true


    /*  this.appService.connectedCustomer['roles'].forEach(role => {
        if (role.isSupervisor == true) {
          this.role = true;
          this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');
  P
        } else
          this.role = false
  
      })*/



  }
  /*getFarmerById(id) {
    let params: HalParam[] = []
    params.push({ key: 'projection', value: 'customers' })
    this.customerService.get(id, params).subscribe(res => {
      this.farmer = res
      this.code = this.farmer.addresses[0].country.code
    })
  }*/
  convertToDays(input) {
    if (input)
      return moment().diff(moment(input), "days")
    else return "--";
  }

  convertToAge(input) {
    if (input) {
      let diff = moment().diff(moment(input), "days")
      if (diff >= 30) {
        this.ageYear = (Math.floor(diff / 30.416 / 12));
        this.ageMonth = Math.floor((Math.round(diff - (this.ageYear * 12 * 30.4167))) / 30);
      }
    }
  }

  animalNote(value) {
    if (this.animal['customerAnimals']?.length > 0 && this.animal['customerAnimals'][0]?.customer.id == this.appService.connectedCustomer.id)
      this.appService.error('DISABLED_ANIMAL')
    else {
      let dialogRef = this.dialog.open(AddWeightPopupComponent, {
        data: {
          animalId: value,
          idFarmer: this.idFarmer ? this.idFarmer : this.animal.farmerId,
          reload: () => {
            this.reload.emit();
          }
        }
      });

    }
    /* error => {
       this.appService.hideLoading();
      let  message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
       let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK )
       this.alertsService.open(message, alert);
     }*/
    /*   dialogRef.afterClosed().subscribe(result => {
   
       }); */
  }

  verifCard(input) {

    let diffDays = moment().diff(moment(input), "days")
    if ((diffDays >= 365) && (this.animal.sexe == 'FEMALE'))
      this.cardType = true
    else if ((input == null) && (this.animal.sexe == 'FEMALE') && (this.animal.lactNum != null))
      this.cardType = true
    else if ((diffDays < 365) || (this.animal.sexe == 'MALE'))
      this.cardType = false

  }
  settingsUrl() {
    if (this.role) {
      if (this.idFarmer == null)
        this.idFarmer = this.animal.address.customer.id;

      if (this.currentRoute.indexOf('resultSearch') != -1)
        this.router.navigateByUrl("/resultSearch/farmers/" + this.idFarmer + "/animals/" + this.animal.id + "/settings")
      else
        this.router.navigateByUrl("/farmers/" + this.idFarmer + "/animals/" + this.animal.id + "/settings")
    }

    else {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + this.idFarmer + "/animals/" + this.animal.id + "/settings")
      }
      else
        this.router.navigateByUrl("/animals/" + this.animal.id + "/settings")
    }

  }

  groupUrl() {
    let oldGroup: any;
    if (this.animal.customerAnimalGroups && this.animal.customerAnimalGroups.length > 0)
      oldGroup = this.animal.customerAnimalGroups[0].id
    else if (this.animal.groupId)
      oldGroup = this.animal.this.animal.groupId
    else
      oldGroup = -1
    if (this.animal.customerAnimalGroups)
      if (this.role) {
        if (this.idFarmer == null)
          this.idFarmer = this.animal.address.customer.id;
        this.router.navigateByUrl('/farmers/' + this.idFarmer + '/animals/' + this.animal.id + '/groups?oldGroup=' + oldGroup);
      }
      else {
        if (this.currentRoute.startsWith('/supervisors')) {
          this.router.navigateByUrl('supervisors/' + this.supervisorId + '/farmers/' + this.idFarmer + '/animals/' + this.animal.id + '/groups?oldGroup=' + oldGroup)
        }
        else
          this.router.navigateByUrl('/animals/' + this.animal.id + '/groups?oldGroup=' + oldGroup);
      }
    this.appService.verif = false;
  }

  timeLineUrl() {
    if (this.role) {
      if (this.idFarmer != null)
        this.router.navigateByUrl('/farmers/' + this.idFarmer + '/animals/' + this.animal.id + '/timelines');
      else {
        this.animalService.getById(this.animal.id).subscribe(res => {
          this.animal = res;
          this.idFarmer = this.animal.address.customer.id;
          this.router.navigateByUrl('/farmers/' + this.idFarmer + '/animals/' + this.animal.id + '/timelines')
        })

      }
    }
    else {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.router.navigateByUrl('supervisors/' + this.supervisorId + '/farmers/' + this.idFarmer + '/animals/' + this.animal.id + '/timelines')
      }
      else
        this.router.navigateByUrl('/animals/' + this.animal.id + '/timelines');
    }
    this.appService.verif = false;
    //googleAnalytics
    this.appService.googleAnalyticsAllPages('timeline_animal', this.animal.uid, this.animal.name)
    error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
      this.alertsService.open(message, alert);
    }

  }
  getSize(value) {
    if (value != null)
      return value.toString().length;
  }

}


