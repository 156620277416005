import { Injectable } from '@angular/core';
import { MoomeRestService, Options } from './moome-rest.service';
import { FarmerMilkProduction } from '../resources/FarmerMilkProduction';
import { map } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class FarmerMilkProductionService extends MoomeRestService<FarmerMilkProduction> {

    constructor(public http: HttpClient, private configService: ConfigService) {
        super(FarmerMilkProduction);
    }

    findByFarmer() {
        let options: Options =
        {
            params: {
                projection: "farmer-milk-producion",
            }
        }
        return this.customRequestGet(`/findAllByFarmer`, null, options).pipe(
            map(res => { return res['_embedded']?.farmerMilkProductions }
            ))
    }

    findByFarmerByKey(key) {
        let options: Options =
        {
            params: {
                key: key,
                projection: "farmer-milk-producion",
            }
        }
        
        return this.customRequestGet(`/findAllByFarmerByKey`, null, options).pipe(
            map(res => { return res['_embedded']?.farmerMilkProductions }
            ))
    }
    

    deleteById(body) {
        return this.delete(body)
    }

    saveFarmerMilkProduction(body) {
		return this.http.post(this.configService.getApiPath() + `/farmerMilkProductions/save`, body);
    }
    findMilkProductionByExisteDate(date){
        return this.http.get(this.configService.getApiPath() + `/farmerMilkProductions/findMilkProductionByExisteDate?date=${date}`);
    }

    countByDateAndGroup(startDate,endDate,listCodeGroups) {
        return this.http.get(this.configService.getApiPath() + `/farmerMilkProductions/countByDateAndGroup?listCodeGroups=${listCodeGroups}&startDate=${startDate}&endDate=${endDate}`);
    }
    updatePriceMilkProduction(ids,price){
        return this.http.post(this.configService.getApiPath() + `/farmerMilkProductions/updatePriceMilkProduction?price=${price}`, ids);
    }

    findMilkPrice(){
        return this.http.get(this.configService.getApiPath() + `/farmerMilkProductions/getPriceMilk`);
    }
	 
}