
<app-bar [element]="bar"></app-bar>
<form [formGroup]="formGroup1">
    <div class="row  px-4 py-3 position">
        <div class="col-12 mx-auto back-style">
            <div class="mt-1 text-center note-style" [ngClass]="{rtl:appService.rtl()}">
                <div>{{ 'FEED_CONSUMPTION_OVERVIEW' | translate}}</div>
            </div>
        </div>
    </div>
    <div class="row  px-4 py-3 position">

        <div class="row " [ngClass]="{rtl:appService.rtl()}">
            <div class="col mb-2 center-element">
                <ion-item class="input-mob-date">

                    <ion-datetime #datePicker mode="ios" class="font-size-text " [ngClass]="{rtl:appService.rtl()}"
                        [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()"
                        placeholder="{{ 'START_DATE'| translate }}" [doneText]="'SAVE' |translate"
                        [cancelText]="'CANCEL' |translate" (ionChange)="fixArabeFormat()"
                        [monthShortNames]="appService.monthShort()" formControlName="startDateFinancial">

                    </ion-datetime>
                    <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar' : 'date'"
                        src="/assets/images/calendar4.svg" />

                </ion-item>
            </div>
        </div>
        <div class="row " [ngClass]="{rtl:appService.rtl()}">
            <div class="col mb-2 center-element ">
                <ion-item class="input-mob-date">

                    <ion-datetime #datePicker mode="ios" class="font-size-text " [ngClass]="{rtl:appService.rtl()}"
                        [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()"
                        placeholder="{{ 'END_DATE'| translate }}" [doneText]="'SAVE' |translate"
                        [cancelText]="'CANCEL' |translate" (ionChange)="fixArabeFormat()"
                        [monthShortNames]="appService.monthShort()" formControlName="endDateFinancial">

                    </ion-datetime>
                    <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar' : 'date'"
                        src="/assets/images/calendar4.svg" />

                </ion-item>
            </div>
        </div>
        <div class="row ">
            <div class="col-6 mb-2 center-element">
                <div class="float-start">
                    <button [ngClass]="!formGroup1.valid?  'opac' : ''"
                        class="button-dialog button-text-size text-center" mat-raised-button color="primary"><span
                            [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'"
                            class="center-element" (click)="calculFeedConsumptionOverview()">{{ 'CONFIRM' | translate }}
                        </span></button>
                </div>
            </div>
        </div>
    </div>

</form>
<div class="row py-2 position center-element" [ngClass]="{rtl:appService.rtl()}">
    <div class="col-6 back-style-financial ">
      
        <div class="note-style-description " *ngFor="let value of listFeedAndQuantity">
            {{value[0] }}({{value[1]}} {{value[2] | translate}})</div>
        
    </div>
</div>

<form [formGroup]="formGroup">
    <div class="row  px-4 py-3 position">
        <div class="col-12 mx-auto back-style">
            <div class="mt-1 text-center note-style" [ngClass]="{rtl:appService.rtl()}">
                <div>{{ 'FEED_CONSUMPTION_DETAILS' | translate}}</div>
            </div>
        </div>
    </div>
   

    <div class="row  px-4 py-3 position">
      <div class="row center-element mt-3 mb-2"  [ngClass]="{rtl:appService.rtl()}">
          <div class="text-center input-mob-message ">{{'ONLY_GROUPS' | translate}}</div>
        </div>
      <div class="row " [ngClass]="{rtl:appService.rtl()}">
          <div class="col mb-2 center-element">
          <mat-select class=" input-height input-width op ps-2 pe-2" disableOptionCentering panelClass="myPanelAnimalClass"
          placeholder="{{ 'CHOOSE' | translate}} &nbsp; {{ 'GROUP' | translate}}" [ngClass]="{rtl:appService.rtl()}" formControlName="group" required>
           
          <mat-option *ngFor="let group of selectedGroups" [value]="group.code" [ngClass]="{rtl:appService.rtl()}" >
              <span *ngIf="labelEn">{{group.labelEn}}</span>
              <span *ngIf="labelFr">{{group.labelFr}}</span>
              <span *ngIf="labelInd">{{group.labelInd}}</span>
              <span [ngClass]="{rtl:appService.rtl()}"*ngIf="labelAr">{{group.labelAr}}</span>
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="row " [ngClass]="{rtl:appService.rtl()}">
          <div class="col mb-2 center-element">
              <ion-item class="input-mob-date">
      
                  <ion-datetime #datePicker mode="ios" class="font-size-text " [ngClass]="{rtl:appService.rtl()}"
                  [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()"
                      placeholder="{{ 'START_DATE'| translate }}" [doneText]="'SAVE' |translate"
                      [cancelText]="'CANCEL' |translate"
                      (ionChange)="fixArabeFormat()"
                      [monthShortNames]="appService.monthShort()"  formControlName="startDate" >
      
                  </ion-datetime>
                  <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar' : 'date'"
                      src="/assets/images/calendar4.svg" />
      
              </ion-item>
          </div>
      </div>
      <div class="row " [ngClass]="{rtl:appService.rtl()}">
          <div class="col mb-2 center-element ">
              <ion-item class="input-mob-date">
      
                  <ion-datetime #datePicker mode="ios" class="font-size-text " [ngClass]="{rtl:appService.rtl()}"
                  [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()"
                      placeholder="{{ 'END_DATE'| translate }}" [doneText]="'SAVE' |translate"
                      [cancelText]="'CANCEL' |translate"
                      (ionChange)="fixArabeFormat()"
                      [monthShortNames]="appService.monthShort()"   formControlName="endDate">
      
                  </ion-datetime>
                  <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar' : 'date'"
                      src="/assets/images/calendar4.svg" />
      
              </ion-item>
          </div>
      </div>
      <div class="row ">
          <div class="col-6 mb-2 center-element">
              <div class="float-start"> 
                  <button [ngClass]="!formGroup.valid?  'opac' : ''" class="button-dialog button-text-size text-center"
                      mat-raised-button color="primary" ><span
                          [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'"
                          class="center-element" (click)="countByDateAndGroup()" >{{ 'CONFIRM' | translate }} </span></button>
              </div>
          </div>
      </div>
      </div>
  </form>
  <div class="row px-4 py-3 position " >
      <app-large-rect-card *ngFor="let element of list" class="col-6  center-element py-3" [element]="element" >
      </app-large-rect-card>
  </div>

