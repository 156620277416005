import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'projects/shared/services/app.service';
import { FarmerFeedConsumptionService } from 'projects/shared/services/farmer-feed-consumption.service';
import { Authorities, SectionForm } from 'projects/shared/tools/constants';
import { CustomRouter } from '../services/custom-router';
import { FarmerMiscInventoryStockService } from 'projects/shared/services/farmer-misc-inventory-stock.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { element } from 'protractor';

@Component({
  selector: 'app-farmer-feed-consumption',
  templateUrl: './farmer-feed-consumption.component.html',
  styleUrls: ['./farmer-feed-consumption.component.scss']
})
export class FarmerFeedConsumptionComponent implements OnInit {

  forms: any = {}
  idFormTemplate
  service
  key
  name;
  section = SectionForm.farmer_feed_consumption
  idFeed
  title
  color = 'color-feed'
  constructor(public tokenManagementService: TokenManagementService, public farmerFeedConsumptionService: FarmerFeedConsumptionService, public farmerMiscInventoryStockService: FarmerMiscInventoryStockService,
    public appService: AppService, public route: ActivatedRoute,
    private router: CustomRouter) {
    const { idFormTemplate, idFeed, title } = this.route.snapshot.params
    this.idFormTemplate = idFormTemplate;
    this.idFeed = idFeed;
    this.service = this.farmerFeedConsumptionService
    this.title = title
    let currentRoute = this.router.url;
    if (currentRoute.indexOf('historyFeedInventory') != -1) {
      this.name = "FEED_INVENTORY"
      this.color = 'color-stock'
    }
    else if (currentRoute.indexOf('historyMiscInventory') != -1) {
      this.name = "MISC_INVENTORY"
      this.service = farmerMiscInventoryStockService;
      this.color = 'color-stock'
    }
    else
      this.name = "CONCENTRATED_FEED"
    if (currentRoute.indexOf('key') == -1) {
      this.getData(this.idFeed)
    }
  }

  ngOnInit(): void {
    this.appService.getFormTemplateFarmerFeedConsumption().subscribe(res => { })
  }

  getDataByKey(element) {
    if (this.router.url.indexOf('history') != -1) {
      this.service.findByFarmerByKey(element.key, this.idFeed).subscribe(res => {
        this.forms = this.appService.groupDataByYearAndMonth(this.appService.organizedData(res, this.appService.dataAddFeedConsumption));
        //this.forms = this.appService.organizedData(res, this.appService.dataAddFeedConsumption);
      }, error => {
        this.appService.error(error.error.error)
      });
    }
    else {
      this.service.findAllByFarmerByGroupOrDate(element.key).subscribe(res => {
        let keys = Object.keys(res).sort((a, b) => {
          return <any>new Date(b) - <any>new Date(a);
        });
        this.forms = [];
        if (this.tokenManagementService.getTranslateLanguage() == "ar")
          keys.forEach(key => this.forms.push({ 'date': key, 'id': key, 'stocks': res[key], 'title': res[key][0]['group']?.labelAr, 'percentFiber': null }))
        else if (this.tokenManagementService.getTranslateLanguage() == "fr")
          keys.forEach(key => this.forms.push({ 'date': key, 'id': key, 'stocks': res[key], 'title': res[key][0]['group']?.labelFr, 'percentFiber': null }))
        else if (this.tokenManagementService.getTranslateLanguage() == "ind")
          keys.forEach(key => this.forms.push({ 'date': key, 'id': key, 'stocks': res[key], 'title': res[key][0]['group']?.labelInd, 'percentFiber': null }))

        else
          keys.forEach(key => this.forms.push({ 'date': key, 'id': key, 'stocks': res[key], 'title': res[key][0]['group']?.labelEn, 'percentFiber': null }))
        this.listFiberAndNoFiber(this.forms)
        this.forms = this.appService.groupDataByYearAndMonth(this.forms)

      })
    }

  }

  getData(id?) {
    if (id) {
      this.service.findByFarmer(id).subscribe(res => {
        this.forms = this.appService.groupDataByYearAndMonth(this.appService.organizedData(res, this.appService.dataAddFeedConsumption));
      }, error => {
        this.appService.error(error.error.error)
      });
    }
    else {
      this.service.find().subscribe(res => {
        let keys = Object.keys(res).sort((a, b) => {
          return <any>new Date(b) - <any>new Date(a);
        });
        this.forms = [];
        if (this.tokenManagementService.getTranslateLanguage() == "ar")
          keys.forEach(key => this.forms.push({ 'date': key, 'id': key, 'stocks': res[key], 'title': res[key][0]['group']?.labelAr, 'percentFiber': null }))
        else if (this.tokenManagementService.getTranslateLanguage() == "fr")
          keys.forEach(key => this.forms.push({ 'date': key, 'id': key, 'stocks': res[key], 'title': res[key][0]['group']?.labelFr, 'percentFiber': null }))
        else if (this.tokenManagementService.getTranslateLanguage() == "ind")
          keys.forEach(key => this.forms.push({ 'date': key, 'id': key, 'stocks': res[key], 'title': res[key][0]['group']?.labelInd, 'percentFiber': null }))
        else
          keys.forEach(key => this.forms.push({ 'date': key, 'id': key, 'stocks': res[key], 'title': res[key][0]['group']?.labelEn, 'percentFiber': null }))
        this.listFiberAndNoFiber(this.forms)

        this.forms = this.appService.groupDataByYearAndMonth(this.forms)

      })

    }
  }

  back() {
    if (this.router.url.indexOf('search') == -1)
      this.router.back('')
    else
      this.router.navigateByUrl('/settings/' + this.idFormTemplate + '/' + Authorities.FARMER_FEED_CONSUMPTION)

  }

  action(item) {
    if ((this.router.url.indexOf('historyFeedInventory') == -1) && (this.router.url.indexOf('historyMiscInventory') == -1))
      this.router.navigateByUrl('/settings/' + this.idFormTemplate + '/' + Authorities.FARMER_FEED_CONSUMPTION + '/save' + (item ? ("/" + (item.id)) : ""))
  }

  listFiberAndNoFiber(result) {
    result.forEach(res => {
      let listFiber = []
    let listNoFiber = []
      let dryMatterNull = res.stocks.some(element => element.dryMatter === null)
      res.stocks.forEach(element => {
        if (element.dryMatter != null) {
          if (element.fiber == false || element.fiber == null)
            listNoFiber.push(element);
          else
            listFiber.push(element)
        }
      });
      res.percentFiber = dryMatterNull ? null : this.calculateFiberPercentage(listFiber, listNoFiber);
    });
  }

  calculateFiberPercentage(listFiber, listNoFiber) {
    let totalQuantityDryFiber = 0;
    let totalQuantityDryNoFiber = 0;
    let percentFiber = 0
    totalQuantityDryFiber = this.calculTotalQuantity(listFiber);
    totalQuantityDryNoFiber += this.calculTotalQuantity(listNoFiber);
    return percentFiber = Math.round(totalQuantityDryFiber * 100 / (totalQuantityDryNoFiber + totalQuantityDryFiber))
  }

  calculTotalQuantity(list) {
    let quantityDry = 0;
    let totalQuantity = 0
    list.forEach(element => {
      quantityDry = (element.quantity * -1 * element.dryMatter) / 100
      totalQuantity += quantityDry;
    });
    return totalQuantity;
  }
}
