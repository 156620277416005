import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { NUMERIC_PATTERN, Type } from 'projects/shared/tools/constants';
import { EditSettingFormComponent, FORM_DATA } from '../edit-setting-form/edit-setting-form.component';
@Component({
  selector: 'app-quality-form',
  templateUrl: './quality-form.component.html',
  styleUrls: ['./quality-form.component.scss']
})
export class QualityFormComponent implements OnInit {
  type = Type
  @Input() formGroup;
  @Input() formDefinition;
  formatArabe
  params
  fieldRemarkQuality
  dateQualityField
  numericPattern=NUMERIC_PATTERN
  constructor(@Inject(FORM_DATA) @Optional() data, public appService: AppService, public tokenManagementService: TokenManagementService, private editSettingFormComponent: EditSettingFormComponent) {
    this.formatArabe = this.appService.verifFormatDate()
    this.fixArabeFormat()
    if (data && data.formGroup) this.formGroup = data.formGroup
    if (data && data.formDefinition) this.formDefinition = data.formDefinition
    this.params = Array.from(new Set(Object.keys(this.formGroup.value)
      .filter(f => f.endsWith("_MORNING") || f.endsWith("_EVENING") || f.endsWith("_BALANCE"))
      .map(f => f.replace(/_MORNING|_EVENING|_BALANCE/, ""))))
    this.dateQualityField = this.formDefinition.formFields.find(f => f.code == 'DATE_QUALITY');
    this.fieldRemarkQuality = this.formDefinition.formFields.find(f => f.code == 'REMARK_QUALITY');
  }

  fixArabeFormat() {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD/MMM/YYYY"
  }

  ngOnInit(): void {
  }
  


  getField(field) {
    //return this.formDefinition.formFields.find(f => f.code == field);

    return this.formGroup.get(field)
    //return this.formGroup.controls[field]
  }
}