import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MilkCollectionCenterCardComponent } from './milk-collection-center-card.component';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [MilkCollectionCenterCardComponent],
  imports: [
    CommonModule,
    MoomeCommonModule,
    TranslateModule,
  ],exports: [
    MilkCollectionCenterCardComponent
  ]
})
export class MilkCollectionCenterCardModule { }
