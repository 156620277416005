import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { Alert } from 'projects/shared/tools/constants';
import { CustomRouter } from '../services/custom-router';

@Component({
	selector: 'app-entry',
	templateUrl: './entry.component.html',
	styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit {
	connectedCustomerSubscription: any;


	constructor(public appService: AppService, public translate: TranslateService, public alertsService: AlertsService, public tokenManagementService: TokenManagementService, private router: CustomRouter) {

		this.connectedCustomerSubscription = this.appService.connectedCustomerUpdated.subscribe(res => {
			this.appService.getConnected().subscribe(customer => {
				
			},
			error => {
				this.tokenManagementService.logout()
				this.router.navigateByUrl('/login')
			 /* let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : 'SAVE_FAIL');
			  let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
			  this.alertsService.open(message, alert);*/
			})
		})
		this.appService.connectedCustomerUpdated.next(null);

		
	}

	ngOnInit() {
	}




}
