import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';
import * as moment from 'moment';
import { GraphComponent } from 'projects/shared/components/graph/graph.component';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AnimalService } from 'projects/shared/services/animal.service';
import { AppService } from 'projects/shared/services/app.service';
import { FarmerService } from 'projects/shared/services/farmer.service';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { ReportService } from 'projects/shared/services/report.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { CapacitorUtils } from 'projects/shared/tools/CapacitorUtils';
import { Alert, FORMAT_MAX_MIN, RUMINATION, SITTING } from 'projects/shared/tools/constants';
import { CustomRouter } from '../services/custom-router';

@Component({
  selector: 'app-milk-feed-report',
  templateUrl: './milk-feed-report.component.html',
  styleUrls: ['./milk-feed-report.component.scss']
})
export class MilkFeedReportComponent implements OnInit {
  formGroup: UntypedFormGroup;
  formatArabe: any
  periods = ["1_DAY", "3_DAYS", "1_WEEK", "1_MONTH"]
  selectedPeriods = ["1_DAY", "3_DAYS", "1_WEEK", "1_MONTH"]
  maxDate: any
  displaygraph = false;
  lineChart: Chart;
  dayTime: String[];
  startDate;
  endDate;
  periodValue
  title: any;
  datasets: any[] = [];
  feed: Number[];
  production: Number[];
  formatString: string
  constructor(public translateService: TranslateService, public formTemplateService: FormTemplateService, public tokenManagementService: TokenManagementService,
    public router: CustomRouter, public appService: AppService, private formBuilder: UntypedFormBuilder,
    public alertsService: AlertsService, public translate: TranslateService, public codeTranslate: CodeTranslatePipe,
    public reportService: ReportService, public animalService: AnimalService) {
    Chart.register(
      ArcElement,
      LineElement,
      BarElement,
      PointElement,
      BarController,
      BubbleController,
      DoughnutController,
      LineController,
      PieController,
      PolarAreaController,
      RadarController,
      ScatterController,
      CategoryScale,
      LinearScale,
      LogarithmicScale,
      RadialLinearScale,
      TimeScale,
      TimeSeriesScale,
      Decimation,
      Filler,
      Legend,
      Title,
      Tooltip,
      SubTitle
    );
  }

  ngOnInit(): void {
    this.formatString = this.translate.instant('DATEPICKER_FORMAT');
    this.createForm()
    this.maxDate = moment(new Date()).format(FORMAT_MAX_MIN);
  }
  back() {
    this.router.back('')
  }
  fixArabeFormat() {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD/MMM/YYYY"
  }
  apply() {
    this.applyGraph();

  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      'period': ['1_WEEK'],
      'startDate': [],
      'endDate': []

    });
    this.formGroup.controls['startDate'].disable();
    this.formGroup.controls['endDate'].disable();
  }
  searchPeriod(value, list) {
    let filter = value.toLowerCase();
    return list.filter(option => this.translate.instant(option).toLowerCase().includes(filter));
  }

  onKeyPeriod(value) {
    this.selectedPeriods = this.searchPeriod(value, this.periods)
  }
  exportCsv() {
    this.periodValue = this.formGroup.controls['period'].value
    this.startDate = this.formGroup.controls['startDate'].value;
    this.endDate = this.formGroup.controls['endDate'].value;
    
    this.appService.showLoading();
    this.setPeriod();
    this.reportService.exportFeedMilkGraphToCsv(this.startDate, this.endDate).subscribe(data => {
      let type = ".csv"
      let blob = new Blob([data], { type: 'text/csv; charset=utf-8'});  
      let filename = this.translate.instant('MILK_FEED_GRAPH')  + moment(new Date()).format('yyyy-MM-DD HH:mm') 
      if (CapacitorUtils.isApp) {
        CapacitorUtils.writeAndOpenFile(blob, filename+type);
        this.appService.hideLoading();
        let message = this.translate.instant('SAVE_FILE_SUCESS');
        this.alertsService.open(message);
      } else {
        this.appService.saveAs(blob, filename, type);
      this.appService.hideLoading();
      }
    },
    error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.code);
      let alert = this.translate.instant(Alert.OK);
      this.alertsService.open(message, alert);
    }
  );
}
  showOptions(event) {
    this.formGroup.controls['period'].setValue(null);
    this.formGroup.controls['startDate'].setValue(null);
    this.formGroup.controls['endDate'].setValue(null);

    if (event.checked) {
      this.formGroup.controls['period'].disable();

      this.formGroup.controls['startDate'].enable();
      this.formGroup.controls['endDate'].enable();
    } else {
      this.formGroup.controls['startDate'].disable();

      this.formGroup.controls['endDate'].disable();
      this.formGroup.controls['period'].enable();
    }
  }
  setPeriod() {
    
    if (this.periodValue != null) {
      let start
      if (this.periodValue == "1_DAY")
        start = moment().subtract(1, 'days');

      if (this.periodValue == "3_DAYS")
        start = moment().subtract(3, 'days');

      if (this.periodValue == "1_WEEK")
        start = moment().subtract(1, 'weeks');

      if (this.periodValue == "1_MONTH")
        start = moment().subtract(1, 'months');
      this.title = start.format(this.formatString) + " -- "
        + moment().format(this.formatString);

      this.startDate = start.toISOString();
      this.endDate = moment().toISOString();
    } else {

      this.title = moment(this.startDate).format(this.formatString) + " -- "
        + moment(this.endDate).format(this.formatString);
      this.startDate = moment(new Date(this.formGroup.controls['startDate'].value)).set({ minute: 0, second: 0, millisecond: 0 }).toISOString();
      this.endDate = moment(this.formGroup.controls['endDate'].value).toISOString();
    }
  }
  applyGraph() {
    if (this.lineChart) {
      this.lineChart.destroy();
      this.displaygraph = false;
    }
    this.periodValue = this.formGroup.controls['period'].value
    this.startDate = this.formGroup.controls['startDate'].value;
    this.endDate = this.formGroup.controls['endDate'].value;
    this.appService.showLoading();
    this.setPeriod();
    this.reportService.getFeedMilkGraph(this.startDate, this.endDate).subscribe((res: any) => {
      this.dayTime = [];
     // this.feed = [];
      this.production = [];
      this.datasets = [];

      let keys = (Object.keys(res)).sort();
      keys.forEach(element => {
        this.dayTime.push(moment(element).format("YYYY/MM/DD"));
        //this.feed.push(res[element]['FEED'] ? res[element]['FEED'] : null);
        this.production.push(res[element]['PRODUCTION'] ? res[element]['PRODUCTION'] : null)

      });
      
      this.datasets.push({
        data: this.production,
        label: this.translate.instant('MILK_PRODUCTION'),
        fill: false,
        borderColor: "blue",
        spanGaps: true
      },
        /*{
          data: this.feed,
          label: this.translate.instant('CONCENTRATED_FEED'),
          fill: false,
          borderColor: "red",
          spanGaps: true
        }*/
      )

      this.displaygraph = true;
      this.appService.hideLoading();
      this.lineChart = new Chart('lineChart', {
        type: 'line',
        data: {
          labels: this.dayTime,
          datasets: this.datasets,
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
            title: {
              text: this.title,
              display: true
            },
          },
  
      }
      })

    }, error => {

      this.appService.hideLoading();
      let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.code);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
      this.alertsService.open(message, alert);
    })
  }


  updateChart(point) {
    this.lineChart.options.elements.point.radius = point.checked ? 0 : 2;
    if (!point.checked) {
      this.lineChart.options.elements.point.hitRadius = 5
      this.lineChart.options.elements.point.hoverRadius = 10
      this.lineChart.options.elements.point.hoverBorderWidth = 2
    }
    this.lineChart.update();
  }



}
