import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'projects/shared/services/app.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { CustomRouter } from '../services/custom-router';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})

export class WelcomeComponent implements OnInit {
  language: String = 'ar'
  Images = null;
  color='color-white'
  SlideOptions = { items: 1, dots: true, nav: false };
  CarouselOptions = { items: 3, dots: true, nav: false };
  selectedLanguage = {};

  constructor(public translate: TranslateService, public appService: AppService, public tokenManagementService: TokenManagementService, private router: CustomRouter,
    private dateAdapter: DateAdapter<Date>) {
    this.appService.hideLoading()
    const l = this.tokenManagementService.getTranslateLanguage()
    if (l) {
      this.language = l;
    this.selectedLanguage=this.appService.chooseLanguage(this.language);
    }}

    ngOnInit() {
      setTimeout(() => {
        this.Images = [{ img: '../assets/images/moome.svg', text1: 'WELCOME_TO', text2: 'MOOME!', button: "NEXT", final: false },
        { img: '../assets/images/Groupe-41.svg', text1: 'MOOME_WILL_HELP_YOU_KEEP', text2: 'YOUR_COWS_HEALTHY', button: "LET_BEGIN", final: true },
       
     ] }, 300);
    }

    next(image) {
      let owl = $('.owl-carousel')
      owl.trigger('next.owl.carousel');

      if (image.final == true) {
        this.router.navigateByUrl("/get-started")
      }
    }


  }
