
<div *ngIf="!empty && !(groupAnimalsEmpty && defaultGroupId == groupId)" class="style-bar-troups w-100" ngxHideOnScroll
    [scrollingElementSelector]="'body'" [propertyUsedToHide]="'top'" [valueWhenHidden]="'-50px'"
    [valueWhenShown]="'90px'">
    <div class="row  h-100">
        <div class="col-12">
            <div *ngIf="role" class="row background-farmer">
                <div class="col-12 center-element text-style">{{fullname}}
                </div>
            </div>
            <div class="row background-troups">
                <div class="col-4 center-element" (click)="back()">
                    <img src="/assets/images/left-arrow-1.svg" />
                </div>
                <div class="col-4 center-element">
                    <div class="row d-block">
                        <div class="col text-center"> <img src="/assets/images/Groupe-156-troups.svg" />
                        </div>
                        <div class="col text-center ps-0 pe-0 pt-1"> <span class="text-style"
                                [ngClass]="{rtl:appService.rtl()}">{{ 'TROUPS' | translate }}</span></div>
                    </div>
                </div>
                <div class="col-4 text-center d-none d-sm-block " *ngIf="verifAnimalsNumber && !tableView">
                    <mat-icon class=" table_icon style-icon"  (click)="(tableView=true)">table_chart</mat-icon>   
               </div>
               <div *ngIf="verifAnimalsNumber && tableView" class="col-4 text-center d-none d-sm-block ">

                <mat-icon class=" table_icon style-icon" (click)="(tableView=false)">
                    view_module</mat-icon>
    
            </div>
                <div class="col center-element">
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="empty || (groupAnimalsEmpty && defaultGroupId == groupId)">
    <app-animals-empty [getAuthorized]="authorityAnimals"></app-animals-empty>
</div>

<div *ngIf="searchAnimalsEmpty" class="mt-5">
    <app-no-animals [text]="'NO_ANIMALS'" [value]="value" [getAuthorized]="authorityAnimals"></app-no-animals>
</div>

<div *ngIf="groupAnimalsEmpty && defaultGroupId != groupId" class="mt-5">
    <app-no-animals [text]="'NO_GROUP_ANIMALS'" [value]="value" [getAuthorized]="authorityAnimals"></app-no-animals>
</div>

<div *ngIf="!groupAnimalsEmpty && !tableView" >
    <div class="row px-2 pb-5 position" [ngClass]="role? 'pt-5' : ''">
        <app-animal-card *ngFor="let animal of animals" [animal]="animal" [signal]="animal.device?.active"
            [getAuthorized]="authorityAnimals" (reload)="getAnimals()" [imgBattery]="appService.checkImgBattery(animal.device)"
            class="col-12 col-sm-6 col-md-6 col-lg-4 mt-2 mb-2">
        </app-animal-card>
    </div>
</div>

<div *ngIf="!empty && !(groupAnimalsEmpty && defaultGroupId == groupId)" class="fixed-bar-mob fixed-bar-height"
    ngxHideOnScroll [scrollingElementSelector]="'body'" [propertyUsedToHide]="'bottom'" [valueWhenHidden]="'-50px'"
    [valueWhenShown]="'0px'">
    <div class="row">
        <div class="col" [getAuthorized]="authorityGroups"><img src="/assets/images/Groupe-428.svg"
                (click)="groupsUrl()" />
        </div>
        <div class="col" [createAuthorized]="authorityAnimals">
            <img src="/assets/images/Groupe-429.svg" class="float-end"
                (click)="addAnimalUrl()" />
        </div>
    </div>
</div>









<div  #target>
<div class=" px-2 pb-5 position" [ngClass]="role? 'pt-5' : ''" style="overflow-y: hidden !important; top: 150px " *ngIf="tableView">

    <div class="containerTab mat-elevation-z8">
		<table mat-table [dataSource]="animals"  [ngClass]="{rtl:appService.rtl()}">

			<ng-container matColumnDef="signal" >
				<th mat-header-cell *matHeaderCellDef> {{ 'SIGNAL' | translate}}    </th>
                <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{rtl:appService.rtl()}"> 
                    <div *ngIf="element.device?.active && element.device">
                    <img src="{{appService.checkImgBattery(element.device)}}" class="imb-bat"  [ngClass]="appService.rtl()? 'ms-1' : 'me-1'" >
                    <img src="/assets/images/Tracé-836.svg" />
                </div>
                <div *ngIf="!element.device?.active && element.device">
                    <img src="{{appService.checkImgBattery(element.device)}}" class="imb-bat "  [ngClass]="appService.rtl()? 'ms-1' : 'me-1'">
                    <img src="/assets/images/Tracé-848.svg" />
                </div>
                <div *ngIf="!element.device?.active && !element.device">
                    <img src="/assets/images/Tracé-750.svg" class="imb-bat"  [ngClass]="appService.rtl()? 'ms-1' : 'me-1'" />
                    <img src="/assets/images/Tracé-847.svg" />
                </div> </td>
			</ng-container>

            <ng-container matColumnDef="group" class="center-element">
				<th mat-header-cell *matHeaderCellDef > {{ 'GROUP' | translate}}
				</th>
                <td mat-cell *matCellDef="let element" class="center-element" style="height: 5pc;">                 
                    <div  (click)="groupUrl(element)" class="dot"  [ngStyle]="{'background-color':element.colorGroup}" ></div>
            </td>
			</ng-container>

			<ng-container matColumnDef="uid">
				<th mat-header-cell *matHeaderCellDef > {{ 'UID' | translate}} / {{ 'NAME' | translate}}
				</th>
                <td mat-cell *matCellDef="let element" class=" text-center" style="width: 20px;">  
                    <div [getAuthorized]="authority" > {{appService.valueUid == nationalIdentification? (element.uid? element.uid :element.name) : (appService.valueUid == nameLocalCode? (element.name? element.name : element.uid) : (element.uid? element.uid :element.name))}}</div>
            </td>
			</ng-container>

			
			

			<ng-container matColumnDef="sex">
				<th mat-header-cell *matHeaderCellDef > {{ 'SEX' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="text-center ms-2 me-2"> {{element.sexe}} </td>

			</ng-container>
            <ng-container matColumnDef="lactNumber" >
				<th mat-header-cell *matHeaderCellDef> {{'CALVINGS' | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
            <div class="min-card width-card blue-color" [getAuthorized]="authorityTimelines" (click)="timeLineUrl(element); appService.verif=false">
                <div class="text-center ">
                    <div class="d-inline pt-1 pe-1 ps-1"><span class="nbr-style">{{element.lactNum}}</span></div>
                </div>

               
            </div>
        </td>				
    </ng-container>

			<ng-container matColumnDef="lactDays" >
				<th mat-header-cell *matHeaderCellDef> {{ 'LACTATION' | translate}} </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="text-center  color">
                        <div class="d-inline pe-1 ps-1" (click)="timeLineUrl(element); appService.verif=false"><span class="nbr-style">{{convertToDays(element.lastLactation)}}</span></div>
                        <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span class="exp-unit">{{ 'DAYS' | translate }}</span></div>
                    </div>
                </td>				
			</ng-container>

		

			<ng-container matColumnDef="age" >
				<th mat-header-cell *matHeaderCellDef > {{ 'Age' |translate }}</th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="min-card width-card blue-color">
                        <div   class="text-center ">
                            <div  class="d-inline pe-1 ps-1"><span class="nbr-style" [ngClass]="element.sexe == 'MALE' ? 'color-male' : 'color-femelle'">{{convertToYear(element.birthDate)}}</span></div>
                            <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span [ngClass]="element.sexe == 'MALE' ? 'color-male' : 'color-femelle'" class="exp-unit">{{ convertToYear(element.birthDate) >1 ? ('YEARS'| translate ) :('YEAR' | translate) }}</span></div>
                        </div>
                        <div class="mt-3 text-center" >
                            <div class="d-inline pe-1 ps-1" ><span class="nbr-style" [ngClass]="element.sexe == 'MALE' ? 'color-male' : 'color-femelle'">{{convertToMonth(element.birthDate)}}</span></div>
                            <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span [ngClass]="element.sexe == 'MALE' ? 'color-male' : 'color-femelle'" class="exp-unit">{{ convertToMonth(element.birthDate) >1 ? ('MONTHS'| translate ) :('MONTHH' | translate)}}</span></div>
                        </div>
                    </div>
                     </td>

				
			</ng-container>
			<ng-container matColumnDef="weight"  >
				<th mat-header-cell *matHeaderCellDef > {{ 'WEIGHT' |translate }}</th>
                <td mat-cell *matCellDef="let element" (click)="animalNote(element.id)"> 
                    <div class="text-center ms-3 me-3" >
                        <div class="d-inline pe-1 ps-1"><span class="nbr-style"> {{element.weight? element.weight :
                                '--'}}</span></div>
                        <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span  class="exp-unit">{{ 'KG' | translate }}</span></div>
                    </div>
                </td>

				
			</ng-container>
			<ng-container matColumnDef="gest" >
				<th mat-header-cell *matHeaderCellDef> {{ 'GESTATION' |translate }}</th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="text-center" (click)="timeLineUrl(element); appService.verif=false">
                        <div class="d-inline pe-1 ps-1"  ><span class="nbr-style"> {{convertToDays(element.gest)}}</span></div>
                        <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span  class="exp-unit">{{ 'DAYS' | translate }}</span></div>
                    </div>
                    </td>

				
			</ng-container>

			

			<ng-container matColumnDef="rumination">
				<th mat-header-cell *matHeaderCellDef> {{ 'RUMINATING' | translate}} </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="text-center ms-3 me-4">
                        <div class="d-inline pe-1 ps-1"><span  class="nbr-style " [ngClass]="appService.changeColorRumination(element.countRumination)">{{element.countRumination? element.countRumination : '--'}}</span></div>
                        <div  [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span class="exp-unit" [ngClass]="appService.changeColorRumination(element.countRumination)">{{ 'MIN' | translate }}</span></div>
                    </div>
                </td>

			</ng-container>

            <ng-container matColumnDef="repos">
				<th mat-header-cell *matHeaderCellDef> {{ 'REST' | translate}} </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="text-center ms-3">
                        <div class="d-inline pe-1 ps-1"><span  class="nbr-style " [ngClass]="appService.changeColorResting(element.countRest)">{{element.countRest? element.countRest : '--'}}</span></div>
                        <div  [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span class="exp-unit" [ngClass]="appService.changeColorResting(element.countRest)">{{ 'MIN' | translate }}</span></div>
                    </div>    
                </td>

			</ng-container>

            <ng-container matColumnDef="gmq">
				<th mat-header-cell *matHeaderCellDef> {{ 'ADG' | translate}} </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="text-center ms-3 me-3">
                        <div class="d-inline pe-1 ps-1"><span class="nbr-style">{{element.adg ? element.adg : element.adg=='0' ? '0' :'--'}}</span></div>
                        <div  [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span  class="exp-unit">{{ 'G' | translate }}</span></div>
                    </div>    
                </td>

			</ng-container>

            <ng-container matColumnDef="settings">
				<th mat-header-cell *matHeaderCellDef> {{ 'SETTINGS' | translate}} </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="center-element" (click)="settingsUrl(element)" (click)="appService.verif=false"><img
                        src="/assets/images/gear-option.svg"/></div>
           
                </td>

			</ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

		</table>
		
	</div>





</div>
</div>