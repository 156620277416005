
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MoomeRestService, Options } from './moome-rest.service';
import { Tank } from "../resources/Tank";
import { Species } from '../resources/Species';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
	providedIn: 'root'
})
export class TankService extends MoomeRestService<Tank> {


	constructor(public http: HttpClient, injector: Injector, private configService: ConfigService) {
		super(Tank);
	}

	saveTank(body) {
		return this.http.post(this.configService.getApiPath() + `/tanks/save`, body);
	}

	getTanksWithFilter(page, size, key,id) {
		let param: any =
		{
			projection: 'tanks',
			
		}
		if (key != null)
			param.key = key.toLowerCase();
			if (id != null)
			param.id = id;


		let options: Options = {
			params:
				param
			,
			pageParams: {
				page:page,
				size:size
			}
		}

		return this.searchPages(`getTanksByKey`, options)
	}

	findDevicesType(type) {
		let options: Options = {
			params: 
				{  projection: "code_device_proj" ,
				 type: type }	
			
		}
		return (this.search(`devicesType`, options) as Observable<any>) as Observable<Species>
	}

	findByEntreprise(id) {
		return this.search(`getByEntreprise?id=${id}&projection=tank-card`)
	}
	getTankByCode(code,id){
	return this.search(`getTankByCode?code=${code}&id=${id}&projection=tank-card`)}

}