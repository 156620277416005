import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { AppService } from 'projects/shared/services/app.service';
import { CustomRouter } from '../services/custom-router';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { CustomerService } from 'projects/shared/services/customer.service';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { Authorities, SETTING_PATTERN, TypeAlert} from 'projects/shared/tools/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  key: any
  type: any
  serchTimeout: any;
  @Output()
  searchWithKey: EventEmitter<any> = new EventEmitter();
  router
  role = false;
  auth: string[] = [];
  idSupervisor:any;
  farmerId:any;
  idFormTemplate:any;
  customer:any;
  authAdmin:string[] = [];
  administratorCCl
  advisorCCl
  advisor
  code:any
  constructor( private route: ActivatedRoute,public alertsService: AlertsService, private translate: TranslateService, public formTemplateService:FormTemplateService,public dialog: MatDialog, router: CustomRouter, public appService: AppService,public customerService: CustomerService) {
    this.router = router
  }

  ngOnInit() {
    this.appService.connectedCustomer['roles'].forEach(role => {
    this.auth.push((role.isSupervisor).toString())
    this.authAdmin.push((role.adminAccess).toString())
      role['authorityRoles'].forEach(authorityRole => {

        if (authorityRole.authority.code == Authorities.TANKS && authorityRole.get == true)
        this.administratorCCl = true
        if (authorityRole.authority.code == Authorities.ENTREPRISES && authorityRole.get == true)
        this.advisorCCl = true
        if (authorityRole.authority.code == Authorities.FARMERS && authorityRole.get == true)
        this.advisor = true
        
      })})
      if(this.auth.indexOf("true") != -1)
        this.role=true;
    
  }

  home() {
    this.router.back('/home');
  }

  searchElements(key) {
    if (this.router.url.startsWith("/alerts")  ) {
      let route = this.router.url.split("/search")[0]
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigateByUrl(route + '/search/' + key));

    } else if( (this.router.url.indexOf("/settings") != -1)||(this.router.url.indexOf("stock") != -1) ){
      let route

      if (this.router.url.indexOf("/save") != -1)
        route = this.router.url.split("/save")[0]
      else
        route = this.router.url.split("/search")[0]

      if (key.indexOf("/") != -1) {
        let indice = "/"
        if(this.router.url.indexOf("notes") != -1 ||
        this.router.url.indexOf(Authorities.FARMER_MILK_PRODUCTION) != -1
        ||  this.router.url.indexOf(Authorities.FARMER_FEED_CONSUMPTION) != -1
        ||  this.router.url.indexOf("stock") != -1)
        indice = "-"

        var re = new RegExp(SETTING_PATTERN);
        if ((re.test(key)))
          this.appService.searchSettingsFarmer(key)
        else {
          if (key.length == 10)
            key = moment(key).format("YYYY" + indice + "MM" + indice + "DD")
          else if (key.length == 7)
            key = moment(key).format("YYYY" + indice + "MM")
          else if (key.length == 5)
            key = moment(key).format("MM" + indice + "DD")
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigateByUrl(route + '/search?key=' + key));
        }

      } else {

       
          if (this.router.url.indexOf("/pedigree") != -1){
          const navigationExtras: NavigationExtras = { state: { value: key } }
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigateByUrl(this.role? '/farmers/animals/groups/-1/resultSearch/' + key :'/animals/groups/-1/search/' + key, navigationExtras),
          );
          this.appService.verif=false}
          else{
            // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigateByUrl(route + '/search?key=' + key)
          //);
          }
      }
    }
    else if (key.indexOf("/") != -1) {
      this.appService.searchSettingsFarmer(key)
      this.appService.afterClick()
    }
    else if(((this.advisorCCl)&&(!this.advisor))){
      const navigationExtras: NavigationExtras = { state: { value: key } }
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigateByUrl( '/mcc/' + key+'/tanks' , navigationExtras),
      );
      this.appService.verif=false
    }
    else if((this.administratorCCl) && (!this.advisor)){
      const navigationExtras: NavigationExtras = { state: { value: key } }
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigateByUrl('/tanks/' + key, navigationExtras),
      );
      this.appService.verif=false
    }

    else  {
      const navigationExtras: NavigationExtras = { state: { value: key } }
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigateByUrl(this.role? '/farmers/animals/groups/-1/resultSearch/' + key :'/animals/groups/-1/search/' + key, navigationExtras),
          );
          this.appService.verif=false
    }
    
  }
  closePopup() {
    if (this.dialog.openDialogs && this.dialog.openDialogs.length)
      this.dialog.closeAll();
  }

  functionSearch(){
    let length =this.router.url.length
    this.code = this.router.url.substring(length -2, length);
    if(this.router.url == '/menu')
    this.router.navigateByUrl('/home')
    else{
      if(this.appService.verif){
        this.appService.verif=false;
        }
      else{
        this.appService.verif=true;
       
        this.appService.connectedCustomer['roles'].forEach(role => {
          this.auth.push((role.isSupervisor).toString())
        })
         if(((this.auth.indexOf("true") == -1 )&& (this.authAdmin.indexOf("true") == -1) && (this.administratorCCl))||((this.router.url.indexOf("mcc") != -1)&&(!this.advisorCCl))||(this.router.url.indexOf("tanks") != -1)){
          this.appService.searchText='TANK_NUMBER'
         }
        else if(this.auth.indexOf("true") != -1) {
           if(((this.advisorCCl)&&(!this.advisor))||((this.advisorCCl)&&(this.router.url.indexOf("mcc") != -1))){
            this.appService.searchText='MILK_COLLECTION_CENTERS_TANK_NUMBER'}
            
          else if((this.router.url.indexOf("alerts") != -1)&&(!TypeAlert.checkIfTankAlertByCode(this.code)))
             this.appService.searchText = 'search_by_uid'
             else if((this.router.url.indexOf("alerts") != -1)&&(TypeAlert.checkIfTankAlertByCode(this.code)))
             this.appService.searchText = 'TANK_NUMBER'
          else if((this.router.url.indexOf("settings/") != -1)&&(this.router.url.indexOf("pedigree") == -1))
            this.appService.searchText='serach_note'
            else
            this.appService.searchText = 'search_by_uid_name_phone_farmer_settings'
           
          }
          else {
            if(((this.router.url.indexOf("settings/") != -1)&&(this.router.url.indexOf("pedigree") == -1)&&((this.router.url.indexOf("settings/search") == -1))))
            this.appService.searchText='serach_note'
           else if((this.router.url.indexOf("stock/") != -1))
           this.appService.searchText='SEARCH_QUANTITY_TITLE'
            else 
           this.appService.searchText = 'search_by_uid'

          }
      }
    }
  }

 

}

