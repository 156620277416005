import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { CustomerAnimalGroupsService } from 'projects/shared/services/customer-animal-groups.service';
import { GroupService } from 'projects/shared/services/group.service';
import { Alert, Sexe, Group, GroupCode, Authorities } from 'projects/shared/tools/constants';
import { CommonDialogComponent } from '../../../common-dialog/common-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AnimalService } from 'projects/shared/services/animal.service';
import { CustomRouter } from '../../../services/custom-router';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import * as moment from "moment"
import { LostAnimalsDialogComponent } from './lost-animals-dialog/lost-animals-dialog.component';
import { LocalNotificationService } from 'projects/shared/services/local-notification.service';
import { CustomerAlertService } from 'projects/shared/services/customer-alert.service';

@Component({
  selector: 'app-group-card',
  templateUrl: './group-card.component.html',
  styleUrls: ['./group-card.component.scss']
})
export class GroupCardComponent implements OnInit {
  @Input() group: any;
  @Input() animalId;
  @Input() oldGroup;
  @Output() reload = new EventEmitter();
  oldGroupLabels: any
  text: any
  sexAnimal: any
  router
  lostGroupData: any;
  role: Boolean = false;
  defaultGroupId
  idFarmer: any;
  auth: string[] = [];
  currentRoute: any;
  supervisorId: any
  authorityGroups = Authorities.CUSTOMER_ANIMAL_GROUPS
  valueGroup:any;
  constructor(public customerAlertService: CustomerAlertService, public localNotificationService: LocalNotificationService, public dialogRef: MatDialogRef<LostAnimalsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private route: ActivatedRoute, public groupService: GroupService, public animalService: AnimalService, public codeTranslate: CodeTranslatePipe, private sanitizer: DomSanitizer, public appService: AppService, private alertsService: AlertsService, router: CustomRouter, public dialog: MatDialog, private translate: TranslateService, public customerAnimalGroupsService: CustomerAnimalGroupsService) {
    this.router = router
  }

  ngOnInit() {
    this.currentRoute = this.router.url;
    this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
    this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');
    this.defaultGroupId = this.appService.defaultGroupId
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1) {
      this.role = true;
    }

  }

  transform(url) {
    if (url)
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  troups(id, labelEn?) {

    const navigationExtras: NavigationExtras = { state: { value: this.codeTranslate.transform(this.group) } };
    if (this.role == true) {
      let idFarmer = this.route.snapshot.paramMap.get('idFarmer');
      if (!idFarmer)
        idFarmer = this.data.idFarmer;
      if (labelEn) {
        if(!(Group.isChildsGroup(this.group.code ))){
          this.router.navigateByUrl('/farmers/' + idFarmer + '/animals/groups/' + id + "/" + labelEn, navigationExtras)
          this.dialog.closeAll();
        }
        else {

          const dialogRef = this.dialog.open(LostAnimalsDialogComponent, {
            data:
              { group: this.group, animalId: this.animalId, idFarmer: this.idFarmer }
          })
        }
      }
      else
        this.router.navigateByUrl('/farmers/' + idFarmer + '/animals/groups/' + id, navigationExtras)

    }
    else {
      if (labelEn) {
        if(!(Group.isChildsGroup(this.group.code ))) {
          if (this.currentRoute.startsWith('/supervisors')) {
            if (!this.supervisorId) {
              this.supervisorId = this.data.idSupervisor
            }
            if (!this.idFarmer) {
              this.idFarmer = this.data.idFarmer
            }
            this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + this.idFarmer + "/animals/groups/" + id + "/" + labelEn)
          }
          else { this.router.navigateByUrl('/animals/groups/' + id + "/" + labelEn, navigationExtras) }
          this.dialog.closeAll();
        }
        else {

          const dialogRef = this.dialog.open(LostAnimalsDialogComponent, {
            data:
              { group: this.group, animalId: this.animalId, idFarmer: this.idFarmer, idSupervisor: this.supervisorId }
          })

        }
      }
      else {
        if (this.currentRoute.startsWith('/supervisors')) {
          this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + this.idFarmer + + "/animals/groups/" + id + "/" + labelEn)
        }
        else {
          this.router.navigateByUrl('/animals/groups/' + id, navigationExtras)
        }
      }
    }
  }

  findOldGroup() {
    if (this.oldGroup && this.oldGroup != -1) {
      this.groupService.getGroupById(parseInt(this.oldGroup)).subscribe(res => {
        this.oldGroupLabels = res
        this.belongToGroup(this.group)
      })
    }
    else {
      this.oldGroupLabels = { "labelFr": "Tout le troupeau", "labelEn": "All Herd", "labelAr": "كل القطيع","labelInd": "Semua Kawanan", }
      this.belongToGroup(this.group)
    }
  }

  belongToGroup(group) {
    if (group.clickable) {
      if(this.animalId){
      this.animalService.getAssignGroupValue(parseInt(this.animalId)).subscribe(res1 => {
        this.valueGroup=res1
      })}
      this.animalService.getAnimalById(parseInt(this.animalId), "animal-details3").subscribe(res => {
        this.sexAnimal = res.sexe
        if (this.sexAnimal == Sexe.FEMALE)
          this.text = "COW-F"
        else
          this.text = "COW-M"
        let diff = moment().diff(moment(res.birthDate), "days")
        let month = (Math.floor(diff / 30.4167));
        if (!(Group.isChildsGroup(this.group.code ))) {
          const dialogRef = this.dialog.open(CommonDialogComponent, {

            data: {
              message: this.translate.instant('CONFIRM_ASSIGN_ANIMAL') + " " + this.translate.instant(this.text) + " " + this.translate.instant('FROM') + " ",

              message1: this.codeTranslate.transform(this.oldGroupLabels),
              message2: this.translate.instant('TO'),
              message3: this.codeTranslate.transform(group),
              title: this.translate.instant('CHANGE_GROUP'),
              onConfirm: () => {
                if (res['customerAnimals'].length > 0 && res['customerAnimals'][0].customer.id == this.appService.connectedCustomer.id)
                    this.appService.error('DISABLED_ANIMAL')
                else {
                  if (this.sexAnimal == Sexe.MALE && !Group.isSickLostSold(group.code)) {

                    let message = this.translate.instant('CHECK_GENDRE');
                    let alert = this.translate.instant(Alert.OK)
                    return this.alertsService.open(message, alert);
                  }
                  if(((Group.isSickLostSoldGroup(this.oldGroupLabels.code)))&&this.valueGroup==null && !Group.isProdNotInsemDry(this.group.code)){
                    let message = this.translate.instant('CHECK_GROUP') + " " + this.codeTranslate.transform(this.oldGroupLabels) + " " + this.translate.instant('TO') + " " + this.codeTranslate.transform(group) + " " ;
                    let alert = this.translate.instant(Alert.OK)
                    return this.alertsService.open(message, alert);
                  }
                  if(((Group.isSickLostSoldGroup(this.oldGroupLabels.code)))&&this.group.code!= this.valueGroup &&this.valueGroup!=null  ){
                    let message = this.translate.instant('CHECK_GROUP') + " " + this.codeTranslate.transform(this.oldGroupLabels) + " " + this.translate.instant('TO') + " " + this.codeTranslate.transform(group) + " " ;
                    let alert = this.translate.instant(Alert.OK)
                    return this.alertsService.open(message, alert);
                  }
                  if (res.lactNum > 0 && Group.isHeifersCalves(group.code)&& !Group.isProdDry(this.oldGroupLabels.code)) {
                    let message = this.translate.instant('CHECK_LACT_NUM');
                    let alert = this.translate.instant(Alert.OK)
                    return this.alertsService.open(message, alert);
                  }
                  if (res.lactNum > 0 && Group.isHeifersCalves(group.code)&& Group.isProdDry(this.oldGroupLabels.code)) {
                    let message = this.translate.instant('CHECK_GROUP_INSEM');
                    let alert = this.translate.instant(Alert.OK)
                    return this.alertsService.open(message, alert);
                  }
                  if(this.sexAnimal==Sexe.FEMALE && month>=6 && month<12 && !Group.isHeif612(this.group.code)){
                    let message = this.translate.instant('CHECK_AGE_COW');
                    let alert = this.translate.instant(Alert.OK)
                    return this.alertsService.open(message, alert);
                  }
                  if(this.sexAnimal==Sexe.FEMALE && month<6 && month>=0 && Group.isCalve(this.oldGroupLabels.code) && !Group.isSickLostSoldGroup(this.group.code)){
                    let message = this.translate.instant('CHECK_AGE_COW');
                    let alert = this.translate.instant(Alert.OK)
                    return this.alertsService.open(message, alert);
                  }
                  if ((Group.isNotHeif612(this.oldGroupLabels.code)&& this.sexAnimal == Sexe.FEMALE ) &&(!Group.isSickLostSoldGroup(this.group.code)) ) {
                    let message = this.translate.instant('CHECK_GROUP_INSEM');
                    let alert = this.translate.instant(Alert.OK)
                    return this.alertsService.open(message, alert);
                  }
                 if (Group.isProdDry(group.code) && this.sexAnimal == Sexe.FEMALE && month <= 12) {
                    let message = this.translate.instant('CHECK_GROUP') + " " + this.codeTranslate.transform(this.oldGroupLabels) + " " + this.translate.instant('TO') + " " + this.codeTranslate.transform(group) + " " 
                    let alert = this.translate.instant(Alert.OK)
                    return this.alertsService.open(message, alert);
                  }
                  if (  (Group.isFattening(group.code) && this.sexAnimal == Sexe.MALE && ((month < 3) ))||((Group.isCalve(group.code) && this.sexAnimal == Sexe.MALE && ((month > 3) )))) {
                    let message = this.translate.instant('CHECK_GROUP') + " " + this.codeTranslate.transform(this.oldGroupLabels) + " " + this.translate.instant('TO') + " " + this.codeTranslate.transform(group) + " " + this.translate.instant('PLEASE_CHECK_AGE');
                    let alert = this.translate.instant(Alert.OK)
                    return this.alertsService.open(message, alert);
                  }
                 if (Group.isDry(this.oldGroupLabels.code) &&(!Group.isSickLostSoldGroup(this.group.code)) ) {
                  let message = this.translate.instant('CHECK_GROUP') + " " + this.codeTranslate.transform(this.oldGroupLabels) + " " + this.translate.instant('TO') + " " + this.codeTranslate.transform(group) + " " ;
                  let alert = this.translate.instant(Alert.OK)
                  return this.alertsService.open(message, alert);
                  }
                  if ((Group.isDry(this.group.code)) && (Group.isSickLostSoldGroup(this.oldGroupLabels.code)&& month>=6 && month<=12 && this.sexAnimal==Sexe.FEMALE) ) {
                    let message = this.translate.instant('CHECK_GROUP') + " " + this.codeTranslate.transform(this.oldGroupLabels) + " " + this.translate.instant('TO') + " " + this.codeTranslate.transform(group) + " " ;
                  let alert = this.translate.instant(Alert.OK)
                  return this.alertsService.open(message, alert);
                  }
                 
                  this.appService.showLoading();
                  if (group.id != -1 && this.oldGroup != -1) {
                    this.customerAnimalGroupsService.detachAnimal(this.animalId, this.oldGroup).subscribe(data => {
                      this.customerAnimalGroupsService.saveAnimalGroup({ animalId: parseInt(this.animalId), groupId: parseInt(group.id) }).subscribe(data => {
                        this.success()
                      }, error => {
                        this.error(error)
                      })
                    }, error => {
                      this.error(error)
                    })
                  }
                  else if (group.id == -1 && this.oldGroup != -1) {
                    this.customerAnimalGroupsService.detachAnimal(parseInt(this.animalId), parseInt(this.oldGroup)).subscribe(data => {
                      this.success()
                    }, error => {
                      this.error(error)
                    })
                  }
                  else {
                    this.customerAnimalGroupsService.saveAnimalGroup({ animalId: parseInt(this.animalId), groupId: parseInt(group.id) }).subscribe(data => {
                      this.success()
                    }, error => {
                      this.error(error)
                    })
                 }
                }
              }

            }

          });
        }
        else {
          const dialogRef = this.dialog.open(LostAnimalsDialogComponent, {
            data:
              { group: this.group, animalId: this.animalId, idFarmer: this.idFarmer }
          })
        }

      })

    }
  }

  success() {
    let message: string;
    this.appService.hideLoading();
    message = this.translate.instant('SAVE_SUCESS');
    // this.reload.emit();
    this.troups(this.defaultGroupId)
    this.dialog.closeAll();
    this.alertsService.open(message);
    setTimeout(() => {
      this.customerAlertService.sendNotif(this.animalId).subscribe()
    }, 3000);
  }

  error(error) {
    let message: string;
    this.appService.hideLoading();
    message = this.translate.instant(error.error.code);
    let alert = this.translate.instant(Alert.OK)
    this.alertsService.open(message, alert);
  }
}
