import { Component, ComponentRef, EventEmitter, InjectionToken, Injector, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { AppService } from 'projects/shared/services/app.service';
import { SectionForm, Type } from 'projects/shared/tools/constants';
import * as moment from 'moment';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { CommonFieldComponent } from '../common-field/common-field.component';
import { QualityFormComponent } from '../custom-edit-setting-form/quality-form.component';
import { Subscription } from 'rxjs';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { DefaultEditSettingFormComponent } from '../default-edit-setting-form/default-edit-setting-form.component';
import { CustomRouter } from '../../../services/custom-router';

export const FORM_DATA = new InjectionToken<{}>('FORM_DATA');

@Component({
  selector: 'app-edit-setting-form',
  templateUrl: './edit-setting-form.component.html',
  styleUrls: ['./edit-setting-form.component.scss']
})

export class EditSettingFormComponent {
  formGroup: UntypedFormGroup;
  type = Type
  data
  idFarmer
  idFormTemplate
  id
  list: any[] = []
  formDefinition: any;
  args = {}
  lastValue
  valueChangeSubscription: Subscription;
  componentPortal: ComponentPortal<CommonFieldComponent>;
  componentMap: { [key: string]: ComponentType<any> } = {
    default: DefaultEditSettingFormComponent,
    quality: QualityFormComponent
  }
@Input() relatedTo
@Input() section
  component: ComponentType<any>

  @Output() routerBack = new EventEmitter();
  @Output() load = new EventEmitter();
  @Output() show = new EventEmitter();
  @Output() save = new EventEmitter()

  @Input() formDataById

  
  @Input() value

  @Input() name
  
  settingsFormsAdvisorFarmer=SectionForm.advisor_farmer_settings;
  sectionAnimalMilkProduction=SectionForm.animal_milk_production
  @Input() color
  @Input() price;
  constructor(public router: CustomRouter, private alertsService: AlertsService, public translate: TranslateService, private formTemplateService: FormTemplateService, public appService: AppService, public codeTranslate: CodeTranslatePipe, public route: ActivatedRoute,
    private injector: Injector, private formBuilder: UntypedFormBuilder,
  ) {
 
  
  
  }

  displayInfo(id) {
    this.show.emit(id)
  }

  getElement() {
    this.load.emit();
  }

  setValue(value) {
    if (value) {
      this.appService.getKeys(value).forEach(key => {
        if(key!='_links'){
        if (Array.isArray(value[key])) {
          value[key].forEach(field =>
            (this.formGroup.get(key) as UntypedFormArray).push(this.newSubField(field)))
        } else
          this.formGroup.controls[key].setValue(value[key])
     }})
    }
  }

  createForm() {
    const group: any = {};
    this.formDefinition.formFields.forEach(field => {
      let control
      if (field.type == this.type.COMPLEX)
        control = this.newField(field)
      else
        control = new UntypedFormControl(field.type == this.type.NUMBER ? (parseFloat(field.value)) : (field.type == this.type.DATE ? (moment(new Date()).format(this.appService.verifFormatDate())) : null/*field.value*/)/*, Validators.required*/)

      group[field.code] = field.multiple ? new UntypedFormArray(this.formDataById ? [] : new Array(this.getFieldLength(field.code)).fill(control)) : control
    })
    this.formGroup = this.formBuilder.group(group)
    if(this.formDefinition.type == "MILK_PRODUCTION") {
      this.formGroup.controls["price"].setValue(this.price);
    }
    this.listenToValueChange()
  }

  getFieldLength(fieldCode) {
    if (this.formDataById)
      if (this.formDataById.value) {
        return this.formDataById.value[fieldCode].length
      }
    return 1
  }

  evalFunctions() {
    if (this.formDefinition.script) {
      eval(this.formDefinition.script);
    }
  }

  public listenToValueChange() {
    if (this.valueChangeSubscription) this.valueChangeSubscription.unsubscribe();
    this.valueChangeSubscription = this.formGroup.valueChanges.subscribe(evt => {
      if (JSON.stringify(this.lastValue) == JSON.stringify(evt)) return;
      this.lastValue = evt;
      this.formDefinition.formFields.forEach(field => {
        if (field.type == this.type.DYNAMIC) {
          this.formGroup.controls[field.code].setValue(this[field.fct](this.lastValue));
        }
        if (field.type == this.type.DATE) {
          this.formGroup.controls[field.code].setValue(moment(evt[field.code]).format("yyyy/MM/DD"));
        }
      })
    })
  }

  getControls(code) {
    return (this.formGroup.controls[code] as UntypedFormArray).controls
  }

  addfields(field) {
    (this.formGroup.get(field.code) as UntypedFormArray).push(this.newField(field));
  }

  removeFields(i: number, field) {
    (this.formGroup.controls[field.code] as UntypedFormArray).removeAt(i);
  }

  newField(field) {
    let group = field.formFields
      .map(element => ({ control: new UntypedFormControl(element.value), code: element.code }))
    var res = {}
    group.forEach(e => {
      res[e.code] = e.control
    })
    return this.formBuilder.group(res)
  }

  newSubField(field) {
    let group = this.appService.getKeys(field)
      .map(key => ({ control: new UntypedFormControl(field[key]), code: key }))
    var res = {}
    group.forEach(e => {
      res[e.code] = e.control
    })
    return this.formBuilder.group(res)
  }

  initView(): void {
    if (this.data.view)
      this.component = this.componentMap[this.data.view]
    else this.component = this.componentMap.default

    this.componentPortal = new ComponentPortal(this.component, null, Injector.create({
      providers: [
        {
          provide: FORM_DATA,
          useValue: {
            formGroup: this.formGroup,
            formDefinition: this.formDefinition,
            formData: this.formDataById
          }
        }
      ],
      parent: this.injector
    }))
  }

  ngOnInit() {
    
    const { idFormTemplate, id } = this.route.snapshot.params
    this.idFormTemplate = idFormTemplate;
    this.id = id
    this.appService.showLoading()
    this.formTemplateService.getForm(idFormTemplate, "form-template",this.section, this.relatedTo).subscribe(t => {
      const stateSetting = t;
      this.data = stateSetting
      const stateSettingAdd = t;
      this.formDefinition = stateSettingAdd.xml
      this.createForm();
      this.evalFunctions()
      this.initView()
    setTimeout(() => {
        this.setValue(this.value)
        this.appService.hideLoading()
      }, 400)
;
      
    })
  }

  ngOnDestroy() {
    if (this.valueChangeSubscription) this.valueChangeSubscription.unsubscribe()
  }

  confirm() {
      this.save.emit({ "formGroup": this.formGroup, "id": this.id })
  }

  back() {
    this.routerBack.emit()
  }

  onComponentRendering(ref, formGroup, formDefinition): void {
    ref = ref as ComponentRef<any>;
    ref.instance.formGroup = formGroup
    ref.instance.formDefinition = formDefinition
  }}