import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { Animal } from "./Animal";
import { Customer } from "./Customer";
import { Group } from "./group";
@HateoasResource('customerAnimalGroups')
export class CustomerAnimalGroup extends Resource {
  
    animal: Animal | string;
	customer: Customer | any;
	group: Group | any;

  }