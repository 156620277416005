import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';

@Component({
  selector: 'app-tank-card',
  templateUrl: './tank-card.component.html',
  styleUrls: ['./tank-card.component.scss']
})
export class TankCardComponent implements OnInit {
  @Input() tank;
  tankSign: string = "-"
  constructor(public appService: AppService, public tokenManagementService: TokenManagementService) {

  }

  ngOnInit() {
  }

}
