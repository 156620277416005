import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";


@HateoasResource('orderDetails')
export class OrderDetail extends Resource {

    id: number;
    order: any ;
    orderType:string
    count:number;
    
    
  }