<form [formGroup]="formGroup" (ngSubmit)="apply()">
  <div class="row">
    <div class="mx-auto">
      <!--a [ngClass]="{rtl:appService.rtl()}">{{ "ee"| translate}}</a-->
    </div>
  </div>
  <div class="row">
    <div class="col-6" *ngIf="!summary">
      <mat-form-field [ngClass]="{rtl:appService.rtl()}" style="width:100%">
        <mat-label>{{ 'CHOOSE' | translate}} &nbsp; {{ 'FARMER' | translate}}</mat-label>
        <mat-select formControlName="selectedFarmers" multiple>
          <input (keydown)="$event.stopPropagation()" matInput placeholder="{{ 'CHOOSE' | translate}} &nbsp; {{ 'FARMER' | translate}}"
            (keyup)="onKeyFarmer($event.target.value)" class="search_style">
          <mat-option [value]="1" (click)="selectAllFarmers(evf)" #evf>{{ 'ALL' | translate}}</mat-option>
          <mat-option *ngFor="let farmer of selectedListFarmers" (click)="checkFarmer(farmer.id)" [value]="farmer.id">
            {{farmer.fullname}}
          </mat-option>
        </mat-select>

        <mat-error
          *ngIf="!formGroup.controls['selectedFarmers'].valid && formGroup.controls['selectedFarmers'].touched">
          {{ 'FIELD_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div [ngClass]="summary? 'col-12' :'col-6'">
      <mat-form-field [ngClass]="{rtl:appService.rtl()}" style="width:100%">
        <mat-label>{{ 'CHOOSE' | translate}}&nbsp;{{ 'COLUMNS' | translate}}</mat-label>
        <mat-select formControlName="selectedColumns" multiple>
          <input  matInput placeholder="{{ 'CHOOSE' | translate}} &nbsp; {{ 'COLUMNS' | translate}}" (keydown)="$event.stopPropagation()" (keyup)="onKeyColumn($event.target.value)" class="search_style">
          <mat-option [value]="1" (click)="selectAllColumns(ev)" #ev>{{ 'ALL' | translate}}</mat-option>
          <mat-option *ngFor="let column of selectedListColumn" [value]="column" (click)="checkColumn(column)">
            {{column | translate}}
          </mat-option>


        </mat-select>
        <mat-error
          *ngIf="!formGroup.controls['selectedColumns'].valid && formGroup.controls['selectedColumns'].touched">
          {{ 'FIELD_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="ml-auto" style="margin-top:220px">
      <div class="col-12"> <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="warn" mat-dialog-close
          style="margin-right:10px">{{ 'CANCEL' | translate}}</button>
        <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="primary" type="submit"
          [disabled]="!formGroup.valid">{{ 'EXPORT' | translate}}</button>
      </div>
    </div>
  </div>