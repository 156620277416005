<div class="row py-2 gx-1 {{element.class}} ">
    <div class="col px-2 ">
        <div [ngClass]="{'rtl':appService.rtl()}" class="row {{element.value1Font}} pt-1 mb-2">
            <span>{{element.value1 | translate}}</span>
            <span *ngIf="element.value11">({{element.value11|translate}})</span>
        </div>
        <div [ngClass]="{'rtl':appService.rtl()}" class=" wrapper pt-1 py-2 ">
            <span class="{{element.value2Font}}">{{element.value2 | translate}}</span>
            
        </div>
        <div [ngClass]="{'rtl':appService.rtl()}" class=" wrapper pt-1 py-2 ">
        <span class=" {{element.value3Font}}">{{element.value3 | translate}}</span>
    </div>
    <div [ngClass]="{'rtl':appService.rtl()}" class=" wrapper pt-1 py-2 ">
        <span class=" {{element.value3Font}}">{{element.value4 | translate}}</span>
    </div>
    </div>
</div>