import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AnimalService } from 'projects/shared/services/animal.service';
import { ReportService } from 'projects/shared/services/report.service';
import { Customer } from 'projects/shared/resources/Customer';
import { Animal } from 'projects/shared/resources/Animal';
import { Alert, Authorities, FORMAT_MAX_MIN, LivestockIdentif } from 'projects/shared/tools/constants';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'projects/shared/services/customer.service';
import { AddressService } from 'projects/shared/services/address.service';
import { RuminationUiComponent } from 'projects/shared/components/rumination-ui/rumination-ui.component';
import { GraphComponent } from '../graph/graph.component';
import { EntrepriseService } from 'projects/shared/services/entreprise.service';
import { DeviceService } from 'projects/shared/services/device.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import * as moment from 'moment';
import { DeviceFarmerService } from 'projects/shared/services/device-farmer.service';

@Component({
  selector: 'app-commun-graph',
  templateUrl: './commun-graph.component.html',
  styleUrls: ['./commun-graph.component.scss']
})
export class CommunGraphComponent implements OnInit {
  project: any;
  graphType: any
  formGroup: UntypedFormGroup;
  customers: Customer[];
  selectedFarmers: Customer[] = [];
  animals: Animal[];
  selectedAnimals: Animal[] = [];
  getGraphs: Boolean = false
  getExportInfo: Boolean = false
  getExportTempGraph: Boolean = false
  getRuminationUi: Boolean = false
  getExportThi: Boolean = false
  getThi: Boolean = false
  getTempMov: Boolean = false
  //idSupervisor: any = null
  id: any = null
  farmer
  idA
  title: String
  periods = ["1_DAY", "3_DAYS", "1_WEEK", "1_MONTH"]
  selectedPeriods = [/*"3_HOURS", "6_HOURS", */"1_DAY", "3_DAYS", "1_WEEK", "1_MONTH"]

  @ViewChild("ruminationUiChart", { static: true }) ruminationUiChart: RuminationUiComponent;
  @ViewChild("graph", { static: true }) graph: GraphComponent;

  xyzGraph: boolean = false;
  tempGraph: boolean = false;
  thiGraph: boolean = false;
  rumGraph: boolean = false;


  entreprises: any[];
  tanks: any[];
  selectedEntreprises: any[] = [];
  selectedTanks: any[] = [];
  selectedHumiditySensors: any[] = [];
  deviceFarmers: any[] = [];

  icon
  authGetGraphs: string[] = [];
  authGetExportInfo: string[] = [];
  authGetExportTempGraph: string[] = [];
  authGetRuminationUi: string[] = [];
  authGetThi: string[] = [];
  authGetExportThi: string[] = [];
  authGetTempMov: string[] = [];
  serviceActivated
  roleFarmer: boolean = false;
  roleCcl: boolean = false;
  tagIds: String[] = []
  showStartDate: boolean = false;
  showEndDate: boolean = false;
  formatArabe: any
  maxDate: any
  nationalIdentification
  nameLocalCode;
  listCodeRoles=[]

  constructor(private deviceFarmerService : DeviceFarmerService , public tokenManagementService: TokenManagementService, public deviceService: DeviceService, private alertsService: AlertsService, public appService: AppService, private formBuilder: UntypedFormBuilder, public animalService: AnimalService, public translate: TranslateService,
    public router: Router, public reportService: ReportService, public route: ActivatedRoute, public customerService: CustomerService, public addressService: AddressService, private entrepriseService: EntrepriseService) {
    this.appService.connectedCustomer['roles'].forEach(element => {
      /*if (element.publicAccess == true && element.isSupervisor == true)
        this.idSupervisor = this.appService.connectedCustomer.id*/
      element['authorityRoles'].forEach(authorityRole => {

        if (authorityRole.authority.code == Authorities.REPORTS_GRAPHS)
          this.authGetGraphs.push((authorityRole["get"]).toString())

        if (authorityRole.authority.code == Authorities.REPORTS_EXPORT_INFO)
          this.authGetExportInfo.push((authorityRole["get"]).toString())


        if (authorityRole.authority.code == Authorities.REPORTS_TEMP_GRAPHS)
          this.authGetExportTempGraph.push((authorityRole["get"]).toString())

        if (authorityRole.authority.code == Authorities.RUMINATION_UI)
          this.authGetRuminationUi.push((authorityRole["get"]).toString())


        if (authorityRole.authority.code == Authorities.REPORTS_HUMIDITY_TEMP)
          this.authGetThi.push((authorityRole["get"]).toString())


        if (authorityRole.authority.code == Authorities.REPORTS_EXPOR_HUMIDITY_TEMPT)
          this.authGetExportThi.push((authorityRole["get"]).toString())


        if (authorityRole.authority.code == Authorities.REPORTS_TEMP_GRAPHS) {
          this.authGetTempMov.push((authorityRole["get"]).toString())
        }
      }
      );

    });
    if (this.authGetGraphs.indexOf("true") != -1)
      this.getGraphs = true;
    if (this.authGetExportInfo.indexOf("true") != -1)
      this.getExportInfo = true;
    if (this.authGetExportTempGraph.indexOf("true") != -1)
      this.getExportTempGraph = true;
    if (this.authGetRuminationUi.indexOf("true") != -1)
      this.getRuminationUi = true;
    if (this.authGetThi.indexOf("true") != -1)
      this.getThi = true;
    if (this.authGetExportThi.indexOf("true") != -1)
      this.getExportThi = true;
    if (this.authGetTempMov.indexOf("true") != -1)
      this.getTempMov = true;
    this.id = this.route.snapshot.paramMap.get('id');
    this.idA = this.route.snapshot.paramMap.get('idA');

    if (this.router.url.indexOf("/graphs") != -1) {
      this.title = 'DEVICE_DATA';
      this.xyzGraph = true
      this.icon = "graphic_eq"
    }

    if (this.router.url.indexOf("/rumination-ui") != -1) {
      this.title = "RUMINATING_RESTING_TIME";
      this.rumGraph = true
      this.icon = "bar_chart"
    }

    if (this.router.url.indexOf("/temp_graph") != -1) {
      this.title = 'TEMP_GRAPH';
      this.tempGraph = true
      this.icon = "timeline"
    }

    if (this.router.url.indexOf("/humidity-temp") != -1) {
      this.title = 'THI';
      this.thiGraph = true;
      this.icon = "show_chart"

    }


    this.chooseRole();
    this.createForm();

  }

  createForm() {

    this.formGroup = this.formBuilder.group({
      'farmer': [null, (!this.tempGraph && !this.roleFarmer) ? [Validators.required] : []],
      'entreprise': [null, (this.tempGraph && !this.roleCcl) ? [Validators.required] : []],
      'code': [null, !this.thiGraph ? [Validators.required] : []],
      'device': [null, this.thiGraph ? [Validators.required] : []],
      'period': [!this.thiGraph ? '1_WEEK' : '1_DAY'],
      'startDate': [],
      'endDate': []
    });

    this.formGroup.controls['startDate'].disable();
    this.formGroup.controls['endDate'].disable();
  }

  ngOnInit() {
    this.nationalIdentification = LivestockIdentif.NATIONAL_IDENTIFICATION
    this.nameLocalCode = LivestockIdentif.NAME_LOCALCODE
    this.formatArabe = this.appService.verifFormatDate()
    let currentRoute = this.router.url;
    if ((currentRoute.startsWith('/supervisors')) || (currentRoute.startsWith('/rumination-ui')) || (currentRoute.startsWith('/humidity-temp'))) {
      {
        this.project = "publicV2";
      
      }
      if (currentRoute.indexOf('humidity-temp') != -1)
        this.graphType = "thi"
      else if (currentRoute.indexOf('temp_graph') != -1) {
        this.graphType = "tempGraph"
      }
      else {
        this.graphType = "rumination"
      }
    }
    else
      this.project = "admin";

    if (!this.tempGraph)
      this.loadCustomers();
    else
      this.loadEntreprises();

    if (this.roleFarmer) {
      this.loadAnimals()
      this.loadDeviceFarmers()
    }
    else if (this.roleCcl) {
      this.loadTanks(this.appService.connectedCustomer.entreprise.id);
    }
    this.maxDate = moment(new Date()).format(FORMAT_MAX_MIN);
    
 
  }
  fixArabeFormat() {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD/MMM/YYYY"
  }


  loadCustomers() {
    //if ((this.router.url.indexOf("/rumination-ui") != -1) || (this.router.url.indexOf("/temp_graph") != -1) || (this.router.url.indexOf("/graphs") != -1))
    if (this.rumGraph || this.tempGraph || this.xyzGraph)
      this.serviceActivated = this.reportService.getFarmersForRummination(/*this.idSupervisor*/)
    else if (this.thiGraph)
      this.serviceActivated = this.reportService.getFarmersForThi(/*this.idSupervisor*/)
    if (!this.roleFarmer && (this.getGraphs || this.getExportInfo || this.getRuminationUi || this.getThi)) {
      this.serviceActivated.subscribe(res => {
        this.selectedFarmers = this.customers = res._embedded.customers
        let farmer = res._embedded.customers.filter(customer => customer.id.toString() === this.id)
        this.formGroup.controls['farmer'].setValue(farmer.length ? farmer[0] : null);
        if (this.idA) {
          this.loadAnimals();
        }
      }, error => {
        this.appService.hideLoading();
        let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
        let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
        this.alertsService.open(message, alert);
      })
    }
  }

  chooseRole() {
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.listCodeRoles.push(role.code);
    })
    this.appService.connectedCustomer['roles'].forEach(role => {
        if(this.listCodeRoles.indexOf('ROLE_FARMER')!= -1)
        this.roleFarmer = true;
       else if (role.code == 'ROLE_CCL_ADMIN')
        this.roleCcl = true;

      else {
        this.roleFarmer = false;
        this.roleCcl = false;
      }
    })
  }

  search(value, list, field) {
    let filter = value.toLowerCase();
    return list.filter(option => option[field].toLowerCase().includes(filter));
  }
  searchUid(value, list, field) {
    let filter = value;
    return list.filter(option => option[field].includes(filter));
  }

  onKeyFarmer(value) {
    this.selectedFarmers = this.search(value, this.customers, 'fullname');
  }

  searchPeriod(value, list) {
    let filter = value.toLowerCase();
    return list.filter(option => this.translate.instant(option).toLowerCase().includes(filter));
  }

  onKeyPeriod(value) {
    this.selectedPeriods = this.searchPeriod(value, this.periods)
  }

  showOptions(event) {
    this.formGroup.controls['period'].setValue(null);
    this.formGroup.controls['startDate'].setValue(null);
    this.formGroup.controls['endDate'].setValue(null);

    if (event.checked) {
      this.formGroup.controls['period'].disable();
      //this.showStartDate = true;
      //this.showEndDate = true;
     this.formGroup.controls['startDate'].enable();
     this.formGroup.controls['endDate'].enable();
    } else {
     this.formGroup.controls['startDate'].disable();
     //this.showStartDate = false;
     //this.showEndDate = false;
    this.formGroup.controls['endDate'].disable();
      this.formGroup.controls['period'].enable();
    }
  }

  onKeyAnimals(value) {
    this.selectedAnimals = this.searchUid(value, this.animals, 'uid');
  }

  loadAnimals() {
    if (this.rumGraph || this.tempGraph || this.xyzGraph) {
      if (this.getGraphs || this.getExportInfo || this.getRuminationUi) {
        let addressId
        if (!this.roleFarmer)
          addressId = this.formGroup.controls['farmer'].value.addresses[0].id;
        else if (this.roleFarmer == true)
          addressId = this.appService.connectedCustomer.addresses[0].id;
        this.reportService.getListAnimals(addressId).subscribe(res => {
          if (res._embedded) {
            this.selectedAnimals = this.animals = res._embedded.animals;
            if (this.idA) {
              let animal = res._embedded.animals.filter(animal => animal.id.toString() === this.idA)
              this.formGroup.controls['code'].setValue(animal.length ? animal[0] : null);
            }
          }
          else {
            this.selectedAnimals = this.animals = []
            let message = this.translate.instant("FARMER_NOT_HAVE_ANIMALS");
            let alert = this.translate.instant(Alert.OK)
            this.alertsService.open(message, alert);
          }
        }, error => {
          this.appService.hideLoading();
          let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
          let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
          this.alertsService.open(message, alert);
        })
      }
    }

  }

  loadDeviceFarmers() {
    if (this.thiGraph) {
      let farmerId
      if (this.roleFarmer != true)
        farmerId = this.formGroup.controls['farmer'].value.id;
      else if (this.roleFarmer == true)
        farmerId = this.appService.connectedCustomer.id;
      this.formGroup.controls['device'].setValue(null);
      this.deviceFarmerService.getDevicesCode(farmerId).subscribe((data: any) => {
        this.selectedHumiditySensors = this.deviceFarmers = data
      }, error => {
        this.appService.hideLoading();
        let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
        let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
        this.alertsService.open(message, alert);
      }
      )
    }


  }

  loadEntreprises() {
    this.reportService.getEntreprisesWithTanks().subscribe(res => {
      if (res._embedded)
        this.entreprises = res._embedded.entreprises;
      let filtredEntreprises = this.entreprises;
      const map = new Map();
      for (const item of filtredEntreprises) {
        if (!map.has(item.id)) {
          map.set(item.id, true);
          this.selectedEntreprises.push({
            id: item.id,
            name: item.name
          });
        }
      }
    }, error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
      this.alertsService.open(message, alert);
    }
    )
  }

  loadTanks(entrepriseId) {
    this.reportService.getTanks(entrepriseId).subscribe(res => {
      if (res._embedded)
        this.selectedTanks = this.tanks = res._embedded.tanks;
      else {
        this.selectedTanks = this.tanks = []
        let message = this.translate.instant("ENTREPRISE_NOT_HAVE_TANKS");
        let alert = this.translate.instant(Alert.OK)
        this.alertsService.open(message, alert);
      }
    }, error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
      this.alertsService.open(message, alert);
    }
    )
  }

  onKeyEntreprises(value) {
    this.selectedEntreprises = this.search(value, this.entreprises, "code");
  }

  onKeyTanks(value) {
    this.selectedTanks = this.search(value, this.tanks, "code");
  }

  onKeyHumiditySensors(value) {
    this.selectedHumiditySensors = this.search(value, this.deviceFarmers, "label");
  }

  apply() {
    if (this.getGraphs && this.router.url.indexOf("/graphs") != -1)
      this.graph.apply();

    if (this.getRuminationUi && this.router.url.indexOf("/rumination-ui") != -1)
      this.ruminationUiChart.apply();
    if (this.getTempMov && this.router.url.indexOf("/temp_graph") != -1)
      this.graph.applyTempMov()
    if (this.getThi && this.router.url.indexOf("/humidity-temp") != -1) {
      this.graph.applyThi(this.getTagIds());
    }
  }

  exportCsv() {
    if (this.getExportInfo && this.router.url.indexOf("/graphs") != -1)
      this.graph.exportCsv()

    if (this.getExportInfo && this.router.url.indexOf("/rumination-ui") != -1)
      this.ruminationUiChart.exportCsv()

    if (this.getExportThi && this.router.url.indexOf("/humidity-temp") != -1) {
      this.graph.exportThiCsv(this.getTagIds())
    }

    if (this.getExportTempGraph && this.router.url.indexOf("temp_graph") != -1)
      this.graph.exportTempGraphCsv()

  }

  getTagIds() {
    this.tagIds = []
    if (this.formGroup.controls['device'].value == 'ALL_HIMIDITY_SENSOR') {
      this.deviceFarmers.forEach(devideFarmer =>
        this.tagIds.push(devideFarmer.code)
      )
    }
    else
      this.tagIds.push(this.formGroup.controls['device'].value)
    return this.tagIds
  }

  back() {
    this.router.navigateByUrl('/setting-graphs')
  }
}
