import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkDetectionDialogComponent } from './network-detection-dialog.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    NetworkDetectionDialogComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],  exports: [
    NetworkDetectionDialogComponent  ]
})
export class NetworkDetectionDialogModule { }
