import { Injectable, Injector } from '@angular/core';
import { Device } from '../resources/Device';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomerCommonService } from './customer-common.service';
import { map, Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { Options } from './moome-rest.service';

@Injectable({
	providedIn: 'root'
})
export class DeviceService extends CustomerCommonService<Device>  {

	constructor(public http: HttpClient, injector: Injector, private configService: ConfigService) {
		super(Device);
	}

	importFromExcel(formData: any) {
		return this.http.post(`api/import_from_excel/equipments`, formData);
	}

	checkIfHasDevices() {
		return this.http.get(this.configService.getApiPath() + "/devices/hasDevices")
	}

	getByCode(code) {
		let options: Options = {

			params: {
				code: code,
				projection: "devices"
			}
		}
		return this.searchSingle(`getByCode`, options)
	}

	findSensors(type) {
		let options: Options = {
			params: {
				projection: 'code_device_proj',
				type: type,
			}
		}
		return this.search(`devices`, options)
		/*.pipe(
			map(res =>{ return res['_embedded']?.devices}
			))*/



	}

	getTagsWithFilter(page, size, field, direction, key) {
		let param: any =
		{
			projection: 'devices',
		}
		if (field != null)
			param.field = field;
		if (direction != null)
			param.direction = direction.toUpperCase();
		if (key != null)
			param.key = key.toLowerCase();


		let options: Options = {
			params:
				param
			,
			pageParams: {
				page: page,
				size: size
			}
		}

		return this.searchPages(`getTagsByKey`, options)
	}
	getTagsByAvailabilityAndType(page, size, field, direction, availability, type) {
		let param: any =
		{
			projection: 'devices',
		}
		if (field != null)
			param.field = field;
		if (direction != null)
			param.direction = direction.toUpperCase();

		if (type != null)
			param.type = type;
		if (availability != null)
			param.availability = availability;


		let options: Options = {
			params:
				param
			,
			pageParams: {
				page: page,
				size: size
			}
		}

		return this.searchPages(`getTagsByAvailabilityAndType`, options)
	}
	getTagsAttachedOfAnimalByType(page, size, field, direction, type) {
		let param: any =
		{
			projection: 'devices',
		}
		if (field != null)
			param.field = field;
		if (direction != null)
			param.direction = direction.toUpperCase();

		if (type != null)
			param.type = type;
		let options: Options = {
			params:
				param
			,
			pageParams: {
				page: page,
				size: size
			}
		}

		return this.searchPages(`getTagsAttachedOfAnimalByType`, options)
	}
	getTagsAttachedOfDeviceFarmerByType(page, size, field, direction, type) {
		let param: any =
		{
			projection: 'devices',
		}
		if (field != null)
			param.field = field;
		if (direction != null)
			param.direction = direction.toUpperCase();

		if (type != null)
			param.type = type;
		let options: Options = {
			params:
				param
			,
			pageParams: {
				page: page,
				size: size
			}
		}

		return this.searchPages(`getTagsAttachedOfDeviceFarmerByType`, options)
	}
	getTagsAttachedOfDeviceAgitatorFridgeByType(page, size, field, direction, type) {
		let param: any =
		{
			projection: 'devices',
		}
		if (field != null)
			param.field = field;
		if (direction != null)
			param.direction = direction.toUpperCase();

		if (type != null)
			param.type = type;
		let options: Options = {
			params:
				param
			,
			pageParams: {
				page: page,
				size: size
			}
		}

		return this.searchPages(`getTagsAttachedOfDeviceAgitatorFridgeByType`, options)
	}
	getTagsAttachedOfDeviceHumiditiesByType(page, size, field, direction, type) {
		let param: any =
		{
			projection: 'devices',
		}
		if (field != null)
			param.field = field;
		if (direction != null)
			param.direction = direction.toUpperCase();

		if (type != null)
			param.type = type;
		let options: Options = {
			params:
				param
			,
			pageParams: {
				page: page,
				size: size
			}
		}
		return this.searchPages(`getTagsAttachedOfDeviceHumiditiesByType`, options)
	}
	getTagsAttachedOfDoubleTempSensorByType(page, size, field, direction, type) {
		let param: any =
		{
			projection: 'devices',
		}
		if (field != null)
			param.field = field;
		if (direction != null)
			param.direction = direction.toUpperCase();

		if (type != null)
			param.type = type;

		let options: Options = {
			params:
				param
			,
			pageParams: {
				page: page,
				size: size
			}
		}

		return this.searchPages(`getTagsAttachedOfDoubleTempSensorByType`, options)
	}

	getTagesOfConnectedCustomer() {
		return this.customRequestGet("/find")
	}

	getDevices(id) {
		return this.search(`getDevices?id=${id}`)
	}

	detachDevice(deviceId, body) {
		const httpOptions = {
			headers: new HttpHeaders({
				"content-type": "application/json"
			}),
		};
		return this.http.post(this.configService.getApiPath() + `/devices/${deviceId}/detachDevice`, body, httpOptions);
	}

	findByCode(code) {
		return this.searchSingle(`existCode?code=${code}&projection=devices`)
	}

	findByDeviceId(code) {
		return this.searchSingle(`existCode?code=${code}&projection=tags`)
	}

	unassignCustomer(selectedRows) {
		return this.http.patch(this.configService.getApiPath() + `/devices/detachCustomer`, selectedRows);
	}

	assignCustomer(body) {
		return this.http.patch(this.configService.getApiPath() + `/devices/assignCustomer`, body);
	}

	findByRows(rows) {
		return this.search(`findDevices?rows=${rows}&projection=devices`)
	}

	isHasAnimal(device): Observable<any> {
		return this.http.get(this.configService.getApiPath() + "/devices/hasAnimal/" + device);
	}

	getTagsOfCustomerByGroup(farmerId?, groupId?) {
		if (farmerId == null)
			farmerId = ""
		return this.customRequestGet(`/findByGroup?projection=devices&farmer=${farmerId}&group=${groupId}`)
	}

	getBatteryLevelStatistic() {
		return this.http.get(this.configService.getApiPath() + `/devices/battery-level-statistic`)
	}

	getDevicesOfBatteryLevel(firstPercentage, secondPercentage, page, size) {
		return this.http.get(this.configService.getApiPath() + `/devices/battery-level-table?firstPercentage=${firstPercentage}&secondPercentage=${secondPercentage}&page=${page}&size=${size}`)
	}
	getTypeSensorByAvailability() {
		return this.http.get(this.configService.getApiPath() + `/devices/getTypeSensorByAvailability`)
	}



}

