<div class="style-bar-troups w-100" ngxHideOnScroll [scrollingElementSelector]="'body'" [propertyUsedToHide]="'top'"
    [valueWhenHidden]="'-50px'" [valueWhenShown]="'90px'">
    <div class="row  h-100">
        <div class="col-12">
            <div class="row background-troups">
                <div class="col center-element">
                    <img src="/assets/images/left-arrow-1.svg" (click)="back()"/>
                </div>
                <div class="col center-element">
                    <div class="row d-block">
                        <div class="col text-center"> <img src="/assets/images/CCL_icon_1.svg" />
                        </div>
                        <div class="col text-center ps-0 pe-0 "> <span class="text-style"
                                [ngClass]="{rtl:appService.rtl()}">{{ 'MCC' | translate }}</span></div>
                    </div>
                </div>
                <div class="col center-element">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row px-2 pb-5 position">
    <app-milk-collection-center-card *ngFor="let milkCollectionCenter of milkCollectionCenters"
        [milkCollectionCenter]="milkCollectionCenter" class="col-12 col-sm-6 col-md-6 col-lg-4 mt-2 mb-2">
    </app-milk-collection-center-card>
</div>
<div *ngIf="milkCollectionEmpty" class="mt-5">
    <app-no-animals [text]="'NO_MCC'" [getAuthorized]="authorityAnimals"></app-no-animals>
</div>