<app-animal-bar [id]="id" (goBack)="back()">
</app-animal-bar>

<div class="row">
    <div class="col text-style text-center mt-3 mb-3" [ngClass]="{rtl:appService.rtl()}">{{'NOTES' | translate}}
    </div>
</div>

<div class="row px-4" [createAuthorized]="authority">
    <div class="col-12 mx-auto back-style" (click)="takeNote()">
        <div class="mt-3 text-center note-style" [ngClass]="{rtl:appService.rtl()}">
            <div>{{ 'TAKE_NOTE' | translate}}</div>
        </div>
    </div>
</div>

<div class="row"  [getAuthorized]="authority">
    <div class="col note-style text-center mt-4 mb-2" [ngClass]="{rtl:appService.rtl()}">{{'MY_NOTES' | translate}}
    </div>
</div>

<div class="row px-4" [getAuthorized]="authority">
    <app-note-card *ngFor="let note of notes" class="col-12 mt-2" [note]="note" (reload)="findNotes()">
    </app-note-card>
</div>