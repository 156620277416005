import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePassordDialogComponent } from './change-passord-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'projects/publicV2/src/app/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [ChangePassordDialogComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    ReactiveFormsModule
  ]
})
export class ChangePassordDialogModule { }
