<div class="dialog-size-mob">
    <div class="row" [getAuthorized]="authorityAnimals">
        <div class="col center-element p-0">
            <div class="row gx-4 center-element">
                <div class="color-group-cercle-note col-3 p-0" [ngStyle]="{'background-color':colorGroup}"
                    style="margin-left: 11px;"> 
                </div>
                <div class="col text-style-setting"><span>{{appService.valueUid == nationalIdentification? (uid? uid : name) : (appService.valueUid == nameLocalCode? (name? name : uid) : (uid? uid : name))}}</span>&nbsp;<span
                        *ngIf="code">({{code}})</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col text-center mt-3 mb-3"> <span class="text-style" [ngClass]="{rtl:appService.rtl()}">{{
                'WEIGHT' | translate }}&nbsp;/{{ 'HEIGHT' | translate}}</span> </div>
    </div>
    <form [formGroup]="formGroup">

        <!--<div class="row" [ngClass]="appService.rtl()? 'font-size-text-ar' : 'font-size-text'">
            <div class="col  text-center " >
                <input class="input-mob   width" [ngClass]="{rtl:appService.rtl()}" [matDatepicker]="picker"
                    formControlName="actionDate"  [min]="minDate" (dateChange)="addEvent($event)" placeholder="{{ 'CHOOSE' | translate}}&nbsp;{{ 'DATE' | translate}}">
                <div [ngClass]="appService.rtl()? 'date-ar' : 'date'">
                    <mat-datepicker-toggle matSuffix [for]="picker">
                        <img matDatepickerToggleIcon src="/assets/images/calendar4.svg" />
                    </mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker></mat-datepicker>
                </div>
            </div>
        </div>-->

        <div class="row">
            <div class="col-12 mb-3" [ngClass]="{rtl:appService.rtl()}">
                <ion-item class="input-mob-date width">
                    <ion-datetime #datePicker mode="ios" [ngClass]="{rtl:appService.rtl()}"
                        [pickerFormat]="appService.verifFormatDate()" [displayFormat]="formatArabe"
                        formControlName="actionDate" (ionChange)="fixArabeFormat()"
                        placeholder="{{ 'CHOOSE' | translate}}&nbsp;{{ 'DATE' | translate}}" [min]="minDate"
                        [max]="maxDate" [doneText]="'SAVE' |translate" [cancelText]="'CANCEL' |translate"
                        [monthShortNames]="appService.monthShort()">
                    </ion-datetime>
                    <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar' : 'date'"
                        src="/assets/images/calendar4.svg" />

                </ion-item>
            </div>
        </div>


        <div class="row">
            <div class="col-12 mb-3" [ngClass]="{rtl:appService.rtl()}">

                <input class="input-mob width-weight" placeholder="{{ 'WEIGHT'| translate }}"
                    [ngClass]="{rtl:appService.rtl()}" type="number" formControlName="noteDetail" id="weight"
                    inputmode="text" (keyup)="verifPoids()" (input)="appService.validWeight(formGroup.controls['noteDetail'])" (keypress)="appService.numericOnly($event,numericPattern)" >

                <span class="text-kg" [ngClass]="{rtl:appService.rtl()}"> {{ 'KG'| translate }}</span>

            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-3" [ngClass]="{rtl:appService.rtl()}">

                <input class="input-mob width-weight" placeholder="{{ 'HEIGHT'| translate }}"
                    [ngClass]="{rtl:appService.rtl()}" type="number" formControlName="height" id="height"
                    inputmode="text" (keyup)="verifHeight()" (keypress)="appService.numericOnly($event,numericPattern)" >

                <span class="text-kg" [ngClass]="{rtl:appService.rtl()}"> {{ 'CM'| translate }}</span>

            </div>
        </div>
    </form>

    <!-- <div class="row">
        <div class="col center-element">
            <div  > 
                    <button  mat-raised-button color="warn" style="margin-right: 5px" class="button-dialog   text-center" mat-dialog-close>
                         <span  [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'" class="button-dialog   text-center">{{ 'CANCEL' | translate }} </span></button>
                    
                    <button  [ngClass]="!formGroup.valid?  'opac' : '' " style="margin-right: 5px"   class="button-dialog text-center" mat-raised-button color="primary"
                    (click)="confirm()">
                    <span [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'" class="button-dialog button-text-size text-center">{{ 'CONFIRM' | translate }} </span></button>
                </div>
        </div>
    </div>-->
    <div class="row">
        <div class="col">
            <div class="center-element">
                <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="warn" style="margin-right: 5px"
                    class="button-dialog   text-center" mat-dialog-close> <span
                        [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'" class="center-element">{{
                        'CANCEL' | translate }} </span></button>

                <button
                    [ngClass]="!formGroup.valid? (appService.rtl()? 'opac rtl' : 'opac' ) : (appService.rtl()? 'rtl' : '' )"
                    class="button-dialog button-text-size text-center" mat-raised-button color="primary"
                    (click)="confirm()"><span [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'"
                        class="center-element">{{ 'CONFIRM' | translate }} </span></button>
            </div>
        </div>
    </div>
</div>