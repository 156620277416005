export class FarmerStockFeedDto {
  
    date: string;
    quantity: string;
    title: string;
    unit:string;
    unitPrice:number
    dryMatter:number
    crudProtein:number
    fiber:boolean
   
  }