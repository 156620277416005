import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { GroupService } from 'projects/shared/services/group.service';
import { ProjectService } from 'projects/shared/services/project.service';
import { Authorities, Roles, SectionForm } from 'projects/shared/tools/constants';
import { CustomRouter } from '../services/custom-router';
import { FormService } from 'projects/shared/services/form.service';

@Component({
  selector: 'app-economy',
  templateUrl: './economy.component.html',
  styleUrls: ['./economy.component.scss']
  
})
export class EconomyComponent implements OnInit {
  list 
  listForm= []
  idFarmer
  idSupervisor
  title: String
  supervisorId: any
  showSettings: Boolean = true
  projection = "form-template"
  relatedTo: String = "economy"
  isSupervisor: Boolean = false;
  service
  color='color-abort'
  constructor(private router: CustomRouter, public appService: AppService,
    public dialog: MatDialog, public route: ActivatedRoute, private translate: TranslateService,
    public groupService: GroupService,public formService: FormService,
    private alertsService: AlertsService, public projectService: ProjectService) {
      
  }

  ngOnInit() {
    this.listForm = this.appService.loadForm(this.projection,SectionForm.farmer_economy)
      this.list = [
        { img: "/assets/images/new_other_income.svg", router: "/setting-economy/settings/"+this.listForm[0]?.formTemplateId, text: "OTHER_INCOMES", class: "sect-style abort-style", back: "abort-back"},
       
        { img: "/assets/images/new_other_costs.svg", router: "/setting-economy/settings/"+this.listForm[1]?.formTemplateId, text: "OTHER_COSTS", class: "sect-style abort-style", back: "abort-back"}
      ]
    this.title = "ECONOMY"
    this.supervisorId = this.appService.connectedCustomer.id
    this.appService.verif = false
    this.idFarmer =  this.appService.connectedCustomer.id
    this.idSupervisor = this.route.snapshot.paramMap.get('idSupervisor');
    this.service = this.formService
    this.appService.connectedCustomer['roles'].forEach(element => {
      if (element.isSupervisor == true)
        this.isSupervisor = true;
    })
   
  }
  back() {
    this.router.back('')
  }


  
  pathUrl(element) {
      return this.router.navigateByUrl(element.router)
  }
}
