<app-bar [element]="bar"></app-bar>
<form [formGroup]="formGroup" >
<div class="row  px-4 py-3 position">
    <div class="row " [ngClass]="{rtl:appService.rtl()}">
        <div class="col mb-2 center-element">
            <ion-item class="input-mob-date">
    
                <ion-datetime #datePicker mode="ios" class="font-size-text " [ngClass]="{rtl:appService.rtl()}"
                    [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()" formControlName="startDate"
                    placeholder="{{ 'START_DATE'| translate }}" [doneText]="'SAVE' |translate" 
                    [cancelText]="'CANCEL' |translate"
                    [monthShortNames]="appService.monthShort()" 
                    (ionChange)="fixArabeFormat()">
    
                </ion-datetime>
                <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar' : 'date'"
                    src="/assets/images/calendar4.svg" />
    
            </ion-item>
        </div>
    </div>
    <div class="row " [ngClass]="{rtl:appService.rtl()}">
        <div class="col mb-2 center-element ">
            <ion-item class="input-mob-date">
    
                <ion-datetime #datePicker mode="ios" class="font-size-text " [ngClass]="{rtl:appService.rtl()}"
                [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()"
                [monthShortNames]="appService.monthShort()" 
                placeholder="{{ 'END_DATE'| translate }}" [doneText]="'SAVE' |translate" formControlName="endDate"
                [cancelText]="'CANCEL' |translate"
                (ionChange)="fixArabeFormat()">
    
                </ion-datetime>
                <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar' : 'date'"
                    src="/assets/images/calendar4.svg" />

            </ion-item>
        </div>
    </div>
    <div class="row ">
        <div class="col-6 mb-2 center-element">
            <div class="float-start" > 
                <button [ngClass]="!formGroup.valid?  'opac' : ''" class="button-dialog button-text-size text-center"
                    mat-raised-button color="primary" ><span
                        [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'"
                        class="center-element"(click)="countByDateAndGroup()"  >{{ 'CONFIRM' | translate }} </span></button>
            </div>
        </div>
    </div>
    </div>
</form>
<div class="row px-4 py-3 position ">
    <app-rectangle-card *ngFor="let element of list" class="col-6  center-element py-3" [element]="element">
    </app-rectangle-card>
</div>