<app-animal-bar [id]="id" (goBack)="back()">
</app-animal-bar>
<div class="row px-4 mb-4">
    <app-common-setting *ngFor="let element of list" class="col-12 mt-4" [element]="element"
    (routerUrl)="pathUrl(element.type)" [element.authorityType]="element.authority" >
    </app-common-setting>
    
    <!--<app-common-setting [getAuthorized]="authorityNotes" class="col-12 mt-4" [element]="note"
        (routerUrl)="pathUrl(SettingsText.NOTES)"></app-common-setting>
    <app-common-setting [getAuthorized]="authorityPedigree" class="col-12 mt-4" [element]="pedigree"
        (routerUrl)="pathUrl(SettingsText.PEDIGREE)"></app-common-setting>
        <app-common-setting *ngIf="showLabel" [getAuthorized]="authorityLabel" class="col-12 mt-4" [element]="label"
        (routerUrl)="pathUrl(SettingsText.LABEL)" ></app-common-setting>
    <app-common-setting [editAuthorized]="authorityAnimals" class="col-12 mt-4" [element]="edit"
        (routerUrl)="pathUrl(SettingsText.EDIT)"> </app-common-setting>
    <app-common-setting [deleteAuthorized]="authorityAnimals" class="col-12 mt-4" [element]="remove"
        (routerUrl)="pathUrl(SettingsText.REMOVE)"> </app-common-setting>-->

</div>