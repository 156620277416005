<div class="dialog-size-mob">
    <div class="row">
        <div class="col">
            <div class="text-center title-style-dialog"><div [ngClass]="{rtl:appService.rtl()}"
                    [ngStyle]="{'color': ((data.title == ('WARNING' | translate))? 'red' : '')}">{{data.title}}</div>
            </div>
        </div>
    </div>
    <div class="row" [ngClass]="{rtl:appService.rtl()}">
        <div class="col mt-3 mb-3 p-0">
            <span class="message-style" [ngClass]="{rtl:appService.rtl()}">{{data.message}}</span>
            <span class="message-style bold" [ngClass]="{rtl:appService.rtl()}">{{data.message1}}</span>
            <span class="message-style" [ngClass]="{rtl:appService.rtl()}">{{data.message2}}</span>
            <span class="message-style bold" [ngClass]="{rtl:appService.rtl()}">{{data.message3}}</span>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="float-end"> <button *ngIf="showCancel" [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="warn"
                    style="margin-right: 5px" class="button-dialog   text-center" (click)="cancel()"> <span
                        [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'"
                        class="center-element">{{ 'CANCEL' | translate }} </span></button>
                <button [ngClass]="{rtl:appService.rtl()}" class="button-dialog button-text-size text-center"
                    mat-raised-button color="primary" (click)="confirm()"><span
                        [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'"
                        class="center-element">{{ 'CONFIRM' | translate }} </span></button>
            </div>
        </div>
    </div>
</div>