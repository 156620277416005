import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { FormTemplate } from "./FormTemplate";
@HateoasResource('forms')
export class Form extends Resource {
    id: number;
    formTemplate: FormTemplate | any;
    date: any;
    actionDate: any;
    xml: any;
    value: any;
    farmerForms: any[]
    uuid: any
  }
