import { Injectable, Injector } from '@angular/core';
import { MoomeRestService } from './moome-rest.service';
import { HttpClient } from '@angular/common/http';
import { Timeline } from '../resources/timeline';
import { ConfigService } from './config.service';

@Injectable({
	providedIn: 'root'
})

export class TimelineService extends MoomeRestService<Timeline>{

	constructor(injector: Injector, public http: HttpClient, private configService: ConfigService) {
		super(Timeline);
	}

	createTimeline = (body) => {
		return this.http.post(this.configService.getApiPath() + `/timelines/create`, body);
	}

	endTimeline = (body) => {
		return this.http.post(this.configService.getApiPath() + `/timelines/end`, body);
	}

	updateOldEndTimeline = (body) => {
		return this.http.post(this.configService.getApiPath() + `/timelines/update-old-end`, body);
	}

	abortTimeline = (body) => {
		return this.http.post(this.configService.getApiPath() + `/timelines/abort`, body);
	}

	getTimelineByAnimal(animalId) {
		return this.customRequestGet(`/findByAnimal?animal=${animalId}&projection=timelines`)
	}

	updateTimeline = (body) => {
		return this.http.post(this.configService.getApiPath() + `/timelines/update`, body);
	}

	updateStartTimeline = (body) => {
		return this.http.post(this.configService.getApiPath() + `/timelines/update-give-birth`, body);
	}

	updateAbortTimeline = (body) => {
		return this.http.post(this.configService.getApiPath() + `/timelines/update-abort`, body);
	}

	updateAbortOldTimeline = (body) => {
		return this.http.post(this.configService.getApiPath() + `/timelines/update-old-abort`, body);
	}

	deleteAbortimeline = (body) => {
		return this.http.delete(this.configService.getApiPath() + `/timelines/${body.timelineId}/abort`);
	}

	deleteGiveBirthEndConfirmed= (body) => {
		return this.http.delete(this.configService.getApiPath() + `/timelines/${body.timelineId}/cancel-give-birth-end-confirmed`);
	}
	findCountTimeline(farmerId?) {
		if (farmerId == null)
		farmerId = ""
		return this.http.get(this.configService.getApiPath() +`/timelines/findCountTimeline?farmerId=${farmerId}`);
    }

	updateTimelineDetail = (body) => {
		return this.http.post(this.configService.getApiPath() + `/timelines/updateTimelineDetail`, body);
	}

	/*updateIaCancelTimeline = (body) => {
		return this.http.post(this.configService.getApiPath() + `/timelines/update-ia-cancel`, body);
	}*/
}
