import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'projects/shared/services/app.service';
import { FarmerStockFeedService } from 'projects/shared/services/farmer-stock-feed.service';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { FormService } from 'projects/shared/services/form.service';
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { Authorities, SectionForm } from 'projects/shared/tools/constants';
import { CustomRouter } from '../../services/custom-router';

@Component({
  selector: 'app-history-stock',
  templateUrl: './history-stock.component.html',
  styleUrls: ['./history-stock.component.scss']
})
export class HistoryStockComponent implements OnInit {
  idFarmer
  idFormTemplate
  forms: any={}
  projection = "form-projection"
  name = "FEED_INVENTORY"
  section = SectionForm.farmer_stock
  service
  color='color-stock'
  constructor(public formTemplateService: FormTemplateService, public formService: FormService, public appService: AppService, public route: ActivatedRoute,
    private router: CustomRouter, private supervisorService: SupervisorService, private farmerStockFeedService: FarmerStockFeedService) {
    const { idFormTemplate } = this.route.snapshot.params
    this.idFormTemplate = idFormTemplate;
    let currentRoute = this.router.url;
    if (currentRoute.indexOf('key') == -1) {
      this.getData()
    }
  }

  ngOnInit(): void {
    this.service = this.farmerStockFeedService
    this.idFarmer = this.appService.connectedCustomer.id

  }

  getDataByKey(element) {
    this.farmerStockFeedService.findByFarmerByKey(element.key).subscribe(res => {
      this.forms=this.appService.groupData(this.appService.organizedData(res, this.appService.dataAddStockFeed));
    }, error => {
      this.appService.error(error.error.error)
    });
  }

  getData() {

    this.farmerStockFeedService.findByFarmer().subscribe(res => {
      this.forms=this.appService.groupData(this.appService.organizedData(res, this.appService.dataAddStockFeed));
    }, error => {
      this.appService.error(error.error.error)
    });
  }

  back() {
    
    this.router.navigateByUrl('/stock')
  }
  action(item) {
    if (!item)
      this.router.navigateByUrl('stock/feedInventory/' + this.idFormTemplate + '/save' + (item ? ("/" + (item.id ? item.id : item.uuid)) : ""))
   else
   this.router.navigateByUrl('settings/' + this.appService.formTemplateFormFarmerFeedConsumptionId + '/' + Authorities.FARMER_FEED_CONSUMPTION + '/' + (item.id) + '/historyFeedInventory/' + item.title);
  
  }
}