import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Chart, ArcElement, LineElement, BarElement, PointElement, BarController, BubbleController, DoughnutController, LineController, PieController, PolarAreaController, RadarController, ScatterController, CategoryScale, LinearScale, LogarithmicScale, RadialLinearScale, TimeScale, TimeSeriesScale, Decimation, Filler, Legend, Title, Tooltip, SubTitle } from 'chart.js';
import * as moment from 'moment';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AnimalService } from 'projects/shared/services/animal.service';
import { AppService } from 'projects/shared/services/app.service';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { ReportService } from 'projects/shared/services/report.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { CapacitorUtils } from 'projects/shared/tools/CapacitorUtils';
import { FORMAT_MAX_MIN, Alert } from 'projects/shared/tools/constants';
import { CustomRouter } from '../services/custom-router';
import { SupervisorService } from 'projects/shared/services/supervisor.service';

@Component({
  selector: 'app-milk-collected-graph',
  templateUrl: './milk-collected-graph.component.html',
  styleUrls: ['./milk-collected-graph.component.scss']
})
export class MilkCollectedGraphComponent implements OnInit {

  formGroup: UntypedFormGroup;
  formatArabe: any
  periods = ["1_MONTH"]
  selectedPeriods = ["1_MONTH"]
  maxDate: any
  displaygraph = false;
  lineChart: Chart;
  dayTime: String[];
  startDate;
  endDate;
  periodValue
  title: any;
  datasets: any[] = [];
  quantityMilk: Number[];
  adminAccess: Boolean = false
  formatString: string
  supervisors: any
  idSupervisor
  constructor(public supervisorService:SupervisorService,public translateService: TranslateService, public formTemplateService: FormTemplateService, public tokenManagementService: TokenManagementService,
    public router: CustomRouter, public appService: AppService, private formBuilder: UntypedFormBuilder,
    public alertsService: AlertsService, public translate: TranslateService, public codeTranslate: CodeTranslatePipe,
    public reportService: ReportService, public animalService: AnimalService) {
    Chart.register(
      ArcElement,
      LineElement,
      BarElement,
      PointElement,
      BarController,
      BubbleController,
      DoughnutController,
      LineController,
      PieController,
      PolarAreaController,
      RadarController,
      ScatterController,
      CategoryScale,
      LinearScale,
      LogarithmicScale,
      RadialLinearScale,
      TimeScale,
      TimeSeriesScale,
      Decimation,
      Filler,
      Legend,
      Title,
      Tooltip,
      SubTitle
    );
    this.appService.connectedCustomer['roles'].forEach(role => {
      if (role.adminAccess)
        this.adminAccess = true

    })
  }

  ngOnInit(): void {
    this.formatString = this.translate.instant('DATEPICKER_FORMAT_MONTHLY');
    this.createForm()
    this.maxDate = moment(new Date()).format(FORMAT_MAX_MIN);
    if(this.adminAccess)
    this.loadSupervisors()
  }
  back() {
    this.router.back('')
  }
  fixArabeFormat() {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD/MMM/YYYY"
  }
  apply() {
    this.applyGraph();

  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      'period': [],
      'startDate': [],
      'endDate': [],
      'supervisor': [null, (this.adminAccess) ? [Validators.required] : []]

    });
    this.formGroup.controls['startDate'].disable();
    this.formGroup.controls['endDate'].disable();
  }
  searchPeriod(value, list) {
    let filter = value.toLowerCase();
    return list.filter(option => this.translate.instant(option).toLowerCase().includes(filter));
  }

  onKeyPeriod(value) {
    this.selectedPeriods = this.searchPeriod(value, this.periods)
  }
  exportCsv() {
    this.idSupervisor = this.formGroup.controls['supervisor'].value?.id;
    this.periodValue = this.formGroup.controls['period'].value
    let type = ".csv"
    this.setPeriod();
    if ((this.endDate != "Invalid date") && (this.startDate != "Invalid date"))
      this.reportService.exportMilkGraphToCsv(this.startDate, this.endDate, this.idSupervisor).subscribe(data => {
        let blob = new Blob([data], { type: 'text/csv; charset=utf-8' }); 
        let filename = this.translate.instant('MILK_GRAPH')  + moment(new Date()).format('yyyy-MM-DD HH:mm') 
        if (CapacitorUtils.isApp){
          CapacitorUtils.writeAndOpenFile(blob, filename+type);
          this.appService.hideLoading();
          let message = this.translate.instant('SAVE_FILE_SUCESS');
          this.alertsService.open(message);}
        else{
          this.appService.saveAs(blob, filename, type);
        this.appService.hideLoading();}
      }, error => {
        this.appService.hideLoading();
        let message = this.translate.instant(error.error.code);
        let alert = this.translate.instant(Alert.OK)
        this.alertsService.open(message, alert);
      })
  }
  loadSupervisors() {
    this.supervisorService.getForAdminEntrepriseSupervisors().subscribe(res => {
      this.supervisors = res

    },
      error => {
        this.appService.hideLoading();
        let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
        let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
        this.alertsService.open(message, alert);
      })
  }



  showOptions(event) {
    this.formGroup.controls['period'].setValue(null);
    this.formGroup.controls['startDate'].setValue(null);
    this.formGroup.controls['endDate'].setValue(null);

    if (event.checked) {
      this.formGroup.controls['period'].disable();

      this.formGroup.controls['startDate'].enable();
      this.formGroup.controls['endDate'].enable();
    } else {
      this.formGroup.controls['startDate'].disable();

      this.formGroup.controls['endDate'].disable();
      this.formGroup.controls['period'].enable();
    }
  }
  setPeriod() {
    if (this.periodValue != null) {
      let start
      if (this.periodValue == "1_MONTH")
        start = moment().subtract(1, 'months');
      this.startDate = moment(start).format('yyyy/MM/DD').toString();
      this.endDate = moment().format('yyyy/MM/DD').toString();
      this.title = start.format(this.formatString) + " -- "
        + moment().format(this.formatString);
    } else {
      this.startDate = moment(new Date(this.formGroup.controls['startDate'].value)).format('yyyy/MM/DD').toString();
      this.endDate = moment(this.formGroup.controls['endDate'].value).format('yyyy/MM/DD').toString();
      this.title = moment(this.startDate).format(this.formatString) + " -- "
        + moment(this.endDate).format(this.formatString);
    }
  }
  applyGraph() {
    this.idSupervisor = this.formGroup.controls['supervisor'].value?.id;
    if (this.lineChart) {
      this.lineChart.destroy();
      this.displaygraph = false;
    }
    this.periodValue = this.formGroup.controls['period'].value
    this.setPeriod();
    if ((this.endDate != "Invalid date") && (this.startDate != "Invalid date"))
      this.reportService.getMilkGraph(this.startDate, this.endDate,this.idSupervisor).subscribe((res: any) => {
        this.dayTime = [];
        this.quantityMilk = [];
        this.datasets = [];
        let keys = (Object.keys(res)).sort();
        keys.forEach(element => {
          this.dayTime.push(moment(element).format("YYYY/MM/DD"));
          this.quantityMilk.push(res[element] ? res[element] : null);
        });
        this.datasets.push(
          {
            data: this.quantityMilk,
            label: this.translate.instant('MILK_QUANTITY_TOTAL'),
            fill: false,
            borderColor: "red",
            spanGaps: true
          }
        )
        this.displaygraph = true;
        this.appService.hideLoading();
        this.dayTime = [];
        this.lineChart = new Chart('lineChart', {
          type: 'line',

          data: {
            labels: this.dayTime,
            datasets: this.datasets,
          },
          options: {
            plugins: {
              legend: {
                display: true,
              },
              title: {
                text: this.title,
                display: true
              },
            },

          }
        })

      }, error => {

        this.appService.hideLoading();
        let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.code);
        let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
        this.alertsService.open(message, alert);
      })
  }


  updateChart(point) {
    this.lineChart.options.elements.point.radius = point.checked ? 0 : 2;
    if (!point.checked) {
      this.lineChart.options.elements.point.hitRadius = 5
      this.lineChart.options.elements.point.hoverRadius = 10
      this.lineChart.options.elements.point.hoverBorderWidth = 2
    }
    this.lineChart.update();
  }

}
