<div [ngClass]="animal.statusDisabled ? 'disabled-style' : ''">
<div class="card-size">
    <div class="back-case-color">
        <div class="d-flex align-items-center">
            <div class="ms-2 me-0 pe-0 mt-2 mb-2" >
                <div  class="dot" [ngStyle]="{'background-color':animal.colorGroup}" [deleteAuthorized]="authorityGroups" [editAuthorized]="authorityGroups"
                    (click)="groupUrl()"></div>
            </div>
            <div class="flex-grow-1 ms-2 ps-0 mt-3 mb-3"  >
                <div  [ngStyle]="{'font-size': code == 'MAR' ? '22px' : '27px' }" class="info-style-UID" [getAuthorized]="authority" > {{appService.valueUid == nationalIdentification? (animal.uid? animal.uid :animal.name) : (appService.valueUid == nameLocalCode? (animal.name? animal.name : animal.uid) : (animal.uid? animal.uid :animal.name))}}</div>
            </div>
            
            <div class="d-flex flex-nowrap ms-2 ps-0 mt-4 mb-3 pe-0 me-3 text-aligne" [getAuthorized]="authority">
                <div *ngIf="animal.project" style="margin-top: -32px;">  
                    <!--<i class="material-icons" style="align-self: center; font-size: 53px; color : #316a90 ">bookmark</i>-->
                    <span>  <img style="margin-top: 5px;" src="/assets/images/bookmark.svg"></span>

                </div>
               
                    <div *ngIf="signal && animal.device">
                        <img src="{{imgBattery}}" class="imb-bat me-1">
                        <img src="/assets/images/Tracé-836.svg" />
                    </div>
                    <div *ngIf="!signal && animal.device">
                        <img src="{{imgBattery}}" class="imb-bat me-1">
                        <img src="/assets/images/Tracé-848.svg" />
                    </div>
                    <div *ngIf="!signal && !animal.device">
                        <img src="/assets/images/Tracé-750.svg" class="imb-bat me-1" />
                        <img src="/assets/images/Tracé-847.svg" />
                    </div>
            </div>
        </div>
    </div>
    <div class="back-color">
        <div class="row">
            <div class="col mb-3 mt-3">
                <div class="d-flex justify-content-around">
                    <div class="center-element">
                        <div [ngClass]="hide? 'd-block' : 'd-none'"><img (click)="hide? hide = false : hide = true"
                                src="/assets/images/left-arrow-1-4.svg" />
                        </div>
                    </div>
                    <div class="d-flex align-items-center" [ngClass]="{rtl:appService.rtl()}">
                        <img [ngClass]="appService.rtl()? 'ps-2' : 'pe-1'"
                                [src]="(hide? '/assets/images/Groupe-331.svg' : (cardType? '/assets/images/Groupe-423.svg' : '/assets/images/chart-line-solid.svg'))" />
                        
                        <div class="d-inline"><span class="title-style" [ngClass]="appService.rtl()? 'title-ar' : 'title'">{{ (hide? 'WELL_BEING2' : (cardType? 'REPRODUCTION' : 'GROUTH')) | translate }}</span>
                        </div>
                    </div>
                    <div class="center-element me-2" >
                        <div [ngClass]="hide? 'd-none' : 'd-block'" *ngIf="!displayCode"> <img
                                (click)="hide == true ? hide = false : hide = true"
                                src="/assets/images/left-arrow-cow.svg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="hide? 'd-none' : 'd-block'">
        <div *ngIf="cardType" class="d-flex justify-content-around  mt-3 mb-1 " [ngClass]="{rtl:appService.rtl()}">
            <div class="ps-0">
                <div class="min-card width-card blue-color" [getAuthorized]="authorityTimelines" (click)="timeLineUrl(); appService.verif=false">
                    <div class="text-center pt-3">
                        <div class="d-inline pt-1 pe-1 ps-1"><span class="nbr-style">{{animal.lactNum}}</span></div>
                    </div>

                    <div  [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="mt-2 text-center"><span>{{animal.lactNum >1 ? ('CALVINGS' | translate):('CALVING_CARTE' | translate) }}</span></div>
                </div>
            </div>
            <div class="ps-0">
                <div class="min-card width-card blue-color" [getAuthorized]="authorityTimelines"(click)="timeLineUrl();appService.verif=false">
                    <div class="text-center pt-3 color">
                        <div class="d-inline pe-1 ps-1"><span class="nbr-style"  [ngClass]="appService.changeColorLactation(lactDays)">{{lactDays}}</span></div>
                        <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span class="exp-unit"   [ngClass]="appService.changeColorLactation(lactDays)">{{ 'DAYS' | translate }}</span></div>
                    </div>
                    
                    <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="mt-2 text-center " ><span [ngClass]="appService.changeColorLactation(lactDays)" *ngIf="animal.customerAnimalGroups[0].code == 03 || animal.customerAnimalGroups[0].code == 04">{{ 'LACTATION_DRY' | translate }} </span></div>
                    <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="mt-2 text-center " ><span [ngClass]="appService.changeColorLactation(lactDays)" *ngIf="animal.customerAnimalGroups[0].code != 03 && animal.customerAnimalGroups[0].code != 04">{{ 'LACTATION' | translate }}</span></div>
                
                </div>
            </div>
            <div class="ps-0">
                <div class="min-card width-card blue-color" [getAuthorized]="authorityTimelines" (click)="timeLineUrl(); appService.verif=false">
                    <div class="text-center pt-3">
                        <div class="d-inline pe-1 ps-1"><span class="nbr-style">{{gestDays}}</span></div>
                        <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span  class="exp-unit">{{ 'DAYS' | translate }}</span></div>
                    </div>
                    <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="mt-2 text-center"><span>{{ 'GESTATION' | translate }}</span></div>
                </div>
            </div>
            
        </div>

        <div *ngIf="!cardType" class="d-flex justify-content-around  mt-3 mb-1 " [ngClass]="{rtl:appService.rtl()}">
            <div class="ps-0">
                <div class="min-card width-card blue-color">
                    <div   class="text-center pt-3">
                        <div  class="d-inline pe-1 ps-1"><span class="nbr-style" [ngClass]="animal.sexe == 'MALE' ? 'color-male' : 'color-femelle'">{{ageYear}}</span></div>
                        <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span [ngClass]="animal.sexe == 'MALE' ? 'color-male' : 'color-femelle'" class="exp-unit">{{ ageYear >1 ? ('YEARS'| translate ) :('YEAR' | translate) }}</span></div>
                    </div>
                    <div class="mt-3 text-center" >
                        <div class="d-inline pe-1 ps-1" ><span class="nbr-style" [ngClass]="animal.sexe == 'MALE' ? 'color-male' : 'color-femelle'">{{ageMonth}}</span></div>
                        <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span [ngClass]="animal.sexe == 'MALE' ? 'color-male' : 'color-femelle'" class="exp-unit">{{ ageMonth >1 ? ('MONTHS'| translate ) :('MONTHH' | translate)}}</span></div>
                    </div>
                </div>
            </div>
            <div class="ps-0"> 
                <div class="min-card width-card blue-color" [createAuthorized]="authorityNotes" (click)="animalNote(animal.id)">
                    <div class="text-center pt-3" >
                        <div class="d-inline pe-1 ps-1"><span [ngStyle]="{'font-size': getSize(animal.weight) >= '6' ? '17px' : '25px'}" class="nbr-style"> {{animal.weight? animal.weight :
                                '--'}}</span></div>
                        <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span  class="exp-unit">{{ 'KG' | translate }}</span></div>
                    </div>
                    <div class="text-center pt-3" >
                        <div class="d-inline pe-1 ps-1"><span [ngStyle]="{'font-size': getSize(animal.weight) >= '6' ? '17px' : '25px'}" class="nbr-style"> {{animal.height? animal.height :
                                '--'}}</span></div>
                        <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span  class="exp-unit">{{ 'CM' | translate }}</span></div>
                    </div>
                </div>
            </div>
            <div class="ps-0">
                <div class="min-card width-card blue-color">
                    <div class="text-center pt-3">
                        <div class="d-inline pe-1 ps-1">
                            <span class="nbr-style" *ngIf="animal.adg"></span>
                            <span class="nbr-style" *ngIf="animal.adg<0 && !appService.rtl()">{{animalAdgSign}}</span>
                                <span class="nbr-style"
                                    *ngIf="animal.adg<0">{{((animal.adg?.toString()).split("-")[1])}}</span>
                                <span class="nbr-style" *ngIf="animal.adg>=0">{{(animal.adg?.toString())}}</span>
                                <span class="nbr-style" *ngIf="animal.adg<0 && appService.rtl()">{{animalAdgSign}}</span>
                                    <span class="nbr-style" *ngIf="!animal.adg">--</span>
                        </div>
                        <div  [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span  class="exp-unit">{{ 'G' | translate }}</span></div>
                        <div class="text-center pt-3">
                            <div class="d-inline pe-1 ps-1">
                                <span class="nbr-style" *ngIf="animal.diffHeight"></span>
                                <span class="nbr-style" *ngIf="animal.diffHeight<0 && !appService.rtl()">{{animalAdgSign}}</span>
                                    <span class="nbr-style"
                                        *ngIf="animal.diffHeight<0">{{((animal.diffHeight?.toString()).split("-")[1])}}</span>
                                    <span class="nbr-style" *ngIf="animal.diffHeight>=0">{{(animal.diffHeight?.toString())}}</span>
                                    <span class="nbr-style" *ngIf="animal.diffHeight<0 && appService.rtl()">{{animalAdgSign}}</span>
                                        <span class="nbr-style" *ngIf="!animal.diffHeight">--</span>
                            </div>
                            <div  [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span  class="exp-unit">{{ 'MM' | translate }}</span></div>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>

    </div>
    <div [ngClass]="hide? 'd-block' : 'd-none'" *ngIf="!displayCode">
        <div class="row px-4 mt-3 mb-1 gx-3" [ngClass]="{rtl:appService.rtl()}">
            <div class="col-6">
                <div class="min-card width-card blue-color" [ngClass]="appService.rtl()? 'float-start' : 'float-end'">
                    <div class="text-center pt-3">
                        <div class="d-inline pe-1 ps-1"><span  class="nbr-style " [ngClass]="appService.changeColorRumination(animal.countRumination)">{{animal.countRumination? animal.countRumination : '--'}}</span></div>
                        <div  [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span class="exp-unit" [ngClass]="appService.changeColorRumination(animal.countRumination)">{{ 'MIN' | translate }}</span></div>
                    </div>
                    <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="mt-2 text-center"><span>{{ 'RUMINATING' | translate }}</span></div>
                </div>
            </div>
            <div class="col-6">
                <div class="min-card width-card blue-color">
                    <div class="text-center pt-3">
                        <div class="d-inline pe-1 ps-1"><span  class="nbr-style " [ngClass]="appService.changeColorResting(animal.countRest)">{{animal.countRest? animal.countRest : '--'}}</span></div>
                        <div  [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span class="exp-unit" [ngClass]="appService.changeColorResting(animal.countRest)">{{ 'MIN' | translate }}</span></div>
                    </div>
                    <div  [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="mt-2 text-center"><span>{{ 'REST' | translate }}</span></div>
                </div>
            </div>
        </div>
    </div> 
    <div class="float-end me-1" (click)="settingsUrl()" (click)="appService.verif=false"><img
            src="/assets/images/gear-option.svg"/></div>
</div>
</div>