import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Device } from "../resources/Device";
import { DeviceFarmer } from "../resources/DeviceFarmer";
import { ConfigService } from "./config.service";
import { CustomerCommonService } from "./customer-common.service";

@Injectable({
	providedIn: 'root'
})
export class DeviceFarmerService extends CustomerCommonService<DeviceFarmer>  {

	constructor(public http: HttpClient, injector: Injector, private configService: ConfigService) {
		super(DeviceFarmer);
	}

	getDevicesCode(farmerId?){
		if (farmerId == null || !farmerId)
			farmerId = ""
		return this.http.get(this.configService.getApiPath() + `/deviceFarmers/getDevices?farmerId=${farmerId}&projection=devices_code`)
	}

}