import { Component, OnInit } from '@angular/core';
import { AlertService } from 'projects/shared/services/alert.service';
import { AppService } from 'projects/shared/services/app.service';
import { Authorities, Alert, TypeAlerts } from 'projects/shared/tools/constants';
import { ActivatedRoute } from '@angular/router';
import { CustomRouter } from '../services/custom-router';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AlertTypeGroup } from 'projects/shared/resources/AlertTypeGroup';
import { AlertTypeGroupService } from 'projects/shared/services/alert-type-group.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { CommonSettingModule } from '../settings/common-setting/common-setting.module';
import { DeviceFarmerService } from 'projects/shared/services/device-farmer.service';



@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  alertTypeGroups: any[] = []
  unreadAlert: any
  authority = Authorities.ALERTS;
  auth: string[] = [];
  farmerId: any;
  mccId: any;
  currentRoute: any;
  supervisorId: any;
  role = false;
  getSupervisors = [];
  nbrUnreadTankAlerts: Number = 0
  nbrUnreadControlAlerts: Number = 0
  nbrUnreadDeviceAlerts: Number = 0;
  nbrUnreadSicknessAlerts: Number = 0;
  ListAlertTypeGroups: AlertTypeGroup[] = [];
  roleIds: any[] = []
  thiCards: any[]
  thiEmpty: any
  alertTypeGroupsList: any[] = []
  constructor(public deviceFarmerService: DeviceFarmerService, private codeTranslate: CodeTranslatePipe, private alertTypeGroupService: AlertTypeGroupService, private _DomSanitizationService: DomSanitizer, private alertsService: AlertsService, private translate: TranslateService, public appService: AppService, private alertService: AlertService, public router: CustomRouter, public route: ActivatedRoute) {
  }

  ngOnInit() {
    this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
    this.currentRoute = this.router.url
    this.farmerId = this.route.snapshot.paramMap.get('idFarmer');
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.roleIds.push(role.id);
      this.auth.push((role.isSupervisor).toString())
      role['authorityRoles'].forEach(authorityRole => {
        if (authorityRole.authority.code == Authorities.SUPERVISORS && authorityRole["get"] == true) {
          this.getSupervisors.push("true");
        }
      });
    })
    if (this.auth.indexOf("true") != -1) {
      this.role = true;
    }
    this.appService.searchText = 'search_by_uid'
    this.appService.verif = false
    this.alertCard()
  }


  back() {
    if (!this.role) {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.router.navigateByUrl('supervisors/' + this.supervisorId + '/farmers');
      }
      else
        this.router.navigateByUrl('/home')
    }
    else
      if (this.getSupervisors.indexOf("true") == -1 || this.currentRoute.indexOf("farmers") == -1)
        this.router.navigateByUrl('/home')
      else {
        if (this.currentRoute.indexOf('resultSearch') != -1)
          this.router.back('')
        else
          this.router.navigateByUrl('farmers')

      }
  }




  ngOnDestroy() {
    this.appService.verif = false
    this.appService.menu = false

    if (this.auth.indexOf("true") != -1)
      this.appService.searchText = 'search_by_uid_name_phone'
  }



  alertCard() {
    this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor')
    this.farmerId = this.route.snapshot.paramMap.get('idFarmer');
    this.mccId = this.route.snapshot.paramMap.get('mccId');

    this.appService.showLoading();
    // setTimeout(() => {
    this.alertService.getUnreadAlertCountWithType(this.supervisorId, this.farmerId, this.mccId).subscribe(data => {
      this.unreadAlert = data
      this.alertTypeGroupService.findAlertTypeGroupByRole(this.roleIds).subscribe(res => {
        this.ListAlertTypeGroups = res['resources'].sort((a, b) => {
          if (a.code > b.code) {
            return 1
          } else if (a.code < b.code) {
            return -1
          }
          return 0
        })
        if (this.currentRoute.indexOf("mcc") != -1) {
          let alertTypeGroup = this.ListAlertTypeGroups.find(f => f.labelEn == TypeAlerts.TANKS_ALERTS)
          this.ListAlertTypeGroups = [];
          this.ListAlertTypeGroups.push(alertTypeGroup)
        }
        this.ListAlertTypeGroups.forEach(alertTypeGroup => {
          this.alertTypeGroupsList.push({ title: this.codeTranslate.transform(alertTypeGroup), code: alertTypeGroup.code, icon: alertTypeGroup.icon, coloredIcon: alertTypeGroup.coloredIcon, color: alertTypeGroup.color, number: this.unreadAlert[alertTypeGroup.code] ? this.unreadAlert[alertTypeGroup.code] : '0', authority: (alertTypeGroup.code == '07' ? Authorities.GATEWAYS : (alertTypeGroup.code == '08' ? Authorities.TAGS : (alertTypeGroup.code == '09' ? Authorities.TANKS : Authorities.ANIMALS))) },);
          this.deviceFarmerService.getDevicesCode().subscribe((data: any) => {
            this.thiCards = data
            this.thiEmpty = this.thiCards.length == 0;
            if (this.thiEmpty){
              this.alertTypeGroupsList = this.alertTypeGroupsList.filter(bar => bar.code != "08");}

              this.alertTypeGroups = this.alertTypeGroupsList;
           
          })
          
        })


      });
      this.appService.hideLoading();

    }, error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
      this.alertsService.open(message, alert);
    });
    // this.appService.hideLoading();

    // }, 200);


  }
}
