import { NgModule } from "@angular/core";
import { CodeTranslatePipe } from '../pipes/code-translate.pipe';
import { FilterPipe } from '../pipes/filter.pipe';
import { GetAuthorizedDirective } from 'projects/publicV2/src/app/directive/get-authorized.directive';
import { AuthorizedDirective } from 'projects/publicV2/src/app/directive/authorized.directive';
import { CreateAuthorizedDirective } from 'projects/publicV2/src/app/directive/create-authorized.directive';
import { DeleteAuthorizedDirective } from 'projects/publicV2/src/app/directive/delete-authorized.directive';
import { EditAuthorizedDirective } from 'projects/publicV2/src/app/directive/edit-authorized.directive';
@NgModule({
    imports: [
    ],
    exports: [
        FilterPipe,
        CodeTranslatePipe,
        GetAuthorizedDirective,
        AuthorizedDirective, EditAuthorizedDirective,
        CreateAuthorizedDirective, DeleteAuthorizedDirective
    ], declarations: [
        FilterPipe,
        CodeTranslatePipe,
        GetAuthorizedDirective,
        AuthorizedDirective,
        CreateAuthorizedDirective,
        DeleteAuthorizedDirective,
        AuthorizedDirective, EditAuthorizedDirective,

    ],
    providers: [CodeTranslatePipe]
})
export class MoomeCommonModule { }
