import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AnimalNoteService } from 'projects/shared/services/animal-note.service';
import { AppService } from 'projects/shared/services/app.service';
import { Alert, Authorities } from 'projects/shared/tools/constants';
import { CommonDialogComponent } from '../../common-dialog/common-dialog.component';
import { CustomRouter } from '../../services/custom-router';

@Component({
  selector: 'app-note-card',
  templateUrl: './note-card.component.html',
  styleUrls: ['./note-card.component.scss']
})
export class NoteCardComponent implements OnInit {

  @Input() note: any
  @Output() reload = new EventEmitter();


  id: any
  role:any
  idFarmer:any;
  authority = Authorities.ANIMAL_NOTES
  auth: string[] = [];

  constructor(public appService: AppService, public router: CustomRouter, private route: ActivatedRoute, public dialog: MatDialog,
    private alertsService: AlertsService, private translate: TranslateService, public animalNoteService: AnimalNoteService) { }

  ngOnInit() {
    this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if(this.auth.indexOf("true") != -1)
        this.role=true;
      else
      this.role=false   
    
    this.id = this.route.snapshot.paramMap.get('id');
  }

  apply(note) {
    if(!this.role)
    this.router.navigateByUrl('/animals/' + this.id + '/settings/notes/save/' + parseInt(note.id))
    else
    this.router.navigateByUrl('farmers/'+this.idFarmer+'/animals/' + this.id + '/settings/notes/save/' + parseInt(note.id))
  }

  deleteNote(note) {

    const dialogRef = this.dialog.open(CommonDialogComponent, {
      data: {
        message: this.translate.instant('CONFIRM_DELETE_NOTE'),
        title: this.translate.instant('DELETE_NOTE'),
        onConfirm: () => {
          this.appService.showLoading();
          this.animalNoteService.delete(note).subscribe(data => {
            this.appService.hideLoading();
            this.reload.emit();
            let message = this.translate.instant('SAVE_SUCESS');
            this.alertsService.open(message);
          }, error => {
            this.appService.hideLoading();
            let message = this.translate.instant(error.error.code);
            let alert = this.translate.instant(Alert.OK)
            this.alertsService.open(message, alert);
          })
        }
      }
    });
  }
}
