import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { Authorities, GroupCode, SectionForm } from 'projects/shared/tools/constants';
import { CustomRouter } from '../../services/custom-router';

import state from "../../../../../shared/tools/state"
import { CustomerService } from 'projects/shared/services/customer.service';
import { AnimalService } from 'projects/shared/services/animal.service';
import { GroupService } from 'projects/shared/services/group.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonDialogComponent } from '../../common-dialog/common-dialog.component';

@Component({
  selector: 'app-settings-form',
  templateUrl: './settings-form.component.html',
  styleUrls: ['./settings-form.component.scss']
})
export class SettingsFormComponent implements OnInit {
  list: any[] = []
  formAdditional: any[] = []
  idFarmer
  idSupervisor
  name
  farmer
  authority = Authorities.FORM_TEMPLATE
  projection = "form-template"
  value
  length
  isSupervisor: Boolean = false;
  relatedTo: String = "farmer"
  showSettings: Boolean = true
  authorityFarmers=Authorities.FARMERS
  color='color-alert'
  constructor(public alertsService: AlertsService, public dialog: MatDialog, public formTemplateService: FormTemplateService, private router: CustomRouter, public groupService: GroupService, public appService: AppService, public translate: TranslateService,
    public route: ActivatedRoute, public supervisorService: SupervisorService, private customerService: CustomerService, public animalService: AnimalService) {

    this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');
    this.idSupervisor = this.route.snapshot.paramMap.get('idSupervisor');

    this.getFarmer();
    this.loadForm();
    this.additionalForm();

  }

  getFarmer() {
    this.supervisorService.getFarmer(this.idFarmer).subscribe(data => {
      this.farmer = data
      this.name = data['fullname']
    })
  }

  ngOnInit(): void {
    this.appService.connectedCustomer['roles'].forEach(element => {
      if (element.isSupervisor == true)
        this.isSupervisor = true;
    })
    // this.getCountAnimalByFarmer()
    this.getAnimalsByGroup()
  }

  getCountAnimalByFarmer() {
    this.customerService.getCountAnimalByFarmer(this.idFarmer).subscribe(data => {
      this.value = data
      if (!this.value)
        return this.appService.error('FARMER_NOT_HAVE_ANIMALS')
    })
  }
  getAnimalsByGroup() {
    this.animalService.getNumberAnimalInProduction(this.idFarmer, GroupCode.PRODUCTION_CODE).subscribe(res => {
      this.length = res['resources'].length
    })
  }

  loadForm() {
    this.list = this.appService.loadForm(this.projection, SectionForm.advisor_farmer_settings,this.relatedTo)
    if (this.list.length == 0)
      this.showSettings = false;

  }
  additionalForm() {
    this.formAdditional.push({ type: "DELETE_FARMER", labelAr: "فصل الفلاح", labelFr: "Détacher l'éleveur", labelEn: "Detach Farmer" ,labelInd: "Lepaskan Petani" },)
  }
  deleteFarmer() {

    const dialogRef = this.dialog.open(CommonDialogComponent, {
      data: {
        message: this.translate.instant('CONFIRM_DELETE_FARMER'),
        title: this.translate.instant('DELETE_FARMER'),
        onConfirm: () => {
          this.customerService.deleteFarmerBySupervisor(this.idFarmer,this.idSupervisor).subscribe(data => {
           
            this.appService.successMessage( this.translate.instant('DELETED_FARMER_SUCCESS') + " "+ this.name + " "+ this.translate.instant('SUCCESS') + " " )
            this.back()
          }
            , error => {
              this.appService.error(error.error.code)
            });
        }
      }
    });
  }

  back() {

    let currentRoute = this.router.url;
    state.removeItem("formData");
    if (currentRoute.indexOf('resultSearch') != -1)
      this.router.back('')
    else {
      if (this.isSupervisor)
        this.router.navigateByUrl('farmers')
      else
        this.router.navigateByUrl('supervisors/' + this.idSupervisor + '/farmers')

    }
  }

  pathUrl(value) {

    /* if (!this.value)
       return this.appService.error('FARMER_NOT_HAVE_ANIMALS')
     if ((SettingsFarmers.checkTypeMilk(value['type'])) && (this.length == 0))
       return this.appService.error('FARMER_NOT_HAVE_ANIMALS_IN_PRODUCTION')
       else{*/
    if (this.isSupervisor)
      this.router.navigateByUrl('farmers/' + this.idFarmer + '/settings/' + value['formTemplateId'])
    else
      this.router.navigateByUrl('supervisors/' + this.idSupervisor + '/farmers/' + this.idFarmer + '/settings/' + value['formTemplateId'])

    //}
  }

}
