import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';

import { MaterialModule } from 'projects/public/src/app/material/material.module';
import { ShowNotesComponent } from './show-notes.component';
import { AddNoteComponent } from '../add-note/add-note.component';
import { ConfirmComponent } from '../confirm/confirm.component';
import { ConfirmModule } from '../confirm/confirm.module';
import { AddNoteModule } from '../add-note/add-note.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
        ConfirmModule,
        MoomeCommonModule,
        AddNoteModule
    ],
    declarations: [
        ShowNotesComponent,
    ],
    exports: [
        ShowNotesComponent
    ]
})
export class ShowNotesModule { }   