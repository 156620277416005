<div [ngClass]=" verif? 'd-none' : 'd-block'">
    <div class="row">
        <div class="col mt-3 mb-3 ms-3 ">
            <div (click)="nextB ? prevSig() : prevSms() "><img src="/assets/images/left-arrow.svg"></div>
        </div>
    </div>
    <div class="row">
        <div class="text-center col mt-4 mb-4">
            <img class="moome-style" src="/assets/images/moome.svg" />
        </div>
    </div>

    <div class="row">
        <div class="col">
            <form [formGroup]="groupForm">
                <div class="row">
                    <div class="text-center text-style color-dark-blue col mt-4">
                        <span [ngClass]="{rtl:appService.rtl()}">{{ text | translate }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center text-style color-dark-blue">
                        <span [ngClass]="{rtl:appService.rtl()}">{{ sms | translate }}</span>
                    </div>
                </div>
                <div class="row" [ngClass]="{rtl:appService.rtl()}">
                    <div *ngIf="nextB" class="text-center col mt-4 ">
                        <input matInput name="phone" class="input-mob input-border width-input" id="phone" type="number"
                            inputmode="text" formControlName="phone">
                    </div>
                </div>
                <div class="row" [ngClass]="{rtl:appService.rtl()}">
                    <div *ngIf="!nextB" class="text-center col mt-4 ">
                        <input matInput name="code" class="input-mob  input-border width-input" id="code" type="number"
                            inputmode="text" formControlName="code">
                    </div>
                </div>
                
                <div class="row" [ngClass]="!nextB ? 'd-block' : 'd-none'">
                    <div class="text-center col mt-2">
                            <div [ngClass]="!appService.showResend ? 'd-block' : 'd-none'"
                            class="text-style2 color-dark-blue" [ngClass]="{rtl:appService.rtl()}">
                            <span>{{ 'EXPIRED_TIME' | translate }}</span>&nbsp;<span>00:{{appService.timer}}</span></div>
                        <div [ngClass]="appService.showResend? 'd-block' : 'd-none'"><span
                                class="text-style2 color-dark-blue" [ngClass]="{rtl:appService.rtl()}">{{ notRec |
                                translate
                                }}</span>&nbsp;<a class="text-style2 moome-color" (click)="resendCode()"
                                [ngClass]="{rtl:appService.rtl()}" color="primary">{{ resend |
                                translate }}</a></div>
                    </div>
                </div>
                <div class="row">
                    <div class="text-center col  mt-3 ">
                        <button mat-raised-button color="primary" class="button-mob"
                            [ngClass]="!groupForm.valid? 'opaci ' : '' " (click)="nextB ? applyA() : applyB()"><span
                                [ngClass]="{rtl:appService.rtl()}">{{ next |
                            translate }}</span></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div [ngClass]="verif ? 'd-block h-100' : 'd-none'">
    <app-verified></app-verified>
</div>