import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import {Species} from "./Species";
@HateoasResource('breeds')
export class Breed extends Resource {
    id: number;
    code: string;
    labelFr: string;
    labelEn: string;
    labelAr: string;
    labelInd:string;
    animalType: Species | string;
  }
