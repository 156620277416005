import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { Authorities, PATTERN_NAME_ARABIC, PATTERN_NAME_FRENCH, SettingsFarmer, SettingsFarmerByLabel, SectionForm } from 'projects/shared/tools/constants';
import { CustomRouter } from '../../services/custom-router';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { MarkerService } from 'projects/shared/services/marker.service';
import state from 'projects/shared/tools/state';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { FormService } from 'projects/shared/services/form.service';

@Component({
  selector: 'app-farmer-card',
  templateUrl: './farmer-card.component.html',
  styleUrls: ['./farmer-card.component.scss']
})
export class FarmerCardComponent implements OnInit {
  @Input() farmer;
  authorityAlerts = Authorities.ALERTS
  authorityRapports = Authorities.REPORTS_MILK_PRODUCTION
  defaultGroupId: any
  currentRoute: any;
  supervisorId: any
  url
  list = []
  value: boolean = false;
  projection = "form-template"
  relatedTo: String = "farmer"
  idFormTemplate
  showSettings:Boolean=true;

  constructor(public tokenManagementService: TokenManagementService, private formTemplateService: FormTemplateService, private route: ActivatedRoute, public appService: AppService, public router: CustomRouter, private markerService: MarkerService) { }

  ngOnInit() {
    state.removeItem("formData");
    this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
    if (!this.supervisorId)
      this.supervisorId = this.appService.connectedCustomer.id
    this.currentRoute = this.router.url
    this.url = 'supervisors/' + this.supervisorId + '/farmers/' + this.farmer.id + '/map'
    this.checkhLanguage()
    this.loadForm()

  }

  openAnimals() {
    this.appService.getDefaultGroup().subscribe(group => {
      this.defaultGroupId = group
      const navigationExtras: NavigationExtras = { state: { farmer: this.farmer } };
      if (this.currentRoute.startsWith('/supervisors'))
        this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + this.farmer.id + "/animals/groups/" + this.defaultGroupId, navigationExtras)
      else {
        if (this.currentRoute.indexOf('resultSearch') != -1)
          this.router.navigateByUrl("resultSearch/farmers/" + this.farmer.id + "/animals/groups/" + this.defaultGroupId, navigationExtras)
        else
          this.router.navigateByUrl("farmers/" + this.farmer.id + "/animals/groups/" + this.defaultGroupId, navigationExtras)

      }
    })
  }
  openAlerts() {
    if (this.currentRoute.startsWith('/supervisors'))
      this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + this.farmer.id + "/alerts")
    else {
      if (this.currentRoute.indexOf('resultSearch') != -1)
        this.router.navigateByUrl("resultSearch/farmers/" + this.farmer.id + "/alerts"/*, navigationExtras*/)
      else
        this.router.navigateByUrl("farmers/" + this.farmer.id + "/alerts"/*, navigationExtras*/)

    }
  }
  loadSettingsFarmer(){
  if (this.currentRoute.startsWith('/supervisors'))
          this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + this.farmer.id + "/settings")
        else {
          if (this.currentRoute.indexOf('resultSearch') != -1)
            this.router.navigateByUrl("resultSearch/farmers/" + this.farmer.id + "/settings")
          else
            this.router.navigateByUrl("farmers/" + this.farmer.id + "/settings")
        }
      }

  settingFarmer() {

   // this.list = this.appService.loadForm(this.projection, this.relatedTo)
   // if (this.list.length > 0) 
    this.loadSettingsFarmer();
 
  //  else{
   /* setTimeout(() => {
      this.loadSettingsFarmer();    
      }
    , 400);
    }*/

  }

  addLocation() {

    this.markerService.getUserLocation(this.farmer.addresses[0])
  }

  checkhLanguage() {
    var patternArabe = new RegExp(PATTERN_NAME_ARABIC);
    var patternDifferentArabe = new RegExp(PATTERN_NAME_FRENCH);
    if (patternArabe.test(this.farmer.fullname)) {
      this.value = true;
    }
    else if (patternDifferentArabe.test(this.farmer.fullname)) {
      this.value = false;
    }
  }
  showMilkQuantity(farmer) {
    if(this.showSettings==true){
    this.formTemplateService.findByLabelOfFormTemplate(SettingsFarmerByLabel.MILK_QUANTITY).subscribe(res1 => {
      this.idFormTemplate = res1;
      if (this.currentRoute.startsWith('/supervisors'))
      this.router.navigateByUrl("supervisors/" + this.supervisorId + '/farmers/' + farmer.id + '/settings/' + this.idFormTemplate)
      else 
      this.router.navigateByUrl('farmers/' + farmer.id + '/settings/' + this.idFormTemplate)})}

  }
  loadForm() {
		this.list = this.appService.loadForm(this.projection,SectionForm.advisor_farmer_settings,this.relatedTo)
		if(this.list.length==0){
			this.showSettings=false;
		}}
    
}
