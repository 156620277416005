import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
//import * as uuidv4 from 'uuid';
import { v4 as uuidv4 } from 'uuid';

import { forkJoin, Observable, Subscription } from 'rxjs';
import { ConfigService } from './config.service';
import { TokenManagementService } from './token-management.service'

export abstract class RealtimeStreamService {

	stompClient = null;
	clientId = uuidv4();
	stompSubscriptions = [];
	devicesSubscription: Subscription;
	connected = false;

	constructor(private topic: string,
		protected configService: ConfigService,
              protected tokenManagementService: TokenManagementService
	) { }

	connect(callback) {
		// let socket = new SockJS('moome-ws');
		let socket = new SockJS(this.configService.getWebSocketPath());//+"?access_token="+this.tokenManagementService.getToken());

		this.stompClient = Stomp.over(socket);
		// this.stompClient.debug = null;
		this.stompClient.connect({ id: this.clientId }, (frame) => {
			this.connected = true;
			callback(frame)
		})
	}

	abstract onMessage(device, body);

	subscribeDevices(devices, callback?) {
		
		if(!this.connected)
			return;
		let obs = []
		devices.forEach(element => {
			obs.push(this.subscribeDevice(element))
		});
		if (obs.length === 0)
			return;

		// obs.push(interval(1000).pipe(map(second => {
        //
        //
		// 	devices.forEach(element => {
		// 		if (!element.lastSignalReceivedDate)
		// 			element.active = false;
		// 		else {
		// 			if (new Date().getTime() - element.lastSignalReceivedDate.getTime() > this.configService.getGatewayTimeout()) {
		// 				element.active = false;
		// 			} else {
		// 				element.active = true;
		// 			}
		// 		}
		// 	})
		// })))
		this.devicesSubscription = forkJoin(obs).subscribe(res => callback ? callback(res) : '');
	}

	subscribeDevice(device) {
		
		return Observable.create(o => {
			if(device){
			var sub = this.stompClient.subscribe(this.topic + "/" + device.code, (body) => {
				this.onMessage(device, body);
				o.next(body);
			});
			this.stompSubscriptions.push(sub);
		}
		})
	}

	unsubscribeDevices() {
		
		this.stompSubscriptions.forEach(sub => {

			// this.stompClient.unsubscribe(sub)
			sub.unsubscribe();
		})
		this.stompSubscriptions = [];
		if (this.devicesSubscription)
			this.devicesSubscription.unsubscribe();
	}

	disconnect() {
		this.unsubscribeDevices();
		if (this.stompClient !== null && this.stompClient.connected) {
			this.stompClient.disconnect();
		}
		this.connected = false;
	}

	subscribeAlerts(callback) {
        this.stompClient.subscribe(this.topic, (body) => {
            callback()
    })
	}

	unsubscribeAlert() {
	 this.stompClient.unsubscribe()
	}

	disconnectAlert() {
		if (this.stompClient !== null) {
            this.stompClient.disconnect();
        }
        console.log("Disconnected");
	}

}
