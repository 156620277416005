import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { NetworkDetectionDialogComponent } from 'projects/publicV2/src/app/network-detection-dialog/network-detection-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomRouter } from 'projects/publicV2/src/app/services/custom-router';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root'
})
export class NetworkDetectionService {
    constructor(public dialog: MatDialog,
        public router:CustomRouter, public appService: AppService) {

          
    }

    async getStatus() {
        let status = await Network.getStatus();
        console.log('Network status', status);
    if (!status.connected)
        this.appService.showNetwork()
        //this.openDialog()
       // this.router.navigateByUrl('/no-connection')

    }

    startListenNetwork() {
        Network.addListener('networkStatusChange', status => {
            console.log('Network status changed', status);
            if (!status.connected)
            this.appService.showNetwork()

             //this.openDialog()
            //this.router.navigateByUrl('/no-connection')
               else 
               this.appService.hideNetwork()
            //else  if (status.connected)
            //this.appService.hideLoading()
            //this.closeDialog()
            //this.router.back()
                
        });
    }

    openDialog() {
         const dialogRef = this.dialog.open(NetworkDetectionDialogComponent);
    }

    closeDialog() {
        this.dialog.closeAll();
    }
}