import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'projects/shared/services/app.service';
import { CustomRouter } from '../services/custom-router';

@Component({
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss']
})
export class CommonDialogComponent implements OnInit {
  currentRoute: any;
  showCancel: boolean = true;
  constructor(public dialogRef: MatDialogRef<CommonDialogComponent>, public appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any, public route: CustomRouter) { }

  ngOnInit() {
    this.currentRoute = this.route.url;

    if (this.currentRoute.indexOf("animals/save") != -1)
      this.showCancel = false;
    else
      this.showCancel = true;

  }

  cancel() {
    if (this.data.onClose)
      this.data.onClose()
    this.dialogRef.close()
  }

  confirm() {
    this.data.onConfirm()
    this.dialogRef.close()
  }

}
