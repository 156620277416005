import { Injectable, Injector } from '@angular/core';
import { Breed } from '../resources/Breed';
import { MoomeRestService, Options } from './moome-rest.service';
import { Species } from '../resources/Species';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { map, Observable, take } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BreedService extends MoomeRestService<Breed>  {

	constructor(injector: Injector, private http: HttpClient, private configService: ConfigService) {
		super(Breed);
	}

	getBreedsWithFilter(page, size, field, direction, key) {
		let param: any=
		{projection: 'breeds',
		
	}	
			if (field != null)
			param.field=field;
			if (direction != null)
			param.direction=direction.toUpperCase();
			if (key != null)
			param.key=key.toLowerCase();
	
		let options: Options = {
			params: 
			   param
			 ,
			 pageParams:{
				size:size,
				page:page
			}
		}
		
		return this.searchPages(`getBreedsByKey`, options).pipe(
			take(1),
			map(result => {
		
			return result
		}))
	}



	getSpecies(field) {

		let param: any=
		{}	
			if (field != null)
			param.field=field;
			
	
		let options: Options = {
			params: 
			   param
		}
		return (this.search(`species`, options) as Observable<any>) as Observable<Species[]>
	}


	isHasAnimals(breed) {
		return this.http.get(this.configService.getApiPath() + "/breeds/hasAnimals/" + breed);
	}
}
