import { Alert, AlertsType, Authorities, LivestockIdentif, TypeAlert } from 'projects/shared/tools/constants';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'projects/shared/services/app.service';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertReadDto } from 'projects/shared/resources/AlertReadDto';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CustomRouter } from '../../services/custom-router';
import { CustomerAlertService } from 'projects/shared/services/customer-alert.service';
import { AlertTypeGroup } from 'projects/shared/resources/AlertTypeGroup';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertTypeGroupService } from 'projects/shared/services/alert-type-group.service';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { Platform } from '@ionic/angular';
import { AlertTypeAppelLaboComponent } from './alert-type-appel-labo/alert-type-appel-labo.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { DeviceFarmerService } from 'projects/shared/services/device-farmer.service';

@Component({
  selector: 'app-alert-type',
  templateUrl: './alert-type.component.html',
  styleUrls: ['./alert-type.component.scss']
})

export class AlertTypeComponent implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  page: number = 0;
  size: number = 12;
  code: any
  img: any
  color: any
  barFiltre: any[] = []
  alertsDetails: any[] = []
  barComp: any[] = []
  projection: any;
  key: any
  innerHeight: any;
  farmerId: any
  loading = true;
  authority = Authorities.ALERTS;
  auth: string[] = [];
  supervisorId: any;
  roleIds: any[] = []
  @ViewChild(CdkVirtualScrollViewport, { static: false }) viewport: CdkVirtualScrollViewport;
  theEnd = false;
  state
  codee: any;
  alertTypeGroup: AlertTypeGroup | any;
  title: any;
  itemSize: any;
  mccId: any
  tableView:Boolean=false;
  displayedColumns: string[] 
  authGetEntreprises: string[] = [];
  role:any;
 currentRoute:any;
 type:any;
 resultsLength: any = 0;
 empty:boolean=false
 nationalIdentification
 nameLocalCode
 thiCards: any[]
 thiEmpty: any
  constructor(private deviceFarmerService: DeviceFarmerService,private platform: Platform, public dialog: MatDialog, private codeTranslate: CodeTranslatePipe, private alertTypeGroupService: AlertTypeGroupService, public appService: AppService, private route: ActivatedRoute, private router: CustomRouter, public sanitizer: DomSanitizer,
    private alertsService: AlertsService, public translate: TranslateService, public customerAlerService: CustomerAlertService) {
    this.code = this.route.snapshot.paramMap.get('code');
    this.appService.connectedCustomer['roles'].forEach(element => {
      this.roleIds.push(element.id);
      this.auth.push((element.isSupervisor).toString())
      if (element.publicAccess == true && element.isSupervisor == true)
        this.supervisorId = this.appService.connectedCustomer.id
        element['authorityRoles'].forEach(authorityRole => {

          if (authorityRole.authority.code == Authorities.ENTREPRISES)
            this.authGetEntreprises.push((authorityRole["get"]).toString())
        })
    })
   
    if ((this.auth.indexOf("true") != -1) || ((this.auth.indexOf("true") != -1) && (this.authGetEntreprises.indexOf("true") == -1)))
      this.role = true
    const navigation = this.router.getCurrentNavigation();
    this.state = navigation.extras.state as { alertTypeGroup: AlertTypeGroup };
    if (this.state) {
      this.alertTypeGroup = this.state.alertTypeGroup;
      this.check();
    }
    else
   if(this.code!=null)
      this.getByCode(this.code);
     else this.check()

      if(TypeAlert.checkIfTankAlertByCode(this.code)){
      if(this.role)
      this.displayedColumns= ['tank', 'entreprise',  'message', 'date'];
      else
      this.displayedColumns= ['tank', 'message', 'date'];

      }
      else if(TypeAlert.checkIfThiAlertByCode(this.code)){
        if(this.role)
      this.displayedColumns= ["name",  'message', 'date'];
      else
      this.displayedColumns= [  'message', 'date'];

      }

      else if(TypeAlert.checkIfConnectionAlertByCode(this.code)) {
        if(this.role)
      this.displayedColumns= ["name",  'uid',  'message', 'date'];
      else
      this.displayedColumns= [  'uid', 'message', 'date'];

    }

      else{
      if(this.role)
      this.displayedColumns= ["name", 'group', 'uid', 'message', 'date'];
      else
        this.displayedColumns= [ 'group', 'uid',   'message', 'date'];
       }
  }


  ngOnInit() {
    this.currentRoute = this.router.url
    this.type = this.route.snapshot.paramMap.get('type');
    this.mccId = this.route.snapshot.paramMap.get('mccId');
   if (this.platform.is("cordova"))
      this.itemSize = "140"
    else
      this.itemSize = "85"
    this.key = this.route.snapshot.paramMap.get('key');
    this.farmerId = this.route.snapshot.paramMap.get('idFarmer');
    if (!this.supervisorId)
      this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
    this.appService.searchText = 'search_by_uid'
    this.innerHeight = (window.innerHeight - 174) + 'px';
    this.projection = TypeAlert.checkAlertTypeGroupProjectionByCode(this.code)
  this.nationalIdentification = LivestockIdentif.NATIONAL_IDENTIFICATION
  this.nameLocalCode = LivestockIdentif.NAME_LOCALCODE


  }
  onPaginateChange(event) {
    this.size = event.pageSize;
    this.page = event.pageIndex;
    this.loadAlertDetail();
  }


  getByCode(code) {
    this.alertTypeGroupService.getByCode(code).subscribe(res => {

      this.alertTypeGroup = res//.resources.filter(element => element.code == code)[0]
      this.check();

    })

  }
  checkType(value) {
    return TypeAlert.checkTypeAlertTanks(value)
  }

  checkTypeConnected(value) {
    return TypeAlert.checkTypeAlertConnected(value)
  }

  checkTypeThi(value) {
    return TypeAlert.checkTypeAlertTHI(value)
  }

  check() {
    if (this.alertTypeGroup)
      this.code = this.alertTypeGroup.code
    if (!this.code) {
      this.img = "/assets/images/Exclusion-1-1.svg"
      this.title = "alerts"
      this.color = "#316a90"
      this.loadSearchAlerts()
    }
    else {
      this.projection = TypeAlert.checkAlertTypeGroupProjectionByCode(this.code)
      this.loadAlertsType()
    }
  }

  loadSearchAlerts() {
    this.key = this.route.snapshot.params.key;
    this.projection = TypeAlert.checkAlertTypeGroupProjectionByCode(this.code)
    this.customerAlerService.getSearchAlerts(this.key, this.page, this.size, this.projection).subscribe(res => {

      if (!res['nextLink']) {
        this.theEnd = true;
      }
      this.appService.hideLoading()
      this.loading = false;
      if (res['resources'])
        this.alertsDetails.push(...res['resources']);
       this.alertsDetails = [...this.alertsDetails]
      this.empty = this.alertsDetails.length == 0;

    }, error => {
      this.loading = false;
      this.appService.hideLoading()
      let message = this.translate.instant(error.error.code);
      let alert = this.translate.instant(Alert.OK)
      this.alertsService.open(message, alert);
    })
  }

  loadSearchAlertsType() {
    this.customerAlerService.getSearchAlertsType(this.key, this.code, this.page, this.size, this.projection).subscribe(res => {
      if (!res['nextLink']) {
        this.theEnd = true;
      }
      this.appService.hideLoading()
      this.loading = false;
      if (res['resources'])
        this.alertsDetails.push(...res['resources']);
      this.alertsDetails = [...this.alertsDetails]
      this.empty = this.alertsDetails.length == 0;

    }, error => {
      this.appService.hideLoading()
      this.loading = false;
      let message = this.translate.instant(error.error.code);
      let alert = this.translate.instant(Alert.OK)
      this.alertsService.open(message, alert);
    })
  }

  loadAlertDetail() {
    if(!this.code)
    this.code = this.route.snapshot.paramMap.get('code');
    this.projection = TypeAlert.checkAlertTypeGroupProjectionByCode(this.code)
    this.farmerId = this.route.snapshot.paramMap.get('idFarmer');
    this.mccId = this.route.snapshot.paramMap.get('mccId');
    this.customerAlerService.findByType(this.code, this.page, this.size, this.projection, this.supervisorId, this.farmerId, this.mccId).subscribe(res => {
      if (!res['nextLink']) {
        this.theEnd = true;
      }
      this.appService.hideLoading()
      this.loading = false;
      if (res['resources'])
        this.alertsDetails.push(...res['resources']);
      this.alertsDetails = [...this.alertsDetails]
      this.empty = this.alertsDetails.length == 0
      this.resultsLength = res['totalElements'];
    }, error => {
      this.appService.hideLoading()
      this.loading = false;
      let message = this.translate.instant(error.error.code);
      let alert = this.translate.instant(Alert.OK)
      this.alertsService.open(message, alert);
    })
  }

  updateRead(selectedRows, supervisorId?) {
    let body = new AlertReadDto();
    let rows: any[] = [];
    selectedRows.forEach(element => {
      if (element.read == false)
        rows.push(element.alert.id)
    });
    body.read = true;
    body.rows = rows;
    if (rows.length != 0)
      this.customerAlerService.updateRead(body, supervisorId).subscribe(data => {
      })
  }

  navRouteCode(code) {
    this.code = code.code;
    this.img = code.img;
    this.title = code.title
    this.color = code.color
    this.loadAlertsType()
  }

  ngOnDestroy() {
    this.appService.verif = false
    if (this.supervisorId)
      this.updateRead(this.alertsDetails, this.supervisorId)
    else
      this.updateRead(this.alertsDetails)
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1)
      this.appService.searchText = 'search_by_uid_name_phone'
  }


  loadAlertsType() {
    this.alertsDetails = []
    if (!this.color)
      this.color = this.alertTypeGroup.color
    if (!this.title) {
      if (!this.alertTypeGroup.title)
        this.title = this.codeTranslate.transform(this.alertTypeGroup)
      else
        this.title = this.alertTypeGroup.title
    }

    if (!this.img)
      this.img = this.alertTypeGroup.coloredIcon

    this.page = 0
    this.theEnd = false;
    if (this.key) {
      this.img = "/assets/images/Exclusion-1-1.svg"
      this.title = "alerts"
      this.color = "#316a90"
      this.loadSearchAlertsType()
    }
    else
      this.loadAlertDetail()


    setTimeout(() => {
      
      this.alertTypeGroupService.findAlertTypeGroupByRole(this.roleIds).subscribe(res => {
        if (res['resources'])
          this.barComp = res['resources'].sort((a, b) => {
            if (a.code > b.code) {
              return 1
            } else if (a.code < b.code) {
              return -1
            }
            return 0
          })
          this.barFiltre = this.barComp.filter(bar => bar.code != this.code)
          this.deviceFarmerService.getDevicesCode().subscribe((data: any) => {
            this.thiCards = data
            this.thiEmpty = this.thiCards.length == 0;
          if(this.thiEmpty){
          this.barFiltre = this.barFiltre.filter(bar => bar.code != "09")
          this.barFiltre = this.barFiltre.filter(bar => bar.code != "08")}
        })
        
      });
    }, 200);

  }

  checkBarAuthority(bar) {
    if (bar.code == '08')
      return Authorities.GATEWAYS
    else if (bar.code == '09')
      return Authorities.TAGS
    else if (bar.code == '10')
      return Authorities.TANKS
    else
      return Authorities.ANIMALS
  }

  nextBatch(e) {
    if (this.theEnd) {
      return;
    }
    console.log(this.viewport.getDataLength(), e, this.viewport.getRenderedRange())
    if (this.viewport.getDataLength() - 3 <= this.viewport.getRenderedRange().end && !this.loading) {
      this.loading = true
      this.page = this.viewport.getDataLength() / 12
      if (this.title == "alerts") {
        this.loadSearchAlerts()
      } else if (this.key) {
        this.loadSearchAlertsType()
      } else {
        this.loadAlertDetail();
      }
    }
  }


  back() {
    
    this.router.back('')
    this.code = this.route.snapshot.paramMap.get('code');
    this.navRouteCode(this.code)
   
  

  }
  confirmAppel() {
    //googleAnalytics
    this.appService.googleAnalyticsAllPages('call-lab',null,null,null)
    const dialogRef = this.dialog.open(AlertTypeAppelLaboComponent, {
      data: {
        message: this.translate.instant('CALL_LAB_POPUP'),
        title: this.translate.instant('CALL_LAB'),

      }
    })
  }



  apply(alertDetail) {
    if (AlertsType.timelineRouter(this.type)) {
      if (alertDetail.alert.animal.sexe) {
      if (alertDetail.alert.animal.sexe == "FEMALE") {
        if (this.role)
          this.router.navigateByUrl('/farmers/' + alertDetail.alert.animal.address.customer.id + '/animals/' + alertDetail.alert.animal.id + '/timelines')
        else {
          if (this.currentRoute.startsWith('/supervisors'))
            this.router.navigateByUrl('supervisors/' + this.supervisorId + '/farmers/' + alertDetail.alert.animal.address.customer.id + '/animals/' + alertDetail.alert.animal.id + '/timelines')
          else
            this.router.navigateByUrl('/animals/' + alertDetail.alert.animal.id + '/timelines')
        }
      } else {
        /* const dialogRef = this.dialog.open(AnimalCardComponent, {
           width: '350px',
         });
         dialogRef.afterClosed().subscribe(result => {
         });*/
      }
    }
  }
  }
}
