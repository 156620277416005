import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'projects/shared/services/app.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { CustomRouter } from '../services/custom-router';
import { DateAdapter } from '@angular/material/core';
import { Authorities } from 'projects/shared/tools/constants';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
  @Input() value;
  @Input() color;
  isDropdownOpen: boolean = false;
  language
  selectedLanguage = {
    name: '',
    flag: ''
  };
  languages = [
    { name: 'عربية', flag: '/assets/images/tobias-Flag-of-Tunisia.svg', use: 'ar' },
    { name: 'Français', flag: '/assets/images/flag-for-flag-france-svgrepo-com.svg', use: 'fr' },
    { name: 'English', flag: '/assets/images/Flag_of_the_United_Kingdom.svg', use: 'en' },
    { name: 'Kinyarwanda', flag: '/assets/images/Rwanda-flagpalette.svg', use: 'rw' },
    { name: 'Indonesia', flag: '/assets/images/indonesia-flag-icon.svg', use: 'ind' },
    // Ajoutez d'autres langues selon vos besoins
  ];
  constructor(public translate: TranslateService, public appService: AppService, public tokenManagementService: TokenManagementService, private router: CustomRouter,
    private dateAdapter: DateAdapter<Date>) {
     }

  ngOnInit(): void {
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  useLanguage(language) {
    this.tokenManagementService.translateLanguage(language.use);
    this.translate.use(language.use).subscribe(res => {
      this.dateAdapter.setLocale(language.use + '-TN');
      this.language = language.use
      this.selectedLanguage = language;
      this.value=this.selectedLanguage ;
      this.isDropdownOpen = false;
      this.appService.saveLanguage()
    });
  }

}
