<div class="d-flex justify-content-end m-2">
    <img src="/assets/images/Union-5.svg" (click)="closePopUp()" />
</div>

<div class=" text-center title-style-dialog d-flex justify-content-center" style="margin-top: 20px!important;"
    [ngClass]="{rtl:appService.rtl()}">
    {{'FOR_MORE_INFORMATION' | translate }}
</div>

<div class=" d-flex justify-content-center " style="margin-top: 34px!important; margin-bottom: 44px!important;">
    <div [ngClass]="{rtl:appService.rtl()}">
        <span class="message-style help-style" [ngClass]="{rtl:appService.rtl()}">{{'LEARN_MORE' | translate }}</span>
    </div>
</div>

<div class="row  d-flex justify-content-center">
    <div class="col-6">
        <div class="float-end">
            <a (click)="openLink('https://www.facebook.com/watch/moome.tn/2929288183988007/')" target="blank"><i><img
                        src="/assets/images/facebook-icon-1.svg"></i></a>

        </div>
    </div>
    <div class="col-6">
        <div class="float-start">
            <a (click)="openLink('https://www.youtube.com/channel/UCJMkStxryqvreAWsCAxJbXg/videos')"
                target="blank"><i><img src="/assets/images/timeline/youtube-brands.svg"></i></a>
        </div>
    </div>
</div>