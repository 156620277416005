import { NgModule } from '@angular/core';
//import { NGX_MAT_FILE_INPUT_CONFIG, MaterialFileInputModule } from 'ngx-material-file-input';
import { MatDialogModule } from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';

import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';

export const config = {
  sizeUnit: 'Octet'
};

@NgModule ({
    imports : [MatButtonToggleModule, MatRadioModule, MatExpansionModule, MatSortModule,MatSnackBarModule, MatTooltipModule,
MatAutocompleteModule, MatCardModule, MatPaginatorModule, MatSelectModule,MatDialogModule,MatMenuModule,MatTableModule,MatTabsModule,MatDatepickerModule,MatButtonModule,MatFormFieldModule,MatInputModule,MatNativeDateModule,MatSidenavModule,MatToolbarModule,MatIconModule,MatCheckboxModule,MatListModule/*,MaterialFileInputModule*/],
    exports : [MatButtonToggleModule, MatRadioModule, MatExpansionModule, MatSortModule,MatTooltipModule, MatSnackBarModule,MatAutocompleteModule, MatCardModule, MatPaginatorModule, MatSelectModule,MatDialogModule,MatMenuModule,MatTableModule,MatTabsModule,MatDatepickerModule,MatButtonModule,MatFormFieldModule,MatInputModule,MatNativeDateModule,MatSidenavModule,MatToolbarModule,MatIconModule,MatCheckboxModule,MatListModule/*,MaterialFileInputModule,*/]
,  //  providers:[{ provide: NGX_MAT_FILE_INPUT_CONFIG, useValue: config }]
})

export class MaterialModule { }
