import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { AlertType } from "./AlertType";
@HateoasResource('alertTypeGroups')
export class AlertTypeGroup extends Resource {
    id: Number
    code: any;
    color: string;
    labelFr: string;
    labelEn: string;
    labelAr: string;
    icon: string;
    coloredIcon: string;
    barIcon: string;
    alertTypes: AlertType[];

  }