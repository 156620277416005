import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'projects/shared/services/app.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { GroupService } from 'projects/shared/services/group.service';
import { CustomRouter } from '../services/custom-router';
import { Authorities, Roles } from 'projects/shared/tools/constants';
import { FarmerService } from 'projects/shared/services/farmer.service';
import { CustomerService } from 'projects/shared/services/customer.service';
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { DeviceFarmerService } from 'projects/shared/services/device-farmer.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonDialogComponent } from '../common-dialog/common-dialog.component';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  showElements = false;
  fullname: String;
  phone: String;
  language: String
  auth: string[] = [];
  connectedCustomer: any;
  defaultGroupId: any;
  getSupervisors = []
  getTanks = []
  list = []
  service: any
  authAdmin: string[] = [];
  authFarmer: string[] = [];
  supervisorId: any
  getCustomerSetting = []
  idFormTemplateStock
  thiCards: any[]
  thiEmpty: any
  selectedLanguage = {};
  color='color-transparent'
  constructor(public dialog: MatDialog, private deviceFarmerService: DeviceFarmerService, public tokenManagementService: TokenManagementService, public appService: AppService, public groupService: GroupService, public router: CustomRouter,
    public translate: TranslateService, private dateAdapter: DateAdapter<Date>, private farmerService: FarmerService, private customerService: CustomerService, private supervicorService: SupervisorService) {
    this.fullname = this.appService.connectedCustomer.fullname;
    this.phone = this.appService.connectedCustomer.phone;
    this.service = this.customerService
    this.appService.connectedCustomer['roles'].forEach(role => {
      role['authorityRoles'].forEach(authorityRole => {
        if (authorityRole.authority.code == Authorities.SUPERVISORS)
          this.getSupervisors.push((authorityRole["get"]).toString());
        if (authorityRole.authority.code == Authorities.TANKS)
          this.getTanks.push((authorityRole["get"]).toString());
        if (authorityRole.authority.code == Authorities.CUSTOMER_SETTING)
          this.getCustomerSetting.push((authorityRole["get"]).toString());
        if (authorityRole.authority.code == Authorities.CUSTOMER_SETTING)
          this.getCustomerSetting.push((authorityRole["edit"]).toString());
      })
    })
    const l = this.tokenManagementService.getTranslateLanguage()
    if (l) {
      this.language = l;
    this.selectedLanguage=this.appService.chooseLanguage(this.language);
    }
  }

  ngOnInit() {
    this.appService.listId=[]
    this.supervisorId = this.appService.connectedCustomer.id
    //this.appService.verifValueUid()
    this.appService.verif = false
    
    
    this.defaultGroupId = this.appService.defaultGroupId
    this.appService.connectedCustomer['roles'].forEach(role => {
      //this.auth = this.auth || (role.isSupervisor)
      this.auth.push((role.isSupervisor).toString())
      this.authAdmin.push((role.adminAccess).toString())
      if (role.code == Roles.ROLE_FARMER) {
        this.service = this.farmerService
        this.authFarmer.push((true).toString())
      }
    })
    this.list = [
      {
        icon: "/assets/images/Home-menu.svg",
        router: "/home", text: "HOME", style: "color-dark-blue"
      },
      { icon: "/assets/images/Alert-menu.svg", router: "/alerts", text: "ALERTS", style: "color-dark-blue", authority: Authorities.ALERTS },
      {
        icon: (this.auth.indexOf("true") != -1 || this.getSupervisors.indexOf("true") != -1) ? "/assets/images/farmer_icon_neutral_1.svg" : "/assets/images/Troups-menu.svg",
        router: (this.auth.indexOf("true") != -1) ? "/farmers" : ((this.getSupervisors.indexOf("true") != -1) ? "/supervisors" : "/animals/groups/" + this.defaultGroupId),
        text: (this.auth.indexOf("true") != -1) ? "FARMERS_MOB" : ((this.getSupervisors.indexOf("true") != -1) ? "SUPERVISORSS" : "TROUPS"),
        style: "color-dark-blue",
        authority: (this.auth.indexOf("true") != -1 || this.getSupervisors.indexOf("true") != -1) ? Authorities.SUPERVISORS : Authorities.ANIMALS
      },
      {
        icon: "/assets/images/Groupe_icon.svg",
        router: "/animals/groups",
        text: "GROUPS2",
        style: "color-dark-blue",
        authority: this.authFarmer.indexOf("true") != -1 ? Authorities.ANIMALS : Authorities.NO_ACCESSIBLE
      },

      { icon: "/assets/images/calendar-week-solid.svg", router: "/weekly-plan", text: "WEEK_PLAN", style: "color-dark-blue", authority: (this.authAdmin.indexOf("true") != -1) ? Authorities.NO_ACCESSIBLE : Authorities.ANIMALS },

      {
        icon: '/assets/images/tank_dark_menu.svg', router: 'settings/' + this.appService.formTemplateFormFarmerMilkProductionId + '/' + Authorities.FARMER_MILK_PRODUCTION,
        text: "MILK_PRODUCTION", style: "color-dark-blue ", authority: (this.authFarmer.indexOf("true") != -1) ? Authorities.FARMER_MILK_PRODUCTION : Authorities.NO_ACCESSIBLE
      },
      {
        icon: "/assets/images/feed-consumption-icon-menus_new.svg", router: 'settings/' + this.appService.formTemplateFormFarmerFeedConsumptionId + '/' + Authorities.FARMER_FEED_CONSUMPTION,
        text: "CONCENTRATED_FEED", style: "color-dark-blue ", authority: (this.authFarmer.indexOf("true") != -1) ? Authorities.FARMER_FEED_CONSUMPTION : Authorities.NO_ACCESSIBLE
      },
      { icon: "/assets/images/new_Other_income_costs_menus.svg", router: "/setting-economy", text: "ECONOMY", style: "color-dark-blue ", authority: (this.authFarmer.indexOf("true") != -1) ? Authorities.FORM : Authorities.NO_ACCESSIBLE },
      { icon: "/assets/images/stock_1_menu.svg", router: "/stock", text: "STOCK", style: "color-dark-blue ", authority: Authorities.STOCK },


      { icon: "/assets/images/graphic_eq.svg", router: "/setting-graphs", text: "LIST_GRAPH", style: "color-dark-blue" },

      { icon: "/assets/images/gear-option-menu.svg", router: "/settings", text: "SETTINGS_MOB", style: "color-dark-blue", authority: (this.auth.indexOf("true") != -1 || this.getSupervisors.indexOf("true") != -1) ? Authorities.SUPERVISORS : Authorities.FARMERS },
      {
        icon: '/assets/cart-shopping-solid.svg',
        router: "/shop",
        text: "SHOP",
        style: "color-dark-blue",
        authority: Authorities.ORDERS
      },
      { icon: "/assets/images/feedback-icon.svg", router: '/feedback', text: "FEEDBACK", style: "color-dark-blue ", authority: Authorities.FEEDBACK },


      { icon: "/assets/images/CCL_icon_2.svg", router: "/mcc", text: "MCC", style: "color-dark-blue", authority: this.auth.indexOf("true") != -1 ? Authorities.ENTREPRISES : Authorities.NO_ACCESSIBLE },
      { icon: "/assets/images/milk_tank_icon_1_menu.svg", router: "/tanks", text: "TANKS-MOB", style: "color-dark-blue ", authority: (this.auth.indexOf("true") == -1 && this.authAdmin.indexOf("true") == -1) ? Authorities.TANKS : Authorities.NO_ACCESSIBLE },
      {
        icon: "/assets/images/maps.svg", router: "supervisors/" + this.supervisorId + "/map", text: "LOCATION_OF_FARMERS", style: "color-dark-blue",
        authority: (this.auth.indexOf("true") != -1) ? Authorities.SUPERVISORS : Authorities.NO_ACCESSIBLE
      },

      { icon: "/assets/images/Groupe-367.svg", router: "/help", text: "FOR_MORE_INFORMATION", style: "color-dark-blue", authority: (this.auth.indexOf("true") != -1 || this.getSupervisors.indexOf("true") != -1) ? Authorities.SUPERVISORS : Authorities.ANIMALS },
      { icon: "/assets/images/phone-flip-solid.svg", router: "/contact", text: "CONTACT", style: "color-dark-blue" },
     /* { icon: "/assets/images/delete-svgrepo-com.svg", text: "DELETE_ACCOUNT", style: "color-dark-blue" },*/
      { icon: "/assets/images/logout-menu.svg", router: "/login", text: "LOG_OUT", style: "color" },

      /*
      { icon: "/assets/images/alert/THI_high_menu.svg", router: "/thi", text: "THI", style: "color-dark-blue ", authority: (this.authFarmer.indexOf("true") != -1) ? Authorities.TAGS : Authorities.NO_ACCESSIBLE },
      { icon: "/assets/images/report-svgrepo-com.svg", router: "supervisors/" + this.supervisorId + "/reports", text: "REPORT", style: "color-dark-blue d-none d-sm-block", authority: ((this.auth.indexOf("true") != -1) && this.authAdmin.indexOf("true") == -1 || (this.getSupervisors.indexOf("true") != -1 && this.authAdmin.indexOf("true") == -1)) ? Authorities.SUPERVISORS : Authorities.NO_ACCESSIBLE },
     { icon: "/assets/images/graph-bar-svgrepo-com.svg", router: (this.authFarmer.indexOf("true") != -1) ? "rumination-ui" : "supervisors/" + this.supervisorId + "/rumination-ui", text: "RUMINATING_RESTING_TIME_MAJUSCULE", style: "color-dark-blue d-none d-sm-block", authority: (this.authFarmer.indexOf("true") != -1 || this.getSupervisors.indexOf("true") != -1) ? Authorities.RUMINATION_UI : Authorities.NO_ACCESSIBLE },
     { icon: "/assets/images/graph-line-svgrepo-com.svg", router: (this.authFarmer.indexOf("true") != -1) ? "humidity-temp" : "supervisors/" + this.supervisorId + "/humidity-temp", text: "THI_GRAPH" , style: "color-dark-blue d-none d-sm-block ", authority: (this.auth.indexOf("true") != -1 || this.getSupervisors.indexOf("true") != -1 || this.authFarmer.indexOf("true") != -1) ? Authorities.REPORTS_HUMIDITY_TEMP : Authorities.NO_ACCESSIBLE },
     { icon: "/assets/images/graph-svgrepo-com.svg", router: "supervisors/" + this.supervisorId + "/temp_graph", text: "TEMP_GRAPH_MAJUSCULE", style: "color-dark-blue d-none d-sm-block ", authority: (this.getTanks.indexOf("true") != -1) ? Authorities.REPORTS_TEMP_GRAPHS : Authorities.NO_ACCESSIBLE },
     { icon: "/assets/images/reproReport.svg", router: "/reproduction-report", text: "REPRODUCTION_REPORT", style: "color-dark-blue", authority: (this.authAdmin.indexOf("true") != -1 ) ? Authorities.NO_ACCESSIBLE : Authorities.ANIMALS },
     {
       icon:'/assets/icon-graph-feed-milk.svg',
       router: "/milk-feed-report",
       text: "MILK_FEED_GRAPH",
       style: "color-dark-blue",
       authority:Authorities.REPORTS_FEED_MILK
     },*/

    ]
    this.deviceFarmerService.getDevicesCode().subscribe((data: any) => {
      this.thiCards = data
      this.thiEmpty = this.thiCards.length == 0;
      if (!this.thiEmpty)
        this.list.splice(10, 0, { icon: "/assets/images/alert/THI_high_menu.svg", router: "/thi", text: "THI", style: "color-dark-blue ", authority: (this.authFarmer.indexOf("true") != -1) ? Authorities.TAGS : Authorities.NO_ACCESSIBLE })
    })
    if ((this.authFarmer.indexOf("true") != -1)) {
      this.list.splice(4, 0, { icon: "/assets/images/dark-dashboard.svg", router: "/dashboard", text: "DASHBOARD", style: "color-dark-blue" },)
    }
    if (this.auth.indexOf("true") != -1)
      this.service = this.supervicorService
    //googleAnalytics
    this.appService.googleAnalyticsAllPages('menu')
  }




  useLanguage(language) {
    this.tokenManagementService.translateLanguage(language);
    this.translate.use(language).subscribe(res => {
      this.dateAdapter.setLocale(language + '-TN');
      this.language = language
      // this.appService.changeRecaptchaLanguage(language)
      if (this.getCustomerSetting.indexOf("true") != -1)
        this.appService.saveLanguage()

    });
  }
 /* deleteAccount() {
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      data: {
        message: this.translate.instant('CONFIRM_DELETE_ACCOUNT'),
        title: this.translate.instant('DELETE_ACCOUNT'),
        onConfirm: () => {
          this.customerService.deleteCustomer().subscribe(res => {
            this.appService.successMessage( this.translate.instant('DELETED_ACCOUNT_SUCCESS') + " "+ this.translate.instant('SUCCESS') + " " )
            setTimeout(() => {
              this.login()
            }, 800);
          }
            , error => {
              this.appService.error(error.error.code)
            });
        }
      }
    });
  }*/
  login(){
    this.router.navigateByUrl('/login')
  }

  /* changeRecaptchaLanguage(language) {
     var script = document.createElement('script');
     script.src = 'https://www.google.com/recaptcha/api.js?hl=' + language;
     script.async = true;
     script.defer = true;
     document.querySelector('head').appendChild(script);
   }*/



}
