import { Injectable, Injector } from '@angular/core';
import { CustomerCommonService } from './customer-common.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable, take } from 'rxjs';
import { ConfigService } from './config.service';
import { TokenManagementService } from './token-management.service';
import { Farmer } from '../resources/Farmer';
import { Options } from './moome-rest.service';

@Injectable({
	providedIn: 'root'
})

export class FarmerService extends CustomerCommonService<Farmer> {
  importFromExcelAnimal(formData: FormData) {
	return this.http.post(`api/import_from_excel/animals`, formData);
}

	
	constructor(injector: Injector, public http: HttpClient, private configService: ConfigService,
		public tokenManagementService: TokenManagementService) {
		super(Farmer);
		
	}


	getById(id) {
		let options: Options = {
			params: { 
				id: id
			 }
		}
		return (this.search(`getById`, options))

	}

	saveFarmer(body) {
		return this.http.post(this.configService.getApiPath() + `/farmers/save`, body);
	}

	checkCode(body) {
		let httpOptions = { headers: new HttpHeaders({
			'language':  this.tokenManagementService.getTranslateLanguage()		  })
		};
		return this.http.post(this.configService.getApiPath() + `/farmers/check-code`, body, httpOptions);
	}

	resendCode(body) {
		let httpOptions = { headers: new HttpHeaders({
			'language':  this.tokenManagementService.getTranslateLanguage(),
		  })
		};
		return this.http.post(this.configService.getApiPath() + `/farmers/resend-sms`, body, httpOptions);
	}
	forgetPassword(body) {
		let httpOptions = { headers: new HttpHeaders({
			'language':  this.tokenManagementService.getTranslateLanguage(),
		  })
		};
		return this.http.post(this.configService.getApiPath() + `/farmers/forget-password`, body, httpOptions);
	}
	saveFarmerMobile(body) {
		let httpOptions = { headers: new HttpHeaders({
			'language':  this.tokenManagementService.getTranslateLanguage(),
		  })
		};
		return this.http.post(this.configService.getApiPath() + `/farmers/save-app`, body, httpOptions);
	}

	sendSms(body) {
		let httpOptions = { headers: new HttpHeaders({
			'language':  this.tokenManagementService.getTranslateLanguage(),
		  })
		};
		return this.http.post(this.configService.getApiPath() + `/farmers/send-sms`, body, httpOptions);
	}

	isHasAnimals(customer): Observable<any> {
		return this.http.get(this.configService.getApiPath() + "/farmers/hasAnimals/" + customer);
	}

	deleteFarmer(id) {
		return this.http.delete(this.configService.getApiPath() + `/farmers/${id}/delete`);
	}

	updatePasswordApp(customer) {
		let httpOptions = { headers: new HttpHeaders({
			'language':  this.tokenManagementService.getTranslateLanguage(),
		  })
		};
		return this.http.put(this.configService.getApiPath() + `/farmers/update-password`, customer, httpOptions);
	}

	updatePassword(id, password) {
		return this.http.put(this.configService.getApiPath() + `/farmers/${id}/updatePassword`, password);
	}

	assignHumiditySensor(id, sensors) {
		return this.http.put(this.configService.getApiPath() + `/farmers/${id}/assignSensors`, sensors);
	}


	getSupervisor(id) {
		return this.http.get(this.configService.getApiPath() + `/farmers/${id}/findCustomerById`, id)
			
	}

	importFromExcel(formData) {
		return this.http.post(`api/import_from_excel/farmers`, formData);
	}

	importAnimalFromExcel(formData: FormData , phone) {
		return this.http.post(`api/import_from_excel/animals/${phone}`, formData);
	}

	importQuantityMccOfFarmers(formData: FormData , phone) {
		return this.http.post(`api/import_from_excel/farmers/${phone}`, formData);
	}

	updateFullname(fullname){
		return this.http.put(this.configService.getApiPath()+ `/farmers/updateFullname`, fullname);
	}

	updateAddress(countryId){
		return this.http.put(this.configService.getApiPath()+ `/farmers/updateAddress`, countryId);
	}

	verifPassword(password){
		return this.http.get(this.configService.getApiPath()+ `/farmers/verifPassword?password=${password}`);
	}

	updatePhone(phone){
		return this.http.put(this.configService.getApiPath()+ `/farmers/updatePhone`, phone);
	}

	updateUsername(customer){
		let httpOptions = { headers: new HttpHeaders({
			'language':  this.tokenManagementService.getTranslateLanguage(),
		  })
		};
		
		return this.http.put(this.configService.getApiPath()+ `/farmers/updateUsername`, customer, httpOptions);
	}

	getByPhone(phone) {
		return this.searchSingle(`findByPhone?phone=${phone}`)
	}

	saveDeviceToken(token) {
		return this.http.put(this.configService.getApiPath()+ `/farmers/save-device-token`, token);
	}

	updateEnabled(username, value) {
		return this.http.put(this.configService.getApiPath() + `/farmers/${username}/updateEnabled`, value);
	}

	getFarmerOfSupervisorByProject(projectIds) {
		
		let param: any=
        {projectIds: projectIds,
        }
       

        let options: Options = {
            params: 
               param
             ,
			 pageParams: {
				
			 }
        }
		return this.customRequestGet(`/getFarmersByProject`,null,options)
	}

	getFarmerOfSupervisor() {
		return this.customRequestGet(`/getFarmerOfSupervisor`).pipe(
			take(1),
			map(result => {
				return result['_embedded']?.customers
		
		}))
	}

	getFarmerOfSupervisorForReproReport() {
		return this.customRequestGet(`/getFarmerOfSupervisorForReproReport`).pipe(
			take(1),
			map(result => {
				return result['_embedded']?.customers
		
		}))
	}
	
	
	saveWebToken(token) {
		return this.http.put(this.configService.getApiPath()+ `/farmers/save-web-token`, token);
	}
}
