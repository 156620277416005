import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import {  NavigationExtras, Router } from "@angular/router";




@Injectable({
    providedIn:"root"
})
export class CustomRouter{
    get url(){
        return this.router.url
    }
  

    constructor(private router: Router, private location: Location){
        
    }

    history = []

    navigateByUrl(url: string, extras?: NavigationExtras): Promise<boolean>{
      // if(url!="/")
       this.history.push(this.location.path())
        return this.router.navigateByUrl(url, extras || {});
    }

    back(_default?: string): Promise<boolean>{
        if(this.history.length == 0){
            if(!_default){
                return
            }
            this.navigateRoot(_default, null);
        }
        const lastUrl = this.history.pop();
        return this.router.navigateByUrl(lastUrl)
    }

    navigateRoot(url: string, extras?: NavigationExtras): Promise<boolean>{
        this.history = []
        return this.navigateByUrl(url, extras);
    }

    canBack(){
        return this.history.length !== 0
    }

    getCurrentNavigation() {
        return this.router.getCurrentNavigation()
    }

   
}
