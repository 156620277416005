<div>
  <button type="button" [ngClass]="color" class="btn style-button text-style" data-bs-toggle="dropdown"
    aria-expanded="false" (click)="toggleDropdown()">
    <div class="d-flex flex-nowrap">
      <div class="p-2 w-60"><img [src]="value.flag" alt="Language Flag" class="flag"></div>
      <div class="p-2 w-60">{{ value.name }}</div>
      <div class="p-2 w-30"><mat-icon>{{ isDropdownOpen ? 'expand_less' : 'expand_more' }}</mat-icon></div>
    </div>
    <div class=" dropdown text-style custom-dropdown" [ngClass]="{'show': isDropdownOpen}">
      <div class="d-flex flex-nowrap" *ngFor="let language of languages" (click)="useLanguage(language)">
        <div class="p-2 w-40"><img [src]="language.flag" alt="Language Flag" class="flag"></div>
        <div class="p-2 w-60">{{ language.name }}</div>
      </div>
    </div>
  </button>


 

</div>