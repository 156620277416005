import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import {Address} from "./Address";
import { Role } from './Role';
import { Entreprise } from './Entreprise';
@HateoasResource('customers')
export class Customer extends Resource {
    id: number;
    fullname: string;
    username: string;
    phone: string;
    email: string;
    password: string;
    roles: Role[] | any;
    addresses: Address[];
    supervisors: Customer[] | any;
    farmers: Customer[] | any
    totalMilkProduction: number
    defaultGroupColor
    temp
    humidity
    thi
    sex: String;
    entreprises: Entreprise [] | any
    entreprise:any;


}
