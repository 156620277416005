<!--<div class="row style-bar-troups w-100" ngxHideOnScroll [scrollingElementSelector]="'body'" [propertyUsedToHide]="'top'"
    [valueWhenHidden]="'-90px'" [valueWhenShown]="'90px'">-->
    <div class="row style-bar-troups w-100" >
    <form [formGroup]="formGroup">
        <div class="col text-center">
            <input matInput class="input-mob input width f-size"
                [ngStyle]="{'font-size': appService.rtl()? '25px': '19px'}" formControlName="key"
                [ngClass]="{rtl:appService.rtl()}" type="text" placeholder="{{appService.searchText | translate}}..." (keyup.enter)="search(formGroup.controls['key'].value)">
            <div [ngClass]="appService.rtl()? 'float-start position-icon-ar' : 'float-end position-icon'"
            ><img src=" /assets/images/search1.svg " (click)="search(formGroup.controls['key'].value)">
            </div>
        </div>
    </form>
</div>