import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimalNoteService } from 'projects/shared/services/animal-note.service';
import { AnimalService } from 'projects/shared/services/animal.service';
import { AppService } from 'projects/shared/services/app.service';
import { CustomRouter } from '../services/custom-router';
import { Authorities } from 'projects/shared/tools/constants'
@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  id: any
  uid: any
  code: any
  colorGroup: any
  animal: any
  customerId: any
  notes: any
  key: any
  router
  idFarmer: any;
  role: any
  authority = Authorities.ANIMAL_NOTES
  auth: string[] = [];
  currentRoute: any;
  supervisorId: any;
  constructor(router: CustomRouter, public appService: AppService, private route: ActivatedRoute, public animalService: AnimalService,
    public animalNoteService: AnimalNoteService) {
    this.router = router
  }

  ngOnInit() {
    this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
    this.currentRoute = this.router.url
    this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1) {
      this.role = true;
      this.customerId = this.idFarmer
    } else {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.customerId = this.idFarmer
      }
      else {
        this.role = false
        this.customerId = this.appService.connectedCustomer.id;
      }
    }

    this.id = this.route.snapshot.paramMap.get('id');
    // this.key = this.route.snapshot.paramMap.get('key');
    this.route.queryParams.subscribe(params => {

      this.key = params.key;
    })
    //this.customerId = this.appService.connectedCustomer.id;

    if (this.key)
      this.searchNotes()
    else
      this.findNotes();

    this.appService.searchText = "serach_note"
  }

  findNotes() {
    this.appService.showLoading()
    this.animalNoteService.findAnimalNotes(this.id, this.customerId).subscribe(res => {
      this.notes = res['resources']
      this.appService.hideLoading()
    })
  }

  ngOnDestroy() {
    if (this.role) {
      this.appService.searchText = 'search_by_uid_name_phone'
    } else
      this.appService.searchText = "search_by_uid"
  }

  back() {
    this.appService.verif = false
    if (!this.role) {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.router.navigateByUrl('/supervisors/' + this.supervisorId + '/farmers/' + this.idFarmer + '/animals/' + this.id + '/settings');
      }
      else
        this.router.navigateByUrl('/animals/' + this.id + '/settings')
    }
    else
      this.router.navigateByUrl('farmers/' + this.idFarmer + '/animals/' + this.id + '/settings')
  }

  takeNote() {
    if (!this.role) {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.router.navigateByUrl('/supervisors/' + this.supervisorId + '/farmers/' + this.idFarmer + '/animals/' + this.id + '/settings/notes/save');
      }
      else
        this.router.navigateByUrl('/animals/' + this.id + '/settings/notes/save')
    } else
      this.router.navigateByUrl('farmers/' + this.idFarmer + '/animals/' + this.id + '/settings/notes/save')
  }

  searchNotes() {

    this.animalNoteService.findAnimalNotesSearch(parseInt(this.id), this.customerId, this.key).subscribe(res => {
      this.notes = res.resources
      this.appService.hideLoading();

    })
  }
}
