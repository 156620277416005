import { Component, Input, OnInit } from '@angular/core';
import { CustomRouter } from '../../services/custom-router';

@Component({
  selector: 'app-dashbord-card',
  templateUrl: './dashbord-card.component.html',
  styleUrls: ['./dashbord-card.component.scss']
})
export class DashbordCardComponent implements OnInit {
@Input() element : any
  constructor(public router:CustomRouter) { }

  ngOnInit(): void {
  }
navigate(url){
  this.router.navigateByUrl(url)
}
}
