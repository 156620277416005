<ng-container *ngIf="!farmerLength || animals || tankCards">
    <div class="row px-2">
        <app-farmer-card *ngFor="let farmer of farmers" class="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mt-4"
            [farmer]="farmer">
        </app-farmer-card>
    </div>
    <div class="row px-2">
        <app-animal-card *ngFor="let animal of animals" [animal]="animal" (reload)="loadFramersWithAnimals(key)"
            [signal]="animal.device?.active" [imgBattery]="appService.checkImgBattery(animal.device)"
            class="col-12 col-sm-6 col-md-6 col-lg-4">
        </app-animal-card>
    </div>

    <div class="row px-2">
        <app-tank-card *ngFor="let tank of tankCards" [tank]="tank" class="col-12 col-sm-6 col-md-6 col-lg-4">
        </app-tank-card>
    </div>
</ng-container>
<div *ngIf="farmerLength || !animals || !loading " class="mt-5">
    <app-no-animals [text]="'NO_UID_Farmers'" [value]="value" [getAuthorized]="authorityAnimals"></app-no-animals>
</div>
<!--<div *ngIf="!tankCards" class="mt-5">
    <app-no-animals [text]="'NO_TANKS'" [getAuthorized]="authorityTanks"></app-no-animals>
</div>-->