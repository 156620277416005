import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';

@Component({
  selector: 'app-large-rect-card',
  templateUrl: './large-rect-card.component.html',
  styleUrls: ['./large-rect-card.component.scss']
})
export class LargeRectCardComponent implements OnInit {
@Input() element:any
  constructor(public appService: AppService) { }

  ngOnInit(): void {
  }

}
