import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Feedback } from "../resources/Feedback";
import { ConfigService } from "./config.service";
import { MoomeRestService } from "./moome-rest.service";
import { Options } from './moome-rest.service';
import { map, Observable, take } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CustomerFeedbackService extends MoomeRestService<Feedback> {


	constructor(public http: HttpClient, injector: Injector, private configService: ConfigService) {
		super(Feedback);
	}

	saveFeedback(body) {
		return this.http.post(this.configService.getApiPath() + `/customerFeedbacks/`, body);
	}

	numberOfFeedbackPerDay() {
		let options: Options = {}
		return this.http.get(this.configService.getApiPath() + `/customerFeedbacks/numberOfFeedbackPerDay`, options)
	}

	getFeedbacks(page, size, field, direction, key) {

		let param: any =
		{
			projection: 'feedbacks',
		}
		if (key != null)
			param.key = key.toLowerCase();
		if (field != null)
			param.field = field;
		if (direction != null)
			param.direction = direction.toUpperCase();


		let options: Options = {
			params:
				param,
			pageParams: {
				page: page,
				size: size
			}

		}

		return this.searchPages(`getFeedbacksByKey`, options).pipe(
            take(1),
            map(result => {

            return result
        }))
	}


}

