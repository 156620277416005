<div class="row">
    <div class="col mt-5 pt-5 mb-4">
        <img class="img-fluid mx-auto d-block check" src="/assets/images/check-2.svg" />
    </div>
</div>

<div class="row">
    <div class="col text-center text-style" [ngClass]="{rtl:appService.rtl()}">
        <span>{{ text | translate }}</span>
    </div>
</div>

<div class="row">
    <div class="col text-center text-style" [ngClass]="{rtl:appService.rtl()}">
        <span>{{value}}</span>
    </div>
</div>