import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { map } from "rxjs";
import { CustomerLike } from "../resources/CustomerLike";
import { ConfigService } from "./config.service";
import { MoomeRestService, Options } from "./moome-rest.service";

@Injectable({
	providedIn: 'root'
})
export class CustomerLikeService extends MoomeRestService<CustomerLike> {


	constructor(public http: HttpClient, injector: Injector, private configService: ConfigService) {
		super(CustomerLike);
	}

   /* getByconnected(id) {
		return this.searchSingle(`findCustomerLikeByConnected?connected=${id}&projection=likes`)
	}*/

    updateLike(id,like) {
		return this.http.put(this.configService.getApiPath() + `/customerLikes/${id}/updateLike/${like}`, null);
	}

	checkLike(days) {
		
			let options: Options = {
				params: { 
					projection: 'likes',
					days : days,
				 }
			}
		
		return this.http.get(this.configService.getApiPath() + `/customerLikes/customerLikesToSend` , options).pipe(
			map(res =>{ return res['_embedded']?.customerLikes}
				
			)
		)

	}
}