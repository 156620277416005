import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { GroupService } from 'projects/shared/services/group.service';
import { ProjectService } from 'projects/shared/services/project.service';
import { Authorities, Country, Roles } from 'projects/shared/tools/constants';
import { CustomRouter } from '../../services/custom-router';
import { DeviceFarmerService } from 'projects/shared/services/device-farmer.service';


@Component({
  selector: 'app-settings-graph',
  templateUrl: './settings-graph.component.html',
  styleUrls: ['./settings-graph.component.scss']
})
export class SettingsGraphComponent implements OnInit {
  list = []
  title: String
  connectedCustomer: any;
  defaultGroupId: any;
  getSupervisors = []
  getTanks = []
  authAdmin: string[] = [];
  authFarmer: string[] = [];
  supervisorId: any
  getCustomerSetting = []
  auth: string[] = [];
  authority = Authorities.FARMERS;
  thiCards: any[]
  thiEmpty: any
  color='color-blue'
  code:string;
  displayCode:boolean=false
  constructor(private deviceFarmerService: DeviceFarmerService,private router: CustomRouter, public appService: AppService,
    public dialog: MatDialog, public route: ActivatedRoute, private translate: TranslateService,
    public groupService: GroupService,
    private alertsService: AlertsService, public projectService: ProjectService) {

    this.appService.connectedCustomer['roles'].forEach(role => {
      role['authorityRoles'].forEach(authorityRole => {
        if (authorityRole.authority.code == Authorities.SUPERVISORS)
          this.getSupervisors.push((authorityRole["get"]).toString());
        if (authorityRole.authority.code == Authorities.TANKS)
          this.getTanks.push((authorityRole["get"]).toString());
        if (authorityRole.authority.code == Authorities.CUSTOMER_SETTING)
          this.getCustomerSetting.push((authorityRole["get"]).toString());
        if (authorityRole.authority.code == Authorities.CUSTOMER_SETTING)
          this.getCustomerSetting.push((authorityRole["edit"]).toString());
      })
    })
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
      this.authAdmin.push((role.adminAccess).toString())
      if (role.code == Roles.ROLE_FARMER) {
        this.authFarmer.push((true).toString())
      }
    })

  }

  ngOnInit() {
    this.title = "LIST_GRAPH"
    this.supervisorId = this.appService.connectedCustomer.id
    this.appService.verif = false
    if(Country.isPakIdn(this.appService.connectedCustomer.addresses[0].country.code))
    this.displayCode=true
  else
  this.displayCode=false
    this.loadAnalysis()

  }
  back() {
    this.router.back('')
  }
  loadAnalysis() {
    this.list = [
      {
        router: "supervisors/" + this.supervisorId + "/reports",
        labelAr: "التقارير", labelFr: "Rapports", labelEn: "Reports",labelInd: "Laporan",
        authority: ((this.auth.indexOf("true") != -1) && this.authAdmin.indexOf("true") == -1 || (this.getSupervisors.indexOf("true") != -1 && this.authAdmin.indexOf("true") == -1)) ? Authorities.SUPERVISORS : Authorities.NO_ACCESSIBLE
      },
      {
        router: "/reproduction-report",
        labelAr:"تقرير التكاثر", labelFr: "Rapport de la reproduction", labelEn: "Reproduction report",labelInd: "Laporan reproduksi",
        authority: (this.authAdmin.indexOf("true") != -1) ? Authorities.NO_ACCESSIBLE : Authorities.ANIMALS
      },
      {

        router: "/milk-feed-report",
        labelAr: " الرسم البياني لإنتاج الحليب", labelFr: "Graphique de la production laitière", labelEn: "Milk Production Graph",labelInd: "Grafik produksi susu",
        //labelAr: "الرسم البياني لكمية الإنتاج  و العلف", labelFr: "Aliment & Production graph", labelEn: "Milk & Feed graph",
        authority: Authorities.REPORTS_FEED_MILK
      },
      /*{
        router: (this.authFarmer.indexOf("true") != -1) ? "humidity-temp" : "supervisors/" + this.supervisorId + "/humidity-temp",
        labelAr: "الرسم البياني لمعدل الحرارة", labelFr: "THI graph", labelEn: "THI graph",
        authority: (this.auth.indexOf("true") != -1 || this.getSupervisors.indexOf("true") != -1 || this.authFarmer.indexOf("true") != -1) ? Authorities.REPORTS_HUMIDITY_TEMP : Authorities.NO_ACCESSIBLE
      },*/
      {
        router: "supervisors/" + this.supervisorId + "/temp_graph",
        labelAr: "الرسم البياني لدرجة الحرارة", labelFr: "Graphique de température", labelEn: "Temperature graph",labelInd: "Grafik suhu",
        authority: (this.getTanks.indexOf("true") != -1) ? Authorities.REPORTS_TEMP_GRAPHS : Authorities.NO_ACCESSIBLE
      },
     {
        router: "milk-graphs",
        labelAr: "الرسم البياني لكمية الحليب ", labelFr: "Graphique de la quantité du lait", labelEn: "Milk graph",labelInd: "Grafik Susu",
        authority: Authorities.MILK_GRAPH
      },
      {
        router: "calving-graphs",
        labelAr: "الرسم البياني لمخطط  الولادات ", labelFr: "Graphique du vêlage", labelEn: "Calving graph",labelInd: "Grafik Melahirkan",
        authority: Authorities.CALVING_GRAPH
      },
      {
        router: "cow-milk-graphs",
        labelAr: "إنتاج حليب / بقرة ", labelFr: "Graphique des contrôles laitiers", labelEn: "Cow's milk production graph",labelInd: "Grafik produksi susu sapi",
        authority: Authorities.ANIMAL_MILK_GRAPH
      },
     
      
    ]
    
    if(!this.displayCode)
    this.list.splice(4, 0,{
      router: (this.authFarmer.indexOf("true") != -1) ? "rumination-ui" : "supervisors/" + this.supervisorId + "/rumination-ui",
      labelAr: "وقت الإجترار و الراحة", labelFr: "Temps de rumination & repos", labelEn: "Rumination & Resting time",labelInd: "Ruminasi & Waktu Istirahat",
      authority: (this.authFarmer.indexOf("true") != -1 || this.getSupervisors.indexOf("true") != -1) ? Authorities.RUMINATION_UI : Authorities.NO_ACCESSIBLE
    },)

    this.deviceFarmerService.getDevicesCode().subscribe((data: any) => {
      this.thiCards = data
      this.thiEmpty = this.thiCards.length == 0;
    if(!this.thiEmpty)
    this.list.splice(5, 0, {
      router: (this.authFarmer.indexOf("true") != -1) ? "humidity-temp" : "supervisors/" + this.supervisorId + "/humidity-temp",
      labelAr: "الرسم البياني لمعدل الحرارة", labelFr: "THI graph", labelEn: "THI graph",labelInd: "Grafik THI",
      authority: (this.auth.indexOf("true") != -1 || this.getSupervisors.indexOf("true") != -1 || this.authFarmer.indexOf("true") != -1) ? Authorities.REPORTS_HUMIDITY_TEMP : Authorities.NO_ACCESSIBLE
     })
  })
  }

  pathUrl(value) {
    return this.router.navigateByUrl(value)
  }
}
