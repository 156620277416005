import { Component, OnInit, Inject } from '@angular/core';
import { Type, Alert, Roles, Title, Authorities } from 'projects/shared/tools/constants';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DeviceService } from 'projects/shared/services/device.service';
import { AnimalNoteService } from 'projects/shared/services/animal-note.service';
import { AppService } from 'projects/shared/services/app.service';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-show-notes',
  templateUrl: './show-notes.component.html',
  styleUrls: ['./show-notes.component.scss']
})
export class ShowNotesComponent implements OnInit {
  btnColour
  notes: any = [];
  Type = Type;
  delete: boolean = true;
  get: Boolean = false;
  authGet: string[] = [];
  authDel: string[] = [];
  length=0;
  constructor(public dialogRef: MatDialogRef<ShowNotesComponent>, private deviceService: DeviceService, public animalNoteService: AnimalNoteService,
    @Inject(MAT_DIALOG_DATA) public data: any, public appService: AppService, private alertsService: AlertsService, public dialog: MatDialog, public translate: TranslateService ) {
      this.appService.connectedCustomer['roles'].forEach(role => {
        role['authorityRoles'].forEach(authorityRole => {
          if (authorityRole.authority.code == Authorities.ANIMAL_NOTES) {
            this.authGet.push((authorityRole["get"]).toString())
            this.authDel.push((authorityRole["delete"]).toString())
          }
        }
        );
      });
      if (this.authGet.indexOf("true") != -1)
        this.get = true;
      if (this.authDel.indexOf("true") != -1)
        this.delete = true;
     }

  ngOnInit() {
    if (this.data.deviceId)
      this.findByCode(this.data.deviceId)
    else
      this.loadAnimalNotes();
  }

  findByCode(value) {
    
    this.deviceService.findByDeviceId(value).subscribe(res => {
      this.data = res;
        this.loadAnimalNotes();
    })
  }

  loadAnimalNotes() {
    if(this.get) {
    this.animalNoteService.findAnimalNotes(this.data.animal.id, this.data.farmerId).subscribe(res => {
      this.notes = res['resources']
      if(this.notes)
      this.length = this.notes.length
      else
      this.length=0
    }, 	error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.code);
      let alert = this.translate.instant( Alert.OK)
      this.alertsService.open(message, alert);
    });
  }
  }

  deleteNote(note) {
    if(this.delete) {
		const dialogRef = this.dialog.open(ConfirmComponent, {
			width: '350px',
			data: {
				message: this.translate.instant('CONFIRM_DELETE_NOTE'),
				title: this.translate.instant('DELETE_NOTE'),
				onConfirm: () => {
          this.appService.showLoading();
          this.animalNoteService.delete(note).subscribe(data => {
            this.appService.hideLoading();
            let message = this.translate.instant('SAVE_SUCESS');
            this.alertsService.open(message);
            this.  loadAnimalNotes();

				}, 	error => {
          this.appService.hideLoading();
          let message = this.translate.instant(error.error.code);
          let alert = this.translate.instant( Alert.OK)
          this.alertsService.open(message, alert);
        })
      }
    }
    });
  }
	}

}
