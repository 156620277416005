import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { Region } from "./Region";
import { Customer } from './Customer';
@HateoasResource('entreprises')
export class Entreprise extends Resource {

  id: number;
  logo: string;
  name: string;
  address: string;
  region: Region | any;
  type: String;
  entreprise: Entreprise | any;
  phone: string;
  email: string;
  customers: Customer[] | any;
  entreprises: Entreprise[] | any ;
}