import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppService } from 'projects/shared/services/app.service';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AnimalNoteService } from 'projects/shared/services/animal-note.service';
import { Alert, Type, Authorities, NUMERIC_PATTERN } from 'projects/shared/tools/constants';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from 'projects/shared/services/device.service';
import { ValidatorMessagesService } from 'projects/shared/services/validator-message.service';
import * as moment from 'moment';
import { ShowNotesComponent } from '../show-notes/show-notes.component';
import { fromEvent, Subscription } from 'rxjs';
import { TokenManagementService } from 'projects/shared/services/token-management.service';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})
export class AddNoteComponent implements OnInit {

  device: any;
  formGroup: UntypedFormGroup;
  Type = Type;
  milkProdChecked: Boolean = false;
  weightChecked: Boolean = false;
  create: boolean = false;
  get: boolean = false
  authGet: string[] = [];
	authCreate: string[] = [];
  subscription: Subscription;
  formatArabe: any
  maxDate: any;
  minDate
  numericPattern=NUMERIC_PATTERN
  constructor(private tokenManagementService: TokenManagementService, public dialogRef: MatDialogRef<AddNoteComponent>, public dialog: MatDialog, private renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: UntypedFormBuilder, private translate: TranslateService, private deviceService: DeviceService,
    public alertsService: AlertsService, public appService: AppService, public animalNoteService: AnimalNoteService, public validatorsMesssageService: ValidatorMessagesService) {
    this.appService.connectedCustomer['roles'].forEach(role => {
      role['authorityRoles'].forEach(authorityRole => {
        if (authorityRole.authority.code == Authorities.ANIMAL_NOTES) {
          this.authGet.push((authorityRole["get"]).toString())
					this.authCreate.push((authorityRole["create"]).toString())
        }
      }
      );
    });
    if (this.authGet.indexOf("true") != -1)
			this.get = true;
		if (this.authCreate.indexOf("true") != -1)
			this.create = true;
  }

  ngOnInit() {
    let buttons = document.getElementsByClassName("note_type");
    for (let i = 0; i < buttons.length; i++) {
      this.subscription = fromEvent(buttons[i], 'click').subscribe(e => {
        e.preventDefault()
        for (let j = 0; j < buttons.length; j++) {
          this.renderer.removeClass(buttons.item(j), 'active')
        }
        this.renderer.addClass(e.currentTarget, 'active')
        this.setValue(e.currentTarget['attributes'].notetype.value);
      })
    }

    this.createForm();
    if (this.data.deviceId)
      this.findByCode(this.data.deviceId)

    this.formGroup.controls['type'].setValue(Type.ADD_NOTE)
    
  }
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'type': [null],
      'note': [null],
      'actionDate': [null, [Validators.required]]
    });
  }

  findByCode(value) {
    this.deviceService.findByDeviceId(value).subscribe(res => {
      this.data = res
    })
  }

  openShowNotes() {
    if(this.get) {
    const dialogRef = this.dialog.open(ShowNotesComponent, {
      width: '350px',
      data: {
        animal: this.data.animal,
        farmerId: this.data.farmerId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  }


  setValue(value) {
    this.formGroup.controls['type'].setValue(value)
    if (value == Type.MILK_PROD) {
      this.milkProdChecked = true
      this.weightChecked = false
      this.formGroup.controls['note'].setValidators([Validators.required]);
    } else if (value == Type.WEIGHT) {
      this.weightChecked = true
      this.milkProdChecked = false
      this.formGroup.controls['note'].setValidators([Validators.required]);
    }
    else {
      this.weightChecked = false
      this.milkProdChecked = false
      this.formGroup.controls['note'].setValidators(null);
    }
    this.formGroup.controls['note'].updateValueAndValidity();
  }

  save() {
    if(this.create) {
         let message: string;
    this.appService.showLoading();
    let animalNote = this.formGroup.value;
    animalNote.actionDate = moment(this.formGroup.controls['actionDate'].value).toISOString();
    animalNote.customer = this.appService.connectedCustomer['link'].href;
    animalNote.animal = this.data.animal._links.self.href;
    this.animalNoteService.save(animalNote).subscribe(
      data => {
        this.appService.hideLoading();
        message = this.translate.instant('SAVE_SUCESS');
        this.alertsService.open(message);
        this.formGroup.controls['note'].setValue(null);
        // this.formGroup.controls['actionDate'].setValue(null);
      }, error => {
        this.appService.hideLoading();
        message = this.translate.instant(error.error.code);
        let alert = this.translate.instant(Alert.OK)
        this.alertsService.open(message, alert);
      }
    )
  }
}

fixArabeFormat() {
  if (this.tokenManagementService.getTranslateLanguage() == "ar")
    this.formatArabe = "DD/MMM/YYYY"
}

}
