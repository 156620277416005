import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
@HateoasResource('countries')
export class Country extends Resource {
    id: number;
    labelFr: string;
    labelEn: string;
    labelAr: string;
    phoneIndex: string;
    uidIndex: string;
    phoneLength: number;
    uidLength: number;
    code: string;
    currency:string

  }