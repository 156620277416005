<div class="style-bar w-100" ngxHideOnScroll [scrollingElementSelector]="'body'" [propertyUsedToHide]="'top'"
 [valueWhenShown]="'100px'">
    <div class="row h-100 ">
        <div class="row h-100 ">
            <div class="col center-element" (click)="back()">
                <img src="/assets/images/left-arrow-1.svg" />
            </div>
            <div class="col center-element">
                <div class="row d-block">
                    <div class="col text-center"> <img src="/assets/images/Dashboard.svg" />
                    </div>
                    <div class="col text-center ps-0 pe-0 pt-1"> <span class="text-style"
                            [ngClass]="{rtl:appService.rtl()}">{{ 'DASHBOARD' | translate }}</span></div>
                </div>
            </div>
            <div class="col center-element">
            </div>
        </div>
    </div>
</div>
<div class="row px-4 py-2 position" >
    <app-dashbord-card *ngFor="let element of list" class="col-6" [element]="element" >
    </app-dashbord-card>
</div>
