import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'projects/shared/services/app.service';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { ConfirmComponent } from 'projects/shared/components/confirm/confirm.component';
import { Alert } from 'projects/shared/tools/constants';
import { AnimalService } from 'projects/shared/services/animal.service';
import { ReportService } from 'projects/shared/services/report.service';
import { CommonDialogComponent } from 'projects/publicV2/src/app/common-dialog/common-dialog.component';
import { RapportDialogComponent } from 'projects/public/src/app/rapport-dialog/rapport-dialog.component';

@Component({
  selector: 'app-action-livestock-summary',
  templateUrl: './action-livestock-summary.component.html',
  styleUrls: ['./action-livestock-summary.component.scss']
})
export class ActionLivestockSummaryComponent implements OnInit {

  @Output() load = new EventEmitter
  @Input("selected") selectedRows
  @Output() cleanSelected = new EventEmitter
  @Input("del") del;
  @Input("get") get;
  @Input("id") id;
  @Input("summary") summary

  constructor(public dialog: MatDialog, private translate: TranslateService, private appService: AppService,
    private alertsService: AlertsService, private animalService: AnimalService, private reportService: ReportService) { }

  ngOnInit() {
  }

  openDialog() {
    const dialogRef = this.dialog.open(RapportDialogComponent, {
      width: this.summary? '350px':'900px',
      data: {
        id: this.id,
        summary: this.summary
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  // exportAllAnimals() {
  //   this.reportService.exportAnimals().subscribe(data => {
  //     let blob = new Blob([data], { type: 'application/pdf' });
  //     FileSaver.saveAs(blob, this.translate.instant('ALL_LIVESTOCK_pdf') + ".pdf");
  //     let fileURL = URL.createObjectURL(blob);
  //     window.open(fileURL);
  //   })
  // }

  /* exportAnimalsSummary() {
     this.animalService.getAddress(this.id).subscribe(res => {
       this.reportService.exportAnimalsSummary(res.customer.id).subscribe(data => {
         let blob = new Blob([data], { type: 'application/pdf' });
         FileSaver.saveAs(blob, this.translate.instant('SUMMARY_LIVESTOCK_pdf') + ".pdf");
         let fileURL = URL.createObjectURL(blob);
         window.open(fileURL);
       })
     })
   }
 */
  deleteAnimals() {
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      //width: '350px',
      data: {
        message: this.translate.instant('CONFIRM_DELETE_ANIMAL'),
        title: this.translate.instant('DELETE_ANIMAL'),
        onConfirm: () => {
          let rows: any[] = [];
          if (this.selectedRows.length == 0) {
            let message: string;
            this.appService.hideLoading();
            message = this.translate.instant('SHOULD_SELECT_DELETE');
            let alert = this.translate.instant(Alert.OK)
            this.alertsService.open(message, alert);
          } else {

            this.selectedRows.forEach(element => {
              rows.push(element.id)
            });
            let message: string;
            this.appService.showLoading();
            this.animalService.deleteAll(rows).subscribe(
              data => {
                this.load.emit();
                this.cleanSelected.emit();
                this.appService.hideLoading();
                message = this.translate.instant('DELETED');
                this.alertsService.open(message);
              },
              error => {
                this.appService.hideLoading();
                message = this.translate.instant(error.error.code);
                let alert = this.translate.instant(Alert.OK)
                this.alertsService.open(message, alert);
              }
            );
          }
        }
      }
    });
  }

}
