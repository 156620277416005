import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';

@Component({
  selector: 'app-thi-card',
  templateUrl: './thi-card.component.html',
  styleUrls: ['./thi-card.component.scss']
})
export class ThiCardComponent implements OnInit {
  @Input() thi;
  thiSign: string = "-"
  @Input() signal: any
  @Input() imgBattery: any;

  constructor(public appService: AppService) {
  }
  ngOnInit(): void {
    
  }

}
