import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { FarmerMilkProductionService } from 'projects/shared/services/farmer-milk-production.service';
import { CommonDialogComponent } from '../../common-dialog/common-dialog.component';
import { CustomRouter } from '../../services/custom-router';
import { AnimalMilkProductionService } from 'projects/shared/services/animal-milk-production.service';
import { MAX_DAY_RECALL, MIN_DAY_RECALL, NUMERIC_PATTERN_RECALL, SectionForm } from 'projects/shared/tools/constants';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AnimalMilkProduction } from 'projects/shared/resources/AnimalMIlkProduction';

@Component({
  selector: 'app-edit-milk-quantity-cow',
  templateUrl: './edit-milk-quantity-cow.component.html',
  styleUrls: ['./edit-milk-quantity-cow.component.scss']
})
export class EditMilkQuantityCowComponent implements OnInit {

  idFarmer
  idFormTemplate
  id
  result
  create = false
  name = "MILK_PRODUCTION_ANIMAL"
  idAnimal
  section = SectionForm.animal_milk_production
  formGroup: UntypedFormGroup;
  alertId
  numericPattern = NUMERIC_PATTERN_RECALL
  color='color-blue'
  constructor(private formBuilder: UntypedFormBuilder, public dialog: MatDialog, public animalMilkProductionService: AnimalMilkProductionService, public appService: AppService,
    public translate: TranslateService, private alertsService: AlertsService,
    private router: CustomRouter, public route: ActivatedRoute) {

    const { idFormTemplate, idAnimal, id } = this.route.snapshot.params
    this.idFarmer = this.appService.connectedCustomer.id
    this.idFormTemplate = idFormTemplate;
    this.id = id
    this.idAnimal = idAnimal

    if (this.id)
      this.displayInfo(this.id);
    else this.create = true

  }

  ngOnInit(): void {

  }

  displayInfo(id) {
    this.appService.showLoading()
    this.animalMilkProductionService.get(id).subscribe(res => {
      let body: AnimalMilkProduction = new AnimalMilkProduction();
      body.date = res.date;
      body.quantity = res.quantity;
      if (res.others != null) {
        body.reminder = res.others['countDay']
        this.alertId = res.others['alertId']
        body.checkbox = true
      }
      this.result = body;
    })
    this.appService.hideLoading()
  }

  confirm(event) {
    this.animalMilkProductionService.findMilkProductionByExisteDate(event.formGroup.value.date, this.idAnimal).subscribe(data => {
      if ((data == '0') || ((event.id))) {
        this.save(event);
      }
      else {
        const dialogRef = this.dialog.open(CommonDialogComponent, {
          data: {
            message: this.translate.instant('DATE_EXIST'),
            onConfirm: () => {
              this.save(event);
            }
          }
        });
      }

    })
  }

  save(event) {
    let form = event.formGroup.value
    if (!form.quantity)
      this.appService.error('MILK_EMPTY')
    else if((form.checkbox)&&((!form.reminder)||(form.reminder < MIN_DAY_RECALL)))
      this.appService.error(this.translate.instant('MIN_RECALL') + " " + 0)
    else if ((form.checkbox)&&(form.reminder)&&(form.reminder > MAX_DAY_RECALL))
      this.appService.error(this.translate.instant('MAX_RECALL') + " " + 366)
    else if( (!form.checkbox)&&(form.reminder))
      this.appService.error('VERIF_CHECK')
    else {
      form.id = this.id
      if (form.checkbox == true) {
        if (form.reminder != null)
          form.others = { "countDay": + form.reminder }
        if (this.alertId != null)
          form.others = { "countDay": + form.reminder, "alertId": +this.alertId }
      }
      this.appService.showLoading();
      this.animalMilkProductionService.saveAnimalMilkProduction(form, this.idAnimal).subscribe(res => {
        this.appService.hideLoading();
        let message = this.translate.instant('SAVE_SUCESS');
        this.alertsService.open(message);
        this.back();
      });
    }
  }
  back() {
    this.router.back('')
  }
}

