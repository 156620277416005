import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from 'projects/public/src/app/material/material.module';
import { AddNoteComponent } from './add-note.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
    ],
    declarations: [
        AddNoteComponent,
    ],
    exports: [
        AddNoteComponent
    ]
})
export class AddNoteModule { }   