import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppService } from 'projects/shared/services/app.service';
import { CountryService } from 'projects/shared/services/country.service';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { CustomRouter } from '../../services/custom-router';
import { Validator } from 'projects/shared/tools/validators';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { Alert, MAX_LENGTH_PASSWORD, MIN_LENGTH_PASSWORD, PATERN_PASSWORD } from 'projects/shared/tools/constants';
import { FarmerService } from 'projects/shared/services/farmer.service';
import { Customer } from 'projects/shared/resources/Customer';
//import { RecaptchaComponent } from '../../recaptcha/recaptcha.component';
import { MatDialog } from '@angular/material/dialog';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { Address } from 'projects/shared/resources/Address';
import { Country } from 'projects/shared/resources/Country';
import { Subscription } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  formGroup: UntypedFormGroup;
  text: String;
  sms: String;
  countryPage: Boolean = true;
  next: String;
  phonePage: Boolean = false;
  passwordPage: Boolean = false;
  notRec: String;
  resend: String;
  phoneLength: number;
  verify: boolean = false
  customer: Customer = new Customer();
  minLengthPassword = MIN_LENGTH_PASSWORD
  maxLengthPassword = MAX_LENGTH_PASSWORD
  patternPassword = PATERN_PASSWORD
  private executionsSubscription: Subscription;

  constructor(private formBuilder: UntypedFormBuilder, public appService: AppService, private router: CustomRouter, public translate: TranslateService,
    private codeTranslate: CodeTranslatePipe, public alertsService: AlertsService, public farmerService: FarmerService, public dialog: MatDialog,
    private recaptchaV3Service: ReCaptchaV3Service, public tokenManagementService: TokenManagementService) {
    //this.loadCountries()
    this.createForm();
  }

  setValidators(value, validators) {
    this.formGroup.clearValidators();
    this.formGroup.controls[value].setValidators(validators);
    this.formGroup.controls[value].updateValueAndValidity();
  }

  back() {
    this.router.navigateByUrl("/login")
  }

  message(msg) {
    let alert = this.translate.instant(Alert.OK)
    this.alertsService.open(msg, alert);
  }

  ngOnInit() {
    this.appService.showRecaptcha()
    this.displayCountryPage()
    this.appService.loadCountries();
  }

  displayCountryPage() {
    this.text = "WHAT_COUNTRY";
    this.sms = '';
    this.next = "NEXT";
    this.notRec = '';
    this.resend = '';
    this.countryPage = true;
    this.phonePage = false;
    this.passwordPage = false;
    this.setValidators('country', [Validators.required])

  }

  displayPhonePage() {
    if (this.formGroup.controls['country'].hasError('required')) {
      this.message(this.translate.instant('FIELD_REQUIRED'));
    } else {
      let address = new Address();
      let country = new Country()
      country.id = this.formGroup.controls['country'].value
      address.country = country
      this.customer.addresses = [address];
      this.phoneLength = this.appService.countries.filter(ctry => country.id == ctry.id)[0].phoneLength
      this.text = "YOUR_PHONE";
      this.sms = '';
      this.next = "NEXT";
      this.notRec = '';
      this.resend = '';
      this.countryPage = false;
      this.phonePage = true;
      this.passwordPage = false;
      this.setValidators('phone', [
        Validators.required, Validator.validPhoneLength(this.translate, this.phoneLength, this.alertsService)])
    }

  }

  dispalyPasswordPage() {
    if (this.formGroup.controls['phone'].hasError('required'))
      this.message(this.translate.instant('FIELD_REQUIRED'));
    else if ((document.getElementById("phone") as HTMLFormElement).value.toString().replace(/\s/g, "").length != this.phoneLength) {
      this.message(this.translate.instant('PHONE_LENGTH') + " " + this.phoneLength.toString());
    } else {
     /* const dialogRef = this.dialog.open(RecaptchaComponent, {
        data: {
          onConfirm: (token) => {*/
          this.appService.showRecaptcha();
          this.executionsSubscription = this.recaptchaV3Service.execute('importantAction')
          .subscribe((token: string) => {
            this.appService.showLoading()
            this.customer.phone = (document.getElementById("phone") as HTMLFormElement).value.toString().replace(/\s/g, "")
            this.customer['recaptchaToken'] = token
            this.customer['smsInfos'] = []
            this.farmerService.forgetPassword(this.customer).subscribe(data => {
              this.appService.hideLoading();
              this.customer['smsInfos'].push(data)
              this.text = 'YOUR_SMS'
              this.sms = 'YOUR_PASS'
              this.next = 'VERIFY'
              this.notRec = 'NOT_RECI?'
              this.resend = 'RESEND',
                this.countryPage = false;
              this.phonePage = false;
              this.passwordPage = true;
              this.setValidators('code', [Validators.required])
              this.setValidators('newPassword', [
                Validators.required,
                Validators.minLength(MIN_LENGTH_PASSWORD),
                Validators.maxLength(MAX_LENGTH_PASSWORD),
                Validators.pattern(PATERN_PASSWORD)])
              this.setValidators('confNewPassword', [
                Validators.required,
                Validators.minLength(MIN_LENGTH_PASSWORD),
                Validators.maxLength(MAX_LENGTH_PASSWORD),
                Validators.pattern(PATERN_PASSWORD)])
                this.appService.observableTimer()

            }, error => {
              this.appService.hideLoading();
              this.message(this.translate.instant(error.error.code));
            })


          })
      //  }
     // })
    }
      this.appService.subscribeTimer.unsubscribe();

  }

  verif() {
    if (this.formGroup.controls['code'].hasError('required')) {
      this.message(this.translate.instant('CODE_REQUIRED'));
    }
    else if (this.formGroup.controls['newPassword'].hasError('required')) {
      this.message(this.translate.instant('PLEASE_CHECK_NEW_PASSWORD'));
    }
    else if (this.formGroup.controls['newPassword'].hasError('minlength'))
    this.message(this.translate.instant('LENG_NEW_PASSWORD') + " "  + this.minLengthPassword.toString()+ this.translate.instant('CARACTERE') + " ")
  
  else if (this.formGroup.controls['newPassword'].hasError('maxlength'))
    this.message(this.translate.instant('MAX_LENGTH_NEW_PASSWORD') + " "  + this.maxLengthPassword.toString()+ this.translate.instant('CARACTERE') + " ")
  

    else if (this.formGroup.controls['newPassword'].hasError('pattern'))
      this.message('NEW_PASSWORD_PATTERN');
      
    else if (this.formGroup.controls['confNewPassword'].hasError('required')) {
      this.message(this.translate.instant('PLEASE_CONFIRM_NEW_PASSWORD'));
    }
    else if (this.formGroup.controls['confNewPassword'].hasError('minlength'))
        this.message(this.translate.instant('LENG_CONF_NEW_PASSWORD') + " "  + this.minLengthPassword.toString()+ this.translate.instant('CARACTERE') + " ")
      
      else if (this.formGroup.controls['confNewPassword'].hasError('maxlength'))
        this.message(this.translate.instant('MAX_LENGTH_CONF_NEW_PASSWORD') + " "  + this.maxLengthPassword.toString()+ this.translate.instant('CARACTERE') + " ")

    else if (this.formGroup.controls['confNewPassword'].hasError('pattern'))
      this.message('CONF_NEW_PASSWORD_PATTERN');


    else if (this.formGroup.controls['newPassword'].value != this.formGroup.controls['confNewPassword'].value) {
      this.message(this.translate.instant('NO_CONFIRM_PASSWORD'));
    }
    else {
      this.confirm()
    }
  }

  confirm() {
    this.appService.showLoading()
    this.customer.password = (document.getElementById("code") as HTMLFormElement).value.toString().replace(/\s/g, "");
    this.customer['newPassword'] = (document.getElementById("newPassword") as HTMLFormElement).value.toString().replace(/\s/g, "");
    this.customer['confNewPassword'] = (document.getElementById("confNewPassword") as HTMLFormElement).value.toString().replace(/\s/g, "");
    this.farmerService.checkCode(this.customer).subscribe((data: Customer) => {
      this.customer.password = this.customer['newPassword'];
      this.appService.hideLoading();
      this.login(data.username);
    }
      , error => {
        this.appService.hideLoading();
        this.message(this.translate.instant(error.error.code));
      })
  }

  login(username) {
    this.tokenManagementService.login(username, this.customer.password)
      .subscribe(
        data => {
          this.tokenManagementService.setToken(data['access_token'], data['refresh_token'], data['expires_in'], data['fullname']);
          this.appService.hideLoading();
          this.verify = true
          setTimeout(() => {
            this.router.navigateByUrl('/home');
          }, 3000);
        },
        error => {
          this.appService.hideLoading();
          this.message(this.translate.instant('LOGIN_FAIL'));
        }
      );
  }

  resendCode() {
   /* const dialogRef = this.dialog.open(RecaptchaComponent, {
      data: {
        onConfirm: (token) => {*/
        this.appService.showRecaptcha();
        this.executionsSubscription = this.recaptchaV3Service.execute('importantAction')
        .subscribe((token: string) => {
          this.customer['recaptchaToken'] = token;
          this.appService.showLoading()
          this.farmerService.resendCode(this.customer).subscribe(data => {
            this.appService.hideLoading();
            this.appService.observableTimer()
          }, error => {
            this.message(this.translate.instant(error.error.message));
          });
        })
     // }
   // });
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'country': [null],
      'phone': [],
      'code': [null],
      'newPassword': [null],
      'confNewPassword': [null],
    });
  }

  /*searchLabel(list, value: string) {
    let filter = value.toLowerCase();
    return list.filter(option => this.codeTranslate.transform(option).toLowerCase().includes(filter)).sort((a, b) => {
      if (this.codeTranslate.transform(a) > this.codeTranslate.transform(b)) {
        return 1
      } else if (this.codeTranslate.transform(a) < this.codeTranslate.transform(b)) {
        return -1
      }
      return 0
    });
  }

  loadCountries() {
    let options: HalOptions = {
      params: [
        { key: "projection", value: "country" },]
    }
    this.countryService.getAll(options).subscribe(res => {
      this.selectedCountries = this.countries = res.sort((a, b) => {
        if (this.codeTranslate.transform(a) > this.codeTranslate.transform(b)) {
          return 1
        } else if (this.codeTranslate.transform(a) < this.codeTranslate.transform(b)) {
          return -1
        }
        return 0
      });
    })
  }

  onKeyCountry(value) {
    this.selectedCountries = this.searchLabel(this.countries, value);
  }*/

  ngOnDestroy() {
    this.appService.hideRecaptcha()
    if (this.executionsSubscription) {
      this.executionsSubscription.unsubscribe();
    }
  }

}
