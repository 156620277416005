import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnimalNoteService } from 'projects/shared/services/animal-note.service';
import { AnimalService } from 'projects/shared/services/animal.service';
import { AppService } from 'projects/shared/services/app.service';
import { Authorities, SectionForm } from 'projects/shared/tools/constants';
import { CustomRouter } from '../services/custom-router';
import { FarmerMilkProductionService } from 'projects/shared/services/farmer-milk-production.service';
import { AnimalMilkProductionService } from 'projects/shared/services/animal-milk-production.service';

@Component({
  selector: 'app-milk-quantity-cow',
  templateUrl: './milk-quantity-cow.component.html',
  styleUrls: ['./milk-quantity-cow.component.scss']
})
export class MilkQuantityCowComponent implements OnInit {

  forms: any={}
  idFormTemplate
  service
  name = "MILK_PRODUCTION_ANIMAL"
  idAnimal
  auth: string[] = [];
  role
  idFarmer
  section=SectionForm.animal_milk_production
  color='color-blue'
  constructor(public animalMilkProductionService: AnimalMilkProductionService,
    public appService: AppService, public route: ActivatedRoute,
    private router: CustomRouter) {

    const { idFarmer, idAnimal,idFormTemplate } = this.route.snapshot.params
    this.idFormTemplate = idFormTemplate;
    this.service = this.animalMilkProductionService
    this.idAnimal = this.route.snapshot.paramMap.get('idAnimal');
    this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1)
      this.role = true;
    else
      this.role = false

  }

  ngOnInit(): void {
    let currentRoute = this.router.url;
    if (currentRoute.indexOf('key') == -1)
      this.getData()
  }

  getDataByKey(element) {
    this.animalMilkProductionService.findByAnimalByKey(element.key, this.idAnimal).subscribe(res => {
      this.forms=this.appService.groupDataByYearAndMonthWithoutTiming(this.appService.organizedData(res, this.appService.dataAddAnimalMilkProduction));
    }, error => {
      this.appService.error(error.error.error)
    });
  }

  getData() {
    this.animalMilkProductionService.findByAnimal(this.idAnimal).subscribe(res => {
      this.forms=this.appService.groupDataByYearAndMonthWithoutTiming(this.appService.organizedData(res, this.appService.dataAddAnimalMilkProduction));
    }, error => {
      this.appService.error(error.error.error)
    });
  }

  back() {
    this.router.back('')
  }

  action(item) {

    if (!this.role)
      this.router.navigateByUrl('animals/' + this.idAnimal + '/settings/' + this.idFormTemplate + '/' + Authorities.ANIMAL_MILK_PRODUCTION + '/save' + (item ? ("/" + (item.id)) : ""))
    else
      this.router.navigateByUrl('farmers/' + this.idFarmer + '/animals/' + this.idAnimal + '/settings/' + this.idFormTemplate + '/' + Authorities.ANIMAL_MILK_PRODUCTION + '/save' + (item ? ("/" + (item.id)) : ""))


  }

}

