<div class="row">
  <div class="col-12">
    <h2 mat-dialog-title [ngClass]="{rtl:appService.rtl()}"> {{ 'ADD_NOTE' | translate}}</h2>
  </div>
</div>

<form [formGroup]="formGroup" (ngSubmit)="save()">
  <div class="row" [ngClass]="{rtl:appService.rtl()}">
    <div class="col-12">
      <div class="row">
        <div class="col-12 mb-2"> <label>{{'TYPE' | translate}}:</label></div>
      </div>

      <div class="row">

        <div class="col-12 col-sm-6 col-md-6 col-xl-6 pb-4">
          <button type="button" mat-raised-button color="primary" [attr.notetype]="Type.ADD_NOTE"
            [ngClass]="appService.rtl()? 'button-style-ar active' : 'button-style active'" class="note_type">
            <div> <i class="fa fa-folder icon-button-style"></i><span>{{'NEW_NOTE' | translate}}</span></div>
          </button>
        </div>

        <!--<div class="col-12 col-sm-6 col-md-6 col-xl-6 pb-4">
          <button type="button" mat-raised-button color="primary" [attr.notetype]="Type.CALVING_DATE"
            [ngClass]="appService.rtl()? 'button-style-ar' : 'button-style'" class="note_type">
            <div><i class="fa fa-paw icon-button-style"></i> <span>{{'CALVING_DATE' | translate}}</span></div>
          </button>
        </div>-->

        <!--<div class="col-12 col-sm-6 col-md-6 col-xl-6 pb-4">

          <button type="button" mat-raised-button color="primary" [attr.notetype]="Type.HEAT_DATE"
            [ngClass]="appService.rtl()? 'button-style-ar' : 'button-style'" class="note_type">
            <div><i class="fa fa-thermometer-full icon-button-style"></i> <span>{{'HEAT_DATE' | translate}}</span></div>
          </button>
        </div>-->

        <!--<div class="col-12 col-sm-6 col-md-6 col-xl-6 pb-4">
          <button type="button" mat-raised-button color="primary" [attr.notetype]="Type.INSE_DATE"
            [ngClass]="appService.rtl()? 'button-style-ar' : 'button-style'" class="note_type">

            <div><i class="fa fa-syringe icon-button-style"></i><span> {{'INSE_DATE' | translate}}</span></div>
          </button>
        </div>-->
        
        <div class="col-12 col-sm-6 col-md-6 col-xl-6 pb-4">
          <button type="button" mat-raised-button color="primary" [attr.notetype]="Type.MILK_PROD"
            [ngClass]="appService.rtl()? 'button-style-ar' : 'button-style'" class="note_type">

            <div><img src="/assets/images/icons/milk-tank_g.svg"
                [ngClass]="appService.rtl()? 'icon-button-style-svg-ar' : 'icon-button-style-svg'"><span>{{'MILK_PROD' | translate}}</span>
            </div>
          </button>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-xl-6">
          <button type="button" mat-raised-button color="primary" [attr.notetype]="Type.WEIGHT"
            [ngClass]="appService.rtl()? 'button-style-ar' : 'button-style'" class="note_type">

            <div><i class="fa fa-weight icon-button-style"></i><span>{{'WEIGHT' | translate}}</span> </div>
          </button>
        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-form-field style="width:100%" [ngClass]="{rtl:appService.rtl()}" *ngIf="!milkProdChecked && !weightChecked">
        <textarea matInput placeholder="{{ 'NOTES'| translate }}" formControlName="note"></textarea>
      </mat-form-field>

      <mat-form-field style="width:100%" [ngClass]="{rtl:appService.rtl()}" *ngIf="milkProdChecked">
        <input matInput type="number" min="0" placeholder="{{ 'MILK_PROD' | translate }}" formControlName="note">
        <mat-error *ngIf="!formGroup.controls['note'].valid && formGroup.controls['note'].touched">
          {{ 'FIELD_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field style="width:100%" [ngClass]="{rtl:appService.rtl()}" *ngIf="weightChecked">
        <input matInput type="number" min="0" placeholder="{{ 'WEIGHT' | translate }}" formControlName="note" (keypress)="appService.numericOnly($event,numericPattern)">
        <mat-error *ngIf="!formGroup.controls['note'].valid && formGroup.controls['note'].touched">
          {{ 'FIELD_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>

      <div class="row" [ngClass]="{rtl:appService.rtl()}">
        <div class="col mb-3 mt-2">
            <ion-item class="input-mob-date ps-2 pe-2" >
                <ion-datetime #datePicker mode="ios" [ngClass]="{rtl:appService.rtl()}" class="font-size-text"
                    [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()" formControlName="actionDate"
                    placeholder="{{ 'CHOOSE'| translate }}&nbsp;{{ 'DATE' | translate}}" (ionChange)="fixArabeFormat()"
                    [max]="maxDate" 
                    [doneText]="'SAVE' |translate"
                    [cancelText]="'CANCEL' |translate"
                    [monthShortNames]="appService.monthShort()"
                    >
                </ion-datetime>    
                <img matDatepickerToggleIcon  [ngClass]="appService.rtl()? 'date-ar' : 'date'" src="/assets/images/calendar4.svg" />                      
             
            </ion-item>
            <mat-error *ngIf="!formGroup.controls['actionDate'].valid && formGroup.controls['actionDate'].touched">
              {{ 'FIELD_REQUIRED' | translate }}
            </mat-error>
      </div>
      </div>

     <!-- <mat-form-field style="width:100%" [ngClass]="{rtl:appService.rtl()}">
        <input matInput [matDatepicker]="picker" placeholder="{{ 'CHOOSE' | translate}}&nbsp;{{ 'DATE' | translate}}"
          formControlName="actionDate"  >
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="!formGroup.controls['actionDate'].valid && formGroup.controls['actionDate'].touched">
          {{ 'FIELD_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>-->

    </div>
  </div>
  <div class="row">
    <div class="ml-auto">
      <div class="col-12 mt-1">
        <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="warn" mat-dialog-close
          style="margin-right:10px">{{ 'CANCEL' | translate}}</button>
        <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="primary" [disabled]="!formGroup.valid"
          type="submit">{{ 'SAVE' | translate}}</button>
      </div>
    </div>
  </div>
</form>
<div class="row" *ngIf="get">
  <div class="ml-auto">
    <div class="col-12 mt-3 mb-2">
      <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button class="active"
        (click)="openShowNotes()">{{ 'SHOW_NOTES' | translate}}</button>
    </div>
  </div>
</div>