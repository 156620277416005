import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { AlertTypeGroup } from 'projects/shared/resources/AlertTypeGroup';
import { AlertTypeGroupService } from 'projects/shared/services/alert-type-group.service';
import { AppService } from 'projects/shared/services/app.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-alert-type-empty',
  templateUrl: './alert-type-empty.component.html',
  styleUrls: ['./alert-type-empty.component.scss']
})
export class AlertTypeEmptyComponent implements OnInit {
  key: any
  code:any
  title:any;
  urlImg:any;
  alertTypeGroup:AlertTypeGroup;
 
  constructor(private translate: TranslateService,private alertTypeGroupService : AlertTypeGroupService, private codeTranslate: CodeTranslatePipe, public appService: AppService, private route: ActivatedRoute,private routerR: Router) { 
    this.routerR.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.code = this.route.snapshot.paramMap.get('code');
    this.key = this.route.snapshot.paramMap.get('key');
      this.alertTypeGroupService.getPages().subscribe(res => {
        res.resources.forEach(element => {
          this.urlImg= "/assets/images/check-2.svg";
          if (element.code == this.code){
          this.alertTypeGroup = element;
          this.title =  this.translate.instant('NO_ALERTS')  + "<br>" + this.codeTranslate.transform(element)
          document.getElementById("title").innerHTML = this.title; 
        }
        else{
        this.title= this.translate.instant('NO_ANIMALS') 
       } })  
      })
    
    
    if (!this.code)
      this.code = this.key
  }

}
