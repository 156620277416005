<app-animal-bar [id]="id" (goBack)="back()">
</app-animal-bar>

<div class="row px-4 mb-3">
    <div class="col center-element"> <span class="text-style bold-style" [ngClass]="{rtl:appService.rtl()}">{{
            'PEDIGREE' |
            translate }}</span></div>
</div>
<div class="d-flex justify-content-center">
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col center-element back-style width-size"> <span class="text-style"
                        [ngClass]="{rtl:appService.rtl()}">{{ 'PARENTS' |
                        translate }}</span></div>
            </div>
            <div class="row">
                <div class="col-6 text-center mt-2 me-1 center-element back-style width-size-2"> <span
                        class="text-style" [ngClass]="{rtl:appService.rtl()}">{{ 'FATHER' | translate }}</span></div>
                <div class="col-6 text-center mt-2 pt-2 back-style width-size-2"> <span class="text-style"
                        [ngClass]="{rtl:appService.rtl()}">{{ 'MOTHER' | translate }}</span></div>
            </div>
        </div>
    </div>
</div>
<div class="d-flex justify-content-center" [getAuthorized]="authorityPedigrees">
    <div class="row">
        <div class="col-6 text-center mt-2 me-1 center-element back-element-style  width-size-2 with-element"> <a
                *ngIf="fatherId" [routerLink]="" (click)="loadPedigree(fatherId)"><span 
                    [ngStyle]="{'color': colorGroupFather}" class="element-style"> {{appService.valueUid == nationalIdentification? (uidFather? uidFather : nameFather) : (appService.valueUid == nameLocalCode? (nameFather? nameFather: uidFather) : (uidFather? uidFather : nameFather))}}</span> &nbsp;<span [ngStyle]="{'color': colorGroupFather}"
                    *ngIf="codeFather">({{codeFather}})</span> </a>
            <div class="element-style" *ngIf="inseminationRef">{{inseminationRef}}</div>
            <div class="element-style" *ngIf="externalFather">{{externalFather}}</div>
        </div>
        <div class="col-6 text-center mt-2 me-1 center-element back-element-style width-size-2 with-element lien"> <a
                class="lien" *ngIf="motherId" [routerLink]="" (click)="loadPedigree(motherId)">
                <span [ngStyle]="{'color': colorGroupMother}" class="element-style">{{appService.valueUid ==nationalIdentification? (uidMother? uidMother : nameMother) : (appService.valueUid == nameLocalCode? (nameMother? nameMother: uidMother) : (uidMother? uidMother : nameMother))}}</span>&nbsp;<span [ngStyle]="{'color': colorGroupMother}"
                    *ngIf="codeMother">({{codeMother}})</span> </a></div>
    </div>
</div>
<div class="row px-4" [ngClass]="{rtl:appService.rtl()}" [getAuthorized]="authorityPedigrees">
    <div class="col center-element text-style mb-2 mt-3 "> <span>{{ 'LACT_NUM' |
            translate }}</span>&nbsp;<span>({{nbre}})</span></div>
</div>

<div class="d-flex justify-content-center">
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col center-element back-style width-size"> <span class="text-style"
                        [ngClass]="{rtl:appService.rtl()}">{{ 'DESCENDANTS' |
                        translate }}</span></div>
            </div>
            <div class="row">
                <div class="col-6 text-center mt-2  me-1 center-element back-style width-size-2"
                    [ngClass]="{rtl:appService.rtl()}"> <span class="text-style">{{ 'G1' |
                        translate }}</span></div>
                <div class="col-6 text-center mt-2 center-element back-style width-size-2"
                    [ngClass]="{rtl:appService.rtl()}">
                    <span class="text-style">{{ 'G2' |
                        translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mb-3" *ngIf="pedigreeDetails" [getAuthorized]="authorityPedigrees">
    <div class="d-flex justify-content-center" *ngFor="let element of pedigreeDetails.childrens">
        <div class="row">
            <div
                class="col-6 text-center mt-2 me-1 center-element direction back-element-style  width-size-2 with-element">
                <a class="lien" routerLink="" (click)="loadPedigree(element.id)">
                    <div class="element-style color-dark-blue"
                        [ngStyle]="{'color':  element.customerAnimalGroups[0] ? element.customerAnimalGroups[0].color : ''}">
                        <span >  {{appService.valueUid ==nationalIdentification? (element.uid? element.uid : element.name) : (appService.valueUid == nameLocalCode? (element.name? element.name: element.uid) : (element.uid? element.uid : element.name))}} </span>
                      
                        <span *ngIf="element.code">({{element.code}})</span>
                    </div>
                </a>
            </div>
            <div
                class="col-6 text-center mt-2 me-1 back-element-style width-size-2 element-style color-dark-blue center-element direction ">
                <div *ngFor="let ele of element.childrens" [ngClass]="ele.uid ? 'with-element' : 'no-element'">
                    <a class="lien" [routerLink]="" (click)="loadPedigree(ele.id)">
                        <div class="back-element-style width-size-2"
                            [ngStyle]="{'color':  ele.customerAnimalGroups[0] ? ele.customerAnimalGroups[0].color : ''}">
                            <span >  {{appService.valueUid ==nationalIdentification? (ele.uid? ele.uid : ele.name) : (appService.valueUid == nameLocalCode? (ele.name? ele.name: ele.uid) : (ele.uid? ele.uid : ele.name))}} </span>
                                
                            <span *ngIf="ele.code">({{ele.code}}) </span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>