import { Directive, ElementRef, Renderer2, Input } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { AuthorizedDirective } from './authorized.directive';

@Directive({
  selector: '[getAuthorized]',
  exportAs:'getAuthorized'
})
export class GetAuthorizedDirective extends AuthorizedDirective {
    
  @Input() getAuthorized: string;
  @Input() hideType: String = "none"
  constructor( public elmRef: ElementRef, 
    public renderer: Renderer2, public appService: AppService) {
      super(elmRef, renderer, appService)
  }

  ngOnInit() {
     
    if(this.getAuthorized)
    this.checkAuthDisplay("get", this.getAuthorized, this.hideType)
  }
}
