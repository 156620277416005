<div class="style-bar-troups w-100" ngxHideOnScroll [scrollingElementSelector]="'body'" [propertyUsedToHide]="'top'"
  [valueWhenHidden]="'-50px'" [valueWhenShown]="'90px'">
  <div class="row  h-100">
    <div class="col-12">
      <div class="row background-troups">
        <div class="col center-element" (click)="back()">
          <img src="/assets/images/left-arrow-1.svg" />
        </div>
        <div class="col center-element">
          <div class="row d-block">
            <div class="col text-center"> <img src="/assets/images/report-svgrepo-com-white.svg" />
            </div>
            <div class="col text-center ps-0 pe-0 pt-1"> <span class="text-style" [ngClass]="{rtl:appService.rtl()}">{{
                'REPORT' | translate }}</span></div>
          </div>
        </div>
        <div class="col center-element">
        </div>
      </div>
    </div>
  </div>
</div>


<div style="padding-top:100px" class=mat-elevation-z88>
  <div class="d-flex bd-highlight">
    <div *ngIf="project=='admin'" class="flex-grow-1 bd-highlight" class="d-flex justify-content-center">
      <div class="d-flex bd-highlight">
        <i class="me-2 flex-shrink-1 bd-highlight material-icons center-element" [ngStyle]="{'color':'#204157'}"
          style="align-self: center;font-size:40px;color:white">
          pets
        </i>
        <h2 class=" center-element w-100 bd-highlight" style="font-size:22px;color:#204157"> {{ 'ALL_LIVESTOCK' |
          translate}}</h2>
      </div>
    </div>
    <div class="p-2 bd-highlight">
      <div class="d-flex justify-content-around">
        <app-action-livestock-summary [selected]="selectedRows" [del]="del" [get]="get" [summary]="false"
          (load)="loadFramersWithAnimals($event)" (cleanSelected)="cleanSelected($event)" *ngIf="edit || del ">
        </app-action-livestock-summary>

        <app-search (searchWithKey)="loadFramersWithAnimals($event)"></app-search>
      </div>
    </div>
  </div>
  <div class="containerTab mat-elevation-z8">

    <mat-accordion displayMode="flat" multi>
      <section matSort class="mat-elevation-z2 mat-header-row" (matSortChange)="sortData($event)">
        <mat-checkbox *ngIf="edit || del" (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>

        <span class="mat-header-cell" mat-sort-header="uid">{{ 'UID' | translate}}</span>
        <span class="mat-header-cell" mat-sort-header="code">{{ 'LOCAL_CODE' | translate}} </span>
        <span class="mat-header-cell" mat-sort-header="breed_label">{{ 'BREED' |translate }}</span>
        <span class="mat-header-cell" mat-sort-header="sexe">{{ 'SEX' | translate}} </span>
        <span class="mat-header-cell" mat-sort-header="lactNum">{{ 'LACT_NUM' | translate}} </span>
        <span class="mat-header-cell" mat-sort-header="lastLactation">{{ 'L_DAYS' | translate}}</span>
        <span class="mat-header-cell" mat-sort-header="gest">{{ 'G_DAYS' | translate}} </span>
        <span class="mat-header-cell" mat-sort-header="birthDate">{{ 'AGE'| translate}} </span>
        <span class="mat-header-cell" mat-sort-header="weight">{{ 'WEIGHT' | translate}} </span>
        <span class="mat-header-cell" mat-sort-header="milkProd"> {{'MILK_PROD' | translate}}</span>
        <span class="mat-header-cell"> {{ 'SETTINGS'| translate}} </span>
      </section>

      <mat-expansion-panel *ngFor="let item of dataSource">
        <mat-expansion-panel-header class="mat-row">
          <mat-panel-title>
            <span>{{item.fullname}}</span>&nbsp;<span
              *ngFor="let address of item.addresses">({{address.address}})</span>
          </mat-panel-title>

          <mat-panel-description class="milkprod-style">
            <span>{{(item.totalMilkProduction || item.totalMilkProduction == 0?item.totalMilkProduction:'N/A')}}</span>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div>
          <table class="animals" *ngFor="let address of item.addresses">
            <tr *ngFor="let animal of address.animals">
              <td class="style-width-select">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? test(animal) : null"
                  [checked]="selection.isSelected(animal)">
                </mat-checkbox>
              </td>
              <td class="style-width">{{animal.uid? animal.uid : 'N/A'}}</td>
              <td class="style-width-code">{{animal.code? animal.code : 'N/A'}}</td>
              <td class="style-width">{{animal.breed | codeTranslate}}</td>
              <td class="style-width">{{animal.sexe? (animal.sexe | translate) : 'N/A'}}</td>
              <td class="style-width-code">{{animal.lactNum? animal.lactNum : 'N/A'}}</td>
              <td class="style-width-lact">{{convertToDays(animal.lastLactation)}}</td>
              <td class="style-width-lact">{{convertToDays(animal.gest)}}</td>
              <td class="style-width-lact">{{convertToAge(animal.birthDate)}}</td>
              <td class="style-width">{{animal.weight? animal.weight : 'N/A'}}</td>
              <!--td class="style-width">{{(item.totalMilkProduction || item.totalMilkProduction == 0?item.totalMilkProduction:'N/A')}}</td-->
              <td class="style-action">
                <div class="fa-stack fa-1x pointer" matTooltip="{{ 'DETACHE_DEVICE' | translate }}"
                  matTooltipClass="custom-tooltip" (click)="detachDevice(animal)"
                  *ngIf="animal.lastHistoryStartDate && animal.deviceId && editDevice && appType == APP_TYPE_ENUM.ADMIN">
                  <i class="fa fa-paw fa-stack-1x"></i>
                  <i class="fa fa-ban fa-stack-2x" style="opacity: .6;"></i>
                </div>
                <i class="material-icons pointer" matTooltip="{{ 'ADD_NOTE' | translate }}"
                  matTooltipClass="custom-tooltip" (click)="openAddNote(animal, item.id)"
                  *ngIf="addAnimalNotes && getAnimalNotes" style="vertical-align: middle;"
                  [ngClass]="animal.lastHistoryStartDate && animal.deviceId? 'styleAlign' : 'marginStyle'">
                  note_add
                </i>
                <i class="material-icons pointer" matTooltip="{{ 'EDIT' | translate }}" matTooltipClass="custom-tooltip"
                  style="vertical-align: middle;" (click)="cuAnimal(animal, address,  item.id)" *ngIf="edit">
                  edit
                </i>
                <i class="material-icons pointer" matTooltip="{{ 'DELETE' | translate }}"
                  matTooltipClass="custom-tooltip" style="vertical-align: middle;" (click)="deleteAnimal(animal)"
                  *ngIf="del">
                  delete
                </i>
              </td>
            </tr>
          </table>
        </div>
      </mat-expansion-panel>

    </mat-accordion>
    <mat-paginator #paginator class="paginator" [length]="resultsLength" [pageSize]="size"
      [pageSizeOptions]="pageSizeOptions" (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>

  </div>
</div>