<div [ngClass]=" verify? 'd-none' : 'd-block'">
    <div class="row">
        <div class="col mt-3 mb-3 ms-3 ">
            <div (click)="countryPage ? back() : (phonePage? displayCountryPage() : displayPhonePage())"><img
                    src="/assets/images/left-arrow.svg"></div>
        </div>
    </div>
    <div class="row">
        <div class="text-center col mt-4 mb-4">
            <img class="moome-style" src="/assets/images/moome.svg" />
        </div>
    </div>
    <form [formGroup]="formGroup">
        <div class="row">
            <div class="col mt-4 text-center text-style color-dark-blue">
                <span [ngClass]="{rtl:appService.rtl()}">{{ text | translate }}</span>
            </div>
        </div>
        <div class="row">
            <div class="col mb-4 text-center text-style color-dark-blue">
                <span [ngClass]="{rtl:appService.rtl()}">{{ sms | translate }}</span>
            </div>
        </div>
        <div class="row" *ngIf="countryPage">
            <div class="col center-element">
                <mat-select class="input-mob input-border width-input" disableOptionCentering panelClass="myPanelSettingLabelClass" [ngClass]="{rtl:appService.rtl()}"
                    formControlName="country">
                    <input (keydown)="$event.stopPropagation()" (keyup)=" appService.onKeyCountry($event.target.value)" class="input-search-mob">
                    <mat-option *ngFor="let country of appService.selectedCountries" [value]="country.id"
                        [ngStyle]="{'text-align':(appService.rtl()? 'right' : 'left')}"><span
                            [ngClass]="{rtl:appService.rtl()}">{{country | codeTranslate}}</span>
                    </mat-option>
                </mat-select>
            </div>
        </div>

        <div class="row" *ngIf="phonePage" [ngClass]="{rtl:appService.rtl()}">
            <div class="text-center col">
                <input matInput name="phone" class="input-mob input-border width-input" id="phone" type="number"
                    inputmode="text" formControlName="phone">
            </div>
        </div>

        <div *ngIf="passwordPage">
            <div class="row">
                <div class="text-center col mb-4">
                    <input matInput name="code" class="input-mob input-border width-input font-size" id="code"
                        type="number" inputmode="text" [ngClass]="{rtl:appService.rtl()}" formControlName="code"
                        placeholder="{{'PASSWORD' | translate}}">
                </div>
            </div>
            <div class="row">
                <div class="text-center col mb-4 position-relative">
                    <input matInput class="input-mob input-border width-input font-size"
                        [type]="hidePass ? 'text' : 'password'" id="newPassword" formControlName="newPassword"
                        [ngClass]="{rtl:appService.rtl()}" placeholder="{{'NEW_PASS' | translate}}">
                    <mat-icon *ngIf="hidePass" class="icon-pos"
                        (click)="!hidePass ? hhidePasside= true : hidePass=false"
                        [ngClass]="appService.rtl()? 'icon-ar-pos' :  'icon-fr-en-pos'" color="primary"> visibility
                    </mat-icon>
                    <mat-icon *ngIf="!hidePass" class="icon-pos" (click)="!hidePass ? hidePass= true : hidePass=false"
                        [ngClass]="appService.rtl()? 'icon-ar-pos' :  'icon-fr-en-pos'" color="primary">
                        visibility_off </mat-icon>
                </div>
            </div>
            <div class="row">
                <div class="text-center col mb-4 position-relative">
                    <input matInput class="input-mob input-border width-input font-size ellipsis-text"
                        [type]="hidePassC ? 'text' : 'password'" id="confNewPassword" formControlName="confNewPassword"
                        [ngClass]="{rtl:appService.rtl()}" placeholder="{{'CONF_NEW_PASSWORD' | translate}}">
                    <mat-icon *ngIf="hidePassC" class="icon-pos"
                        (click)="!hidePassC ? hidePassC= true : hidePassC=false"
                        [ngClass]="appService.rtl()? 'icon-ar-pos' :  'icon-fr-en-pos'" color="primary"> visibility
                    </mat-icon>
                    <mat-icon *ngIf="!hidePassC" class="icon-pos"
                        (click)="!hidePassC ? hidePassC= true : hidePassC=false"
                        [ngClass]="appService.rtl()? 'icon-ar-pos' :  'icon-fr-en-pos'" color="primary">
                        visibility_off </mat-icon>
                </div>
            </div>

            <div class="row">
                <div class="text-center col mt-2">
                    <div [ngClass]="!appService.showResend && !nextB ? 'd-block' : 'd-none'"
                        class="text-style2 color-dark-blue" [ngClass]="{rtl:appService.rtl()}">
                        <span [ngClass]="{rtl:appService.rtl()}">{{ 'EXPIRED_TIME' | translate }}</span>&nbsp;<span>00:{{appService.timer}}</span></div>
                    <div [ngClass]="appService.showResend? 'd-block' : 'd-none'">
                        <span class="text-style2 color-dark-blue" [ngClass]="{rtl:appService.rtl()}">{{ notRec |
                        translate
                        }}</span>&nbsp;<a class="text-style2 moome-color" (click)="resendCode()"
                            [ngClass]="{rtl:appService.rtl()}" color="primary">{{ resend |
                        translate }}</a></div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="text-center col  mt-5 mb-5">
                <button mat-raised-button color="primary" class="button-mob"
                    [ngClass]="!formGroup.valid? 'opaci ' : '' "
                    (click)="countryPage ? displayPhonePage() : (phonePage? dispalyPasswordPage() : verif())"><span
                        [ngClass]="{rtl:appService.rtl()}">{{ next |
                            translate }}</span></button>
            </div>
        </div>

    </form>
</div>
<div [ngClass]="verify ? 'd-block h-100' : 'd-none'">
    <app-verified></app-verified>
</div>