import { Directive, ElementRef, Renderer2, Input } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { AuthorizedDirective } from './authorized.directive';

@Directive({
  selector: '[deleteAuthorized]',
  exportAs:'deleteAuthorized'

})
export class DeleteAuthorizedDirective extends AuthorizedDirective {
    
  @Input() deleteAuthorized: string;
  @Input() hideType: String = "none"
  
  constructor( public elmRef: ElementRef, 
    public renderer: Renderer2, public appService: AppService) {
      super(elmRef, renderer, appService)
   
  }
   ngOnInit() {
     if(this.deleteAuthorized)
    this.checkAuthDisplay("delete", this.deleteAuthorized, this.hideType)
  }
}
