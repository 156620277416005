import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Region } from '../resources/Region';
import { MoomeRestService } from './moome-rest.service';

@Injectable({
    providedIn: 'root'
})
export class RegionService extends MoomeRestService<Region> {

    constructor(public http: HttpClient, injector: Injector) {
        super(Region);
    }
}