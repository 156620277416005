import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'projects/shared/services/app.service';
import { CustomRouter } from '../../services/custom-router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FarmerSupervisorRequestService } from 'projects/shared/services/farmer-supervisor-request.service';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { SettingCustomerDialogComponent } from '../../settings/settings-customer/setting-customer-dialog/setting-customer-dialog.component';
import { CustomerService } from 'projects/shared/services/customer.service';
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { Customer } from 'projects/shared/resources/Customer';
import { Validator } from 'projects/shared/tools/validators';
import { Alert } from 'projects/shared/tools/constants';
import { Subscription } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';
@Component({
  selector: 'app-button-dialog',
  templateUrl: './button-dialog.component.html',
  styleUrls: ['./button-dialog.component.scss']
})
export class ButtonDialogComponent implements OnInit {

  formGroup: UntypedFormGroup;
  idFarmer: any;
  nameFarmer: String;
  customer: Customer = new Customer()
  phoneLength: number
  phoneFarmer: string
  private executionsSubscription: Subscription;

  constructor(public dialogRef: MatDialogRef<ButtonDialogComponent>,
    private recaptchaV3Service: ReCaptchaV3Service, public appService: AppService, private alertsService: AlertsService, public customerService: CustomerService,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: UntypedFormBuilder, public router: CustomRouter,
    public supervisorService: SupervisorService, public dialog: MatDialog, private translate: TranslateService, private farmerSupervisorRequestService: FarmerSupervisorRequestService) { }


  ngOnInit(): void {
    this.phoneLength = this.appService.connectedCustomer.addresses[0].country.phoneLength

  }

  subscribe() {
    const dialogRef = this.dialog.open(SettingCustomerDialogComponent, {
      data: {
        title: this.translate.instant('PHONE_FARMER'),
        editType: "phone",
        value: 'phone',
        //validators: [Validators.required, Validator.validPhoneLength(this.translate, this.phoneLength, this.alertsService)],

        onConfirm: (formGroup) => {

         /* const dialogRef = this.dialog.open(RecaptchaComponent, {
            data: {
              onConfirm: (token) => {*/
              this.appService.showRecaptcha();
              this.executionsSubscription = this.recaptchaV3Service.execute('importantAction')
              .subscribe((token: string) => {
                this.appService.showLoading()
                this.customer.phone = (formGroup.controls['phone'].value)
                this.customer['recaptchaToken'] = token
                
                this.supervisorService.sendSms(this.customer).subscribe((res: Customer) => {
                  this.nameFarmer = res.fullname;
                  this.phoneFarmer = res.phone
                  this.appService.hideLoading()
                  this.dialog.closeAll();
                  this.message(this.translate.instant('SMS_SENT') + " " + this.translate.instant('FARMER') + " " + this.nameFarmer)
                  setTimeout(() => {
                    const dialogRef = this.dialog.open(SettingCustomerDialogComponent, {
                      data: {
                        title: this.translate.instant('PASSWORD'),
                        editType: "code",
                        value: 'code',
                        otherData: this.phoneFarmer,
                        onConfirm: (formGroup) => {
                          /*const dialogRef = this.dialog.open(RecaptchaComponent, {
                            data: {
                              onConfirm: (token) => {*/
                              this.appService.showRecaptcha();
                              this.executionsSubscription = this.recaptchaV3Service.execute('importantAction')
                              .subscribe((token: string) => {
                                this.appService.showLoading()
                                let code = formGroup.controls['code'].value
                                let body = { 'code': code.toString(), 'phone': this.phoneFarmer, 'recaptchaToken': token }
                                this.supervisorService.checkCode(body).subscribe(res => {
                                  this.appService.hideLoading()

                                  if (res) {
                                    this.dialog.closeAll();
                                    this.message(this.translate.instant('FARMER_ADDED_SUCCESS'))
                                  }
                                  else
                                    this.message(this.translate.instant('FAILED_ADD_FARMER'))
                                }, error => {
                                  this.appService.hideLoading()
                                  this.alertsService.open(this.translate.instant(error.error.code), Alert.OK)
                                })
                              })
                        //    }
                       //   })
                        
                        }
                      }
                    })
                  }, 4000);
                }, error => {
                  this.appService.hideLoading()
                  this.alertsService.open(this.translate.instant(error.error.code), Alert.OK)
                });
              })
       






          /* this.customerService.findByPhone(formGroup.controls['phone'].value).subscribe(
             res => {
               this.customer = res;
               this.nameFarmer = this.customer.fullname;
             });
             this.appService.showLoading()
           this.farmerSupervisorRequestService.saveRequest(formGroup.controls['phone'].value).subscribe(
             data => {
               this.appService.hideLoading()
               this.dialog.closeAll();
               this.message(this.translate.instant('FARMER') + " " + this.nameFarmer + " " + this.translate.instant('ADDED_AFTER_ACCEPTED') + " ")
             })*/
        }

      }
    });
  }

  ngOnDestroy() {
    this.appService.hideRecaptcha()
    if (this.executionsSubscription) {
      this.executionsSubscription.unsubscribe();
    }
  }

  notSubscribe() {
    this.router.navigateByUrl('/farmers/sign-up')
  }

  message(msg) {
    this.alertsService.open(msg);
  }
}
