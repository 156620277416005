import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import {Breed} from './Breed';
import {Address} from './Address';
import {Device} from './Device';
import { Project } from "./Project";
@HateoasResource('animals')
export class Animal extends Resource {
    id: number;
    uuid: string;
    uid: string;
    code: string;
    name: string;
    breed: Breed | any;
    address: Address | any;
    device: Device | any;
    sexe: String;
    lactNum: number;
    birthDate: Date;
    weight: number;
    lastLactation: Date;
    gest: Date;
    lactDays: any;
    gestDays: any;
    customerAnimalGroups: any[]
    project: Project;
    height:number;
  }
