import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
@Component({
  selector: 'app-rectangle-card',
  templateUrl: './rectangle-card.component.html',
  styleUrls: ['./rectangle-card.component.scss']
})
export class RectangleCardComponent implements OnInit {
  @Input() element:any
  constructor(public appService: AppService) { }

  ngOnInit(): void {
  }

}
