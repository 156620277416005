
import { MoomeRestService, Options } from './moome-rest.service';

import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { FarmerMiscInventory } from '../resources/FarmerMiscInventory';

@Injectable({
    providedIn: 'root'
})
export class FarmerMiscInventoryService extends MoomeRestService<FarmerMiscInventory> {

    constructor(public http: HttpClient, private configService: ConfigService) {
        super(FarmerMiscInventory);
    }

    findByFarmer() {
        let options: Options =
        {
            params: {
                projection: "farmer-misc-inventory",
            }
        }
        return this.customRequestGet(`/findAllByFarmer`, null, options).pipe(
            map(res => { return res['_embedded']?.farmerMiscInventories }
            ))
    }

    findByFarmerByKey(key) {
        let options: Options =
        {
            params: {
                key: key,
                projection: "farmer-misc-inventory",
            }
        }
        
        return this.customRequestGet(`/findAllByFarmerByKey`, null, options).pipe(
            map(res => { return res['_embedded']?.farmerMiscInventories }
            ))
    }
    

    deleteById(body) {
        return this.http.delete(this.configService.getApiPath() + `/farmerMiscInventorys/${body.id}/deleteStock`)
    }

    saveFarmerMiscInventory(body) {
		return this.http.post(this.configService.getApiPath() + `/farmerMiscInventorys/save`, body);
    }
    update(body) {
		return this.http.post(this.configService.getApiPath() + `/farmerMiscInventorys/update`, body);
    }
    findMiscInventoryByExisteDate(date){
        return this.http.get(this.configService.getApiPath() + `/farmerMiscInventorys/findMiscInventoryByExisteDate?date=${date}`);
    }
    findTitleAndUnity(){

        let options: Options =
        {
            params: {
               
                projection: "farmer-misc-inventory",
            }
        }
        
        return this.customRequestGet(`/findTitleAndUnity`, null, options).pipe(
            map(res => { return res['_embedded']?.farmerMiscInventories }
            ))
    }
   getMiscInventory(id){
        return this.http.get(this.configService.getApiPath() + `/farmerMiscInventorys/getStock/${id}`);
    }
	 
}