import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FarmersComponent } from './farmers.component';
import { FarmerCardModule } from './farmer-card/farmer-card.module';
import { TranslateModule } from '@ngx-translate/core';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { NoAnimalsModule } from '../animals/no-animals/no-animals.module';
import { ButtonDialogComponent } from './button-dialog/button-dialog.component';
import { MaterialModule } from '../material/material.module';


@NgModule({
    declarations: [FarmersComponent],
    imports: [
        CommonModule,
        FarmerCardModule,
        TranslateModule,
        MoomeCommonModule,
        NoAnimalsModule,
        NgxHideOnScrollModule,
        MaterialModule,
    ],
    exports: [
        FarmersComponent
    ]
})
export class FarmersModule { }

