<div class="row gx-1" (click)="apply()">
    <div class="style-bar col mt-3 mb-2" [ngStyle]="{'background-color':alertTypeGroup.color}">
        <div *ngIf="alertTypeGroup.number != 0" class="dot">
            <span class="center-element number-style mt-2">{{alertTypeGroup.number>=100 ? '+99' : alertTypeGroup.number}}</span>
        </div>
        <img class="m-auto d-block center-element mt-2 mb-2"  [src]="sanitizer.bypassSecurityTrustUrl(icon)">
        <div *ngIf="!checkType && !isTankAlert" class="text-center text-style pt-1 "
        [ngClass]="appService.rtl()? 'font-text-ar' : 'font-text'"
 >
            <div [ngClass]="{rtl:appService.rtl()} ">{{alertTypeGroup.title | translate }}</div>
        </div>
        <div *ngIf="checkType" class="text-center text-style pt-1 "
        [ngClass]="appService.rtl()? 'font-text-long-ar' : 'font-text'"
 >
            <div [ngClass]="{rtl:appService.rtl()} ">{{alertTypeGroup.title | translate }}</div>
        </div>

        <div *ngIf="isTankAlert" class="text-center text-style pt-1 "
        [ngClass]="appService.rtl()? 'font-text-tanks-ar' : 'font-text'"
 >
            <div [ngClass]="{rtl:appService.rtl()} ">{{alertTypeGroup.title | translate }}</div>
        </div>
    </div>
</div>