<div class="color-page" *ngIf="Images">
    <div class="center">
        <div class="row">
            <div class="col">
                <owl-carousel [options]="SlideOptions" [carouselClasses]="['owl-theme', 'sliding']">
                    <div *ngFor="let image of Images">
                        <img class="img-style mt-3" src={{image.img}} />
                        <div style="height: 225px">
                            <div class="row">
                                <div class="col mt-5 text-center color-dark-blue text-style">
                                    <span [ngClass]="{rtl:appService.rtl()}">{{ image.text1 | translate }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col mb-2 text-center color-dark-blue text-style">
                                    <span [ngClass]="{rtl:appService.rtl()}">{{ image.text2 | translate }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col  text-center"><button mat-raised-button color="primary"
                                    (click)="next(image)" class="button-mob position_button"><span
                                        [ngClass]="{rtl:appService.rtl()}">{{ image.button | translate
                                        }}</span></button>
                            </div>
                        </div>
                       
                    </div>
                    
                </owl-carousel>
                <div class="row">
                    <div class="col text-center mt-2 mb-4 lang-pos">
                            <app-language [value]="selectedLanguage" [color]="color"></app-language>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>