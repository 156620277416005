import { Injectable } from '@angular/core';
import { MoomeRestService, Options } from './moome-rest.service';
import { map } from 'rxjs';
import { FarmerFeedConsumption } from '../resources/FarmerFeedConsumption';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root'
})
export class FarmerFeedConsumptionService extends MoomeRestService<FarmerFeedConsumption> {
  
   
    constructor(public http: HttpClient, private configService: ConfigService) {
        super(FarmerFeedConsumption);
    }

    findByFarmer(id) {
        
        let param: any=
        {projection: 'farmer-feed-consumption',
       }
        if(id!=null)
        param.id=id;

        let options: Options =
        {
            params: 
            param
        }
        return this.customRequestGet(`/findAllByFarmer`, null, options).pipe(
            map(res => { return res['_embedded']?.farmerFeedConsumptions }
            ))
    }

    findAllByFarmerByGroupOrDate(key: any) {
        let options: Options =
        {
            params: {
                key: key
               
            }
        }
        return this.customRequestGet(`/findAllByFarmerByGroupOrDate`, null, options).pipe(
            map(res => { return res }
            ))
    }
    findByFarmerByKey(key: any,idStock) {
        let options: Options =
        {
            params: {
                key: key,
                idStock:idStock,
                projection: "farmer-feed-consumption",
            }
        }
        return this.customRequestGet(`/findAllByFarmerByKey`, null, options).pipe(
            map(res => { return res['_embedded']?.farmerFeedConsumptions }
            ))
    }

    deleteById(body) {
        return this.http.delete(this.configService.getApiPath() + `/farmerFeedConsumptions/${body.id}/deleteFeed`)
    }

    findFeedConsumptionByExisteDate(date){
        return this.http.get(this.configService.getApiPath() + `/farmerFeedConsumptions/findFeedConsumptionByExisteDate?date=${date}`);
    }
    saveAll(listStocks,date,groupId,consumptionPeriod,period){
		return this.http.post(this.configService.getApiPath() + `/farmerFeedConsumptions/save`, {'stocks':listStocks,'date':date,'groupId':groupId,'consumptionPeriod':consumptionPeriod,'period':period});
	  }
      find(){
        return this.http.get(this.configService.getApiPath() + `/farmerFeedConsumptions/find`);
    }
    update(idStock,body){
        return this.http.post(this.configService.getApiPath() + `/farmerFeedConsumptions/${idStock}/update`,body)
    }
    countByDateAndGroup(startDate,endDate,listCodeGroups) {
        return this.http.get(this.configService.getApiPath() + `/farmerFeedConsumptions/countByDateAndGroup?listCodeGroups=${listCodeGroups}&startDate=${startDate}&endDate=${endDate}`);
    }
    countByDateAndGroupDetails(startDate,endDate,listCodeGroups) {
        return this.http.get(this.configService.getApiPath() + `/farmerFeedConsumptions/countByDateAndGroupDetails?listCodeGroups=${listCodeGroups}&startDate=${startDate}&endDate=${endDate}`);
    }
    countByDateFarmDetails(startDate,endDate) {
        return this.http.get(this.configService.getApiPath() + `/farmerFeedConsumptions/countByDateFarmDetails?startDate=${startDate}&endDate=${endDate}`);
    }
    countFinancialDetails(startDate,endDate) {
        return this.http.get(this.configService.getApiPath() + `/farmerFeedConsumptions/countFinancialDetails?startDate=${startDate}&endDate=${endDate}`);
    }
    feedConsumptionOverview(startDate,endDate) {
        return this.http.get(this.configService.getApiPath() + `/farmerFeedConsumptions/feedConsumptionOverview?startDate=${startDate}&endDate=${endDate}`);
    }
}