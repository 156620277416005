import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { AppService } from 'projects/shared/services/app.service';
import { Alert } from 'projects/shared/tools/constants';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { CustomRouter } from '../services/custom-router';
import { CustomerLikeService } from 'projects/shared/services/customer-like.service';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide: Boolean
  loginForm: UntypedFormGroup;
  router
  auth: string[] = [];
  service: any
  language: String = 'ar'
  selectedLanguage = {};
  color='color-white'
  constructor( private dateAdapter: DateAdapter<Date>,public dialog: MatDialog, public translate: TranslateService,
    private tokenManagementService:
      TokenManagementService, router: CustomRouter, public appService: AppService, public alertsService: AlertsService,
    private formBuilder: UntypedFormBuilder) {
    this.router = router
    this.createForm();
    const l = this.tokenManagementService.getTranslateLanguage()
    if (l) {
      this.language = l;
      this.selectedLanguage=this.appService.chooseLanguage(this.language);
    }
  }


  ngOnInit() {
    if(this.appService.connectedCustomer){
    this.service = this.appService.checkService();
    if(this.router.history[this.router.history.length -1]!="/settings"){
    this.saveDeviceToken();
    this.saveWebToken();
    }}
    this.tokenManagementService.logout();
    this.appService.connectedCustomer = null;

   
  }

  saveDeviceToken() {
    this.service.saveDeviceToken({ "deviceToken": null }).subscribe(
      data => {
        console.log('delete device token success');
      }, error => {
        console.log('delete device token failed');
      }
    );
  }

  saveWebToken(){
    this.service.saveWebToken({ "webToken": null }).subscribe(
      data => {
        console.log('delete web token success');
      }, error => {
        console.log('delete web token failed');
      }
    );
  }

  
  createForm() {
    this.loginForm = this.formBuilder.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required],
    });
  }

  login() {

    let message: string;
    if (!this.loginForm.valid) {
      message = this.translate.instant('FIELD_REQUIRED');
      let alert = this.translate.instant(Alert.OK)
      this.alertsService.open(message, alert);
    }
    else {
      this.appService.showLoading();
      this.tokenManagementService.login(this.loginForm.controls.username.value.replace(/\s/g, "").toLowerCase(), this.loginForm.controls.password.value)
        .subscribe(
          data => {
            this.tokenManagementService.setToken(data['access_token'], data['refresh_token'], data['expires_in'], data['fullname']);
            this.appService.hideLoading();
            this.router.navigateByUrl("/home")
            //googleAnalytics
            this.appService.googleAnalyticsAllPages('login')
          },
          error => {
            this.appService.hideLoading();
            message = this.translate.instant(error.error.error_description);
            let alert = this.translate.instant(error.error.error_description == "User is disabled" ? Alert.CONTACT_US : Alert.OK )
            this.alertsService.open(message, alert);
          }
        );
    }
  }
}
