
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Project } from '../resources/Project';
import { ConfigService } from './config.service';
import { MoomeRestService, Options } from './moome-rest.service';

@Injectable({
	providedIn: 'root'
})
export class ProjectService extends MoomeRestService<Project> {


	constructor(public http: HttpClient, injector: Injector,private configService: ConfigService) {
		super(Project);
	}

	getProjectsWithFilter(id, page, size, key) {
		let param: any =
		{
			projection: 'projects',
			
			id:id,
			
		}
		if (key != null)
			param.key = key.toLowerCase();

		let options: Options = {
			params:
				param
			,
			pageParams:{
				page: page,
				size:size
			}
		}

		return this.searchPages(`getProjectsByKey`, options)
	}

	getProjectOfConnected() {
		let param: any=
        {projection: 'projects',
        }
       

        let options: Options = {
            params: 
               param
             ,
			 pageParams: {
				
			 }
        }
		return this.customRequestGet(`?projection=${param.projection}`).pipe(
			map(res =>{ return res['_embedded']?.projects}
				
			)
		)
	}

	getProjectsByKeyForAdmin( page, size, key) {
		
		let param: any =
		{
			projection: 'projects',
			
		}
		if (key != null)
			param.key = key.toLowerCase();

		let options: Options = {
			params:
				param
			,
			pageParams:{
				page: page,
				size:size,
			}
		}

		return this.searchPages(`getProjectsByKeyForAdmin`, options)
	}

	saveProject(body) {
		return this.http.post(this.configService.getApiPath() + `/projects/`, body);
	}

	isHasAnimals(project): Observable<any> {
		return this.http.get(this.configService.getApiPath() + `/projects/hasAnimals/` + project);
	}

	/*deleteProject(projectId) {
        return this.http.delete(this.configService.getApiPath() + `/projects/deleteProject/${projectId}`);
    }*/


	
}
