<div class="row " >
    <div class="d-flex justify-content-center">
        <div [ngClass]="appService.rtl()? 'mt-2 pt-2' : 'mt-3 pt-3' ">  
        </div>
        <div class=" center-element back-style me-2 mb-2 ">
            <div class="row col-12 mx-auto" [ngClass]="{rtl:appService.rtl()}">
                <div class=" mt-3 col-12 text-center text-style"  (click)="toggleDropdown()" >
                    <span>{{element}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="dropdownVisible">
    <app-history-card *ngFor="let form of forms[element]" class="col-12 mt-4" [form]="form" [service]="service" (itemClick)="action(form)"
    (reload)="findForms()" [authority]="authority" (reloadState)="findForms()" [section]="section"></app-history-card>
</div>

