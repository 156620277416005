import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { Customer } from "./Customer";
import { Region } from './Region';
import { Country } from './Country';

@HateoasResource('addresses')
export class Address extends Resource {
    id: number;
    name: string;
    address:string;
    customer:Customer;
    longitude: number;
    latitude: number;
    region: Region;
    country: Country
  }