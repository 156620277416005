import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'projects/shared/services/app.service';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import { DeviceService } from 'projects/shared/services/device.service';
import { ReportService } from 'projects/shared/services/report.service';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { Alert } from 'projects/shared/tools/constants';

@Component({
  selector: 'app-temp-graph',
  templateUrl: './temp-graph.component.html',
  styleUrls: ['./temp-graph.component.scss'],
})

export class TempGraphComponent implements OnInit {
  @Input() formGroup

  lineChart: Chart;
  title: any;
  datasets: any[] = [];
  showGraph: Boolean
  dayTime: String[];
  dayAccX1: Number[];
  dayAccY1: Number[];
  dayAccZ1: boolean[];
  startDate: any;
  endDate: String;



  constructor(public translate: TranslateService, public appService: AppService, private deviceService: DeviceService, private alertsService: AlertsService,
    private reportService: ReportService
  ) {


  }

  ngOnInit() {

  }

  setPeriod() {
    if (this.formGroup.controls['period'].value != null) {
      let start

      /*if (this.formGroup.controls['period'].value == "3_HOURS")
        start = moment().subtract(3, 'hours');

      if (this.formGroup.controls['period'].value == "6_HOURS")
        start = moment().subtract(6, 'hours');*/

      if (this.formGroup.controls['period'].value == "1_DAY")
        start = moment().subtract(1, 'days')

      if (this.formGroup.controls['period'].value == "3_DAYS")
        start = moment().subtract(3, 'days');

      if (this.formGroup.controls['period'].value == "1_WEEK")
        start = moment().subtract(1, 'weeks');

      if (this.formGroup.controls['period'].value == "1_MONTH")
        start = moment().subtract(1, 'months');

      /*if (this.formGroup.controls['period'].value == "3_MONTHS")
        start = moment().subtract(3, 'months');*/

      this.title = start.format("MM/DD/YYYY HH") + "H00 " + " -- "
        + moment().format("MM/DD/YYYY HH:") + "H00 ";
      this.startDate = moment(start)

      this.startDate.set({ minute: 0, second: 0, millisecond: 0 })
      this.startDate = this.startDate.toISOString();
      this.endDate = moment().toISOString()

    } else {
      this.title = moment(this.formGroup.controls['startDate'].value).format("MM/DD/YYYY") + " 00H00" + " -- "
        + moment(this.formGroup.controls['endDate'].value).format("MM/DD/YYYY") + " 23H59";
      this.startDate = moment(this.formGroup.controls['startDate'].value).toISOString();
      this.endDate = moment(this.formGroup.controls['endDate'].value).add(24, 'hours').toISOString();

    }
  }





  apply() {
    if (this.lineChart) {
      this.lineChart.destroy();
      this.showGraph = false;
    }
    this.showGraph = true;
    let tankId = this.formGroup.controls['code'].value.id;
    let on = "ON"
    let off = "OFF"

    this.setPeriod();

    this.reportService.getTempGraph(tankId, this.startDate, this.endDate).subscribe(res => {
      this.dayTime = [];
      this.dayAccX1 = [];
      this.dayAccY1 = [];
      this.dayAccZ1 = [];
      this.datasets = [];
      let keys = Object(res);
      keys.forEach(element => {
        this.dayAccX1.push(element.temperature)
        this.dayAccY1.push(element.eventAgi)
        this.dayAccZ1.push(element.eventFri)
        this.dayTime.push(moment(element.date).format('MM/DD HH:mm:ss'))

      });
      this.datasets.push({
        label: this.translate.instant('TEMPERATURE'),
        data: this.dayAccX1,
        fill: false,
        // lineTension: 0.2,
        borderColor: "blue",
        backgroundColor: "blue",
        borderWidth: 1,
        yAxisID: 'X1'
      },
        {
          label: this.translate.instant('EVENT_FRI'),
          data: this.dayAccZ1,
          fill: false,
          // lineTension: 0.2,
          borderColor: "green",
          backgroundColor: "green",
          borderWidth: 1,
          yAxisID: 'Y1'
        },
        {
          label: this.translate.instant('EVENT_AGI'),
          data: this.dayAccY1,
          fill: false,
          // lineTension: 0.2,
          borderColor: "red",
          backgroundColor: "red",
          borderWidth: 1,
          yAxisID: 'Z1'
        })

      this.showGraph = true;
      this.appService.hideLoading();

      this.lineChart = new Chart('lineChart', {
        type: 'line',
        data: {
          labels: this.dayTime,
          datasets: this.datasets
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: this.title,
            }
          }
          , 
          elements:
          {
            point:
            {
              radius: 0,
            }
          },
       scales: {
            x: {
              type: 'time',
              time: {
                unit: 'minute',
                displayFormats: {
                  'minute': 'MM/DD HH:mm',
                }
              }
            },
            X1: {
              type: 'linear',
              position: 'left',
              max: 40,
              min: -40,
              beginAtZero: false,
              ticks: {
                color: "blue",
                stepSize: 1,

                callback: function (value) {
                  if (value > 0 && value < 41) return value;
                }
              },
            },
            Y1: {
              type: 'linear',
              position: 'left',
              max: 12,
              min: -8,
              beginAtZero: false,
              ticks: {
                color: "green",
                stepSize: 1,
                callback: function (value) {
                  if (value > -1 && value < 2 && value == 0) return off;
                  if (value > -1 && value < 2 && value == 1) return on;


                }
              }
            },
            Z1: {
              type: 'linear',
              position: 'left',
              max: 15,
              min: -5,
              beginAtZero: false,
              ticks: {
                color: "red",
                stepSize: 1,
                callback: function (value) {
                  if (value > -1 && value < 2 && value == 0) return off
                  if (value > -1 && value < 2 && value == 1) return on
                }
              }
            }

          }
        }
      })
    }, error => {

      this.appService.hideLoading();
      let message = this.translate.instant(error.error.code);
      let alert = this.translate.instant(Alert.OK)
      this.alertsService.open(message, alert);
    })
  }
}