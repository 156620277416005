<div class="row py-3 gx-1 center-element">
    <div class="center-element col-12  cercle  {{element.class}}">
        <div class="text-center inner-font-big {{element.value1Font}} ">
            <span>
                {{element.value1}}
            </span>
        </div>
        <div class="text-center {{element.value2Font}}  ">
            <span>
                {{element.value2 | translate}}
            </span>
        </div>

    </div>
    <div class="text-center center-element px-3 pt-2 {{element.labelFont}}">
        <span class="mx-3">{{element.label | translate}}</span>
    </div>
    <div class="text-center center-element px-3 pt-2 {{element.labelFont}}" *ngIf="element.label1">
        <span class="mx-3" >({{element.label1 | translate}})</span>
    </div>
    <div class="text-center center-element px-3 pt-2 {{element.labelFont}}" *ngIf="element.label2">
    <span class="mx-3" >{{element.label2 | translate}}/{{element.label3 | translate}}</span>
</div>