import { Injectable, Injector } from '@angular/core';
import { MoomeRestService } from './moome-rest.service';
import { environment } from 'projects/admin/src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { Timeline } from '../resources/timeline';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class TimelineChildService extends MoomeRestService<Timeline>{

  constructor(injector: Injector, public http: HttpClient, private configService: ConfigService) {
		super(Timeline);
  }
  
  deleteChild(timelineId,childId) {
    return this.http.delete(this.configService.getApiPath() + `/timelineChilds/${timelineId}/child/${childId}`);
  }


}
