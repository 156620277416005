import { Directive, ElementRef, Renderer2, Input } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { AuthorizedDirective } from './authorized.directive';

@Directive({
  selector: '[createAuthorized]',
  exportAs: 'createAuthorized'
})
export class CreateAuthorizedDirective extends AuthorizedDirective {

  @Input() hideType: String = "none"
  @Input() createAuthorized;

  constructor(public elmRef: ElementRef,
    public renderer: Renderer2, public appService: AppService) {
    super(elmRef, renderer, appService)

  }
  ngOnInit() {
    if (this.createAuthorized)
      this.checkAuthDisplay("create", this.createAuthorized, this.hideType)
  }
}
