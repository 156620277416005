import { Injectable, Injector } from '@angular/core';
import { Customer } from '../resources/Customer';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';

import { Role } from '../resources/Role';
import { CustomerCommonService } from './customer-common.service';
import { TokenManagementService } from './token-management.service';
import { Entreprise } from '../resources/Entreprise';
import { Observable } from 'rxjs/internal/Observable';
import { Options } from './moome-rest.service';
@Injectable({
	providedIn: 'root'
})

export class CustomerService extends CustomerCommonService<Customer> {

	constructor(public tokenManagementService: TokenManagementService, public http: HttpClient, private configService: ConfigService,) {
		super(Customer);
	}

	saveCustomer(body) {
		return this.http.post(this.configService.getApiPath() + `/customers/save`, body);
	}

	getAnimals(id) {
		return this.search(`getAnimalsByCustomer?id=${id}&projection=animals`)
	}

	getAddressesByCustomerId(id) {
		return this.search(`getAddressesByCustomerId?id=${id}&page=0&size=5&projection=addressesByCustomers`)
	}

	getCustomersById(id) {
		return this.search(`getCustomers?id=${id}&projection=customer`)
	}

	getConnectedCustomerInfo() {
		return this.http.get(this.configService.getApiPath() + "/customers/info");
	}

	findByFullname(fullname) {
	
		let options: Options = {
			
				params: { 
					fullname: fullname,
				}
			   }
		
		return this.searchSingle(`findByFullname`, options)
	}

	updatePassword(id, password) {
		return this.http.put(this.configService.getApiPath() + `/customers/${id}/updatePassword`, password);
	}

	getRoles() {
		return (this.search(`roles`) as Observable<any>) as Observable<Role[]>
	}

	getAdditionalRole(){
		return (this.search(`additionalRole`) as Observable<any>) as Observable<Role[]>
	}
	verifPassword(password) {
		return this.http.get(this.configService.getApiPath() + `/customers/verifPassword?password=${password}`);
	}

	updateUsername(customerId, customer) {
		let httpOptions = {
			headers: new HttpHeaders({
				'language': this.tokenManagementService.getTranslateLanguage(),
			})
		};

		return this.http.put(this.configService.getApiPath() + `/customers/${customerId}/updateUsername`, customer, httpOptions);
	}

	updateFullname(id, fullname) {
		return this.http.put(this.configService.getApiPath() + `/customers/${id}/updateFullname`, fullname);
	}

	updateAddress(customerId, countryId) {
		return this.http.put(this.configService.getApiPath() + `/customers/${customerId}/updateAddress`, countryId);
	}

	updatePhone(customerId, phone) {
		return this.http.put(this.configService.getApiPath() + `/customers/${customerId}/updatePhone`, phone);
	}

	updateEnabled(username, value) {
		return this.http.put(this.configService.getApiPath() + `/customers/${username}/updateEnabled`, value);
	}

	updatePasswordApp(id, customer) {
		let httpOptions = {
			headers: new HttpHeaders({
				'language': this.tokenManagementService.getTranslateLanguage(),
			})
		};
		return this.http.put(this.configService.getApiPath() + `/customers/update-password`, customer, httpOptions);
	}

	saveDeviceToken(token) {
		return this.http.put(this.configService.getApiPath() + `/customers/save-device-token`, token);
	}
	getEntrepriseList() {
		return (this.search(`entreprise`) as Observable<any>) as Observable<Entreprise>
	}
	addFarmerToSupervisor(phone){
		return this.http.post(this.configService.getApiPath() + `/customers/addFarmerToSupervisor/${phone}`, phone);
	}

	getEntrepriseOfConnected(id){
		let options: Options = {
			params: { 
				projection: 'entreprise-project',
				id: id,
			 }
		}
		return this.search(`getEntrepriseOfConnectedSupervisor`, options)
	}

        findCustomerById(id){
		return this.http.get(this.configService.getApiPath() + `/customers/${id}/findCustomerById`, id);
	}
	findByPhone(phone){
		return this.http.get(this.configService.getApiPath() + `/customers/${phone}/findByPhone`, phone);
	}
	/*savelanguage() {
		let language = this.tokenManagementService.getTranslateLanguage();
		return this.http.put(this.configService.getApiPath()+ `/customers/save-language`, language);
	}*/
	saveWebToken(token) {
		return this.http.put(this.configService.getApiPath() + `/customers/save-web-token`, token);
	}
	/*findIdCustomerByFullname(fullname){
		return this.http.get(`findIdCustomerByFullname?fullname=${fullname}&projection=customer_id`)
	}*/

	findIdCustomerByFullname(fullname){
		return this.http.get(this.configService.getApiPath() + `/customers/${fullname}/findIdCustomerByFullname`, fullname);
	}
	getCountAnimalByFarmer(id){
		return this.http.get(this.configService.getApiPath() + `/customers/${id}/getCountAnimalByFarmer`, id);
	}
	deleteFarmerBySupervisor(id,idSupervisor?) {
		if (idSupervisor == null )
		idSupervisor = ""
		return this.http.delete(this.configService.getApiPath() + `/customers/deleteFarmerBySupervisor?id=${id}&idSupervisor=${idSupervisor}`);
	}
	getRolesFarmer(){
		return (this.search(`rolesFarmer`) as Observable<any>) as Observable<Role[]>
	}
	deleteCustomer() {
		return this.http.post(this.configService.getApiPath() + `/customers/deleteAccount`,null);
	}
}

