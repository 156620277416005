import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Customer } from 'projects/shared/resources/Customer';
import { AppService } from 'projects/shared/services/app.service';
import { FormService } from 'projects/shared/services/form.service';
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { CustomRouter } from '../services/custom-router';
import state from "../../../../shared/tools/state"
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { SectionForm } from 'projects/shared/tools/constants';

@Component({
  selector: 'app-supervisor-farmer-settings',
  templateUrl: './supervisor-farmer-settings.component.html',
  styleUrls: ['./supervisor-farmer-settings.component.scss']
})
export class SupervisorFarmerSettingsComponent implements OnInit {

  forms: any={}
  projection = "form-projection"
  idFarmer
  idFormTemplate
  service
  name
  idSupervisor: any;
  isSupervisor: Boolean = false;
  dataAdd;
  section=SectionForm.advisor_farmer_settings
color='color-alert'
  constructor(public formTemplateService: FormTemplateService, public formService: FormService, public appService: AppService, public route: ActivatedRoute,
    private router: CustomRouter, private supervisorService: SupervisorService) {
    const { idFarmer, idFormTemplate } = this.route.snapshot.params
    this.idFarmer = idFarmer
    this.idFormTemplate = idFormTemplate;
    this.service = this.formService
    if (idFarmer)
      this.supervisorService.getFarmer(idFarmer).subscribe(res => {
        const stateFarmer = state.farmers[idFarmer] as Customer;
        if (stateFarmer) {
          this.name = stateFarmer.fullname;
        }
      })
    let currentRoute = this.router.url;
    if (currentRoute.indexOf('key') == -1)
      this.getData()
  }

  ngOnInit(): void {
    this.idSupervisor = this.route.snapshot.paramMap.get('idSupervisor');
    this.appService.connectedCustomer['roles'].forEach(element => {
      if (element.isSupervisor == true)
        this.isSupervisor = true;
    })

  }
  findFomTemplate() {
    this.formService.findByFarmerAndFormTemplate(this.idFarmer, this.idFormTemplate, this.projection).subscribe(res => {
      if (res)
      this.forms=this.appService.groupDataByYearAndMonthWithoutTiming(this.appService.organizedData(res, res[0]?.formTemplate));
        //this.forms = this.appService.organizedData(res, res[0]?.formTemplate);
    }, error => {
      this.appService.error(error.error.error)
    })
  }

  getData() {
    this.findFomTemplate()
  }

  back() {
    this.router.back('')
  }

  action(item) {
    if (this.isSupervisor)
      this.router.navigateByUrl('farmers/' + this.idFarmer + '/settings/' + this.idFormTemplate + '/save' + (item ? ("/" + (item.id ? item.id : item.uuid)) : ""))
    else
      this.router.navigateByUrl('supervisors/' + this.idSupervisor + '/farmers/' + this.idFarmer + '/settings/' + this.idFormTemplate + '/save' + (item ? ("/" + (item.id ? item.id : item.uuid)) : ""))
  }

  searchSettings(element) {
    this.formService.findFormByKeySearch(this.idFormTemplate, this.idFarmer, element.key).subscribe(res => {
      this.forms=this.appService.groupDataByYearAndMonthWithoutTiming(this.appService.organizedData(res['resources'], res['resources'][0].formTemplate));
      //this.forms = this.appService.organizedData(res['resources'], res['resources'][0].formTemplate)
    }, error => {
      this.appService.error(error.error.error)
    });
  }

}
