import { CustomerAnimalGroup } from "../resources/CustomerAnimalGroup";
import { Injectable, Injector } from '@angular/core';
import { MoomeRestService } from './moome-rest.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/admin/src/environments/environment';
import { ConfigService } from "./config.service";


@Injectable({
    providedIn: 'root'
})
export class CustomerAnimalGroupsService extends MoomeRestService<CustomerAnimalGroup> {

    constructor(public http: HttpClient, injector: Injector, private configService: ConfigService) {
        super(CustomerAnimalGroup);
    }

    detachAnimal(animalId, groupId) {
        return this.http.delete(this.configService.getApiPath() + `/customerAnimalGroups/${groupId}/animal/${animalId}`);
    }

    saveAnimalGroup(body) {
        return this.http.post(this.configService.getApiPath() + `/customerAnimalGroups`, body);
    }

    countByDateAndGroup(startDate,endDate,listCodeGroups) {
        return this.http.get(this.configService.getApiPath() + `/customerAnimalGroups/countByDateAndGroup?listCodeGroups=${listCodeGroups}&startDate=${startDate}&endDate=${endDate}`);
    }
    countByGroup() {
        return this.http.get(this.configService.getApiPath() + `/customerAnimalGroups/countByGroup`);
    }
   

}
