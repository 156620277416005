import { Injectable } from '@angular/core';
import { FormTemplate } from '../resources/FormTemplate';
import { MoomeRestService, Options } from './moome-rest.service';
import state from '../../shared/tools/state'
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { flatMap, map, of, take } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class FormTemplateService extends MoomeRestService<FormTemplate> {
	
	constructor(public http: HttpClient, private configService: ConfigService) {
		super(FormTemplate);
	}

	findByType(type: string) {
		return this.searchSingle(`findByType?type=${type}&projection=form-template`)
	}

	saveFormTemplate(body) {
		return this.http.post(this.configService.getApiPath() + `/formTemplates/save`, body);
	}

	saveFormTemplateXml(body: any) {
		return this.http.post(this.configService.getApiPath() + `/formTemplates/saveXml`, body);
	}

	getByConnected(projection, page, size) {
		let options: Options =
		{
			params: {
				projection: projection,

			},
			pageParams: {
				page: page,
				size: size
			}
		}
		return this.customRequestGet(`/findByConnected`, null, options)
	}

	getForms(projection,section?,relatedTo?) {
		if (!relatedTo)
		relatedTo = ""
		if (!section)
		section = ""
		let options: Options =
		{
			params: {
				section: section,
				projection: projection,
				relatedTo: relatedTo,
			}
		}
		var forms = (state.forms || {} ) [section]
		if (forms){
		return of(forms)}
		return this.customRequestGet(`/find`, null, options)
			.pipe(
				take(1),
				map(result => {
					var forms = (state.forms || {} )
					forms[section] = result['_embedded']?.formTemplates
					state.forms = forms
					return result['_embedded']?.formTemplates;
				}))
	}

	getForm(formTemplateId, projection, section?,relatedTo?) {
		
		let forms: FormTemplate[] = state.forms[section] || [];
		let selectedForm = forms.find(f => f.id == formTemplateId)
		if (selectedForm) return of(selectedForm)
		return this.getForms(projection,section,relatedTo).pipe(flatMap(result => this.getForm(formTemplateId, projection,section,relatedTo)))
	}

	findByLabelOfFormTemplate(label) {
		return this.http.get(this.configService.getApiPath() + `/formTemplates/${label}/findByLabelOfFormTemplate`, label);
	}

	findFormTemplateIdOfMilkQuantity() {	
		return this.searchSingle(`findFormTemplateIdOfMilkQuantity`)
	  }
}
