import { Injectable } from '@angular/core';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from '../../../../../node_modules/rxjs';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { CustomRouter } from '../services/custom-router';

export const AUTHORIZED_REQUESTS = [
    "/oauth",
    "/assets",
    "/countries",
    "/appVersions",
    "/farmers/send-sms",
    "/farmers/save-app","/farmers/resend-sms", "/version-app/check", 
			"/farmers/check-code",
			 "/regions", "farmers/forget-password"
]

const IS_AUTHORIZED = (request) => {

    let authorized = false;
    AUTHORIZED_REQUESTS.forEach(u => {
        if (typeof (u) == "string" && request.url.indexOf(u) >= 0) {
            authorized = true;
            return true;
        }
    })
    return authorized;
}

@Injectable()
export class PublicHttpInterceptor implements HttpInterceptor {
    constructor(public tokenManagementService: TokenManagementService, public router: CustomRouter) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        let header = {
            'root-url': location.origin
        }


        let isAuthorized = IS_AUTHORIZED(request);



        if (isAuthorized) {
            request = request.clone({

                setHeaders: header

            });
            return next.handle(request);
        }

        return this.tokenManagementService.verify().pipe(mergeMap(ok => {
            
            if (!ok) {
                
                this.router.navigateByUrl('/login')
                return next.handle(request);
            }

            header["Authorization"] = `Bearer ${this.tokenManagementService.getToken()}`
            request = request.clone({

                setHeaders: header

            });
            return next.handle(request)
        }))



    }
}
