import { GetOption, HateoasResourceOperation, HttpMethod, PagedGetOption, PagedResourceCollection, RequestOption, Resource, ResourceCollection } from "@lagoshny/ngx-hateoas-client";
import { RequestBody } from "@lagoshny/ngx-hateoas-client/lib/model/declarations";
import { Observable } from "rxjs";
import { Helper } from "../tools/helper";

export class MoomeRestService<T extends Resource> extends HateoasResourceOperation<T> {

    save(object: T) {
        if (object['_links'] && object['_links'].self && object['_links'].self.href) {
            object['_links'] = { self: { href: Helper.cleanLink(object['_links'].self.href) } }
            
            return this.patchResource(object, { body: object });
        }
        return this.createResource({ body: object });
        
    }

    getAll(options?: Options): Observable<ResourceCollection<T>> {
        return this.getCollection({...cacheOptions, ...options} as GetOption);
    }

    delete(entity: T, options?: RequestOption): Observable<T> {
        return this.deleteResource(entity, {...cacheOptions, ...options})
    }

    get(id: string | number, options?: Options): Observable<any> {
        return this.getResource(id, {...cacheOptions, ...options} as GetOption)
    }

    search(query: string, options?: Options): Observable<ResourceCollection<T>> {
        return this.searchCollection(query, {...cacheOptions, ...options} as GetOption);
    }

    searchSingle(query: string, options?: Options): Observable<T> {
        return this.searchResource(query, {...cacheOptions, ...options} as GetOption)
    }

    searchPages(query: string, options?: Options): Observable<PagedResourceCollection<T>> {
        return this.searchPage(query, {...cacheOptions, ...options} as PagedGetOption)
    }

    getPages(options?: Options): Observable<PagedResourceCollection<T>> {
        return this.getPage({...cacheOptions, ...options} as PagedGetOption)
    }

    customRequestGet(query: string, requestBody?: RequestBody<any>, options?: PagedGetOption): Observable<T> {
        return this.customQuery(HttpMethod.GET, query, requestBody, {...cacheOptions, ...options})
    }

    customRequestPost(query: string, requestBody?: RequestBody<any>, options?: PagedGetOption): Observable<T> {
        return this.customQuery(HttpMethod.POST, query, requestBody, {...cacheOptions, ...options})
    }

}

export type SortOrder = 'DESC' | 'ASC';

export interface Options {
    params?: { [key: string]: string };
    sort?: { [key: string]: SortOrder };
    pageParams?: {
        page?: number,
        size?: number
    };
}

const cacheOptions = {
    useCache: false
}