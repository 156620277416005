import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Form } from 'projects/shared/resources/Form';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { FormService } from 'projects/shared/services/form.service';
import * as uuid from 'uuid';
import * as moment from 'moment';
import { CustomRouter } from '../../services/custom-router';
import state from "../../../../../shared/tools/state"
import { ActivatedRoute } from '@angular/router';
import { Customer } from 'projects/shared/resources/Customer';
import {  SectionForm } from 'projects/shared/tools/constants';
import { FormTemplateService } from 'projects/shared/services/form-template.service';

@Component({
  selector: 'app-edit-supervisor-farmer-settings',
  templateUrl: './edit-supervisor-farmer-settings.component.html',
  styleUrls: ['./edit-supervisor-farmer-settings.component.scss']
})
export class EditSupervisorFarmerSettingsComponent implements OnInit {

  idFarmer
  idFormTemplate
  formTemplate
  id
  result
  name
  valueIdFormTemplate
  section=SectionForm.advisor_farmer_settings
  color='color-alert'
  constructor(public formService: FormService, public appService: AppService,
    public translate: TranslateService, private alertsService: AlertsService,
    private router: CustomRouter, public route: ActivatedRoute, public formTemplateService: FormTemplateService) {

    const { idFarmer, idFormTemplate, id } = this.route.snapshot.params
    this.idFarmer = idFarmer
    this.idFormTemplate = idFormTemplate;
    this.id = id
    this.findFormTemplate()
    if (state.farmers) {
      let stateFarmer: any
      stateFarmer = state.farmers[idFarmer] as Customer;
      this.name = stateFarmer.fullname;
    }
    if (this.id)
      this.displayInfo(this.id);
  }

  ngOnInit(): void {

  }

  findFormTemplate() {
    this.formTemplateService.getForm(this.idFormTemplate, "form-template",this.section).subscribe(template => {
      this.formTemplate = template
    })
  }
  displayInfo(id) {
    if (id.length > 8)
      this.result = state.formDataToSave[id];
    else
      this.formService.getFormById(id, this.idFormTemplate, this.idFarmer, "form-projection").subscribe(res => {
        this.result = res;
      })
    return this.result
  }
  /* findIdForm() {
     this.formTemplateService.findByLabelOfFormTemplate(SettingsFarmerByLabel.MILK_QUANTITY).subscribe(res1 => {
       this.valueIdFormTemplate = res1;
       return this.valueIdFormTemplate
     })
   }*/
  confirm(event) {
    // this.findIdForm()
    let form = new Form()
    if (event.id) {
      if (event.id.length > 8)
        form.uuid = event.id
      else
        form.id = event.id
    }
    else form.uuid = uuid.v4();

    const hasNonNullValue = Object.keys(event.formGroup.value)
    .filter(key => key !== 'DATE_QUALITY')  
    .some(key => {
        const value = event.formGroup.value[key];
        return value !== null && !isNaN(value);
    });
    if ((!event.formGroup.valid)&&(!hasNonNullValue) || (((this.valueIdFormTemplate)?.toString() == this.idFormTemplate) && (!event.formGroup.value?.QUANTITY))) {
      this.appService.error('FIELD_REQUIRED')
    }
    else {
      form.date = moment()
      form.value = event.formGroup.value
      form.formTemplate = this.formTemplate//{ id: this.idFormTemplate }
      form.farmerForms = [{ farmer: { id: this.idFarmer } }]
      this.appService.showLoading()
      this.formService.saveForm(form).subscribe(res => {
        this.appService.hideLoading();
        let message = this.translate.instant('SAVE_SUCESS');
        this.alertsService.open(message);
        this.back()
      }
      )
    }
  }

  back() {
    this.router.back('')
    //this.router.back('farmers/' + this.idFarmer + '/settings')
  }

}
