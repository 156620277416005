import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'projects/shared/services/alert.service';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AnimalService } from 'projects/shared/services/animal.service';
import { AppService } from 'projects/shared/services/app.service';
import { GroupService } from 'projects/shared/services/group.service';
import { Alert, Authorities, GroupCode, SectionForm } from 'projects/shared/tools/constants';
import { CommonDialogComponent } from '../../common-dialog/common-dialog.component';
import { CustomRouter } from '../../services/custom-router';
import { ProjectService } from 'projects/shared/services/project.service';
import { FormTemplateService } from 'projects/shared/services/form-template.service';

export enum SettingsText {
  NOTES = "NOTES",
  PEDIGREE = "PEDIGREE",
  LABEL = "LABEL",
  EDIT = "EDIT",
  REMOVE = "REMOVE",
  DETACH = "DETACH",
  MILK_QUANTITY = "MILK_QUANTITY"
}

@Component({
  selector: 'app-settings-animal',
  templateUrl: './settings-animal.component.html',
  styleUrls: ['./settings-animal.component.scss']
})

export class SettingsAnimalComponent implements OnInit {

  id: any
  defaultGroupId: any
  uid: any
  code: any
  colorGroup: any
  animal: any
  role: Boolean = false;
  idFarmer: any;
  SettingsText = SettingsText
  authorityNotes = Authorities.ANIMAL_NOTES
  authorityPedigree = Authorities.PEDIGREE
  authorityAnimals = Authorities.ANIMALS
  authorityLabel = Authorities.PROJECT
  currentRoute: any;
  supervisorId: any;
  projects: any;
  selectedProjects: any
  /*{ text: SettingsText.DETACH,/* htmlToAdd : "" }*/
  list = []
  auth: string[] = [];
  showLabel: Boolean = false;
  idFormTemplate
  constructor(private router: CustomRouter, public appService: AppService,
    public dialog: MatDialog, public route: ActivatedRoute, private translate: TranslateService,
    public animalService: AnimalService, public groupService: GroupService,
    private alertsService: AlertsService, public projectService: ProjectService, private formTemplateService: FormTemplateService) {


  }
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getAnimalDetails()

    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
      // if (this.auth.length == 1){
      // }
    })
    this.currentRoute = this.router.url
    this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
    this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');

    if (this.auth.indexOf("true") != -1) {
      this.role = true;
    }

    this.defaultGroupId = this.appService.defaultGroupId
    this.formTemplateService.getForms("form-template", SectionForm.animal_milk_production).subscribe(res => {
      this.idFormTemplate = res[0].id})
    this.appService.getFormTemplateAnimalMilkProduction().subscribe(res => { })

  }

  getAnimalDetails() {
    this.animalService.getById(this.id).subscribe(res => {
      this.animal = res;
      this.loadProjects(res.sexe, res.customerAnimalGroups[0].code);
    })
  }

  pathUrl(value) {
    if (!this.role) {
      if (this.currentRoute.startsWith('/supervisors')) {
        switch (value) {
          case SettingsText.NOTES:
            return this.router.navigateByUrl('supervisors/' + this.supervisorId + '/farmers/' + this.idFarmer + '/animals/' + this.id + '/settings/notes')
          case SettingsText.MILK_QUANTITY:
            return this.router.navigateByUrl('supervisors/' + this.supervisorId + '/farmers/' + this.idFarmer + '/animals/' + this.id + '/settings/' + this.idFormTemplate + '/' + Authorities.ANIMAL_MILK_PRODUCTION)
          case SettingsText.PEDIGREE:
            return this.router.navigateByUrl('supervisors/' + this.supervisorId + '/farmers/' + this.idFarmer + '/animals/' + this.id + '/settings/pedigree')
          case SettingsText.LABEL:
            return this.router.navigateByUrl('supervisors/' + this.supervisorId + '/farmers/' + this.idFarmer + '/animals/' + this.id + '/settings/label')
          case SettingsText.EDIT:
            return this.router.navigateByUrl('supervisors/' + this.supervisorId + '/farmers/' + this.idFarmer + '/animals/save/' + this.id)
          case SettingsText.REMOVE:
            return this.deleteAnimal()


        }
        //  this.router.navigateByUrl('/supervisors/'+this.supervisorId+'/farmers');
      }
      else {
        switch (value) {
          case SettingsText.NOTES:
            return this.router.navigateByUrl('/animals/' + this.id + '/settings/notes')
          case SettingsText.MILK_QUANTITY:
            return this.router.navigateByUrl('/animals/' + this.id + '/settings/' + this.idFormTemplate + '/' + Authorities.ANIMAL_MILK_PRODUCTION)
          case SettingsText.PEDIGREE:
            return this.router.navigateByUrl('/animals/' + this.id + '/settings/pedigree')
          case SettingsText.LABEL:
            return this.router.navigateByUrl('/animals/' + this.id + '/settings/label')
          case SettingsText.EDIT:
            return this.router.navigateByUrl('/animals/save/' + this.id)
          case SettingsText.REMOVE:
            return this.deleteAnimal()

          /*   case SettingsText.DETACH:
               return this.detachAnimal()*/
        }
      }
    }
    else {
      switch (value) {
        case SettingsText.NOTES:
          return this.router.navigateByUrl('farmers/' + this.idFarmer + '/animals/' + this.id + '/settings/notes')
        case SettingsText.MILK_QUANTITY:
          return this.router.navigateByUrl('/farmers/' + this.idFarmer + '/animals/' + this.id + '/settings/' + this.idFormTemplate + '/' + Authorities.ANIMAL_MILK_PRODUCTION)
        case SettingsText.PEDIGREE:
          return this.router.navigateByUrl('farmers/' + this.idFarmer + '/animals/' + this.id + '/settings/pedigree')
        case SettingsText.LABEL:
          return this.router.navigateByUrl('farmers/' + this.idFarmer + '/animals/' + this.id + '/settings/label')
        case SettingsText.EDIT:
          return this.router.navigateByUrl('farmers/' + this.idFarmer + '/animals/save/' + this.id)
        case SettingsText.REMOVE:
          return this.deleteAnimal()


      }
    }
  }

  back() {
    this.appService.verif = false
    if ((this.currentRoute.indexOf('resultSearch') != -1) /*|| (this.router.history[this.router.history.length - 1])*/)
      this.router.back('')
    else if (this.role) {
      this.appService.getDefaultGroup().subscribe(group => {
        this.defaultGroupId = group
        this.router.navigateByUrl("farmers/" + this.idFarmer + "/animals/groups/" + this.defaultGroupId)
      })

    }

    else {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + this.idFarmer + "/animals/groups/" + this.defaultGroupId)
      }
      else {
        this.router.navigateByUrl("animals/groups/" + this.defaultGroupId)
      }
    }
  }

  backAfterDelete() {
    if (this.role) {
      this.appService.getDefaultGroup().subscribe(group => {
        this.defaultGroupId = group
        this.router.navigateByUrl("farmers/" + this.idFarmer + "/animals/groups/" + this.defaultGroupId)
      })
    }

    else {
      if (this.currentRoute.startsWith('/supervisors')) {
        this.router.navigateByUrl("supervisors/" + this.supervisorId + "/farmers/" + this.idFarmer + "/animals/groups/" + this.defaultGroupId)
      }
      else {
        this.router.navigateByUrl("animals/groups/" + this.defaultGroupId)
      }
    }
  }

  deleteAnimal() {

    const dialogRef = this.dialog.open(CommonDialogComponent, {
      data: {
        message: this.translate.instant('CONFIRM_DELETE_ANIMAL'),
        title: this.translate.instant('DELETE_ANIMAL'),
        onConfirm: () => {
          this.animalService.delete(this.animal).subscribe(data => {
            this.backAfterDelete()
          }
            , error => {
              this.appService.hideLoading();
              let message = this.translate.instant(error.error.code);
              let alert = this.translate.instant(Alert.OK)
              this.alertsService.open(message, alert);
            });
        }
      }
    });
  }
  loadProjects(value, groupCode) {
    this.projectService.getProjectOfConnected().subscribe(res => {
      this.selectedProjects = this.projects = res
      if (res)
        this.showLabel = true;
      // arr.splice(2, 0, "Lene");
      this.list = [
        { text: "note", authorityType: "getAuthorized", type: SettingsText.NOTES, labelAr: "الملاحظات", labelFr: "Notes", labelEn: "Notes",labelInd: "Catatan", authority: Authorities.ANIMAL_NOTES },
        { text: "pedigree", authorityType: "getAuthorized", type: SettingsText.PEDIGREE, labelAr: "النسب", labelFr: "Pedigrée", labelEn: "Pedigree",labelInd: "Silsilah", authority: Authorities.PEDIGREE },
        { text: "edit", authorityType: "editAuthorized", type: SettingsText.EDIT, labelAr: "تعديل", labelFr: "Editer", labelEn: "Edit",labelInd: "Edit", authority: Authorities.ANIMALS },
        { text: "remove", authorityType: "deleteAuthorized", type: SettingsText.REMOVE, labelAr: "حذف", labelFr: "Supprimer", labelEn: "Delete",labelInd: "Menghapus", authority: Authorities.ANIMALS }
      ]
      if (this.showLabel)
        this.list.splice(2, 0, { text: "label", authorityType: "getAuthorized", type: SettingsText.LABEL, labelAr: "وسم", labelFr: "Label", labelEn: "Label",labelInd: "Label", authority: Authorities.PROJECT },)
      if (((value == 'FEMALE') && ((groupCode == GroupCode.PINS_CODE) || (groupCode == GroupCode.PNOTINS_CODE)|| (groupCode == GroupCode.P_Pregnant_CODE)|| (groupCode == GroupCode.P_Negative_Pregnancy_CODE) )))
        this.list.splice(1, 0,{ text: "milk_quantity", type: SettingsText.MILK_QUANTITY, labelAr: " مراقبة إنتاج الحليب", labelFr: "Contrôle laitier", labelEn: "Milk Production Control",labelInd: "Kontrol Produksi Susu", authority: Authorities.ANIMAL_MILK_PRODUCTION },)


    })
  }
}
