<div [ngClass]="{rtl:appService.rtl()}" class="message-style  text-center"
    [ngStyle]="{'color': data.action? 'red' : '#80cc28'}">{{data.message}}
</div>

<div [ngStyle]="{'display' : (data.action ? 'block' : 'none')}">
    <div class="row">
        <div class="text-center col mt-3 center-element">
            <button mat-raised-button (click)="close()" class="action-style center-element button-dialog" [ngClass]="{rtl:appService.rtl()}">
                {{data.action}}</button>
        </div>
    </div>
</div>