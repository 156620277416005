import { CustomerAlert } from "../resources/CustomerAlert";
import { Injectable, Injector } from '@angular/core';
import { MoomeRestService, Options } from './moome-rest.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from "./config.service";
import { map } from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class CustomerAlertService extends MoomeRestService<CustomerAlert> {

    constructor(public http: HttpClient, injector: Injector, private configService: ConfigService) {
        super(CustomerAlert);
    }

    updateRead(body,supervisorId?) {
        if (!supervisorId)
        supervisorId=""
        return this.http.patch(this.configService.getApiPath() + `/customerAlerts/updateRead?supervisorId=${supervisorId}`, body);
    }

    findByType(code, page, size, projection, supervisorId, farmerId, mccId) {
        
        if (supervisorId == null || !supervisorId)
        supervisorId = ""
        if (farmerId == null || !farmerId)
        farmerId = ""
        if (mccId == null || !mccId)
        mccId = ""
        let param: any=
        {projection: projection,
        code:code
       }
        let options: Options = {
            params: 
               param
             ,
             pageParams:{
                page: page,
                size:size,
             }
        }
        return this.customRequestGet(`findByType?supervisorId=${supervisorId}&farmerId=${farmerId}&mccId=${mccId}`,null,options)
    }

    getSearchAlerts(uid, page, size, projection){
        let param: any=
        {projection: projection,
        uid:uid
       }
        let options: Options = {
            params: 
               param
             ,
             pageParams:{
                page: page,
                size:size,
             }
        }
        return this.customRequestGet(`/getSearchAlerts`,null,options)
    }

    getUnreadAlertsOfConnected() {
        return this.http.get(this.configService.getApiPath() + `/customerAlerts/getUnreadAlertsOfConnected?projection=customer_alert`)
    }

    getSearchAlertsType(uid,code, page, size, projection){
        
        let param: any=
        {projection: projection,
        uid:uid,
        code:code
       }
        let options: Options = {
            params: 
               param
             ,
             pageParams:{
                page: page,
                size:size,
             }
        }
        return this.customRequestGet(`/getSearchAlertsType`,null,options)
    }

    getAlerts(page, size, field, direction, key, projection) {
        let param: any=
        {projection: projection,
       }
       if(key!=null)
        param.key=key.toLowerCase() 
        if(field!=null)
        param.field=field
        if(direction!=null)
        param.direction=direction.toUpperCase()

        let options: Options = {
            params: 
               param
             ,
             pageParams:{
                page: page,
                size:size,
             }
        }

        return this.customRequestGet(`/getAlerts`,null,options)
    }

   /* getUnreadLocalNotifAlerts(animalId) {
        return this.customRequestGet(`/getUnreadLocalNotifAlerts/${animalId}?projection=local_notification`);
    }
    */
    /*updatecustomerAlertStatus(ids){
        return this.http.put(this.configService.getApiPath() +'/customerAlerts/updateLocalNotifStatus', ids)
    }*/

    sendNotif(animalId){
        const httpOptions = { headers: new HttpHeaders({
            "content-type": "application/json"		  }),
		};
        return this.http.put(this.configService.getApiPath() +'/customerAlerts/updateNotifStatus', animalId, httpOptions)
    }
    getAlertsOfWeekForFarmer(startDate,endDate){
        return this.http.get(this.configService.getApiPath() + `/customerAlerts/getAlertsOfWeekForFarmer/${startDate}/${endDate}`).pipe(map(element => {return element['_embedded']?.customerAlerts}))
    }
    getAlertsOfWeekForSupervisor(startDate,endDate){
        return this.http.get(this.configService.getApiPath() + `/customerAlerts/getAlertsOfWeekForSupervisor/${startDate}/${endDate}`)
    }

}