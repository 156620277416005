import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimalsEmptyComponent } from './animals-empty.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../material/material.module';
import { RouterModule } from '@angular/router';
import { SectionBarModule } from '../section-bar/section-bar.module';



@NgModule({
  declarations: [AnimalsEmptyComponent],
  imports: [
    CommonModule, 
    TranslateModule,
    MaterialModule,
    RouterModule,
    SectionBarModule,
  ],
  exports: [AnimalsEmptyComponent]
})
export class AnimalsEmptyModule { }
