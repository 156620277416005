import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { Options } from 'projects/shared/services/moome-rest.service';
import { OrderDetailService } from 'projects/shared/services/order-detail.service';
import { OrderService } from 'projects/shared/services/order.service';
import { ShopFormTemplateService } from 'projects/shared/services/shop-form-template';
import { MAX_COUNT_ORDER, NUMERIC_PATTERN_RECALL, TYPE } from 'projects/shared/tools/constants';
import { CustomRouter } from '../services/custom-router';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {
  listShop = []
  formGroup: UntypedFormGroup;
  numberOrder: any
  numericPattern = NUMERIC_PATTERN_RECALL

  constructor(public orderService: OrderService,
    public orderDetailService: OrderDetailService,
    public alertsService: AlertsService, private formBuilder: UntypedFormBuilder,
    public appService: AppService, public translate: TranslateService,
    public codeTranslate: CodeTranslatePipe,
    public router: CustomRouter,
    public sanitizer: DomSanitizer,
    private shopFormTemplateService: ShopFormTemplateService
  ) {
    this.checkCount()
  }

  checkCount() {
    this.orderService.getCountOrderPerDay().subscribe(res => {
      this.numberOrder = res
      if (this.numberOrder >= MAX_COUNT_ORDER)
        this.formGroup.setErrors({ 'invalid': true });
    }
      , error => {
        this.appService.error(error.error.code);
      });
  }

  ngOnInit(): void {
    this.loadShopFormTmplate();
  }

  loadShopFormTmplate() {
     this.listShop=this.appService.listShop
    this.createForm();
    /*let options: Options = {
      pageParams: {
        page: 0,
        size: 100
      }
    }
    this.appService.showLoading();
    this.shopFormTemplateService.getPages(options).subscribe(res => {
      this.listShop = res.resources;
      this.createForm();
      this.appService.hideLoading();
    },
      error => {
        this.appService.error(error.error.code);
      });*/
  }

  createForm() {
    let group = this.listShop
      .map(element => ({ control: new UntypedFormControl(null), code: element.code }))
    var res = {}
    group.forEach(e => {
      res[e.code] = e.control
    })
    this.formGroup = this.formBuilder.group(res)
  }


  back() {
    this.router.back('')
  }

  confirm() {
    if (/*(!this.formGroup.controls['COLLAR'].value) &&*/ (!this.formGroup.controls['THI_SENSOR'].value)) {
      this.appService.error('INPUT_REQUIRED');
      this.formGroup.setErrors({ 'invalid': true });
    }
    else {
      let map: { [name: string]: number } = {};
      /*if (this.formGroup.controls['COLLAR'].value != null)
        map[TYPE.COLLAR] = this.formGroup.controls['COLLAR'].value;
      else*/
        map[TYPE.COLLAR] = 0;
      if (this.formGroup.controls['THI_SENSOR'].value != null)
        map[TYPE.THI_SENSOR] = this.formGroup.controls['THI_SENSOR'].value;
      else
        map[TYPE.THI_SENSOR] = 0;

      this.orderService.saveOrder(map).subscribe(res1 => {
        this.appService.hideLoading();
        let message = this.translate.instant('CONTACT_ASAP');
        this.alertsService.open(message, null, 10000);
       this.formGroup.controls['COLLAR']?.setValue(null);
        this.formGroup.controls['THI_SENSOR'].setValue(null);
      }, error => {
        this.appService.error(error.error.code);
       this.formGroup.controls['COLLAR']?.setValue(null);
        this.formGroup.controls['THI_SENSOR'].setValue(null);
      })
    }
  }
}
