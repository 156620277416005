import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FarmerCardComponent } from './farmer-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';



@NgModule({
  declarations: [FarmerCardComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MoomeCommonModule
  ], exports: [FarmerCardComponent]
})
export class FarmerCardModule { }
