import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThiCardComponent } from './thi-card/thi-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { ThiCardModule } from './thi-card/thi-card.module';
import { ThiComponent } from './thi.component';
import { NoAnimalsModule } from '../animals/no-animals/no-animals.module';



@NgModule({
  declarations: [
    ThiComponent
  ],
  imports: [
    CommonModule,
    CommonModule,
    ThiCardModule,
    MoomeCommonModule,
    NgxHideOnScrollModule,
    TranslateModule,
    NoAnimalsModule,
    
  ], exports: [
    ThiComponent
  ]
})
export class ThiModule { }



