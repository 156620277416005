<div class="style-bar w-100" ngxHideOnScroll [scrollingElementSelector]="'body'" [propertyUsedToHide]="'top'"
    [valueWhenHidden]="'-10px'" [valueWhenShown]="'93px'">
    <div class="row  h-100">
        <div class="col-12">
            <div class="row background-troups">
                <div class="col-4 center-element" (click)="back()">
                    <img src="/assets/images/left-arrow-1.svg" />
                </div>
                <div class="col-4 center-element">
                    <div class="row d-block">
                        <div class="col text-center"> <i class="material-icons"
                                style="align-self: center;font-size:40px;color: white">
                                devices_other
                            </i>
                        </div>
                        <div class="col text-center ps-0 pe-0 pt-1"> <span class="text-style align"
                                [ngClass]="{rtl:appService.rtl()}">{{ 'SENSORS' | translate }}</span></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div>

        <form [formGroup]="formGroup" *ngIf="listShop.length>0">
            <div class="row m-3" *ngFor="let element of listShop">
                <div class="card-size mx-auto" [ngClass]="{rtl:appService.rtl()}">
                    <div class="col-12">
                        <div class="row center-element">
                            <div class="col-6">
                                <div class="text-title mb-2"> {{element| codeTranslate: 'title'}}</div>
                                <div class="text-description"> {{element| codeTranslate: 'description'}}</div>
                            </div>
                            <div class="col-6 ">
                                <div class="m-2">
                                    <div class="mb-2 center-element"><img
                                            [src]="sanitizer.bypassSecurityTrustResourceUrl(element.image)"></div>
                                    <div class="center-element">
                                        <input class="input-mob text-center" type="number"(keypress)="appService.numericOnly($event,numericPattern)"
                                            formControlName="{{element.code}}" placeholder="{{ 'QUANTITY' | translate}}"
                                            [ngClass]="{rtl:appService.rtl()}">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col mt-3 center-element">
                <button mat-raised-button color="primary" class="button-mob-note" (click)="confirm()"
                    [ngClass]=" !formGroup.valid  ?  'opac' : '' "><span [ngClass]="{rtl:appService.rtl()}">{{ 'CONFIRM' |
                        translate }}</span></button>
    
            </div>
        </form>

      

    </div>




</div>