import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cercle-card',
  templateUrl: './cercle-card.component.html',
  styleUrls: ['./cercle-card.component.scss']
})
export class CercleCardComponent implements OnInit {
  @Input() element:any 

  constructor() { }

  ngOnInit(): void {
  }

}
