import { Component, OnInit } from '@angular/core';
import { CustomRouter } from '../services/custom-router';
import { AppService } from 'projects/shared/services/app.service';
import { GroupService } from 'projects/shared/services/group.service';
import { Group, GroupCode } from 'projects/shared/tools/constants';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { FarmerFeedConsumptionService } from 'projects/shared/services/farmer-feed-consumption.service';

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss']
})
export class FeedComponent implements OnInit {
  bar: any;
  list: any[] = [];
  selectedGroups: any[];
  listGroups
  labelAr: boolean = false
  labelEn: boolean = false
  labelFr: boolean = false
  labelInd: boolean = false
  formatArabe: any;
  formGroup: UntypedFormGroup;
  startDate
  endDate
  totalQuantityFeedConsumption
  totalQuantityDryMatter
  totalQuantityCrudProtein
  totalQuantityFeedConsumptionPerDay
  totalQuantityDryMatterPerDay
  totalQuantityCrudProteinPerDay
  totalQuantityFeedConsumptionPerDayPerCow
  totalQuantityDryMatterPerDayPerCow
  totalQuantityCrudProteinPerDayPerCow
  formGroup1: UntypedFormGroup;
  startDateFinancial
  endDateFinancial
  listFeedAndQuantity=[]
  constructor(private farmerFeedConsumptionService: FarmerFeedConsumptionService, private formBuilder: UntypedFormBuilder, public tokenManagementService: TokenManagementService, public router: CustomRouter, public appService: AppService, public groupService: GroupService) {
  }

  ngOnInit() {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.labelAr = true
    else if (this.tokenManagementService.getTranslateLanguage() == "fr")
      this.labelFr = true
      else if (this.tokenManagementService.getTranslateLanguage() == "ind")
      this.labelInd = true
    else
      this.labelEn = true
    this.getGroups()
    this.formatArabe = this.appService.verifFormatDate()
    this.createForm()
    this.list = [
      { value1: "TOTAL_FEED_CONSUMPTION", value2: "", value3: "KG", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
      { value1: "TOTAL_DRY_MATTER", value2: "", value3: "KG", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
      { value1: "TOTLAL_CRUD_PROTEIN", value2: "", value3: "KG", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
      { value1: "TOTAL_FEED_CONSUMPTION_DAY", value2: "", value3: "KG_DAY", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
      { value1: "TOTAL_DRY_MATTER_DAY", value2: "", value3: "KG_DAY", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
      { value1: "TOTLAL_CRUD_PROTEIN_DAY", value2: "", value3: "KG_DAY", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
      { value1: "TOTAL_FEED_CONSUMPTION_DAY_COW", value2: "", value3: "KG_DAY_COW", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
      { value1: "TOTAL_DRY_MATTER_DAY_COW", value2: "", value3: "KG_DAY_COW", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
      { value1: "TOTLAL_CRUD_PROTEIN_DAY_COW", value2: "", value3: "KG_DAY_COW", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
    ]
    this.bar = { title: "FEED_FODDER", arrowIcon: "/assets/images/left-arrow.svg", color: "#f6f7fb", fontColor: "#F7AC08", router: "/dashboard" }

  }
  back() {
    this.router.navigateByUrl('/dashboard')
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      'group': [null, [Validators.required]],
      'startDate': [null, [Validators.required]],
      'endDate': [null, [Validators.required]]
    });
    this.formGroup1 = this.formBuilder.group({
      'startDateFinancial': [null, [Validators.required]],
      'endDateFinancial': [null, [Validators.required]],
      
    });
  }

  getGroups() {
    this.groupService.findAvailableGroupOfCustomer().subscribe((res: any) => {
      const groupCodesSet = new Set<string>();
      this.selectedGroups = res['_embedded']?.groups?.filter(group => {
        const isFeedGroup = Group.isFeedGroup(group.code);
        // Vérifier si le groupe est un groupe d'alimentation et s'il n'est pas déjà présent dans l'ensemble des codes de groupe
        if (isFeedGroup && !groupCodesSet.has(group.code)) {
          groupCodesSet.add(group.code); // Ajouter le code du groupe à l'ensemble
          return true; // Garder le groupe dans la liste filtrée
        }
        return false; // Ignorer le groupe s'il n'est pas un groupe d'alimentation ou s'il est déjà présent
      });
    });
  }
  fixArabeFormat() {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD/MMM/YYYY"
  }
  countByDateAndGroup() {
    this.listGroups = []
    this.listGroups.push(this.formGroup.controls['group'].value)
    if (this.formGroup.controls['startDate'].value && this.formGroup.controls['endDate'].value) {
      this.startDate = moment(this.formGroup.controls['startDate'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format('yyyy-MM-DD')
      this.endDate = moment(this.formGroup.controls['endDate'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format('yyyy-MM-DD')
      const date1 = (new Date(this.startDate)).getTime();
      const date2 = (new Date(this.endDate)).getTime();
      if (date1 > date2)
        this.appService.error('ERROR_DATE_FIELD');
      else {
        this.farmerFeedConsumptionService.countByDateAndGroup(this.startDate, this.endDate, this.listGroups).subscribe(data => {
          this.totalQuantityFeedConsumption = data["totalQuantityFeedConsumption"]
          this.totalQuantityDryMatter = data["totalQuantityDryMatter"]
          this.totalQuantityCrudProtein = data["totalQuantityCrudProtein"]
          this.totalQuantityFeedConsumptionPerDay = data["totalQuantityFeedConsumptionPerDay"]
          this.totalQuantityDryMatterPerDay = data["totalQuantityDryMatterPerDay"]
          this.totalQuantityCrudProteinPerDay = data["totalQuantityCrudProteinPerDay"]
         
            this.totalQuantityFeedConsumptionPerDayPerCow = data["totalQuantityFeedConsumptionPerDayPerCow"]? data["totalQuantityFeedConsumptionPerDayPerCow"]:'--'
        
            this.totalQuantityDryMatterPerDayPerCow = data["totalQuantityDryMatterPerDayPerCow"] ? data["totalQuantityDryMatterPerDayPerCow"]:'--'
         
            this.totalQuantityCrudProteinPerDayPerCow = data["totalQuantityCrudProteinPerDayPerCow"] ? data["totalQuantityCrudProteinPerDayPerCow"]:'--'
          if (this.totalQuantityFeedConsumption || this.totalQuantityDryMatter || this.totalQuantityCrudProtein ||
            this.totalQuantityFeedConsumptionPerDay || this.totalQuantityDryMatterPerDay || this.totalQuantityCrudProteinPerDay ||
            this.totalQuantityFeedConsumptionPerDayPerCow || this.totalQuantityDryMatterPerDayPerCow || this.totalQuantityCrudProteinPerDayPerCow) {
            this.list = [
              { value1: "TOTAL_FEED_CONSUMPTION", value11: Group.isFeedGroupValue(this.formGroup.controls['group'].value), value2: this.totalQuantityFeedConsumption, value3: "KG", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
              { value1: "TOTAL_DRY_MATTER", value11: Group.isFeedGroupValue(this.formGroup.controls['group'].value), value2: this.totalQuantityDryMatter, value3: "KG", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
              { value1: "TOTLAL_CRUD_PROTEIN", value11: Group.isFeedGroupValue(this.formGroup.controls['group'].value), value2: this.totalQuantityCrudProtein, value3: "KG", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
              { value1: "TOTAL_FEED_CONSUMPTION_DAY", value11: Group.isFeedGroupValue(this.formGroup.controls['group'].value), value2: this.totalQuantityFeedConsumptionPerDay, value3: "KG_DAY", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
              { value1: "TOTAL_DRY_MATTER_DAY", value11: Group.isFeedGroupValue(this.formGroup.controls['group'].value), value2: this.totalQuantityDryMatterPerDay, value3: "KG_DAY", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
              { value1: "TOTLAL_CRUD_PROTEIN_DAY", value11: Group.isFeedGroupValue(this.formGroup.controls['group'].value), value2: this.totalQuantityCrudProteinPerDay, value3: "KG_DAY", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
              { value1: "TOTAL_FEED_CONSUMPTION_DAY_COW", value11: Group.isFeedGroupValue(this.formGroup.controls['group'].value), value2: this.totalQuantityFeedConsumptionPerDayPerCow, value3: "KG_DAY_COW", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
              { value1: "TOTAL_DRY_MATTER_DAY_COW", value11: Group.isFeedGroupValue(this.formGroup.controls['group'].value), value2: this.totalQuantityDryMatterPerDayPerCow, value3: "KG_DAY_COW", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
              { value1: "TOTLAL_CRUD_PROTEIN_DAY_COW", value11: Group.isFeedGroupValue(this.formGroup.controls['group'].value), value2: this.totalQuantityCrudProteinPerDayPerCow, value3: "KG_DAY_COW", class: "yellow-border-box", value1Font: "feed-value1-font", value2Font: "feed-value2-font", value3Font: "feed-value3-font" },
            ]
            this.bar = { title: "FEED_FODDER", arrowIcon: "/assets/images/left-arrow.svg", color: "#f6f7fb", fontColor: "#F7AC08", router: "/dashboard" }
          }
          else
            this.appService.error('HEALTH_REQUIRED');
        })

      }
    }
  }
  calculFeedConsumptionOverview(){
    if (this.formGroup1.controls['startDateFinancial'].value && this.formGroup1.controls['endDateFinancial'].value) {
      this.startDateFinancial = moment(this.formGroup1.controls['startDateFinancial'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format('yyyy-MM-DD')
    this.endDateFinancial = moment(this.formGroup1.controls['endDateFinancial'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format('yyyy-MM-DD')
      const date1=(new Date(this.startDateFinancial)).getTime();
        const date2=(new Date(this.endDateFinancial)).getTime();
        if(date1>date2)
        this.appService.error('ERROR_DATE_FIELD');
      else {
        this.farmerFeedConsumptionService.feedConsumptionOverview(this.startDateFinancial, this.endDateFinancial).subscribe((data:any) => {
         
          this.listFeedAndQuantity=data;
        })
      }

  }}
}
