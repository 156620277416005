import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimalsComponent } from './animals.component';
import { AnimalCardModule } from './animal-card/animal-card.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { SectionBarModule } from './section-bar/section-bar.module';
import { AnimalsEmptyModule } from './animals-empty/animals-empty.module';
import { NoAnimalsModule } from './no-animals/no-animals.module';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { MaterialModule } from '../material/material.module';

@NgModule({
  declarations: [AnimalsComponent,  ],
  imports: [
    CommonModule,
    BrowserModule,
    AnimalCardModule,
    TranslateModule,
    NgxHideOnScrollModule,
    RouterModule,
    SectionBarModule,
    AnimalsEmptyModule,
    NoAnimalsModule,
    MoomeCommonModule,
    MaterialModule,
  ],
  exports: [
    AnimalsComponent
  ]
})
export class AnimalsModule { }
