import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonDialogComponent } from './common-dialog.component';
import { MaterialModule } from '../material/material.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [CommonDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule
  ]
})
export class CommonDialogModule { }
