import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { map } from 'rxjs';
import { Address } from '../resources/Address';
import { ConfigService } from './config.service';
import { MoomeRestService, Options } from './moome-rest.service';

@Injectable({
    providedIn: 'root'
})
export class AddressService extends MoomeRestService<Address> {

    constructor(public http: HttpClient, injector: Injector, private configService: ConfigService) {
        super(Address);
    }

    getAddresses(page, size, id) {
        return this.search(`getAddressesByCustomerId?projection=addresses&page=${page}&size=${size}&id=${id}`)
    }
    getAddressesByCustId(id) {
        return this.search(`getAddressesByCustId?projection=address&id=${id}`)
    }

    getAddressesOfFarmersBySupervisor() {
        //return this.http.get(this.configService.getApiPath() + `/addresses/findOfFarmersBySupervisor?projection=address`)}
        return this.customRequestGet(`/findOfFarmersBySupervisor?projection=address`).pipe(map(element => {return element['_embedded'].addresses}))
    }

    getAddress(id) {
        let options: Options = {
            params: {
                id: id,
                projection: "address" 
            }
        }
        return this.searchSingle(`findAddress`, options)
    }
}
