import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { AppService } from 'projects/shared/services/app.service';
import { TypeAlert } from 'projects/shared/tools/constants';
import { CustomRouter } from '../../services/custom-router';
@Component({
  selector: 'app-alert-card',
  templateUrl: './alert-card.component.html',
  styleUrls: ['./alert-card.component.scss']
})
export class AlertCardComponent implements OnInit {

  @Input() alertTypeGroup: any
  //@Input() collar: any
  //@Input() thi: any
  router
  farmerId: any
  auth: string[] = [];
  currentRoute: any;
  supervisorId: any
  icon : any;
  checkType :any
  isTankAlert:any
  mccId:any;

  constructor(public sanitizer: DomSanitizer, private route: ActivatedRoute, public appService: AppService, router: CustomRouter) {
    this.router = router;
  }

  ngOnInit() {
    this.mccId = this.route.snapshot.paramMap.get('mccId');
    this.supervisorId = this.route.snapshot.paramMap.get('idSupervisor');
    this.currentRoute = this.router.url
    this.icon =this.alertTypeGroup.icon;
    this.checkType = TypeAlert.checkSizeByCode(this.alertTypeGroup.code)
    this.isTankAlert = TypeAlert.checkIfTankAlertByCode(this.alertTypeGroup.code)
  }

  apply() {
    
    const navigationExtras: NavigationExtras = { state: { alertTypeGroup: this.alertTypeGroup } };
    this.farmerId = this.route.snapshot.paramMap.get('idFarmer');
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1) {
      if (this.farmerId != null)
        this.router.navigateByUrl('farmers/' + this.farmerId + '/alerts/' + this.alertTypeGroup.code, navigationExtras)
        else if(this.currentRoute.startsWith('/mcc'))
        this.router.navigateByUrl('/mcc/'  + this.mccId + '/alerts/'+ this.alertTypeGroup.code, navigationExtras)

      else
        this.router.navigateByUrl('/alerts/' + this.alertTypeGroup.code, navigationExtras)
    }
    else {
      if (this.currentRoute.startsWith('/supervisors')){
      if(this.mccId)
      this.router.navigateByUrl('supervisors/' + this.supervisorId + '/mcc/' + this.mccId + '/alerts/' + this.alertTypeGroup.code , navigationExtras)
      else
        this.router.navigateByUrl('supervisors/' + this.supervisorId + '/farmers/' + this.farmerId + '/alerts/' + this.alertTypeGroup.code, navigationExtras)
       } else
        this.router.navigateByUrl('/alerts/' + this.alertTypeGroup.code, navigationExtras)
    }
    //googleAnalytics
    this.appService.googleAnalyticsAllPages('apply-alert-card', null, null, this.alertTypeGroup.code)
  }

}
