import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppService } from 'projects/shared/services/app.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ConfirmComponent>, public appService: AppService,public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      
    }

  ngOnInit() {

  }

  confirm() {
    this.data.onConfirm()
    this.dialogRef.close()
  }
}
