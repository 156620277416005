import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Authorities, Alert } from 'projects/shared/tools/constants';
import { ReportService } from 'projects/shared/services/report.service';
import * as FileSaver from "file-saver"
import { SelectionModel } from '@angular/cdk/collections';
import { AnimalService } from 'projects/shared/services/animal.service';
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { MatOption } from '@angular/material/core';
import { AddressService } from 'projects/shared/services/address.service';

@Component({
  selector: 'app-rapport-dialog',
  templateUrl: './rapport-dialog.component.html',
  styleUrls: ['./rapport-dialog.component.scss']
})
export class RapportDialogComponent implements OnInit {
  formGroup: UntypedFormGroup;
  columns: String[] = ["UID", "LOCAL_CODE", "BREED", "SEX", "AGE", "WEIGHT", "LACT", "LACT_NUM", "GEST"];
  selectedListColumns: String[] = [];
  selectedListColumn: String[] = ["UID", "LOCAL_CODE", "BREED", "SEX", "AGE", "WEIGHT", "LACT", "LACT_NUM", "GEST"];

  selectedListFarmers: any
  get: boolean
  getFarmer: boolean

  listFarmers: any[] = []
  farmers: any
  summary: Boolean;
  selection = new SelectionModel<any>(true, []);

  @ViewChild('allSelected', { static: true }) private allSelected: MatOption;

  constructor(public appService: AppService, private addressService: AddressService, private formBuilder: UntypedFormBuilder, public alertsService: AlertsService, public translate: TranslateService, private supervisorService: SupervisorService, public dialogRef: MatDialogRef<RapportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public reportService: ReportService, public animalService: AnimalService, public dialog: MatDialogRef<RapportDialogComponent>) {
    this.summary = this.data.summary
    this.createForm();
    if (!this.summary)
      this.formGroup.controls['selectedFarmers'].setValidators([Validators.required])
    this.appService.connectedCustomer['roles'].forEach(element => {
      if (element.publicAccess == true && element.isSupervisor == true)
        element['authorityRoles'].forEach(authorityRole => {
          if (authorityRole.authority.code == Authorities.REPORTS_EXPORT_LIVESTOCK) {
            this.get = authorityRole["get"];
          }
          if (authorityRole.authority.code == Authorities.SUPERVISORS) {
            this.getFarmer = authorityRole["get"];
          }
        }
        );
    });
  }
  ngOnInit() {
    if (!this.summary)
      this.loadFarmers()
    else this.addressService.getAddress(this.data.id).subscribe((res:any) => {
      this.listFarmers.push(res.customer.id);
    })
  }

  checkFarmer(farmer) {
    if (this.selection.isSelected(farmer)) {
      this.selection.deselect(farmer)
      let index = this.listFarmers.indexOf(farmer)
      this.listFarmers.splice(index, 1)
    }
    else {
      this.selection.select(farmer)
      this.listFarmers.push(farmer)
    }
    this.formGroup.controls.selectedFarmers.setValue(this.listFarmers);


  }

  checkColumn(column) {
    
    if (this.selection.isSelected(column)) {
      this.selection.deselect(column)
      let index = this.selectedListColumns.indexOf(column)
      this.selectedListColumns.splice(index, 1)
    }
    else {
      this.selection.select(column)
      this.selectedListColumns.push(column)
    }
    this.formGroup.controls.selectedColumns.setValue(this.selectedListColumns);

  }

  selectAllColumns(ev) {
    if (ev._selected) {
      this.formGroup.controls.selectedColumns.setValue(this.columns);
      ev._selected = true;
    }
    if (ev._selected == false) {
      this.formGroup.controls.selectedColumns.setValue([]);
    }
    this.selectedListColumns = ["UID", "LOCAL_CODE", "BREED", "SEX", "AGE", "WEIGHT", "LACT", "LACT_NUM", "GEST"];
  }

  selectAllFarmers(ev) {
    if (ev._selected) {
      this.farmers.forEach(farmer => {
        this.listFarmers.push(farmer.id)
      })
      
      this.formGroup.controls.selectedFarmers.setValue(this.farmers);
      ev._selected = true;
    }
    if (ev._selected == false)
      this.formGroup.controls.selectedFarmers.setValue([]);
  }

  createForm() {

    this.formGroup = this.formBuilder.group({
      'selectedColumns': [null, Validators.required],
      'selectedFarmers': [null],
    });

  }

  searchColumn(value: string) {
    let filter = value.toLowerCase();
    return this.columns.filter(option => option.toLowerCase().includes(filter));
  }

  searchFarmer(value: string) {
    let filter = value.toLowerCase();
    return this.farmers.filter(option => option['fullname'].toLowerCase().includes(filter));
  }

  onKeyColumn(value) {
    this.selectedListColumn = this.searchColumn(value);
  }

  onKeyFarmer(value) {
    this.selectedListFarmers = this.searchFarmer(value);
  }

  loadFarmers() {
    if (this.getFarmer) {
      this.supervisorService.getFarmerOfSupervisor().subscribe(res => {
        this.farmers = this.selectedListFarmers = res;
      })
    }
  }

  apply() {
  /*  if (this.get) {
      this.appService.showLoading();
      this.reportService.exportAnimals(this.listFarmers, this.selectedListColumns, this.summary).subscribe(data => {
        let blob = new Blob([data], { type: 'application/pdf' });
        FileSaver.saveAs(blob, this.translate.instant(this.summary ? 'SUMMARY_LIVESTOCK_pdf' : 'ALL_LIVESTOCK_pdf') + ".pdf");
        let fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
        this.appService.hideLoading();
        this.dialog.close();
      }, error => {
        this.appService.hideLoading();
        let message = this.translate.instant(error.error.code);
        let alert = this.translate.instant(Alert.OK)
        this.alertsService.open(message, alert);
      })

    }*/
  }
}
