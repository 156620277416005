import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'projects/shared/services/alert.service';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { GroupService } from 'projects/shared/services/group.service';
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { Alert, Authorities } from 'projects/shared/tools/constants';
import { CustomRouter } from '../services/custom-router';

@Component({
  selector: 'app-supervisors',
  templateUrl: './supervisors.component.html',
  styleUrls: ['./supervisors.component.scss']
})
export class SupervisorsComponent implements OnInit {
  supervisors: any



  authority = Authorities.SUPERVISORS;
  constructor( private alertsService: AlertsService,private translate: TranslateService,public router: CustomRouter, private alertService: AlertService, private supervisorService: SupervisorService, private groupService: GroupService, public appService: AppService, public dialog: MatDialog) { }

  ngOnInit() {
    this.loadSupervisors()
  }

  back() {
    this.appService.verif = false
    this.router.navigateByUrl('/home')
  }


  loadSupervisors() {
    this.appService.showLoading();
    this.supervisorService.getForAdminEntrepriseSupervisors().subscribe(res => {
      this.appService.hideLoading();
      this.supervisors = res

    },
     error => {
			this.appService.hideLoading();
			let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
			let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
			this.alertsService.open(message, alert);
		})
  }

  
}

