<div class="row py-2 gx-1 style-bar"[ngStyle]="{'background-color':element.color}" [ngClass]="{'ar-flips':appService.rtl()} "  >
    <div  class="col-8 pt-1 px-3"   >
        <div [ngClass]="{'rtl':appService.rtl(),'ar-flips':appService.rtl()} ">
            <span class=" text-style small">{{element.title | translate }}</span>
        </div>
        <div [ngClass]="{'rtl':appService.rtl(),'ar-flips':appService.rtl()} ">
            <span class=" text-style big">{{element.value1 | translate }}</span>
        </div>
      <div [ngClass]="{'rtl':appService.rtl(),'ar-flips':appService.rtl()} ">
        <span class=" text-style small">{{element.value2 | translate }}</span>
    </div>
    </div>
      <div class="col pt-2">
        <div  style="float:right">
            <img class="image" src="{{element.image}}">
        </div>
      </div> 
</div>