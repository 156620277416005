import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { Entreprise } from "./Entreprise";
import { Gateway } from "./Gateway";
import { Device } from "./Device";
@HateoasResource('tanks')
export class Tank extends Resource {

    id: number;
    serialNumber: string;
    mark:string;
    entreprise: Entreprise | any;
    code: number ;
    gateway: Gateway | any;
    deviceTemp: Device | any;
    deviceAgitators: Device[] | any;
    deviceFridge: Device | any;
    date : Date;
   
  }