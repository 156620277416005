import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AlertCardModule } from './alert-card/alert-card.module';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';



@NgModule({
  declarations: [
    AlertsComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    AlertCardModule,
    NgxHideOnScrollModule,
    MoomeCommonModule

  ],
  exports: [
    AlertsComponent,
  ],

})
export class AlertsModule { }
