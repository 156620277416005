import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimalBarComponent } from './animal-bar.component';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';



@NgModule({
  declarations: [AnimalBarComponent],
  imports: [
    CommonModule,
    MoomeCommonModule
  ],
  exports: [
    AnimalBarComponent,
  ],
  
})
export class AnimalBarModule { }
