import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditTimelineComponent } from './edit-timeline.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { CommonDialogComponent } from '../../common-dialog/common-dialog.component';
import { CommonDialogModule } from '../../common-dialog/common-dialog.module';
import { IonicModule } from '@ionic/angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    declarations: [EditTimelineComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MaterialModule,
        RouterModule, FormsModule,
        ReactiveFormsModule,
        MoomeCommonModule,
        CommonDialogModule,
        FormsModule,
        IonicModule,
        MatAutocompleteModule,
        MatFormFieldModule,
    ],
    exports: [
        EditTimelineComponent,
        MatFormFieldModule,
    ]
})
export class EditTimelineModule { }
