<div class="style-bar-troups w-100" ngxHideOnScroll [scrollingElementSelector]="'body'" [propertyUsedToHide]="'top'"
    [valueWhenHidden]="'-50px'" [valueWhenShown]="'90px'">
    <div class="row background-troups  h-100">

        <div class="col-4 center-element" (click)="back()">
            <img src="/assets/images/left-arrow-1.svg" />
        </div>
        <div class="col-4 center-element">
            <div class="row d-block">
                <div class="col text-center"> <img src="/assets/images/farmer_icon_neutral_2.svg" />
                </div>
                <div class="col text-center ps-0 pe-0 pt-1"> <span class="text-style"
                        [ngClass]="{rtl:appService.rtl()}">{{ 'FARMERS_MOB' | translate }}</span></div>
            </div>
        </div>


        <div class="col-4 text-center d-none d-sm-block " *ngIf="verif && !tableView">
            <mat-icon class=" table_icon style-icon" (click)="(tableView=true)">
                table_chart</mat-icon>
        </div>
        <div *ngIf="verif && tableView" class="col-4 text-center d-none d-sm-block ">

            <mat-icon class=" table_icon style-icon" (click)="(tableView=false)">
                view_module</mat-icon>

        </div>




    </div>
</div>
<div *ngIf="!tableView" class="row px-2 pb-5 position">
    <app-farmer-card *ngFor="let farmer of farmers" [farmer]="farmer"
        class="col-12 col-sm-6 col-md-6 col-lg-4 mt-2 mb-2" [getAuthorized]="authority"></app-farmer-card>
</div>
<div *ngIf="farmerEmpty">
    <app-no-animals [text]="'NO_Farmers'" [getAuthorized]="authority"></app-no-animals>
</div>
<div class="fixed-bar-mob fixed-bar-height" ngxHideOnScroll [scrollingElementSelector]="'body'"
    [propertyUsedToHide]="'bottom'" [valueWhenHidden]="'-50px'" [valueWhenShown]="'0px'">
    <div class="row">

        <div class="col">
            <img src="/assets/images/Groupe-429.svg" class="float-end" (click)="addFarmer()" />
        </div>
    </div>
</div>



<div #target>
    <div class=" px-2 pb-5 position" [ngClass]="role? 'pt-5' : ''" style="overflow-y: hidden !important; top: 150px "
        *ngIf="tableView">

        <div class="containerTab mat-elevation-z8">
            <table mat-table [dataSource]="farmers" [ngClass]="{rtl:appService.rtl()}">

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> {{ 'NAME' | translate}} </th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                        {{element.fullname}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef> {{ 'PHONE' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" class=" text-center">
                        {{element.phone}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="setAddress">
                    <th mat-header-cell *matHeaderCellDef style=" width: 88px;">{{ 'SET_ADDRESS' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" class=" text-center" style="width: 20px;">
                        <div class="mt-1 mb-1">
                            <span (click)="addLocation(element)">
                                <img src="/assets/images/add-location-little-size.svg">
                            </span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef> {{ 'ADDRESS' | translate}} </th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                        <div class="mt-1 mb-1"><span (click)="showLocation(element)" [ngClass]="appService.rtl()? 'ms-4' : 'me-4'">
                                <img src="/assets/images/map-location-little-size.svg">
                            </span>

                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cowNumber">
                    <th mat-header-cell *matHeaderCellDef > {{'COW_NUMBER' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="text-center" (click)="openAnimals(element)">
                        {{element.animalCount? element.animalCount : "0"}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="milkQuantity">
                    <th mat-header-cell *matHeaderCellDef> {{ 'MILK_QUANTITY' | translate}} </th>
                    <td mat-cell *matCellDef="let element" (click)="showMilkQuantity(element)">
                        {{element.quantityMilk}}{{ 'KG' | translate }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="t">
                    <th mat-header-cell *matHeaderCellDef> {{ 'T°' |translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-center">
                            <div class="d-inline  ps-1"><span class="nbr-style title-style">{{element.temp ?
                                    (element.temp |
                                    number:'2.1-2' ): '--'}}</span></div>
                            <div class="d-inline"><span class="exp-unit-celsus pe-2">°C</span></div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="h">
                    <th mat-header-cell *matHeaderCellDef> {{ 'H%' |translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-center">
                            <div class="d-inline pe-1 ps-1"><span class="nbr-style title-style">{{element.humidity ?
                                    element.humidity : '--'}}</span></div>
                            <div [ngClass]="appService.rtl()? 'text-size-ar' : 'text-size'" class="d-inline"><span
                                    class="exp-unit-pourcentage">%</span></div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="thi">
                    <th mat-header-cell *matHeaderCellDef> {{ 'THI' |translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="d-inline pe-1 ps-1"><span class="nbr-style title-style"
                                [ngClass]="appService.colorThi(element.thi)"> {{element.thi ? (element.thi |
                                number:'2.1-2' ): '--'}} </span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="alerts">
                    <th mat-header-cell *matHeaderCellDef> {{ 'alerts' | translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        <div class=" blue-color mt-2" [getAuthorized]="authorityAlerts" (click)="openAlerts(element)">
                            <div class="d-flex center-element h-100 gx-1 px-1">
                                <div class=" center-element mt-1">
                                    <span><img src="/assets/images/Alert-menu-little.svg"></span>
                                </div>
                            </div>
                            <div *ngIf="element.unreadAlert!=null" class="common-dot" [ngClass]="appService.rtl()? 'dot-table' : 'dot-fr-table'">
                                <div class="number-style mt-2" [ngClass]="appService.rtl()? 'pos' : 'pos-fr'">
                                    {{element.unreadAlert>=100 ?
                                    '+99' :
                                    element.unreadAlert}}</div>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="settings">
                    <th mat-header-cell *matHeaderCellDef> {{ 'SETTINGS' | translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="center-element" (click)="settingFarmer(element)" (click)="appService.verif=false"><img
                                src="/assets/images/gear-option-little.svg" /></div>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

        </div>
    </div>
</div>