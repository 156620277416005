<div class="card-style" [ngStyle]="{'background-color':group.color}" 
    (click)="animalId ? findOldGroup() : troups(group.id , group.labelEn)" [deleteAuthorized]="authorityGroups" [editAuthorized]="authorityGroups" [getAuthorized]="authorityGroups">
    <div class="row">
        <div class="col">
            <div class="float-end text-style center-element font-style-num position-numb pe-2 ps-2 " [ngStyle]="{'background-color':group.backgroundColor}" >
                {{group.groupAnimalCount? group.groupAnimalCount : "0"}}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="text-center text-style center-element position-text"
                [ngClass]="appService.rtl() ? 'font-style-text-ar' : 'font-style-text' "><span
                    [ngClass]="{rtl:appService.rtl()}">{{group |
                    codeTranslate}}</span></div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="float-end  pe-2" [ngClass]="appService.rtl() ? 'position-icon-ar' : 'position-icon' "><img
                    *ngIf="group.icon" [src]="transform(group.icon)" /></div>
        </div>
    </div>
</div>