<app-section-bar [title]="text" (goBack)="back()" [color]="color"></app-section-bar>


<div class="row px-3 gx-1 m-2" *ngFor="let group of filtredGroupsHasChildren" style="background-color: #E9E9E9"
    [getAuthorized]="authority">
    <app-group-card *ngFor="let child of group.childrens" (reload)="loadGroups()" [group]="child" [animalId]="animalId"
        [oldGroup]="oldGroup" class="{{child.class}} mt-2 mb-2"></app-group-card>
    <app-group-card [group]="group" [animalId]="animalId" [oldGroup]="oldGroup" class="{{group.class}} mt-2 mb-2">
    </app-group-card>
</div>

<div class="row px-3 gx-1 m-2" style="background-color: #E9E9E9" [getAuthorized]="authority">
    <app-group-card *ngFor="let group of groupsNotHasChildren" [group]="group" [animalId]="animalId"
        [oldGroup]="oldGroup" class="col-6 mt-2 mb-2">
    </app-group-card>
</div>
<div class="row px-3 gx-1 m-2" [getAuthorized]="authority">
    <app-group-card *ngFor="let group of groupAll" [group]="group" [animalId]="animalId" [oldGroup]="oldGroup"
        class="col-12 mt-2 mb-2">
    </app-group-card>
</div>