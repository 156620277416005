import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { Customer } from "./Customer";

@HateoasResource('orders')
export class Order extends Resource {

    id: number;
    customer: Customer[] | any; 
    createDate:Date;
   
    
    
  }