import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../material/material.module';
import { TimelineInformationComponent } from './timeline-information.component';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { RouterModule } from '@angular/router';
import { HelpModule } from '../../menu/help/help.module';


@NgModule({
  declarations: [TimelineInformationComponent ],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    RouterModule, 
    MoomeCommonModule,
    HelpModule
  ]
  
})
export class TimelineInformationModule { }