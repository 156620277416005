<div [ngClass]="isPopUp? 'make-scrollable' : '' ">
<app-animal-bar  [id]="id" (goBack)="back()">
</app-animal-bar>

<div class="row">
    <div class="col text-center mt-3 mb-3"> <span class="text-style"
            [ngClass]="{rtl:appService.rtl()}">{{ text | translate }}</span> </div>
</div>

<form [formGroup]="formGroup">
    <div class="row">
        <div class="col mb-3 mt-2 center-element">
            <div>
                <div class="title-style" [ngClass]="{rtl:appService.rtl()}">{{ 'TITLE' | translate }}</div>
            <input class="input-mob height font-size-text" formControlName="note" placeholder="{{ 'TITLE' | translate }}"
                [ngClass]="{rtl:appService.rtl()}" [readonly]="!editable" >
        </div>
    </div>
    </div>

   <!-- <div class="row">
        <div class="col mb-3 mt-2 text-center">
            <input class="input-mob font-size-text" [ngClass]="{rtl:appService.rtl()}" [matDatepicker]="picker"
                formControlName="actionDate" [max]="maxDate" [min]="minDate" (dateChange)="addEvent($event)"
                placeholder="{{ 'CHOOSE' | translate}}&nbsp;{{ 'DATE' | translate}}">
            <div [ngClass]="appService.rtl()? 'date-ar' : 'date'">
                <mat-datepicker-toggle matSuffix [for]="picker">
                    <img matDatepickerToggleIcon src="/assets/images/calendar4.svg" />
                </mat-datepicker-toggle>
                <mat-datepicker touchUi #picker></mat-datepicker>
            </div>
        </div>
    </div>-->

    <div class="row" [ngClass]="{rtl:appService.rtl()}">
        <div class="col mb-3 mt-2 center-element ">
            <ion-item class="input-mob-date ps-2 pe-2" >
                <ion-datetime #datePicker mode="ios" [ngClass]="{rtl:appService.rtl()}" class="font-size-text"
                    [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()" formControlName="actionDate"
                    placeholder="{{ 'CHOOSE'| translate }}&nbsp;{{ 'DATE' | translate}}" (ionChange)="fixArabeFormat()"
                    [max]="maxDate"
                    [min]="minDate"
                    [doneText]="'SAVE' |translate"
                    [cancelText]="'CANCEL' |translate"
                    [monthShortNames]="appService.monthShort()"
                    >
                </ion-datetime>    
                <img matDatepickerToggleIcon  [ngClass]="appService.rtl()? 'date-ar' : 'date'" src="/assets/images/calendar4.svg" />                      
             
            </ion-item>
        </div>
    </div>
    
    
       <!-- <div class="row">
            <div class="col mb-3 mt-2 text-center">
                <ion-item>
                    <ion-datetime #datePicker class="input-mob font-size-text" [ngClass]="{rtl:appService.rtl()}"
                        [displayFormat]="format" formControlName="actionDate"
                        placeholder="{{ 'CHOOSE'| translate }}" (ionChange)="addEvent($event)"
                        [max]="maxDate">
                    </ion-datetime>                 
                </ion-item>
            </div>
        </div>-->
    


    <div class="row" *ngIf="formGroup.controls['type'].value != typeWeight && formGroup.controls['type'].value != typeHeight ">
        <div class="col center-element">
            <div>
                <div class="title-style" [ngClass]="{rtl:appService.rtl()}">{{ 'TEXT' | translate }}</div>
            <textarea class="input-mob input-height font-size-text pt-2" [ngClass]="{rtl:appService.rtl()}"
                placeholder="{{ 'TEXT'| translate }}" formControlName="noteDetail"></textarea>
        </div>
    </div>
    </div>
    <div class="row" *ngIf="formGroup.controls['type'].value == typeWeight" [ngClass]="{rtl:appService.rtl()}">
        <div class="col center-element mt-2 mb-2">
            <div>
                <div class="title-style">{{ 'WEIGHT' | translate }}</div>
            <input class="input-mob font-size-text width-weight" placeholder="{{ 'WEIGHT'| translate }}"
                [ngClass]="{rtl:appService.rtl()}" type="number" formControlName="noteDetail" (keyup)="verifPoids()"
                id="weight" (input)="appService.validWeight(formGroup.controls['noteDetail'])">

            <span class="text-kg" [ngClass]="{rtl:appService.rtl()}"> {{ 'KG'| translate }}</span>
        </div>
        </div>
    </div>
    <div class="row" *ngIf="formGroup.controls['type'].value == typeHeight" [ngClass]="{rtl:appService.rtl()}">
        <div class="col center-element mt-2 mb-2">
            <div>
                <div class="title-style">{{ 'HEIGHT' | translate }}</div>
            <input class="input-mob font-size-text width-weight" placeholder="{{ 'HEIGHT'| translate }}"
                [ngClass]="{rtl:appService.rtl()}" type="number" formControlName="noteDetail" (keyup)="verifHeight()"
                id="height" >

            <span class="text-kg" [ngClass]="{rtl:appService.rtl()}"> {{ 'CM'| translate }}</span>
        </div>
        </div>
    </div>
    <section class="example-section col center-element mt-2 mb-1" [ngClass]="{rtl:appService.rtl()}">
        <mat-checkbox formControlName="isCheck" (change)="showOptions($event)"> 
            <span class="rappel-style me-1 ms-1">
            {{'RAPPEL_IN'| translate}}
        </span></mat-checkbox>
        <input class="input-mob year-style" placeholder="{{ 'NUMBER' | translate}}"  type="number" 
          formControlName="count" (keypress)="appService.numericOnly($event,numericPattern)"  >
         <span class="rappel-style me-1 ms-1" >
            {{'DAYS'| translate}}
        </span> 
      </section>

    <div class="row">
        <div class="col center-element mt-3 mb-3">
            <button mat-raised-button color="primary" class="button-mob-note" (click)="confirm()"  [ngClass]=" !formGroup.valid  ?  'opac' : '' " ><span
                    [ngClass]="{rtl:appService.rtl()}">{{ 'CONFIRM' | translate }}</span></button>
        </div>
    </div>
</form>
</div>