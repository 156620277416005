import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AnimalService } from 'projects/shared/services/animal.service';
import { AppService } from 'projects/shared/services/app.service';
import { FarmerService } from 'projects/shared/services/farmer.service';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { ReportService } from 'projects/shared/services/report.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { CustomRouter } from '../services/custom-router';
import { MatOption } from '@angular/material/core';
import { Alert, Authorities, FORMAT_MAX_MIN, LivestockIdentif } from 'projects/shared/tools/constants';

import * as moment from 'moment';
import { ArcElement, BarController, BarElement, BubbleController, CategoryScale, Chart, Decimation, DoughnutController, Filler, Legend, LineController, LineElement, LinearScale, LogarithmicScale, PieController, PointElement, PolarAreaController, RadarController, RadialLinearScale, ScatterController, SubTitle, TimeScale, TimeSeriesScale, Title, Tooltip } from 'chart.js';
import { CapacitorUtils } from 'projects/shared/tools/CapacitorUtils';
import { Customer } from 'projects/shared/resources/Customer';




@Component({
  selector: 'app-cow-milk-graph',
  templateUrl: './cow-milk-graph.component.html',
  styleUrls: ['./cow-milk-graph.component.scss']
})
export class CowMilkGraphComponent implements OnInit {
  formGroup: UntypedFormGroup;
  animals: any = [];
  ListFarmers: any = [];
  create: boolean
  formatArabe: any
  selectedAnimals = [];
  roleFarmer: boolean = false;
  nationalIdentification
  nameLocalCode
  maxDate: any;
  periods = ["1_DAY", "3_DAYS", "1_WEEK", "1_MONTH"]
  selectedPeriods = ["1_DAY", "3_DAYS", "1_WEEK", "1_MONTH"]
  displaygraph = false;
  lineChart: Chart;
  dayTime: String[];
  startDate;
  endDate;
  periodValue
  title: any;
  datasets: any[] = [];
  quantityMilk: Number[];
  adminAccess: Boolean = false
  idAnimal
  selectedFarmers: Customer[] = [];
  customers: Customer[];
  constructor(public formTemplateService: FormTemplateService, public tokenManagementService: TokenManagementService,
    private farmerService: FarmerService,
    public router: CustomRouter, public appService: AppService, private formBuilder: UntypedFormBuilder,
    public alertsService: AlertsService, public translate: TranslateService, public codeTranslate: CodeTranslatePipe,
    public reportService: ReportService, public animalService: AnimalService) {
    Chart.register(
      ArcElement,
      LineElement,
      BarElement,
      PointElement,
      BarController,
      BubbleController,
      DoughnutController,
      LineController,
      PieController,
      PolarAreaController,
      RadarController,
      ScatterController,
      CategoryScale,
      LinearScale,
      LogarithmicScale,
      RadialLinearScale,
      TimeScale,
      TimeSeriesScale,
      Decimation,
      Filler,
      Legend,
      Title,
      Tooltip,
      SubTitle
    );
    this.createForm();

    this.appService.connectedCustomer['roles'].forEach(element => {
      if (element.isSupervisor == true)
        this.roleFarmer = false;
      else this.roleFarmer = true;
      element['authorityRoles'].forEach(authorityRole => {
        if (authorityRole.authority.code == Authorities.ANIMAL_MILK_GRAPH)
          this.create = authorityRole["create"];


      });
    });
  }

  ngOnInit(): void {
    this.maxDate = moment(new Date()).format(FORMAT_MAX_MIN);
    this.nationalIdentification = LivestockIdentif.NATIONAL_IDENTIFICATION
    this.nameLocalCode = LivestockIdentif.NAME_LOCALCODE
    this.formatArabe = this.appService.verifFormatDate()
    if (this.roleFarmer)
      this.loadAnimals();
    else
      this.loadCustomers();

  }
  createForm() {
    this.formGroup = this.formBuilder.group({

      'farmer': [null],
      'code': [null, Validators.required],
      'period': ['1_WEEK'],
      'startDate': [],
      'endDate': []

    });
    this.formGroup.controls['startDate'].disable();
    this.formGroup.controls['endDate'].disable();
  }

  fixArabeFormat() {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD/MMM/YYYY"
  }
  loadAnimals() {
    let addressId
    if (!this.roleFarmer)
      addressId = this.formGroup.controls['farmer']?.value?.addresses[0].id;
    else if (this.roleFarmer == true)
      addressId = this.appService.connectedCustomer.addresses[0].id;
      if(!addressId){
        let message = this.translate.instant("FARMER_NOT_HAVE_ANIMALS");
        let alert = this.translate.instant(Alert.OK)
        this.alertsService.open(message, alert);
      }
    this.reportService.getListAnimalsForMilkCowGraph(addressId).subscribe(res => {
      if (res._embedded) {
        this.selectedAnimals = this.animals = res._embedded.animals;

      }
      else {
        this.selectedAnimals = this.animals = []
        let message = this.translate.instant("FARMER_NOT_HAVE_ANIMALS");
        let alert = this.translate.instant(Alert.OK)
        this.alertsService.open(message, alert);
      }
    }, error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
      this.alertsService.open(message, alert);
    })
  }
  loadAllFarmers() {

    this.farmerService.getFarmerOfSupervisorForReproReport().subscribe(res => {
      this.selectedFarmers = res._embedded.customers


    })

  }
  searchPeriod(value, list) {
    let filter = value.toLowerCase();
    return list.filter(option => this.translate.instant(option).toLowerCase().includes(filter));
  }

  onKeyPeriod(value) {
    this.selectedPeriods = this.searchPeriod(value, this.periods)
  }
  back() {
    this.router.back('')
  }
  showOptions(event) {
    this.formGroup.controls['period'].setValue(null);
    this.formGroup.controls['startDate'].setValue(null);
    this.formGroup.controls['endDate'].setValue(null);

    if (event.checked) {
      this.formGroup.controls['period'].disable();

      this.formGroup.controls['startDate'].enable();
      this.formGroup.controls['endDate'].enable();
    } else {
      this.formGroup.controls['startDate'].disable();

      this.formGroup.controls['endDate'].disable();
      this.formGroup.controls['period'].enable();
    }
  }
  setPeriod() {
    if (this.formGroup.controls['period'].value != null) {
      let start

      if (this.formGroup.controls['period'].value == "1_DAY")
        start = moment().subtract(1, 'days').startOf('day');

      if (this.formGroup.controls['period'].value == "3_DAYS")
        start = moment().subtract(3, 'days').startOf('day');

      if (this.formGroup.controls['period'].value == "1_WEEK")
        start = moment().subtract(1, 'weeks').startOf('day');

      if (this.formGroup.controls['period'].value == "1_MONTH")
        start = moment().subtract(1, 'months').startOf('day');

      if (this.formGroup.controls['period'].value == "3_MONTHS")
        start = moment().subtract(3, 'months').startOf('day');

      this.title = start.format("MM/DD/YYYY") + " -- "
        + moment().format("MM/DD/YYYY");
      this.startDate = start.toISOString();
      this.endDate = moment().toISOString();
    } else {
      this.title = moment(this.formGroup.controls['startDate'].value).format("MM/DD/YYYY") + " -- "
        + moment(this.formGroup.controls['endDate'].value).format("MM/DD/YYYY");
      this.startDate = moment(this.formGroup.controls['startDate'].value).toISOString();
      this.endDate = moment(this.formGroup.controls['endDate'].value).toISOString();
    }


  }
  apply() {
    
    this.idAnimal = this.formGroup.controls['code'].value?.id;
    if (this.lineChart) {
      this.lineChart.destroy();
      this.displaygraph = false;
    }
    this.periodValue = this.formGroup.controls['period'].value
    this.setPeriod();
    if ((this.endDate != "Invalid date") && (this.startDate != "Invalid date"))
      this.reportService.getMilkProductionOfAnimal(this.startDate, this.endDate, this.idAnimal).subscribe((res: any) => {
        this.dayTime = [];
        this.quantityMilk = [];
        this.datasets = [];
        let keys = (Object.keys(res)).sort();
        keys.forEach(element => {
          this.dayTime.push(moment(res[element][0]).format("YYYY/MM/DD"));
          this.quantityMilk.push(res[element][1] ? res[element][1] : null);

        });
        this.dayTime=this.dayTime.sort();
        this.datasets.push(
          {
            data: this.quantityMilk,
            label: this.translate.instant('COW_MILK_GRAPH'),
            fill: false,
            borderColor: "red",
            spanGaps: true
          }
        )
        this.displaygraph = true;
        this.appService.hideLoading();

        this.lineChart = new Chart('lineChart', {
          type: 'line',

          data: {
            labels: this.dayTime,
            datasets: this.datasets,
          },
          options: {
            plugins: {
              legend: {
                display: true,
              },
              title: {
                text: this.title,
                display: true
              },
            },

          }
        })

      }, error => {

        this.appService.hideLoading();
        let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.code);
        let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
        this.alertsService.open(message, alert);
      })
  }
  exportCsv() {
    
    this.idAnimal = this.formGroup.controls['code'].value?.id;
    this.periodValue = this.formGroup.controls['period'].value
    let type = ".csv"
    this.setPeriod();
    if ((this.endDate != "Invalid date") && (this.startDate != "Invalid date"))
      this.reportService.exportMilkProductionOfAnimalToCsv(this.startDate, this.endDate, this.idAnimal).subscribe(data => {
        let blob = new Blob([data], {type: 'text/csv; charset=utf-8' }); 
        let filename = this.translate.instant('COW_MILK_GRAPH') + moment(new Date()).format('yyyy-MM-DD HH:mm') 
        if (CapacitorUtils.isApp){
          CapacitorUtils.writeAndOpenFile(blob, filename+type);
          this.appService.hideLoading();
          let message = this.translate.instant('SAVE_FILE_SUCESS');
          this.alertsService.open(message);}
        else{
          this.appService.saveAs(blob, filename, type);
        this.appService.hideLoading();}
      }, error => {
        this.appService.hideLoading();
        let message = this.translate.instant(error.error.code);
        let alert = this.translate.instant(Alert.OK)
        this.alertsService.open(message, alert);
      })
  }



  onKeyAnimals(value) {
    if (this.appService.valueUid == this.nationalIdentification)
      this.selectedAnimals = this.search(value, this.animals, 'uid');
    else if (this.appService.valueUid == this.nameLocalCode)
      this.selectedAnimals = this.search(value, this.animals, 'name');
    else (!this.appService.valueUid)
    this.selectedAnimals = this.search(value, this.animals, 'uid');
  }

  search(value, list, field) {
    let filter = value.toLowerCase()
    let listFiltred;
    listFiltred = list.filter(option => option[field]?.toLowerCase().includes(filter));
    if (listFiltred.length != 0)
      return listFiltred;
    else {
      listFiltred = list.filter(option => option['name']?.toLowerCase().includes(filter));
      return listFiltred;

    }

  }

  updateChart(point) {
    this.lineChart.options.elements.point.radius = point.checked ? 0 : 2;
    if (!point.checked) {
      this.lineChart.options.elements.point.hitRadius = 5
      this.lineChart.options.elements.point.hoverRadius = 10
      this.lineChart.options.elements.point.hoverBorderWidth = 2
    }
    this.lineChart.update();
  }
  onKeyFarmer(value) {
    this.selectedFarmers = this.search(value, this.customers, 'fullname');
  }
  loadCustomers() {

    this.reportService.getFarmersForMilkCowGraph().subscribe(res => {
      this.selectedFarmers = this.customers = res._embedded?.customers
      let farmer = res._embedded?.customers
      this.formGroup.controls['farmer'].setValue(farmer);
      //if (this.idA) {
      this.loadAnimals()
      //}
    }, error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
      this.alertsService.open(message, alert);
    })
  }







}
