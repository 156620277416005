import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { FarmerMilkProductionService } from 'projects/shared/services/farmer-milk-production.service';
import { Helper } from 'projects/shared/tools/helper';
import { element } from 'protractor';
import { CommonDialogComponent } from '../../common-dialog/common-dialog.component';
import { CustomRouter } from '../../services/custom-router';
import { SectionForm } from 'projects/shared/tools/constants';

@Component({
  selector: 'app-edit-farmer-milk-production',
  templateUrl: './edit-farmer-milk-production.component.html',
  styleUrls: ['./edit-farmer-milk-production.component.scss']
})
export class EditFarmerMilkProductionComponent implements OnInit {

  idFarmer
  idFormTemplate
  id
  result
  create = false
  name = "MILK_PRODUCTION"
  section = SectionForm.farmer_milk_production
  color = 'color-milk-prod'
  price=this.appService.price
  constructor(public dialog: MatDialog, public farmerMilkProductionService: FarmerMilkProductionService, public appService: AppService,
    public translate: TranslateService, private alertsService: AlertsService,
    private router: CustomRouter, public route: ActivatedRoute) {

    const { idFormTemplate, id } = this.route.snapshot.params
    this.idFarmer = this.appService.connectedCustomer.id
    this.idFormTemplate = idFormTemplate;
    this.id = id

    if (this.id)
      this.displayInfo(this.id);
    else this.create = true
   
    this.appService.listId = [];


    
  }

  ngOnInit(): void {

  }
  displayInfo(id) {

    this.appService.showLoading()
    this.farmerMilkProductionService.get(id).subscribe(res => {
      this.result = res;
    })
    this.appService.hideLoading()
  }

  confirm(event) {
    this.appService.showLoading();

    this.farmerMilkProductionService.findMilkProductionByExisteDate(event.formGroup.value.date).subscribe(data => {
      this.appService.hideLoading();
      if ((data == '0')) {
        this.save(event);
      }
      else {
        const dialogRef = this.dialog.open(CommonDialogComponent, {
          data: {
            message: this.translate.instant('DATE_EXIST'),
            onConfirm: () => {
              this.save(event);
            }
          }
        });
      }

    })
  }
  save(event) {
    if (event.formGroup.status == "INVALID") {
      this.appService.error('STOCK_INVALID')
    }
    else {
      let form = event.formGroup.value
      if (!form.totalQuantityMilk)
        this.appService.error('MILK_EMPTY')
      else if (!form.price)
        this.appService.error('PRICE_INVALID')
      else {
        form.id = this.id
        this.appService.showLoading();
        if (form.adjusted)
          form.adjusted = form.adjusted
        this.farmerMilkProductionService.saveFarmerMilkProduction(form).subscribe(res => {
          this.appService.hideLoading();
          let message = this.translate.instant('SAVE_SUCESS');
          this.alertsService.open(message);
          this.back();
        });
      }
    }
  }
  back() {
    this.router.back('')
  }

}
