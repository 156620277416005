import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Authorities, Alert, LivestockIdentif, FORMAT_MAX_MIN } from 'projects/shared/tools/constants';
import { ReportService } from 'projects/shared/services/report.service';
import { SelectionModel } from '@angular/cdk/collections';
import { AnimalService } from 'projects/shared/services/animal.service';
import { CustomRouter } from '../services/custom-router';
import { CustomerService } from 'projects/shared/services/customer.service';
import { FarmerService } from 'projects/shared/services/farmer.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import * as moment from 'moment';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { MatOption } from '@angular/material/core';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { CapacitorUtils } from 'projects/shared/tools/CapacitorUtils';
import { formatDate } from '@angular/common';

export class FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  checked?: boolean
  item: any;
}


enum NodeType {
  FARMER, GROUP, ANIMAL
}

@Component({
  selector: 'app-reproduction-report',
  templateUrl: './reproduction-report.component.html',
  styleUrls: ['./reproduction-report.component.scss']
})
export class ReproductionReportComponent implements OnInit {

  projection = "form-template"
  relatedTo = "farmer"

  hasChild = (_: number, node: FlatNode) => node.expandable;

  formGroup: UntypedFormGroup;
  selectedListAnimals: any = [];
  animals: any = [];
  ListFarmers: any = [];
  cows: any = [];
  selectedListFarmers: any = []
  create: boolean
  getFarmer: boolean
  listAnimals: any[] = []
  farmers: any
  selection = new SelectionModel<any>(true, []);
  list = []
  formatArabe: any
  allAnimal: any[] = [];
  searchListA: any[] = [];
  listIdAnimals = [];
  selectedAnimals = [];
  farmerAnimals = [];
  newItems = {};
  /*startDate;
  endDate;*/
  roleFarmer: boolean = false;
  display = false
  showAll: Boolean = false
  select
  isAllSelected
  nationalIdentification
  nameLocalCode
  maxDate: any;
  @ViewChild('allSelectedFarmer') private allSelectedFarmer: MatOption;
  @ViewChild('allSelectedAnimal') private allSelectedAnimal: MatOption;

  allFarmers;

  constructor(public formTemplateService: FormTemplateService, public tokenManagementService: TokenManagementService,
    private farmerService: FarmerService,
    public router: CustomRouter, public appService: AppService, private formBuilder: UntypedFormBuilder,
    public alertsService: AlertsService, public translate: TranslateService, public codeTranslate: CodeTranslatePipe,
    public reportService: ReportService, public animalService: AnimalService) {

    this.createForm();

    this.appService.connectedCustomer['roles'].forEach(element => {

      if (element.code == 'ROLE_FARMER')
        this.roleFarmer = true;

      if (element.publicAccess == true && element.isSupervisor == true)
        element['authorityRoles'].forEach(authorityRole => {
          if (authorityRole.authority.code == Authorities.REPORTS_EXPORT_LIVESTOCK)
            this.create = authorityRole["create"];


        });
    });
  }

  ngOnInit() {
    this.maxDate = moment(new Date()).format(FORMAT_MAX_MIN);
    this.nationalIdentification = LivestockIdentif.NATIONAL_IDENTIFICATION
    this.nameLocalCode = LivestockIdentif.NAME_LOCALCODE
    this.formatArabe = this.appService.verifFormatDate()
    if (this.roleFarmer)
      this.loadAnimals();
    else
      this.loadAllFarmers();

  }






  loadAnimalsAfterChek(value) { 
    this.loadAnimalsForFarmers(value)
  }


  tosslePerOneFarmer() {
    if (this.allSelectedFarmer.selected) {
      this.allSelectedFarmer.deselect();
      this.allAnimal = []
      this.organiseTreeDataAnimal({});
      this.animals = this.selectedListAnimals = [];
      this.newItems = {};
      return false;
    }
    /*if (this.formGroup.controls['selectedFarmers'].value.length == this.selectedListFarmers.length) {
      this.allSelectedFarmer.select();
      this.formGroup.controls['selectedFarmers'].setValue(this.allFarmers.map(item => item.id))
    }*/

    this.loadAnimalsAfterChek(this.formGroup.controls['selectedFarmers'].value)

  }
  toggleAllSelectionAnimal() {
    if (this.allSelectedAnimal.selected) {
      this.formGroup.controls['selectedAnimals']
        .patchValue([...this.selectedAnimals.map(item => item), 0]);

    } else {
      this.formGroup.controls['selectedAnimals'].patchValue([]);
      this.newItems = {};
    }

  }
  toggleAllSelectionFarmer() {
    if (this.allSelectedFarmer.selected) {

      this.formGroup.controls['selectedFarmers']
        .patchValue([...this.selectedListFarmers.map(item => item.id), 0]);   
      this.loadAnimalsAfterChek(this.formGroup.controls['selectedFarmers'].value)

    } else {
      this.formGroup.controls['selectedFarmers'].patchValue([]);
      this.allAnimal = []
      this.organiseTreeDataAnimal({});
      this.animals = this.selectedListAnimals = [];
      this.newItems = {};
    }

  }

  fixArabeFormat() {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD/MMM/YYYY"
  }

  createForm() { 
    this.formGroup = this.formBuilder.group({

      'selectedFarmers': [null],
      'selectedAnimals': [null, Validators.required],
      /*'startDate': [moment()],
      'endDate': [moment()],*/

    });
  }




  loadAllFarmers() {

    this.farmerService.getFarmerOfSupervisorForReproReport().subscribe(res => {
      this.allFarmers = res
      this.farmers = this.selectedListFarmers = res;
    })

  }

  searchFarmer(value: string) {
    let filter = value.toLowerCase();
    return this.farmers.filter(option => option['fullname'].toLowerCase().includes(filter));
  }

  onKeyFarmer(value) {
    this.selectedListFarmers = this.searchFarmer(value);
  }




  loadAnimalsForFarmers(farmerIds) {
    this.newItems = {};
    this.animalService.getAnimalsOfFarmerForReproReport(farmerIds).subscribe(res => {
      this.newItems = this.groupByType(res);
      this.organiseTreeDataAnimal(this.newItems);
      this.animals = this.selectedListAnimals = res;
      this.dataSourceAnimal.data = this.allAnimal
      // this.checklistSelection.clear();
      if ((this.dataSourceAnimal.data).length > 0)
        this.showAll = true
    })
  }
  onKeyAnimals(value) {
    if (this.appService.valueUid == this.nationalIdentification)
      this.selectedAnimals = this.search(value, this.animals, 'uid');
    else if (this.appService.valueUid == this.nameLocalCode)
      this.selectedAnimals = this.search(value, this.animals, 'name');
    else (!this.appService.valueUid)
    this.selectedAnimals = this.search(value, this.animals, 'uid');
  }

  search(value, list, field) {
    let filter = value.toLowerCase()
    let listFiltred;
    listFiltred = list.filter(option => option[field]?.toLowerCase().includes(filter));
    if (listFiltred.length != 0)
      return listFiltred;
    else {
      listFiltred = list.filter(option => option['name']?.toLowerCase().includes(filter));
      return listFiltred;

    }

  }

  groupByType(array) {
    return array.reduce((r, a) => {
      r[a.customerFullName] = r[a.customerFullName] || [];
      r[a.customerFullName].push(a);
      return r;
    }, Object.create(null));
  }

  getKeys(o) {
    return Object.keys(o)
  }

  organiseTreeDataAnimal(data) {
    this.allAnimal = []
    this.getKeys(data).forEach(key => {
      data[key].forEach(animal => {
        animal['type'] = NodeType.ANIMAL
      })
      this.allAnimal.push({ name: key, animals: data[key], type: NodeType.FARMER, id: -1 })
    })
  }

  back() {
    this.router.back('')
  }




  error(message) {
    this.appService.hideLoading();
    let alert = this.translate.instant(Alert.OK)
    this.alertsService.open(this.translate.instant(message), alert);
  }




  transformerAnimal(node, level: number): FlatNode {
    if (node.type == NodeType.FARMER) {
      node.expandable = !!node.animals && node.animals.length > 0
    }
    if (node.type == NodeType.ANIMAL) {
      node.expandable = false
      node.name = (node.uid ? node.uid : node.name)
    }

    return {
      expandable: node.expandable,
      name: node.name,
      level: level,
      item: node,
      checked: node.checked
    };
  }

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable);

  treeFlattenerAnimal = new MatTreeFlattener(

    this.transformerAnimal, node => {
      return node.level
    }, node => node.expandable, node => {
      node.children = node.animals
      return node.children
    });

  check(node: FlatNode, event) {
    if (!node.item)
      node.item = node
    this.updateSelected([node], event.checked)
  }

  updateSelected(nodes: FlatNode[], checked) {
    nodes.forEach(node => {

      if (node.item.type == NodeType.ANIMAL) {
        if (checked) {
          let index = this.selectedAnimals.indexOf(node.item);
          if (index < 0)
            this.selectedAnimals.push(node.item);
        } else {
          let index = this.selectedAnimals.indexOf(node.item);
          this.selectedAnimals.splice(index, 1);
        }
      }

    })
    this.createListId();
  }

  createListId() {
    this.listIdAnimals = [];
    this.selectedAnimals.forEach(element => {
      this.listIdAnimals.push(element.id);
    })

  }

  dataSourceAnimal = new MatTreeFlatDataSource(this.treeControl, this.treeFlattenerAnimal);

  apply() {
    this.appService.showLoading();
    /*this.startDate = moment(new Date(this.formGroup.controls['startDate'].value)).set({ minute: 0, second: 0, millisecond: 0 }).toISOString();
    this.endDate = moment(this.formGroup.controls['endDate'].value).toISOString();*/

    let listFarmers = []
    let listAnimals = []
    if (this.roleFarmer) {
      listFarmers.push(this.appService.connectedCustomer.id);
      (this.formGroup.controls['selectedAnimals'].value).filter(element => element != 0).forEach(element => {
        listAnimals.push(element.id)
      })
    }
    else {
      if (this.formGroup.controls['selectedFarmers'].value)
        listFarmers = (this.formGroup.controls['selectedFarmers'].value).filter(element => element != 0)
      let animals = this.checklistSelection['_selection']
      animals.forEach(elem => {
        if (!elem.item.animals)
          listAnimals.push(elem.item.id);
      })
    }

    this.reportService.exportReproductionReportAsCsv(listFarmers, listAnimals).subscribe(res => {
      let blob = new Blob([res], { type: 'application/vnd.ms-excel' });
      let type = ".xlsx"
      let filename = this.translate.instant('REPRODUCTION_REPORT')+ moment(new Date()).format('yyyy-MM-DD HH:mm')
      if (CapacitorUtils.isApp){
        CapacitorUtils.writeAndOpenFile(blob, filename + type);
        this.appService.hideLoading();
        let message = this.translate.instant('SAVE_FILE_SUCESS');
        this.alertsService.open(message);}
      else{
        this.appService.saveAs(blob, filename, type);
      this.appService.hideLoading();}
    }, error => {
      this.appService.error('CHECK_DATE')
      /*this.appService.hideLoading();
      let message = this.translate.instant(error.error.code);
      let alert = this.translate.instant(Alert.OK)
      this.alertsService.open(message, alert);*/
    })
  }




  checklistSelection = new SelectionModel<FlatNode>(true /* multiple */);


  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: FlatNode): void {

    let parent: FlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: FlatNode): void {

    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }
  getLevel = (node: FlatNode) => node.level;


  /* Get the parent node of a node */
  getParentNode(node: FlatNode): FlatNode | null {

    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }


  selectAll(): void {
    this.select = !this.select;
    this.treeControl.dataNodes.forEach((r, index) => {
      this.treeControl.expand(this.treeControl.dataNodes[index]);
      if (this.select)
        this.checklistSelection.select(this.treeControl.dataNodes[index])
      else {
        this.checklistSelection.deselect(this.treeControl.dataNodes[index]);
        this.checklistSelection.clear();

      }
    });
    this.isAllSelected = true;
  }


  loadAnimals() {

    let addressId

    addressId = this.appService.connectedCustomer.addresses[0].id;
    this.reportService.getListAnimalsForReproReport(addressId).subscribe(res => {
      if (res._embedded) {
        this.selectedAnimals = this.animals = res._embedded.animals;

      }
      else {
        this.selectedAnimals = this.animals = []
        let message = this.translate.instant("FARMER_NOT_HAVE_ANIMALS");
        let alert = this.translate.instant(Alert.OK)
        this.alertsService.open(message, alert);
      }
    }, error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
      this.alertsService.open(message, alert);
    })
  }
}


