import { Injectable } from '@angular/core';

export enum APP_TYPE{
	ADMIN, PUBLIC
}

@Injectable(
	{
		providedIn: 'root'
	}
)
export abstract class ConfigService {
	public abstract getServerTokenPath();
	public abstract getServerAuthorisation();
	public abstract getApiPath();
	public abstract getWebSocketPath();
	public getGatewayTimeout(){
		return 15000;
	}
	public abstract applicationType() : APP_TYPE;

}