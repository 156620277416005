
import { MoomeRestService, Options } from './moome-rest.service';

import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FarmerStockFeed } from '../resources/FarmerStockFeed';
import { map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FarmerStockFeedService extends MoomeRestService<FarmerStockFeed> {

    constructor(public http: HttpClient, private configService: ConfigService) {
        super(FarmerStockFeed);
    }

    findByFarmer() {
        let options: Options =
        {
            params: {
                projection: "farmer-stock-feed",
            }
        }
        return this.customRequestGet(`/findAllByFarmer`, null, options).pipe(
            map(res => { return res['_embedded']?.farmerStockFeeds }
            ))
    }

    findByFarmerByKey(key) {
        let options: Options =
        {
            params: {
                key: key,
                projection: "farmer-stock-feed",
            }
        }
        
        return this.customRequestGet(`/findAllByFarmerByKey`, null, options).pipe(
            map(res => { return res['_embedded']?.farmerStockFeeds }
            ))
    }

    saveFarmerStockFeed(body) {
		return this.http.post(this.configService.getApiPath() + `/farmerStockFeeds/save`, body);
    }
    update(body) {
		return this.http.post(this.configService.getApiPath() + `/farmerStockFeeds/update`, body);
    }
    findStockFeedByExisteDate(date){
        return this.http.get(this.configService.getApiPath() + `/farmerStockFeeds/findStockFeedByExisteDate?date=${date}`);
    }
    findTitleAndUnity(date){

        let options: Options =
        {
            params: {
                date:date,
                projection: "farmer-stock-feed",
            }
        }
        
        return this.customRequestGet(`/findTitleAndUnity`, null, options).pipe(
            map(res => { return res['_embedded']?.farmerStockFeeds }
            ))
    }
   getStockFeed(id){
        return this.http.get(this.configService.getApiPath() + `/farmerStockFeeds/getStock/${id}`);
    }
	 
}