import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { TimelineInformationComponent } from '../../timelines/timeline-information/timeline-information.component';
import { TimelineInformationModule } from '../../timelines/timeline-information/timeline-information.module';
import { HelpComponent } from './help.component';



@NgModule({
  declarations: [HelpComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MoomeCommonModule
  ],
  exports: [HelpComponent],

})
export class HelpModule { }
