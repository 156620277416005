<div class="style-bar w-100" ngxHideOnScroll [scrollingElementSelector]="'body'" [propertyUsedToHide]="'top'"
    [valueWhenHidden]="'-10px'" [valueWhenShown]="'93px'">
    <div class="row h-100 ">
        <div class="row h-100 ">
            <div class="col center-element" (click)="back()">
                <img src="/assets/images/left-arrow-1.svg" />
            </div>
            <div class="col center-element">
                <div class="row d-block">
                    <div class="col text-center"> <img src="/assets/images/Exclusion-1.svg" />
                    </div>
                    <div class="col text-center ps-0 pe-0 pt-1"> <span class="text-style"
                            [ngClass]="{rtl:appService.rtl()}">{{ 'ALL_ALERTS' | translate }}</span></div>
                </div>
            </div>
            <div class="col center-element">
            </div>
        </div>
    </div>
</div>

<div class="row px-4 pb-2 position" [getAuthorized]="authority">
    <app-alert-card *ngFor="let alertTypeGroup of alertTypeGroups" class="col-6 col-md-3" [alertTypeGroup]="alertTypeGroup" [getAuthorized]="alertTypeGroup.authority">
    </app-alert-card>
</div>