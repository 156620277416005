import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { Farmer } from "./Farmer";
@HateoasResource('farmerMilkProductions')
export class FarmerMilkProduction extends Resource {
    id: number;
    farmer: Farmer | any;
    date: string;
    quantity: string;
    sold: string;
    lost: string;
    consumed: string;
    calve : string;
    lr:string
    fat:string;
    snf:string;
    adjusted:string;
    soldAdjusted:string;

}