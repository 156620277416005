<app-section-bar [title]="title" (goBack)="back()" [color]="color"></app-section-bar>
<div class="row gx-1" (click)="appService.verif = false">
  <div class="col-6 mt-4 pt-4 " *ngFor="let element of list" [getAuthorized]="element.authority">
      <div class="{{element.class}} text-center" >
          
              <div class="col mb-2 back center-element mx-auto {{element.back}}">
           
              <img class=" position-icon"
                  src="{{element.img}}" (click)="pathUrl(element)" >
          </div>
         
          <div [ngClass]="{rtl:appService.rtl()}">{{ element.text | translate }}</div>
      </div>
  </div>
</div>
