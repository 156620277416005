import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { Farmer } from "./Farmer";
@HateoasResource('farmerMiscInventorys')
export class FarmerMiscInventory extends Resource {
    id: number;
    farmer: Farmer | any;
    date: string;
    quantity: string;
    title: string;
    unit:string;
}