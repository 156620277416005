<div class="card-supervisor-size ">
    <div class="row pe-4 ps-4 pt-3" [ngClass]="{rtl:appService.rtl()}">
        <div class="col-8 ps-0" [ngClass]="{rtl:appService.rtl()}">
            <div [ngClass]="{rtl:appService.rtl()}" class="ellipsis-text"><span
                    class=" font-name title-style">{{supervisor.fullname}}</span>
            </div>
            <div [ngClass]="{rtl:appService.rtl()}"><span
                    class="nbr-style-parametre title-style-parametre">{{supervisor.phone}}</span></div>
            <div [ngClass]="{rtl:appService.rtl()}">

                <section *ngFor="let role of supervisor.roles"><span
                        class="nbr-style-parametre title-style-parametre">{{role | codeTranslate}} </span></section>
            </div>
        </div>

        <div class="col-4 ps-2 pe-2 ">

            <div *ngIf="advisorMcc" class="min-card width-card-animals blue-color ">
                <div class="row  h-100 gx-1 px-1" [ngClass]="appService.rtl()? 'pt-1' : ''" (click)="openEntreprises()">
                    <div class="col-6 center-element">
                        <span><img src="/assets/images/CCL_icon_1blue.svg"></span>
                    </div>
                    <div class="col-6 center-element">
                        <span class="nbr-style-number">{{supervisor.entrepriseCount? supervisor.entrepriseCount:
                            "0"}}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="advisorFarmer" class="min-card width-card-animals blue-color ">
                <div class="row  h-100 gx-1 px-1" [ngClass]="appService.rtl()? 'pt-1' : ''"
                    (click)="openFarmers(supervisor.id)">
                    <div class="col-6 center-element">
                        <span><img src="/assets/images/farmer_icon_neutral_2 blue.svg"></span>
                    </div>
                    <div class="col-6 center-element">
                        <span class="nbr-style-number">{{supervisor.farmerCount? supervisor.farmerCount:
                            "0"}}</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>