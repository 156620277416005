import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { FlexLayoutModule } from '@angular/flex-layout';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { MaterialModule } from 'projects/public/src/app/material/material.module';
import { ActionLivestockSummaryComponent } from './action-livestock-summary.component';
import { CommonDialogComponent } from 'projects/publicV2/src/app/common-dialog/common-dialog.component';
import { RapportDialogModule } from 'projects/public/src/app/rapport-dialog/rapport-dialog.module';




@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        //    FlexLayoutModule,
        MoomeCommonModule,
        RapportDialogModule
    ],
    declarations: [
        ActionLivestockSummaryComponent,
    ],
    exports: [
        ActionLivestockSummaryComponent,
    ]
})
export class ActionLivestockSummaryModule { }   