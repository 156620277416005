import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationExtras } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'projects/shared/services/app.service';
import { CustomerLikeService } from 'projects/shared/services/customer-like.service';
import { CustomRouter } from '../../services/custom-router';
import { LinkPlayStoreDialogComponent } from './link-play-store-dialog/link-play-store-dialog.component';
@Component({
  selector: 'app-like',
  templateUrl: './like.component.html',
  styleUrls: ['./like.component.scss']
})
export class LikeComponent implements OnInit {
  customerId: any;
  likes:Boolean=false;
  constructor(public dialogRef: MatDialogRef<LikeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private likeService: CustomerLikeService, public dialog: MatDialog, public translate: TranslateService, public appService: AppService, public router: CustomRouter) { }

  ngOnInit(): void {
   }

  closePopup() {
    this.dialog.closeAll()
  }

  noUrl() {
    const navigationExtras: NavigationExtras = { state: { like: this.data.like[0].id } };
    this.likeService.updateLike(this.data.like[0].id, this.likes).subscribe(data => {
      this.router.navigateByUrl('/feedback', navigationExtras)
    })
  }

  yesUrl() {
    this.likes=true;
    this.likeService.updateLike(this.data.like[0].id, this.likes).subscribe(data => {
      const dialogRef = this.dialog.open(LinkPlayStoreDialogComponent, {
        width: '410px',
      });
    })
    this.dialog.closeAll()

  }

}
