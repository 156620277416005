import { Injectable, Injector } from '@angular/core';
import { MoomeRestService, Options } from './moome-rest.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Country } from '../resources/Country';

@Injectable({
	providedIn: 'root'
})
export class CountryService extends MoomeRestService<Country> {
	lang: string = this.translate.currentLang;

	constructor(injector: Injector, private translate: TranslateService, private http: HttpClient, private configService: ConfigService) {
		super(Country);
	}

	getCountriesWithFilter(page, size, field, direction, key) {
		let param: any=
	{
}	
		if (field != null)
		param.field=field;
		if (direction != null)
		param.direction=direction.toUpperCase();
		if (key != null)
		param.key=key.toLowerCase();
		

	let options: Options = {
		params: 
		   param,
		 pageParams:{
			size:size,
			page:page
		}
	}

		return this.searchPages(`getByKey`, options)
	}
	
}