import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ControlResult, Status, Step, AnimalType, Alert, PATTERN_UID_MARC, UID1_ANIMALS_MAR, UID2_ANIMALS_MAR, MAX_UID1, MAX_UID2, Authorities, FORMAT_MAX_MIN, PATTERN_UID_MAROC, MAX_REF, MIN_LENGTH_NAME_COW, MAX_LENGTH_NAME_COW, PATTER_NAME_COW, LivestockIdentif, PATTERN_UID_DIFFERENT, Roles } from 'projects/shared/tools/constants';
import { AppService } from 'projects/shared/services/app.service';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment'
import * as $ from "jquery";
import * as uuid from 'uuid';
import { Animal } from 'projects/shared/resources/Animal';
import { AnimalService } from 'projects/shared/services/animal.service';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { CommonDialogComponent } from '../../common-dialog/common-dialog.component';
import { TimelineChildService } from 'projects/shared/services/timeline-child.service';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { TimelineService } from 'projects/shared/services/timeline.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { ActivatedRoute } from '@angular/router';
import { CustomRouter } from '../../services/custom-router';
import { LocalNotificationService } from 'projects/shared/services/local-notification.service';
import { CustomerAlertService } from 'projects/shared/services/customer-alert.service';
import { Validator } from 'projects/shared/tools/validators';

@Component({
  selector: 'app-edit-timeline',
  templateUrl: './edit-timeline.component.html',
  styleUrls: ['./edit-timeline.component.scss']
})
export class EditTimelineComponent implements OnInit {
  suffixMinuscule
  title: String
  labelCheck: String
  labelDate: String
  formGroup: UntypedFormGroup;
  date = null;
  isAbort = false
  speciess: any = [];
  breeds: any;
  selectedSpecies: any = [];
  selectedBreeds: any = [];
  isStart = false;
  isIa = false
  //isControl = false
  Status = Status
  disabledButtonPositive: Boolean = true;
  disabledButtonNegative: Boolean = true;
  //selectedStatus: any
  isFirst = false;
  selectedAnimals: any = [];
  animals: any = [];
  MALE = "MALE"
  FEMALE = "FEMALE"
  suffix: string
  uidLength: number
  valUid = true
  valUid1 = true
  valUid2 = true
  maxUid1 = MAX_UID1
  maxUid2 = MAX_UID2
  B = UID2_ANIMALS_MAR
  patern = PATTERN_UID_MARC
  uid1Length = UID1_ANIMALS_MAR
  uidLengthTN = PATTERN_UID_DIFFERENT
  uidLengthMAR = PATTERN_UID_MAROC
  code: String;
  authorityAnimals = Authorities.ANIMALS;
  authorityTimelineChilds = Authorities.TIMELINE_CHILD;
  authorityBreeds = Authorities.BREEDS
  authoritySpecies = Authorities.SPECIES
  maxDate: any;
  minDate: any;
  valueEvent: boolean = false;
  animal
  calculDate: any;
  formatArabe: any
  listUidChild = []
  uidIni: any
  j = 0
  nameUid: any
  animalId: any;
  minLengthCowName = MIN_LENGTH_NAME_COW
  maxLengthCowName = MAX_LENGTH_NAME_COW
  patternCowName = PATTER_NAME_COW
  nationalIdentification
  nameLocalCode
  controlResult = ControlResult
  clickSub: any;
  farmer: any;
  role: Boolean = false
  auth: string[] = [];
  customerId: any
  valueSetting
  valueFinal
  nameUid1: any;
  nameUid2: any;
  fatherId = null;
  valueTypeUid: boolean = false;
  showAdd:boolean=true;
  constructor(public customerAlertService: CustomerAlertService, public localNotificationService: LocalNotificationService, public router: CustomRouter, private route: ActivatedRoute, public tokenManagementService: TokenManagementService, public dialogRef: MatDialogRef<EditTimelineComponent>, public dialog: MatDialog, public alertsService: AlertsService, public timelineService: TimelineService,
    @Inject(MAT_DIALOG_DATA) public data: any, public appService: AppService, public animalService: AnimalService, public codeTranslate: CodeTranslatePipe,
    private formBuilder: UntypedFormBuilder, public translate: TranslateService, public timelineChildService: TimelineChildService,) {

    this.code = data.animal.address.country.code
    this.suffix = data.animal.address.country.uidIndex;
    this.uidLength = data.animal.address.country.uidLength;
    this.animalId = data.timeline.animalId;
    // }
    /* else {
 this.code = this.appService.connectedCustomer.addresses[0].country.code;
 this.suffix = this.appService.connectedCustomer.addresses[0].country.uidIndex;
 this.uidLength = this.appService.connectedCustomer.addresses[0].country.uidLength;
     }})*/
    this.displayInfo()
    this.createForm()
    this.displayInfoIa();
    this.displayInfoAborrt();
    this.isFirst = this.data.timelineDetail.nbreDay == 0;
    this.disabledEnabledControl()

  }

  ngOnInit() {
    this.formatArabe = this.appService.verifFormatDate()
    this.maxDate = moment(new Date()).format(FORMAT_MAX_MIN);
    if (Step.checkTitleDry(this.title)){
      this.maxDate = moment(this.data.dateCalve).format(FORMAT_MAX_MIN);
    this.showAdd=false}

    this.calculMinDate()
    //this.setFormControl()
    if (this.formGroup.controls['date'].value != null)
      this.fixArabeFormat()

    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1) {

      this.role = true
      this.customerId = this.route.snapshot.paramMap.get('idFarmer');


    } else { this.role = false }
    this.onWindowResize()
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.valueTypeUid = this.appService.onWindowResize()
  }

  calculMinDate() {
    var x = 12;
    var CurrentDate = new Date(this.data.birthDate);
    this.calculDate = CurrentDate.setMonth(CurrentDate.getMonth() + x);
    this.minDate = moment(new Date(this.calculDate)).format(FORMAT_MAX_MIN);
    if (Step.checkTitleDry(this.title))
      this.minDate = moment(this.data.minDate).format(FORMAT_MAX_MIN);
  }

  valueUidIni(child) {
    this.uidIni = child.uidIni
    return this.uidIni
  }

  /* addValueUid(i) {
     let valueUidChild
     this.listUidChild.push("uidIni" + i)
     for (let i = 0; i < this.listUidChild.length; i++) {
       valueUidChild = ((document.getElementById(this.listUidChild[i]) as HTMLFormElement).value.toString())
     };
   }*/

  message(msg) {
    this.appService.hideLoading();
    let message = this.translate.instant(msg);
    let alert = this.translate.instant(Alert.OK)
    this.alertsService.open(message, alert);
  }

  displayInfo() {
    this.date = this.data.timelineDetail.date
    this.title = Step.getTitle(this.data.timelineDetail.status, this.data.timelineDetail.label, this.translate);
    this.labelCheck = Step.getLabelCheck(this.data.timelineDetail.status, this.data.timelineDetail.label, this.translate);
    this.labelDate = Step.getLabelDate(this.data.timelineDetail.status, this.data.timelineDetail.label, this.translate);

    if (Step.isXCalving(this.data.timelineDetail.status))
      this.isAbort = true;

    if (Step.isIa(this.data.timelineDetail.status))
      this.isIa = true

    /*if (Step.isControl(this.data.timelineDetail.status))
       this.isControl = true*/

    if (this.data.timelineDetail.status == Status.X_NO_ACCESSIBLE) {
      this.date = null;
    }
  }

  createForm() {
    if (Step.isStart(this.data.timelineDetail.status)) {
      this.isStart = true;
    }
    this.formGroup = this.formBuilder.group({
      'isCheck': [this.date != null],
      'date': [this.date ? (moment(this.date).format(this.appService.verifFormatDate())) : (moment(new Date()).format(this.appService.verifFormatDate()))],
      'addCow': [this.ifAddCow()],
      'counterLact': [false],
      'animalId': [this.data.timeline.animalId],
      'timelineId': [this.data.timeline.id],
      'gestationControl': [Step.isIaD(this.data.timelineDetail.status)],
      'type_insemination': ['ARTIFICIAL_INSEMINATION'],
      'father': [null],
      'externalFather': [null],
      'insemination_ref': [null],
      'childs': this.formBuilder.array([]),
      'controlResult': [Step.controlResult(this.data.timelineDetail.status)],
      'status': [this.data.timelineDetail.status]
    })

    this.displayChildrens()

  }

  setFormControl() {
    if (this.formGroup.controls['father'].value) {
      //this.animals.filter(animal => this.formGroup.controls['father'].value == animal.uid)
      if ((this.animals.filter(animal => (this.formGroup.controls['father'].value == animal.uid) || (this.formGroup.controls['father'].value === animal.name))).length == 0) {
        var re = new RegExp(this.uidLengthTN);
        let lengthUidTN = re.test(this.formGroup.controls['father'].value)
        var ve = new RegExp(this.uidLengthMAR)
        let lengthUidMAR = ve.test(this.formGroup.controls['father'].value)
        if (((this.suffix != "MAR") && (lengthUidTN) && (((document.getElementById("father") as HTMLFormElement).value.toString().length == (this.uidLength)))) || ((this.suffix == "MAR") && (lengthUidMAR))) {
          this.formGroup.controls['externalFather'].setValue(this.suffix + this.formGroup.controls['father'].value)
          this.formGroup.controls['father'].setValue(null)
        }
        else {
          this.formGroup.controls['externalFather'].setValue(this.formGroup.controls['father'].value)
          this.formGroup.controls['father'].setValue(null)
        }
      }

      else {
        let animal = (this.animals.find((a) => a.uid == this.formGroup.controls['father'].value || a.name == this.formGroup.controls['father'].value));
        this.formGroup.controls['externalFather'].setValue(null)
        this.fatherId = animal.id

      }
    }
    else {
      this.formGroup.controls['externalFather'].setValue(null)
      this.fatherId = null
    }
    //this.formGroup.controls['father'].setValue(animal.id)


  }

  verifLengthInsemRef() {
    if ((document.getElementById("insemination_ref") as HTMLFormElement).value.toString().length > MAX_REF) {
      this.formGroup.setErrors({ 'invalid': true });
      let msg = this.translate.instant('REF_LENGTH') + " " + MAX_REF;
      let alert = this.translate.instant(Alert.OK)
      this.formGroup.controls['insemination_ref'].setValue(this.formGroup.controls['insemination_ref'].value.substr(0, MAX_REF));
      return this.alertsService.open(msg, alert);
    }
  }

  messageAlert(length) {
    this.formGroup.setErrors({ 'invalid': true });
    let msg = this.translate.instant('LENGTH_UID') + " " + length;
    let alert = this.translate.instant(Alert.OK)
    return this.alertsService.open(msg, alert);
  }

  verifLengthInsemFather() {

    if (((document.getElementById("father") as HTMLFormElement).value.toString().toUpperCase().startsWith(this.suffix))) {
      if (((document.getElementById("father") as HTMLFormElement).value.toString().length > (this.uidLength + this.suffix.length)) && (this.suffix != "MAR")) {
        this.formGroup.controls['father'].setValue(this.formGroup.controls['father'].value.substr(0, (this.uidLength + this.suffix.length)));

        this.messageAlert((this.uidLength));
      }
      else if ((((document.getElementById("father") as HTMLFormElement).value.toString().length > (this.uidLength + this.suffix.length + 1)) && (this.suffix == "MAR"))) {
        this.formGroup.controls['father'].setValue(this.formGroup.controls['father'].value.substr(0, (this.uidLength + this.suffix.length + 1)));
        this.messageAlert((this.uidLength));
      }

    }
    else {
      if (((document.getElementById("father") as HTMLFormElement).value.toString().length > this.uidLength + 1) && (this.suffix == "MAR")) {
        this.formGroup.controls['father'].setValue(this.formGroup.controls['father'].value.substr(0, this.uidLength + 1));
        this.messageAlert(this.uidLength);
      }
      else if (((document.getElementById("father") as HTMLFormElement).value.toString().length > this.uidLength) && (this.suffix != "MAR")) {
        this.formGroup.controls['father'].setValue(this.formGroup.controls['father'].value.substr(0, this.uidLength));
        this.messageAlert(this.uidLength);
      }
    }
  }

  ifAddCow() {
    if (this.data.timeline.lastTimeline && this.data.timelineDetail.status == Status.GIVE_BIRTH && this.data.timeline.lastTimeline.timelineChilds.length > 0) {
      return true
    } else if (this.data.timeline.timelineChilds.length > 0 && this.data.timelineDetail.status == Status.GIVE_BIRTH_END_CONFIRMED) {
      return true
    } else {
      return false
    }
  }

  displayChildrens() {
    //this.fixArabeFormat()

    if (this.formGroup.controls['addCow'].value)
      this.loadSpecies()
    if (this.data.timeline.lastTimeline && this.data.timelineDetail.status == Status.GIVE_BIRTH && this.data.timeline.lastTimeline.timelineChilds.length > 0) {
      this.data.timeline.lastTimeline.timelineChilds.forEach(child => {
        this.j = this.j + 1
        this.childs.push(this.newChild(child, this.j))
      });
    } else if (this.data.timeline.timelineChilds.length > 0 && this.data.timelineDetail.status == Status.GIVE_BIRTH_END_CONFIRMED) {
      this.data.timeline.timelineChilds.forEach(child => {
        this.j = this.j + 1
        this.childs.push(this.newChild(child, this.j))
      });

    } else {
      this.loadSpecies()
      setTimeout(() => {
        this.addChilds()
      }, 1000);
    }
  }

  isAddCow(event) {
    if (event.checked == true)
      this.loadSpecies()
  }

  loadSpecies() {
    let lang: string = this.translate.currentLang;
    if (lang == "rw")
      lang = "en"
    let field = 'animalType_label' + lang.charAt(0).toUpperCase() + lang.slice(1);
    this.animalService.getSpecies(field).subscribe(res => {
      this.selectedSpecies = this.speciess = res['resources']
      this.speciess = res['resources'].filter(element => element.code == AnimalType.COW);
      this.loadBreeds(this.speciess[0])
    })
  }

  setValueSpecies(value, child) {
    child.get('breedId').setValue(null);
    this.loadBreeds(value)
  }

  loadBreeds(value) {
    this.animalService.getBreeds(value.id).subscribe(res => {
      this.selectedBreeds = this.breeds = res['resources'];
    });
  }

  get childs(): UntypedFormArray {
    return this.formGroup.get("childs") as UntypedFormArray
  }

  newChild(child?, j?): UntypedFormGroup {

    return this.formBuilder.group({
      'uidIni': [(child && this.code != ' MAR') ? (child.uid ? child.uid.substring(this.suffix.length) : null) : null, [Validator.validUidPattern(this.alertsService, this.translate, this.uidLength, j), Validator.validUidLength(this.translate, this.uidLength, this.alertsService, j)]],
      'uid1Ini': [(child && this.code == 'MAR') ? (child.uid ? child.uid.substr(this.suffix.length, this.uid1Length) : null) : null, [Validator.validUid1Pattern(this.alertsService, this.translate, j), Validator.validUid1Length(this.translate, this.maxUid1, this.alertsService, j)]],
      'uid2Ini': [(child && this.code == 'MAR') ? (child.uid ? child.uid.substr(this.suffix.length + this.uid1Length + this.B.length) : null) : null, [Validator.validUid2Pattern(this.alertsService, this.translate, j), Validator.validUid2Length(this.translate, this.maxUid2, this.alertsService, j)]],
      'code': [child ? child.code : null, [Validators.min(1)]],
      'species': [child && child.animalType ? child.animalType.id : null],
      'breedId': [child && child.breed ? child.breed.id : null],
      'sexe': [child ? child.sexe : null],
      'lactNum': [child ? child.lactNum : null],
      'uuid': [child ? child.uuid : uuid.v4()],
      'id': [child ? child.id : null],
      'timelineId': [child ? child.timelineId : null],
      'nameUid': ["uid" + j],
      'nameUid1': ["uid1" + j],
      'nameUid2': ["uid2" + j],
      'name': [child ? child.name : null,
      [
        Validators.minLength(MIN_LENGTH_NAME_COW),
        Validators.pattern(PATTER_NAME_COW),
        Validator.validNameLength(this.translate, this.maxLengthCowName, this.alertsService, j)]],
      'nameCow': ["name" + j],
    })

  }

  searchLabel(list, value: string) {
    let filter = value.toLowerCase();
    return list.filter(option => this.codeTranslate.transform(option).toLowerCase().startsWith(filter));
  }

  onKeySpecies(value) {
    this.selectedSpecies = this.searchLabel(this.speciess, value);
  }

  onKeyBreed(value) {
    this.selectedBreeds = this.searchLabel(this.breeds, value)
  }

  addChilds() {
    this.j = this.j + 1
    this.childs.push(this.newChild(null, this.j));
  }

  removeChilds(i: number, child) {
    if (child.id)
      this.deleteChild(child, i)
    else this.childs.removeAt(i);
  }

  deleteChild(animal, i) {
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      width: '350px',
      data: {
        message: this.translate.instant('CONFIRM_DELETE_ANIMAL'),
        title: this.translate.instant('DELETE_ANIMAL'),
        onConfirm: () => {
          this.appService.showLoading();
          this.timelineChildService.deleteChild(animal.timelineId, animal.id).subscribe(data => {

            animal._links = { self: { href: location.origin + "/api/animals" + "/" + animal.id } }
            this.deleteAnimal(animal, i)
          });
        }
      }
    });
  }

  deleteAnimal(animal, i) {
    this.animalService.delete(animal).subscribe(data => {
      this.childs.removeAt(i);
      this.done(this.translate.instant('DELETED'))
      if (this.childs.length == 0)
        this.formGroup.controls['addCow'].setValue(false)
    }
      , error => {
        this.error(error.error.error)
      });
  }

  onChange(event) {
    if (event.checked == true) {
      this.formGroup.controls['date'].enable();
      if (this.data.timelineDetail.date && Step.isIaD(this.data.timelineDetail.status)) {
        this.disabledButtonPositive = false
        this.disabledButtonNegative = false;
        this.formGroup.controls['gestationControl'].enable();
      }
    } else {
      this.formGroup.controls['date'].disable();
      this.formGroup.controls['gestationControl'].disable();
      this.disabledButtonPositive = true
      this.disabledButtonNegative = true
    }
  }

  onChangeG(event) {

    if (event.checked == true) {
      this.disabledButtonPositive = false
      this.disabledButtonNegative = false
    }
    else {
      this.disabledButtonPositive = true
      this.disabledButtonNegative = true
      this.formGroup.controls['controlResult'].setValue(null)
    }
  }

  onChangeIA(event) {
    //this.fixArabeFormat()
    this.formGroup.controls['father'].setValue(null);
    if (event.value == 'NATURAL_INSEMINATION')
      this.loadAnimals();
  }

  fixArabeFormat() {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD/MMM/YYYY"
  }

  displayInfoIa() {
    ///this.fixArabeFormat()
    if (Step.displayInfoIa(this.data.timelineDetail.status)) {
      if (this.data.timelineDetail.extraParameters) {
        let id = $.parseJSON(this.data.timelineDetail.extraParameters).father
        if (id) {
          this.animalService.getAnimalById(id, "animal-details").subscribe(res => {
            let father = res.uid
            if (!father)
              father = res.name
            if (father) {
              this.formGroup.controls['father'].setValue(father);
              this.loadAnimals();
            }
          })
        }
        let reference = $.parseJSON(this.data.timelineDetail.extraParameters).reference
        let externalFather = $.parseJSON(this.data.timelineDetail.extraParameters).externalFather
        let typeInsemination = $.parseJSON(this.data.timelineDetail.extraParameters).typeInsemination


        if (reference == "null") {
          this.formGroup.controls['insemination_ref'].setValue('');
        }
        else {
          this.formGroup.controls['insemination_ref'].setValue(reference);
        }

        if (externalFather) {
          this.formGroup.controls['father'].setValue(externalFather);
          this.loadAnimals();
        }
        if (externalFather == "null")
          this.formGroup.controls['father'].setValue('');

        if (typeInsemination) {
          this.formGroup.controls['type_insemination'].setValue(typeInsemination)
        }
        //})
      }


    }
  }

  displayInfoAborrt() {
    // this.fixArabeFormat()
    if (Step.displayInfoAbort(this.data.timelineDetail.status)) {
      if (this.data.timelineDetail.extraParameters) {
        let resetLact = JSON.parse($.parseJSON(this.data.timelineDetail.extraParameters).resetLact)
        if (resetLact)
          this.formGroup.controls['counterLact'].setValue(resetLact);
      }
    }
  }

  loadAnimals() {
    if (this.role)
      this.farmer = this.data.farmerId;
    this.animalService.getAnimalsOfFarmersBySexe(this.farmer, this.MALE).subscribe(data => {
      this.animals = this.selectedAnimals = data['resources']
    },
      error => {
        this.error(error.error.error)
      });
  }

  search(value, list, field) {
    let filter = value.toLowerCase();
    return list.filter(option => option[field].toLowerCase().includes(filter));
  }

  onKeyAnimals(value) {
    this.selectedAnimals = this.search(value, this.animals, 'uid');
  }

  disabledEnabledControl() {

    //this.fixArabeFormat()

    // if (this.isControl) {
    if (this.isIa) {
      this.formGroup.controls['gestationControl'].disable();
      this.disabledButtonPositive = true
      this.disabledButtonNegative = true
      if (this.data.timelineDetail.date && Step.isIaD(this.data.timelineDetail.status)) {
        this.formGroup.controls['gestationControl'].enable();
        this.disabledButtonPositive = false
        this.disabledButtonNegative = false
      }

      if (!this.formGroup.controls['isCheck'].value) {
        this.formGroup.controls['date'].disable();
      }
    }
  }

  iaStatus(value, event: MouseEvent) {
    event.preventDefault()
    this.formGroup.controls['controlResult'].setValue(value)
  }

  confirm() {
    // this.fixArabeFormat()
    let message: string;
    let timelineDto = this.formGroup.value
    timelineDto.timelineDetailId = this.data.timelineDetail.id
    timelineDto.date = moment(this.formGroup.controls['date'].value).toDate()
    var endPoint = this.timelineService.updateTimeline
    if (!this.formGroup.value.isCheck && !this.date) {
      return this.dialogRef.close();
    }

    if (this.formGroup.controls['addCow'].value) {

      let hasSex = false;
      let hasBreed = false;
      let hasMin = false;
      let hasMax = false;
      let hasPattern = false;
      let hasName = false;
      let verifUid = 0
      let verifUidMaroc = 0

      this.formGroup.controls['childs'].value.forEach(child => {
        if (this.code != "MAR") {
          this.nameUid = ((document.getElementById(child.nameUid) as HTMLFormElement).value)
          let uid = this.nameUid

          if (uid && this.code != "MAR") {
            if (uid.toString().length > this.uidLength || uid.toString().length < this.uidLength)
              verifUid += 1
            child.uid = this.suffix + uid;
          }
        }
        else if (this.code == "MAR") {
          this.nameUid1 = ((document.getElementById(child.nameUid1) as HTMLFormElement).value)
          this.nameUid2 = ((document.getElementById(child.nameUid2) as HTMLFormElement).value)
          let uid1 = this.nameUid1
          let uid2 = this.nameUid2
          if (uid1 || uid2) {
            child.uid = this.suffix + uid1 + this.B + uid2;
            var re = new RegExp(this.patern);
            if (!re.test(child.uid))
              verifUidMaroc += 1
          }
        }
        else
          delete child.uid;

        if (child['breedId'] == null)
          hasBreed = true

        if (child['sexe'] == null)
          hasSex = true
        if (((child['name'] != null) && (child['name'] != "")) && (child['name'].length < this.minLengthCowName))
          hasMin = true
        if (((child['name'] != null) && (child['name'] != "")) && (child['name'].length > this.maxLengthCowName))
          hasMax = true
        if (((!(child['uid'])) && (!(child['name'])) && (this.code != "MAR")) || ((!(child['uid1Ini'])) && (!(child['uid2Ini'])) && (!(child['name'])) && (this.code == "MAR")))
          hasName = true

        var patternName = new RegExp(this.patternCowName);
        if (((child['name'] != null) && (child['name'] != "")) && (!patternName.test(child['name'])))
          hasPattern = true
        if (child['name'] == "")
          child['name'] = null
      })

      if (hasMin) {
        message = (this.translate.instant('NAME_COW_MIN') + " " + this.minLengthCowName.toString() + " " + this.translate.instant('CARACTERE') + " ")
        let alert = this.translate.instant(Alert.OK)
        return this.alertsService.open(message, alert);
      }

      if (hasMax) {
        message = (this.translate.instant('COWS_NAME') + " " + this.maxLengthCowName.toString() + " " + this.translate.instant('CARACTERE_ONLY') + " ")
        let alert = this.translate.instant(Alert.OK)
        return this.alertsService.open(message, alert);
      }

      if (hasPattern) {
        message = this.translate.instant('COW_PATTERN_NAME');
        let alert = this.translate.instant(Alert.OK)
        return this.alertsService.open(message, alert);
      }

      if (hasSex) {
        message = this.translate.instant('SEX_REQUIRED');
        let alert = this.translate.instant(Alert.OK)
        return this.alertsService.open(message, alert);
      }

      if (hasBreed) {
        message = this.translate.instant('BREED_REQUIRED');
        let alert = this.translate.instant(Alert.OK)
        return this.alertsService.open(message, alert);
      }

      if (hasName) {
        message = this.translate.instant('UID_NAME');
        let alert = this.translate.instant(Alert.OK)
        return this.alertsService.open(message, alert);
      }


      if ((verifUid != 0) && (this.nameUid.length < this.uidLength)) {
        let message = this.translate.instant('UID_LENGTH') + " ";
        let alert = this.translate.instant(Alert.OK)
        return this.alertsService.open(message, alert);
      }
      /* if ((verifUid != 0)&&(this.nameUid.length>this.uidLength)) {
         let msg = this.translate.instant('UID_LENGTH_MIN') + " "+this.uidLength.toString() ;
         let alert = this.translate.instant(Alert.OK)
         return this.alertsService.open(msg, alert);
       }*/

      if (verifUidMaroc != 0) {
        let message = this.translate.instant('UID_LENGTH');
        let alert = this.translate.instant(Alert.OK)
        return this.alertsService.open(message, alert);
      }
    }

    // if ((!this.formGroup.value.isCheck && !this.isIa && !this.isControl && !this.isAbort && this.data.timelineDetail.status !== Status.GIVE_BIRTH_END_CONFIRMED) || (!this.formGroup.value.isCheck && this.isIa && this.isFirst)) {
    if ((!this.formGroup.value.isCheck && !this.isIa && !this.isAbort && this.data.timelineDetail.status !== Status.GIVE_BIRTH_END_CONFIRMED) || (!this.formGroup.value.isCheck && this.isIa && this.isFirst)) {
      return this.confirmDeletion(this.data.timeline)
    }


    if (this.formGroup.value.isCheck && timelineDto.date == null) {
      message = this.translate.instant('DATE_REQUIRED');
      let alert = this.translate.instant(Alert.OK)
      return this.alertsService.open(message, alert);
    }
    // if (!this.formGroup.value.isCheck && this.isControl) {
    if (!this.formGroup.value.isCheck && this.isIa) {
      timelineDto.controlResult = null;
    }

    if ((this.valueEvent == true) || ((this.formGroup.value.isCheck) && (timelineDto.date > this.maxDate))) {
      message = this.translate.instant('FORM_DATE');
      let alert = this.translate.instant(Alert.OK)
      return this.alertsService.open(message, alert);
    }

    /* if ((!this.formGroup.value.isCheck && this.isIa)) {
         timelineDto.status = Status.IA_START
         return this.confirmDeletion(timelineDto, 'updateControlTimeline')
     }*/

    if ((!this.formGroup.value.isCheck && !this.isIa && this.isAbort)) {
      return this.confirmDeletion(timelineDto, 'deleteAbortimeline')
    }

    if ((!this.formGroup.value.isCheck && !this.isIa && !this.isAbort && this.data.timelineDetail.status === Status.GIVE_BIRTH_END_CONFIRMED)) {
      return this.confirmDeletion(timelineDto, 'deleteGiveBirthEndConfirmed')
    }

    let isC0 = true
    let isC1 = true
    let isC2 = true

    if (Step.isC0(this.data.timelineDetail.status))
      isC0 = (moment(this.data.maxDateIA).add(18, 'days')).isBefore(this.formGroup.value.date)
    if (Step.isC1(this.data.timelineDetail.status))
      isC1 = (moment(this.data.maxDateIA).add(25, 'days')).isBefore(this.formGroup.value.date)
    if (Step.isC2(this.data.timelineDetail.status))
      isC2 = (moment(this.data.maxDateIA).add(60, 'days')).isBefore(this.formGroup.value.date)

    let dateToday = (moment(this.maxDate).isBefore(this.formGroup.value.date))
    let dateMin = (moment(this.data.minDate).isAfter(this.formGroup.value.date))

    let dateLast = (moment(this.data.lastDate).isAfter(this.formGroup.value.date))
    let dateAbort = false
    let dateAbortAfterCalving = false
    if (Step.isXCalving(this.data.timelineDetail.status)) {

      let moments = []
      moments = this.data.timeline.timelineDetails.filter(
        detail => Step.isC0(detail.status)).map(function (detail) {
          return moment(detail.date);
        });

      dateAbortAfterCalving = (moment(this.data.dateCalve).add(30, 'days')).isBefore(this.formGroup.value.date)
      let maxDateC2 = moment.max(moments)
      if (moments.length > 0) {
        dateAbort = (maxDateC2.isAfter(this.formGroup.value.date))
        dateLast = false
      }
    }

    if ((this.data.minDate && dateMin) || (this.data.lastDate && dateLast) || (dateToday) || (dateAbort) || (!isC0) || (!isC1) || (!isC2) || (dateAbortAfterCalving))
      return this.error(Step.messageValid(this.data.timelineDetail.status, this.translate, this.data.minLabel, dateToday, dateAbort, isC0, isC1, isC2, dateLast, dateAbort, dateAbortAfterCalving))

    timelineDto.timelineDetailId = this.data.timelineDetail.id


    if (this.formGroup.controls['type_insemination'].value == 'ARTIFICIAL_INSEMINATION' && this.isIa) {

      // if (this.formGroup.controls['insemination_ref'].value)
      timelineDto.extraParameters = '{"reference":"' + this.formGroup.controls['insemination_ref'].value + '"' + ',' + '"typeInsemination":' + '"ARTIFICIAL_INSEMINATION"' + ' }'
      /*else {
        message = this.translate.instant('CHOOSE_REFERENCE');
        let alert = this.translate.instant(Alert.OK)
        return this.alertsService.open(message, alert);
      }*/

    } else if (this.formGroup.controls['type_insemination'].value == 'NATURAL_INSEMINATION' && this.isIa) {
      this.setFormControl()
      //if (this.formGroup.controls['father'].value != null) {
      if (this.fatherId) {
        timelineDto.extraParameters = '{"father":' + this.fatherId + '' + "," + '"typeInsemination":' + '"NATURAL_INSEMINATION"' + '}'

        // timelineDto.extraParameters = '{"father":' + this.formGroup.controls['father'].value + '' + "," + '"typeInsemination":' + '"NATURAL_INSEMINATION"' + '}'
      } else {
        timelineDto.extraParameters = '{"externalFather":"' + this.formGroup.controls['externalFather'].value + '"' + "," + '"typeInsemination":' + '"NATURAL_INSEMINATION"' + '}'
        timelineDto.externalFather = timelineDto.father
        timelineDto.father = null
      }
      /* else {
         message = this.translate.instant('CHOOSE_FATHER');
         let alert = this.translate.instant(Alert.OK)
         return this.alertsService.open(message, alert);
       }*/
    }


    if (this.isAbort)
      timelineDto.extraParameters = '{"resetLact":"' + this.formGroup.controls['counterLact'].value + '" }'


    if (this.formGroup.controls['gestationControl'].value) {
      if (!this.formGroup.controls['controlResult'].value) {
        message = this.translate.instant('CHOOSE_RESULT_PREGANCY');
        let alert = this.translate.instant(Alert.OK)
        return this.alertsService.open(message, alert);
      }
      /*else
        timelineDto.status = this.selectedStatus*/
    }


    if (!this.formGroup.value.isCheck && this.isIa) {
      let list = this.data.timeline.timelineDetails.filter(detail =>
        moment(detail.date).isBefore(timelineDto.date))

      let status = list[list.length - 1].status
      if (Step.checkContolNegative(status))
        timelineDto.controlResult = ControlResult.NEGATIVE
      if (Step.checkContolPositive(status))
        timelineDto.controlResult = ControlResult.POSITIVE


      if (Step.checkContolNegative(status) || Step.checkContolPositive(status)) {
        timelineDto.status = status
        timelineDto.timelineDetailId = list[list.length - 1].id
        timelineDto.date = list[list.length - 1].date
      }
      if (status == Status.GIVE_BIRTH)
        timelineDto.status = Status.IA_START
    }

    if (this.data.timelineDetail.status === Status.GIVE_BIRTH_END) {
      endPoint = this.timelineService.endTimeline;
    } else if (this.data.timelineDetail.status === Status.GIVE_BIRTH_END_CONFIRMED) {
      timelineDto.status = Status.GIVE_BIRTH_END_CONFIRMED
      endPoint = this.timelineService.updateOldEndTimeline;
    }
    else if (this.data.timelineDetail.status === Status.X_NO_ACCESSIBLE) {
      endPoint = this.timelineService.abortTimeline;
    } else if (this.data.timelineDetail.status === Status.X_CALVING) {
      timelineDto.status = Status.X_CALVING
      endPoint = this.timelineService.updateAbortTimeline;
    } else if (this.data.timelineDetail.status === Status.X_ACCESSIBLE) {
      endPoint = this.timelineService.updateAbortOldTimeline;
    }
    else if (this.data.timelineDetail.status === Status.V1_NO_ACCESSIBLE) {
      timelineDto.status = Status.GIVE_BIRTH
      endPoint = this.timelineService.createTimeline;
    } else if (this.data.timelineDetail.status === Status.GIVE_BIRTH) {
      timelineDto.status = Status.GIVE_BIRTH
      endPoint = this.timelineService.updateStartTimeline;
    } else if (this.data.timelineDetail.status == Status.IA_WAITING) {
      timelineDto.status = Status.IA_DONE
      endPoint = this.timelineService.createTimeline
    } else if (this.data.timelineDetail.status == Status.IA_START) {
      timelineDto.status = Status.IA_DONE
    } else if (timelineDto.date == null)
      timelineDto.status = Status.IA_WAITING;
    else if (!this.formGroup.value.gestationControl && this.formGroup.value.isCheck && this.data.timelineDetail.status != Status.T_REAL)
      timelineDto.status = Status.IA_DONE
    else if (!this.formGroup.value.isCheck)
      timelineDto.status = Status.IA_START
    else if (this.data.timelineDetail.status == Status.T_REAL) {
      timelineDto.status = Status.T_REAL
      endPoint = this.timelineService.updateTimelineDetail;
    }
    this.appService.showLoading()

    endPoint(timelineDto).subscribe(data => {
      this.done(this.translate.instant('SAVE_SUCESS'))
    }, error => {
      this.appService.messageMaxCow(error.error.code);
    })

  }

  confirmDeletion(timeline, method?) {
    let title = this.translate.instant('WARNING')
    let message1 = this.translate.instant('CONFIRM_DELETION1')
    let message2 = this.translate.instant('CONFIRM_DELETION2')

    if (!method)
      method = 'delete'

    const dialogRef = this.dialog.open(CommonDialogComponent, {
      width: '350px',
      data: {
        message1,
        title,
        onConfirm: () => {
          const dialogRef = this.dialog.open(CommonDialogComponent, {
            width: '350px',
            data: {
              message2,
              title,
              onConfirm: () => {
                this.appService.showLoading()
                this.timelineService[method](timeline).subscribe(data => {
                  this.done(this.translate.instant('DELETED'))
                }
                  , error => {
                    this.error(error.error.error)
                  });
              }
            }
          });
        }
      }
    });
  }

  done(message) {
    this.appService.hideLoading();
    this.data.reloadData();
    this.dialogRef.close();
    this.alertsService.open(message);
    setTimeout(() => {
      this.customerAlertService.sendNotif(this.animalId).subscribe()
    }, 3000);
  }

  error(message) {
    this.appService.hideLoading();
    let alert = this.translate.instant(Alert.OK)
    this.alertsService.open(this.translate.instant(message), alert);
  }

  changeSex(value, child) {
    if (value == this.FEMALE) {
      child.controls['lactNum'].setValue(0);
    } else {
      child.controls['lactNum'].setValue(null);
    }
    child.controls['sexe'].setValue(value);
  }
}