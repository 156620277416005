import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppService } from 'projects/shared/services/app.service';
import { CustomRouter } from '../../services/custom-router';
import { EditTimelineComponent } from '../../timelines/edit-timeline/edit-timeline.component';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  @Input() help: any
  @Output() reclose = new EventEmitter();
  constructor(public appService: AppService, public router: CustomRouter, public dialogRef: MatDialogRef<EditTimelineComponent>,) { }

  ngOnInit(): void {


  }
  openLink(url: string) {
    window.open(url, "_blank");
  }
  closePopUp() {
    let currentRoute = this.router.url;
    if (currentRoute.indexOf('help') != -1)
      this.router.back('')
    else this.reclose.emit();
   
  }
}
