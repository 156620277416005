<button mat-raised-button class='button-style' [matMenuTriggerFor]="menu">
    <mat-icon class="style-icon">settings</mat-icon><span class="style-span">{{ 'SETTINGS' | translate}}</span>
</button>
<mat-menu #menu="matMenu">
    <!--<button *ngIf="get && summary" mat-menu-item (click)="exportAnimalsSummary()">
        <i class="material-icons style-icon-notif pointer">get_app</i>
        <span class="style-align">{{ 'EXPORT_LIVESTOCK_SUMMARY' | translate}}</span>
    </button>-->
    <button *ngIf="get" mat-menu-item (click)="openDialog()">
            <i class="material-icons style-icon-notif pointer">get_app</i>
            <span class="style-align">{{ (summary? 'EXPORT_LIVESTOCK_SUMMARY' : 'EXPORT_ALL_LIVESTOCK') | translate}}</span>
        </button>
   <!--<button *ngIf="get && all" mat-menu-item (click)="exportAllAnimals()">
        <i class="material-icons style-icon-notif pointer">get_app</i>
        <span class="style-align">{{ 'EXPORT_ALL_LIVESTOCK' | translate}}</span>
    </button>-->
    <button *ngIf="del" mat-menu-item (click)="deleteAnimals()">
        <i class="material-icons style-icon-notif pointer">delete</i>
        <span class="style-align">{{ 'DELETE' | translate}}</span>
    </button>
</mat-menu>