import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoAnimalsComponent } from './no-animals.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [NoAnimalsComponent],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    NoAnimalsComponent,
  ],
})
export class NoAnimalsModule { }
