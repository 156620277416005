import { Injectable, NgZone } from '@angular/core';
import {
  PushNotifications,
  Token,
  ActionPerformed,
  PushNotificationSchema,
} from '@capacitor/push-notifications';
import { CustomRouter } from 'projects/publicV2/src/app/services/custom-router';
import { AnimalService } from './animal.service';
import { Sexe, TypeAlert } from '../tools/constants';
import { TokenManagementService } from './token-management.service';
import * as moment from "moment"
import { AppService } from './app.service';
import { LocalNotificationService } from './local-notification.service';
import { FarmerService } from './farmer.service';
import { Capacitor } from '@capacitor/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestDialogComponent } from 'projects/publicV2/src/app/request-dialog/request-dialog.component';
import { LocalNotifications } from '@capacitor/local-notifications';
const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  constructor(public localNotificationService: LocalNotificationService, private router: CustomRouter, private animalService: AnimalService,
    public tokenManagementService: TokenManagementService, private ngZone: NgZone,
    public appService: AppService, public farmerService: FarmerService, public dialog: MatDialog) {
    if (isPushNotificationsAvailable)
      this.initPush(this.appService.checkService(), this.appService.checkRole())
  }
  auth: string[] = [];

  initPush(service, role) {
    console.log('Initializing Push Notification');

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        console.log('Push registration success, token: ' + token.value);
        this.tokenManagementService.setDeviceToken(token.value);
        service.saveDeviceToken({ "deviceToken": token.value }).subscribe(
          data => {
            console.log('register device token success');
          }, error => {
            console.log('register device token failed');
          }
        );
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification) => {
          this.ngZone.run(() => {
            console.log('Push action performed: ' + JSON.stringify(notification));
            let uid = notification.notification.data.uid
            let name = notification.notification.data.name
            let mccId = notification.notification.data.mccId
            let typeAlert = notification.notification.data.typeAlert
            let idFarmerSupervisorRequest = notification.notification.data.idFarmerSupervisorRequest
            let message = notification.notification.data.message
            let title = notification.notification.data.title
            this.actionRouter(uid, name, mccId, typeAlert, role, idFarmerSupervisorRequest,message,title)
          })
        }
  
      );
  }

  actionRouter(uid, name, mccId, typeAlert, role, idFarmerSupervisorRequest, message, title) {

    let service
    if (mccId != null) {
      if (!role)
        this.router.navigateByUrl('/mcc/' + mccId + '/tanks')
      else
        this.router.navigateByUrl('/supervisors')
    }
    else if (idFarmerSupervisorRequest != null) {
      const dialogRef = this.dialog.open(RequestDialogComponent, {
        width: '350px',
        data: {
          title: title,
          message: message,
          idFsr: idFarmerSupervisorRequest,
          onConfirm: () => {
          }
        }
      });
    }
    else {
      if ((uid == null) && (name != null))
        service = this.animalService.getByName(name)
      else if ((uid != null) && (name == null))
        service = this.animalService.getByUid(uid)
      if (service) {
        service.subscribe(animal => {
          if (TypeAlert.getSickAlertType().indexOf(typeAlert) != -1) {
            if (role)
              this.router.navigateByUrl("/farmers//animals/groups/-1 search/" + uid ? uid : name)
            else this.router.navigateByUrl("animals/groups/-1/search/" + uid ? uid : name)


          }
          else if (idFarmerSupervisorRequest != null) {
            const dialogRef = this.dialog.open(RequestDialogComponent, {
              width: '350px',
              data: {
                title: title,
                message: message,
                idFsr: idFarmerSupervisorRequest,
                onConfirm: () => {
                }
              }
            });


          }
          else {
            let diff = moment().diff(moment(animal.birthDate), "days")
            let month = (Math.floor(diff / 30.4167));
            if ((animal.sexe == Sexe.FEMALE && month > 14) || ((animal.birthDate == null) && (animal.sexe == Sexe.FEMALE) && (animal.lactNum != null))) {
              if (role)
                this.router.navigateByUrl('/farmers/' + animal['customerId'] + '/animals/' + animal.id + '/timelines')
              else this.router.navigateByUrl('/animals/' + animal.id + '/timelines')
            } else {
              this.appService.getDefaultGroup().subscribe(defaultGroupId => {
                if (role)
                  this.router.navigateByUrl('/farmers/' + animal['customerId'] + '/animals/groups/' + defaultGroupId)
                else this.router.navigateByUrl("/animals/groups/" + defaultGroupId)
              })
            }
          }
        })
      }
    }
  }
}



