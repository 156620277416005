import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { Animal } from "./Animal";
@HateoasResource('animalMilkProductions')
export class AnimalMilkProduction extends Resource {
  
   
    date: string;
    quantity: string
    checkbox:boolean
    reminder:number
}