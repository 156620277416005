import { Injectable } from '@angular/core';
import { MoomeRestService, Options } from './moome-rest.service';
import { map } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { AnimalMilkProduction } from '../resources/AnimalMIlkProduction';

@Injectable({
    providedIn: 'root'
})
export class AnimalMilkProductionService extends MoomeRestService<AnimalMilkProduction> {

    constructor(public http: HttpClient, private configService: ConfigService) {
        super(AnimalMilkProduction);
    }

    findByAnimal(id) {
        let options: Options =
        {
            params: {
                projection: "animal-milk-producion",
            }
        }
        return this.customRequestGet(`/findAllByAnimal?id=${id}`, null, options).pipe(
            map(res => { return res['_embedded']?.animalMilkProductions }
            ))
    }

    findByAnimalByKey(key,id) {
        let options: Options =
        {
            params: {
                key: key,
                projection: "animal-milk-producion",
            }
        }
        
        return this.customRequestGet(`/findAllByAnimalByKey?id=${id}`, null, options).pipe(
            map(res => { return res['_embedded']?.animalMilkProductions }
            ))
    }
    

    deleteById(body) {
        return this.http.delete(this.configService.getApiPath() + `/animalMilkProductions/${body.id}/deleteAnimalMilkProduction`)
    }

    saveAnimalMilkProduction(body,id) {
        
		return this.http.post(this.configService.getApiPath() + `/animalMilkProductions/save?id=${id}`, body);
    }
    findMilkProductionByExisteDate(date,id){
        return this.http.get(this.configService.getApiPath() + `/animalMilkProductions/findMilkProductionByExisteDate/?date=${date}&id=${id}`);
    }
	 
}