import { Component, OnInit } from '@angular/core';
import { CustomRouter } from '../services/custom-router';

@Component({
  selector: 'app-carbon-emissions',
  templateUrl: './carbon-emissions.component.html',
  styleUrls: ['./carbon-emissions.component.scss']
})
export class CarbonEmissionsComponent implements OnInit {
  bar:any
  constructor(public router:CustomRouter) {
    this.bar={title:"CARBON_EMISSIONS",arrowIcon:"/assets/images/left-arrow.svg", color:"#f6f7fb",fontColor:"#80cc28",router:"/dashboard"}
   }

  ngOnInit(): void {
  }
  back(){
    this.router.navigateByUrl('/dashboard')
  }
}
