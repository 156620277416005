import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditAnimalComponent } from './edit-animal.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [EditAnimalComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
  MoomeCommonModule]
})
export class EditAnimalModule { }
