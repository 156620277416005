import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultSearchComponent } from './result-search.component';
import { AnimalCardModule } from '../../animals/animal-card/animal-card.module';
import { FarmerCardModule } from '../farmer-card/farmer-card.module';
import { TranslateModule } from '@ngx-translate/core';
import { SearchModule } from 'projects/shared/components/search/search.module';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { NoAnimalsModule } from '../../animals/no-animals/no-animals.module';
import { TankCardModule } from '../../tanks/tank-card/tank-card.module';


@NgModule({
  declarations: [ResultSearchComponent],
  imports: [
    CommonModule,
    AnimalCardModule,
    FarmerCardModule,
    TranslateModule,   
    BrowserModule,
    AnimalCardModule,
    TranslateModule,
    NoAnimalsModule,
    NgxHideOnScrollModule,
    RouterModule,
    MoomeCommonModule,
    TankCardModule
  ], exports: [  ResultSearchComponent]
})
export class ResultSearchModule { }
