<div class="style-bar" [getAuthorized]="authority">
    <div class="d-flex justify-content-around h-100">
        <div *ngIf="!isPopUp" class="center-element" (click)="back()">
            <img src="/assets/images/left-arrow-2.svg" />
        </div>

        <div *ngIf="isPopUp" class="d-flex align-items-end flex-column ">
            <img src="/assets/images/Union-5.svg" (click)="closePopUp()" />
        </div>

        <div class="center-element p-0" [getAuthorized]="authority" >
            <div class="row gx-4 center-element">
                <div class="color-group-cercle col-3 p-0" [ngStyle]="{'background-color':colorGroup}">
                </div>
                
                <div class="col text-style-setting"><span></span>{{appService.valueUid ==nationalIdentification? (uid? uid : name) : (appService.valueUid == nameLocalCode? (name? name : uid) : (uid? uid : name))}}
                </div>
                
            </div>
        </div>
        <div class="center-element">
        </div>
    </div>
</div>
