import { Component, OnInit } from '@angular/core';
import { CustomRouter } from '../services/custom-router';
import { AppService } from 'projects/shared/services/app.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  bar:any
  list : any[] = []
  constructor(public router: CustomRouter,public appService:AppService) { 
    this.list=[
      {router:"/economics",text:"FARM_ECONOMICS",fontColor:"white",color:"#316a90"},
      {router:"/milkProd",text:"MILK_PRODUCTION",fontColor:"white",color:"#06AF8F"},
      {router:"/feed",text:"FEED_FODDER",fontColor:"white",color:"#F7AC08"},
      {router:"/production",text:"REPRO",fontColor:"white",color:"#80cc28"},
      {router:"/health",text:"HEALTH",fontColor:"white",color:"#f44336"},
      {router:"/herd",text:"HERD",fontColor:"white",color:"#204157"},
    /*{router:"/carbon",text:"CARBON_EMISSIONS",fontColor:"white",color:"#80cc28"},*/
    /*{router:"/home",text:"ETC",fontColor:"black",color:"#e4e8eb"},*/]
    this.bar={title:"DASHBOARD",arrowIcon:"/assets/images/left-arrow-1.svg",icon:"/assets/images/Dashboard.svg", color:"#204157",fontColor:"white",router:"/home"}
  }


  ngOnInit(){
  }

back(){
  this.router.navigateByUrl('/home')
}

}
