import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsAnimalComponent } from './settings-animal.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CommonSettingModule } from '../common-setting/common-setting.module';
import { CommonDialogComponent } from '../../common-dialog/common-dialog.component';
import { CommonDialogModule } from '../../common-dialog/common-dialog.module';
import { AnimalBarModule } from '../../animals/animal-bar/animal-bar.module';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';

@NgModule({
    declarations: [SettingsAnimalComponent],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        CommonSettingModule,
        CommonDialogModule,
        AnimalBarModule,
        MoomeCommonModule
    ],
    exports: [
        SettingsAnimalComponent
    ]
})
export class SettingsAnimalModule { }
