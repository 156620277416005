import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Version } from '../resources/Version';
import { MoomeRestService } from './moome-rest.service';

@Injectable({
    providedIn: 'root'
})
export class AppVersionService extends MoomeRestService<Version> {

    constructor(public http: HttpClient, injector: Injector) {
        super(Version);
    }
}