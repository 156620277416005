<div class="style-bar mb-3" [ngClass]="color" >
    <div class="row h-100 ">
        <div class="col-3 center-element" (click)="back()">
            <img src="/assets/images/left-arrow-1.svg" />
        </div>

        <div class="col center-element">
            <div class="row">
                <div class="col text-center ps-0 pe-0 "> <span class="text-style "
                        [ngClass]="{rtl:appService.rtl()}"  *ngIf="title">{{ title | translate }}</span></div>
            </div>
        </div>
        <div class="col-3 center-element">
        </div>
    </div>
</div>