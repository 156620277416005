import { Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { MAT_DATEPICKER_VALIDATORS } from '@angular/material/datepicker';
import { Alert, PATTERN_UID_DIFFERENT } from './constants';
//import * as moment from 'moment';

export class Validator {

	public static passwordValidator(edit: boolean) {
		let passwordValidators = [];
		if (!edit) {
			passwordValidators = [Validators.required, this.checkPassword]
		}
		return passwordValidators;
	}

	public static checkPassword(control) {
		let enteredPassword = control.value
		let passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
		return (!passwordCheck.test(enteredPassword) && enteredPassword) ? { 'requirements': true } : null;
	}

	public static installDateVlidator() {
		let dateValidator = [];
		dateValidator = [MAT_DATEPICKER_VALIDATORS.required]
		return dateValidator;
	}

	public static validPhoneLength(translate, phoneLength, alertsService) {
		let msg: string
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			if (control.value !== undefined && (isNaN(control.value) || (document.getElementById("phone") as HTMLFormElement))) {
				if (((document.getElementById("phone") as HTMLFormElement).value.toString().length) > phoneLength) {
					msg = translate.instant('PHONE_LENGTH') + " " + phoneLength.toString() + translate.instant('DIGITS') + " ";
					let alert = translate.instant(Alert.OK)
					alertsService.open(msg, alert);
					return { 'phoneLength': true };
				}
				else if (((document.getElementById("phone") as HTMLFormElement).value.toString().length) < phoneLength) {
					return { 'phoneLength': true };
				}
			}
			return null;

		}
	}

	public static validWeight(alertsService, translate) {

		let msg: string
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			if (control.value !== undefined && (isNaN(control.value) || (document.getElementById("weight") as HTMLFormElement))) {
				let weightPattern = /^[0-9]+(\.[0-9]{1,3})?$/
				let weight = (document.getElementById("weight") as HTMLFormElement).value.toString()
				if ((document.getElementById("weight") as HTMLFormElement).value.toString() !== "") {
					if (!weightPattern.test(weight)) {
						msg = translate.instant('WEIGHT_MESSAGE');
						let alert = translate.instant(Alert.OK)
						alertsService.open(msg, alert);
						return { 'weightValid': true };
					}
				}
				else {
					return null;
				}
			}
			return null;
		}
	}

	/*	public static datePickerValidator(alertsService, translate, tokenManagementService): ValidatorFn {
			
			return (control: AbstractControl): { [key: string]: any } | null => {
				let forbidden = true;
				if (control.value) {
					let msg = translate.instant('FORM_DATE');
					let alert = translate.instant(Alert.OK)
					if (tokenManagementService.getTranslateLanguage() == "fr") {
						if (!moment(control.value, 'DD/MM/YYYY', true).isValid())
							alertsService.open(msg, alert);
						forbidden = false;
	
					}
					else if (tokenManagementService.getTranslateLanguage() == "ar") {
						
						if (!moment(control.value, 'YYYY/MM/DD', true).isValid())
							msg = translate.instant('FORM_DATE');
						alertsService.open(msg, alert);
						forbidden = false;
	
					}
					else if (tokenManagementService.getTranslateLanguage() == "en") {
						if (!moment(control.value, 'MM/DD/YYYY', true).isValid())
							msg = translate.instant('FORM_DATE');
						alertsService.open(msg, alert);
						forbidden = false;
					}
	
				}
				return forbidden ? { 'invalidDOBYear': true } : null;
			};
		}*/
	/*
	public static datePickerValidator(alertsService, translate): ValidatorFn {
		
		return (control: AbstractControl): { [key: string]: any } | null => {
			let forbidden = true;
			if (control.value) {
				let msg = translate.instant('FORM_DATE');
				let alert = translate.instant(Alert.OK)
				let datePattern ="/^[0-9]{1,2}/[0-9]{1,2}/[0-9]{4}?$/ "
				if (!datePattern.match(control.value.change(Date))){
					alertsService.open(msg, alert);
					forbidden = false;
				}
				

			}
			return forbidden ? { 'invalidDOBYear': true } : null;
		};
	}*/


	public static validUidLength(translate, uidLength, alertsService, j?) {
		let uid: any
		if (j)
			uid = "uid" + j
		else
			uid = "uid"
		let msg: string
		return (control: AbstractControl): { [key: string]: boolean } | null => {

			if (control.value != null)
				if ((control.value !== undefined && (isNaN(control.value) || (document.getElementById(uid) as HTMLFormElement) && (document.getElementById(uid) as HTMLFormElement).value !== undefined
				&& (document.getElementById(uid) as HTMLFormElement).value !== ""))) {
					if (((document.getElementById(uid) as HTMLFormElement).value.toString().length) < uidLength) {
						return { 'uidLength': true };
					}

					else if (((document.getElementById(uid) as HTMLFormElement).value.toString().length) > uidLength) {
						msg = translate.instant('UID_LENGTH_MIN') + " " + uidLength.toString();
						let alert = translate.instant(Alert.OK)
						alertsService.open(msg, alert);
						control.setValue((document.getElementById(uid) as HTMLFormElement).value.substr(0, uidLength).replace(/[.,\/#!?$%@\^&\*;:{}=\-+_`~()]+/g, ""))

						if ((document.getElementById(uid) as HTMLFormElement).value.toString().length = uidLength)
							return { 'uidLength': false };
					}
				}
			return null;
		}
	}

	public static validUid1Length(translate, uidLength, alertsService, j?) {
		let uid: any
		if (j)
			uid = "uid1" + j
		else
			uid = "uid1"
		let msg: string
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			if (control.value != null)
				if ((control.value !== undefined && (isNaN(control.value) || (document.getElementById(uid) as HTMLFormElement) && (document.getElementById(uid) as HTMLFormElement).value !== undefined))) {
					if (((document.getElementById(uid) as HTMLFormElement).value.toString().length) < uidLength) {
						return { 'uidLength': true };
					}

					else if (((document.getElementById(uid) as HTMLFormElement).value.toString().length) > uidLength) {
						control.setValue((document.getElementById(uid) as HTMLFormElement).value.substr(0, uidLength).replace(/[.,\/#!?$%@\^&\*;:{}=\-+_`~()]+/g, ""))
						msg = translate.instant('UID_LENGTH_MIN') + " " + uidLength.toString();
						let alert = translate.instant(Alert.OK)
						alertsService.open(msg, alert);

						if ((document.getElementById(uid) as HTMLFormElement).value.toString().length = uidLength)
							return { 'uidLength': false };
					}
				}
			return null;
		}
	}

	public static validUid2Length(translate, uidLength, alertsService, j?) {
		let uid: any
		if (j)
			uid = "uid2" + j
		else
			uid = "uid2"
		let msg: string
		return (control: AbstractControl): { [key: string]: boolean } | null => {

			if (control.value != null)
				if ((control.value !== undefined && (isNaN(control.value) || (document.getElementById(uid) as HTMLFormElement) && (document.getElementById(uid) as HTMLFormElement).value !== undefined))) {
					if (((document.getElementById(uid) as HTMLFormElement).value.toString().length) < uidLength) {
						return { 'uidLength': true };
					}

					else if (((document.getElementById(uid) as HTMLFormElement).value.toString().length) > uidLength) {
						msg = translate.instant('UID_LENGTH_MIN') + " " + uidLength.toString();
						let alert = translate.instant(Alert.OK)
						alertsService.open(msg, alert);
						control.setValue((document.getElementById(uid) as HTMLFormElement).value.substr(0, uidLength).replace(/[.,\/#!?$%@\^&\*;:{}=\-+_`~()]+/g, ""))

						if ((document.getElementById(uid) as HTMLFormElement).value.toString().length = uidLength)
							return { 'uidLength': false };
					}
				}
			return null;
		}
	}


	public static validUidPattern(alertsService, translate, uidLength, j?) {
		let uid: any
		if (j)
			uid = "uid" + j
		else
			uid = "uid"
		let msg: string
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			if (control.value !== undefined && (isNaN(control.value) || ((document.getElementById(uid) as HTMLFormElement)))) {
				let uidValue = (document.getElementById(uid) as HTMLFormElement).value.toString();
				let uidPattern = /^[0-9]*$/
				if (((document.getElementById(uid) as HTMLFormElement).value.toString().length) <= uidLength) {
					if ((!uidPattern.test(uidValue))) {
						msg = translate.instant('WEIGHT_MESSAGE');
						let alert = translate.instant(Alert.OK)
						alertsService.open(msg, alert);
						control.setValue((document.getElementById(uid) as HTMLFormElement).value.replace(/[.,\/#!?$%@\^&\*;:{}=\-+_`~()a-zA-Z-]+/g, ""));
						return { 'uidValue': true };
					}
				}
				else {
					return null;
				}
			}
			return null;
		}
	}
	public static validUid1Pattern(alertsService, translate, j?) {

		let uid1: any
		if (j)
			uid1 = "uid1" + j
		else
			uid1 = "uid1"
		let msg: string
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			if (control.value !== undefined && (isNaN(control.value) || ((document.getElementById(uid1) as HTMLFormElement)))) {
				let uidPattern = /^[0-9]*$/
				let uidValue = (document.getElementById(uid1) as HTMLFormElement).value.toString()
				if ((!uidPattern.test(uidValue))) {
					msg = translate.instant('WEIGHT_MESSAGE');
					let alert = translate.instant(Alert.OK)
					alertsService.open(msg, alert);
					control.setValue((document.getElementById(uid1) as HTMLFormElement).value.substr(0, ((document.getElementById(uid1) as HTMLFormElement).value.length - 1)));
					return { 'uid1Value': true };
				}
				else {
					return null;
				}
			}
			return null;
		}
	}
	public static validUid2Pattern(alertsService, translate, j?) {

		let uid2: any
		if (j)
			uid2 = "uid2" + j
		else
			uid2 = "uid2"
		let msg: string
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			if (control.value !== undefined && (isNaN(control.value) || ((document.getElementById(uid2) as HTMLFormElement)))) {
				let uidPattern = /^[A-Z0-9]*$/
				let uidValue = (document.getElementById(uid2) as HTMLFormElement).value.toString()
				if ((!uidPattern.test(uidValue))) {
					msg = translate.instant('UID2_PATTERN');
					let alert = translate.instant(Alert.OK)
					alertsService.open(msg, alert);
					control.setValue((document.getElementById(uid2) as HTMLFormElement).value.substr(0, ((document.getElementById(uid2) as HTMLFormElement).value.length - 1)));
					return { 'uid2Value': true };
				}
				else {
					return null;
				}
			}
			return null;
		}
	}

	public static validNameLength(translate, maxCowName, alertsService, j?) {
		let name: any
		if (j)
			name = "name" + j
		else
			name = "name"
		let msg: string
		return (control: AbstractControl): { [key: string]: boolean } | null => {

			if (control.value != null)
				if ((control.value !== undefined && (isNaN(control.value) || (document.getElementById(name) as HTMLFormElement) && (document.getElementById(name) as HTMLFormElement).value !== undefined))) {
					if ((document.getElementById(name) as HTMLFormElement)) {
						if (((document.getElementById(name) as HTMLFormElement).value.toString().length) > maxCowName) {
							msg = translate.instant('COWS_NAME') + " " + maxCowName.toString() + translate.instant('CARACTERE_ONLY') + " "
							let alert = translate.instant(Alert.OK)
							alertsService.open(msg, alert);
							control.setValue((document.getElementById(name) as HTMLFormElement).value.substr(0, maxCowName))
							if ((document.getElementById(name) as HTMLFormElement).value.toString().length = maxCowName)
								return { 'nameValid': false };
						}
					}
				}
			return null;
		}
	}


}
