
import { TimelineDetail } from '../resources/timeline-detail'

export const PRESENTES_COWS = "11";
export const RUMINATION = "RUMINATION";
export const SITTING = "SITTING";
export const SUFFIX_ANIMALS = "TN";
export const SUFFIX_ANIMALS_MAR = "MAR";
export const UID2_ANIMALS_MAR = "B";
export const UID1_ANIMALS_MAR = 2;
export const PATTERN_UID_MARC = "^MAR[0-9]{2}B[A-Z][0-9]{9}$";
export const UID_PATTERN = /^[0-9]*$/
export const USERNAME_MIN_LENGTH = 4;
export const USERNAME_MAX_LENGTH = 40;
export const NAME_MIN = 3
export const NAME_MAX = 30
export const NAME_PATTERN = "^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z ]*$"
export const NAME_MIN_LENGTH = 3;
export const NAME_MAX_LENGTH = 30;
export const USERNAME_PATTERN = "^[a-zA-Z0-9.@\u0600-\u06FF ]*$";
export const PATTERN_NAME_ARABIC = "^[\s\u0621-\u064A./-0-9]+[ \u0621-\u064A0-9\s./-]*$";
export const PATTERN_NAME_FRENCH = "^[\sa-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ0-9./-]+[ a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ0-9\s]*$";
export const PATTERN_NAME_ENGLISH = "^[\sa-zA-Z]+[ a-zA-Z\s]*$";
export const PATTERN_FEEDBACK_ARABIC = "^[\s\u0621-\u064A0-9\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff].,;:!?]+[ \u0621-\u064A0-9\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff].,;:!?\s]*$";
export const PATTERN_FEEDBACK_FRENCH = "^[\sa-zA-Z0-9\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff].,;:!?'áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ]+[ a-zA-Z0-9\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff].,;:!?'áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ\s]*$";
export const PATTERN_FEEDBACK_ENGLISH = "^[\sa-zA-Z0-9\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff].,;:!?']+[ a-zA-Z0-9\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff].,;!?:'\s]*$";
export const MAX_LENGTH_PASSWORD = 20;
export const MIN_LENGTH_PASSWORD = 4;
export const PATERN_PASSWORD = "^[a-zA-Z0-9\u0600-\u06FF ]+$";
export const MIN_LENGTH_NAME_COW = 3;
export const MAX_LENGTH_NAME_COW = 15;
export const PATTER_NAME_COW = "^[\sa-zA-Z0-9\u0600-\u06FF]+[ a-zA-Z0-9\u0600-\u06FF\s]*$";
export const MAX_UID1 = 2;
export const MAX_UID2 = 10;
export const PATTERN_DAY = "^[13-31]*$";
export const PATTERN_DAY_MONTH = "^[1-12]*$";
export const FORMAT_MAX_MIN = "YYYY-MM-DD"
export const PATTERN_UID_DIFFERENT = "^[0-9]*$"
export const PATTERN_UID_MAROC = "^[0-9]{2}B[A-Z]{1}[0-9]{9}$"
export const MAX_REF = 20;
export const LIVESTOCK_IDENTIFICATION = "LIVESTOCK_IDENTIFICATION";
export const LENGTH_WEIGHT = 7;
export const LANGUAGE = "LANGUAGE";
export const SETTING_PATTERN = "^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z/ ]*$"
//export const OFFSET_RUMINATION = 6
export const NUMERIC_PATTERN = /^[-0-9]+([,.][0-9]{0,3})?$/;
export const PHONE_PATTERN = /^[0-9]*$/;
export const PATTERN_MAIL = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
export const LENGTH_MAIL = 50;
export const MAX_COUNT_ORDER = 3;
export const PATTERN_FULLNAME = "^[a-zA-Z\u0600-\u06FF]+[ a-zA-Z\u0600-\u06FF]*$";
export const NUMERIC_PATTERN_RECALL = /^[0-9]*$/;
export const MIN_DAY_RECALL = 1;
export const MAX_DAY_RECALL = 365;
export const TEXT_PATTERN = /^[a-zA-Z0-9\u0600-\u06FF,.:]*$/;
export const NUMERIC_PATTERN_FEED = /^[0-9,.]*$/;
export const NUMERIC_PATTERN_PERCENT = /^(100(\.000)?|\d{1,2}(\.\d{0,3})?)$/;
export enum Type {
	DATE = "DATE",
	NUMBER = "NUMBER",
	TEXT = "TEXT",
	TEXTAREA = "TEXTAREA",
	SELECT_STATIC = "SELECT_STATIC",
	SELECT_QUERY = "SELECT_QUERY",
	COMPLEX = "COMPLEX",
	DYNAMIC = "DYNAMIC",
	CHECKBOX = "CHECKBOX",
	REMINDER = "REMINDER",
	NUMBER_PERCENTAGE = "NUMBER_PERCENTAGE"

}
export class TypeForm {
	public static getTypes() {
		return [
			"DATE",
			"NUMBER",
			"TEXT",
			"TEXTAREA",
			"SELECT_STATIC",
			"SELECT_QUERY",
			"COMPLEX",
			"DYNAMIC",
			"CHECKBOX",
			"REMINDER",
			"NUMBER_PERCENTAGE"
		]
	}
}

export enum ControlResult {
	NEGATIVE = "NEGATIVE",
	POSITIVE = "POSITIVE",

}

export enum Subscription {
	NEW = "NEW",
	DONE = "DONE",
	EXPIRED = "EXPIRED"

}

export enum TypeEntrep {
	ENTREPRISE = "ENTREPRISE",
	COLLECTION_CENTER = "MILK_COLLECTION_CENTER",
}
export class Country {
	public static isPakIdn(code: any) {
		switch (code) {
			case 'PAK':
			case 'IDN':
				return true
			default:
				return false
		}
	}
	public static isPak(code: any) {
		switch (code) {
			case 'PAK':
				return true
			default:
				return false
		}
	}
	public static isIdn(code: any) {
		switch (code) {
			case 'IDN':
				return true
			default:
				return false
		}
	}
}

export class TypeEntreprise {
	public static getLevels(route) {
		if (route.indexOf('ENTREPRISE') != -1)
			return [
				"L1",
				"L2"
			]
		else if (route.indexOf('MILK_COLLECTION_CENTER') != -1)
			return [
				"L3"
			]
		else
			return [
				"L0",
				"L1",
				"L2",
				"L3"
			]
	}
	public static getTypes(route) {
		if (route.indexOf('ENTREPRISE') != -1)
			return [
				"ENTREPRISE",
			]
		else if (route.indexOf('MILK_COLLECTION_CENTER') != -1)
			return [
				"MILK_COLLECTION_CENTER",
				"FARM"
			]
		else
			return [
				"ENTREPRISE",
				"MILK_COLLECTION_CENTER",
				"FARM"
			]
	}
}

export enum Language {
	AR = "AR",
	FR = "FR",
	EN = "EN",
	RW = "RW",
	IND = "IND"
}

export enum LivestockIdentif {
	NATIONAL_IDENTIFICATION = "NATIONAL_IDENTIFICATION",
	NAME_LOCALCODE = "NAME_LOCALCODE",

}

export enum GroupCode {
	PRODUCTION_CODE = "01",
	DRY_CODE = "02",
	DRY_NotPregnant_CODE = "03",
	DRY_Pregnant_CODE = "04",
	PINS_CODE = "05",
	PNOTINS_CODE = "06",
	P_Negative_Pregnancy_CODE = "07",
	P_Pregnant_CODE = "08",
	HEIFERS_CODE = "09",
	HINS_CODE = "10",
	HNOTINS_CODE = "11",
	H612_CODE = "12",
	H_Negative_Pregnancy_CODE = "13",
	H_Pregnant_CODE = "14",
	CALVES_CODE = "15",
	SICK_CODE = "16",
	SOLD_CODE = "17",
	LOST_CODE = "18",
	FATTENING_CODE = "19",
	RFORM_CODE = "20",
	MORTALITY_CODE = "21",
	PRESENT_HERD = "22",
	STOLEN_CODE = "23",
	ADULT_COWS = "24"

}

export class Group {
	public static isHeifersCalves(code: any) {
		switch (code) {
			case GroupCode.H612_CODE:
			case GroupCode.HINS_CODE:
			case GroupCode.HNOTINS_CODE:
			case GroupCode.H_Negative_Pregnancy_CODE:
			case GroupCode.H_Pregnant_CODE:
			case GroupCode.HEIFERS_CODE:
			case GroupCode.CALVES_CODE:
				return true
			default:
				return false
		}
	}
	public static isFattening(code: any) {
		switch (code) {
			case GroupCode.FATTENING_CODE:
				return true
			default:
				return false
		}
	}
	public static isCalve(code: any) {
		switch (code) {
			case GroupCode.CALVES_CODE:
				/*case GroupCode.STOLEN_CODE:
				case GroupCode.MORTALITY_CODE:
				case GroupCode.RFORM_CODE:
				case GroupCode.SOLD_CODE:
				case GroupCode.LOST_CODE:
				case GroupCode.SICK_CODE:*/
				return true
			default:
				return false
		}
	}

	public static isProdDry(code: any) {
		switch (code) {
			case GroupCode.PINS_CODE:
			case GroupCode.PNOTINS_CODE:
			case GroupCode.PRODUCTION_CODE:
			case GroupCode.P_Negative_Pregnancy_CODE:
			case GroupCode.P_Pregnant_CODE:
			case GroupCode.DRY_CODE:
			case GroupCode.DRY_NotPregnant_CODE:
			case GroupCode.DRY_Pregnant_CODE:

				return true
			default:
				return false
		}
	}
	public static isFattDry(code: any) {
		switch (code) {
			case GroupCode.DRY_CODE:
			case GroupCode.DRY_NotPregnant_CODE:
			case GroupCode.DRY_Pregnant_CODE:
			case GroupCode.FATTENING_CODE:

				return true
			default:
				return false
		}
	}
	public static isDry(code: any) {
		switch (code) {
			case GroupCode.DRY_CODE:
			case GroupCode.DRY_NotPregnant_CODE:
			case GroupCode.DRY_Pregnant_CODE:
				return true
			default:
				return false
		}
	}
	public static isSickLostSoldGroup(code: any) {
		switch (code) {
			case GroupCode.STOLEN_CODE:
			case GroupCode.MORTALITY_CODE:
			case GroupCode.RFORM_CODE:
			case GroupCode.SOLD_CODE:
			case GroupCode.LOST_CODE:
			case GroupCode.SICK_CODE:



				return true
			default:
				return false
		}
	}
	public static isNotHeif612(code: any) {
		switch (code) {
			case GroupCode.HINS_CODE:
			case GroupCode.HNOTINS_CODE:
			case GroupCode.H_Negative_Pregnancy_CODE:
			case GroupCode.H_Pregnant_CODE:
			//case GroupCode.FATTENING_CODE:
			case GroupCode.PINS_CODE:
			case GroupCode.PNOTINS_CODE:
			case GroupCode.P_Negative_Pregnancy_CODE:
			case GroupCode.P_Pregnant_CODE:
				//case GroupCode.DRY_CODE:
				return true
			default:
				return false
		}
	}
	public static isProdNotInsemDry(code: any) {
		switch (code) {
			case GroupCode.PNOTINS_CODE:
			case GroupCode.DRY_CODE:
			case GroupCode.DRY_NotPregnant_CODE:

				return true
			default:
				return false
		}
	}
	public static isHeifInsemNotInsem(code: any) {
		switch (code) {
			case GroupCode.HINS_CODE:
			case GroupCode.HNOTINS_CODE:
			case GroupCode.H_Negative_Pregnancy_CODE:
			case GroupCode.H_Pregnant_CODE:
				return true
			default:
				return false
		}
	}
	public static isProdDryHeif(code: any) {
		switch (code) {
			case GroupCode.PINS_CODE:
			case GroupCode.PNOTINS_CODE:
			case GroupCode.P_Negative_Pregnancy_CODE:
			case GroupCode.P_Pregnant_CODE:
			case GroupCode.DRY_CODE:
			case GroupCode.DRY_NotPregnant_CODE:
			case GroupCode.DRY_Pregnant_CODE:
				return true
			default:
				return false
		}
	}

	public static isHeif612(code: any) {
		switch (code) {
			case GroupCode.H612_CODE:
			case GroupCode.STOLEN_CODE:
			case GroupCode.MORTALITY_CODE:
			case GroupCode.RFORM_CODE:
			case GroupCode.SOLD_CODE:
			case GroupCode.LOST_CODE:
			case GroupCode.SICK_CODE:

				return true
			default:
				return false
		}
	}

	public static isSickLostSold(code: any) {
		switch (code) {
			case GroupCode.STOLEN_CODE:
			case GroupCode.MORTALITY_CODE:
			case GroupCode.RFORM_CODE:
			case GroupCode.FATTENING_CODE:
			case GroupCode.SOLD_CODE:
			case GroupCode.LOST_CODE:
			case GroupCode.SICK_CODE:
			case GroupCode.CALVES_CODE:
				return true
			default:
				return false
		}
	}


	public static isChildsGroup(code: any) {
		switch (code) {
			case GroupCode.PRODUCTION_CODE:
			case GroupCode.HEIFERS_CODE:
			case GroupCode.DRY_CODE:
			case GroupCode.LOST_CODE:
				return true
			default:
				return false
		}
	}
	public static isFeedGroup(code: any) {
		switch (code) {
			case GroupCode.PRODUCTION_CODE:
				return GroupCode.PRODUCTION_CODE
			case GroupCode.HEIFERS_CODE:
				return GroupCode.HEIFERS_CODE
			case GroupCode.DRY_CODE:
				return GroupCode.DRY_CODE
			case GroupCode.CALVES_CODE:
				return GroupCode.CALVES_CODE
			case GroupCode.FATTENING_CODE:
				return GroupCode.FATTENING_CODE
			case GroupCode.PRESENT_HERD:
				return GroupCode.PRESENT_HERD
			case GroupCode.ADULT_COWS:
				return GroupCode.ADULT_COWS
				
			default:
				return false
		}
	}
	public static isFeedGroupValue(code: any) {
		switch (code) {
			case GroupCode.ADULT_COWS:
				return "ADULT_COWS";
			case GroupCode.PRODUCTION_CODE:
				return "MILKING"
			case GroupCode.DRY_CODE:
				return "DRY"
			case GroupCode.HEIFERS_CODE:
				return "HEIFERS"
			case GroupCode.CALVES_CODE:
				return "CALVES"
			case GroupCode.FATTENING_CODE:
				return "FATTENING"
			case GroupCode.PRESENT_HERD:
				return "TOTAL_HERD"
			default:
				return false
		}
	}
	public static haveTimeline(code: any) {
		switch (code) {
			case GroupCode.PINS_CODE:
			case GroupCode.PNOTINS_CODE:
			case GroupCode.P_Negative_Pregnancy_CODE:
			case GroupCode.P_Pregnant_CODE:
			case GroupCode.DRY_NotPregnant_CODE:
			case GroupCode.DRY_Pregnant_CODE:
			case GroupCode.HINS_CODE:
			case GroupCode.HNOTINS_CODE:
			case GroupCode.H_Negative_Pregnancy_CODE:
			case GroupCode.H_Pregnant_CODE:
				return true
			default:
				return false
		}
	}

}
export enum TYPE {
	COLLAR = 'COLLAR',
	THI_SENSOR = 'THI_SENSOR'
}

export enum Sexe {
	MALE = "MALE",
	FEMALE = "FEMALE"
}

export enum TypeSensor {
	ACCELERATION_SENSOR = "ACCELERATION_SENSOR",
	DOUBLE_TEMP_SENSOR = "DOUBLE_TEMP_SENSOR",
	TEMP_HUMIDITY_SENSOR = "TEMP_HUMIDITY_SENSOR",
	MOVEMENT_SENSOR = "MOVEMENT_SENSOR"
}
export enum AvailabilitySensor {
	LOST = "LOST",
	BROKEN = "BROKEN",
	AVAILABLE = "AVAILABLE"
}
export enum Roles {
	ROLE_ADMINISTRATOR = "ROLE_ADMINISTRATOR",
	ROLE_VETERINARY = "ROLE_VETERINARY",
	ROLE_FARMER = "ROLE_FARMER",
	//ROLE_GUEST = "ROLE_GUEST",
	ROLE_AI = "ROLE_AI",
	//ROLE_FARMER_GROUP = "ROLE_FARMER_GROUP",
	ROLE_ADVISOR_MCC = "ROLE_ADVISOR_MCC",
	ROLE_ENTREPRISE_ADMIN = "ROLE_ENTREPRISE_ADMIN",
	ROLE_ADVISOR_FARMER = "ROLE_ADVISOR_FARMER"
}

export enum labelStep {
	C = "C",
	D = "D",
	C0 = "C0",
	C1 = "C1",
	C2 = "C2",
	AI = "AI"
}

export enum Alert {
	OK = "Ok",
	CONTACT_US = "CONTACT_US"
}

export enum TypePayment {
	CASH = "CASH",
	CHECK = "CHECK"
}

export enum StatusPayment {
	ADVANCE = "ADVANCE",
	TOTAL = "TOTAL"
}

export enum AccessType {
	ADMIN = "administrator",
	FARMER = "farmer",
	SUPERVISOR = "supervisor"
}


export enum Title {
	LOGIN = "Admin - Login",
	WELCOME = "Admin - Welcome",
	ANIMALS = "Admin - Animals",
	FARMERS = "Admin - Farmers",
	SUPERVISORS = "Admin - Supervisors",
	ADMINISTRATORS = "Admin - Administrators",
	GATEWAYS = "Admin - Gateways",
	TAGS = "Admin - Tags",
	SPECIES = "Admin - Species",
	BREEDS = "Admin - Breeds",
	AUTHORITIES = "Admin - Authorities",
	FINANCIAL = "Admin - Financial",
	ERRORS = "Admin - Errors",
	ALERTS = "Admin - Alerts",
	BATTERY_LEVEL = "Admin - Battery Level",
	GROUPS = "Admin - Groups",
	COUNTRIES = "Admin - Countries",
	TRANSLATIONS = "Admin - Translations",
	FORM = "Admin - forms"
}

export enum Topic {

	TAG = "/topic/tag",
	GATEWAY = "/topic/gateway",
	ALERT = "/topic/alert"
}








export enum Authorities {

	SPECIES = "animalTypes",
	BREEDS = "breeds",
	ROLES = "roles",
	ADDRESS = "addresses",
	AUTHORITY_ROLES = "authorityRoles",
	ANIMALS = "animals",
	DEVICE_ANIMAL_HISTORIES = "deviceAnimalHistories",
	GATEWAYS = "gateways",
	TAGS = "devices",
	IMPORT_FROM_EXCEL = "import_from_excel",
	ANIMAL_NOTES = "animalNotes",
	FARMERS = "farmers",
	SUPERVISORS = "supervisors",
	USERS = "customers",
	ERRORS = "errors",
	ALERTS = "alerts",
	REPORTS_GRAPHS = "reports/graphs",
	REPORTS_EXPORT_INFO = "reports/export-info",
	REPORTS_RUMINATION = "reports/rumination",
	REPORTS_MILK_PRODUCTION = "reports/milk-production",
	REPORTS_WEIGHT_AGE = "reports/weight-age",
	REPORTS_ALERTS_STATS = "reports/alerts-stats",
	REPORTS_FEED_CONSUMPTION = "reports/feed-consumption",
	GROUPS = "groups",
	RUMINATION_UI = "reports/rumination-ui",
	PEDIGREE = "pedigree",
	REPORTS_EXPORT_LIVESTOCK = "reports/export-livestock",
	CUSTOMER_ANIMAL_GROUPS = "customerAnimalGroups",
	ORDERS = "orders",
	TIMELINE_CHILD = "timelineChilds",
	REPORTS_TEMP_GRAPHS = "reports/temp-graphs",
	ENTREPRISES = "entreprises",
	TANKS = "tanks",
	REPORTS_HUMIDITY_TEMP = "reports/humidity-temp",
	REPORTS_EXPOR_HUMIDITY_TEMPT = "reports/export-humidity-temp",
	COUNTRY = "countries",
	TRANSLATIONS = "translations",
	VERSION = "appVersions",
	TIMELINES = "timelines",
	CUSTOMER_ALERT = "customerAlerts",
	NOTIFICATIONS = "notifications",
	REPORT = "reports",
	NO_ACCESSIBLE = "no-accessible",
	CUSTOMER_SETTING = "customerSettings",
	ALERT_TYPE = "alertTypes",
	ALERT_TYPE_GROUP = "alertTypeGroups",
	PROJECT = "projects",
	FORM_TEMPLATE = "formTemplates",
	FORM = "forms",
	FARMER_MILK_PRODUCTION = "farmerMilkProductions",
	FARMER_FEED_CONSUMPTION = "farmerFeedConsumptions",
	KPI_REPORT = "kpiReports",
	FEEDBACK = "customerFeedbacks",
	SUBSCRIPTION = "subscriptionRequests",
	EMAIL = "emails",
	PAYMENT = "payments",
	REPORTS_FEED_MILK = "reports/feedMilk",
	LIKE = "customerLikes",
	SHOP_FORM_TEMPLATE = "shopFormTemplates",
	MILK_GRAPH = "reports/milk-graphs",
	CALVING_GRAPH = "reports/calving-graphs",
	CUSTOMER_ANIMAL = "customerAnimals",
	ANIMAL_MILK_PRODUCTION = "animalMilkProductions",
	ANIMAL_MILK_GRAPH = "reports/animal-milk-graphs",
	STOCK = "farmerStockFeeds",
	GENERAL_REPORT="generalReports"
}

export enum SearchRep {
	GET = "get",
	GET_SUPERVISOR = "getSupervisors",
	GET_FARMERS = "getFarmers",
	GET_USERS = "getUsers",
	FARMERS = "farmers",
	SUPERVISORS = "supervisors",
	GET_SUPERVISORS_BY_KEY = "getSupervisorsByKey"
}

export enum Type {
	ADD_NOTE = "ADD_NOTE",
	CALVING_DATE = "CALVING_DATE",
	HEAT_DATE = "HEAT_DATE",
	INSE_DATE = "INSE_DATE",
	MILK_PROD = "MILK_PROD",
	WEIGHT = "WEIGHT",
	HEIGHT = "HEIGHT"
}
export enum SectionForm {
	advisor_farmer_settings = "advisor_farmer_settings",
	farmer_milk_production = "farmer_milk_production",
	farmer_feed_consumption = "farmer_feed_consumption",
	farmer_economy = "farmer_economy",
	animal_milk_production = "animal_milk_production",
	farmer_stock = "farmer_stock"
}

export enum SettingsFarmer {
	MILK_QUANTITY = "MILK_QUANTITY",
	MILK_QUALITY = "MILK_QUALITY",
	NOTES = "NOTES",
	SERVICES = "SERVICES",
	MATERAIL = "MATERAIL",

}
export enum SettingsFarmerByLabel {
	MILK_QUANTITY = "MILK QUANTITY",
	MILK_QUALITY = "MILK QUALITY",
	NOTES = "NOTES",
	SERVICES = "SERVICES",
	MATERAIL = "MATERAIL",

}
export enum AnimalType {
	COW = "COW"
}

export enum Status {
	IA_DONE = "IA_DONE",
	GIVE_BIRTH = "GIVE_BIRTH",
	GIVE_BIRTH_END = "GIVE_BIRTH_END",
	V1_NO_ACCESSIBLE = "V1_NO_ACCESSIBLE",
	V2_NO_ACCESSIBLE = "V2_NO_ACCESSIBLE",
	IA_WAITING = "IA_WAITING",
	IA_POSITIVE = "IA_POSITIVE",
	IA_NEGATIVE = "IA_NEGATIVE",
	X_NO_ACCESSIBLE = "X_NO_ACCESSIBLE",
	X_ACCESSIBLE = "X_ACCESSIBLE",
	T_NO_ACCESSIBLE = "T_NO_ACCESSIBLE",
	T_REAL = "T_REAL",
	GIVE_BIRTH_END_CONFIRMED = "GIVE_BIRTH_END_CONFIRMED",
	X_CALVING = "X_CALVING",
	IA_START = "IA_START",
	CONTROL0_NO_ACCESSIBLE = "CONTROL0_NO_ACCESSIBLE",
	CONTROL0_TEST = "CONTROL0_TEST",
	CONTROL0_ACCESSIBLE = "CONTROL0_ACCESSIBLE",
	CONTROL0_POSITIVE = "CONTROL0_POSITIVE",
	CONTROL0_NEGATIVE = "CONTROL0_NEGATIVE",
	CONTROL1_NO_ACCESSIBLE = "CONTROL1_NO_ACCESSIBLE",
	CONTROL1_TEST = "CONTROL1_TEST",
	CONTROL1_ACCESSIBLE = "CONTROL1_ACCESSIBLE",
	CONTROL1_POSITIVE = "CONTROL1_POSITIVE",
	CONTROL1_NEGATIVE = "CONTROL1_NEGATIVE",
	CONTROL2_NO_ACCESSIBLE = "CONTROL2_NO_ACCESSIBLE",
	CONTROL2_TEST = "CONTROL2_TEST",
	CONTROL2_ACCESSIBLE = "CONTROL2_ACCESSIBLE",
	CONTROL2_POSITIVE = "CONTROL2_POSITIVE",
	CONTROL2_NEGATIVE = "CONTROL2_NEGATIVE",
}


/*export enum CodeAlerts {
	CALVING = "01",
	AI = "02",
	CONTROL = "03",
	DRYING = "04",
	SICKNESS = "05",
	GROUP_CHANGE = "06",
	CONNECTION_STATE = "07",
	HIGH_THI = "08",
	DEVICE_TANK_STATUS = "09"
}
*/

export enum TypeAlerts {
	CALVING = "C",
	AI = "AI",
	CONTROL = "Controle",
	DRYING = "D",
	SICKNESS = "SICKNESS",
	COLLARS = "CONNECTION STATE",
	GROUP_CHANGE = "GROUP_CHANGE",
	HIGH_THI = "THI",
	TANKS_ALERTS = "TANKS ALERTS"
	//DEVICE_TANK_STATUS = "TANKS ALERTS"
}

export enum StatusRequest {
	WAITING = "WAITING",
	ACCEPTED = "ACCEPTED",
	REFUSED = "REFUSED",

}
export class Step {
	public static checkContolPositive(status: any) {
		switch (status) {
			case Status.CONTROL0_POSITIVE:
			case Status.CONTROL1_POSITIVE:
			case Status.CONTROL2_POSITIVE:
			case Status.IA_DONE:
				return true
			default:
				return false
		}
	}

	/*public static checkContol0(status: any) {
		switch (status) {
			case Status.CONTROL0_POSITIVE:
			case Status.CONTROL0_ACCESSIBLE:
				return true
			default:
				return false
		}
	}*/

	public static checkContolNegative(status: any) {
		switch (status) {
			case Status.CONTROL0_NEGATIVE:
			case Status.CONTROL1_NEGATIVE:
			case Status.CONTROL2_NEGATIVE:
				return true
			default:
				return false
		}
	}
	public static isIa(status: any) {
		switch (status) {
			case Status.IA_POSITIVE:
			case Status.IA_NEGATIVE:
			case Status.IA_DONE:
			case Status.IA_WAITING:
			case Status.IA_START:
				return true
			default:
				return false
		}
	}

	public static isIaD(status: any) {
		switch (status) {
			case Status.IA_POSITIVE:
			case Status.IA_NEGATIVE:
			case Status.IA_DONE:
				return true
			default:
				return false
		}
	}
	/*public static isControl(status: any) {
		switch (status) {
			case Status.CONTROL0_ACCESSIBLE: case Status.CONTROL0_POSITIVE: case Status.CONTROL0_NEGATIVE:
			case Status.CONTROL1_ACCESSIBLE: case Status.CONTROL1_POSITIVE: case Status.CONTROL1_NEGATIVE:
			case Status.CONTROL2_ACCESSIBLE: case Status.CONTROL2_POSITIVE: case Status.CONTROL2_NEGATIVE:
				return true
			default:
				return false
		}
	}*/

	public static isXCalving(status: any) {
		switch (status) {
			case Status.X_ACCESSIBLE:
			case Status.X_NO_ACCESSIBLE:
			case Status.X_CALVING:
				return true
			default:
				return false
		}
	}
	public static showCows(status: Status) {
		switch (status) {
			case Status.GIVE_BIRTH:
			case Status.GIVE_BIRTH_END:
			case Status.GIVE_BIRTH_END_CONFIRMED:
				return true
			default:
				return false
		}
	}

	public static numAi(timelineDetail) {
		switch (timelineDetail.status) {
			case Status.IA_POSITIVE:
			case Status.IA_DONE:
			case Status.IA_WAITING:
			case Status.IA_NEGATIVE:
			case Status.IA_START:
				return timelineDetail.label.substr(timelineDetail.label.length - 1);
			default:
				return timelineDetail.style.num
		}
	}
	public static style(status: Status) {
		switch (status) {
			case Status.IA_DONE:
				return { 'backgroundColor': '#306a90', 'color': 'white' }
			case Status.GIVE_BIRTH:
				return { 'backgroundColor': '#306a90', 'color': 'white' }
			case Status.GIVE_BIRTH_END:
				return { 'backgroundColor': '#A4A4A4', 'color': 'white' }
			case Status.V1_NO_ACCESSIBLE:
				return { 'backgroundColor': '#A4A4A4', 'color': 'white' }
			case Status.V2_NO_ACCESSIBLE:
				return { 'backgroundColor': '#A4A4A4', 'color': 'white' }
			case Status.IA_WAITING:
				return { 'backgroundColor': '#A4A4A4', 'color': 'white' }
			case Status.IA_POSITIVE:
				return { 'backgroundColor': '#80cc28', 'color': 'white' }
			case Status.IA_NEGATIVE:
				return { 'backgroundColor': '#FC4242', 'color': 'white' }
			case Status.CONTROL1_NO_ACCESSIBLE:
				return { 'backgroundColor': '#A4A4A4', 'color': 'white' }
			case Status.CONTROL1_ACCESSIBLE:
				return { 'backgroundColor': '#F7AC08', 'color': 'white' }
			case Status.CONTROL1_TEST:
				return { 'backgroundColor': '#F7AC08', 'color': 'white' }
			case Status.CONTROL2_NO_ACCESSIBLE:
				return { 'backgroundColor': '#A4A4A4', 'color': 'white' }
			case Status.CONTROL2_ACCESSIBLE:
				return { 'backgroundColor': '#F7AC08', 'color': 'white' }
			case Status.X_NO_ACCESSIBLE:
				return { 'backgroundColor': '#306a90', 'color': 'white' }
			case Status.X_ACCESSIBLE:
				return { 'backgroundColor': 'black', 'color': 'white' }
			case Status.T_NO_ACCESSIBLE:
				return { 'backgroundColor': '#A4A4A4', 'color': 'white' }
			case Status.T_REAL:
				return { 'backgroundColor': '#632289', 'color': 'white' }
			case Status.GIVE_BIRTH_END_CONFIRMED:
				return { 'backgroundColor': '#306a90', 'color': 'white' }
			case Status.X_CALVING:
				return { 'backgroundColor': 'black', 'color': 'white' }
			case Status.IA_START:
				return { 'backgroundColor': '#A4A4A4', 'color': 'white' }

		}
	}

	public static styleApp(status: Status) {
		switch (status) {
			case Status.IA_DONE:
				return { 'backgroundColor': '#306a90', 'color': 'white', 'icon': '/assets/images/timeline/ia.svg' }
			case Status.GIVE_BIRTH:
				return { 'backgroundColor': '#306a90', 'icon': '/assets/images/timeline/GIVE_BIRTH.svg' }
			case Status.GIVE_BIRTH_END:
				return { 'backgroundColor': '#CECECE', 'icon': '/assets/images/timeline/V1_NO_ACCESSIBLE.svg' }
			case Status.V1_NO_ACCESSIBLE:
				return { 'backgroundColor': '#CECECE', 'icon': '/assets/images/timeline/V1_NO_ACCESSIBLE.svg' }
			case Status.V2_NO_ACCESSIBLE:
				return { 'backgroundColor': '#CECECE', 'icon': '/assets/images/timeline/V1_NO_ACCESSIBLE.svg' }
			case Status.IA_WAITING:
				return { 'backgroundColor': '#CECECE', 'icon': '/assets/images/timeline/IA-WAITING.svg', 'color': '#204157', }
			case Status.IA_POSITIVE:
				return { 'backgroundColor': '#80cc28', 'color': 'white', 'icon': '/assets/images/timeline/ia.svg' }
			case Status.IA_NEGATIVE:
				return { 'backgroundColor': '#FC4242', 'color': 'white', 'icon': '/assets/images/timeline/ia.svg' }
			case Status.CONTROL0_NO_ACCESSIBLE:
			case Status.CONTROL0_TEST:
				return { 'backgroundColor': '#CECECE', 'color': '#204157', 'icon': '/assets/images/timeline/C0_eye.svg' }
			case Status.CONTROL1_NO_ACCESSIBLE:
			case Status.CONTROL1_TEST:
				return { 'backgroundColor': '#CECECE', 'color': '#204157', 'icon': '/assets/images/timeline/C1_test_tube.svg' }
			case Status.CONTROL2_NO_ACCESSIBLE:
			case Status.CONTROL2_TEST:
				return { 'backgroundColor': '#CECECE', 'color': '#204157', 'icon': '/assets/images/timeline/C2_palpation.svg' }
			case Status.CONTROL0_ACCESSIBLE:
			case Status.CONTROL0_POSITIVE:
				return { 'backgroundColor': '#F7AC08', 'color': 'white', 'icon': '/assets/images/timeline/C0_eye _accessible.svg' }
			case Status.CONTROL1_ACCESSIBLE:
			case Status.CONTROL1_POSITIVE:
				return { 'backgroundColor': '#F7AC08', 'color': 'white', 'icon': '/assets/images/timeline/C1_test_tube _accessible.svg' }
			case Status.CONTROL2_ACCESSIBLE:
			case Status.CONTROL2_POSITIVE:
				return { 'backgroundColor': '#F7AC08', 'color': 'white', 'icon': '/assets/images/timeline/C2_palpation_accessible.svg' }
			case Status.T_NO_ACCESSIBLE:
				return { 'backgroundColor': '#CECECE', 'icon': '/assets/images/timeline/T_NO_ACCESSIBLE.svg' }
			case Status.T_REAL:
				return { 'backgroundColor': '#632289', 'icon': '/assets/images/timeline/T_ACCESSIBLE.svg' }
			case Status.GIVE_BIRTH_END_CONFIRMED:
				return { 'backgroundColor': '#306a90', 'icon': '/assets/images/timeline/GIVE_BIRTH.svg' }
			case Status.X_CALVING:
			case Status.X_ACCESSIBLE:
				return { 'backgroundColor': 'black', 'icon': '/assets/images/timeline/abort.svg' }
			case Status.IA_START:
				return { 'backgroundColor': '#CECECE', 'icon': '/assets/images/timeline/IA-WAITING.svg', 'color': '#204157', }
			/*case Status.CONTROL0_POSITIVE:
				return { 'backgroundColor': '#80cc28', 'color': 'white', 'icon': '/assets/images/timeline/C0_eye _accessible.svg' }
			*/case Status.CONTROL0_NEGATIVE:
				return { 'backgroundColor': '#FC4242', 'color': 'white', 'icon': '/assets/images/timeline/C0_eye _accessible.svg' }
			/*case Status.CONTROL1_POSITIVE:
				return { 'backgroundColor': '#80cc28', 'color': 'white', 'icon': '/assets/images/timeline/C1_test_tube _accessible.svg' }
		*/	case Status.CONTROL1_NEGATIVE:
				return { 'backgroundColor': '#FC4242', 'color': 'white', 'icon': '/assets/images/timeline/C1_test_tube _accessible.svg' }
			/*case Status.CONTROL2_POSITIVE:
				return { 'backgroundColor': '#80cc28', 'color': 'white', 'icon': '/assets/images/timeline/C2_palpation_accessible.svg' }
			*/case Status.CONTROL2_NEGATIVE:
				return { 'backgroundColor': '#FC4242', 'color': 'white', 'icon': '/assets/images/timeline/C2_palpation_accessible.svg' }
		}
	}


	public static styleColorDate(status: Status) {
		switch (status) {
			case Status.CONTROL0_ACCESSIBLE: case Status.CONTROL1_ACCESSIBLE: case Status.CONTROL2_ACCESSIBLE: case Status.IA_DONE: case Status.GIVE_BIRTH: case Status.T_REAL:
			case Status.IA_NEGATIVE: case Status.IA_POSITIVE:
			case Status.CONTROL0_POSITIVE: case Status.CONTROL0_NEGATIVE:
			case Status.CONTROL1_POSITIVE: case Status.CONTROL1_NEGATIVE:
			case Status.CONTROL2_POSITIVE: case Status.CONTROL2_NEGATIVE:
			case Status.X_ACCESSIBLE: case Status.GIVE_BIRTH_END_CONFIRMED: case Status.X_CALVING:
				return '#204157'
			default:
				return '#AFAFAF'
		}
	}

	/*public static controlAccessible(status: Status) {
		switch (status) {
			case Status.CONTROL0_ACCESSIBLE:
			case Status.CONTROL1_ACCESSIBLE:
			case Status.CONTROL2_ACCESSIBLE:
				return true
			default:
				return false
		}
	}*/
	public static controlResult(status: Status) {

		switch (status) {
			case Status.IA_POSITIVE:
				return ControlResult.POSITIVE
			case Status.IA_NEGATIVE:
				return ControlResult.NEGATIVE
			default:
				return null
		}
	}

	public static gestationControl(status: Status) {
		switch (status) {
			case Status.CONTROL0_POSITIVE:
			case Status.CONTROL1_POSITIVE:
			case Status.CONTROL2_POSITIVE:
			case Status.CONTROL0_NEGATIVE:
			case Status.CONTROL1_NEGATIVE:
			case Status.CONTROL2_NEGATIVE:
			case Status.CONTROL0_ACCESSIBLE:
			case Status.CONTROL1_ACCESSIBLE:
			case Status.CONTROL2_ACCESSIBLE:
				return true
			default:
				return false
		}
	}

	public static isEnd(status: Status) {
		switch (status) {
			case Status.X_ACCESSIBLE: case Status.GIVE_BIRTH_END_CONFIRMED:
				return true
			default:
				return false
		}
	}

	public static isEditable(status: Status) {
		switch (status) {
			case Status.V1_NO_ACCESSIBLE:
			case Status.GIVE_BIRTH:
			case Status.GIVE_BIRTH_END:
			case Status.GIVE_BIRTH_END_CONFIRMED:
			case Status.X_CALVING:
			case Status.X_NO_ACCESSIBLE:
			case Status.X_ACCESSIBLE:
			case Status.IA_POSITIVE:
			case Status.IA_NEGATIVE:
			case Status.IA_DONE:
			case Status.IA_WAITING:
			case Status.IA_START:
			case Status.T_REAL:
				/*case Status.CONTROL0_ACCESSIBLE: case Status.CONTROL1_ACCESSIBLE: case Status.CONTROL2_ACCESSIBLE:
				case Status.CONTROL0_POSITIVE: case Status.CONTROL0_NEGATIVE:
				case Status.CONTROL1_POSITIVE: case Status.CONTROL1_NEGATIVE:
				case Status.CONTROL2_POSITIVE: case Status.CONTROL2_NEGATIVE:*/
				return true
			default:
				return false
		}
	}


	public static isNoEditable(status: Status) {
		switch (status) {
			//	case Status.CONTROL0_NO_ACCESSIBLE:
			//case Status.CONTROL0_TEST:
			//case Status.CONTROL1_NO_ACCESSIBLE:
			//case Status.CONTROL1_TEST:
			//case Status.CONTROL2_NO_ACCESSIBLE:
			//case Status.CONTROL2_TEST:
			case Status.V2_NO_ACCESSIBLE:
			case Status.T_NO_ACCESSIBLE:
				return true
			default:
				return false
		}
	}

	public static isNoEditableMessage(status: Status, translateService) {
		switch (status) {
			case Status.CONTROL0_NO_ACCESSIBLE:
			case Status.CONTROL0_TEST:
				return translateService.instant('C0_DATE_TODAY')
			case Status.CONTROL1_NO_ACCESSIBLE:
			case Status.CONTROL1_TEST:
				return translateService.instant('C1_DATE_TODAY')
			case Status.CONTROL2_NO_ACCESSIBLE:
			case Status.CONTROL2_TEST:
				return translateService.instant('C2_DATE_TODAY')
			/*case Status.V2_NO_ACCESSIBLE:
				return translateService.instant('NEW_V_DATE_TODAY')
			case Status.T_NO_ACCESSIBLE:
				return translateService.instant('T_DATE_TODAY')*/

		}
	}

	public static getTimelineMax(timelineDetail: TimelineDetail) {
		switch (timelineDetail.status) {
			case Status.GIVE_BIRTH_END: case Status.GIVE_BIRTH_END_CONFIRMED: case Status.V2_NO_ACCESSIBLE:
				return timelineDetail['nbreDay']
		}
	}


	public static isAbort(status: Status) {
		switch (status) {
			case Status.X_ACCESSIBLE:
				return true

		}
	}


	public static displayInfoAbort(status: Status) {
		switch (status) {
			case Status.X_ACCESSIBLE:
			case Status.X_CALVING:
				return true

		}
	}
	public static displayInfoIa(status: Status) {
		switch (status) {
			case Status.IA_POSITIVE:
			case Status.IA_NEGATIVE:
			case Status.IA_DONE:
			case Status.IA_WAITING:
			case Status.IA_START:
				//case Status.GIVE_BIRTH: 
				return true
			default:
				return false
		}
	}
	public static isStart(status: Status) {
		switch (status) {
			case Status.V1_NO_ACCESSIBLE:
			case Status.GIVE_BIRTH:
			case Status.GIVE_BIRTH_END:
			case Status.GIVE_BIRTH_END_CONFIRMED:
				return true

		}
	}

	public static isC0(status: Status) {
		switch (status) {
			case Status.CONTROL0_ACCESSIBLE:
			case Status.CONTROL0_POSITIVE:
			case Status.CONTROL0_NEGATIVE:
				return true

		}
	}

	public static isC1(status: Status) {
		switch (status) {
			case Status.CONTROL1_ACCESSIBLE:
			case Status.CONTROL1_POSITIVE:
			case Status.CONTROL1_NEGATIVE:
				return true

		}
	}

	public static isC2(status: Status) {
		switch (status) {
			case Status.CONTROL2_ACCESSIBLE:
			case Status.CONTROL2_POSITIVE:
			case Status.CONTROL2_NEGATIVE:
			case Status.CONTROL2_TEST:
				return true

		}
	}


	public static isPositifIA(status: Status) {
		switch (status) {
			case Status.IA_POSITIVE:
				return true

		}
	}

	public static getTitle(status: Status, label?, translateService?) {
		switch (status) {
			case Status.X_CALVING: case Status.X_NO_ACCESSIBLE: case Status.X_ACCESSIBLE:
				return 'ABORTION'
			case Status.IA_POSITIVE: case Status.IA_NEGATIVE: case Status.IA_DONE: case Status.IA_WAITING: case Status.IA_START:
				return translateService.instant('IA') + " " + translateService.instant(label.substring(2))
			case Status.T_REAL:
				return translateService.instant('DATES') + " " + translateService.instant('DRYINGS')
			/*case Status.CONTROL0_NEGATIVE: case Status.CONTROL0_POSITIVE: case Status.CONTROL0_ACCESSIBLE:
				return "VISUAL_CONTROL"
			case Status.CONTROL1_NEGATIVE: case Status.CONTROL1_POSITIVE: case Status.CONTROL1_ACCESSIBLE:
				return "MILK_ANALYSIS"
			case Status.CONTROL2_NEGATIVE: case Status.CONTROL2_POSITIVE: case Status.CONTROL2_ACCESSIBLE:
				return "VETERINARY_DIAGNOSTIC"*/
			default:
				return 'THIS_CALVING'
		}
	}

	public static getLabelCheck(status: Status, label?, translateService?) {
		switch (status) {
			case Status.X_CALVING: case Status.X_NO_ACCESSIBLE: case Status.X_ACCESSIBLE:
				return 'NEW_ABORTION'
			case Status.IA_POSITIVE: case Status.IA_NEGATIVE: case Status.IA_DONE: case Status.IA_WAITING: case Status.IA_START:
				return translateService.instant('IA') + " " + translateService.instant(label.substring(2)) + ' ' + translateService.instant('PERFORM')
			case Status.T_REAL:
				return translateService.instant('DATES') + " " + translateService.instant('DRYINGS')
			/*case Status.CONTROL0_NEGATIVE: case Status.CONTROL0_POSITIVE: case Status.CONTROL0_ACCESSIBLE:
			return "NEW_VISUAL_CONTROL"
		case Status.CONTROL1_NEGATIVE: case Status.CONTROL1_POSITIVE: case Status.CONTROL1_ACCESSIBLE:
			return "NEW_MILK_ANALYSIS"
		case Status.CONTROL2_NEGATIVE: case Status.CONTROL2_POSITIVE: case Status.CONTROL2_ACCESSIBLE:
			return "NEW_VETERINARY_DIAGNOSTIC"*/
			default:
				return 'NEW_CALVING'
		}
	}

	public static getLabelDate(status: Status, label?, translateService?) {
		switch (status) {
			case Status.X_CALVING: case Status.X_NO_ACCESSIBLE: case Status.X_ACCESSIBLE:
				return 'date_ABORTION'
			case Status.IA_POSITIVE: case Status.IA_NEGATIVE: case Status.IA_DONE: case Status.IA_WAITING: case Status.IA_START: case Status.T_REAL:
				return translateService.instant('DATE') + ' ' + translateService.instant(label)
			default:
				return 'CALVING_DATE'
		}
	}
	public static checkTitleDry(title: any) {
		switch (title) {
			case "Date Drying":
			case "la date Tarissement":
			case "تاريخ التغريز":
			case "Tanggal Pengeringan":
				return true;
			default:
				return false;
		}
	}

	public static messageValid(status: Status, translateService, minLabel, dateToday, dateC0, isC0, isC1, isC2, dateLast, dateAbort, dateAbortAfterCalving) {

		switch (status) {
			case Status.IA_WAITING:
			case Status.IA_POSITIVE:
			case Status.IA_NEGATIVE:
			case Status.IA_DONE:
			case Status.IA_START:
				if (minLabel == labelStep.C)
					return translateService.instant('IA1_DATE_SUPER_V_DATE')
				else if (dateToday)
					return translateService.instant('IA_DATE_TODAY')
				else return translateService.instant('THE_DATE') + translateService.instant('IA_DATE_RECENT_IA_DATE')

			//return translateService.instant('THE_DATE') + translateService.instant(label) + translateService.instant('IA_DATE_RECENT_IA_DATE') + translateService.instant(minLabel)
			case Status.GIVE_BIRTH_END_CONFIRMED:
			case Status.GIVE_BIRTH_END:
				if (dateToday)
					return translateService.instant('NEW_V_DATE_SUPER_D_DATE')
				else
					return translateService.instant('NEW_V_DATE_SUPER_T_DATE')
			case Status.X_NO_ACCESSIBLE:
			case Status.X_ACCESSIBLE:
				if (dateAbortAfterCalving)
					return translateService.instant('DATE_ABOR')
				else if (dateAbort)
					return translateService.instant('X_DATE_SUPER_IA_POSITIF_DATE')

			case Status.CONTROL0_ACCESSIBLE:
			case Status.CONTROL0_POSITIVE:
			case Status.CONTROL0_NEGATIVE:
				if (!isC0)
					return translateService.instant('C0_DATE')
				else if (dateToday)
					return translateService.instant('C0_DATE_TODAY')
				else if (dateLast)
					return translateService.instant('C0_DATE_MIN')
				else return translateService.instant('C0_DATE_MIN')
			case Status.CONTROL1_ACCESSIBLE:
			case Status.CONTROL1_POSITIVE:
			case Status.CONTROL1_NEGATIVE:
				if (!isC1)
					return translateService.instant('C1_DATE')
				else if (dateToday)
					return translateService.instant('C1_DATE_TODAY')
				else if (dateLast)
					return translateService.instant('C1_DATE_MIN')
				else
					return translateService.instant('C1_DATE_MIN')
			case Status.CONTROL2_ACCESSIBLE:
			case Status.CONTROL2_POSITIVE:
			case Status.CONTROL2_NEGATIVE:
				if (!isC2)
					return translateService.instant('C2_DATE')
				else if (dateToday)
					return translateService.instant('C2_DATE_TODAY')
				else if (dateLast)
					return translateService.instant('C2_DATE_MIN')
				else
					return translateService.instant('C2_DATE_MIN')

			/*case Status.GIVE_BIRTH_END_CONFIRMED: case Status.GIVE_BIRTH_END:
				if (minLabel == labelStep.C)
					return translateService.instant('NEW_V_DATE_SUPER_D_DATE')*/
		}
	}




}
export class AlertsType {

	/*public static typeAlerts(type: TypeAlerts) {
		switch (type) {
			case TypeAlerts.CALVING:
				return { 'icon': '/assets/images/alert/Velage-2.svg', 'color': '#416a7d' }
			case TypeAlerts.AI:
				return { 'icon': '/assets/images/alert/ia_2.svg', 'color': '#80cc28' }
			case TypeAlerts.CONTROL:
				return { 'icon': '/assets/images/alert/Controle2.svg', 'color': '#f6a001' }
			case TypeAlerts.DRYING:
				return { 'icon': '/assets/images/alert/Taris2.svg', 'color': '#67549d' }
			case TypeAlerts.SICKNESS:
				return { 'icon': '/assets/images/alert/Malade2.svg', 'color': '#f1493b' }
			case TypeAlerts.COLLARS:
				return { 'icon': '/assets/images/alert/pb_equipment2.svg', 'color': '#30424A' }
			case TypeAlerts.GROUP_CHANGE:
				return { 'icon': '/assets/images/alert/Group_change2.svg', 'color': '#30424A' }
			case TypeAlerts.HIGH_THI:
				return { 'icon': '/assets/images/alert/THI_high2.svg', 'color': '#30424A' }
			case TypeAlerts.DEVICE_TANK_STATUS:
				return { 'icon': '/assets/images/milk_tank_alert2.svg', 'color': '#30424A' }
		}
	}*/

	public static timelineRouter(type: TypeAlerts) {
		switch (type) {
			case TypeAlerts.CALVING:
			case TypeAlerts.AI:
			case TypeAlerts.CONTROL:
			case TypeAlerts.DRYING:
			case TypeAlerts.GROUP_CHANGE:
				return true
			case TypeAlerts.HIGH_THI:
			case TypeAlerts.SICKNESS:
			case TypeAlerts.COLLARS:
				return false
			default:
				return true
		}
	}

	/*	public static getBarCompList() {
			return [
				{ type: "C", icon: '/assets/images/alert/Velage3.svg', color: '#416a7d', authority: Authorities.ANIMALS },
				{ type: "AI", icon: "/assets/images/alert/ia3.svg", color: "#80cc28", authority: Authorities.ANIMALS },
				{ type: "Controle", icon: "/assets/images/alert/Controle3.svg", color: "#f6a001", authority: Authorities.ANIMALS },
				{ type: "D", icon: "/assets/images/alert/Taris3.svg", color: "#67549d", authority: Authorities.ANIMALS },
				{ type: "SICKNESS", icon: "/assets/images/alert/Malade3.svg", color: '#f1493b', authority: Authorities.ANIMALS },
				{ type: "GROUP_CHANGE", icon: '/assets/images/alert/Group_change3.svg', color: "#30424A", authority: Authorities.ANIMALS },
				{ type: "CONNECTED_DEVICE", icon: '/assets/images/alert/pb_equipment3.svg', color: '#30424A' },
				{ type: "HIGH_THI", icon: '/assets/images/alert/THI_high3.svg', color: '#30424A', authority: Authorities.ANIMALS },
				{ type: "DEVICE_TANK_STATUS", icon: '/assets/images/milk_tank_alert3.svg', color: '#416a7d', authority: Authorities.TANKS }
	
			]
		}*/
}


/*export class AlertsCode {

	public static codeAlerts(code: CodeAlerts) {
		switch (code) {
			case CodeAlerts.CALVING:
				return { 'icon': '/assets/images/alert/Velage-2.svg', 'color': '#416a7d', title: 'C' }
			case CodeAlerts.AI:
				return { 'icon': '/assets/images/alert/ia_2.svg', 'color': '#80cc28', title: 'AI' }
			case CodeAlerts.CONTROL:
				return { 'icon': '/assets/images/alert/Controle2.svg', 'color': '#f6a001', title: 'Controle' }
			case CodeAlerts.DRYING:
				return { 'icon': '/assets/images/alert/Taris2.svg', 'color': '#67549d', title: 'D' }
			case CodeAlerts.SICKNESS:
				return { 'icon': '/assets/images/alert/Malade2.svg', 'color': '#f1493b', title: 'SICKNESS' }
			case CodeAlerts.CONNECTION_STATE:
				return { 'icon': '/assets/images/alert/pb_equipment2.svg', 'color': '#30424A', title: 'CONNECTED_DEVICE' }
			case CodeAlerts.GROUP_CHANGE:
				return { 'icon': '/assets/images/alert/Group_change2.svg', 'color': '#30424A', title: 'GROUP_CHANGE' }
			case CodeAlerts.HIGH_THI:
				return { 'icon': '/assets/images/alert/THI_high2.svg', 'color': '#30424A', title: 'THI' }
			case CodeAlerts.DEVICE_TANK_STATUS:
				return { 'icon': '/assets/images/milk_tank_alert2.svg', 'color': '#416a7d', title: 'DEVICE_TANK_STATUS' }
		}
	}
}*/

export enum typeAlert {
	DISCONNECTED_DEVICE = "DISCONNECTED_DEVICE",
	DISCONNECTED_DEVICE_1H = "DISCONNECTED_DEVICE_1H",
	DISCONNECTED_GATEWAY_30S = "DISCONNECTED_GATEWAY_30S",
	CONNECTED_DEVICE_30S = "CONNECTED_DEVICE_30S",
	DISCONNECTED_DEVICE_30S = "DISCONNECTED_DEVICE_30S",
	BATTERY_STATE = "BATTERY_STATE",
	BATTERY_LEVEL_DEVICE = "BATTERY_LEVEL_DEVICE",
	BAD_BATTERY_LEVEL_DEVICE = "BAD_BATTERY_LEVEL_DEVICE",
	AI = "AI",
	C0 = "C0",
	C1 = "C1",
	C2 = "C2",
	D = "D",
	C = "C",
	Controle = "Controle",
	CONNECTED_DEVICE = "CONNECTED_DEVICE",
	DISCONNECTED_GATEWAY = "DISCONNECTED_GATEWAY",
	CONNECTED_GATEWAY = "CONNECTED_GATEWAY",
	GROUP_CHANGE = "GROUP_CHANGE",
	DEVICE_TANK_STATUS = "DEVICE_TANK_STATUS",
	OVERCOOLING1 = "OVERCOOLING1",
	OVERCOOLING2 = "OVERCOOLING2",
	RISK_MILK = "RISK_MILK",
	HIGH_TEMP = "HIGH_TEMP",
	MOVEMENT_ON_FRIDGE = "MOVEMENT_ON_FRIDGE",
	MOVEMENT_ON_AGITATOR = "MOVEMENT_ON_AGITATOR",
	CLEANING = "CLEANING",
	PROBLEM_AGITATOR1 = "PROBLEM_AGITATOR1",
	PROBLEM_AGITATOR2 = "PROBLEM_AGITATOR2",
	LOW_REST = "LOW_REST",
	LOW_RUM = "LOW_RUM",
	DROP_REST = "DROP_REST",
	DROP_RUM = "DROP_RUM",
	SICKNESS = "SICKNESS",
	HIGH_THI = "HIGH_THI",
	HIGHT_REST = "HIGHT_REST",
	RECALL = "RECALL"
}
export class SettingsFarmers {
	public static checkTypeSettingsFarmer(status: any) {

		switch (status) {
			case SettingsFarmer.MILK_QUANTITY:
			case SettingsFarmer.MILK_QUALITY:
			case SettingsFarmer.MATERAIL:
			case SettingsFarmer.NOTES:
			case SettingsFarmer.SERVICES:

				return true
			default:
				return false
		}
	}
	public static checkTypeMilk(status: any) {

		switch (status) {
			case SettingsFarmer.MILK_QUANTITY:
			case SettingsFarmer.MILK_QUALITY:

				return true
			default:
				return false
		}
	}

}
export class TypeAlert {
	public static checkTypeAlertTimeline(status: any) {

		switch (status) {
			case typeAlert.AI:
			case typeAlert.C0:
			case typeAlert.C1:
			case typeAlert.C2:
			case typeAlert.D:
			case typeAlert.C:
			case typeAlert.GROUP_CHANGE:
				return true
			default:
				return false
		}
	}
	public static checkTypeAlertTanks(status: any) {

		switch (status) {
			//case typeAlert.DEVICE_TANK_STATUS:
			case typeAlert.OVERCOOLING1:
			case typeAlert.OVERCOOLING2:
			case typeAlert.RISK_MILK:
			case typeAlert.HIGH_TEMP:
			case typeAlert.MOVEMENT_ON_FRIDGE:
			case typeAlert.MOVEMENT_ON_AGITATOR:
			case typeAlert.CLEANING:
			case typeAlert.PROBLEM_AGITATOR1:
			case typeAlert.PROBLEM_AGITATOR2:
				return true
			default:
				return false
		}
	}

	public static checkTypeAlertConnected(status: any) {

		switch (status) {
			case typeAlert.DISCONNECTED_DEVICE:
			case typeAlert.DISCONNECTED_DEVICE_1H:
			case typeAlert.CONNECTED_DEVICE_30S:
			case typeAlert.DISCONNECTED_DEVICE_30S:
			case typeAlert.CONNECTED_DEVICE:
			case typeAlert.DISCONNECTED_GATEWAY_30S:
			case typeAlert.BATTERY_STATE:
			case typeAlert.BATTERY_LEVEL_DEVICE:
			case typeAlert.BAD_BATTERY_LEVEL_DEVICE:
			case typeAlert.DISCONNECTED_GATEWAY:
			case typeAlert.CONNECTED_GATEWAY:
				return true
			default:
				return false
		}
	}

	public static checkTypeAlertTHI(status: any) {
		switch (status) {
			case typeAlert.HIGH_THI:
				return true
			default:
				return false
		}
	}

	public static getTanksAlertType() {
		return [
			typeAlert.OVERCOOLING1,
			typeAlert.OVERCOOLING2,
			typeAlert.RISK_MILK,
			typeAlert.HIGH_TEMP,
			typeAlert.MOVEMENT_ON_FRIDGE,
			typeAlert.MOVEMENT_ON_AGITATOR,
			typeAlert.CLEANING,
			typeAlert.PROBLEM_AGITATOR1,
			typeAlert.PROBLEM_AGITATOR2,
			//typeAlert.DEVICE_TANK_STATUS,
		]
	}


	public static getSickAlertType() {
		return [
			typeAlert.DROP_REST,
			typeAlert.DROP_RUM,
			typeAlert.LOW_REST,
			typeAlert.LOW_RUM,
			typeAlert.HIGHT_REST
		]
	}
	public static getControleType() {
		return [
			typeAlert.C0,
			typeAlert.C1,
			typeAlert.C2
		]
	}
	public static getConectedType() {
		return [
			typeAlert.DISCONNECTED_DEVICE,
			typeAlert.DISCONNECTED_DEVICE_1H,
			typeAlert.CONNECTED_DEVICE_30S,
			typeAlert.DISCONNECTED_DEVICE_30S,
			typeAlert.CONNECTED_DEVICE,
			typeAlert.DISCONNECTED_GATEWAY_30S,
			typeAlert.BATTERY_STATE,
			typeAlert.BATTERY_LEVEL_DEVICE,
			typeAlert.BAD_BATTERY_LEVEL_DEVICE,
			typeAlert.DISCONNECTED_GATEWAY,
			typeAlert.CONNECTED_GATEWAY,
		]
	}

	public static checkAlertTypeGroupProjectionByCode(code: any) {
		if (code == '10')
			return "customer_alert_tank"
		else if (code == '08' || code == '09')
			return "customer_alert_connexion"
		else
			return "customer_alert_animal";
	}

	public static checkSizeByCode(code: any) {
		if (code == '08' || code == '09')
			return true
		else
			return false
	}

	public static checkIfTankAlertByCode(code: any) {
		if (code == '10')
			return true
		else
			return false
	}

	public static checkIfThiAlertByCode(code: any) {
		if (code == '09')
			return true
		else
			return false
	}
	public static checkIfConnectionAlertByCode(code: any) {
		if (code == '08')
			return true
		else
			return false
	}
}

export class typeSensor {
	public static type(status) {
		switch (status) {
			case "Movement sensor":
				return TypeSensor.MOVEMENT_SENSOR
			case "Double temperature sensor":
				return TypeSensor.DOUBLE_TEMP_SENSOR
			case "Acceleration Sensor":
				return TypeSensor.ACCELERATION_SENSOR
			case "Humidity sensor":
				return TypeSensor.TEMP_HUMIDITY_SENSOR


		}
	}
}


