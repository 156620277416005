import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MilkCollectionCenterComponent } from './milk-collection-center.component';
import { MilkCollectionCenterCardModule } from './milk-collection-center-card/milk-collection-center-card.module';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { TranslateModule } from '@ngx-translate/core';
import { NoAnimalsModule } from '../animals/no-animals/no-animals.module';


@NgModule({
  declarations: [MilkCollectionCenterComponent],
  imports: [
    CommonModule,
    MilkCollectionCenterCardModule,
    MoomeCommonModule,
    NgxHideOnScrollModule,
    TranslateModule,
    NoAnimalsModule,
  ],
  exports: [
    MilkCollectionCenterComponent
  ]
})
export class MilkCollectionCenterModule { }