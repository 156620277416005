import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';

@Component({
  selector: 'app-no-animals',
  templateUrl: './no-animals.component.html',
  styleUrls: ['./no-animals.component.scss']
})
export class NoAnimalsComponent implements OnInit {

  @Input() text: string;
  @Input() value: string;
  constructor(public appService: AppService) { }

  ngOnInit() {
  }

}
