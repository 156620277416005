import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Roles } from '../tools/constants';
import { AppService } from '../services/app.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthorityGuard implements CanActivate {

    constructor(private router: Router, private appService: AppService) {
    }


    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let role: Roles = next.data.role;


        return this.appService.getConnected().pipe(map(u => {
            let found: Boolean = false;
            let authority: Roles = next.data.auth;
            u['roles'].forEach(role => {
                role['authorityRoles'].forEach(element => {
                    if (authority.indexOf(element.authority.code) >= 0) {
                        if (element.get == true)
                            found = true;
                        return true;
                    }
                })
            })
            if (found)
                return true;
            this.router.navigate(['/'])
            return false
        }
        ))
    }

}
