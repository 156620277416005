import { Component, OnInit } from '@angular/core';
import { CustomRouter } from '../services/custom-router';
import { AppService } from 'projects/shared/services/app.service';
import { CustomerAnimalGroupsService } from 'projects/shared/services/customer-animal-groups.service';
import { GroupCode } from 'projects/shared/tools/constants';
import { GroupService } from 'projects/shared/services/group.service';
@Component({
  selector: 'app-production',
  templateUrl: './production.component.html',
  styleUrls: ['./production.component.scss']
})
export class ProductionComponent implements OnInit {
  list:any[]=[]
  bar:any;
  numberPregnant
  numberAdult
  numberOpen
  numberConfirmedPregnancy
  constructor(public groupService:GroupService,public customerAnimalGroupsService: CustomerAnimalGroupsService,public router:CustomRouter,public appService:AppService) {
    this.list=[
      {value1:"PREGNANT",value2:"--",value3:"",value4:"--",class:"green-border-box",value1Font:"repro-value1-font",value2Font:"repro-value2-font",value3Font:"repro-value3-font",value4Font:"repro-value4-font"},
      {value1:"OPEN",value2:"--",value3:"",value4:"--",class:"red-border-box",value1Font:"repro-value1-font",value2Font:"repro-value2-font",value3Font:"repro-value3-font",value4Font:"repro-value4-font"},
      {value1:"CONFIRMED_PREG",value2:"--",value3:"COWS",value4:"--",class:"green-box",value1Font:"repro-value1-font",value2Font:"repro-value2-font",value3Font:"repro-value3-font",value4Font:"repro-value4-font"} 
    ]
    this.bar={title:"REPRODUCTION",arrowIcon:"/assets/images/left-arrow.svg",color:"#f6f7fb",fontColor:"#80cc28",router:"/dashboard"}
   }

  ngOnInit() {
    this.loadGroups()
  }
  back(){
    this.router.navigateByUrl('/dashboard')
  }

  countByGroup() {
      this.customerAnimalGroupsService.countByGroup().subscribe(data => {
        this.numberConfirmedPregnancy= data["numberConfirmedPregnancy"]
      })

    }

    loadGroups(idFarmer?) {
      this.countByGroup()
      this.groupService.getGroupsOfCustomer(idFarmer).subscribe(data => {

        this.numberAdult = ((data['resources'].filter(d => d.code == GroupCode.PRODUCTION_CODE)[0]).groupAnimalCount)+((data['resources'].filter(d => d.code == GroupCode.DRY_CODE)[0]).groupAnimalCount)
        this.numberPregnant =((data['resources'].filter(d => d.code == GroupCode.P_Pregnant_CODE)[0]).groupAnimalCount)+((data['resources'].filter(d => d.code == GroupCode.DRY_Pregnant_CODE)[0]).groupAnimalCount)
        this.numberOpen=this.numberAdult-this.numberPregnant;
        if (this.numberPregnant || this.numberAdult||this.numberOpen) {
          this.list=[
            {value1:"PREGNANT",value2:this.numberPregnant+"/"+this.numberAdult,value4:(this.numberPregnant*100/this.numberAdult).toFixed(0)+"%",class:"green-border-box",value1Font:"repro-value1-font",value2Font:"repro-value2-font",value3Font:"repro-value3-font",value4Font:"repro-value4-font"},
            {value1:"OPEN",value2:this.numberOpen+"/"+this.numberAdult,value4:(this.numberOpen*100/this.numberAdult).toFixed(0)+"%",class:"red-border-box",value1Font:"repro-value1-font",value2Font:"repro-value2-font",value3Font:"repro-value3-font",value4Font:"repro-value4-font"},
            {value1:"CONFIRMED_PREG",value2: this.numberConfirmedPregnancy,value3:"COWS",value4:"",class:"green-box",value1Font:"repro-value1-font",value2Font:"repro-value2-font",value3Font:"repro-value3-font",value4Font:"repro-value4-font"} 
          ]
          this.bar={title:"REPRODUCTION",arrowIcon:"/assets/images/left-arrow.svg",color:"#f6f7fb",fontColor:"#80cc28",router:"/dashboard"}
         }
         else
          this.appService.error('HEALTH_DASH_REQUIRED');
      })
    }
  
}
