<div class="dialog-size-mob">
    <div class="row">
        <div class="col mb-4">
            <div class="text-center title-style-dialog"><span [ngClass]="{rtl:appService.rtl()}">{{data.title}}</span>
            </div>
        </div>
    </div>
    <form [formGroup]="formGroup">
        <div *ngIf="data.editType == 'name' " class="row">
            <div class="col mb-4">
                <input matInput class="input-mob input-width" type="text" formControlName="name"  
                    [ngClass]="{rtl:appService.rtl()}" placeholder="{{'NAME' | translate}}">
            </div>
        </div>
        <div class="row" *ngIf="data.editType == 'livestockIdentification' "  >
            <div class="col mb-4">
                <div class="row" >
                    <mat-radio-group formControlName="value" >
                        <div class="col-10" [ngClass]="{rtl:appService.rtl()}"  >
                            <mat-radio-button value="NATIONAL_IDENTIFICATION" class="mb-2"><span class="text-age ms-1 me-1"
                                    [ngClass]="{rtl:appService.rtl()}">{{ 'NATIONAL_IDENTIFICATION' | translate}}</span>
                            </mat-radio-button>
                        </div>
                        <div class="col-10" [ngClass]="{rtl:appService.rtl()}" >
                            <mat-radio-button value="NAME_LOCALCODE" class="mb-2"><span class=" text-age ms-1 me-1"
                                    [ngClass]="{rtl:appService.rtl()}">{{ 'NAME_LOCALCODE' | translate}}</span>
                            </mat-radio-button>
    
                        </div>
                    </mat-radio-group>
                </div>
            </div>
        </div>

        <div *ngIf="data.editType == 'country' " class="row">
            <div class="col mb-4">
                <mat-select placeholder="{{ 'ADDRESS_ORDER'| translate }}"  [ngClass]="{rtl:appService.rtl()}" class="input-mob input-width-add" formControlName="country" >
                    <input (keydown)="$event.stopPropagation()" (keyup)=" appService.onKeyCountry($event.target.value)" class="input-search-mob " >
                    <mat-option *ngFor="let country of appService.selectedCountries" [value]="country.id"
                        [ngStyle]="{'text-align':(appService.rtl()? 'right' : 'left')}"><span
                            [ngClass]="{rtl:appService.rtl()}">{{country| codeTranslate}}</span>
                    </mat-option>
                </mat-select>
            </div>
        </div>
        

        <div *ngIf="data.editType == 'phonePass' " class="row">
            <div class="col mb-4">
                <input matInput class="input-mob input-width" [type]="hide ? 'text' : 'password'"  [ngClass]="{rtl:appService.rtl()}"
                    placeholder="{{'PASSWORD' | translate}}" formControlName="password">
                <mat-icon *ngIf="hide" class="position-relative" (click)="!hide ? hide= true : hide=false"
                    [ngClass]="appService.rtl()? 'icon-ar-pos1' :  'icon-fr-en-pos'" color="primary"> visibility
                </mat-icon>
                <mat-icon *ngIf="!hide" class="position-relative" (click)="!hide ? hide= true : hide=false"
                    [ngClass]="appService.rtl()? 'icon-ar-pos1' :  'icon-fr-en-pos'" color="primary"> visibility_off
                </mat-icon>
            </div>
        </div>
        
        <div *ngIf="data.editType == 'phone' " class="row">
            <div class="col mb-2">
                <input matInput class="input-mob input-width" type="tel" formControlName="phone" inputmode="text" 
                    [ngClass]="{rtl:appService.rtl()}" placeholder="{{'NEW_PHONE_Number' | translate}}">
            </div>
        </div>

     
        <div *ngIf="data.editType == 'code' " class="row">
            <div class="col mb-2">
                <input matInput class="input-mob input-width" type="number" formControlName="code" inputmode="text" 
                    [ngClass]="{rtl:appService.rtl()}" placeholder="{{'PASSWORD' | translate}}">
            </div>
        </div>

        <div *ngIf="data.editType == 'code' " class="row">
            <div class="text-center col mt-2">
                <div [ngClass]="!appService.showResend? 'd-block' : 'd-none'"
                    class="text-style2color -dark-blue" [ngClass]="{rtl:appService.rtl()}">
                    <span [ngClass]="{rtl:appService.rtl()}">{{ 'EXPIRED_TIME' | translate }}</span>&nbsp;<span>00:{{appService.timer}}</span></div>
                <div [ngClass]="appService.showResend? 'd-block' : 'd-none'">
                    <span class="text-style2 color-dark-blue" [ngClass]="{rtl:appService.rtl()}">{{ notRec |
                    translate
                    }}</span>&nbsp;<a class="text-style2 moome-color" (click)="resendCode()"
                        [ngClass]="{rtl:appService.rtl()}" color="primary">{{ resend |
                    translate }}</a></div>
            </div>
        </div>

       <!--<div *ngIf="data.editType == 'phone' " class="row">
             <div class="col mt-1 mb-3" [ngClass]="{rtl:appService.rtl()}">
                <span class="text-connect-style text-center">{{ 'CONNECT' | translate }}</span>
            </div>
        </div>-->
        
        <div *ngIf="data.editType == 'username' " class="row">
            <div class="row-6">
                <div class="col">
                    <input matInput class="input-mob input-width font-size-u"
                        [ngClass]="{rtl:appService.rtl()}" value="{{username}}" disabled>
                </div>
            </div>

            <div class="row-6">
                <div class="col mb-4 mt-4">
                    <input matInput class="input-mob input-width font-size-u" type="text" formControlName="username"
                    [ngClass]="{rtl:appService.rtl()}" placeholder="{{'NEW_USERNAME' | translate}}">
                </div>
            </div>
            
        </div>
      
        <div *ngIf="data.editType == 'password' ">
            <div class="row">
                <div class="col">
                    <input matInput class="input-mob input-width font-size" [type]="hide ? 'text' : 'password'"  formControlName="password"
                        [ngClass]="{rtl:appService.rtl()}" placeholder="{{'PASSWORD' | translate}}">
                    <mat-icon *ngIf="hide" class="position-relative" (click)="!hide ? hide= true : hide=false"
                        [ngClass]="appService.rtl()? 'icon-ar-pos1' :  'icon-fr-en-pos1'" color="primary"> visibility
                    </mat-icon>
                    <mat-icon *ngIf="!hide" class="position-relative" (click)="!hide ? hide= true : hide=false"
                        [ngClass]="appService.rtl()? 'icon-ar-pos1' :  'icon-fr-en-pos1'" color="primary"> visibility_off
                    </mat-icon>
                </div>
                
            </div>
            <div class="row">
                <div class="col mb-4 mt-4">
                    <input matInput class="input-mob input-width font-size" [type]="hide1 ? 'text' : 'password'" formControlName="newPassword"
                        [ngClass]="{rtl:appService.rtl()}" placeholder="{{'NEW_PASS' | translate}}">
                    <mat-icon *ngIf="hide1"  class="position-relative" (click)="!hide1 ? hide1= true : hide1=false"
                        [ngClass]="appService.rtl()? 'icon-ar-pos1' :  'icon-fr-en-pos1'" color="primary"> visibility
                    </mat-icon>
                    <mat-icon *ngIf="!hide1"  class="position-relative" (click)="!hide1 ? hide1= true : hide1=false"
                        [ngClass]="appService.rtl()? 'icon-ar-pos1' :  'icon-fr-en-pos1'" color="primary"> visibility_off
                    </mat-icon>
                </div>
            </div>
            <div class="row">
                <div class="col mb-4">
                    <input matInput class="input-mob input-width ellipsis-text font-size" [type]="hide2 ? 'text' : 'password'" formControlName="confNewPassword"
                        [ngClass]="{rtl:appService.rtl()}" placeholder="{{'CONF_NEW_PASSWORD' | translate}}">
                
                        <mat-icon *ngIf="hide2" class="position-relative" (click)="!hide2 ? hide2= true : hide2=false"
                        [ngClass]="appService.rtl()? 'icon-ar-pos1' :  'icon-fr-en-pos1'" color="primary"> visibility
                    </mat-icon>
                    <mat-icon *ngIf="!hide2" class="position-relative" (click)="!hide2 ? hide2= true : hide2=false"
                        [ngClass]="appService.rtl()? 'icon-ar-pos1' :  'icon-fr-en-pos1'" color="primary"> visibility_off
                    </mat-icon>
                
                    </div>
            </div>
        </div>
        <div *ngIf="data.editType == 'username' || data.editType == 'password' " class="row">
            <div class="col mt-1 mb-3" [ngClass]="{rtl:appService.rtl()}">
                <span class="text-connect-style text-center">{{ 'CONNECT' | translate }}</span>
            </div>
        </div>

        <div class="row" *ngIf="data.editType == 'editMilkPrice' " [ngClass]="{rtl: appService.rtl()}">
            <div class="col center-element mt-1 mb-3" [ngClass]="{rtl: appService.rtl()}" >
                
                <div>
                    <div class="title-style " [ngClass]="{rtl:appService.rtl()}">{{ field | codeTranslate }}</div> 
                    <input matInput class="input-mob input-width-price" type="number" formControlName="price"
                    [ngClass]="{rtl:appService.rtl()}" placeholder="{{'PRICE' | translate}}">
                    <span class="text-kg ms-1" [ngClass]="{rtl: appService.rtl()}" >{{ unit | translate }}</span>
                </div>
            </div>
        </div>
    </form>

   
    <div class="row">
        <div class="col">
            <div class="center-element" >
                 <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="warn"
                    style="margin-right: 5px" class="button-dialog   text-center" mat-dialog-close> <span [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'" class="center-element">{{ 'CANCEL' | translate }} </span></button>
                    <button [ngClass]="{rtl:appService.rtl()}" class="button-dialog button-text-size text-center" mat-raised-button color="primary"
                    (click)="confirm()" >
                    <span [ngClass]="appService.rtl()? 'button-mob-text-ar rtl' : 'button-mob-text'" class="center-element">{{ 'CONFIRM' | translate }} </span></button>
                </div>
        </div>
    </div>
</div>
