import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { FarmerService } from 'projects/shared/services/farmer.service';
import { TranslateService } from '@ngx-translate/core';
import { MIN_LENGTH_PASSWORD, MAX_LENGTH_PASSWORD, PATERN_PASSWORD } from 'projects/shared/tools/constants';
//import { RecaptchaComponent } from '../../../recaptcha/recaptcha.component';
import { Customer } from 'projects/shared/resources/Customer';
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { Subscription } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';


@Component({
  selector: 'app-setting-customer-dialog',
  templateUrl: './setting-customer-dialog.component.html',
  styleUrls: ['./setting-customer-dialog.component.scss']
})
export class SettingCustomerDialogComponent implements OnInit {

  formGroup: UntypedFormGroup;
  countries: any[];
  selectedCountries: any[] = [];
  hide: Boolean
  hide1: Boolean
  hide2: Boolean
  username: any
  minLengthPassword = MIN_LENGTH_PASSWORD
  maxLengthPassword = MAX_LENGTH_PASSWORD
  patternPassword = PATERN_PASSWORD
  nameFarmer
  customer = new Customer()
  notRec = 'NOT_RECI?'
  resend = 'RESEND'
  unit
  private executionsSubscription: Subscription;

  constructor(public dialog: MatDialog, private recaptchaV3Service: ReCaptchaV3Service, public dialogRef: MatDialogRef<SettingCustomerDialogComponent>, public appService: AppService, private codeTranslate: CodeTranslatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: UntypedFormBuilder, private supervisorService: SupervisorService,
    private translate: TranslateService, private farmerService: FarmerService, public alertsService: AlertsService) {

    this.createForm();

    this.setValidators(this.data.value, this.data.validators)
  }

  ngOnDestroy() {
    this.appService.hideRecaptcha()
    if (this.executionsSubscription) {
      this.executionsSubscription.unsubscribe();

    }
  }

  ngOnInit() {
    this.unit = this.appService.connectedCustomer.addresses[0].country.currency
    this.appService.loadCountries()
    this.username = this.appService.connectedCustomer.username
    if (this.data.editType == 'code')
      this.appService.observableTimer()
  }

  createForm() {
    // this.appService.verifValueUid()
    this.formGroup = this.formBuilder.group({
      'name': [null,],
      'username': [null,],
      'country': [null,],
      'phone': [null,],
      'password': [null, Validators.required],
      'newPassword': [null, [Validators.required, Validators.minLength(MIN_LENGTH_PASSWORD),
      Validators.maxLength(MAX_LENGTH_PASSWORD),
      Validators.pattern(PATERN_PASSWORD)]],
      'confNewPassword': [null, [Validators.required, Validators.minLength(MIN_LENGTH_PASSWORD),
      Validators.maxLength(MAX_LENGTH_PASSWORD),
      Validators.pattern(PATERN_PASSWORD)]],
      'setting': [null],
      'value': [this.appService.valueUid],
      'code': [null],
      'price':[null, Validators.required]

    });
  }

  resendCode() {
    /*const dialogRef = this.dialog.open(RecaptchaComponent, {
      data: {
        onConfirm: (token) => {*/
        this.appService.showRecaptcha();
        this.executionsSubscription = this.recaptchaV3Service.execute('importantAction')
        .subscribe((token: string) => {
          this.appService.showLoading()
          this.customer.phone = (this.data.otherData)
          this.customer['recaptchaToken'] = token
          this.supervisorService.sendSms(this.customer).subscribe((res: Customer) => {
            this.customer = res;
            this.nameFarmer = this.customer.fullname;
            this.appService.hideLoading()
            this.message(this.translate.instant('SMS_SENT') + " " + this.translate.instant('FARMER') + " " + this.nameFarmer)
            this.appService.observableTimer()
          }, error => {
            this.message(this.translate.instant(error.error.message));
          });
        })
      //}
   // });
  }

  message(msg) {
    this.alertsService.open(msg);
  }

  setValidators(value, validators) {
    this.formGroup.clearValidators();
    this.formGroup.setErrors({ 'invalid': true });
    this.formGroup.controls[value].setValidators(validators);
    this.formGroup.controls[value].updateValueAndValidity();
  }

  confirm() {
    this.data.onConfirm(this.formGroup)
  }

}
