import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Type } from 'projects/shared/tools/constants';
import { ActivatedRoute } from '@angular/router';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { FORM_DATA } from '../edit-setting-form/edit-setting-form.component';

@Component({
  selector: 'app-default-edit-setting-form',
  templateUrl: './default-edit-setting-form.component.html',
  styleUrls: ['./default-edit-setting-form.component.scss']
})

export class DefaultEditSettingFormComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  type = Type
  name
  farmer
  data
  idFarmer
  idFormTemplate
  idForm
  list: any[] = []
  lastValue = null
  @Input() formDefinition: any;
  @Input() formData;
  args = {}

  constructor(@Inject(FORM_DATA) @Optional() data, public translate: TranslateService, public appService: AppService, public codeTranslate: CodeTranslatePipe, public route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder
  ) {

    this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');
    this.idFormTemplate = this.route.snapshot.paramMap.get('idFormTemplate');
    this.idForm = this.route.snapshot.paramMap.get('idForm');
    if (data && data.formDefinition)
      this.formDefinition = data.formDefinition;
    if (data && data.formGroup)
      this.formGroup = data.formGroup
    if (data && data.formData)
      this.formData = data.formData
  }

  getControls(code) {
    return (this.formGroup.controls[code] as UntypedFormArray).controls
  }

  addfields(field) {
    (this.formGroup.get(field.code) as UntypedFormArray).push(this.newField(field));
  }

  removeFields(i: number, field) {
    (this.formGroup.controls[field.code] as UntypedFormArray).removeAt(i);
  }

  newField(field) {
    if (field.type == this.type.COMPLEX) {
      let group = field.formFields
        .map(element => ({ control: new UntypedFormControl(element.value), code: element.code }))
      //.reduce((a, b) => a[b.code] = b.control, {})
      var res = {}
      group.forEach(e => {
        res[e.code] = e.control
      })
      return this.formBuilder.group(res)
    }
  }
  newSubField(field) {
    let group = this.getKeys(field)
      .map(key => ({ control: new UntypedFormControl(field[key]), code: key }))
    var res = {}
    group.forEach(e => {
      res[e.code] = e.control
    })
    return this.formBuilder.group(res)
  }


  ngOnInit(): void {

  }

  getKeys(o) {
    return Object.keys(o)
  }

}
