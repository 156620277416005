import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as L from 'leaflet';
import { AddressService } from './address.service';
import { AlertsService } from './alerts.service';
import { AppService } from './app.service';
import { MarkerPopUpService } from './marker-popup.service';
import { Geolocation } from '@capacitor/geolocation';
import { Platform } from '@ionic/angular';
import { Helper } from '../tools/helper';

@Injectable({
  providedIn: 'root'
})
export class MarkerService {
  constructor(private markerPopupService: MarkerPopUpService,
    private addressService: AddressService,
    private appService: AppService, private alertsService: AlertsService, private translate: TranslateService, private platform: Platform

  ) {
  }

  findLocationById(map: L.map, id) {
    this.addressService.getAddressesByCustId(id).subscribe(res => {
      this.makeCapitalMarkers(map, res['resources']);
    });
  }

  findLocationOfFarmers(map: L.map) {
    this.addressService.getAddressesOfFarmersBySupervisor().subscribe(res => {
      this.makeCapitalMarkers(map, res);
    });
  }

  makeCapitalMarkers(map: L.map, res): void {

    for (const c of res) {
      const lon = c.longitude;
      const lat = c.latitude;
      
      if (lon && lat) {
        const marker = L.marker([lat, lon]);
        marker.bindPopup(this.markerPopupService.makeCapitalPopup(c));
        marker.addTo(map);
      }
    }
  }




  async getCurrentPosition(address): Promise<void>{
    try{
        //vérifier l'autorisation
        await Geolocation.checkPermissions();
        //demander l'autorisation
        await Geolocation.requestPermissions();
        const coordinates = await Geolocation.getCurrentPosition();
        address.longitude = coordinates.coords.longitude;
        address.latitude = coordinates.coords.latitude;
        this.appService.showLoading()
        this.addressService.save(address).subscribe(res => {
          this.appService.hideLoading();
          let message = this.translate.instant("SAVE_SUCESS");
          this.alertsService.open(message);
        });    
      }catch(error){
    }
      }
  

  getUserLocation(address) {
    
    if (this.platform.is("cordova")){
      this.getCurrentPosition(address);
       }
   else {
      navigator.geolocation.getCurrentPosition(position => {
        address.longitude = position.coords.longitude;
        address.latitude = position.coords.latitude;
        this.appService.showLoading()
        this.addressService.save(address).subscribe(res => {
          this.appService.hideLoading();
          let message = this.translate.instant("SAVE_SUCESS");
          this.alertsService.open(message);
        })
      });
    } 
  }
}


