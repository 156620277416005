import { Entreprise } from "../resources/Entreprise";
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MoomeRestService, Options } from './moome-rest.service';
import { ConfigService } from "./config.service";
import { map } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class EntrepriseService extends MoomeRestService<Entreprise> {

	constructor(private configService: ConfigService, public http: HttpClient, injector: Injector) {
		super(Entreprise);
	}

	getEntreprisesOfCustomerWithFilter(page, size, customerId,  key, types) {

		let param: any =
		{
			projection: 'entreprises',
			types: types,
			
		}
		if (customerId != null)
			param.id = customerId;
		if (key != null)
			param.key = key.toLowerCase();


		let options: Options = {
			params:
				param
			,
			pageParams: {
				page:page,
				size:size
			}
		}


		return this.searchPages(`getEntrepriseOfCustomerByKey`, options)
	}
	

	findEntreprises(projection) {
	
		let options: Options = {
			params: { 
				projection: projection,
				}
					
		}

		return this.search(`findEntreprises`, options)
	}

	findEntreprisesTypeEntreprise() {
		let options: Options = {
		params: { 
		projection: 'entreprises',
		}
		}

		return this.search(`findEntreprisesTypeEntreprise`, options)
	}

	

	getEntreprisesOfConnected(supervisorId?) {
		
		let param: any =
		{
		}
		if (supervisorId != null)
			param.supervisorId = supervisorId;
			param.projection='entreprise-card'


		let options: Options = {
			params:
				param
			
		}
		return this.customRequestGet(`/getEntreprises`,null,options).pipe(
			map(res =>{ return res['_embedded']?.entreprises}
				
			)
		)
	}

	getEmployees(id) {
		return this.http.get(this.configService.getApiPath() + "/entreprises/getEmployees/" + id)
	}

	getById(entrepriseId){
		let options: Options = {
			
			params: { 
				id: entrepriseId,
			}
		   }

		return this.searchSingle(`findMoomeEntreprise`, options)
	}

	/*getEntrepriseIds() {
		return this.http.get(this.configService.getApiPath() + "/entreprises/getIds")
	}*/
}