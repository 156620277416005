import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { CustomRouter } from '../../services/custom-router';

@Component({
  selector: 'app-section-bar',
  templateUrl: './section-bar.component.html',
  styleUrls: ['./section-bar.component.scss']
})
export class SectionBarComponent implements OnInit {

  @Input() title: String
  @Output() goBack = new EventEmitter()
  @Input() color: String

  constructor(private router: CustomRouter,public appService:AppService) { }

  ngOnInit() {
  }

  back() {
   /* if(this.router.url.indexOf('search') !=-1){
      this.router.navigateByUrl(this.router.history[this.router.history.length - 2])
    }
    else */
    this.goBack.emit();
  }

}
