<div class="row">
    <div class="col text-center mt-4">
        <span class="text-style" [ngClass]="{rtl:appService.rtl()}">{{ 'WELCOME_TO_MOOME' | translate }}</span>
    </div>
</div>
<div class="row">
    <div class="col text-center mb-3" [ngClass]="{rtl:appService.rtl()}">
        <span class="text-style">{{ 'FIRST_TIME_HERE' | translate }}</span>
    </div>
</div>
<div class="row">
    <div class="col text-center mb-4">
        <div class=""><img src="/assets/images/get-started.svg"></div>
    </div>
</div>
<div class="row">
    <div class="col text-center mt-3" [ngClass]="{rtl:appService.rtl()}">
        <button mat-raised-button color="primary" (click)="router.navigateByUrl('sign-up')"
            class="button-mob button-signin-style">
            <!--button mat-raised-button color="primary" routerLink="/sign-up"
                    class="button-mob" disabled--><span [ngClass]="{rtl:appService.rtl()}">{{ 'CREATE_ACCOUNT' |
                translate }}</span></button>
    </div>

</div>
<div class="row">
    <div class="col mt-3">
        <hr class="hr-style">
    </div>
    <div class="col-auto mt-3 text-or-style"><span [ngClass]="{rtl:appService.rtl()}">{{ 'OR' | translate }}</span>
    </div>
    <div class="col mt-3">
        <hr class="hr-style">
    </div>
</div>
<div class="row">
    <div class=" col text-style text-center mt-3" [ngClass]="{rtl:appService.rtl()}">
        <span class="text-style">{{ 'ALREADY_A_MEMBER' | translate }}</span>
    </div>
</div>
<div class="row">
    <div class="text-style text-center mt-3 mb-4">
        <button mat-raised-button color="primary" (click)="router.navigateByUrl('/login')" class="button-mob"><span
                [ngClass]="{rtl:appService.rtl()}">{{ 'LOGIN' | translate }}</span></button>
    </div>
</div>
<div class="row">
    <div class="col text-center mt-2 mb-4 lang-pos">
            <app-language [value]="selectedLanguage" [color]="color"></app-language>
    </div>
</div>