<div class="style-bar-troups w-100" ngxHideOnScroll [scrollingElementSelector]="'body'" [propertyUsedToHide]="'top'"
    [valueWhenHidden]="'-50px'" [valueWhenShown]="'90px'">
    <div class="row  h-100">
        <div class="col-12">
            <div class="row background-troups">
                <div class="col center-element" (click)="back()">
                    <img src="/assets/images/left-arrow-1.svg" />
                </div>
                <div class="col center-element">
                    <div class="row d-block">
                        <div class="col text-center"> <img src="/assets/images/farmer_icon_neutral_2.svg" />
                        </div>
                        <div class="col text-center ps-0 pe-0 pt-1"> <span class="text-style"
                                [ngClass]="{rtl:appService.rtl()}">{{ 'SUPERVISORSS' | translate }}</span></div>
                    </div>
                </div>
                <div class="col center-element">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row px-2 pb-5 position">
    <app-supervisor-card *ngFor="let supervisor of supervisors" [supervisor]="supervisor"
        class="col-12 col-sm-6 col-md-6 col-lg-4 mt-2 mb-2" [getAuthorized]="authority"></app-supervisor-card>
</div>