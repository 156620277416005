import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as L from 'leaflet';
import { MarkerService } from 'projects/shared/services/marker.service';
import { CustomRouter } from '../services/custom-router';


const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {
  private map;

  private startLat = 34.7992447;
  private startLon = 10.756735;

  private config = {
    center: [this.startLat, this.startLon],
    zoom: 6
  }

  private initMap(): void {
    this.map = L.map('map', this.config);

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);
  }

  idFarmer

  constructor(private markerService: MarkerService, private router: CustomRouter, private route: ActivatedRoute) {
    this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');
  }

  ngAfterViewInit(): void {
    this.initMap();
    if (this.router.url.indexOf("farmers") == -1)
      this.markerService.findLocationOfFarmers(this.map);
    else
      this.markerService.findLocationById(this.map, this.idFarmer);
  }
}
