import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Chart, ArcElement, LineElement, BarElement, PointElement, BarController, BubbleController, DoughnutController, LineController, PieController, PolarAreaController, RadarController, ScatterController, CategoryScale, LinearScale, LogarithmicScale, RadialLinearScale, TimeScale, TimeSeriesScale, Decimation, Filler, Legend, Title, Tooltip, SubTitle } from 'chart.js';
import * as moment from 'moment';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AnimalService } from 'projects/shared/services/animal.service';
import { AppService } from 'projects/shared/services/app.service';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { ReportService } from 'projects/shared/services/report.service';
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { CapacitorUtils } from 'projects/shared/tools/CapacitorUtils';
import { FORMAT_MAX_MIN, Alert } from 'projects/shared/tools/constants';
import { CustomRouter } from '../services/custom-router';

@Component({
  selector: 'app-calving-graph',
  templateUrl: './calving-graph.component.html',
  styleUrls: ['./calving-graph.component.scss']
})
export class CalvingGraphComponent implements OnInit {

  formGroup: UntypedFormGroup;
  formatArabe: any
  periods = ["1_MONTH"]
  selectedPeriods = ["1_MONTH"]
  maxDate: any
  displaygraph = false;
  barChart: Chart;
  dayTime: String[];
  startDate;
  endDate;
  periodValue
  title: any;
  datasets: any[] = [];
  listStatusV2Accessible: Number[];
  listStatusGiveBirthEndCOnfirmed: Number[];
  percentages: any[] = []
  supervisors: any
  formatString: string
  idSupervisor
  adminAccess: Boolean = false
  constructor(private supervisorService: SupervisorService, public translateService: TranslateService, public formTemplateService: FormTemplateService, public tokenManagementService: TokenManagementService,
    public router: CustomRouter, public appService: AppService, private formBuilder: UntypedFormBuilder,
    public alertsService: AlertsService, public translate: TranslateService, public codeTranslate: CodeTranslatePipe,
    public reportService: ReportService, public animalService: AnimalService) {
    Chart.register(
      ArcElement,
      LineElement,
      BarElement,
      PointElement,
      BarController,
      BubbleController,
      DoughnutController,
      LineController,
      PieController,
      PolarAreaController,
      RadarController,
      ScatterController,
      CategoryScale,
      LinearScale,
      LogarithmicScale,
      RadialLinearScale,
      TimeScale,
      TimeSeriesScale,
      Decimation,
      Filler,
      Legend,
      Title,
      Tooltip,
      SubTitle
    );
    this.appService.connectedCustomer['roles'].forEach(role => {
      if (role.adminAccess)
        this.adminAccess = true

    })
  }

  ngOnInit(): void {
    this.formatString = this.translate.instant('DATEPICKER_FORMAT_MONTHLY');
    this.createForm()
    this.maxDate = moment(new Date()).add(2, 'year').format("YYYY-MM-DD");
    this.loadSupervisors()
  }
  back() {
    this.router.back('')
  }
  fixArabeFormat() {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD/MMM/YYYY"
  }
  apply() {
    this.applyGraph();

  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      'period': [],
      'startDate': [],
      'endDate': [],
      'supervisor': [null, (this.adminAccess) ? [Validators.required] : []]

    });
    this.formGroup.controls['startDate'].disable();
    this.formGroup.controls['endDate'].disable();
  }
  searchPeriod(value, list) {
    let filter = value.toLowerCase();
    return list.filter(option => this.translate.instant(option).toLowerCase().includes(filter));
  }
  onKeySupervisors(value) {
    // this.supervisors = this.search(value, this.entreprises, "code");
  }
  onKeyPeriod(value) {
    this.selectedPeriods = this.searchPeriod(value, this.periods)
  }
  exportCsv() {
    this.idSupervisor = this.formGroup.controls['supervisor'].value?.id;
    this.periodValue = this.formGroup.controls['period'].value
    this.startDate = moment(this.formGroup.controls['startDate'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format('yyyy/MM/DD').toString()
    this.endDate = moment(this.formGroup.controls['endDate'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format('yyyy/MM/DD').toString()
    let type = ".csv";
    this.appService.showLoading();
    this.setPeriod();

    this.reportService.exportCalvingGraphToCsv(this.startDate, this.endDate, this.idSupervisor).subscribe(data => {
      let blob = new Blob([data], { type: 'text/csv; charset=utf-8' }); // Change type to text/csv
      let filename = this.translate.instant('CALVING_GRAPH')  + moment(new Date()).format('yyyy-MM-DD HH:mm') 
      if (CapacitorUtils.isApp){
        CapacitorUtils.writeAndOpenFile(blob, filename+type); 
        this.appService.hideLoading();
        let message = this.translate.instant('SAVE_FILE_SUCESS');
        this.alertsService.open(message);}// Append type to filename
      else{
      
        this.appService.saveAs(blob, filename, type);
      this.appService.hideLoading();}
    }, error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.code);
      let alert = this.translate.instant(Alert.OK)
      this.alertsService.open(message, alert);
    })
}
  showOptions(event) {
    this.formGroup.controls['period'].setValue(null);
    this.formGroup.controls['startDate'].setValue(null);
    this.formGroup.controls['endDate'].setValue(null);

    if (event.checked) {
      this.formGroup.controls['period'].disable();

      this.formGroup.controls['startDate'].enable();
      this.formGroup.controls['endDate'].enable();
    } else {
      this.formGroup.controls['startDate'].disable();

      this.formGroup.controls['endDate'].disable();
      this.formGroup.controls['period'].enable();
    }
  }
  setPeriod() {

    if (this.periodValue != null) {
      let start
      if (this.periodValue == "1_MONTH")
        start = moment().subtract(1, 'months');
      this.title = start.format(this.formatString) + " -- "
        + moment().format(this.formatString);

      this.startDate = start.toISOString();
      this.endDate = moment().toISOString();
    } else {

      this.title = moment(this.startDate).format(this.formatString) + " -- "
        + moment(this.endDate).format(this.formatString);
      this.startDate = moment(new Date(this.formGroup.controls['startDate'].value)).set({ minute: 0, second: 0, millisecond: 0 }).toISOString();
      this.endDate = moment(this.formGroup.controls['endDate'].value).toISOString();
    }
  }
  applyGraph() {
    this.idSupervisor = this.formGroup.controls['supervisor'].value?.id;
    if (this.barChart) {
      this.barChart.destroy();
      this.displaygraph = false;
    }
    this.periodValue = this.formGroup.controls['period'].value
    this.startDate = moment(this.formGroup.controls['startDate'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format('yyyy/MM/DD').toString()
    this.endDate = moment(this.formGroup.controls['endDate'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format('yyyy/MM/DD').toString()
    this.appService.showLoading();
    this.setPeriod();
    this.reportService.getCalvingGraph(this.startDate, this.endDate, this.idSupervisor).subscribe((res: any) => {
      this.appService.hideLoading()
      this.dayTime = [];
      this.percentages = [];
      this.listStatusV2Accessible = [];
      this.listStatusGiveBirthEndCOnfirmed = [];
      this.datasets = [];
      let keys = (Object.keys(res)).sort();
      keys.forEach(element => {
        this.dayTime.push(moment(element).format("YYYY/MM"));
        this.listStatusV2Accessible.push(res[element]['StatusV2Accessible'] ? res[element]['StatusV2Accessible'] : null);
        this.listStatusGiveBirthEndCOnfirmed.push(res[element]['StatusGiveBirthEndCOnfirmed'] ? res[element]['StatusGiveBirthEndCOnfirmed'] : null)
      });
      this.displaygraph = true;
      let datasets: any[] = [
        {
          label: this.translateService.instant('COMING_CALVING') + " ",
          backgroundColor: '#316a90',
          data: this.listStatusV2Accessible,
        },
        {
          label: this.translateService.instant('CALVING_COMPLETED') + " ",
          backgroundColor: '#80cc28',
          data: this.listStatusGiveBirthEndCOnfirmed,
        },

      ]
      this.barChart = new Chart('barChart', {
        type: 'bar',
        data: {
          labels: this.dayTime,
          datasets: datasets

        },

        options: {

          scales: {
            y: {
              beginAtZero: true,
            },

          },
          responsive: false,
          plugins: {
            legend: {
              display: true
            },
            title: {
              display: true,
              text: this.title

            },
          },
        }
      })

    }, error => {

      this.appService.hideLoading();
      let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.code);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
      this.alertsService.open(message, alert);
    })
  }
  loadSupervisors() {
    this.supervisorService.getForAdminEntrepriseSupervisors().subscribe(res => {
      this.supervisors = res

    },
      error => {
        this.appService.hideLoading();
        let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.error_description);
        let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
        this.alertsService.open(message, alert);
      })
  }


}
