import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CustomRouter } from 'projects/publicV2/src/app/services/custom-router';
import { AppService } from 'projects/shared/services/app.service';
import { Alert } from 'projects/shared/tools/constants';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  alert = Alert
  constructor(private dialogRef: MatDialogRef<AlertComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public appService: AppService, public router: CustomRouter, public translate: TranslateService) {
    dialogRef.disableClose = true;
  }
  ngOnInit() {
  }

  closePopup() {
    this.dialogRef.close()
  }

  contactUs() {
    this.router.navigateByUrl("/contact")
  }

  close() {
    if (this.translate.instant(this.alert.CONTACT_US) == this.data.action)
      this.contactUs()
      this.closePopup()
  }
}
