import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { Customer } from "./Customer";
import { Alert } from "./alert";
@HateoasResource('customerAlerts')
export class CustomerAlert extends Resource {
  
    alert: Alert | any;
	customer: Customer | any;
	read: Boolean | any;

  }