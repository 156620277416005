<div class="fixed-header w-100" ngxHideOnScroll [scrollingElementSelector]="'body'" [propertyUsedToHide]="'top'"
  [valueWhenHidden]="'-45px'" [valueWhenShown]="'90px'">
  <div class="row center-element ms-2 me-2 height-bar">
    <div class="col-9 text-style-setting">
      <div class="row gx-4 center-element">
        <div class="color-group-cercle col-3 p-0" [ngStyle]="{'background-color':colorGroup}">
        </div>
        <div class="col text-style-setting pe-0"><span [getAuthorized]="authorityAnimals"> {{appService.valueUid
            ==nationalIdentification? (uid? uid : name) : (appService.valueUid == nameLocalCode? (name? name : uid) :
            (uid? uid : name))}}</span>
        </div>
      </div>
    </div>
    <div class="col-3 ps-0 pe-0">
      <div class="float-end"><img src="/assets/images/Groupe-367.svg" class="me-1" (click)="help()">
        <img src="/assets/images/Union-5.svg" (click)="backToAnimalList()" />
      </div>
    </div>
  </div>
  <div class="white-bar">
  </div>
  <div class="row center-element height-bar">
    <div class="col-3">
      <div class="float-end"> <img
          [src]="isNextExist? '/assets/images/left-arrow-grey-bleu.svg' :'/assets/images/left-arrow-grey.svg'"
          (click)="last()" /></div>
    </div>

    <div class="col-6  text-center text-style"> <span [ngClass]="{rtl:appService.rtl()}">{{ 'LACT_NUM' | translate
        }}</span><span [getAuthorized]="authorityAnimals" *ngIf="timeline">({{ timeline.lactationNum}})</span>

    </div>
    <div class="col-3"><img
        [src]="isLastExist? 'assets/images/right-arrow-grey-bleu.svg' :'/assets/images/right-arrow-grey.svg'"
        (click)="next()" />
    </div>
  </div>
  <div class="white-bar">
  </div>
</div>


<div class="wrap" *ngIf="display" [getAuthorized]="authorityTimelines">

  <div class="line mt-5 mb-5" [ngStyle]="{'height': max+'px'}">

    <div class="event"
      [ngStyle]="{'top': 'calc(' + element.position+'%' + ' - '+ fixedWidthButton +')'  , 'left':  '-' +fixedWidthButton}"
      *ngFor="let element of timelineDetails">

      <button (click)="timelineEventClick(timeline, element)" #buttonRef [editAuthorized]="authorityTimelines"
        [ngStyle]="{'background-color': element.style.backgroundColor}" mat-fab class="style-size">
        <img src={{element.style.icon}} />
        <span class="num-style" [ngStyle]="{'color': element.style.color}">{{element.style.num}}</span>
      </button>

      <div #tagRef class="style-tag" [ngStyle]="{'color': element.color}"
        [ngClass]="(element.date  | date: appService.rtl()? 'yyyy/MM/dd' : 'dd/MM/yyyy') != today ? 'd-block' : 'd-none'">
        {{element.date? (element.date | date: appService.rtl()? 'yyyy/MM/dd' : 'dd/MM/yyyy') : '-/-/-'}}</div>
    </div>

    <div *ngIf="showCurrentState" class="vertical-line" [ngStyle]="{'height': currentState.position +'%'}">
      <div class="cercle"></div>
      <div class="horizantal-line"></div>
      <div class="currrent-date"> {{currentState.label | date: appService.rtl()? 'yyyy/MM/dd' : 'dd/MM/yyyy'}}
      </div>
    </div>

    <div *ngFor="let label of intervalLabels; let i = index" class="interval-label"
      [ngStyle]="{'top': 'calc('+intervalLabelsPositions[i]+'% - 11px)'}" [ngClass]="{rtl:appService.rtl()}">
      <span> {{label}}</span>
    </div>

    <img *ngIf="timeline && !timeline.abortExists && timeline.positifExists && !timeline.end" class="abort-button"
      ngxHideOnScroll [scrollingElementSelector]="'body'" [propertyUsedToHide]="'bottom'" [valueWhenHidden]="'-90px'"
      [editAuthorized]="authorityTimelines" [valueWhenShown]="'80px'"
      (click)="timelineEventClick(timeline, {status:status.X_NO_ACCESSIBLE})"
      [src]="!timeline.abortExists && timeline.positifExists && timeline.id != -1? '/assets/images/timeline/abort-button.svg' : '/assets/images/timeline/abort-button-no-access.svg'" />
    <img *ngIf="(timeline && timeline.end) || abortExists" class="delete-button" (click)="delete(timeline)"
      ngxHideOnScroll [scrollingElementSelector]="'body'" [propertyUsedToHide]="'bottom'" [valueWhenHidden]="'-90px'"
      [valueWhenShown]="'0px'" [deleteAuthorized]="authorityTimelines"
      [src]="(timeline && timeline.end)? '/assets/images/timeline/Soustraction-4.svg' : '/assets/images/timeline/Soustraction-4-1.svg'" />
  </div>
</div>

