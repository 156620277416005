import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { FarmerFeedConsumptionService } from 'projects/shared/services/farmer-feed-consumption.service';
import { CommonDialogComponent } from '../../common-dialog/common-dialog.component';
import { CustomRouter } from '../../services/custom-router';
import { Country, FORMAT_MAX_MIN, Group, GroupCode, NUMERIC_PATTERN, NUMERIC_PATTERN_FEED, SectionForm } from 'projects/shared/tools/constants';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators, } from '@angular/forms';
import { FORM_DATA } from '../../settings/settings-form/edit-setting-form/edit-setting-form.component';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { FarmerStockFeedService } from 'projects/shared/services/farmer-stock-feed.service';
import * as moment from 'moment';
import { GroupService } from 'projects/shared/services/group.service';

@Component({
  selector: 'app-edit-farmer-feed-consumption',
  templateUrl: './edit-farmer-feed-consumption.component.html',
  styleUrls: ['./edit-farmer-feed-consumption.component.scss']
})
export class EditFarmerFeedConsumptionComponent implements OnInit {
  formGroup: UntypedFormGroup;
  idFarmer
  idFormTemplate
  id
  result
  create = false
  name = "CONCENTRATED_FEED"
  section = SectionForm.farmer_feed_consumption
  formTemplate
  formatArabe
  maxDate: any;
  valueQuantity
  finalResult
  minDate: any
  selectedGroups: any[];
  listGroups
  labelAr: boolean = false
  labelEn: boolean = false
  labelFr: boolean = false
  labelInd: boolean = false
  numericPattern = NUMERIC_PATTERN
  verifStock: boolean = false
  isDisabled: boolean = false
  color='color-feed'
  newDateWithTime
  displayCode:boolean=false;
  constructor(public groupService: GroupService, public farmerStockFeedService: FarmerStockFeedService, public tokenManagementService: TokenManagementService, public formTemplateService: FormTemplateService, @Inject(FORM_DATA) @Optional() data, public dialog: MatDialog, public farmerFeedConsumptionService: FarmerFeedConsumptionService,
    public appService: AppService, private formBuilder: UntypedFormBuilder,
    public translate: TranslateService, private alertsService: AlertsService,
    private router: CustomRouter, public route: ActivatedRoute) {
    this.formatArabe = this.appService.verifFormatDate()
    this.fixArabeFormat()
    const { idFormTemplate, id } = this.route.snapshot.params
    this.idFarmer = this.appService.connectedCustomer.id
    this.idFormTemplate = idFormTemplate;
    this.id = id
    this.findFormTemplate()
    this.createForm()
  }

  ngOnInit() {
    
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.labelAr = true
    else if (this.tokenManagementService.getTranslateLanguage() == "fr")
      this.labelFr = true
      else if (this.tokenManagementService.getTranslateLanguage() == "ind")
      this.labelInd = true
    else
      this.labelEn = true
    this.getGroups()
    this.isDisabled = this.id ? true : false;
  if(!this.id){
    this.formGroup.get('date').valueChanges.subscribe((date) => {
        this.formGroup.setControl('stocks', this.formBuilder.array([]));
        this.findListStock(moment(date).format('YYYY-MM-DD'));
      
    });}
    if (Country.isPakIdn(this.appService.connectedCustomer.addresses[0].country.code))
      this.displayCode = true;
    else
      this.displayCode = false;
    
  }
  getGroups() {
    this.groupService.findAvailableGroupOfCustomer().subscribe((res: any) => {
      const groupCodesSet = new Set<string>();
      this.selectedGroups = res['_embedded']?.groups?.filter(group => {
        const isFeedGroup = Group.isFeedGroup(group.code);
        // Vérifier si le groupe est un groupe d'alimentation et s'il n'est pas déjà présent dans l'ensemble des codes de groupe
        if (isFeedGroup && !groupCodesSet.has(group.code)) {
          groupCodesSet.add(group.code); // Ajouter le code du groupe à l'ensemble
          return true; // Garder le groupe dans la liste filtrée
        }
        return false; // Ignorer le groupe s'il n'est pas un groupe d'alimentation ou s'il est déjà présent
      });
    });
  }
  fixArabeFormat() {
    
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD/MMM/YYYY"
  }
  findListStock(date?) {
    if(!date&& !this.id)
   date=moment(new Date).format('YYYY-MM-DD')
    this.farmerStockFeedService.findTitleAndUnity(date).subscribe(res => {
      this.finalResult = res;

      if (!this.id) {
        this.displayStocks(this.finalResult)
      }
      else {
        this.farmerFeedConsumptionService.find().subscribe(res => {

          if (res[this.id])
            this.displayStocks(res[this.id])

        })

      }
    })
  }

  displayStocks(result) {
    
    if ((result?.length > 0))
      result.forEach(element => {
        this.addStock(element);
      })
  }
  get stocks(): UntypedFormArray {
    return this.formGroup.get("stocks") as UntypedFormArray
  }

  createForm() {
    
    this.formGroup = this.formBuilder.group({
      'date': [!this.id ? (moment(new Date()).format(this.appService.verifFormatDate())):'', Validators.required],
      'stocks': this.formBuilder.array([]),
      'group': [null, Validators.required],
      'consumptionPeriod':[ null, Validators.required],
      'period':[null, Validators.required]
    });
    if(this.id)
    this.findListStock(this.formGroup.controls['date'].value)
     else
     this.findListStock()
  }

  addStock(value?) {
    this.stocks.push(this.newStock(value));
  }

  newStock(stock?): UntypedFormGroup {
    if (this.id) {
      this.formGroup.controls['group'].setValue(stock?.group?.id);
      this.formGroup.controls['date'].setValue(stock?.date)
      this.formGroup.controls['consumptionPeriod'].setValue(stock?.consumptionPeriod)
      this.formGroup.controls['period'].setValue(stock?.period)
      if (stock.quantity <= 0)
        this.valueQuantity = (stock.quantity * -1)
      else
        this.valueQuantity = stock.quantity
    }
    return this.formBuilder.group({
      'stockDate': [stock.stockDate ? (moment(stock.stockDate).format(FORMAT_MAX_MIN)) : (moment(new Date()).format(this.appService.verifFormatDate()))],
      'id': [this.id ? stock.id : null],
      'title': [stock?.title],
      'unit': [stock?.unit],
      'quantity': [this.id ? this.valueQuantity : null],
      'quantityStock': [(stock?.quantityStock) ? stock?.quantityStock : stock?.quantity]

    })

  }

  findFormTemplate() {
    this.formTemplateService.getForm(this.idFormTemplate, "form-template", this.section).subscribe(template => {
      this.formTemplate = template
    })
  }


  back() {
    this.router.back('')
  }
  getField(field) {
    return this.formTemplate.xml.formFields.filter(f => f.code == field);
  }
  confirm() {
    this.addCurrentTimeToOriginalDate(this.formGroup.controls['date'].value)
    let listValueQuantity = []
    let listStocks = this.formGroup.controls['stocks'].value
    listStocks.forEach(element => {
      if (element.quantity == null)
        element.quantity = 0
      if (!this.formGroup.controls['stocks'].value[0].id) {
        if (element.quantity > element.quantityStock)
          this.verifStock = true
        listValueQuantity.push(element.quantity)
      }
      else {
        if (element.quantity > (element.quantityStock + this.valueQuantity))
          this.verifStock = true
        listValueQuantity.push(element.quantity)
      }
    })
    
    if (this.verifStock) {
      this.appService.error('VERIF_STOCK')
      this.verifStock = false
    }
    else if (!(listValueQuantity.some(valeur => valeur > 0)))
      this.appService.error('VERIF_QUANTITY_FEED')
    else if ((this.formGroup.invalid) && (this.displayCode))
    this.appService.error('FIELD_REQUIRED')
    else {
     
      this.farmerFeedConsumptionService.saveAll(listStocks,this.newDateWithTime , this.formGroup.controls['group'].value,this.formGroup.controls['consumptionPeriod'].value,this.formGroup.controls['period'].value).subscribe(
        data => {
          this.appService.successMessage('SAVE_SUCESS');
          this.back();
        }, error => {
          this.appService.error(error.error.code)
        }
      )
    }
  }
  addCurrentTimeToOriginalDate(date) {
    let originalDate = new Date(date);
    let currentTime = new Date();
    let formattedTime = currentTime.toTimeString().split(' ')[0];
    let formattedDate = this.formatDate(originalDate);
    this.newDateWithTime = `${formattedDate}T${formattedTime}.${currentTime.getMilliseconds()}Z`;
  
  }

  formatDate(date: Date): string {
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);
    let formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
}
