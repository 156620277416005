<app-section-bar [title]="title" (click)="back()" [color]="color"></app-section-bar>
<form [formGroup]="formGroup" (ngSubmit)="save()">

    <div class="center-element mt-5">
        <mat-card class="ms-2 me-2 card-style">

            <div class="row ms-0 px-4">
                <div class="col-12 mx-auto ">
                    <div class="mt-3 text-center title-style" [ngClass]="{rtl:appService.rtl()}">
                        <div>{{ 'FEEDBACK_TITLE' | translate}} <img class="mt-2" src="/assets/images/grinning-face.svg">
                        </div>

                    </div>
                </div>
            </div>

            <div class="row center-element me-4 ms-4 ">
                <div class="col">
                    <textarea class="textarea-style pt-2 mb-3  w-100" [ngClass]="{rtl:appService.rtl()}"
                        formControlName="message" [readonly]="disabled" required matInput cdkTextareaAutosize
                        [maxlength]="maxSize" #autosize="cdkTextareaAutosize"
                        placeholder="{{ 'WRITE_FEEDBACK' | translate}}" cdkAutosizeMinRows="3"
                        (keyup)="verifLengthMessage(formGroup)">
                </textarea>

                    <mat-hint  [ngClass]="appService.rtl()? 'mat-hint-style-ar' : 'mat-hint-style'">{{(formGroup.controls['message'].value).length}} /
                        {{maxChar}}</mat-hint>
                </div>
            </div>

            <div class="row center-element me-4 ms-4 ">
                <div class="col">
                    <div  [ngClass]="{rtl:appService.rtl()}">
                        <strong>{{'NUMBER_FEEDBACK' | translate}}:</strong> {{numberOfFeedbacksPerDay}} /
                        {{maxFeedbaks}}
                    </div>
                </div>
            </div>
            <div class="center-element mt-4">
                <button mat-raised-button color="primary" class="button-mob button-style " type="submit"
                    [ngClass]="!formGroup.valid  ?  'opac' : '' "><span class="center-element"
                        [ngClass]="{rtl:appService.rtl()}">{{ 'CONFIRM'
                        | translate}}</span></button>
            </div>

        </mat-card>
    </div>
</form>