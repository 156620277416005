import { state } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Customer } from 'projects/shared/resources/Customer';
import { Form } from 'projects/shared/resources/Form';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { FormService } from 'projects/shared/services/form.service';
import { CustomRouter } from '../../services/custom-router';
import { Authorities, SectionForm } from 'projects/shared/tools/constants';
import * as uuid from 'uuid';
@Component({
  selector: 'app-edit-card-economy',
  templateUrl: './edit-card-economy.component.html',
  styleUrls: ['./edit-card-economy.component.scss']
})
export class EditCardEconomyComponent implements OnInit {

  idFarmer
  idFormTemplate
  formTemplate
  id
  result
  name
  valueIdFormTemplate
  section=SectionForm.farmer_economy
  currentRoute
  color='color-abort'
  constructor(public formService: FormService, public appService: AppService,
    public translate: TranslateService, private alertsService: AlertsService,
    private router: CustomRouter, public route: ActivatedRoute, public formTemplateService: FormTemplateService) {

    const {  idFormTemplate, id } = this.route.snapshot.params
    this.idFarmer = this.appService.connectedCustomer.id
    this.idFormTemplate = idFormTemplate;
    this.id = id
    this.findFormTemplate()
    if(this.formTemplate.xml.type=="OTHER_INCOMES")
    this.name='OTHER_INCOMES'
    else 
    this.name='OTHER_COSTS'
    if (this.id)
      this.displayInfo(this.id);
  }

  ngOnInit(): void {

  }

  findFormTemplate() {
    this.formTemplateService.getForm(this.idFormTemplate, "form-template",this.section).subscribe(template => {
      this.formTemplate = template
    })
  }
  displayInfo(id) {
 
      this.formService.getFormById(id, this.idFormTemplate, this.idFarmer, "form-projection").subscribe(res => {
        this.result = res;
      })
    return this.result
  }
  
  confirm(event) {
    
    if(event.formGroup.status=="INVALID"){
      this.appService.error('STOCK_INVALID')
    }
    else{
    let form = new Form()
    if (event.id) {
      if (event.id.length > 8)
        form.uuid = event.id
      else
        form.id = event.id
    }
    else form.uuid = uuid.v4();
    if ((!(event.formGroup.value?.incomes)&& (this.formTemplate.xml.type=="OTHER_INCOMES"))||(!(event.formGroup.value?.totalCosts)&& (this.formTemplate.xml.type=="COSTS"))) {
      this.appService.error('FIELD_REQUIRED')
    }
    else {
      form.date = moment()
      form.value = event.formGroup.value
      form.formTemplate = this.formTemplate
      form.farmerForms = [{ farmer: { id: this.idFarmer } }]
      this.appService.showLoading()
      this.formService.saveForm(form).subscribe(res => {
        this.appService.hideLoading();
        let message = this.translate.instant('SAVE_SUCESS');
        this.alertsService.open(message);
        this.back()
      }
      )
    }}
  }

  back() {
    this.router.back('')
   
  }}