<div [ngClass]="isPopUp? 'make-scrollable' : '' ">
<div class="row">
    <div *ngIf="!isPopUp" [ngClass]="animalId? 'col-3 m-4' : 'col m-2' " >
        <div [ngClass]="animalId? 'float-start' : 'float-end'">
            <img [src]="animalId? '/assets/images/left-arrow-2.svg' : '/assets/images/Union-6.svg'" (click)="animalId? back() : backAdd()"/>
        </div>
    </div>
    <div *ngIf="isPopUp" class="d-flex align-items-end flex-column ">
          <img src="/assets/images/Union-5.svg" (click)="closePopUp()" />
      </div>
</div>
<div class="row">
    <div class="col mb-3 text-center"><span class="text-style" [ngClass]="{rtl:appService.rtl()}">{{ mess | translate
            }}</span></div>
</div>
<div *ngIf="showForm">
    <form [formGroup]="formGroup">
        <div class="d-flex justify-content-center">
            <div class="row gx-0" *ngIf="code!='MAR'">
                <div class="col-6 mb-3 mt-1 center-element back-style">
                    <span class="text-style">{{suffix}}</span>
                </div>
                <div class="col-6 mb-3 text-center uid-width mt-2 ps-0 pe-0">
                    <input matInput class="input-mob font-size-text input-backg" id="uid" name="uid"
                        placeholder="{{ 'NUM_COW'| translate }}" [type]="!valueTypeUid ? 'tel' : 'text'" 
                        [ngClass]="{rtl:appService.rtl()}" formControlName="uid">


                </div>
            </div>
            <div class="row" *ngIf="code=='MAR'" style="margin-left: 35px;">
                <div class="col-6 mb-3 mt-1  back-style-marroc ps-0 center-element">
                    <span class="text-style">{{suffix}}</span>
                </div>
                <div class="col-6 mb-3 text-center  mt-2 ps-0 pe-0">
                    <div class="row">

                        <input matInput
                            class="input-mob font-size-text-marroc-fr text-center input-backg-Mar col-3 ps-0 pe-0"
                            id="uid1" name="uid1" type="text" [ngClass]="{rtl:appService.rtl()}" formControlName="uid1">
                        <span class="col-3 text-style center-element back-style-B ps-0 pe-0">B</span>
                        <input matInput
                            class="input-mob uidMaroc font-size-text-marroc-fr text-center input-backg-uid1 col-6 ps-0 pe-0"
                            id="uid2" name="uid2" placeholder="{{ 'NUM_COW'| translate }}" type="text"
                            [ngClass]="{rtl:appService.rtl()}" formControlName="uid2">
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="d-flex justify-content-center" >
            <div class="row gx-0" *ngIf="suffix=='MAR'">
                <div class="col-3">
                    <span >{{suffix}}</span>
                </div>
                <div class="col-2">
                    <input matInput  id="uid1" name="uid1"
                    *ngIf="suffix=='MAR'" type="number" [ngClass]="{rtl:appService.rtl()}" formControlName="uid1"
                    >
                </div>
                <div class="col-2">
                    <span  *ngIf="suffix=='MAR'">B</span>
                </div>
                <div class="col-5">
                    <input matInput id="uid2" name="uid2" *ngIf="suffix=='MAR'"
                    placeholder="{{ 'NUM_COW'| translate }}" type="number"
                    [ngClass]="{rtl:appService.rtl()}" formControlName="uid2"
                     > 
                </div>
              </div>
          </div>-->
        <div class="d-flex justify-content-center mb-2">
            <div class="row ">
                <div class="col  text-center center-element" [ngClass]="{rtl:appService.rtl()}">
                    <input class="input-mob  font-size-text height-style" [ngClass]="{rtl:appService.rtl()}"
                        placeholder="{{ 'COWS_NAME'| translate }}" id="name"type="text" formControlName="name" >
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center" [getAuthorized]="authorityBreeds" [getAuthorized]="authoritySpecies" >
            <div class="row" >
                <div class="col mb-3" >
                    <mat-select class="input-mob font-size-text op" disableOptionCentering panelClass="myPanelAnimalClass" placeholder="{{ 'BREED'| translate }}"
                        [ngClass]="{rtl:appService.rtl()}" formControlName="breed" required >
                        <input  (keydown)="$event.stopPropagation()" (keyup)="onKey($event.target.value)" class="input-search-mob ">
                        <mat-option *ngFor="let breed of selectedBreeds" [value]="breed.id" >
                            <span
                                [ngClass]="{rtl:appService.rtl()}">{{breed | codeTranslate}}</span>
                        </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center">
            <div class="row" [ngClass]="{rtl:appService.rtl()}">
                <div class="text-age center-element  col-3 ps-0 pe-0 ">
                    <span [ngClass]="{rtl:appService.rtl()}"> {{ 'AGE'| translate }}</span>
                </div>
                <div class="col-4 center-element">
                    <input class="input-mob year-style" [ngClass]="{rtl:appService.rtl()}" type="number" min="0"
                        max="16" (keyup)="appService.verifAge(formGroup , formatArabe)" formControlName="years">

                    <div *ngIf="formGroup.controls['years'].valid" [ngClass]="{rtl:appService.rtl()}"><span
                            class="text-age-year">{{ 'YEARS' | translate }}</span></div>

                </div>
                <div class="col-5 center-element">
                    <input class="input-mob year-style" type="number" min="0" max="12" (keyup)="appService.verifAge(formGroup , formatArabe)"
                        [ngClass]="{rtl:appService.rtl()}" formControlName="months">
                    <div *ngIf="formGroup.controls['months'].valid" [ngClass]="{rtl:appService.rtl()}"><span
                            class="text-age-year">{{ 'MONTHS' | translate }}</span></div>
                </div>
            </div>
        </div>
        <!--<div class="d-flex justify-content-center" style="margin-bottom: -35px;">
            <div class="row">
                <div class="col mb-1 text-center mt-2">
                    <input class="input-mob font-size-text" [ngClass]="{rtl:appService.rtl()}" [matDatepicker]="picker"
                        formControlName="birthDate" placeholder="{{ 'DATE_BIRTH'| translate }}"
                        (dateInput)="calculAge($event)" [max]="maxDate" (dateChange)="addEvent($event)">
                    <div [ngClass]="appService.rtl()? 'date-ar' : 'date'">
                       <mat-datepicker-toggle matSuffix [for]="picker">
                            <img matDatepickerToggleIcon src="/assets/images/calendar4.svg" />
                        </mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker></mat-datepicker>
                    </div>
                </div>
            </div>
        </div>-->
        <div class="d-flex justify-content-center" style="margin-bottom: -35px;" [ngClass]="{rtl:appService.rtl()}">
            <div class="row " [ngClass]="{rtl:appService.rtl()}">
                <div class="col mb-5 mt-3 ">
                    <ion-item class="input-mob-date">

                        <ion-datetime #datePicker mode="ios" class="font-size-text " [ngClass]="{rtl:appService.rtl()}"
                            [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()"
                            formControlName="birthDate" placeholder="{{ 'DATE_BIRTH'| translate }}"
                            (ionChange)="appService.calculAge($event, formGroup , formatArabe) ; appService.showLact(formGroup, formatArabe)" [max]="maxDate" [min]="minDate"
                            [monthShortNames]="appService.monthShort()" [doneText]="'SAVE' |translate"
                            [cancelText]="'CANCEL' |translate">

                        </ion-datetime>
                        <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar' : 'date'"
                            src="/assets/images/calendar4.svg" />

                    </ion-item>
                </div>
            </div>
        </div>

        <!--<div class="col mb-3 mt-2 center-element">
            <ion-item class="input-mob ">-->
        <!--<div class="row mt-2">
            <div class="col  text-center center-element" [ngClass]="{rtl:appService.rtl()}" >
                <input  class="input-mob  font-size-text height-style" formControlName="weight"
                    [ngStyle]="{'width':formGroup.controls['weight'].valid? '207px' : ''}" 
                    [ngClass]="{rtl:appService.rtl()}" placeholder="{{ 'WEIGHT'| translate }}" type="number"  min="0"
                    max="5000" (keyup)="verifPoids()" id="weight" (keypress)="appService.validWeight()" >
                <div *ngIf="formGroup.controls['weight'].valid" [ngClass]="{rtl:appService.rtl()}"><span
                        class="text-age">{{
                        'KG' | translate }}</span></div>
            </div>
        </div>-->

        <div class="row gx-1" (click)="appService.showLact(formGroup, formatArabe)">
            <div class="col-6 mt-3">
                <button class="sex-style male-style float-end" (click)="formGroup.controls['sex'].setValue('MALE')"
                    [ngClass]="formGroup.controls['sex'].value == 'MALE' ? 'bor-style ' : '' "><img
                        src="/assets/images/cow-m.svg"></button>
            </div>
            <div class="col-6 mt-3"><button class="sex-style  female-style"
                    (click)="formGroup.controls['sex'].setValue('FEMALE')"
                    [ngClass]="formGroup.controls['sex'].value == 'FEMALE' ? 'bor-style' : '' "><img
                        src="/assets/images/cow-f.svg"></button>
            </div>
        </div>
        <div>
            <div class="d-flex justify-content-center" [ngClass]="{rtl:appService.rtl()}"
                *ngIf="formGroup.controls['sex'].value  == 'FEMALE' && appService.show">
                <div class="row mt-3 mb-4" [ngClass]="formGroup.controls['sex'].value  != 'FEMALE' ? 'color' : ''  ">
                    <div class="col-5 mt-3 " [ngClass]="{rtl:appService.rtl()}">
                        <div class="text-cal align" [ngClass]="appService.rtl()? 'mar-cal-ar' : 'mar-cal'"><span
                                [ngClass]="{rtl:appService.rtl()}">{{ 'CALVINGS' | translate }}</span></div>
                    </div>
                    <div class="col-7" [ngClass]="{rtl:appService.rtl()}">
                        <div class="row">
                            <div [ngClass]="{rtl:appService.rtl()}"
                                class=" col-3  center-element plus-moins-style moins"
                                [ngClass]="appService.rtl()? 'margin-moins-ar' : 'margin-moins'" (click)="appService.moins(formGroup)">
                                <img src="/assets/images/moins.svg">
                            </div>
                            <div class="col-6 center-element" [ngClass]="{rtl:appService.rtl()}">
                                <div class="back-cal-style center-element">
                                    <span class="text-style text-style-0 "
                                        [ngClass]="appService.rtl()? 'pt-2' : ''">{{appService.calvings}}</span>
                                </div>
                            </div>
                            <div class="col-3 center-element plus-moins-style plus" [ngClass]="{rtl:appService.rtl()}"
                                (click)="appService.plus(formGroup)">
                                <img src="/assets/images/plus.svg">
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div *ngIf="formGroup.controls['sex'].value  == 'FEMALE' && appService.calvings >0 && appService.show">
            <div class="d-flex justify-content-center" [ngClass]="{rtl:appService.rtl()}">
                <div class="row" [ngClass]="{rtl:appService.rtl()}">
                    <div class="text-age " [ngClass]="appService.rtl()? 'float-start': 'float-end'">
                        <div class="col mb-2" [ngClass]="appService.rtl()? 'mar-ar': 'mar'">
                            <span [ngClass]="{rtl:appService.rtl()}"> {{ 'CHOOSE_GR'| translate }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center" [ngClass]="{rtl:appService.rtl()}">
                <div class="row">
                    <mat-radio-group formControlName="group">
                        <div class="col-6" [ngClass]="{rtl:appService.rtl()}">
                            <mat-radio-button value="productionCode" class="mb-2"><span class="text-age ms-1 me-1"
                                    [ngClass]="{rtl:appService.rtl()}">{{ 'PROD' | translate}}</span>
                            </mat-radio-button>
                        </div>
                        <div class="col-6" [ngClass]="{rtl:appService.rtl()}">
                            <mat-radio-button value="dryCode" class="mb-2"><span class=" text-age ms-1 me-1"
                                    [ngClass]="{rtl:appService.rtl()}">{{ 'TARI' | translate}}</span>
                            </mat-radio-button>

                        </div>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </form>

    <div class="row">
        <div class="col text-center mt-3 mb-3">
            <button *ngIf="code!='MAR'"
                [ngClass]="!formGroup.valid  || (!formGroup.controls['uid'].value && !formGroup.controls['name'].value) ?  'opac' : '' "
                mat-raised-button color="primary" class="button-mob" (click)="save()"><span
                    [ngClass]="{rtl:appService.rtl()}">{{ text | translate}}</span></button>
            <button *ngIf="code=='MAR'"
                [ngClass]="!formGroup.valid  || (!formGroup.controls['uid1'].value && !formGroup.controls['uid2'].value &&!formGroup.controls['name'].value) ?  'opac' : '' "
                mat-raised-button color="primary" class="button-mob" (click)="save()"><span
                    [ngClass]="{rtl:appService.rtl()}">{{ text | translate}}</span></button>
        </div>
    </div>
</div>
</div>