import { Injectable, Injector } from '@angular/core';
import { MoomeRestService } from './moome-rest.service';
import { Alert } from '../resources/alert';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root'
})
export class AlertService extends MoomeRestService<Alert> {

    constructor(public http: HttpClient, injector: Injector, private configService: ConfigService) {
        super(Alert);
    }

    getUnreadAlertCount() {
        return this.http.get(this.configService.getApiPath() + `/alerts/getUnreadAlertCount`);
    }

    getUnreadAlertCountWithType(supervisorId?, farmerId?, mccId?) {
        if (supervisorId == null || !supervisorId)
        supervisorId = ""
        if (farmerId == null || !farmerId)
        farmerId = ""
        if (mccId == null || !mccId)
        mccId = ""

        return this.http.get(this.configService.getApiPath() + `/alerts/getUnreadAlertCountWithType?supervisorId=${supervisorId}&farmerId=${farmerId}&mccId=${mccId}`);
    }

    getUnreadAlertCountForSupervisor(supervisor?) {
        if (!supervisor)
        supervisor=""
        return this.http.get(this.configService.getApiPath() + `/alerts/getUnreadAlertCountForSupervisor?supervisorId=${supervisor}`);
    }

}