import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'projects/shared/services/app.service';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { FormService } from 'projects/shared/services/form.service';
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { CustomRouter } from '../services/custom-router';
import { SectionForm } from 'projects/shared/tools/constants';
import { FarmerStockFeedService } from 'projects/shared/services/farmer-stock-feed.service';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})
export class StockComponent implements OnInit {

  title = "STOCK"
  projection = "form-projection"
  section = SectionForm.farmer_stock
  list = []
  listForm = []
  color='color-stock'
  constructor(public formTemplateService: FormTemplateService, public formService: FormService, public appService: AppService, public route: ActivatedRoute,
    private router: CustomRouter, private supervisorService: SupervisorService, private farmerStockFeedService: FarmerStockFeedService) {


    this.listForm = this.appService.loadForm(this.projection,SectionForm.farmer_stock)
    this.appService.idFeedInventory=this.listForm[0].formTemplateId;
    this.appService.idMiscInventory=this.listForm[1].formTemplateId
    this.list = [
      { img: "/assets/images/feed_stock_3.svg", router: "/stock/feedInventory/"+this.listForm[0].formTemplateId, text: "FEED_INVENTORY", class: "sect-style stock-style", back: "stock-back"},
     
      { img: "/assets/images/misc_stock_1.svg", router: "/stock/miscInventory/"+this.listForm[1].formTemplateId, text: "MISC_INVENTORY", class: "sect-style stock-style", back: "stock-back"}
    ]}
  ngOnInit(): void {}

  back() {
    this.router.navigateByUrl('/home')
  }

  
  navigate(element) {
  return this.router.navigateByUrl(element.router)
    }

  
}
