import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ShopFormTemplate } from '../resources/ShopFormTemplate';
import { MoomeRestService } from './moome-rest.service';

@Injectable({
    providedIn: 'root'
})
export class ShopFormTemplateService extends MoomeRestService<ShopFormTemplate> {

    constructor(public http: HttpClient, injector: Injector) {
        super(ShopFormTemplate);
    }
    
    findAll() {
        return this.search(`findAll`)
    }
}