import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable(
	{
		providedIn: 'root'
	}
)
export class ValidatorMessagesService {

	constructor(private translate: TranslateService) { }

	getEmailError(formControl) {
		let mes1 = this.translate.instant('FIELD_REQUIRED');
		let mes2 = this.translate.instant('NOT_VALID_Email')
		let mes3 = this.translate.instant('EMAIL_ALREADY_IN_USE')
		return formControl.hasError('required') ? mes1 :
			formControl.hasError('email') ? mes2 :
				formControl.hasError('alreadyInUse') ? mes3 : '';
	}

	getInstallDateError(formControl){
		let mes1 = this.translate.instant('FIELD_REQUIRED');
		return formControl.hasError('required') ? mes1 : '';
	}
	
	/*checkAlreadyInUseEmail(control) {
		// mimic http database access
		let db = ['tony@gmail.com'];
		return new Observable(observer => {
			setTimeout(() => {
				let result = (db.indexOf(control.value) !== -1) ? { 'alreadyInUse': true } : null;
				observer.next(result);
				observer.complete();
			}, 4000)
		})
	}*/

	getErrorPassword(formControl) {
		let mes = this.translate.instant('PASSWORD_INVALID')
		 return formControl.hasError('required') ? mes :
			formControl.hasError('requirements') ? mes : '';
		/*let mes1 = this.translate.instant('PASSWORD_REQUIRED');
		let mes2 = this.translate.instant('PASSWORD_REQUIREMENTS');
		return formControl.hasError('required') ? mes1 :
			formControl.hasError('requirements') ? mes2 : '';*/
       
		}
}