import { Component, OnInit } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { CustomRouter } from '../services/custom-router';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss'],

})
export class GetStartedComponent implements OnInit {
  language: String = 'ar'
  router
  selectedLanguage = {};
  color='color-white'
  constructor( public tokenManagementService: TokenManagementService,public translate: TranslateService,public appService: AppService, router: CustomRouter, private dateAdapter: DateAdapter<Date>) {

    this.router = router
    const l = this.tokenManagementService.getTranslateLanguage()
    if (l) {
      this.language = l;
      this.selectedLanguage=this.appService.chooseLanguage(this.language);
    }
  }

  ngOnInit() {
  }

}
