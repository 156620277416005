import { Injectable } from '@angular/core';
import { Customer } from '../resources/Customer';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from "rxjs";
import { ConfigService } from './config.service';
import { TranslateService } from '@ngx-translate/core';
import { map, catchError } from '../../../node_modules/rxjs';
import state from "../../shared/tools/state"
import { CapacitorUtils } from '../tools/CapacitorUtils';
import { Platform } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class TokenManagementService {

	expiry: string | number;
	user: Customer = null;
	accessToken: string = null;
	private isRefreshingToken: Subject<boolean>;
	public authenticated: Subject<boolean> = new Subject();
	role: any
	constructor(private http: HttpClient, private configService: ConfigService,
		 public translate: TranslateService, private platform: Platform) {
		this.expiry = Number(localStorage.getItem("token_expiry"));
	}

	translateLanguage(language) {
		localStorage.setItem('language', language);
	}

	setDeviceToken(deviceToken) {
		localStorage.setItem('device_token', deviceToken);
	}

	login(username: string, password: string) {
		return this.http.post<any>(this.configService.getServerTokenPath() + "oauth/token",
			"grant_type=password&username=" + username + "&password=" + password,
			{
				headers: {
					'Authorization': this.configService.getServerAuthorisation(),
					'Content-type': 'application/x-www-form-urlencoded',
					'Access-Control-Allow-Origin': '*',
				}
			}).pipe(map(res => {
				this.setTokens((<any>res).access_token, (<any>res).refresh_token, (<any>res).expires_in)
				this.setRole((<any>res).accessApp)
				this.authenticated.next(true);
				localStorage.setItem('role', (<any>res).authorities);
				//localStorage.setItem('userConnected', (<any>res).id);
				return res;
			}));
	}

	setTokens(accessToken: string, refresh_token?: string, expiry?) {
		if (accessToken) {
			localStorage.setItem('access_token', '' + accessToken);
			if (refresh_token)
				localStorage.setItem('refresh_token', '' + refresh_token);
			if (expiry) {
				this.expiry = new Date().getTime() + expiry * 1000;
				localStorage.setItem("token_expiry", "" + this.expiry);
			}

		} else {
			localStorage.removeItem('access_token');
			localStorage.removeItem('refresh_token');
			localStorage.removeItem('token_expiry');
		}
		this.capacitoreStorage()
	}

	getExpiry() {
		if (!this.expiry)
			this.expiry = Number(localStorage.getItem("token_expiry"));
		return this.expiry;
	}

	logout() {
		state.clearWithExcludeItem(['device_token', 'language'])

		/*localStorage.removeItem("access_token");
		localStorage.removeItem("refresh_token");
		localStorage.removeItem("token_expiry");
		localStorage.removeItem("userConnected");
		localStorage.removeItem("role");
		localStorage.removeItem("farmers");
		localStorage.removeItem("formData");
		localStorage.removeItem("forms");*/
		this.role = null;
		this.user = null;
		//this.token = null;
		this.expiry = null;
	}


	tokenIsExpired() {
		let expiry = localStorage.getItem("token_expiry");
		if (!expiry) {
			return true;
		}
		return Number(expiry) < (new Date().getTime() / 1000) + 500;
	}


	checkToken() {
		if (!this.getAccessToken())
			return of(false)
		if (!this.tokenIsExpired()) {
			return of(true);
		}
		if (this.getRefreshToken())
			return this.refreshToken()
		return of(false)
	}


	refreshToken(): Observable<any> {
		if (!this.isRefreshingToken) {
			//this.isRefreshingToken = true;
			this.isRefreshingToken = new Subject();
			return this.http.post(this.configService.getServerTokenPath() + "oauth/token", "grant_type=refresh_token&refresh_token=" + this.getRefreshToken(), {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'Authorization': "Basic " + btoa("trusted:secret")
				}
			}).pipe(map(res => {
				let r: any = res

				this.setToken(r.access_token, r.refresh_token, r.expires_in, r.fullname);
				//this.lastTokenCheckDate = new Date().getTime();
				//this.isRefreshingToken = false;
				this.isRefreshingToken.next(true);
				this.isRefreshingToken.complete()
				this.isRefreshingToken = null
				return true
			})).pipe(catchError(e => {
				//this.isRefreshingToken = false;
				this.isRefreshingToken.next(false);
				this.isRefreshingToken.complete();
				this.isRefreshingToken = null;
				return of(false)
			}));
		} else {
			//return this.refreshTokenEmitter
			return this.isRefreshingToken

		}

	}

	capacitoreStorage() {
		if (this.platform.is("cordova")) {
			CapacitorUtils.setName('token', this.getToken())
			CapacitorUtils.setName('serverPath', this.configService.getServerTokenPath())
		}
	}



	getAccessToken(): string {
		return localStorage.getItem('access_token');
	}

	verifyApp() {

		if (!this.getToken())
			return of(false)
		if (!this.tokenIsExpired()) {
			return of(true);
		}
		if (this.getRefreshToken())
			return this.doRefreshToken()
		return of(false)
	}

	verify() {
		if (!this.getExpiry()) {
			this.logout();
			return of(false);
		}

		if (new Date().getTime() > this.getExpiry()) {
			return this.doRefreshToken();

		}
		return of(true);

	}

	getRefreshToken(): string {
		return localStorage.getItem('refresh_token');
	}

	doRefreshToken() {

		if (!this.isRefreshingToken) {
			this.isRefreshingToken = new Subject();
			//return Observable.of(true)
			return this.http.post(this.configService.getServerTokenPath() + "oauth/token", "grant_type=refresh_token&refresh_token=" + this.getRefreshToken(), {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'Authorization': "Basic " + btoa("trusted:secret")
				}
			}).pipe(map(res => {
				let r: any = res

				this.setToken(r.access_token, r.refresh_token, r.expires_in, r.fullname);
				this.isRefreshingToken.next(true);
				this.isRefreshingToken.complete()
				this.isRefreshingToken = null
				return true
			})).pipe(catchError(e => {
				//this.isRefreshingToken = false;
				this.isRefreshingToken.next(false);
				this.isRefreshingToken.complete();
				this.isRefreshingToken = null;
				return of(false)
			}));
		} else {
			//return this.refreshTokenEmitter
			return this.isRefreshingToken
		}
	}

	setRole(role) {
		this.role = role;
	}

	getAccess(access) {
		let accessApp: Boolean = false
		let keys = Object.keys(this.role);
		keys.forEach(key => {
			this.role[key].forEach(element => {
				if (element[access] == true)
					accessApp = true
			})
		})
		return accessApp;
	}

	setToken(accessToken: any, refreshToken, expiry, userConnected) {

		this.accessToken = accessToken;
		localStorage.setItem("access_token", accessToken);
		localStorage.setItem("refresh_token", refreshToken);
		this.expiry = new Date().getTime() + expiry * 1000;
		localStorage.setItem("token_expiry", "" + this.expiry);
		//localStorage.setItem("userConnected", userConnected)
		this.capacitoreStorage()
		console.log("setToken done");

	}

	getToken() {
		return localStorage.getItem('access_token');
	}

	hasRole(role): Observable<any> {
		return this.http.get(this.configService.getApiPath() + "/customers/hasRole/" + role);
	}

	getTranslateLanguage() {
		return localStorage.getItem('language');
	}

	getDeviceToken() {
		return localStorage.getItem('device_token');
	}


	getUserInfo() {
		return this.http.get(this.configService.getApiPath() + "/customers/info");
	}
}


