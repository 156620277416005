import { Injectable } from '@angular/core';
//import { Plugins } from '@capacitor/core';
import { CommonDialogComponent } from 'projects/publicV2/src/app/common-dialog/common-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppVersionService } from './app-version.service';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Device } from '@capacitor/device';

//const { NativeMarket } = Plugins;

@Injectable({
    providedIn: 'root'
})
export class UpdateService {

    constructor(
        private appVersion: AppVersion,
        public dialog: MatDialog,
        public translate: TranslateService,
        public appVersionService: AppVersionService
    ) { }
//à voir
    async checkForUpdate() {
        //this.http.get(this.configService.getApiPath() + `/version-app/check`, { responseType: 'text' }).subscribe((lastVersionAppStore: string) => {
        this.appVersionService.getPages().subscribe(data => {


            if (data.resources[0]) {
                let lastVersionAppStore = data.resources[0].androidVersion
                console.log('lastVersionAppStore ' + lastVersionAppStore)
                this.appVersion.getVersionNumber().then(value => {
                    let versionNumber = value
                    console.log(' VersionNumber ' + versionNumber)
                    if (versionNumber != lastVersionAppStore) {
                        this.presentAlert('UPDATE_APP', 'Dounload_NEW_VERSION')
                    }
                }).catch(err => {
                    console.log('erreur  ' + err)
                });
            }

        })
    }

    async presentAlert(title, message) {
        const dialogRef = this.dialog.open(CommonDialogComponent, {
            data: {
                message: this.translate.instant(message),
                title: this.translate.instant(title),
                onConfirm: () => {
                    this.openPlaystoreEntity();
                }
            }
        });
    }

    openPlaystoreEntity() {
        console.log('OPEN ME')
        this.getdevinfoAndOpenApp()
        /* if (this.plt.is('android')) {
             NativeMarket.openStoreListing({
                 appId: 'io.lifeye.moome'
             });
         } else {
             this.iab.create('itms-app://itunes.apple.com/app/id1469563885', '_blank')
         }*/

    }

    getdevinfoAndOpenApp() {
        console.log('trying to get device info');
        Device.getInfo().then (res => {
        console.log(res['manufacturer'])
        if((res['manufacturer']).startsWith("HUAWEI"))
        window.open("appmarket://C104973837");
        else 
        window.open("market://details?id=io.lifeye.moome");
        })
    }
}