<div class="card-size ">
    <div class="row   pt-3" [ngClass]="{rtl:appService.rtl()}">
        <div class="col-8 ps-4 pe-4" [ngClass]="{rtl:appService.rtl()}">
            <div [ngClass]="{rtl:appService.rtl()}" class="ellipsis-text"><span
                    class=" font-name title-style ">{{milkCollectionCenter.name}}</span></div>
            <div [ngClass]="{rtl:appService.rtl()}"><span
                    class="nbr-style-parametre title-style-parametre">{{milkCollectionCenter.phone}}</span></div>
            <div [ngClass]="{rtl:appService.rtl()}"><span
                    class="country-style-parametre title-style-parametre">{{milkCollectionCenter.region |
                    codeTranslate}}</span></div>
        </div>
        <div class=" col-4 ps-2 pe-2 " (click)="openTanks(milkCollectionCenter.id)">

            <div class="min-card width-card-animals blue-color ">
                <div class="row  h-100 gx-1 px-1" [ngClass]="appService.rtl()? 'pt-1' : ''">
                    <div class="col-7 center-element">
                        <span><img src="/assets/images/milk_tank_icon_1.svg"></span>
                    </div>
                    <div class="col-5 center-element">
                        <span class="nbr-style-number">{{milkCollectionCenter.tankCount? milkCollectionCenter.tankCount
                            : "0"}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row px-4 mt-4 mb-1 gx-4" [ngClass]="{rtl:appService.rtl()}">
        <div class="col-5 ps-0" [getAuthorized]="authorityAlerts" (click)="openAlerts(milkCollectionCenter.id)" >
            <div class="min-card-meduim width-card blue-color " [ngClass]="{rtl:appService.rtl()}">
                <div class="row center-element h-100 gx-1 px-1">
                    <div class="col-4">
                        <span><img src="/assets/images/Alert-menu.svg"></span>
                    </div>
                    <div class="col-8">
                        <span class="title-style-parametre">{{ 'alerts' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-5 ps-0">
            <div class="min-card-meduim width-card-rapports blue-color" [ngClass]="{rtl:appService.rtl()}">
                <div class="row center-element h-100 gx-1 px-1">
                    <div class="col-4">
                        <i style="font-size: 30px"[ngClass]="appService.rtl()? 'material-icons' : 'material-icons icon-style'"
                        >
                            dvr
                        </i>
                    </div>
                    <div class="col-8">
                        <span class="title-style-parametre">{{ 'RAPPORTS' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-2 ">
            <div class="min-card-meduim width-card-settings blue-color ">

                <div class="row center-element h-100 pt-1 px-1">
                    <div (click)="appService.verif=false" class="col-12">
                        <img src="/assets/images/gear-option.svg" />
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="milkCollectionCenter.unreadAlert" [ngClass]="appService.rtl()? 'dot' : 'dot-fr'">
            <span class="center-element number-style mt-2">{{milkCollectionCenter.unreadAlert>=100 ? '+99' :
                milkCollectionCenter.unreadAlert}}</span>
        </div>
    </div>
</div>