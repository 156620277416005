import { Component, Input, OnInit } from '@angular/core';
import { CustomRouter } from '../../services/custom-router';

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss']
})
export class BarComponent implements OnInit {
@Input() element:any; 

  constructor(public router:CustomRouter) { }

  ngOnInit(): void {
  }
  back(url){
    this.router.navigateByUrl(url)
  }
}
