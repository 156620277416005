import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerifiedComponent } from './verified.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [VerifiedComponent],

  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
  ],

  exports: [
    VerifiedComponent
  ]

})

export class VerifiedModule { }
