import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CodeTranslatePipe } from 'projects/shared/pipes/code-translate.pipe';
import { AppService } from 'projects/shared/services/app.service';

@Component({
  selector: 'app-common-setting',
  templateUrl: './common-setting.component.html',
  styleUrls: ['./common-setting.component.scss']
})
export class CommonSettingComponent implements OnInit {
  @Input() element: any
  @Output() routerUrl: EventEmitter<any> = new EventEmitter();
  @Input() editAuthorized

  constructor(public appService: AppService,  public codeTranslate: CodeTranslatePipe) {
      }

  ngOnInit() {
    
  }

  navigateRoute() {
    this.routerUrl.emit()
  }

}
