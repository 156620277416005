<div class="row">
    <div class="col mt-5 pt-5 mb-4">
        <img class="img-fluid mx-auto d-block check" [src]="urlImg" />
    </div>
</div>



<div class="row">
    <div id="title" class="col text-center text-style" [ngClass]="{rtl:appService.rtl()}">
        <span>{{ title }}</span>
    </div>
</div>



