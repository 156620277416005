import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AnimalNoteService } from 'projects/shared/services/animal-note.service';
import { AnimalService } from 'projects/shared/services/animal.service';
import { AppService } from 'projects/shared/services/app.service';
import { Alert, Type, FORMAT_MAX_MIN, NUMERIC_PATTERN_RECALL, MAX_DAY_RECALL, MIN_DAY_RECALL } from 'projects/shared/tools/constants';
import { CustomRouter } from '../../services/custom-router';
import { AnimalNote } from 'projects/shared/resources/AnimalNote';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { CustomerService } from 'projects/shared/services/customer.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-edit-note',
  templateUrl: './edit-note.component.html',
  styleUrls: ['./edit-note.component.scss']
})
export class EditNoteComponent implements OnInit {

  uid: any
  code: any
  colorGroup: any
  id: any
  formGroup: UntypedFormGroup;
  animal: any
  noteId: any
  animalNote: AnimalNote = new AnimalNote()
  customerId: any
  text: any = 'NEW_NOTE'
  editable: boolean = true;
  typeWeight = Type.WEIGHT
  maxDate: any;
  role: any;
  idFarmer: any;
  farmer: any;
  minDate: any;
  auth: string[] = [];
  formatArabe: any
  currentRoute: any;
  isPopUp: boolean = false;
  alertId
  numericPattern = NUMERIC_PATTERN_RECALL
  typeHeight = Type.HEIGHT
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: UntypedFormBuilder, public tokenManagementService: TokenManagementService, public appService: AppService, public router: CustomRouter, private route: ActivatedRoute,
    public animalService: AnimalService, public animalNoteService: AnimalNoteService,
    private translate: TranslateService, private alertsService: AlertsService, public customerService: CustomerService) {
    this.currentRoute = this.router.url;
    if (this.currentRoute.indexOf('reports') != -1)
      this.isPopUp = true;
    else this.isPopUp = false
    this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');
    this.appService.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1)
      this.role = true;
    else
      this.role = false


    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.id && this.isPopUp)
      this.id = this.data.animal.id;
    this.noteId = this.route.snapshot.paramMap.get('idNote');
    if (this.noteId) {
      this.text = 'CHANG_NOTE'
    }
    this.createForm();


  }

  ngOnInit() {
    this.formatArabe = this.appService.verifFormatDate()
    if (this.noteId)
      this.findNote()
    this.findAnimal()
    this.appService.searchText = "serach_note"

    this.maxDate = moment().format(FORMAT_MAX_MIN);

  }


  ngOnDestroy() {
    if (this.role) {
      this.appService.searchText = 'search_by_uid_name_phone'
    } else
      this.appService.searchText = "search_by_uid"
  }

  message(msg) {
    this.appService.hideLoading();
    let message = this.translate.instant(msg);
    let alert = this.translate.instant(Alert.OK)
    this.alertsService.open(message, alert);
  }
  findNote() {
    this.animalNoteService.findByIdNote(parseInt(this.noteId)).subscribe(res => {
      this.animalNote = res
      this.formGroup.controls['note'].setValue(this.animalNote.type == Type.WEIGHT ? this.translate.instant(this.animalNote.note) : this.animalNote.note)
      this.formGroup.controls['noteDetail'].setValue(this.animalNote.noteDetail)
      this.formGroup.controls['actionDate'].setValue(this.animalNote.actionDate)
      this.formGroup.controls['type'].setValue(this.animalNote.type)
      if (this.animalNote.others != null) {
        this.formGroup.controls['count'].setValue(this.animalNote.others['countDay'])
        this.alertId = this.animalNote.others['alertId']
        this.formGroup.controls['count'].enable()
        this.formGroup.controls['isCheck'].setValue('true')
      }
      if (this.animalNote.type == this.typeWeight) {
        this.editable = false
      }
    })
  }

  createForm() {

    this.formGroup = this.formBuilder.group({
      /* 'actionDate': [this.animalNote ? moment(this.animalNote.actionDate).toDate(): null],
        'noteDetail': [this.animalNote ? this.animalNote.noteDetail : null],
        'note': [this.animalNote ? (this.animalNote.note ? : null) : null],
        'type': [ this.animalNote ?  (this.animalNote.type ? this.animalNote.type  : Type.ADD_NOTE) : Type.ADD_NOTE]*/
      'actionDate': [null, [Validators.required]],
      'noteDetail': [null, [Validators.required]],
      'note': [null, [Validators.required]],
      'type': [Type.ADD_NOTE, [Validators.required]],
      'count': [null, [Validators.min(MIN_DAY_RECALL), Validators.max(MAX_DAY_RECALL)]],
      'isCheck': [null],
    });
    this.formGroup.controls['count'].disable();
  }
  showOptions(event) {
    if (event.checked)
      this.formGroup.controls['count'].enable();
    else
      this.formGroup.controls['count'].disable();
  }

  back() {
    this.appService.verif = false
    this.router.back('')
  }

  findAnimal() {
    this.fixArabeFormat()
    this.animalService.getById(this.id).subscribe(res => {
      this.animal = res;
      // this.minDate=new Date((res.birthDate).format())
      this.minDate = moment(res.birthDate).format(FORMAT_MAX_MIN)
      if (!res.birthDate)
        this.minDate = moment("2000, 1, 1").format(FORMAT_MAX_MIN)
    })
  }

  verifPoids() {

    if (this.formGroup.controls['noteDetail'].value > 2500) {
      this.formGroup.controls['noteDetail'].setValue(2500)
    }
    else if (this.formGroup.controls['noteDetail'].value < 0) {
      this.formGroup.controls['noteDetail'].setValue(0)
    }


  }
  verifHeight() {


    if (this.formGroup.controls['noteDetail'].value > 250) {
      this.formGroup.controls['noteDetail'].setValue(250)
    }
    else if (this.formGroup.controls['noteDetail'].value < 0) {
      this.formGroup.controls['noteDetail'].setValue(0)
    }

  }
  
  fixArabeFormat() {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD/MMM/YYYY"
  }

  confirm() {
    if (this.animal.customerAnimals.length > 0 && this.animal.customerAnimals.length > 0 && this.animal.customerAnimals[0].customer.id == this.appService.connectedCustomer.id)
      this.message('DISABLED_ANIMAL')
    else {
      if (this.formGroup.controls['count'].hasError('min'))
        this.appService.error(this.translate.instant('MIN_RECALL') + " "+0)
      else if (this.formGroup.controls['count'].hasError('max'))
        this.appService.error(this.translate.instant('MAX_RECALL') + " "+366)
      else if (!this.formGroup.valid) {
        // if (this.formGroup.controls['actionDate'].hasError('required') || this.formGroup.controls['noteDetail'].hasError('required'))
        this.message('FIELD_REQUIRED')

      } else {
        let message: string;
        this.appService.showLoading();
        if (this.noteId) {

          let link = this.animalNote['_links'].self.href
          if (this.animalNote.type == "WEIGHT") {
            this.animalNote = this.formGroup.value;
            this.animalNote.type = "WEIGHT"
          }
          else
            if (this.formGroup.value.isCheck == false)
              this.formGroup.value.count = null
          this.animalNote = this.formGroup.value;
          this.animalNote['_links'] = { self: { href: link } }
        }
        else {

          this.animalNote = this.formGroup.value;
          this.animalNote.actionDate = (moment(this.formGroup.controls['actionDate'].value).toISOString()).substring(0, 10);
          if (this.animal)
            this.animalNote.animal = this.animal._links.self.href
          else
            this.animalNote.animal = this.data.animal._links.self.href;
        }
        if (this.role || this.currentRoute.startsWith('/supervisors')) {
          this.idFarmer = this.route.snapshot.paramMap.get('idFarmer');
          if (!this.idFarmer && this.isPopUp)
            this.idFarmer = this.data.customerId
          this.animalNote.customer = location.origin + "/api/farmers/" + this.idFarmer
        }
        else {
          this.animalNote.customer = this.appService.connectedCustomer['link'].href;
        }
        if (this.formGroup.controls['isCheck'].value?.toString() == "true") {
          if (this.formGroup.controls['count'].value != null)
            this.animalNote.others = { "countDay": + this.formGroup.controls['count'].value }
          if (this.alertId != null)
            this.animalNote.others = { "countDay": + this.formGroup.controls['count'].value, "alertId": +this.alertId }
        }

        if (this.animalNote)
          this.animalNoteService.save(this.animalNote).subscribe(
            data => {
              this.appService.hideLoading();
              message = this.translate.instant('SAVE_SUCESS');
              this.alertsService.open(message);

              if (this.noteId)
                this.back()
              else {
                this.formGroup.controls['note'].setValue(null);
                this.formGroup.controls['noteDetail'].setValue(null);
                this.formGroup.controls['isCheck'].setValue(false);
                this.formGroup.controls['count'].setValue(null);
              }
              this.router.back('')

            }
            , error => {
              this.appService.hideLoading();
              message = this.translate.instant(error.error.code);
              let alert = this.translate.instant(Alert.OK)
              this.alertsService.open(message, alert);
            })
      }
    }

  }
}
