import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { ReportService } from 'projects/shared/services/report.service';
import { Alert } from 'projects/shared/tools/constants';
import { AlertsService } from 'projects/shared/services/alerts.service';
import 'chartjs-adapter-moment';
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';
import { CapacitorUtils } from 'projects/shared/tools/CapacitorUtils';
@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit {


  displaygraph = false;
  lineChart: Chart;
  dayTime: String[];
  dayAccX1: Number[];
  dayAccY1: any[] = []
  dayAccZ1: Number[];
  status: Number[] = [];
  @Input() formGroup
  @Input() tempGraph
  startDate;
  endDate;
  code;
  periodValue
  title: any;
  statusPeriod: any;
  datasets: any[] = [];

  temp: Number[];
  humidity: Number[];
  thi: Number[];
  farmerId: any;


  tankDeviceAgitatorCount;
  tankDeviceFridgeCount;
  size: number = 0
  step: number = 0
  max: any;
  maxTemp: number = 80
  constructor(public appService: AppService, public reportService: ReportService, private alertsService: AlertsService, public translate: TranslateService) {
    Chart.register(
      ArcElement,
      LineElement,
      BarElement,
      PointElement,
      BarController,
      BubbleController,
      DoughnutController,
      LineController,
      PieController,
      PolarAreaController,
      RadarController,
      ScatterController,
      CategoryScale,
      LinearScale,
      LogarithmicScale,
      RadialLinearScale,
      TimeScale,
      TimeSeriesScale,
      Decimation,
      Filler,
      Legend,
      Title,
      Tooltip,
      SubTitle
    );
  }

  ngOnInit() {

  }

  updateChart(point) {
    this.lineChart.options.elements.point.radius = point.checked ? 0 : 2;
    if (!point.checked) {
      this.lineChart.options.elements.point.hitRadius = 5
      this.lineChart.options.elements.point.hoverRadius = 10
      this.lineChart.options.elements.point.hoverBorderWidth = 2
    }
    this.lineChart.update();
  }

  apply() {
    if (this.lineChart) {
      this.lineChart.destroy();
      this.displaygraph = false;
    }
    this.periodValue = this.formGroup.controls['period'].value
    this.startDate = this.formGroup.controls['startDate'].value;
    this.endDate = this.formGroup.controls['endDate'].value;
    this.code = this.formGroup.controls['code'].value;
    this.appService.showLoading();

    this.setPeriod();

    this.reportService.displayGraph(this.code.uuid, this.code.deviceId, this.startDate, this.endDate).subscribe(res => {
      this.dayTime = [];
      this.dayAccX1 = [];
      this.dayAccY1 = [];
      this.dayAccZ1 = [];
      this.statusPeriod = {};
      this.datasets = [];
      let keys = Object.keys(res['displayGraphs']);
      keys.forEach(key => {
        [res['displayGraphs'][key]].forEach(element => {
          this.dayAccX1.unshift(element['x1'])
          this.dayAccY1.unshift(element['y1'])
          this.dayAccZ1.unshift(element['z1'])
          this.dayTime.unshift(moment(element['date']).format("YYYY/MM/DD HH:mm:ss"));

          /*res['displayStatus'].forEach(ele => {
            if (element['receptionDate'] >= ele.startDateTime && element['receptionDate'] < ele.endDateTime)
              element["status"] = ele.status
            if (!this.statusPeriod[ele.status])
              this.statusPeriod[ele.status] = []
          })
          let keys = Object.keys(this.statusPeriod)
          keys.forEach(key => {
            if (element["status"] == key)
              this.statusPeriod[key].push(1600);
            else
              this.statusPeriod[key].push(0);
          })*/

        })
      });
      this.datasets.push({
        label: 'AccX1 [mg]',
        data: this.dayAccX1,
        fill: false,
        tension: 0.2,
        borderColor: "blue",
        backgroundColor: "blue",
        borderWidth: 1,
        yAxisID: 'X1'
      },
        {
          label: 'AccY1 [mg]',
          data: this.dayAccY1,
          fill: false,
          tension: 0.2,
          borderColor: "red",
          backgroundColor: "red",
          borderWidth: 1,
          yAxisID: 'Y1'
        },
        {
          label: 'AccZ1 [mg]',
          data: this.dayAccZ1,
          fill: false,
          tension: 0.2,
          borderColor: "green",
          backgroundColor: "green",
          borderWidth: 1,
          yAxisID: 'Z1'
        })

      /*let keyStatus = Object.keys(this.statusPeriod)
      keyStatus.forEach(key => {
        let ranDomColor = randomColor({
          luminosity: 'dark',
          format: 'rgba',
          alpha: 0.3,
        });
        this.datasets.push(
          {
            label: key,
            data: this.statusPeriod[key],
            fill: true,
            backgroundColor: ranDomColor,

            tension: 0.2,
            borderColor: ranDomColor,
            borderWidth: 1,
            yAxisID: 'status'
          }
        )
      });*/

      this.displaygraph = true;
      this.appService.hideLoading();

      this.lineChart = new Chart('lineChart', {
        type: 'line',
        data: {
          labels: this.dayTime,
          datasets: this.datasets
        },
        options: {
          plugins: {
            title: {
              text: this.title,
              display: true
            },
          },
          elements:
          {
            point:
            {
              radius: 0,
            }
          },

          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'hour',
                displayFormats: {
                  'hour': 'MM/DD HH:mm',
                }
              }
            },
            X1: {
              type: 'linear',
              position: 'left',
              max: 1500,
              min: -300,
              beginAtZero: true,
              ticks: {
                color: "blue",
                stepSize: 100,
                callback: function (value) {
                  if (value > -300 && value < 300) return value;
                }
              },
            },
            Y1: {
              type: 'linear',
              position: 'right',
              max: 900,
              min: -900,
              beginAtZero: true,
              ticks: {
                color: "red",
                stepSize: 100,
                callback: function (value) {
                  if (value > -300 && value < 300) return value;
                }
              }
            },
            Z1: {
              type: 'linear',
              position: 'right',
              max: 300,
              min: -1500,
              beginAtZero: true,
              ticks: {
                color: "green",
                stepSize: 100,
                callback: function (value) {
                  if (value > -300 && value < 300) return value;
                }
              }
            }/*,
						{
							id: 'status',
							type: 'linear',
							display: false,
							ticks: {

								beginAtZero: true,

							}
						}*/

          }
        }
      })
    }, error => {

      this.appService.hideLoading();
      let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.code);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
      this.alertsService.open(message, alert);
    })
  }

  setPeriod() {
    if (this.periodValue != null) {
      let start

      /*if (this.periodValue == "3_HOURS")
        start = moment().subtract(3, 'hours');

      if (this.periodValue== "6_HOURS")
        start = moment().subtract(6, 'hours');*/

      if (this.periodValue == "1_DAY")
        start = moment().subtract(1, 'days');

      if (this.periodValue == "3_DAYS")
        start = moment().subtract(3, 'days');

      if (this.periodValue == "1_WEEK")
        start = moment().subtract(1, 'weeks');

      if (this.periodValue == "1_MONTH")
        start = moment().subtract(1, 'months');

      /*if (this.formGroup.controls['period'].value == "3_MONTHS")
        start = moment().subtract(3, 'months');*/

      this.title = start.format("MM/DD/YYYY HH:mm:ss") + " -- "
        + moment().format("MM/DD/YYYY HH:mm:ss");

      this.startDate = start.toISOString();
      this.endDate = moment().toISOString();
    } else {

      this.title = moment(this.startDate).format("MM/DD/YYYY") + " 00H00" + " -- "
        + moment(this.endDate).format("MM/DD/YYYY") + " 23H59";
      /* this.startDate = moment(this.startDate).toISOString();
       this.endDate = moment(this.endDate).add(24, 'hours').toISOString();*/
      this.startDate = moment(new Date(this.formGroup.controls['startDate'].value)).set({ minute: 0, second: 0, millisecond: 0 }).toISOString();
      this.endDate = moment(this.formGroup.controls['endDate'].value).toISOString();
    }
  }


    exportCsv() {
      this.appService.showLoading();
      /*let uuid = this.formGroup.controls['code'].value.uuid;
      let deviceId = this.formGroup.controls['code'].value.deviceId;*/
      this.setPeriod();

      this.reportService.exportToCsv(this.code.uuid, this.code.deviceId, this.startDate, this.endDate).subscribe(data => {
        let blob = new Blob([data], { type: 'text/csv; charset=utf-8' }); 
        let type=".csv"
        let filename = this.translate.instant('GRAPHS')+ moment(new Date()).format('yyyy-MM-DD HH:mm') 
        if (CapacitorUtils.isApp){
          CapacitorUtils.writeAndOpenFile(blob, filename+type);
          this.appService.hideLoading();
          let message = this.translate.instant('SAVE_FILE_SUCESS');
          this.alertsService.open(message);}
        else{
          this.appService.saveAs(blob, filename, type);
        this.appService.hideLoading();}
      }, error => {
        this.appService.hideLoading();
        let message = this.translate.instant(error.error.code);
        let alert = this.translate.instant(Alert.OK)
        this.alertsService.open(message, alert);
      })
      /*this.reportService.exportToCsv(this.code.uuid, this.code.deviceId, this.startDate, this.endDate).subscribe(res => {
        this.appService.hideLoading();
        FileSaver.saveAs(res, "info.csv")
      }, error => {
        this.appService.hideLoading();
        let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.code);
        let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
        this.alertsService.open(message, alert);
      });*/
    }
  

  applyThi(tagIds) {
    if (this.lineChart) {
      this.lineChart.destroy();
      this.displaygraph = false;
    }
    this.periodValue = this.formGroup.controls['period'].value
    this.startDate = this.formGroup.controls['startDate'].value;
    this.endDate = this.formGroup.controls['endDate'].value;
    if (this.formGroup.controls['farmer'].value)
      this.farmerId = this.formGroup.controls['farmer'].value.id;
    this.appService.showLoading();

    this.setPeriodThi();
    this.reportService.displayThiGraph(tagIds, this.farmerId, this.startDate, this.endDate).subscribe((res: any) => {
      this.dayTime = [];
      this.temp = [];
      this.humidity = [];
      this.thi = [];

      this.datasets = [];
      res.forEach(element => {
        this.temp.push(element['temp'])
        this.humidity.push(element['humidity'])
        this.thi.push(element['thi'])
        this.dayTime.push(moment(element['date']).format("YYYY/MM/DD HH:mm:ss"));
      });

      this.datasets.push({
        label: this.translate.instant('TEMPERATURE'),
        data: this.temp,
        fill: false,
        tension: 0.2,
        borderColor: "red",
        backgroundColor: "red",
        borderWidth: 1,
        yAxisID: 'X1'
      },
        {
          type: 'line',
          label: this.translate.instant('HUMIDITY'),
          data: this.humidity,
          fill: false,
          tension: 0.2,
          borderColor: "blue",
          backgroundColor: "blue",
          borderWidth: 1,
          yAxisID: 'Y1'
        },
        {
          type: 'line',
          label: this.translate.instant('THI'),
          data: this.thi,
          fill: false,
          tension: 0.2,
          borderColor: "green",
          backgroundColor: "green",
          borderWidth: 1,
          yAxisID: 'Z1'
        })

      this.displaygraph = true;
      this.appService.hideLoading();

      Chart.register({
        id: 'p1', beforeDraw: function (chartInstance, easing) {
          var lineOpts = {
            lineY: [72, 72],
            lineColor: "#306a90",
            // text: 'Obj 67 & 68',
            //textPosition: 720,
            //textFont: '18px sans-serif',
            // textColor: "#306a90"
          };
          if (lineOpts) {
            var yAxis = chartInstance.scales["Z1"];
            var yValueStart = yAxis.getPixelForValue(lineOpts.lineY[0], 0);
            var yValueEnd = yAxis.getPixelForValue(lineOpts.lineY[1], 0);

            var xAxis = chartInstance.scales["x"];
            var xValueStart = xAxis.getPixelForTick(0);
            //var xValueEnd = xAxis.getPixelForTick(xAxis.ticks.length-1) + 15/*xAxis.right*/;
            var xValueEnd = xAxis.right;

            /*  console.log('xValueEnd', xValueEnd);
              console.log('xAxis.ticks.length', xAxis.ticks.length);
              console.log(xAxis.getPixelForTick(xAxis.ticks.length - 1));*/

            var ctx = chartInstance.ctx;
            ctx.save();

            // le texte
            // ctx.font = lineOpts.textFont;
            // ctx.fillStyle = lineOpts.textColor;
            //ctx.fillText(lineOpts.text, lineOpts.textPosition, yValueStart + 18);

            // la ligne en pointillés

            ctx.setLineDash([10, 10]);
            ctx.strokeStyle = lineOpts.lineColor;
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(xValueStart, yValueStart);
            ctx.lineTo(xValueEnd, yValueEnd);
            ctx.stroke();

            ctx.restore();
          }
        }
      });

      this.lineChart = new Chart('lineChart', {
        type: 'line',

        data: {
          labels: this.dayTime,
          datasets: this.datasets
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
            title: {
              text: this.title,
              display: true
            },
          },

          elements:
          {
            point:
            {
              radius: 0,
            }
          },

          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'hour',
                displayFormats: {
                  'hour': 'MM/DD HH:mm',
                }
              }
            },
            X1: {
              type: 'linear',
              position: 'left',
              max: 270,
              min: -30,
              beginAtZero: true,
              ticks: {
                color: "red",
                stepSize: 10,
                callback: function (value) {
                  if (value > -20 && value < 60) return value;
                }
              },
            },
            Y1: {
              type: 'linear',
              position: 'right',
              max: 200,
              min: -100,
              beginAtZero: true,
              ticks: {
                color: "blue",
                stepSize: 10,
                callback: function (value) {
                  if (value > 20 && value < 100) return value;
                }
              }
            },
            Z1: {
              type: 'linear',
              position: 'right',
              max: 100,
              min: -200,
              beginAtZero: true,
              ticks: {
                color: "green",
                stepSize: 10,
                callback: function (value) {
                  if (value > 20 && value < 100) return value;
                }
              }
            }
            ,
          }
        }

      })

    }, error => {

      this.appService.hideLoading();
      let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.code);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
      this.alertsService.open(message, alert);
    })
  }
  setPeriodThi() {
    if (this.formGroup.controls['period'].value != null) {
      let start

      /*if (this.formGroup.controls['period'].value == "3_HOURS")
        start = moment().subtract(3, 'hours');

      if (this.formGroup.controls['period'].value == "6_HOURS")
        start = moment().subtract(6, 'hours');*/

      if (this.formGroup.controls['period'].value == "1_DAY")
        start = moment().subtract(1, 'days')

      if (this.formGroup.controls['period'].value == "3_DAYS")
        start = moment().subtract(3, 'days');

      if (this.formGroup.controls['period'].value == "1_WEEK")
        start = moment().subtract(1, 'weeks');

      if (this.formGroup.controls['period'].value == "1_MONTH")
        start = moment().subtract(1, 'months');

      /*if (this.formGroup.controls['period'].value == "3_MONTHS")
        start = moment().subtract(3, 'months');*/

      this.title = start.format("MM/DD/YYYY HH") + ":00 " + " -- "
        + moment().format("MM/DD/YYYY HH") + ":00";
      this.startDate = moment(start)

      this.startDate.set({ minute: 0, second: 0, millisecond: 0 })
      this.startDate = this.startDate.toISOString();
      this.endDate = moment().toISOString()

    } else {
      this.title = moment(this.formGroup.controls['startDate'].value).format("MM/DD/YYYY") + " 00H00" + " -- "
        + moment(this.formGroup.controls['endDate'].value).format("MM/DD/YYYY") + " 23H59";
      this.startDate = moment(new Date(this.formGroup.controls['startDate'].value)).set({ minute: 0, second: 0, millisecond: 0 }).toISOString();
      this.endDate = moment(this.formGroup.controls['endDate'].value).toISOString();

    }
  }

  exportThiCsv(tagIds) {
    this.appService.showLoading();
    this.setPeriodThi();
    if(this.formGroup.controls['farmer'].value)
    this.farmerId = this.formGroup.controls['farmer'].value.id;
    let type=".csv"

    this.reportService.exportThiToCsv(tagIds, this.farmerId, this.startDate, this.endDate).subscribe(res => {
      let blob = new Blob([res], { type: 'text/csv; charset=utf-8' }); 
      let filename = this.translate.instant('THI_GRAPH')  + moment(new Date()).format('yyyy-MM-DD HH:mm') 
      if (CapacitorUtils.isApp){
        CapacitorUtils.writeAndOpenFile(blob, filename+type );this.appService.hideLoading();
        let message = this.translate.instant('SAVE_FILE_SUCESS');
        this.alertsService.open(message);}
      else{
      this.appService.saveAs(blob, filename, type);
      this.appService.hideLoading();}
    }, error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.code);
      let alert = this.translate.instant(Alert.OK)
      this.alertsService.open(message, alert);
    });
  }

  exportTempGraphCsv() {
    this.appService.showLoading();
    this.setPeriodThi();
    let tankId = this.formGroup.controls['code'].value.id;
    let type=".csv"
    this.reportService.exportTempGraphToCsv(tankId, this.startDate, this.endDate).subscribe(res => {
      let blob = new Blob([res], { type: 'text/csv; charset=utf-8'}); 
      let filename = this.translate.instant('TEMP_GRAPH')  + moment(new Date()).format('yyyy-MM-DD HH:mm') 
      if (CapacitorUtils.isApp){
        CapacitorUtils.writeAndOpenFile(blob, filename+type);
        this.appService.hideLoading();
        let message = this.translate.instant('SAVE_FILE_SUCESS');
        this.alertsService.open(message);}
      else{
      this.appService.saveAs(blob, filename, type);
            this.appService.hideLoading();}
    }, error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.code);
      let alert = this.translate.instant(Alert.OK)
      this.alertsService.open(message, alert);
    });
  }



  applyTempMov() {

    if (this.lineChart) {
      this.lineChart.destroy();
      this.displaygraph = false;
    }
    this.displaygraph = true;
    let tankId = this.formGroup.controls['code'].value.id;


    this.setPeriodThi();

    this.reportService.getTempGraph(tankId, this.startDate, this.endDate).subscribe(res => {
      this.dayTime = [];
      this.dayAccX1 = [];
      this.datasets = [];

      let keys = Object(res);

      let dayAccY = [];
      let dayAccZ = [];
      this.tankDeviceAgitatorCount = this.formGroup.controls['code'].value.deviceAgitatorCount
      this.tankDeviceFridgeCount = this.formGroup.controls['code'].value.deviceFridgeCount

      for (let i = 1; i <= this.tankDeviceAgitatorCount; i++) {
        dayAccY[i] = []
      }


      for (let i = 1; i <= this.tankDeviceFridgeCount; i++) {
        dayAccZ[i] = []
      }

      keys.forEach(element => {
        this.dayAccX1.push(element.temperature)
        this.dayTime.push(moment(element.date).format('YYYY/MM/DD HH:mm:ss'))

        element.eventAgis.forEach(ele => {
          Object.keys(ele).forEach(key => {
            dayAccY[key].push(ele[key])
          })
        });

        element.eventFris.forEach(ele => {
          Object.keys(ele).forEach(key => {
            dayAccZ[key].push(ele[key])
          })
        });

      });
      this.datasets.push({
        label: this.translate.instant('TEMPERATURE'),
        data: this.dayAccX1,
        fill: false,
        borderColor: "blue",
        backgroundColor: "blue",
        borderWidth: 1,
        yAxisID: 'X1'
      })

      let on = "ON"
      let off = "OFF"
      this.max = (this.tankDeviceAgitatorCount + this.tankDeviceFridgeCount) * 5
      let options: any = {
        plugins: {
          title: {
            text: this.title,
            display: true
          },
        },
        elements:
        {
          point:
          {
            radius: 0,
          }
        },

        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'minute',
              displayFormats: {
                'minute': 'MM/DD HH:mm',
              }
            }
          },
          X1: {
            type: 'linear',
            position: 'left',
            max: this.maxTemp,
            min: -this.maxTemp,
            beginAtZero: false,
            ticks: {
              color: "blue",
              stepSize: 10,
              callback: function (value) {
                if (value >= 0 && value < 81) return value;
              },

            },
          }
        }
      }
      for (let i = 1; i <= dayAccY.length - 1; i++) {
        let labelY = this.tankDeviceAgitatorCount > 1 ? i.toString() : ""

        this.datasets.push({
          label: this.translate.instant('EVENT_AGI') + " " + i,
          data: dayAccY[i],
          fill: false,
          borderColor: "red",
          backgroundColor: "red ",
          borderWidth: 1,
          yAxisID: 'Y' + i
        })
        this.max = this.max - i
        //options.scales.yAxes.push({
        options.scales['Y' + i] =
        {
          type: 'linear',
          position: 'right',
          min: this.max - (this.tankDeviceAgitatorCount + this.tankDeviceFridgeCount) * 5,
          max: this.max,
          beginAtZero: false,
          ticks: {
            color: "red",
            stepSize: 0.5,
            callback: function (value) {
              if (value > -1 && value < 2 && value == 0) return off + "   " + labelY;
              if (value > -1 && value < 2 && value == 1) return on;

            }
          }
        }
      }
      for (let i = 1; i <= dayAccZ.length - 1; i++) {
        let labelZ = this.tankDeviceFridgeCount > 1 ? i.toString() : "";

        this.datasets.push({
          label: this.translate.instant('EVENT_FRI') + " " + i,
          data: dayAccZ[i],
          fill: false,
          borderColor: "green",
          backgroundColor: "green",
          borderWidth: 1,
          yAxisID: 'Z' + i

        })
        this.max = this.max - 2

        options.scales['Z' + i] =
        {
          type: 'linear',
          position: 'left',
          ticks: {
            min: this.max - (this.tankDeviceAgitatorCount + this.tankDeviceFridgeCount) * 5,
            max: this.max,
            fontColor: "green",
            beginAtZero: false,
            stepSize: 1,
            callback: function (value) {
              if (value > -1 && value < 2 && value == 0) return labelZ + "   " + off;
              if (value > -1 && value < 2 && value == 1) return on
            },

          }
        }
      }

      this.displaygraph = true;
      this.appService.hideLoading();

      this.size = 0
      this.step = 0
      this.lineChart = new Chart('lineChart', {
        type: 'line',
        data: {
          labels: this.dayTime,
          datasets: this.datasets
        },
        options: options
      })
    }, error => {
      this.appService.hideLoading();
      let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : error.error.code);
      let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
      this.alertsService.open(message, alert);
    })
  }

  zoomChart(step, size) {
    this.size = this.size + size
    this.step = this.step + step
    //this.lineChart.options.scales.yAxes = []
    this.max = (this.tankDeviceAgitatorCount + this.tankDeviceFridgeCount) * 5
    this.createTempYAxes()
    this.createAgitatorYAxes()
    this.createFridgeYAxes()
    this.lineChart.update();
  }

  createTempYAxes() {
    //this.lineChart.options.scales.yAxes.push(
    this.lineChart.options.scales.X1 =
    {
      type: 'linear',
      position: 'left',
      max: (this.maxTemp + this.size),
      min: -(this.maxTemp + this.size),
      beginAtZero: false,
      ticks: {
        color: "blue",
        stepSize: (8 - this.step),
        callback: function (value) {
          if (value >= 0 && value < 81) return value;
        },

      },
    }
  }

  createAgitatorYAxes() {
    let on = "ON"
    let off = "OFF"
    for (let i = 1; i <= this.tankDeviceAgitatorCount; i++) {
      let labelY = this.tankDeviceAgitatorCount > 1 ? i.toString() : ""

      this.max = this.max - i

      //this.lineChart.options.scales.yAxes.push({
      this.lineChart.options.scales['Y' + i] =
      {
        type: 'linear',
        position: 'right',
        min: this.max - (this.tankDeviceAgitatorCount + this.tankDeviceFridgeCount) * 5,
        max: this.max,
        beginAtZero: false,
        ticks: {
          color: "red",
          stepSize: 1,
          callback: function (value) {
            if (value > -1 && value < 2 && value == 0) return off + "   " + labelY;
            if (value > -1 && value < 2 && value == 1) return on;
          }
        }

      }

    }
  }
  createFridgeYAxes() {
    let on = "ON"
    let off = "OFF"
    for (let i = 1; i <= this.tankDeviceFridgeCount; i++) {
      let labelZ = this.tankDeviceFridgeCount > 1 ? i.toString() : "";

      this.max = this.max - 2
      // this.lineChart.options.scales.push({
      this.lineChart.options.scales['Z' + i] =
      {
        type: 'linear',
        position: 'left',
        min: this.max - (this.tankDeviceAgitatorCount + this.tankDeviceFridgeCount) * 5,
        max: this.max,
        beginAtZero: false,
        ticks: {
          color: "green",
          stepSize: 1,
          callback: function (value) {
            if (value > -1 && value < 2 && value == 0) return labelZ + "   " + off;
            if (value > -1 && value < 2 && value == 1) return on
          }

        }

      }
    }
  }
 

}
