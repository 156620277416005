import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecaptchaComponent } from './recaptcha.component';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [RecaptchaComponent],
  imports: [
    CommonModule,
    RecaptchaV3Module,
    TranslateModule
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: "6LfZ7VgiAAAAADgu5mRban-woI0kWnOXyIuL-Fqn",
    },
 
  ],
})
export class RecaptchadModule { }
