import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Form } from '../resources/Form';
import { ConfigService } from './config.service';
import { MoomeRestService } from './moome-rest.service';
import state from "../../shared/tools/state"
import { flatMap, map, of, tap, take } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FormService extends MoomeRestService<Form> {

  constructor(public http: HttpClient, injector: Injector, private configService: ConfigService) {
    super(Form);
  }


  saveForm(body){
    return this.http.post(this.configService.getApiPath() + `/forms/save`, body)
    .pipe(tap((formSavedDto: Form) => {
      body.id = formSavedDto['formSaved']['id']
      let formData = state.formData || {};
      let list: Array<any> = formData[body.formTemplate.id] || []
      let list1 = list
      if(formSavedDto['formDeleted'])
      list1 = list.filter(element => element.id != formSavedDto['formDeleted']['id']/*|| element.uuid != body.uuid*/)
      let forms = list1.filter(element => element.id != body.id/*|| element.uuid != body.uuid*/)
      forms.push(body)
      
      let formSaved = state.formData || {}
      formSaved[body.formTemplate.id] = forms
      state.formData = formSaved;
      /*let formsToSave = state.formDataToSave || {}
      let formToSave = formsToSave[body.id ? body.id : body.uuid] || {}
      formToSave = body
      let formToSaved = state.formDataToSave || {}
      formToSaved[body.id ? body.id : body.uuid] = formToSave
      state.formDataToSave = formToSaved*/
    }),
    /*catchError( (err: any, caught: Observable<any>) => { return err } )*/ );
  }

  deleteById(body) {
    return this.http.delete(this.configService.getApiPath() + `/forms/${body.id}`).pipe(tap((form: Form) => {
      let formData = state.formData || {};
      let list: Array<any> = formData[body.formTemplate.id] || []
      let forms = list.filter(element => element.id != body.id /*|| element.uuid != form.uuid*/)
      let formSaved = state.formData || {}
      formSaved[body.formTemplate.id] = forms
      state.formData = formSaved;
  
     /* let formsToDel = state.formDataToDel || {}
      let formToDel = formsToDel[form.id ? form.id : form.uuid] || {}
      formToDel = form
      let formToDeleted = state.formDataToDel || {}
      formToDeleted[form.id ? form.id : form.uuid] = formToDel
      state.formsToDel = formToDeleted*/
    }))
  }

  findByFarmerAndFormTemplate(idFarmer, idFormTemplate, projection) {
    
    let form = (state.formData || {})[idFormTemplate];
    if (form) return of(form)
    return this.customRequestGet(`/find?idFarmer=${idFarmer}&idFormTemplate=${idFormTemplate}&projection=${projection}`)
    .pipe(
      take(1),
      map(formData => {
        
        let forms = state.formData || {}
        forms[idFormTemplate] = formData['_embedded']?.forms
        state.formData = forms;
        return formData['_embedded']?.forms
      }))
  }

  getFormById(idForm, idFormTemplate, idFarmer, projection){

    if(!idForm) return of({})
    let formData = state.formData || {};
    let forms = formData[idFormTemplate] || []
    let result = forms.find(f => f.id == idForm)
    if(result) return of(result)
    return this.findByFarmerAndFormTemplate(idFarmer, idFormTemplate, projection)
      .pipe(flatMap(res => this.getFormById(idForm, idFormTemplate, idFarmer, projection)))

  }

  findFormByKeySearch( idFormTemplate , idFarmer, key) {
    return this.search(`findFormByKeySearch?idFormTemplate=${idFormTemplate}&idFarmer=${idFarmer}&key=${key.toLowerCase()}&projection=form-projection`)
  }


  /*getList(query) {
    return this.http.get(this.configService.getApiPath() + `/forms/getList?query=${query}`)
  }*/

}
