import { Component, OnInit } from '@angular/core';
import { CustomRouter } from '../services/custom-router';
import { AppService } from 'projects/shared/services/app.service';
import { GroupService } from 'projects/shared/services/group.service';
import { Country, Group } from 'projects/shared/tools/constants';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { UntypedFormBuilder, UntypedFormGroup,Validators } from '@angular/forms';
import * as moment from 'moment';
import { FarmerFeedConsumptionService } from 'projects/shared/services/farmer-feed-consumption.service';

@Component({
  selector: 'app-farm-eco',
  templateUrl: './farm-eco.component.html',
  styleUrls: ['./farm-eco.component.scss']
})
export class FarmEcoComponent implements OnInit {
  list: any[] = []
  bar: any
  greenCard: any
  selectedGroups: any[];
  listGroups
  labelAr: boolean = false
  labelEn: boolean = false
  labelFr: boolean = false
  labelInd: boolean = false
  formatArabe: any;
  unitMoney
  formGroup1: UntypedFormGroup;
  formGroup2: UntypedFormGroup;
  formGroup: UntypedFormGroup;
  startDateFarm
  endDateFarm
  startDateGroup
  endDateGroup
  milkProductionCosts
  feedCosts
  otherCosts
  feedCostPerLiter
  dailyFeedCost
  unit
  startDateFinancial
  endDateFinancial
  milk
  feed
  otherCost
  otherIncome
  totalCost:number
  totalIncome:number
  netIncomes
  displayCode:boolean=false;
  constructor(public farmerFeedConsumptionService:FarmerFeedConsumptionService,private formBuilder: UntypedFormBuilder,public tokenManagementService: TokenManagementService, public router: CustomRouter, public appService: AppService, public groupService: GroupService) {
    
    this.unitMoney = this.appService.connectedCustomer.addresses[0].country.currency
    if (Country.isPakIdn(this.appService.connectedCustomer.addresses[0].country.code))
      this.displayCode = true;
    else
      this.displayCode = false;
    }

  ngOnInit() {
    this.unit = this.appService.connectedCustomer.addresses[0].country.currency
    
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
    this.labelAr = true
  else if (this.tokenManagementService.getTranslateLanguage() == "fr")
    this.labelFr = true
    else if (this.tokenManagementService.getTranslateLanguage() == "ind")
    this.labelInd = true
  else
    this.labelEn = true
    this.getGroups()
    this.formatArabe = this.appService.verifFormatDate()
    this.createForm()
    this.list = [
      { value1: "", value2: "", label: "COST_DAY_COW", label1:Group.isFeedGroupValue(this.formGroup2.controls['group'].value), label2: this.unitMoney,label3:"LITRE", class: "blue-cercle", value1Font: "farm-eco-value1-font", value2Font: "farm-eco-value1-font", labelFont: "farm-eco-blue-label-font" },
      { value1: "", value2: "", label: "COST_LITER_COW", label1:Group.isFeedGroupValue(this.formGroup2.controls['group'].value), label2: this.unitMoney,label3:"DAY", class: "blue-cercle", value1Font: "farm-eco-value1-font", value2Font: "farm-eco-value1-font", labelFont: "farm-eco-blue-label-font" },
    ]
    this.bar = { title: "FARM_ECONOMICS", arrowIcon: "/assets/images/left-arrow.svg", color: "#f6f7fb", fontColor: "#316a90", router: "/dashboard" }
    this.greenCard = { value1: "", value2: "", label: "PROD_COST_LITER", label1: "",label2: this.unitMoney,label3:"LITRE", class: "blue-cercle", value1Font: "farm-eco-value1-font", value2Font: "farm-eco-value1-font", labelFont: "farm-eco-blue-label-font" }
  
  }
  back() {
    this.router.navigateByUrl('/dashboard')
  }
  getGroups() {
    this.groupService.findAvailableGroupOfCustomer().subscribe((res: any) => {
      const groupCodesSet = new Set<string>();
      this.selectedGroups = res['_embedded']?.groups?.filter(group => {
        const isFeedGroup = Group.isFeedGroup(group.code);
        // Vérifier si le groupe est un groupe d'alimentation et s'il n'est pas déjà présent dans l'ensemble des codes de groupe
        if (isFeedGroup && !groupCodesSet.has(group.code)) {
          groupCodesSet.add(group.code); // Ajouter le code du groupe à l'ensemble
          return true; // Garder le groupe dans la liste filtrée
        }
        return false; // Ignorer le groupe s'il n'est pas un groupe d'alimentation ou s'il est déjà présent
      });
    });
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      'startDateFinancial': [null, [Validators.required]],
      'endDateFinancial': [null, [Validators.required]],
    });

    this.formGroup1 = this.formBuilder.group({
      'startDateFarm': [null, [Validators.required]],
      'endDateFarm': [null, [Validators.required]],
    });

    this.formGroup2 = this.formBuilder.group({
      'group':[null, [Validators.required]],
      'startDateGroup': [null, [Validators.required]],
      'endDateGroup': [null, [Validators.required]]
    });
    
  }
  
  fixArabeFormat() {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      this.formatArabe = "DD/MMM/YYYY"
  }

  countByDateFarm(){
    
    if (this.formGroup1.controls['startDateFarm'].value && this.formGroup1.controls['endDateFarm'].value) {
      this.startDateFarm = moment(this.formGroup1.controls['startDateFarm'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format('yyyy-MM-DD')
    this.endDateFarm = moment(this.formGroup1.controls['endDateFarm'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format('yyyy-MM-DD')
      const date1=(new Date(this.startDateFarm)).getTime();
        const date2=(new Date(this.endDateFarm)).getTime();
        if(date1>date2)
        this.appService.error('ERROR_DATE_FIELD');
      else {
      this.farmerFeedConsumptionService.countByDateFarmDetails(this.startDateFarm, this.endDateFarm).subscribe(data => {
       
      this.milkProductionCosts=data["milkProductionCosts"]? data["milkProductionCosts"]:'--';
       this.feedCosts=data["feedCosts"] ? data["feedCosts"] :'--';
       this.otherCosts=data["otherCosts"] ? data["otherCosts"]:'--';
      
       
        if (this.milkProductionCosts) {
          this.list = [
            { value1: this.feedCostPerLiter, value2: "", label: "COST_DAY_COW", label1:Group.isFeedGroupValue(this.formGroup2.controls['group'].value), label2: this.unitMoney,label3:"LITRE", class: "blue-cercle", value1Font: "farm-eco-value1-font", value2Font: "farm-eco-value1-font", labelFont: "farm-eco-blue-label-font" },
            { value1:this.dailyFeedCost, value2: "", label: "COST_LITER_COW", label1:Group.isFeedGroupValue(this.formGroup2.controls['group'].value), label2: this.unitMoney,label3:"DAY", class: "blue-cercle", value1Font: "farm-eco-value1-font", value2Font: "farm-eco-value1-font", labelFont: "farm-eco-blue-label-font" },
          ]
          this.bar = { title: "FARM_ECONOMICS", arrowIcon: "/assets/images/left-arrow.svg", color: "#f6f7fb", fontColor: "#316a90", router: "/dashboard" }
          this.greenCard = { value1: this.displayCode ? this.formatNumber(this.milkProductionCosts):this.milkProductionCosts, value2: "", label: "PROD_COST_LITER", label1: "",label2: this.unitMoney,label3:"LITRE", class: "blue-cercle", value1Font: "farm-eco-value1-font", value2Font: "farm-eco-value1-font", labelFont: "farm-eco-blue-label-font" }}
         else
          this.appService.error('HEALTH_REQUIRED');
         })

    }}
  }
  countByDateAndGroup(){
    this.listGroups = []
    this.listGroups.push(this.formGroup2.controls['group'].value )
    if (this.formGroup2.controls['startDateGroup'].value && this.formGroup2.controls['endDateGroup'].value) {
      this.startDateGroup = moment(this.formGroup2.controls['startDateGroup'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format('yyyy-MM-DD')
    this.endDateGroup = moment(this.formGroup2.controls['endDateGroup'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format('yyyy-MM-DD')
      const date1=(new Date(this.startDateGroup)).getTime();
        const date2=(new Date(this.endDateGroup)).getTime();
        if(date1>date2)
        this.appService.error('ERROR_DATE_FIELD');
      else {
      this.farmerFeedConsumptionService.countByDateAndGroupDetails(this.startDateGroup, this.endDateGroup, this.listGroups).subscribe(data => {
      this.feedCostPerLiter=data["feedCostPerLiter"] ? data["feedCostPerLiter"]:'--';
      this.dailyFeedCost=data["dailyFeedCost"] ? data["dailyFeedCost"]:'--';
       
        if ( this.feedCostPerLiter || this.dailyFeedCost ) {
          this.list = [
            { value1:this.displayCode ? this.formatNumber( this.feedCostPerLiter): this.feedCostPerLiter, value2: "", label: "COST_DAY_COW", label1:Group.isFeedGroupValue(this.formGroup2.controls['group'].value), label2: this.unitMoney,label3:"LITRE", class: "blue-cercle", value1Font: "farm-eco-value1-font", value2Font: "farm-eco-value1-font", labelFont: "farm-eco-blue-label-font" },
            { value1: this.displayCode ? this.formatNumber( this.dailyFeedCost):this.dailyFeedCost, value2: "", label: "COST_LITER_COW", label1:Group.isFeedGroupValue(this.formGroup2.controls['group'].value), label2: this.unitMoney,label3:"DAY", class: "blue-cercle", value1Font: "farm-eco-value1-font", value2Font: "farm-eco-value1-font", labelFont: "farm-eco-blue-label-font" },
          ]
          this.bar = { title: "FARM_ECONOMICS", arrowIcon: "/assets/images/left-arrow.svg", color: "#f6f7fb", fontColor: "#316a90", router: "/dashboard" }
          this.greenCard = { value1:  this.displayCode ? this.formatNumber(this.milkProductionCosts):this.milkProductionCosts, value2: "", label: "PROD_COST_LITER", label1: "",label2: this.unitMoney,label3:"LITRE", class: "blue-cercle", value1Font: "farm-eco-value1-font", value2Font: "farm-eco-value1-font", labelFont: "farm-eco-blue-label-font" }}
         else
          this.appService.error('HEALTH_REQUIRED');
         })

    }}
  }
  calculFinancial(){
    if (this.formGroup.controls['startDateFinancial'].value && this.formGroup.controls['endDateFinancial'].value) {
      this.startDateFinancial = moment(this.formGroup.controls['startDateFinancial'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format('yyyy-MM-DD')
    this.endDateFinancial = moment(this.formGroup.controls['endDateFinancial'].value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format('yyyy-MM-DD')
      const date1=(new Date(this.startDateFinancial)).getTime();
        const date2=(new Date(this.endDateFinancial)).getTime();
        if(date1>date2)
        this.appService.error('ERROR_DATE_FIELD');
      else {
      this.farmerFeedConsumptionService.countFinancialDetails(this.startDateFinancial, this.endDateFinancial).subscribe(data => {
        
       this.milk=data["milks"]? data["milks"]:0;
       this.feed=data["feeds"] ? data["feeds"] :0;
       this.otherCost=data["otherCosts"] ? data["otherCosts"]:0;
       this.otherIncome=data["otherIncomes"] ? data["otherIncomes"]:0;
       this.totalCost=Number(this.feed)+Number(this.otherCost);
       this.totalIncome=Number(this.milk)+Number(this.otherIncome);
       this.netIncomes= this.totalIncome-this.totalCost;
       if(this.displayCode){
        this.milk=this.formatNumber( this.milk);
        this.feed=this.formatNumber( this.feed);
       this.otherCost=this.formatNumber( this.otherCost);
       this.otherIncome=this.formatNumber( this.otherIncome);
       this.totalCost = (parseFloat(this.feed.replace(/,/g, '')))+(parseFloat(this.otherCost.replace(/,/g, '')))
       this.totalIncome =(parseFloat(this.milk.replace(/,/g, '')))+(parseFloat(this.otherIncome.replace(/,/g, '')));
       this.netIncomes =this.totalIncome-this.totalCost;
      }
      })

    }}
  }
  
  formatNumber(num: number| string): string {
    if (num === '--') {
      return '--';
  }

  let numStr = num.toString().replace(/,/g, '.');
  return Number(numStr).toLocaleString('en', { maximumFractionDigits: 0 });

  }
}
