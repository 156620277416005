<div class="dialog-size-mob">
    <div class="row">
        <div class="col mb-3">
            <div *ngIf="showAdd"
                [ngClass]="appService.rtl()? 'text-center title-style-dialog-ar' : 'text-center title-style-dialog-fr'">
                <span [ngClass]="{rtl:appService.rtl()}">
                    {{ 'ADD' | translate}}/{{ 'EDIT' | translate}}&nbsp;{{ title | translate}}</span>
            </div>
            <div *ngIf="!showAdd"
                [ngClass]="appService.rtl()? 'text-center title-style-dialog-ar' : 'text-center title-style-dialog-fr'">
                <span [ngClass]="{rtl:appService.rtl()}">
                    {{ 'EDIT' | translate}}&nbsp;{{ title | translate}}</span>
            </div>
        </div>
    </div>

    <form [formGroup]="formGroup">
        <div class="row" [ngClass]="{rtl:appService.rtl()}" *ngIf="showAdd">
            <div class="col mb-2" [ngClass]="appService.rtl()? 'text-style' : 'title-style-dialog-fr'"
                [ngClass]="formGroup.value.isCheck ? '' : 'opacity'">
                <mat-checkbox formControlName="isCheck" (change)="onChange($event)" [ngClass]="{rtl:appService.rtl()}"><span class="ms-1 me-1 "
                    [ngClass]="appService.rtl()? 'text-style' : 'text-style-fr'" >{{ labelCheck | translate}}</span>
                </mat-checkbox>
            </div>
        </div>

        <span [ngStyle]="{'display':formGroup.value.isCheck ? 'block' : 'none'}">
            <div class="row" [ngClass]="{rtl:appService.rtl()}">
                <div class="col-12 mb-3">
                    <ion-item class="input-mob-date input-height input-width">
                        <ion-datetime #datePicker mode="ios" (ionChange)="fixArabeFormat()" [ngStyle]="{'font-size':appService.rtl()? '25px' : '20px'}"
                            [ngClass]="{rtl:appService.rtl()}" [pickerFormat]="appService.verifFormatDate()" [displayFormat]="formatArabe"
                            formControlName="date" placeholder="{{ 'CHOOSE' | translate}}&nbsp;{{ 'DATE' | translate}}"
                            [min]="minDate"[max]="maxDate" [doneText]="'SAVE' |translate" [monthShortNames]="appService.monthShort()"
                            [cancelText]="'CANCEL' |translate">
                        </ion-datetime>
                        <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar' : 'date'"
                            src="/assets/images/calendar4.svg" />
                    </ion-item>
                </div>
            </div>

            <span [ngStyle]="{'display':(isIa)? 'block' : 'none'}">
                <div class="row" [ngClass]="{rtl:appService.rtl()}">
                    <div class="col mb-2" [ngClass]="appService.rtl()? 'text-style' : 'title-style-dialog-fr'">
                        <mat-radio-group formControlName="type_insemination" (change)="onChangeIA($event)">
                            <mat-radio-button value="ARTIFICIAL_INSEMINATION" class="mb-2"><span
                                    class="ms-1 me-1  op-in" [ngClass]="{rtl:appService.rtl()}">{{
                                    'ARTIFICIAL_INSEMINATION' | translate}}</span>
                            </mat-radio-button>
                            <mat-radio-button value="NATURAL_INSEMINATION"><span class="ms-1 me-1 op-in"
                                    [ngClass]="{rtl:appService.rtl()}">{{ 'NATURAL_INSEMINATION' | translate}}</span>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>


                <div class="row" *ngIf="formGroup.controls['type_insemination'].value == 'NATURAL_INSEMINATION'">
                    <div class="col m-2 " >
                            <input  class=" input-mob input-height input-width  input-search-mob op"  placeholder="{{appService.valueUid == nationalIdentification ? ('UID'| translate) : (appService.valueUid == nameLocalCode? ('COWS_NAME'| translate) : ('UID'| translate))}}" (keyup)="onKeyAnimals($event.target.value)"
                            [ngStyle]="{'font-size':appService.rtl()? '25px' : '20px'}" id="father"
                            formControlName="father" [ngClass]="{rtl:appService.rtl()}" [matAutocomplete]="auto"
                           (input)="verifLengthInsemFather()" >
                            <mat-autocomplete #auto="matAutocomplete"  >
                                <mat-option *ngFor="let animal of selectedAnimals" [value]="appService.valueUid == nationalIdentification? (animal.uid? animal.uid : animal.name) : (appService.valueUid == nameLocalCode? (animal.name? animal.name: animal.uid) : (animal.uid? animal.uid : animal.name))" >
                                    {{appService.valueUid == nationalIdentification? (animal.uid? animal.uid : animal.name) : (appService.valueUid == nameLocalCode? (animal.name? animal.name: animal.uid) : (animal.uid? animal.uid : animal.name))}}
                                </mat-option>
                            </mat-autocomplete>
                            
                    </div>
                </div>

                <!-- <div class="row" *ngIf="formGroup.controls['type_insemination'].value == 'NATURAL_INSEMINATION'">
                    <div class="col m-2 ">
                        <mat-select class="input-mob input-height input-width op" placeholder="{{ 'UID'| translate }}"
                            [ngStyle]="{'font-size':appService.rtl()? '25px' : '20px'}"
                            [ngClass]="{rtl:appService.rtl()}" formControlName="father">
                            <input class="input-search-mob" (keyup)="onKeyAnimals($event.target.value)"
                                [ngClass]="{rtl:appService.rtl()}">
                            <mat-option *ngFor="let animal of selectedAnimals" [value]="animal.id">
                                {{animal.uid}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>-->
                <div class="row" *ngIf="formGroup.controls['type_insemination'].value == 'ARTIFICIAL_INSEMINATION'">
                    <div class="col mt-2 mb-2" [ngClass]="{rtl:appService.rtl()}"
                        [ngClass]="appService.rtl()? 'text-style' : 'title-style-dialog-fr'">
                        <input class="input-mob input-height input-width" type="text" [ngClass]="{rtl:appService.rtl()}"
                            placeholder="{{ 'REFERENCE' | translate}}" matInput formControlName="insemination_ref" id="insemination_ref"
                            [ngStyle]="{'font-size':appService.rtl()? '25px' : '20px'}" (input)="verifLengthInsemRef()">
                    </div>
                </div>
                
            <!--</span>
            <span [ngStyle]="{'display':(isControl)? 'block' : 'none'}">-->
                <div class="row" [ngClass]="{rtl:appService.rtl()}">
                    <div class="col mb-3" [ngClass]="appService.rtl()? 'text-style' : 'title-style-dialog-fr'">
                        <mat-checkbox formControlName="gestationControl" (change)="onChangeG($event)">
                            <span class="ms-1 me-1 text-style" [ngClass]="{rtl:appService.rtl()}">{{ 'GESTATION_CONROL' |
                                translate}}</span>
                        </mat-checkbox>
                    </div>
                </div>
                <div class="row gx-2" [ngClass]="{rtl:appService.rtl()}">
                    <div class="col-6 mt-1 mb-4">
                        <button (click)="iaStatus(controlResult.POSITIVE, $event)" class="button"
                            [disabled]='disabledButtonPositive'
                            [ngStyle]="{'backgroundColor': (formGroup.controls['controlResult'].value == controlResult.POSITIVE && disabledButtonPositive == false )? '#80cc28' : (disabledButtonPositive == true) ? 'rgba(0, 0, 0, 0.12)' : 'white',
                        'color': (formGroup.controls['controlResult'].value == controlResult.POSITIVE && disabledButtonPositive == false )? 'white' : 'rgba(32, 65, 87, 0.26)'}"><span
                                style="font-size:25px" [ngClass]="{rtl:appService.rtl()}">{{ 'POSITIVE' |
                                translate}}</span></button>
                    </div>
                    <div class="col-6 mt-1 mb-4"><button (click)="iaStatus(controlResult.NEGATIVE, $event)" class="button"
                            [disabled]='disabledButtonNegative'
                            [ngStyle]="{'backgroundColor': (formGroup.controls['controlResult'].value == controlResult.NEGATIVE && disabledButtonNegative == false) ? '#FC4242' : (disabledButtonPositive == true) ? 'rgba(0, 0, 0, 0.12)' : 'white',
                        'color': (formGroup.controls['controlResult'].value == controlResult.NEGATIVE && disabledButtonNegative == false)? 'white' : 'rgba(32, 65, 87, 0.26)'}"><span
                                style="font-size:25px" [ngClass]="{rtl:appService.rtl()}">{{ 'NEGATIVE' |
                                translate}}</span></button>
                    </div>
                </div>
            </span>
            <span [ngStyle]="{'display':isStart ? 'block' : 'none'}">
                <div class="row" [ngClass]="{rtl:appService.rtl()}" [createAuthorized]="authorityAnimals"
                    [editAuthorized]="authorityAnimals">
                    <div class="col mb-2" [ngClass]="appService.rtl()? 'text-style' : 'title-style-dialog-fr'">
                        <mat-checkbox formControlName="addCow"><span class="ms-1 me-1" (change)="isAddCow($event)"
                                [ngClass]="{rtl:appService.rtl()}">{{ 'ADD_COW' | translate}}</span>
                        </mat-checkbox>
                    </div>
                </div>
                <div [ngStyle]="{'display':formGroup.value.addCow ? 'block' : 'none'}">
                    <div formArrayName="childs"
                        [ngClass]="formGroup.get('childs')['controls'].length > 1 ? 'make-scrollable mb-4' : 'mb-4'">
                        <div *ngFor="let child of formGroup.get('childs')['controls']; let i=index">
                            <div [formGroupName]="i">

                                <mat-card class="mb-2" style="background-color: rgba(164, 164, 164,0.1);">
                                    <div class="row" [ngClass]="{rtl:appService.rtl()}">
                                        <div class="col">
                                            <div class="">
                                                <div>
                                                    <i class="fas float-end icon fa-plus-circle ms-1 me-1"
                                                        (click)="addChilds()" [createAuthorized]="authorityAnimals"
                                                        matTooltip="{{ 'ADD_ANIMAL' | translate }}"></i>
                                                    <i class="fas float-start icon fa-trash ms-1 me-1"
                                                        (click)="removeChilds(i, child.value)"
                                                        [deleteAuthorized]="authorityTimelineChilds"
                                                        [deleteAuthorized]="authorityAnimals"
                                                        matTooltip="{{ 'DELETE_ANIMAL' | translate }}"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row gx-0" *ngIf="code!='MAR'">


                                        <div class="col-6 mb-1 mt-1 input-width-uid1 center-element back-style">
                                            <span class="text-style">{{suffix}}</span>
                                        </div>
                                        <div class="col-6 mb-3 text-center  mt-2 ps-0 pe-0">
                                            <input matInput
                                                class=" input-width-uid2 text-center font-size-text input-backg-diff"
                                                [id]="child.controls['nameUid'].value" name="uid" placeholder="{{ 'NUM_COW'| translate }}"
                                                [type]="!valueTypeUid ? 'tel' : 'text'"  [ngClass]="{rtl:appService.rtl()}"
                                                formControlName="uidIni"
                                                >
                                        </div>
                                    </div>

                                    <div class="row gx-0" *ngIf="code=='MAR'">

                                        <div class="col-6 mb-3 mt-2 center-element back-style-marroc">
                                            <span class="text-style">{{suffix}}</span>
                                        </div>
                                        <div class="col-6 mb-3 text-center  mt-2 ps-0 pe-0">
                                            <div class="row gx-0">

                                                <input matInput [ngStyle]="{'padding-top':appService.rtl() ? '10px' : '0px' }"
                                                    class="mt-0 text-center  font-size-text-marroc-fr input-backg-Mar text-center col-3"
                                                    [id]="child.controls['nameUid1'].value"  name="uid1" type="text" [ngClass]="{rtl:appService.rtl()}"
                                                    formControlName="uid1Ini">
                                                <span
                                                    class="col-3 text-style center-element text-center back-style-B">B</span>
                                                <input matInput [ngStyle]="{'padding-top':appService.rtl() ? '10px' : '0px' }"
                                                    class=" uidMaroc text-center font-size-text-marroc-fr text-center input-backg width"
                                                    [id]="child.controls['nameUid2'].value" name="uid2" placeholder="{{ 'NUM_COW'| translate }}"
                                                    type="text" [ngClass]="{rtl:appService.rtl()}"
                                                    formControlName="uid2Ini">
                                            </div>
                                        </div>

                                    </div>



                                    <div class="row gx-0" >
                                      
                                        <div class="col-12 mb-3 text-center  mt-2 ps-0 pe-0">
                                            <input class="input-mob input-height input-width op" [ngClass]="{rtl:appService.rtl()}"
                                                placeholder="{{ 'COWS_NAME'| translate }}"  [id]="child.controls['nameCow'].value" type="text"  formControlName="name">
                                        </div>
                                    </div>


                                    <!--div class="row">
                                        <div class="col mt-1 mb-1 text-style">
                                            <input class="input-mob input-height input-width op" type="number" min="1"
                                                [ngClass]="{rtl:appService.rtl()}" placeholder="{{ 'CODE' | translate}}"
                                                formControlName="code" matInput>
                                            <mat-error
                                                *ngIf="child.controls['code'].hasError('required') && !child.controls['code'].hasError('min')">
                                                {{ 'FIELD_REQUIRED' | translate }}
                                            </mat-error>
                                            <mat-error
                                                *ngIf="!child.controls['code'].hasError('required') && child.controls['code'].hasError('min')">
                                                {{ 'MIN_1' | translate }}
                                            </mat-error>
                                        </div>
                                    </div-->
                                    <!--div class="row">
                                        <div class="col mt-1 mb-1">
                                            <mat-select class="input-mob input-height input-width op"
                                                formControlName="species" placeholder="{{ 'SPECIES'| translate }}"
                                                [ngClass]="{rtl:appService.rtl()}">
                                                <input class="input-search-mob"
                                                    (keyup)="onKeySpecies($event.target.value)"
                                                    [ngClass]="{rtl:appService.rtl()}">
                                                <mat-option *ngFor="let species of selectedSpecies" [value]="species.id"
                                                    (click)="setValueSpecies(species,child)">{{species | codeTranslate}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="!child.controls['species'].valid && child.controls['species'].touched">
                                                {{ 'FIELD_REQUIRED' | translate }}
                                            </mat-error>
                                        </div>
                                    </div-->
                                    <div class="row" [getAuthorized]="authorityBreeds"
                                        [getAuthorized]="authoritySpecies">

                                        <div class="col mt-1 mb-1">
                                            <mat-select class="input-mob input-height input-width op" disableOptionCentering panelClass="myPanelClass"
                                                placeholder="{{ 'BREED'| translate }}" formControlName="breedId"
                                                [required]="formGroup.controls['addCow'].value == true"
                                                [ngClass]="{rtl:appService.rtl()}">
                                                <input class="input-search-mob"
                                                    [ngStyle]="{'font-size':appService.rtl()? '25px' : '20px'}"
                                                    [ngClass]="{rtl:appService.rtl()}"
                                                    (keydown)="$event.stopPropagation()"(keyup)="onKeyBreed($event.target.value)">
                                                <mat-option *ngFor="let breed of selectedBreeds" [value]="breed.id">
                                                    <span [ngClass]="{rtl:appService.rtl()}">{{breed |
                                                        codeTranslate}}</span>
                                                </mat-option>
                                                <mat-error
                                                    *ngIf="!child.controls['breedId'].valid && child.controls['breedId'].touched">
                                                    {{ 'FIELD_REQUIRED' | translate }}
                                                </mat-error>
                                            </mat-select>
                                        </div>
                                    </div>
                                    <div class="row gx-1">
                                        <div class="col-6 mt-2 mb-1 text-center">
                                            <button class="sex-style male-style float-end"
                                                (click)="changeSex(MALE, child)"
                                                [ngClass]="child.controls['sexe'].value == 'MALE' ? 'bor-style ' : '' "><img
                                                    src="/assets/images/cow-m.svg"></button>
                                        </div>
                                        <div class="col-6 mt-2 mb-1"><button class="sex-style  female-style"
                                                (click)="changeSex(FEMALE,child)"
                                                [ngClass]="child.controls['sexe'].value == 'FEMALE' ? 'bor-style' : '' "><img
                                                    src="/assets/images/cow-f.svg"></button>
                                        </div>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </div>
            </span>
            <span [ngStyle]="{'display':isAbort ? 'block' : 'none'}">
                <div class="row" [ngClass]="{rtl:appService.rtl()}">
                    <div class="col mb-4" [ngClass]="appService.rtl()? 'text-style' : 'title-style-dialog-fr'">
                        <mat-checkbox formControlName="counterLact"><span class="ms-1 me-1"
                                [ngClass]="{rtl:appService.rtl()}">{{ 'RESET_LACT_NUM' | translate}}</span>
                        </mat-checkbox>
                    </div>
                </div>
            </span>
        </span>
    </form>
    <div class="row">
        <div class="col">
            <div class="float-end"> <button [ngClass]="{rtl:appService.rtl()}" mat-raised-button color="warn"
                    style="margin-right: 5px" class="button-dialog   text-center" mat-dialog-close> <span
                        [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'" class="center-element">{{
                        'CANCEL' | translate }} </span></button>
                <button [ngClass]="{rtl:appService.rtl()}" class="button-dialog button-text-size text-center"
                    mat-raised-button color="primary" (click)="confirm()"><span
                        [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'" class="center-element">{{
                        'CONFIRM' | translate }} </span></button>
            </div>
        </div>
    </div>
</div>