import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SectionBarModule } from '../animals/section-bar/section-bar.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FeedbackComponent } from './feedback.component';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [FeedbackComponent],
  imports: [
    CommonModule,
    SectionBarModule,
    TranslateModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
 
})
export class FeedbackModule { }

