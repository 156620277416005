import { Injectable, Injector } from '@angular/core';
import { MoomeRestService, Options } from './moome-rest.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from "./config.service";
import { AlertTypeGroup } from "../resources/AlertTypeGroup";

@Injectable({
	providedIn: 'root'
})
export class AlertTypeGroupService extends MoomeRestService<AlertTypeGroup> {

	constructor(public http: HttpClient, injector: Injector, private configService: ConfigService) {
		super(AlertTypeGroup);
	}

	getAlertTypeGroupsWithFilter(page, size, field, direction, key) {
		
		let param: any=
        {projection: 'alertTypeGroups',
       }
        if (field != null)
            param.field=field;
        if (direction != null)
            param.direction= direction.toUpperCase();
			if (key != null)
            param.key=key.toLowerCase();

        let options: Options = {
            params: 
               param
            ,
			 pageParams:{
				page: page,
				size:size,
			 }
        }

		return this.searchPages(`getAlertTypeGroupsByKey`, options)
	}
	

	saveAlertTypeGroupWithAlertType(group){
		return this.customRequestPost("/save",{ body: group })
	}

	deleteAlertTypeGroup(id) {
		return this.http.delete(this.configService.getApiPath() + `/alertTypeGroups/${id}/delete`)
	}

	findAlertTypeGroupByRole(roleIds){
		  let value:any=[]
		      value.push(roleIds)
			let options: Options = {
				params: { 
				   projection: 'alertTypeGroups',
				   roleIds: value,
				}
			   }
		return this.search("findAlertTypeGroupByRole",options)
	}

	getByCode(code){
		let options: Options = {
			params: { 
				projection: 'alertTypeGroupByCode',
				code: code,
			 }
		}
	return this.searchSingle(`getAlertTypeGroupBycode`,options)
	}

	findAlertTypeGroups(){
	
		  let options: Options = {
			  params: { 
				 projection: 'alertTypeGroups',	 
			  }
			 }
	  return this.search("findAlertTypeGroups",options)
  }
}