import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertTypeCardComponent } from './alert-type-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';
import { AnimalCardModule } from '../../../animals/animal-card/animal-card.module';
import { AnimalCardComponent } from '../../../animals/animal-card/animal-card.component';
import { MaterialModule } from '../../../material/material.module';
import { AlertTypeAppelLaboModule } from '../alert-type-appel-labo/alert-type-appel-labo.module';
import { AlertTypeAppelLaboComponent } from '../alert-type-appel-labo/alert-type-appel-labo.component';




@NgModule({
    declarations: [AlertTypeCardComponent],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        MoomeCommonModule,
        AnimalCardModule,
        MaterialModule,
        AlertTypeAppelLaboModule
    ],
    exports: [
        AlertTypeCardComponent
    ]
})
export class AlertTypeCardModule { }
