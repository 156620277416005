<form [formGroup]="formGroup" *ngIf="formGroup">
    <div *ngFor="let field of formDefinition.formFields">
        <div class="row" *ngIf="field.multiple">
            <div [formArrayName]="field.code">
                <div *ngFor="let element of getControls(field.code); let i=index">
                    <div [formGroupName]="i">
                        <div class="col center-element">
                            <mat-card class="  m-2 style-card">
                                <div class="row " [ngClass]="{rtl:appService.rtl()}">
                                    <div class="col">
                                        <div>
                                            <i class="fas float-end icon fa-plus-circle ms-1 me-1"
                                                (click)="addfields(field)"
                                                matTooltip="{{ 'ADD_OPTION' | translate }}" matTooltipClass="custom-tooltip"></i>
                                            <i class="fas float-start icon fa-trash ms-1 me-1"
                                                (click)="removeFields(i, field)"
                                                matTooltip="{{ 'DELETE_OPTION' | translate }}" matTooltipClass="custom-tooltip"></i>
                                        </div>
                                    </div>
                                </div>
                                <app-common-field [field]="field" [code]="formGroup.get(field.code)">
                                </app-common-field>
                                <div *ngIf="field.type == type.COMPLEX">
                                    <div *ngFor="let subfield of field.formFields">

                                        <app-common-field [field]="subfield"
                                            [code]="element.get(subfield.code)">
                                        </app-common-field>
                                    </div>
                                </div>

                            </mat-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!field.multiple">
            <app-common-field [field]="field" [code]="formGroup.get(field.code)">
            </app-common-field>
            <div *ngIf="field.type == type.COMPLEX">
                <div *ngFor="let subfield of field.formFields">
                    <app-common-field [field]="subfield"
                        [code]="subfield.code">
                    </app-common-field>
                </div>
            </div>
        </div>
    </div>
</form>