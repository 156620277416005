import { Injectable } from '@angular/core';
import { Topic } from '../tools/constants';
import { RealtimeStreamService } from './realtime-stream.service';
import { ConfigService } from './config.service';
import * as qs from 'query-string';
import { TokenManagementService } from './token-management.service'
import { AppService } from './app.service';

@Injectable({
	providedIn: 'root',
})
export class RealtimeTagService extends RealtimeStreamService {

	constructor(protected configService: ConfigService, protected tokenManagementService: TokenManagementService, private appService: AppService) {
		super(Topic.TAG, configService, tokenManagementService);
	}

	onMessage(device, response) {
		let listStates = ['RESTING', 'EATING', 'DRINKING', 'RUMINATING', 'UNKNOWN', 'WALKING']
		var res: any = qs.parse(response.body);
		device.voltage = res.batteryLevel;
		device.active = res.active === "true";
		device.roundVoltage = Math.round(+res.batteryLevel);
		device.rssi = res.rssi;
		if(JSON.parse(res.data)['temp'] || JSON.parse(res.data)['temp']===0)
		device.temp = (JSON.parse(res.data)['temp']).toString()
		if(JSON.parse(res.data)['relativeHumidity'] || JSON.parse(res.data)['relativeHumidity']===0)
		device.relativeHumidity = (JSON.parse(res.data)['relativeHumidity']).toString()
		device.event = JSON.parse(res.data)['event']
		if(device.active === true)
		device.state = listStates[Math.floor(Math.random() * listStates.length)]
		this.appService.hideLoading()

	}
}
