import { HateoasResource, Resource } from "@lagoshny/ngx-hateoas-client";
import { Role } from "./Role";
@HateoasResource('subscriptionRequests')
export class SubscriptionRequest extends Resource {
    id: Number;
    role: Role;

  
    

  }