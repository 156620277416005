
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { FarmerStockFeedService } from 'projects/shared/services/farmer-stock-feed.service';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { CustomRouter } from '../../../services/custom-router';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-commun-history-card',
  templateUrl: './commun-history-card.component.html',
  styleUrls: ['./commun-history-card.component.scss']
})
export class CommunHistoryCardComponent implements OnInit {

  @Input() element: any
  @Output() reload = new EventEmitter();
  @Output() reloadState = new EventEmitter();
  @Input() authority: any
  @Input() service: any
  @Output() itemClick = new EventEmitter();
  unit: any;
  formTemlateId: any;
  formTemlateIdValue: any;
  showDate: boolean = false;
  @Input() section: any
  idFeed
  showReduce: boolean = false;
  dropdownVisible: boolean = false;
  @Output() load = new EventEmitter();
  @Output() routerBack = new EventEmitter();
  @Output() search = new EventEmitter();
  @Input() forms: any
  key: any
  constructor(public farmerStockFeedService: FarmerStockFeedService, public appService: AppService, public dialog: MatDialog, private router: CustomRouter, public route: ActivatedRoute,
    public alertsService: AlertsService, public translate: TranslateService) {
    const { idFormTemplate, idFeed } = this.route.snapshot.params
    this.formTemlateId = idFormTemplate;
    this.idFeed = idFeed
  }

  ngOnInit() {
   
    this.route.queryParams.subscribe(params => {
      this.key = params.key;
    })
    if (this.key)
      this.searchSettings()

  }
  getFormYears(form: any): string[] {
    return Object.keys(form);
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }

  

  back() {
    this.routerBack.emit()
  }

  action(item?) {
    this.itemClick.emit(item)
  }

  findForms() {
    this.load.emit();
  }

  searchSettings() {
    this.search.emit({ "key": this.key, /*"dataAdd": this.dataAdd*/ });
  }
  
  
  






}