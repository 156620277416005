<div class="row gx-1" (click)="appService.verif = false">
    <div class="col-6 mt-4 pt-4 " *ngFor="let element of list1" [getAuthorized]="element.authority">
        <div class="{{element.class}} text-center" >
            <!--div class="col mb-2 back center-element mx-auto {{element.back}}"
                (click)="router.navigateByUrl(element.router)"-->
                <div class="col mb-2 back center-element mx-auto {{element.back}}"
                >
                <div *ngIf="element.text=='ALERTS' && numberAlert != 0" class="dot">
                  <span class="center-element number-style mt-2">{{numberAlert>=100 ? '+99' : numberAlert}}</span> </div>
                    <!--span class="center-element number-style">{{numberAlert}}</span></div>-->
                <img [ngClass]="element.text=='ALERTS' && numberAlert != 0? 'position-icon position-icon-alert' : 'position-icon'"
                    src="{{element.img}}" (click)="navigate(element)">
            </div>
           
            <div [ngClass]="{rtl:appService.rtl()}">{{ element.text | translate }}</div>
        </div>
    </div>
</div>