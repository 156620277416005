
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { SubscriptionRequest } from '../resources/SubscriptionRequest';
import { ConfigService } from './config.service';
import { MoomeRestService, Options } from './moome-rest.service';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionRequestService extends MoomeRestService<SubscriptionRequest> {

    constructor(public http: HttpClient, injector: Injector, private configService: ConfigService) {
        super(SubscriptionRequest);
    }

    saveSubscription() {
        return this.customRequestPost(`/save`);
    }

    getSubscriptionsWithFilter(page, size, field, direction, key, projection) {
        let param: any =
        {
            projection: projection,
        }
        if (key != null)
            param.key = key.toLowerCase()
        if (field != null)
            param.field = field
        if (direction != null)
            param.direction = direction.toUpperCase()

        let options: Options = {
            params:
                param
            ,
            pageParams: {
                page: page,
                size: size,
            }
        }

        return this.customRequestGet(`/getSubscriptions`, null, options)
    }

    updateStatus(id, status) {
        return this.customRequestPost(`/update`, { body: { "id": id, "status": status } });
    }

    getLastSubscriptionExpired(){
        return this.customRequestGet(`/getLastSubscriptionExpired`)
    }
}

