import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
@Pipe({
    name: 'codeTranslate'
})
export class CodeTranslatePipe implements PipeTransform {

    constructor(private translate: TranslateService) { }

    transform(object: any, arg?): string {
        if(!arg)
        arg = 'label'
        let lang: string = this.translate.currentLang;
        let field = arg + lang.charAt(0).toUpperCase() + lang.slice(1);
        if(!object[field]){
        field = 'labelEn'
        }
        return object[field];
    }
}