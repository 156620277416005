import { Component, OnInit } from '@angular/core';
import { AppService } from 'projects/shared/services/app.service';
import { FarmerStockFeedService } from 'projects/shared/services/farmer-stock-feed.service';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { FormService } from 'projects/shared/services/form.service';
import { SupervisorService } from 'projects/shared/services/supervisor.service';
import { Authorities, SectionForm } from 'projects/shared/tools/constants';
import { CustomRouter } from '../../services/custom-router';
import { ActivatedRoute } from '@angular/router';
import { FarmerMiscInventoryService } from 'projects/shared/services/farmer-misc-inventory.service';
@Component({
  selector: 'app-history-misc-stock',
  templateUrl: './history-misc-stock.component.html',
  styleUrls: ['./history-misc-stock.component.scss']
})
export class HistoryMiscStockComponent implements OnInit {
  idFarmer
  idFormTemplate
  forms: any={}
  projection = "form-projection"
  name = "MISC_INVENTORY"
  section = SectionForm.farmer_stock
  service
  color='color-stock'
  constructor(public formTemplateService: FormTemplateService, public formService: FormService, public appService: AppService, public route: ActivatedRoute,
    private router: CustomRouter, private supervisorService: SupervisorService, private farmerMiscInventoryService: FarmerMiscInventoryService) {
    const { idFormTemplate } = this.route.snapshot.params
    this.idFormTemplate = idFormTemplate;
  }

  ngOnInit(): void {
    this.service = this.farmerMiscInventoryService
    this.idFarmer = this.appService.connectedCustomer.id
    let currentRoute = this.router.url;

    if (currentRoute.indexOf('key') == -1)
      this.getData()
  }

  getDataByKey(element) {

    this.farmerMiscInventoryService.findByFarmerByKey(element.key).subscribe(res => {
      this.forms=this.appService.groupDataByYearAndMonth(this.appService.organizedData(res, this.appService.dataAddMiscInventory));    
    }, error => {
      this.appService.error(error.error.error)
    });
  }

  getData() {

    this.farmerMiscInventoryService.findByFarmer().subscribe(res => {
      this.forms=this.appService.groupDataByYearAndMonth(this.appService.organizedData(res, this.appService.dataAddMiscInventory));     
    }, error => {
      this.appService.error(error.error.error)
    });
  }
  back() {
   
    this.router.navigateByUrl('/stock')
  }
  action(item) {
    if (!item)
      this.router.navigateByUrl('stock/miscInventory/' + this.idFormTemplate + '/save' + (item ? ("/" + (item.id ? item.id : item.uuid)) : ""))
    else
      this.router.navigateByUrl('settings/' + this.appService.formTemplateFormFarmerFeedConsumptionId + '/' + Authorities.FARMER_FEED_CONSUMPTION + '/' + (item.id) + '/historyMiscInventory/' + item.title);
  }
}