<app-animal-bar [id]="id" (goBack)="back()">
</app-animal-bar>


<form [formGroup]="groupForm">
    <div class="row margin-select">
        <div class="col center-element" >
            <mat-select class="input-mob input-border width-input " formControlName="project" disableOptionCentering panelClass="myPanelSettingLabelClass"
                 placeholder="{{ 'PROJECT'| translate }}" [ngClass]="{rtl:appService.rtl()}" >
                <input placeholder="{{ 'search'| translate }}" (keydown)="$event.stopPropagation()"
                    (keyup)="onKeyProject($event.target.value)" class="input-search-mob">
                <mat-option [value]=null [ngClass]="{rtl:appService.rtl()}"
                    [ngStyle]="{'text-align':(appService.rtl()? 'right' : 'left')}">{{'ANY_OPTION' | translate}}
                </mat-option>
                <mat-option *ngFor="let project of selectedProjects" [value]="project.id"
                    [ngStyle]="{'text-align':(appService.rtl()? 'right' : 'left')}"><span
                        [ngClass]="{rtl:appService.rtl()}">{{project | codeTranslate}}</span>
                </mat-option>
            </mat-select>
        </div>
    </div>

    <div class="row">
        <div class="col mb-5 mt-5 pt-5 text-center">
            <button mat-raised-button color="primary" class="button-mob" (click)="apply()"
                [ngClass]="{rtl:appService.rtl()}">{{ 'CONFIRM' | translate
                }}</button>
        </div>
    </div>

</form>
