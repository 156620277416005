import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'projects/shared/services/app.service';
import { CustomRouter } from '../../../services/custom-router';

@Component({
  selector: 'app-link-play-store-dialog',
  templateUrl: './link-play-store-dialog.component.html',
  styleUrls: ['./link-play-store-dialog.component.scss']
})
export class LinkPlayStoreDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LinkPlayStoreDialogComponent>, public dialog: MatDialog, public appService: AppService, public translate: TranslateService, public router: CustomRouter) { }

  ngOnInit(): void {
  }
  
  openLink(url: string) {
    window.open(url, "_blank");
    this.dialog.closeAll()

  }
  
}
