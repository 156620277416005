import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertTypeBarComponent } from './alert-type-bar.component';
import { RouterModule } from '@angular/router';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';



@NgModule({
  declarations: [AlertTypeBarComponent],
  imports: [
    CommonModule,
    RouterModule,
    MoomeCommonModule
  ],
  exports: [
    AlertTypeBarComponent
  ]
})
export class AlertTypeBarModule { }
