import { Injectable } from '@angular/core';
import { TokenManagementService } from './token-management.service';
import { Observable, of, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { GroupService } from './group.service';
import { Alert, LENGTH_WEIGHT, LivestockIdentif, Roles, Authorities, LANGUAGE, LIVESTOCK_IDENTIFICATION, NUMERIC_PATTERN, PHONE_PATTERN, FORMAT_MAX_MIN, SectionForm } from '../tools/constants';
import { AlertsService } from './alerts.service';
import { timer } from 'rxjs';
import { CodeTranslatePipe } from '../pipes/code-translate.pipe';
import { CountryService } from './country.service';
import { CustomerService } from './customer.service';
import { EntrepriseService } from './entreprise.service';
import { Badge } from '@capawesome/capacitor-badge';
import { AlertService } from './alert.service';

import { Platform } from '@ionic/angular';
import { RegionService } from './region.service';
import { CustomerSetting } from '../resources/CustomerSetting';
import { ConfigService } from './config.service';
import { CustomerSettingService } from './customer-setting.service';
import { FarmerService } from './farmer.service';
import { SupervisorService } from './supervisor.service';
import { CustomRouter } from 'projects/publicV2/src/app/services/custom-router';
import { Options } from './moome-rest.service';
import { environment } from 'projects/publicV2/src/environments/environment';
import * as FileSaver from 'file-saver';
import { FormTemplateService } from './form-template.service';
import { NavigationExtras } from '@angular/router';
import state from '../tools/state';
import * as moment from 'moment';
import { Validators } from '@angular/forms';
import { SubscriptionRequestHistoryService } from './subscription-request-history.service';
import { CommonDialogComponent } from 'projects/publicV2/src/app/common-dialog/common-dialog.component';
import { SubscriptionRequestService } from './subscription-request.service';
import { MatDialog } from '@angular/material/dialog';
import { ShopFormTemplateService } from './shop-form-template';
import { FarmerMilkProductionService } from './farmer-milk-production.service';
declare let gtag: Function;


@Injectable({
  providedIn: 'root'
})
export class AppService {
  isRtl: Boolean = true;
  connectedCustomer: any = null;
  connectedCustomerUpdated = new Subject();
  getDefaultGroupUpdated = new Subject();
  getFormTemplateForFarmerMilkProduction = new Subject();
  getFormTemplateForFarmerFeedConsumption = new Subject();
  updateLanguage = new Subject();
  subscribeTimer
  verif: Boolean = false
  menu: Boolean = false
  searchText: any
  defaultGroupId
  formTemplateFormFarmerMilkProduction
  formTemplateFormFarmerFeedConsumption
  formTemplateFormAnimalMilkProduction
  formTemplateFormFarmerMilkProductionId
  formTemplateFormFarmerFeedConsumptionId
  formTemplateFormFarmerEconomyId
  formTemplateFormAnimalMilkProductionId
  format: any;
  formatMaxDate: any;
  showResend: boolean = false
  timeLeft: number = 30;
  timer: number = 30;
  monthsShort: any
  auth: string[] = [];
  customers: any
  countries: any[];
  entreprises: any[];
  selectedCountries: any[] = [];
  selectedEntreprises: any[] = [];
  settingValue: any
  valueUid: any;
  selectedEntreprisesOptions: any;
  regions: any[];
  selectedRegions: any[] = [];
  customerSetting: CustomerSetting = new CustomerSetting();
  getSupervisors = [];
  service: any;
  typeWindow: boolean = false;
  public getScreenWidth: any;
  public getScreenHeight: any;
  advancedSearch: Boolean = false
  idFormTemplate
  dataAdd: any;
  show: Boolean = false;
  calvings: number = 0
  month: any
  valueDate: boolean = false
  valueNoDate: boolean = false
  dataAddFeedConsumption
  dataAddMilkProduction
  dataAddAnimalMilkProduction
  idFormTemplateStock
  idFormTemplateStockId
  dataAddStockFeed
  dataAddMiscInventory
  public listShop: any[] = [];
  idFeedInventory
  idMiscInventory
  sommeCrude = 0.0
  sommeDry = 0.0;
  inputValue: number;
  groupedData = {}
  sortedGroupedData = {}
  selectedLanguage = {
    name: '',
    flag: ''
  };
  public listId: any[] = [];
  public price;
  constructor(private farmerMilkProductionService:FarmerMilkProductionService,private shopFormTemplateService: ShopFormTemplateService, private formTemplateService: FormTemplateService, private router: CustomRouter, private configService: ConfigService, public customerSettingService: CustomerSettingService, private regionService: RegionService, private platform: Platform, public alertsService: AlertsService, private alertService: AlertService, public tokenManagementService: TokenManagementService, private translate: TranslateService, private groupService: GroupService,
    private codeTranslate: CodeTranslatePipe, public supervisorService: SupervisorService, public farmerService: FarmerService, private countryService: CountryService, private entrepriseService: EntrepriseService, public customerService: CustomerService,
    public subscriptionRequestHistoryService: SubscriptionRequestHistoryService, private subscriptionRequestService: SubscriptionRequestService, public dialog: MatDialog) {
      this.findMilkPrice()

  }
  checkService() {
    this.getSupervisors = [];
    this.service = this.customerService;
    this.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
      role['authorityRoles'].forEach(authorityRole => {
        if (authorityRole.authority.code == Authorities.SUPERVISORS && authorityRole["get"] == true) {
          this.getSupervisors.push("true");
        }
      });

      if (role.code == Roles.ROLE_FARMER) {
        this.service = this.farmerService;
      }
    })
    if (this.service != this.farmerService)
      if (this.auth.indexOf("true") != -1) {
        if (this.getSupervisors.indexOf("true") != -1)
          this.service = this.supervisorService;
        else
          this.service = this.customerService;
      }
    return this.service;

  }


  loadForm(projection, section, relatedTo?) {
    let list = []
    this.formTemplateService.getForms(projection, section, relatedTo).subscribe(res => {
      res?.forEach(formTemplate => {
        formTemplate.xml['formTemplateId'] = formTemplate.id
        formTemplate.xml['view'] = formTemplate.view
        list.push(formTemplate.xml)
      })
    }, error => {
      this.error(error.error.error)
    })

    return list;

  }

  checkRole(): any {
    this.connectedCustomer['roles'].forEach(role => {
      this.auth.push((role.isSupervisor).toString())
    })
    if (this.auth.indexOf("true") != -1) {
      return true;
    }
    return false
  }



  searchLabel(list, value: string) {
    let filter = value.toLowerCase();
    return list.filter(option => this.codeTranslate.transform(option).toLowerCase().includes(filter)).sort((a, b) => {
      if (this.codeTranslate.transform(a) > this.codeTranslate.transform(b)) {
        return 1
      } else if (this.codeTranslate.transform(a) < this.codeTranslate.transform(b)) {
        return -1
      }
      return 0
    });
  }

  loadCountries() {

    let options: Options = {
      params: {
        projection: 'country',
      }
    }
    this.countryService.getPages(options).subscribe(res => {
      this.selectedCountries = this.countries = res.resources.sort((a, b) => {
        if (this.codeTranslate.transform(a) > this.codeTranslate.transform(b)) {
          return 1
        } else if (this.codeTranslate.transform(a) < this.codeTranslate.transform(b)) {
          return -1
        }
        return 0
      });
    })
  }

  onKeyCountry(value) {
    this.selectedCountries = this.searchLabel(this.countries, value);
  }

  observableTimer() {
    this.timer = 30;
    this.timeLeft = 30;

    this.showResend = false
    const source = timer(1000, 2000);
    this.subscribeTimer = source.subscribe(val => {
      console.log(val, '-');
      this.timer = this.timeLeft - val;
      if (this.timer == 0) {
        this.showResend = true
        this.subscribeTimer.unsubscribe();
      }
    });
  }

  verifFormatDate() {

    if (this.tokenManagementService.getTranslateLanguage() == "fr") {
      this.format = "DD MMM YYYY"
    }
    else if (this.tokenManagementService.getTranslateLanguage() == "ar") {
      this.format = "YYYY MMM DD"
    }
    else {
      this.format = "MMM DD YYYY"
    }
    return this.format
  }


  monthShort() {
    this.monthsShort = [
      this.translate.instant('janshort'),
      this.translate.instant('febshort'),
      this.translate.instant('marshort'),
      this.translate.instant('aprshort'),
      this.translate.instant('mayshort'),
      this.translate.instant('junshort'),
      this.translate.instant('julshort'),
      this.translate.instant('augshort'),
      this.translate.instant('sepshort'),
      this.translate.instant('octshort'),
      this.translate.instant('novshort'),
      this.translate.instant('decshort')
    ];
    return this.monthsShort
  }
  getUnreadAlertCount() {

    if (this.platform.is("cordova"))
      this.platform.ready().then(() => {
        let getAlerts = []
        this.connectedCustomer['roles'].forEach(role => {
          role['authorityRoles'].forEach(authorityRole => {
            if (authorityRole.authority.code == Authorities.ALERTS)
              getAlerts.push((authorityRole["get"]).toString());
          })
        })
        if (getAlerts.indexOf("true") != -1)
          this.alertService.getUnreadAlertCount().subscribe((data: number) => {
            Badge.set({ 'count': data });
          })
      });

  }

  /* verifFormatMaxDate() {
     if (this.tokenManagementService.getTranslateLanguage() == "fr") {
       this.formatMaxDate = "DD-MM-YYYY"
     }
     else if (this.tokenManagementService.getTranslateLanguage() == "ar") {
       this.formatMaxDate = "YYYY-MM-DD"
     }
     else {
       this.formatMaxDate = "MM-DD-YYYY"
     }
     return this.formatMaxDate
   }*/


  hideLoading() {
    window.document.getElementById("loading").style.display = 'none'
  }

  showLoading() {
    window.document.getElementById("loading").style.display = 'flex'
  }

  hideNetwork() {
    window.document.getElementById("network-status").style.display = 'none'
  }

  showNetwork() {
    window.document.getElementById("network-status").style.display = 'flex'
  }

  getConnected() {
    if (this.connectedCustomer) {
      return of(this.connectedCustomer)
    } else {
      return Observable.create(o => {

        this.tokenManagementService.getUserInfo().subscribe(res => {

          this.connectedCustomer = res
          this.verifValueUid(res)

          o.next(res);
        }, error => {
          this.tokenManagementService.logout()
          this.router.navigateByUrl('/login')
          /*let message = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? "User is disabled" : 'INVALID_ACCESS_TOKEN');
          let alert = this.translate.instant(error.error.error_description.startsWith('Invalid access token:') ? Alert.CONTACT_US : Alert.OK)
          this.alertsService.open(message, alert);*/
        })
      },
      )
    }
  }

  getDefaultGroup(): Observable<any> {

    if (this.defaultGroupId) {
      return of(this.defaultGroupId)
    } else {
      return Observable.create(o => {
        this.groupService.getDefaultId().subscribe(defaultGroupId => {
          this.defaultGroupId = defaultGroupId
          o.next(defaultGroupId);
        })
      })
    }
  }

  getFormTemplateFarmerMilkProduction(): Observable<any> {
    if (this.formTemplateFormFarmerMilkProduction) {
      return of(this.formTemplateFormFarmerMilkProduction)
    } else {
      return Observable.create(o => {
        this.formTemplateService.findByType("MILK_PRODUCTION").subscribe(formTemplate => {
          this.dataAddMilkProduction = formTemplate

          this.formTemplateFormFarmerMilkProduction = formTemplate['id']
          o.next(formTemplate['id']);
        })
      })
    }
  }

  getFormTemplateFarmerFeedConsumption(): Observable<any> {
    if (this.formTemplateFormFarmerFeedConsumption) {
      return of(this.formTemplateFormFarmerFeedConsumption)
    } else {
      return Observable.create(o => {
        this.formTemplateService.findByType("FEED_CONSUMPTION").subscribe(formTemplate => {
          this.dataAddFeedConsumption = formTemplate
          this.formTemplateFormFarmerFeedConsumption = formTemplate['id']
          o.next(formTemplate['id']);
        })
      })
    }
  }
  getFormTemplateAnimalMilkProduction(): Observable<any> {

    if (this.formTemplateFormAnimalMilkProduction) {
      return of(this.formTemplateFormAnimalMilkProduction)
    } else {
      return Observable.create(o => {
        this.formTemplateService.findByType("ANIMAL_MILK_PRODUCTION").subscribe(formTemplate => {
          this.dataAddAnimalMilkProduction = formTemplate

          this.formTemplateFormAnimalMilkProduction = formTemplate['id']
          o.next(formTemplate['id']);
        })
      })
    }
  }

  getKeys(o) {
    return Object.keys(o)
  }

  organizedData(value, dataAdd) {
    if (value && dataAdd) {
      value.forEach(form => {
        let code, date, orderField
        if (dataAdd.xml.formFields.filter(field => field.title == true).length > 0)
          code = dataAdd.xml.formFields.find(field => field.title == true).code
        if (dataAdd.xml.formFields.filter(field => field.label == true).length > 0)
          date = dataAdd.xml.formFields.find(field => field.label == true).code
        if (dataAdd.xml.formFields.filter(field => field.orderField == true).length > 0)
          orderField = dataAdd.xml.formFields.find(field => field.orderField == true).code

        let data = form['value'] ? form['value'] : form;
        this.getKeys(data).forEach(key => {
          if (code) {
            if (code.toLowerCase() == key.toLowerCase())
              form.title = data[key]
          }
          if (date) {
            if (date.toLowerCase() == key.toLowerCase())
              form.date = data[key]
          }
          if (orderField) {
            if (orderField.toLowerCase() == key.toLowerCase())
              form.orderField = data[key]
          }
        })
      })

      /* value.sort((a, b) => {
         if (a.orderField < b.orderField) {
           return 1
         } else if (a.orderField > b.orderField) {
           return -1
         }
         return 0
 
       })*/


      value.sort((a, b) => {
        return <any>new Date(b.orderField) - <any>new Date(a.orderField);
      });
    }
    return value

  }
  groupDataByYearAndMonth(data: any[]) {
    
    this.groupedData = {};
    const yearActualy = new Date().getFullYear();
    const monthActualy = (new Date().getMonth() + 1).toString().padStart(2, '0');
    const monthYearKeyActually = `${yearActualy}/${monthActualy}`;

    data?.forEach(item => {
      const dateParts = item.date.split('T')[0].split('-'); // Extraction des parties de la date
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]);
      const monthYearKey = `${year}/${month.toString().padStart(2, '0')}`;

      if (monthYearKey === monthYearKeyActually) {
        if (!this.groupedData["monthValues"]) {
          this.groupedData["monthValues"] = [];
        }
        this.groupedData["monthValues"].push(item);
      } else if (year < new Date().getFullYear()) {
        if (!this.groupedData[year]) {
          this.groupedData[year] = [];
        }
        this.groupedData[year].push(item);
      } else {
        if (!this.groupedData[monthYearKey]) {
          this.groupedData[monthYearKey] = [];
        }
        this.groupedData[monthYearKey].push(item);
      }
    });
    console.log(this.groupedData)
    return this.groupedData;
  }
  groupDataByYearAndMonthWithoutTiming(data: any[]) {
    this.groupedData = {};
    const yearActualy = new Date().getFullYear();
    const monthActualy = (new Date().getMonth() + 1).toString().padStart(2, '0');
    const monthYearKeyActually = `${yearActualy}/${monthActualy}`;
  
    data?.forEach(item => {
      const date = new Date(item.date);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const monthYearKey = `${year}/${month}`;
  
      if (monthYearKey === monthYearKeyActually) {
        if (!this.groupedData["monthValues"]) {
          this.groupedData["monthValues"] = [];
        }
        this.groupedData["monthValues"].push(item);
      } else if (year < new Date().getFullYear()) {
        if (!this.groupedData[year]) {
          this.groupedData[year] = [];
        }
        this.groupedData[year].push(item);
      } else {
        if (!this.groupedData[monthYearKey]) {
          this.groupedData[monthYearKey] = [];
        }
        this.groupedData[monthYearKey].push(item);
      }
    });
    return this.groupedData;
  }
  groupData(data: any[]) {
    this.groupedData = {};
    this.groupedData["monthValues"] = [];
    data?.forEach(item => {
     
      this.groupedData["monthValues"].push(item);
    });
    return this.groupedData;
  }
  updateConnectedCustomer() {
    this.connectedCustomer = null;
    this.connectedCustomerUpdated.next(null);
  }

  rtl() {
    let lang: string = this.translate.currentLang;
    if (lang == "ar")
      return this.isRtl = true;
    else
      return this.isRtl = false;
  }

  rtlF() {
    let lang: string = this.translate.currentLang;
    if (lang == "ar" || lang == "fr")
      return this.isRtl = true;
    else
      return this.isRtl = false;
  }

  /*changeRecaptchaLanguage(language) {
    var script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?hl=' + language;
    script.async = true;
    script.defer = true;
    document.querySelector('head').appendChild(script);
  }*/

  validWeight(form) {
    let weightPattern = /^[0-9]+(\.[0-9]{1,3})?$/
    let weight = (document.getElementById("weight") as HTMLFormElement).value.toString()
    if ((document.getElementById("weight") as HTMLFormElement).value.toString() !== "") {
      if (!weightPattern.test(weight)) {
        this.message('COW_PATTERN_WEIGHT');
        form.setValue((document.getElementById("weight") as HTMLFormElement).value.substr(0, LENGTH_WEIGHT));
        return { 'weightValid': true };
      }
    }
    else {
      return null;
    }
  }

  successMessage(msg) {
    this.hideLoading();
    let message = this.translate.instant(msg);
    this.alertsService.open(message);
  }

  message(msg) {
    let message = this.translate.instant(msg);
    let alert = this.translate.instant(Alert.OK)
    this.alertsService.open(message, alert);
  }

  error(message) {
    this.hideLoading();
    let alert = this.translate.instant(Alert.OK)
    this.alertsService.open(this.translate.instant(message), alert);
  }
  afterClick() {
    this.verif = false;
    this.advancedSearch = false;
  }

  verifValueUid(value) {
    /*let value
        if (valueConnected == null)
          value = this.connectedCustomer;
        else
          value = valueConnected;*/

    if (value['customerSettings'].length != 0) {
      value['customerSettings'].forEach(customerSetting => {
        if (customerSetting.setting == LIVESTOCK_IDENTIFICATION) {
          switch (customerSetting.value) {
            case LivestockIdentif.NATIONAL_IDENTIFICATION:
              return this.valueUid = LivestockIdentif.NATIONAL_IDENTIFICATION;
            case LivestockIdentif.NAME_LOCALCODE:
              return this.valueUid = LivestockIdentif.NAME_LOCALCODE;
            default:
              return this.valueUid = null;

          }
        }
      })
    }
    else
      return this.valueUid = null
  }
  /*  displayResend() {
      this.showResend = false
      setTimeout(() => {
        this.showResend = true
      }, 30000);
    }*/
  /* customerCommonServiceRole() {
     
       this.connectedCustomer['roles'].forEach(role => {
         this.auth.push((role.isSupervisor).toString())
       })
       if (this.auth.indexOf("true") != -1) {
         this.customers = 'supervisors'
       } else {
         this.customers = 'farmers'
       }
       return this.customers
     
   }*/

  loadEntreprises(projection) {
    this.entrepriseService.findEntreprises(projection).subscribe(res => {
      this.selectedEntreprises = this.entreprises = res.resources.sort((a, b) => {
        if (this.codeTranslate.transform(a) > this.codeTranslate.transform(b)) {
          return 1
        } else if (this.codeTranslate.transform(a) < this.codeTranslate.transform(b)) {
          return -1
        }
        return 0
      });
    })
    return this.selectedEntreprises;

  }

  searchLabelRegion(list, value: string) {
    let filter = value.toLowerCase();
    return list.filter(option => this.codeTranslate.transform(option).toLowerCase().includes(filter));
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.entreprises.filter(option => option.name.toLowerCase().includes(filter));
  }

  onKeyEntreprise(value) {
    this.selectedEntreprises = this.search(value);
  }


  loadRegions() {
    this.regionService.getPages().subscribe(res => {
      this.selectedRegions = this.regions = res['resources']
    })
    return this.selectedRegions;
  }

  onKeyRegion(value) {
    this.selectedRegions = this.searchLabelRegion(this.regions, value);
  }



  saveLanguage() {

    let language = this.tokenManagementService.getTranslateLanguage();
    if (this.connectedCustomer['customerSettings'].length != 0) {
      this.connectedCustomer['customerSettings'].forEach(customerSetting => {
        if (customerSetting.setting == LANGUAGE)
          this.customerSetting['_links'] = {
            self: {
              href: environment.apiPath + "/customerSettings/" + customerSetting.id
            }
          }
        else
          this.customerSetting.customer = this.connectedCustomer['link'].href;
      })

    }
    this.customerSetting.customer = this.connectedCustomer['link'].href;
    this.customerSetting.setting = LANGUAGE
    this.customerSetting.value = language.toUpperCase()// this.connectedCustomer.customerSettings.find(set => set.setting == LANGUAGE).value
    this.customerSettingService.save(this.customerSetting).subscribe(
      data => {
      })
    error => {
      this.hideLoading();

    }
  }

  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    if (this.getScreenWidth > 1024)
      this.typeWindow = true;
    else
      this.typeWindow = false;
    return this.typeWindow;
  }

  changeColorRumination(input) {
    if (input) {
      if ((input <= 650) && (input >= 450))
        return 'green';
      else if (((input < 450) && (input >= 300)) || ((input <= 700) && (input > 650)))
        return 'orange';
      else if ((input < 300) || (input > 700))
        return 'red';
    }
  }

  changeColorResting(input) {
    if (input) {
      if ((input <= 800) && (input >= 600))
        return 'green';
      else if (((input < 600) && (input >= 400)) || ((input <= 900) && (input > 800)))
        return 'orange';
      else if ((input < 400) || (input > 900))
        return 'red';
    }
  }

  hideRecaptcha() {
    const recaptchaBadge = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (recaptchaBadge) {
      recaptchaBadge.style.visibility = 'hidden';
    }
  }

  showRecaptcha() {
    const recaptchaBadge = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (recaptchaBadge) {
      recaptchaBadge.style.visibility = 'visible';
    }
  }

  changeColorThi(input1, input2) {
    let value = ((1.8 * input1 + 32) - ((0.55 - 0.0055 * input2) * (1.8 * input1 - 26)))
    this.colorThi(value);
  }

  colorThi(value) {
    if (value < 72)
      return 'color-thi-moome';
    else if ((value >= 72) && (value < 80))
      return 'color-thi-orange';
    else if (value >= 80)
      return 'color-thi-red';
  }
  googleAnalyticsAllPages(input1, input2?, input3?, input4?) {
    this.getConnected().subscribe(user => {
      //the full url 
      this.googleAnalyticsPages()
      //debogage
      gtag('event', input1,
        {
          'debug_mode': true,
          'event_label_username': user.username,
          'event_label_fullname': user.fullname,
          'event_uid_animal': input2,
          'event_name_animal': input3,
          'event_label_titleAlert': input4,
          'event_label_phoneuser': user.phone
        }
      );
    })
  }
  googleAnalyticsSignup(input1, input2?, input3?) {
    //the full url 
    this.googleAnalyticsPages()
    //debogage
    gtag('event', input1, {
      'debug_mode': true,
      'event_label_fullname': input2,
      'event_label_phone': input3
    });
  }

  googleAnalyticsPages() {
    gtag('send', 'event', {
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
    gtag('config', 'G-NVCBWK0WJ8', {
      page_title: window.location.pathname,
      page_location: window.location.href
    });
  }
  checkImgBattery(device) {
    if (device) {
      if (device.voltage > 3)
        return "/assets/images/batterie/100.svg"
      else if (device.voltage > 2.9 && device.voltage <= 3)
        return "/assets/images/batterie/75.svg"
      else if (device.voltage > 2.8 && device.voltage <= 2.9)
        return "/assets/images/batterie/50.svg"
      else if (device.voltage <= 2.8)
        return "/assets/images/batterie/25.svg"
      else
        return "/assets/images/Tracé-835.svg"
    }
  }

  saveAs(blob, filename, type) {
    FileSaver.saveAs(blob, this.translate.instant(filename) + type);
    //let fileURL = URL.createObjectURL(blob);
    // window.open(fileURL);
  }
  searchSettingsFarmer(key) {
    let message: String;
    let slashPos = key.indexOf("/");
    let valueFullname = key.toString().substring(0, slashPos);
    let valueSettings = key.toString().substring(slashPos + 1);
    const navigationExtras: NavigationExtras = { state: { value: key } }

    this.customerService.findIdCustomerByFullname(valueFullname.toUpperCase()).subscribe(
      res => {
        if (res['id']) {
          let farmerId = res['id'];
          this.formTemplateService.findByLabelOfFormTemplate(valueSettings.toUpperCase()).subscribe(res1 => {
            this.idFormTemplate = res1;

            state.removeItem("formData");
            if (this.idFormTemplate != null) {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.router.navigateByUrl('/farmers/' + farmerId + '/settings/' + this.idFormTemplate, navigationExtras))
              this.hideLoading()
              this.verif = false
            }
            else {
              this.hideLoading();
              let mes = this.translate.instant("NO_IDENTIFIAR_FOR_SETTINGS");
              let alert = this.translate.instant(Alert.OK)
              this.alertsService.open(mes, alert);
            }
          })
        }
      }, error => {
        this.hideLoading();
        message = this.translate.instant(error.error.code);
        let alert = this.translate.instant(Alert.OK)
        this.alertsService.open(message, alert);
      })
  }
  numericOnly(event, pattern) {
    const inputValue = event.target.value;
    const keyPressed = event.key;
    const normalizedValue = inputValue.replace(',', '.');
    const newValue = normalizedValue + keyPressed;

    if (!pattern.test(newValue)) {
      return event.preventDefault();
    }
  }

  textOnly(event, pattern) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const newData = event.data;
    if (newData !== null && !pattern.test(inputValue + newData)) {
      return inputElement.value = inputValue.replace(newData, '');
    }
  }
  calculAge(event, formGroup, formatArabe) {

    setTimeout(() => {
      this.calculValueBirth(formGroup, formatArabe)
    }, 3500);
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      formatArabe = "DD/MMM/YYYY"
    let diff = moment().diff(moment(event.detail.value), "days")
    if (diff >= 30) {
      let ageYear = (Math.floor(diff / 30.4167 / 12));
      let ageMonth = Math.floor((Math.round(diff - (ageYear * 12 * 30.4167))) / 30);
      formGroup.controls['years'].setValue(ageYear);
      formGroup.controls['months'].setValue(ageMonth);
      this.showLact(formGroup, formatArabe)
      //this.addWeight()
    }
  }


  verifAge(formGroup, formatArabe) {

    if ((formGroup.controls['years'].value == 16) || (formGroup.controls['months'].value < 0)) {
      formGroup.controls['months'].setValue(0)
    }
    else if (formGroup.controls['years'].value < 0) {
      formGroup.controls['years'].setValue(0)
    }
    else if (formGroup.controls['years'].value > 16) {
      formGroup.controls['years'].setValue(16)
    }
    else if (formGroup.controls['months'].value > 12) {
      formGroup.controls['months'].setValue(12)
    }
    this.calculBirthDate(formGroup, formatArabe)

  }

  calculBirthDate(formGroup, formatArabe) {

    setTimeout(() => {
      this.calculValueBirth(formGroup, formatArabe)
    }, 3500);
    var cntOfYears = formGroup.controls['years'].value;
    var cntOfMonths = formGroup.controls['months'].value;
    var cntOfDays = "0";
    var birthDate = moment().subtract(cntOfYears, 'years').subtract(cntOfMonths, 'months').subtract(cntOfDays, 'days');
    if (cntOfMonths != null && cntOfYears != null) {
      formGroup.controls['birthDate'].setValue(birthDate.format(FORMAT_MAX_MIN))
    }
    formGroup.controls['years'].setValue(cntOfYears)
    formGroup.controls['months'].setValue(cntOfMonths)
    this.showLact(formGroup, formatArabe)
    //this.addWeight()
  }

  calculValueBirth(formGroup, formatArabe) {

    if ((formGroup.controls['years'].value == null) && (formGroup.controls['months'].value != null)) {
      formGroup.controls['years'].setValue(0)
      this.calculBirthDate(formGroup, formatArabe)
    }

    if ((formGroup.controls['months'].value == null) && (formGroup.controls['years'].value != null)) {
      formGroup.controls['months'].setValue(0)
      this.calculBirthDate(formGroup, formatArabe)
    }
  }

  showLact(formGroup, formatArabe) {
    if (this.tokenManagementService.getTranslateLanguage() == "ar")
      formatArabe = "DD/MMM/YYYY"
    let diff = moment().diff(moment(formGroup.controls['birthDate'].value), "days")
    this.month = (Math.floor(diff / 30));
    this.verifDate(formGroup)
    this.verifNoDate(formGroup)
    if ((this.month > 12) || this.valueDate || this.valueNoDate)
      this.show = true
    else
      this.show = false
    return this.show
  }

  verifDate(formGroup) {
    if (((formGroup.controls['months'].value > 2) && (formGroup.controls['years'].value >= 1)) || (formGroup.controls['years'].value > 1))
      this.valueDate = true
    else
      this.valueDate = false
  }
  verifNoDate(formGroup) {
    if ((formGroup.controls['months'].value == null) && (formGroup.controls['years'].value == null) && (formGroup.controls['birthDate'].value == null))
      this.valueNoDate = true
    else
      this.valueNoDate = false
  }

  moins(formGroup) {
    let step: number = 1
    if (this.calvings != 0 && formGroup.controls['sex'].value != 'MALE')
      this.calvings = Math.floor(this.calvings - step)
    if (this.calvings == 0) {
      formGroup.controls['group'].setValue(null);
      formGroup.controls['group'].clearValidators();
      formGroup.controls['group'].updateValueAndValidity();
    }
  }

  plus(formGroup) {
    let step: number = 1
    if ((formGroup.controls['sex'].value != 'MALE') && (this.calvings < 12))
      this.calvings = Math.floor(this.calvings + step)

    if (this.calvings > 0)
      formGroup.controls['group'].setValidators([Validators.required]);
    formGroup.controls['group'].updateValueAndValidity();

  }

  messageMaxCow(msg) {
    if (msg == 'REACH_MAX_COW') {
      this.hideLoading()
      this.showDialog('REACH_MAX_COW')
    }
    else if (msg == 'REACH_MAX_COW_FREE') {
      this.hideLoading()
      this.showDialog('REACH_MAX_COW_FREE')
    } else
      this.error(msg)
  }

  showDialog(mes) {
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      width: '450px',
      disableClose: true,
      data: {
        message: this.translate.instant(mes),
        title: this.translate.instant('ADD_MORE_COWS'),
        onConfirm: () => {
          this.saveSubscription()
        },
        onClose: () => {
          this.saveSubscriptionHistory()
        }
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  saveSubscription() {
    this.subscriptionRequestService.saveSubscription().subscribe(element => {
      /*let message = this.translate.instant('CONTACT_ASAP');
      this.alertsService.open(message, null, 10000);*/
    }, error => {
      this.error(error.error.code)
    })
  }

  saveSubscriptionHistory() {
    this.subscriptionRequestHistoryService.saveSubscriptionHistory().subscribe(element => {
      /* let message = this.translate.instant('CONTACT_ASAP');
       this.alertsService.open(message, null, 10000);*/
    }, error => {
      this.error(error.error.code)
    })
  }
  loadShopFormTmplate() {
    let options: Options = {
      pageParams: {
        page: 0,
        size: 100
      }
    }
    this.shopFormTemplateService.getPages(options).subscribe(res => {
      this.listShop = res['resources']
    },
      error => {
        this.error(error.error.code);
      });
  }

  changeColorLactation(input) {
    if (input) {
      if ((input >= 1) && (input <= 400))
        return 'green';
      else if ((input >= 401) && (input <= 420))
        return 'orange';
      else if ((input > 420))
        return 'red';
      else
        return 'color-dark-blue';
    }
  }
  chooseLanguage(language) {
    const languageMappings = {
      'ar': { name: 'عربية', flag: '/assets/images/tobias-Flag-of-Tunisia.svg' },
      'fr': { name: 'Français', flag: '/assets/images/flag-for-flag-france-svgrepo-com.svg' },
      'en': { name: 'English', flag: '/assets/images/Flag_of_the_United_Kingdom.svg' },
      'rw': { name: 'Kinyarwanda', flag: '/assets/images/Rwanda-flagpalette.svg' },
      'ind': { name: 'Indonesia', flag: '/assets/images/indonesia-flag-icon.svg' }
    };

    this.selectedLanguage = languageMappings[language] || { name: '', flag: '' };
    return this.selectedLanguage;
  }
  findMilkPrice() {
    this.farmerMilkProductionService.findMilkPrice().subscribe(res => {
      this.price = res;
    })
    return this.price;
   

  }

}
