<app-bar [element]="bar"></app-bar>
<form [formGroup]="formGroup">
    <div class="row  px-4 py-3 position">
        <div class="col-12 mx-auto back-style">
            <div class="mt-1 text-center note-style" [ngClass]="{rtl:appService.rtl()}">
                <div>{{ 'FINANCIAL_OVERVIEW' | translate}}</div>
            </div>
        </div>
    </div>
    <div class="row  px-4 py-3 position">

        <div class="row " [ngClass]="{rtl:appService.rtl()}">
            <div class="col mb-2 center-element">
                <ion-item class="input-mob-date">

                    <ion-datetime #datePicker mode="ios" class="font-size-text " [ngClass]="{rtl:appService.rtl()}"
                        [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()"
                        placeholder="{{ 'START_DATE'| translate }}" [doneText]="'SAVE' |translate"
                        [cancelText]="'CANCEL' |translate" (ionChange)="fixArabeFormat()"
                        [monthShortNames]="appService.monthShort()" formControlName="startDateFinancial">

                    </ion-datetime>
                    <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar' : 'date'"
                        src="/assets/images/calendar4.svg" />

                </ion-item>
            </div>
        </div>
        <div class="row " [ngClass]="{rtl:appService.rtl()}">
            <div class="col mb-2 center-element ">
                <ion-item class="input-mob-date">

                    <ion-datetime #datePicker mode="ios" class="font-size-text " [ngClass]="{rtl:appService.rtl()}"
                        [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()"
                        placeholder="{{ 'END_DATE'| translate }}" [doneText]="'SAVE' |translate"
                        [cancelText]="'CANCEL' |translate" (ionChange)="fixArabeFormat()"
                        [monthShortNames]="appService.monthShort()" formControlName="endDateFinancial">

                    </ion-datetime>
                    <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar' : 'date'"
                        src="/assets/images/calendar4.svg" />

                </ion-item>
            </div>
        </div>
        <div class="row ">
            <div class="col-6 mb-2 center-element">
                <div class="float-start">
                    <button [ngClass]="!formGroup.valid?  'opac' : ''"
                        class="button-dialog button-text-size text-center" mat-raised-button color="primary"><span
                            [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'"
                            class="center-element" (click)="calculFinancial()">{{ 'CONFIRM' | translate }}
                        </span></button>
                </div>
            </div>
        </div>
    </div>

</form>
<div class="row py-2 position center-element" [ngClass]="{rtl:appService.rtl()}">
    <div class="col-6 back-style-financial ">
        <div class="note-style-incomes  mt-3">
            {{ 'TOTAL_INCOME' | translate }} ({{unit| translate}})
        </div>
        <div class="note-style-number ">
            {{ totalIncome  | number:'':'en'  }}
        </div>
        <div class="note-style-description ">
            {{ 'MILK' | translate }} ({{milk }})</div>
        <div class="note-style-description ">
            {{ 'OTHERS' | translate }} ({{otherIncome  }})
        </div>
    </div>
</div>
<div class="row py-2 position center-element " [ngClass]="{rtl:appService.rtl()}">
    <div class="col-6 back-style-financial">
        <div class="note-style-costs  mt-3">
            {{ 'TOTAL_COSTS' | translate }} ({{unit| translate}})
        </div>
        <div class="note-style-number ">
            {{totalCost  | number:'':'en'  }}
        </div>
        <div class=" note-style-description ">
            {{ 'FEED' | translate }} ({{feed  }})</div>
        <div class="note-style-description ">
            {{ 'OTHERS' | translate }} ({{otherCost }})
        </div>
    </div>
</div>


<div class="row py-2 position center-element " [ngClass]="{rtl:appService.rtl()}">
    <div class="col-6 back-style-total">
        <div class="note-style-feed-cost  mt-2">
            {{ 'NET_INCOMES' | translate }} ({{unit| translate}})
        </div>
        <div class="note-style-number-net ">
            {{netIncomes | number:'':'en'}}
        </div>
    </div>
</div>

<form [formGroup]="formGroup1">
    <div class="row  px-4 py-3 position">
        <div class="col-12 mx-auto back-style">
            <div class="mt-1 text-center note-style" [ngClass]="{rtl:appService.rtl()}">
                <div>{{ 'FARM_OVERVIEW' | translate}}</div>
            </div>
        </div>
    </div>
    <div class="row  px-4 py-3 position">

        <div class="row " [ngClass]="{rtl:appService.rtl()}">
            <div class="col mb-2 center-element">
                <ion-item class="input-mob-date">

                    <ion-datetime #datePicker mode="ios" class="font-size-text " [ngClass]="{rtl:appService.rtl()}"
                        [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()"
                        placeholder="{{ 'START_DATE'| translate }}" [doneText]="'SAVE' |translate"
                        [cancelText]="'CANCEL' |translate" (ionChange)="fixArabeFormat()"
                        [monthShortNames]="appService.monthShort()" formControlName="startDateFarm">

                    </ion-datetime>
                    <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar' : 'date'"
                        src="/assets/images/calendar4.svg" />

                </ion-item>
            </div>
        </div>
        <div class="row " [ngClass]="{rtl:appService.rtl()}">
            <div class="col mb-2 center-element ">
                <ion-item class="input-mob-date">

                    <ion-datetime #datePicker mode="ios" class="font-size-text " [ngClass]="{rtl:appService.rtl()}"
                        [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()"
                        placeholder="{{ 'END_DATE'| translate }}" [doneText]="'SAVE' |translate"
                        [cancelText]="'CANCEL' |translate" (ionChange)="fixArabeFormat()"
                        [monthShortNames]="appService.monthShort()" formControlName="endDateFarm">

                    </ion-datetime>
                    <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar' : 'date'"
                        src="/assets/images/calendar4.svg" />

                </ion-item>
            </div>
        </div>
        <div class="row ">
            <div class="col-6 mb-2 center-element">
                <div class="float-start">
                    <button [ngClass]="!formGroup1.valid?  'opac' : ''"
                        class="button-dialog button-text-size text-center" mat-raised-button color="primary"><span
                            [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'"
                            class="center-element" (click)="countByDateFarm()">{{ 'CONFIRM' | translate }}
                        </span></button>
                </div>
            </div>
        </div>
    </div>

</form>

<div class="row py-2 position center-element">
    <app-cercle-card class="col-6" [element]="greenCard"></app-cercle-card>

</div>

<div class="row px-4 py-3 position center-element">
    <div class="col-5  back-style-feed center-element text-center note-style-feed-cost me-2"
        [ngClass]="{rtl:appService.rtl()}">
        {{feedCosts}}% {{ 'FEED_COSTS' | translate }}
    </div>
    <div class="col-5  back-style-cost center-element text-center note-style-feed-cost "
        [ngClass]="{rtl:appService.rtl()}">
        {{otherCosts}}% {{ 'OTHER_COSTS' | translate }}
    </div>

</div>

<form [formGroup]="formGroup2">
    <div class="row  px-4 py-3 position">
        <div class="col-12 mx-auto back-style">
            <div class="mt-1 text-center note-style" [ngClass]="{rtl:appService.rtl()}">
                <div>{{ 'GROUPS_DETAILS' | translate}}</div>
            </div>
        </div>
    </div>
    <div class="row  px-4 py-3 position">
        <div class="row center-element mt-3 mb-2"  [ngClass]="{rtl:appService.rtl()}">
            <div class="text-center input-mob-message ">{{'ONLY_GROUPS' | translate}}</div>
          </div>
        <div class="row " [ngClass]="{rtl:appService.rtl()}">
           
            <div class="col mb-2 center-element">
                <mat-select class=" input-height input-width op ps-2 pe-2" disableOptionCentering
                    panelClass="myPanelAnimalClass"
                    placeholder="{{ 'CHOOSE' | translate}} &nbsp; {{ 'GROUP' | translate}}"
                    [ngClass]="{rtl:appService.rtl()}" formControlName="group" required>

                    <mat-option *ngFor="let group of selectedGroups" [value]="group.code"
                        [ngClass]="{rtl:appService.rtl()}">
                        <span *ngIf="labelEn">{{group.labelEn}}</span>
                        <span *ngIf="labelFr">{{group.labelFr}}</span>
                        <span *ngIf="labelInd">{{group.labelInd}}</span>
                        <span [ngClass]="{rtl:appService.rtl()}" *ngIf="labelAr">{{group.labelAr}}</span>
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <div class="row " [ngClass]="{rtl:appService.rtl()}">
            <div class="col mb-2 center-element">
                <ion-item class="input-mob-date">

                    <ion-datetime #datePicker mode="ios" class="font-size-text " [ngClass]="{rtl:appService.rtl()}"
                        [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()"
                        placeholder="{{ 'START_DATE'| translate }}" [doneText]="'SAVE' |translate"
                        [cancelText]="'CANCEL' |translate" (ionChange)="fixArabeFormat()"
                        [monthShortNames]="appService.monthShort()" formControlName="startDateGroup">

                    </ion-datetime>
                    <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar' : 'date'"
                        src="/assets/images/calendar4.svg" />

                </ion-item>
            </div>
        </div>
        <div class="row " [ngClass]="{rtl:appService.rtl()}">
            <div class="col mb-2 center-element ">
                <ion-item class="input-mob-date">

                    <ion-datetime #datePicker mode="ios" class="font-size-text " [ngClass]="{rtl:appService.rtl()}"
                        [displayFormat]="formatArabe" [pickerFormat]="appService.verifFormatDate()"
                        placeholder="{{ 'END_DATE'| translate }}" [doneText]="'SAVE' |translate"
                        [cancelText]="'CANCEL' |translate" (ionChange)="fixArabeFormat()"
                        [monthShortNames]="appService.monthShort()" formControlName="endDateGroup">

                    </ion-datetime>
                    <img matDatepickerToggleIcon [ngClass]="appService.rtl()? 'date-ar' : 'date'"
                        src="/assets/images/calendar4.svg" />

                </ion-item>
            </div>
        </div>
        <div class="row ">
            <div class="col-6 mb-2 center-element">
                <div class="float-start">
                    <button [ngClass]="!formGroup2.valid?  'opac' : ''"
                        class="button-dialog button-text-size text-center" mat-raised-button color="primary"><span
                            [ngClass]="appService.rtl()? 'button-mob-text-ar' : 'button-mob-text'"
                            class="center-element" (click)="countByDateAndGroup()">{{ 'CONFIRM' | translate }}
                        </span></button>
                </div>
            </div>
        </div>
    </div>

</form>



<div class="row py-2 position center-element">
    <app-cercle-card *ngFor="let element of list" class="col-6 " [element]="element"></app-cercle-card>
</div>