import { Component, OnInit } from '@angular/core';
import { FarmerStockFeedDto } from 'projects/shared/resources/FarmerStockFeedDto';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { AppService } from 'projects/shared/services/app.service';
import { FarmerStockFeedService } from 'projects/shared/services/farmer-stock-feed.service';
import { FormTemplateService } from 'projects/shared/services/form-template.service';
import { FormService } from 'projects/shared/services/form.service';
import { SectionForm, TEXT_PATTERN } from 'projects/shared/tools/constants';
import { CustomRouter } from '../../services/custom-router';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { FarmerMiscInventoryDto } from 'projects/shared/resources/FarmerMiscInventoryDto';
import { FarmerMiscInventoryService } from 'projects/shared/services/farmer-misc-inventory.service';
import { FarmerMiscInventoryStockService } from 'projects/shared/services/farmer-misc-inventory-stock.service';
@Component({
  selector: 'app-edit-misc-stock',
  templateUrl: './edit-misc-stock.component.html',
  styleUrls: ['./edit-misc-stock.component.scss']
})
export class EditMiscStockComponent implements OnInit {


  idFarmer
  idFormTemplate
  formTemplate
  id
  result
  name;
  section = SectionForm.farmer_stock
  create = false
  resultValue
  currentRoute
  quantity
  idStock
  textPattern = TEXT_PATTERN
  color='color-stock'
  constructor(public farmerMiscInventoryStockService: FarmerMiscInventoryStockService, public formService: FormService, public appService: AppService, private farmerMiscInventoryService: FarmerMiscInventoryService,
    public translate: TranslateService, private alertsService: AlertsService, public dialog: MatDialog,
    private router: CustomRouter, public route: ActivatedRoute, public formTemplateService: FormTemplateService) {

    const { idFormTemplate, id, quantity, idStock } = this.route.snapshot.params
    this.idFarmer = this.appService.connectedCustomer.id
    this.idFormTemplate = idFormTemplate;
    this.id = id
    this.quantity = quantity
    this.idStock = idStock
    if (this.id)
      this.displayInfo(this.id);
    else this.create = true
    this.currentRoute = this.router.url;
    if (this.router.url.indexOf("addMiscInventory") != -1)
      this.name = "ADD_MISC_INVENTORY"
    else if (this.router.url.indexOf("reduceMiscInventory") != -1)
      this.name = "REDUCE_MISC_INVENTORY"
    else
      this.name = "MISC_INVENTORY"
  }

  ngOnInit(): void {

  }

  findFormTemplate() {
    this.formTemplateService.getForm(this.idFormTemplate, "form-template", this.section).subscribe(template => {
      this.formTemplate = template
    })
  }

  displayInfo(id) {
    let body: FarmerMiscInventoryDto = new FarmerMiscInventoryDto();
    this.appService.showLoading()
    this.farmerMiscInventoryService.getMiscInventory(id).subscribe(res => {
      this.result = res;
      body.title = this.result.title;
      body.date = this.result.date;
      if ((this.currentRoute.indexOf("add") != -1) || (this.currentRoute.indexOf("reduce") != -1))
        body.quantity = null;
      else if (this.quantity != null)
        body.quantity = this.quantity
      else
        body.quantity = this.result.quantity;
      body.unit = this.result.unit
      body.unitPrice = (this.result.farmerMiscInventoryStocks.filter(item => item.id == this.idStock))[0]?.unitPrice;
      body.date = (this.result.farmerMiscInventoryStocks.filter(item => item.id == this.idStock))[0]?.date;

      this.resultValue = body;
    })
    this.appService.hideLoading()
  }
  confirm(event) {
    this.save(event);
  }
  save(event) {
    let form = event.formGroup.value
    if(!this.textPattern.test(form.title)) {
      form.title = form.title.slice(0, -1)  
    }
    if ( (!form.unitPrice)||(!form.quantity))
      this.appService.error('STOCK_EMPTY')
    else if (this.idStock) {
      this.appService.showLoading();
      this.farmerMiscInventoryStockService.update(this.idStock, form).subscribe(res1 => {
        this.appService.hideLoading();
        let message = this.translate.instant('SAVE_SUCESS');
        this.alertsService.open(message);
        this.back();
      })
    }
    else {

      if (event.formGroup.status == "INVALID")
        this.appService.error('STOCK_INVALID')
      else if ((!form.title) || (!form.unit))
        this.appService.error('STOCK_EMPTY')
      else {
        form.id = this.id
        let method = "saveFarmerMiscInventory"
        if ((this.result && (this.currentRoute.indexOf("add") == -1 && this.currentRoute.indexOf("reduce") == -1))) {
          method = "update"
        }

        if (this.result)
          form.sumQuantity = this.result.quantity
        if (this.currentRoute.indexOf("reduce") != -1)
          form.quantity = -form.quantity
        this.appService.showLoading();
        this.farmerMiscInventoryService[method](form).subscribe(res => {
          this.appService.hideLoading();
          let message = this.translate.instant('SAVE_SUCESS');
          this.alertsService.open(message);
          this.back();
        }, error => {
          this.appService.error(error.error.code);
        });

      }
    }
  }

  back() {
    this.router.navigateByUrl('stock/miscInventory/' + this.idFormTemplate);

  }
}