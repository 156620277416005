import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionBarComponent } from './section-bar.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [SectionBarComponent],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [SectionBarComponent]
})
export class SectionBarModule { }
