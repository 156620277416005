<app-section-bar [title]="name" (goBack)="back()" [color]="color"></app-section-bar>

<div class="row" *ngIf="section==settingsFormsAdvisorFarmer">
    <div class="col text-center mt-3 mb-3"> <span class="text-style" [ngClass]="{rtl:appService.rtl()}">{{
            formDefinition |
            codeTranslate }}</span> </div>
</div>

<ng-template [cdkPortalOutlet]="componentPortal"></ng-template>

<div class="row">
    <div class="col center-element mt-3 mb-3">
        <button mat-raised-button color="primary" class="button-mob" (click)="confirm()"
            [ngClass]=" !formGroup?.valid  ?  'opac' : '' "><span [ngClass]="{rtl:appService.rtl()}">{{ 'CONFIRM' |
                translate }}</span></button>
    </div>
</div>