import { Injectable, Injector } from '@angular/core';
import { CustomerCommonService } from './customer-common.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { ConfigService } from './config.service';
import { TokenManagementService } from './token-management.service';
import state from "../../shared/tools/state"
import { Supervisor } from '../resources/Supervisor';
import { Options } from './moome-rest.service';
@Injectable({
	providedIn: 'root'
})

export class SupervisorService extends CustomerCommonService<Supervisor> {
	
	constructor(injector: Injector, public http: HttpClient, private configService: ConfigService, public tokenManagementService: TokenManagementService) {
		super(Supervisor);
		
	}
	getFarmer(id) {
		let farmers : any[]  = state.farmers || {};
		let selectedFarmer = farmers[id]
		if(selectedFarmer) return of(selectedFarmer)
		return this.http.get(this.configService.getApiPath() + `/supervisors/${id}/findCustomerById`, id).pipe(map(element =>{
			farmers[id] = element
			state.farmers=farmers
			return element
		}));
	}
	
	saveSupervisor(body) {
		return this.http.post(this.configService.getApiPath() + `/supervisors/save`, body);
	}

	deleteSupervisor(id) {
		return this.http.delete(this.configService.getApiPath() + `/supervisors/${id}/delete`);

	}

	isHasFarmers(customer): Observable<any> {
		return this.http.get(this.configService.getApiPath() + "/supervisors/hasFarmers/" + customer);
	}



	getSupervisorRoles() {
		return this.http.get(this.configService.getApiPath() + `/supervisors/supervisor_roles`);
	}

	findFarmers(supervisorId?) {
		
		let param: any=
        {type: 'MILK_QUANTITY',
        }
        if (supervisorId)
            param.supervisorId=supervisorId;

        let options: Options = {
            params: 
               param
        }
        return this.customRequestGet(`farmers`,null,options).pipe(
			map(res =>{ return res['_embedded']?.customers}
				
			)
		)
	}

	findFarmersWithAnimals(page, size, field, direction, key) {
	
		let param: any=
        {projection: 'farmer-animal',
        }
        if (key !=null)
            param.key=key.toLowerCase();
			if (field !=null)
            param.field=field;
			if (direction !=null)
            param.direction=direction.toUpperCase();

        let options: Options = {
            params: 
               param
             ,
			 pageParams: {
				page:page,
				size: size
			 }
        }

		return this.customRequestGet(`/farmers/animals`,null,options)
	}

	findFarmersAnimalswithKey(key, groupId) {
		let param: any=
        {   groupId: groupId,
			projection:'farmer-animal',
			type:'MILK_QUANTITY'
        }

        let options: Options = {
            params: 
               param
             ,
			 pageParams: {
			 }
        }
		return  this.customRequestGet(`/farmers/animals/search/${key.toLowerCase()}?groupId=${groupId}&projection=farmer-animal&type=MILK_QUANTITY`).pipe(map(element =>{
			return element['_embedded']?.customers
		}));
		
	}

	getFarmerOfSupervisor() {
		return this.customRequestGet(`/getFarmers`)
	}

	findTemperatureHumidity(ids) {
		return this.http.get(this.configService.getApiPath() + `/supervisors/farmers/temperature-humidity`, { params: { 'ids': [ids] } })
	}

	verifPassword(password) {
		return this.http.get(this.configService.getApiPath() + `/supervisors/verifPassword?password=${password}`);
	}

	updateUsername(customer) {
		let httpOptions = {
			headers: new HttpHeaders({
				'language': this.tokenManagementService.getTranslateLanguage(),
			})
		};

		return this.http.put(this.configService.getApiPath() + `/supervisors/updateUsername`, customer, httpOptions);
	}

	updateFullname(fullname) {
		return this.http.put(this.configService.getApiPath() + `/supervisors/updateFullname`, fullname);
	}

	updateAddress(countryId) {
		return this.http.put(this.configService.getApiPath() + `/supervisors/updateAddress`, countryId);
	}

	updatePhone(phone) {
		return this.http.put(this.configService.getApiPath() + `/supervisors/updatePhone`, phone);
	}

	updatePasswordApp(customer) {
		let httpOptions = {
			headers: new HttpHeaders({
				'language': this.tokenManagementService.getTranslateLanguage(),
			})
		};
		return this.http.put(this.configService.getApiPath() + `/supervisors/update-password`, customer, httpOptions);
	}

	saveDeviceToken(token) {
		return this.http.put(this.configService.getApiPath() + `/supervisors/save-device-token`, token);
	}

	updatePassword(id, password) {
		return this.http.put(this.configService.getApiPath() + `/supervisors/${id}/updatePassword`, password);
	}

	getForAdminEntrepriseSupervisors() {
		return this.customRequestGet(`/getForAdminEntrepriseSupervisors`).pipe(map(element =>{
			return element['_embedded']?.customers
		}));
	}

	updateEnabled(username, value) {
		return this.http.put(this.configService.getApiPath() + `/supervisors/${username}/updateEnabled`, value);
	}

	saveWebToken(token) {
		return this.http.put(this.configService.getApiPath() + `/supervisors/save-web-token`, token);
	}

	sendSms(body) {
		let httpOptions = { headers: new HttpHeaders({
			'language':  this.tokenManagementService.getTranslateLanguage(),
		  })
		};
		return this.http.post(this.configService.getApiPath() + `/supervisors/send-code`, body, httpOptions);
	}

	checkCode(body) {
		return this.http.post(this.configService.getApiPath() + `/supervisors/verif-code`, body);
	}
}