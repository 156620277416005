
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'projects/shared/services/app.service';
import { CustomRouter } from '../../services/custom-router';
import { FarmerService } from 'projects/shared/services/farmer.service';
import { Customer } from 'projects/shared/resources/Customer';
import { Alert } from 'projects/shared/tools/constants';
import { AlertsService } from 'projects/shared/services/alerts.service';
import { TokenManagementService } from 'projects/shared/services/token-management.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonDialogComponent } from '../../common-dialog/common-dialog.component';
//import { RecaptchaComponent } from '../../recaptcha/recaptcha.component';
import { ChangePassordDialogComponent } from '../change-passord-dialog/change-passord-dialog.component';
import { Validator } from 'projects/shared/tools/validators';
import { CustomerService } from 'projects/shared/services/customer.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-sms-verification',
  templateUrl: './sms-verification.component.html',
  styleUrls: ['./sms-verification.component.scss']
})
export class SmsVerificationComponent implements OnInit {
  groupForm: UntypedFormGroup;
  text: String
  sms: String
  next: String
  notRec: String
  resend: String
  verif: Boolean = false
  nextB: Boolean = true
  customer: Customer
  phoneLength: number;
  token: any;
  private executionsSubscription: Subscription;
  phoneText = "YOUR_PHONE"

  constructor(public translate: TranslateService, public dialog: MatDialog, private recaptchaV3Service: ReCaptchaV3Service,
    private formBuilder: UntypedFormBuilder, private alertsService: AlertsService,
    private router: CustomRouter, public appService: AppService, public farmerService: FarmerService, public tokenManagementService: TokenManagementService, private customerService: CustomerService) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as { customer: Customer };
    this.customer = state.customer;
    this.phoneLength = this.customer.addresses[0].country.phoneLength
  }

  ngOnInit() {
    if(this.router.history[this.router.history.length-1].indexOf('farmers')!= -1)
    this.phoneText = "FARMER_PHONE"
    this.appService.showRecaptcha()
    this.createForm();
    this.verifSmsA();
  }

  createForm() {
    this.groupForm = this.formBuilder.group({
      'phone': [null, [
        Validators.required, Validator.validPhoneLength(this.translate, this.phoneLength, this.alertsService)]],
      'code': [null],
    });
  }

  verifSmsA() {
    this.text = this.phoneText
    this.sms = ''
    this.next = 'NEXT'
    this.notRec = ''
    this.resend = ''
  }

  verifSmsB() {
    this.text = 'YOUR_SMS'
    this.sms = 'YOUR_PASS'
    this.next = 'VERIFY'
    this.notRec = 'NOT_RECI?'
    this.resend = 'RESEND'
  }

  prevSig() {
    this.router.back('/home');
  }

  prevSms() {
    this.appService.subscribeTimer.unsubscribe();
    this.groupForm.controls['code'].setValidators([]);
    this.groupForm.controls['code'].updateValueAndValidity();
    this.verifSmsA();
    this.router.navigateByUrl('/sms-verification')
    this.nextB = true
  }

  message(msg) {
    this.appService.hideLoading();
    let alert = this.translate.instant(Alert.OK)
    this.alertsService.open(msg, alert);
  }

  applyA() {
    if (this.groupForm.controls['phone'].hasError('required'))
      this.message(this.translate.instant('FIELD_REQUIRED'));

    else if ((document.getElementById("phone") as HTMLFormElement).value.toString().length != this.phoneLength) {
      this.message(this.translate.instant('PHONE_LENGTH') + " " + this.phoneLength.toString() + this.translate.instant('DIGITS') + " ");
    } else {

      //googleAnalytics
      this.appService.googleAnalyticsSignup('send-sms', null, (document.getElementById("phone") as HTMLFormElement).value.toString())
      this.appService.showRecaptcha();
      this.executionsSubscription = this.recaptchaV3Service.execute('importantAction')
        .subscribe((token: string) => {
          this.token = token;
          this.customer.phone = (document.getElementById("phone") as HTMLFormElement).value.toString().replace(/\s/g, "")
          this.customer['recaptchaToken'] = token
         
          this.customer['smsInfos'] = []

          this.farmerService.sendSms(this.customer).subscribe(data => {
            this.done()
            this.customer['smsInfos'].push(data)
          }, error => {
            this.message(this.translate.instant(error.error.code));
          });
        });







      /*const dialogRef = this.dialog.open(RecaptchaComponent, {
       data: {
         onConfirm: (token) => {
           
           this.token = token;
           this.customer.phone = (document.getElementById("phone") as HTMLFormElement).value.toString().replace(/\s/g, "")
           this.customer['recaptchaToken'] = token
           this.done()
           this.customer['smsInfos'] = []
           
           this.farmerService.sendSms(this.customer).subscribe(data => {
             
             this.customer['smsInfos'].push(data)
           }, error => {
             this.message(this.translate.instant(error.error.code));
           });

         }
       }
     });*/
    }
  }

  done() {

    this.verifSmsB()
    this.router.navigateByUrl('/sms-verification')
    this.nextB = false
    this.groupForm.controls['code'].setValidators([Validators.required]);
    this.groupForm.controls['code'].updateValueAndValidity();
    this.appService.observableTimer()
  }

  applyB() {
    if (this.groupForm.controls['code'].hasError('required')) {
      this.message(this.translate.instant('FIELD_REQUIRED'));
    } else {
      this.customer.password = (document.getElementById("code") as HTMLFormElement).value.toString().replace(/\s/g, "")
      this.appService.showRecaptcha();
      this.executionsSubscription = this.recaptchaV3Service.execute('importantAction')
        .subscribe((token: string) => {
          this.token = token;
          this.customer['recaptchaToken'] = token
          this.appService.showLoading()

          this.farmerService.saveFarmerMobile(this.customer).subscribe(data => {
            if (this.appService.connectedCustomer == null)
              this.login();
            else {
              this.customerService.addFarmerToSupervisor(this.customer.phone).subscribe(res => {
                this.appService.hideLoading()
                this.router.navigateByUrl('/farmers');
              })
            }
          },
            error => {
              this.message(this.translate.instant(error.error.code));
            })

        });
      /*const dialogRef = this.dialog.open(RecaptchaComponent, {
        data: {
          onConfirm: (token) => {
            this.token = token;
            this.customer['recaptchaToken'] = token
            this.appService.showLoading()
            
            this.farmerService.saveFarmerMobile(this.customer).subscribe(data => {
              if(this.appService.connectedCustomer == null)
              this.login();
              else{
                   this.customerService.addFarmerToSupervisor(this.customer.phone).subscribe(res => {
                    this.appService.hideLoading()
                    this.router.navigateByUrl('/farmers');
                   })}
            },
            error => {
              this.message(this.translate.instant(error.error.code));
            })

          }
        }
      });*/
    }
  }

  resendCode() {
    this.appService.showRecaptcha();
    this.executionsSubscription = this.recaptchaV3Service.execute('importantAction')
      .subscribe((token: string) => {
        this.customer['recaptchaToken'] = token;
        this.appService.showLoading()
        this.farmerService.resendCode(this.customer).subscribe(data => {
          this.appService.hideLoading();
          this.appService.observableTimer()
        }, error => {
          this.message(this.translate.instant(error.error.message));
        });
      });
    /* const dialogRef = this.dialog.open(RecaptchaComponent, {
       data: {
         onConfirm: (token) => {
           this.customer['recaptchaToken'] = token;
           this.appService.showLoading()
           this.farmerService.resendCode(this.customer).subscribe(data => {
             this.appService.hideLoading();
             this.appService.observableTimer()
           }, error => {
             this.message(this.translate.instant(error.error.message));
           });
         }
       }
     });*/
  }

  login() {
    this.tokenManagementService.login(this.customer.phone, this.customer.password)
      .subscribe(
        data => {
          this.tokenManagementService.setToken(data['access_token'], data['refresh_token'], data['expires_in'], data['fullname']);
          this.appService.hideLoading();
          this.verif = true
          setTimeout(() => {
            if (this.router.url == '/sms-verification')
              this.router.navigateByUrl('/home');
            this.changePassword()
          }, 3000);
        },
        error => {
          this.message(this.translate.instant('LOGIN_FAIL'));
        }
      );
  }

  changePassword() {
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      data: {
        message: this.translate.instant('CHANGE_PASSWORD'),
        title: this.translate.instant('EDIT_PASS'),
        onConfirm: () => {
          const dialogRef = this.dialog.open(ChangePassordDialogComponent, {
            data: {
              customer: this.customer
            }
          });
        }
      }
    });
  }

  ngOnDestroy() {
    this.appService.hideRecaptcha()
    if (this.executionsSubscription) {
      this.executionsSubscription.unsubscribe();
    }
  }

}
