import { Injectable, Injector } from '@angular/core';

import { Animal } from '../resources/Animal';
import { Gateway } from '../resources/Gateway';
import { TranslateService } from '@ngx-translate/core';
import { Species } from '../resources/Species';
import { HttpClient } from '@angular/common/http';
import { TokenManagementService } from './token-management.service';
import { ConfigService } from './config.service';
import { MoomeRestService, Options } from './moome-rest.service';
import { map, Observable, take } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class AnimalService extends MoomeRestService<Animal>{


	lang: string = this.translate.currentLang;

	constructor(public http: HttpClient, injector: Injector, private translate: TranslateService, public tokenManagementService: TokenManagementService, private configService: ConfigService) {
		super(Animal);

	}

	getCustomersByKey(key, associatedTo, projection) {

		let param: any =
			{ projection: projection, }
		if (associatedTo != null)
			param.associatedTo = associatedTo;
		if (key != null)
			param.key = key.toLowerCase();

		let options: Options = {
			params:
				param
		}
		return this.search('farmersByKey', options)
	}



	saveAnimal(body) {
		return this.http.post(this.configService.getApiPath() + `/animals/save`, body);
	}

	getAnimals(page, size, field, direction, id, key) {
		let param: any =
		{
			projection: 'livestocks',
		}
		if (id != null)
			param.id = id;
		if (key != null)
			param.key = key.toLowerCase();
		if (field != null)
			param.field = field;
		if (direction != null)
			param.direction = direction.toUpperCase();


		let options: Options = {
			params:
				param
			,
			pageParams: {
				size: size,
				page: page
			}
		}

		return this.searchPages(`getAnimals`, options)
	}


	getWithDetail(uuid) {
		return this.searchSingle(`getWithDetail?uuid=${uuid}&projection=details`)
	}

	getAnimalsByCustomer(id) {
		return this.search(`getAnimalsByCustomer?id=${id}&projection=animals`)
	}

	findByCustomer(id) {
		return this.search(`findByCustomer?id=${id}`)
	}

	getAnimalsByConnectedCustomer(farmerId?) {
		if (farmerId == null)
			farmerId = ""
		let options: Options =
		{
			params: {

				farmer: farmerId,

			}
		}
		return this.customRequestGet("/find", null, options).pipe(map(element => { return element['_embedded'].animals }))
	}

	findNoAttachtedAnimal(type) {
		let options: Options = {
			params: {
				projection: 'devices',
				type: type
			}
		}
		return (this.search(`devices`, options) as Observable<any>) as Observable<Species[]>
	}

	findNoAttachtedAnimalByCustomer(id, isSupervisor, type) {
		let options: Options = {
			params: {
				projection: 'devices',
				id: id,
				isSupervisor: isSupervisor,
				type: type
			}

		}
		return (this.search(`devicess`, options) as Observable<any>) as Observable<Species[]>
	}

	getSpecies(field) {
		let param: any =
			{ projection: 'animalTypes', }

		if (field != null)
			param.field = field;

		let options: Options = {
			params:
				param
		}


		return (this.search(`species`, options) as Observable<any>) as Observable<Species[]>
	}


	getBreeds(id) {

		let options: Options = {

			params: {
				projection: 'breeds',
			}
		}
		return (this.search(`breedsByanimalTypeId?id=${id}`, options) as Observable<any>) as Observable<Species[]>;
	}

	getGateways() {
		let options: Options = {

			params: {
				projection: 'gateways_animal',
			}
		}
		return (this.search(`gateways`, options) as Observable<any>) as Observable<Gateway[]>
	}

	getGatewaysByCustomer(id, isSupervisor) {

		let options: Options = {

			params: {
				projection: "gateways_animal",
				id: id,
				isSupervisor: isSupervisor
			}
		}
		return (this.search(`gatewayss`, options) as Observable<any>) as Observable<Gateway[]>
	}

	/*updateAnimal(body,id){
		return this.http.patch(this.configService.getApiPath() + `/animals/checkAndSave/${id}`, body);
	}*/

	/*findByCode(code) {
		let options: Options = {

			params: {
				code: code,
				projection: "devices"
			}
		}
		return this.searchSingle(`findDevice`, options)
	}*/

	/*	saveAnimalGroup(body) {
			return this.http.post(this.configService.getApiPath() + `/animals/group`, body);
		}*/

	getAnimalsByGroup(supervisorId?, farmerId?, groupId?) {
		if (farmerId == null)
			farmerId = ""
		if (supervisorId == null)
			supervisorId = ""

		return this.customRequestGet(`/findByGroup?&projection=car-animals&supervisor=${supervisorId}&farmer=${farmerId}&group=${groupId}`)
	}

	deleteAll(rows) {
		return this.http.delete(this.configService.getApiPath() + `/animals/deleteAnimals/${rows}`)
	}

	getPedigree(id) {
		return this.searchSingle(`getPedigree?id=${id}&projection=animal-pedigree`)
	}

	getAnimalsOfFarmersBysupervisorBySexe(id, sexe) {
		return this.search(`getAnimalsOfFarmersBysupervisorBySexe?id=${id}&sexe=${sexe}&projection=animalUid`)
	}

	getAnimalsOfFarmersBySexe(id, sexe) {

		if (id == null)
			id = ""
		let options: Options =
		{
			params: {

				id: id,
				sexe: sexe,
				projection: "animalUid"
			}

		}

		return this.customRequestGet(`/getAnimalsOfFarmersBySexe`, null, options)
	}

	getById(id) {
		return this.searchSingle(`getById?id=${id}&projection=details`)
	}

	getAnimalById(id, projection) {
		return this.searchSingle(`getAnimalById?id=${id}&projection=${projection}`)
	}


	getAnimalsSearch(farmerId, uid) {
		return this.customRequestGet(`/getAnimalsSearch?&projection=car-animals&farmerId=${farmerId}&uid=${uid}`)
	}

	getByUid(uid) {
		return this.searchSingle(`getByUid?uid=${uid}&projection=animal-uid`)
	}
	getByName(name) {
		return this.searchSingle(`getByName?name=${name}&projection=animal-uid`)
	}

	getByUidOrByName(title) {
		return this.searchSingle(`getByUidOrByName?value=${title}&projection=animal-uid`)
	}

	updateAnimalProject(id, projectId) {
		return this.http.post(this.configService.getApiPath() + `/animals/updateAnimalProject/` + id, projectId);

	}


	getAnimalsByGroupByProject(projectIds, farmerIds) {

		let options: Options =
		{
			params: {

				projection: "animal_customer_project",
				projects: projectIds,
				farmers: farmerIds
			}
		}
		return this.customRequestGet(`/findByGroupByProject`, null, options)
	}

	getAllAnimalByFarmers(farmerIds) {

		let options: Options =
		{
			params: {

				projection: "animal_customer_project",
				farmers: farmerIds
			}
		}
		return this.customRequestGet(`/getAllAnimalByFarmers`, null, options)

		
	}

	getAnimalsOfFarmerForReproReport(farmerIds) {

		let options: Options =
		{
			params: {

				projection: "reproReports",
				farmers: farmerIds
			}
		}
		return this.customRequestGet(`/getAnimalsOfFarmerForReproReport`, null, options).pipe(map(element =>{
            return element['_embedded']?.animals
        }));

		
	}
	getNumberAnimalInProduction(farmerId, code) {
		return this.search(`getNumberAnimalInProduction?farmerId=${farmerId}&code=${code}`)
	}
	getAssignGroupValue(id) {
		if (id == null)
			id = ""
		let options: Options =
		{
			params: {
				id: id,
			}
		}
		return this.customRequestGet(`/getAssignGroupValue`, null, options)
	}
}
