import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupsComponent } from './groups.component';
import { GroupCardModule } from './group-card/group-card.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SectionBarModule } from '../section-bar/section-bar.module';
import { MoomeCommonModule } from 'projects/shared/modules/moome-common.module';

@NgModule({
  declarations: [GroupsComponent],
  imports: [
    CommonModule,
    GroupCardModule,
    TranslateModule,
    RouterModule,
    SectionBarModule,
    MoomeCommonModule
  ]
})
export class GroupsModule { }
